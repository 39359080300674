import React, { useState, useEffect, useContext } from "react";
import { UserContext } from "../../App";
import {
  Tabs,
  Card,
  Input,
  Button,
  Form,
  notification,
  Select,
  Switch,
  InputNumber,
} from "antd";
import { SERVER_URL } from "../../config";
import useAxios from "../../hooks/useAxios";
import { DeleteOutlined } from "@ant-design/icons";
import Axios from "axios";

const { Option } = Select;
const layout = {
  labelCol: { span: 5 },
  wrapperCol: { span: 9 },
};

const MachineForm = ({ isNew, data, onSubmit }) => {
  const [form] = Form.useForm();
  const currentuser = useContext(UserContext);
  const token = currentuser.data.token;
  const [digitalPrintPriceArr, setIsDigitalPrintPriceArr] = useState([]);
  const [selectedTypesOfLabel, setSelectedTypesOfLabel] = useState([]);
  const [typesOfLabel, fetchTypesOfLabel] = useAxios("", [], token, "get");
  const [isMachineForSheetCalc, setIsMachineForSheetCalc] = useState(false);

  let initialValues = { ...data };

  useEffect(() => {
    fetchTypesOfLabel(`${SERVER_URL}/types-of-label`, []);
  }, []);

  useEffect(() => {
    if (data?.printType === "tabak") {
      setIsMachineForSheetCalc(true);
    }

    fetchSelectedTypesOfLabel();
  }, [data]);

  const onChangePrintType = (e) => {
    if (e === "rolna") {
      setIsMachineForSheetCalc(false);
    } else {
      setIsMachineForSheetCalc(true);
    }
  };

  const fetchSelectedTypesOfLabel = async () => {
    const res = await Axios.get(`${SERVER_URL}/machine/${data._id}`, {
      withCredentials: false,
      headers: { Authorization: `Bearer ${token}` },
    });

    setSelectedTypesOfLabel(res.data.typesOfLabel);
  };

  const onFinish = async (values, isNew) => {
    if (!isNew) {
      values._id = data._id;
    }
    onSubmit(values, isNew);
  };

  const handleNewItem = async () => {
    setIsDigitalPrintPriceArr([...digitalPrintPriceArr, {}]);
  };

  const handleRemoveFromAdding = (index) => {
    const data = form.getFieldsValue();
    let dataPrice = data.price;
    let dataCirMin = data.circulationMin;
    let dataCirMax = data.circulationMax;
    let dataTypeOfLabel = data.typeOfLabelPriceArr;

    dataPrice.splice(index, 1); // 2nd parameter means remove one item only
    dataCirMin.splice(index, 1); // 2nd parameter means remove one item only
    dataCirMax.splice(index, 1); // 2nd parameter means remove one item only
    dataTypeOfLabel.splice(index, 1); // 2nd parameter means remove one item only

    form.setFieldsValue({
      price: dataPrice,
      circulationMin: dataCirMin,
      circulationMax: dataCirMax,
      typeOfLabelPriceArr: dataTypeOfLabel,
    });

    setIsDigitalPrintPriceArr(digitalPrintPriceArr.slice(0, -1));
  };

  const handleRemovePrice = async (id) => {
    try {
      ///brisanje itema za brzinu stampe odredjene masine
      await Axios.put(
        `${SERVER_URL}/machine-pricesarray/${data._id}`,
        { id },
        {
          withCredentials: false,
          headers: { Authorization: `Bearer ${token}` },
        },
      );

      setTimeout(() => {
        window.location.reload();
      }, 500);
    } catch (e) {
      notification.error({
        message: "Problem sa brisanjem. Pokušajte kasnije.",
        placement: "bottomRight",
      });
    }
  };

  return (
    <div className="panel panel-body ">
      <div className="panel-body">
        <Card title={`${isNew ? "NOVA MAŠINA" : "IZMENA MAŠINE"}`}>
          <Form
            {...layout}
            initialValues={initialValues}
            onFinish={(values) => onFinish(values, !data)}
            layout="vertical"
            form={form}
          >
            <div className="mainCont">
              <Form.Item
                required
                name="code"
                className="ant-form-item-control-input-content"
                label="Šifra mašine:"
              >
                <Input />
              </Form.Item>
            </div>

            <Tabs forceRender defaultActiveKey="1">
              <Tabs.TabPane
                forceRender
                tab="Osnove informacije o mašini"
                key="1"
              >
                <div>
                  <div className="panel-body">
                    <Form.Item
                      required
                      className="formitemANTD"
                      name="name"
                      label="Naziv mašine:"
                    >
                      <Input />
                    </Form.Item>
                  </div>
                  <div className="panel-body">
                    <Form.Item
                      required
                      className="formitemANTD"
                      name="typesOfLabel"
                      label="Tipovi etiketa na mašini:"
                    >
                      <Select mode="multiple">
                        {typesOfLabel.data.items &&
                          typesOfLabel.data.items.length > 0 &&
                          typesOfLabel.data.items.map((item, index) => (
                            <Option key={index} value={item._id}>
                              {item.name}
                            </Option>
                          ))}
                      </Select>
                    </Form.Item>
                  </div>
                  <div className="panel-body">
                    <Form.Item
                      className="formitemANTD"
                      name="maxWidth"
                      label="Maksimalna širina na mašini:"
                    >
                      <InputNumber min="0" />
                    </Form.Item>
                  </div>

                  <div className="panel-body">
                    <Form.Item
                      className="formitemANTD"
                      name="maxColorsNum"
                      label="Maksimalan broj boja i lakova:"
                    >
                      <InputNumber min="0" />
                    </Form.Item>
                  </div>

                  <div className="panel-body">
                    <Form.Item
                      required
                      className="formitemANTD"
                      name="isDigitalPrint"
                      label="Da li je digitalni print?"
                    >
                      <Switch defaultChecked={initialValues.isDigitalPrint} />
                    </Form.Item>
                  </div>

                  <div className="panel-body">
                    <Form.Item
                      required
                      className="formitemANTD"
                      name="printType"
                      label="Vrsta štampe:"
                    >
                      <Select
                        onChange={(e) => {
                          onChangePrintType(e);
                        }}
                      >
                        <Option value="rolna">Rolna</Option>
                        <Option value="tabak">Tabak</Option>
                      </Select>
                    </Form.Item>

                    {isMachineForSheetCalc && (
                      <div>
                        <p
                          style={{
                            borderBottom: "1px solid black",
                            width: "450px",
                          }}
                        >
                          Osobine tabak mašine:
                        </p>
                        <Form.Item
                          required
                          className="formitemANTD"
                          name="existsColorPrint"
                          label="Podržava boju štampe:"
                        >
                          <Switch
                            defaultChecked={initialValues.existsColorPrint}
                          />
                        </Form.Item>

                        <Form.Item
                          required
                          className="formitemANTD"
                          name="existsCalculatedWaste"
                          label="Podržava kalkulisanu makulaturu:"
                        >
                          <Switch
                            defaultChecked={initialValues.existsCalculatedWaste}
                          />
                        </Form.Item>

                        <Form.Item
                          required
                          className="formitemANTD"
                          name="existsMaterialWeight"
                          label="Podržava gramaturu materijala (g/m2):"
                        >
                          <Switch
                            defaultChecked={initialValues.existsMaterialWeight}
                          />
                        </Form.Item>
                        <Form.Item
                          required
                          className="formitemANTD"
                          name="existsWidthAndHeightSheet"
                          label="Podržava visinu i širinu tabaka (mm):"
                        >
                          <Switch
                            defaultChecked={
                              initialValues.existsWidthAndHeightSheet
                            }
                          />
                        </Form.Item>

                        <Form.Item
                          required
                          className="formitemANTD"
                          name="existsTotalElectricity"
                          label="Podržava trošak el. energije (€):"
                        >
                          <Switch
                            defaultChecked={
                              initialValues.existsTotalElectricity
                            }
                          />
                        </Form.Item>

                        <Form.Item
                          required
                          className="formitemANTD"
                          name="existsSheetWeight"
                          label="Podržava težinu tabaka (kg):"
                        >
                          <Switch
                            defaultChecked={initialValues.existsSheetWeight}
                          />
                        </Form.Item>
                      </div>
                    )}
                  </div>

                  {/* ----------------FORMA ZA DODAVANJE NOVIH CENA-------------- */}
                  {data &&
                    data.isDigitalPrint &&
                    data.name &&
                    data.name.includes("Konica Minolta Rolna") && (
                      <div>
                        <Button
                          // hidden={isNew}
                          onClick={handleNewItem}
                          type="primary"
                          htmlType="button"
                        >
                          Dodaj cenu digitalne štampe iz rolne u rolnu
                        </Button>
                        {digitalPrintPriceArr.map((item, index) => (
                          <div
                            hidden={isNew}
                            style={{
                              display: "flex",
                              width: "100%",
                              alignItems: "center",
                            }}
                          >
                            <Form.Item
                              label="Tip materijala"
                              name={["typeOfLabelPriceArr", index]}
                            >
                              <Select
                                style={{ width: "250px", marginRight: "10px" }}
                              >
                                {selectedTypesOfLabel &&
                                  selectedTypesOfLabel.length > 0 &&
                                  selectedTypesOfLabel.map((item, index) => (
                                    <Option key={index} value={item._id}>
                                      {item.name}
                                    </Option>
                                  ))}
                              </Select>
                            </Form.Item>
                            <Form.Item
                              labelCol={{ span: 24 }}
                              name={["circulationMin", index]}
                              label="Tiraž - Od:"
                            >
                              <InputNumber
                                style={{ width: "150px", marginRight: "10px" }}
                                min="0"
                              />
                            </Form.Item>
                            <Form.Item
                              labelCol={{ span: 24 }}
                              name={["circulationMax", index]}
                              label="Tiraž - Do:"
                            >
                              <InputNumber
                                style={{ width: "150px", marginRight: "10px" }}
                                min="0"
                              />
                            </Form.Item>
                            <Form.Item
                              labelCol={{ span: 24 }}
                              name={["price", index]}
                              label="Cena po metru:"
                            >
                              <InputNumber
                                style={{ width: "150px", marginRight: "10px" }}
                                min="0"
                              />
                            </Form.Item>
                            <Button
                              onClick={() => handleRemoveFromAdding(index)}
                              icon={<DeleteOutlined />}
                              type="primary"
                              style={{
                                padding: "0px 0px 0px 20px",
                                width: "60px",
                                display: "flex",
                                alignItems: "center",
                              }}
                              danger
                            ></Button>
                          </div>
                        ))}
                      </div>
                    )}

                  {/* ----------------FORMA ZA PRIKAZ DODATIH CENA-------------- */}
                  {data &&
                    data.pricesForDigitalPrint &&
                    data.pricesForDigitalPrint.length > 0 &&
                    data.pricesForDigitalPrint.map((item, index) => {
                      return (
                        <div
                          style={{
                            display: "flex",
                            width: "100%",
                            alignItems: "center",
                          }}
                        >
                          <Form.Item label="Tip materijala">
                            <Select
                              value={item.typeOfLabel}
                              disabled
                              style={{ width: "250px", marginRight: "10px" }}
                            >
                              {selectedTypesOfLabel &&
                                selectedTypesOfLabel.length > 0 &&
                                selectedTypesOfLabel.map((item, index) => (
                                  <Option key={index} value={item._id}>
                                    {item.name}
                                  </Option>
                                ))}
                            </Select>
                          </Form.Item>
                          <Form.Item
                            labelCol={{ span: 24 }}
                            label="Tiraž - Od:"
                          >
                            <InputNumber
                              value={item.circulationMin}
                              disabled
                              style={{ width: "150px", marginRight: "10px" }}
                              min="0"
                            />
                          </Form.Item>
                          <Form.Item
                            labelCol={{ span: 24 }}
                            label="Tiraž - Do:"
                          >
                            <InputNumber
                              value={item.circulationMax}
                              disabled
                              style={{ width: "150px", marginRight: "10px" }}
                              min="0"
                            />
                          </Form.Item>
                          <Form.Item
                            labelCol={{ span: 24 }}
                            label="Cena po metru:"
                          >
                            <InputNumber
                              disabled
                              value={item.price}
                              style={{ width: "150px", marginRight: "10px" }}
                              min="0"
                            />
                          </Form.Item>
                          <Button
                            onClick={() => handleRemovePrice(item._id)}
                            icon={<DeleteOutlined />}
                            type="primary"
                            style={{
                              padding: "0px 0px 0px 20px",
                              width: "60px",
                              display: "flex",
                              alignItems: "center",
                            }}
                            danger
                          ></Button>
                        </div>
                      );
                    })}
                </div>
              </Tabs.TabPane>
            </Tabs>

            <div className="text-right">
              <Button type="primary" htmlType="submit">
                {isNew ? "Dodaj" : "Izmeni"} mašinu
              </Button>
            </div>
          </Form>
        </Card>
      </div>
    </div>
  );
};

export default MachineForm;
