import React, { useState, useEffect, useContext } from "react";
import { UserContext } from "../../App";
import { useHistory } from "react-router-dom";
import Axios from "axios";
import { SERVER_URL } from "../../config";

import {
  Tabs,
  Card,
  Input,
  Button,
  Form,
  Select,
  Switch,
  InputNumber,
  Table,
  Popconfirm,
  notification,
} from "antd";
import { DeleteOutlined } from "@ant-design/icons";

const { Option } = Select;
const layout = {
  labelCol: { span: 5 },
  wrapperCol: { span: 9 },
};

const FinishingWorkForm = ({ isNew, data, onSubmit }) => {
  const [form] = Form.useForm();
  const currentuser = useContext(UserContext);
  const history = useHistory();

  const [showTable, setShowTable] = useState(false);
  const [showInputForm, setShowInputForm] = useState(false);
  const [tableData, setTableData] = useState([]);

  let initialValues = { ...data };

  useEffect(() => {
    if (initialValues?.machines) {
      setTableData(initialValues.machines);
    }
  }, []);

  const deleteHandler = async (idOfRow) => {
    const id = initialValues._id;
    const res = await Axios.put(
      `${SERVER_URL}/finishing-work-row/${id}`,
      { idOfRow },
      {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      },
    );

    notification.success({
      message: "Red je uklonjen!",
      duration: 2,
      placement: "bottomRight",
    });

    history.push("/admin");
    history.push(`/admin/edit-finishingwork/${id}`);
  };

  const onFinish = async (values, isNew) => {
    if (!isNew) {
      values._id = data._id;
    }
    values.consumptions = tableData; ///prosledjuju se vrednosti iz tabele da bi se sacuvalo u bazu
    onSubmit(values, isNew);
  };

  const changeConsumptionByMachineHandle = (e) => {
    setShowInputForm(e);
    setShowTable(e);
  };

  const newRowInTableDataHandler = () => {
    if (
      form.getFieldValue("machineNameRow") &&
      form.getFieldValue("pcsPerHourRow") &&
      form.getFieldValue("pricePerHourRow")
    ) {
      setTableData((current) => [
        ...current,
        {
          name: form.getFieldValue("machineNameRow"),
          pcsPerH: form.getFieldValue("pcsPerHourRow"),
          pricePerH: form.getFieldValue("pricePerHourRow"),
        },
      ]);

      form.setFieldsValue({
        machineNameRow: "",
        pcsPerHourRow: "",
        pricePerHourRow: "",
      });
    } else {
      notification.error({
        message: "Popunite sva polja!",
        duration: 2,
        placement: "bottomRight",
      });
    }
  };

  useEffect(() => {
    if (initialValues.consumptionByMachine === true) {
      setShowInputForm(true);
      setShowTable(true);
    }
  }, []);

  let columns = [
    {
      key: 1,
      title: "Mašina",
      dataIndex: "name",
    },
    {
      key: 2,
      title: "Cena po satu",
      dataIndex: "pricePerH",
    },
    {
      key: 3,
      title: "Komada po satu",
      dataIndex: "pcsPerH",
    },
    {
      title: "Akcije",
      render: (text, record) => (
        <div
          style={{ margin: "2px", padding: "4px", cursor: "pointer" }}
          className="lock"
        >
          <Popconfirm
            placement="left"
            title={`Ovo će ukloniti unos`}
            onConfirm={() => {
              deleteHandler(record._id);
            }}
            okText="OK"
            cancelText="Cancel"
            cancelButtonProps={{ type: "secondary" }}
            okButtonProps={{ type: "primary" }}
          >
            <DeleteOutlined className="icon-unlock" title={`Obriši mašinu`} />
          </Popconfirm>
        </div>
      ),
    },
  ];

  return (
    <div className="panel panel-body ">
      <div className="panel-body">
        <Card
          title={`${isNew ? "NOVA DORADNA RADNJA" : "IZMENA DORADNE RADNJE"}`}
        >
          <Form
            {...layout}
            initialValues={initialValues}
            onFinish={(values) => onFinish(values, !data)}
            layout="vertical"
            form={form}
          >
            <Tabs forceRender defaultActiveKey="1">
              <Tabs.TabPane
                forceRender
                tab="Osnove informacije o doradnoj radnji"
                key="1"
              >
                <div>
                  <div className="panel-body">
                    <Form.Item
                      required
                      className="formitemANTD"
                      name="name"
                      label="Naziv doradne radnje:"
                    >
                      <Input />
                    </Form.Item>
                  </div>
                  <div className="panel-body">
                    <Form.Item
                      required
                      className="formitemANTD"
                      name="pricePerH"
                      label="Cena po satu:"
                    >
                      <InputNumber className="formitemANTDTwo" min="0" />
                    </Form.Item>
                  </div>
                  <div className="panel-body">
                    <Form.Item
                      required
                      className="formitemANTD"
                      name="pcsPerH"
                      label="Komada po satu(proizvodnja):"
                    >
                      <InputNumber className="formitemANTDTwo" min="0" />
                    </Form.Item>
                  </div>
                  <div className="panel-body">
                    <Form.Item
                      required
                      className="formitemANTD"
                      name="consumptionByMachine"
                      label="Iskorišćenost po mašini:"
                    >
                      <Switch
                        defaultChecked={initialValues.consumptionByMachine}
                        onChange={(e) => {
                          changeConsumptionByMachineHandle(e);
                        }}
                      />
                    </Form.Item>
                  </div>
                  {showInputForm && (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        width: "100%",
                      }}
                    >
                      <Form.Item
                        required
                        className="formitemANTD"
                        name="machineNameRow"
                        label="Mašina:"
                      >
                        <Input className="formitemANTDTwo" min="0" />
                      </Form.Item>
                      <Form.Item
                        required
                        className="formitemANTD"
                        name="pricePerHourRow"
                        label="Cena po satu:"
                      >
                        <InputNumber className="formitemANTDTwo" min="0" />
                      </Form.Item>
                      <Form.Item
                        required
                        className="formitemANTD"
                        name="pcsPerHourRow"
                        label="Komada po satu:"
                      >
                        <InputNumber className="formitemANTDTwo" min="0" />
                      </Form.Item>
                      <Button
                        onClick={() => {
                          newRowInTableDataHandler();
                        }}
                        type="secondary"
                        style={{ height: "30px", fontSize: "10px" }}
                      >
                        Dodaj
                      </Button>
                    </div>
                  )}
                  {showTable && (
                    <Table
                      columns={columns}
                      bordered
                      dataSource={tableData}
                      size="small"
                      pagination={false}
                    ></Table>
                  )}
                </div>
              </Tabs.TabPane>
            </Tabs>

            <div className="text-right">
              <Button type="primary" htmlType="submit">
                {isNew ? "Dodaj" : "Izmeni"} doradnu radnju
              </Button>
            </div>
          </Form>
        </Card>
      </div>
    </div>
  );
};

export default FinishingWorkForm;
