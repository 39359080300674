import React, { useEffect, useContext, useRef, useState } from "react";
import Axios from "axios";
import useAxios from "../../hooks/useAxios";
import dayjs from "dayjs";
import { SERVER_URL } from "../../config";
import { UserContext } from "../../App";
import {
  Form,
  Input,
  Select,
  Button,
  Switch,
  InputNumber,
  notification,
  Radio,
  Table,
} from "antd";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
const { TextArea } = Input;
const { Option } = Select;

const CalculationForm = ({
  data,
  onSubmit,
  setPrintStepData,
  printStepData,
  closestPrintSteps,
  setClosestPrintSteps,
  onSubmitNew,
  onChangeLabelWidth,
}) => {
  const ref = useRef(null);
  const [form] = Form.useForm();
  const currentuser = useContext(UserContext);
  const token = currentuser.data.token;
  const [printType, setPrintType] = useState("rolna"); ///print type se odredjuje po defaultu, ne brisati!
  const [isDigitalPrint, setIsDigitalPrint] = useState(false);
  const [tooWide, setTooWide] = useState(false);
  const [isVisibleTable, setIsVisibleTable] = useState(false);
  const [maxWidth, setMaxWidth] = useState(false);
  const [machines, setMachines] = useState([]);
  const [folioTypes, setFolioTypes] = useState([]);
  const [typesOfVarnishingTypes, setTypesOfVarnishingTypes] = useState([]); ///tip lakiranja koji predstavlja TIP lakiranja
  const [typesOfVarnishingWays, setTypesOfVarnishingWays] = useState([]); ///tip lakiranja koji predstavlja NACIN lakiranja
  const [selectedVarnishingType, setSelectedVarnishingType] = useState();
  const [selectedVarnishingWay, setSelectedVarnishingWay] = useState();
  const [selectedTransport, setSelectedTransport] = useState();
  const [selectedFolioType, setSelectedFolioType] = useState();
  const [selectedMachine, setSelectedMachine] = useState(null);
  const [selectedFolioMachine, setSelectedFolioMachine] = useState(null);
  const [selectedHilzna, setSelectedHilzna] = useState(null);
  const [selectedBoxType, setSelectedBoxType] = useState(null);
  const [selectedClient, setSelectedClient] = useState(null);
  const [selectedTypeOfLabel, setSelectedTypeOfLabel] = useState(null);
  const [selectedRewindingMachines, setSelectedRewindingMachines] = useState(
    [],
  );
  const [rewindingMachinesArr, setRewindingMachinesArr] = useState([{}]);
  const [tableData, setTableData] = useState();
  const [typesOfLabel, setTypesOfLabel] = useState([]);
  const [isDisabledAuthentication, setIsDisabledAuthentication] =
    useState(true);
  const [machinesForPlasticization, setMachinesForPlasticization] = useState();
  const [totalPrice, setTotalPrice] = useState();
  const [allTypesOfLabels, fetchAllTypesOfLabels] = useAxios(
    "",
    null,
    token,
    "get",
  );
  const [allBoxes, fetchAllBoxes] = useAxios("", null, token, "get");
  const [typesOfTransport, fetchTypesOfTransport] = useAxios(
    "",
    null,
    token,
    "get",
  );

  const [rewindingMachines, fetchRewindingMachines] = useAxios(
    "",
    null,
    token,
    "get",
  );
  const [hilzne, fetchHilzne] = useAxios("", null, token, "get");

  const [allClients, fetchAllClients] = useAxios("", null, token, "get");
  const SERVER_URL_PRODUCTION =
    process.env.NODE_ENV === "production"
      ? "https://birografika-api.concordsoft.solutions"
      : "http://localhost:3334";
  ////////////////////////HIDE/SHOW USE STATES/////////////////////////////////////////////////
  const [hideVarnishing, setHideVarnishing] = useState(true);
  const [hideSubcontractors, setHideSubcontractors] = useState(true);
  const [hideRewinding, setHideRewinding] = useState(true);
  const [hidePlasticization, setHidePlasticization] = useState(true);
  const [hideDesign, setHideDesign] = useState(true);
  const [hideTransport, setHideTransport] = useState(true);
  const [hideTransportInput, setHideTransportInput] = useState(true);
  const [hidePrintingForm, setHidePrintingForm] = useState(true);
  const [hideShrinkSleeve, setHideShrinkSleeve] = useState(true);
  const [hideShrinkSleeveSwitch, setHideShrinkSleeveSwitch] = useState(true);
  const [hidePunchTool, setHidePunchTool] = useState(true);
  const [hidePunchToolInput, setHidePunchToolInput] = useState(true);
  const [hideSendAuthenticationMail, setHideSendAuthenticationMail] =
    useState(true);
  const [hidePacking, setHidePacking] = useState(true);
  const [hideBoxes, setHideBoxes] = useState(true);
  const [pricesForSelection, setPricesForSelection] = useState(true);
  const [hideVacuumSealing, setHideVacuumSealing] = useState(true);
  const [hideFolioTypeWarmFT, setHideFolioTypeWarmFT] = useState(true);
  const [rerender, setRerender] = useState(false);

  ////////////////////////////////////////////////////////////////////////////////////
  const [isHiddenGrossKgPrinting, setIsHiddenGrossKgPrinting] = useState(true); ////uslovni prikaz polja u zavisnosti koja etiketa je izbarana
  const [isHiddenGrossMetersPrinting, setIsHiddenGrossMetersPrinting] =
    useState(true); ////uslovni prikaz polja u zavisnosti koja etiketa je izbarana

  //////////////////////////HANDLE CHANGE OF SWITCH FIELDS//////////////////////////////////

  const onChangeVarnishing = () => {
    setHideVarnishing((current) => !current);
  };

  const onChangeSubcontractors = () => {
    setHideSubcontractors((current) => !current);
  };

  const onChangeRollPacking = () => {
    setHidePacking((current) => !current);
  };

  const onChangeRewinding = () => {
    setHideRewinding((current) => !current);
  };

  const onChangePlasticization = () => {
    setHidePlasticization((current) => !current);
  };

  const onChangeDesign = () => {
    setHideDesign((current) => !current);
  };

  const onChangeTransport = () => {
    setHideTransport((current) => !current);
  };

  const onChangePrintingForm = () => {
    setHidePrintingForm((current) => !current);
  };
  const onChangeShrinkSleeve = () => {
    setHideShrinkSleeve((current) => !current);
  };

  const onChangePunchTool = () => {
    setHidePunchTool((current) => !current);
  };

  /////////////////////////////////////////////////////////////////////////////////////////
  let initialValues;
  if (data) {
    initialValues = { ...data };
    initialValues.folioPrintingMachine =
      initialValues?.folioPrintingMachine?._id;
    initialValues.machine = initialValues.machine._id;
    initialValues.typeOfLabel = initialValues.typeOfLabel._id;
    initialValues.date = dayjs(data.date).format("DD.MM.YYYY");
    initialValues.createdAt = dayjs(data.createdAt).format("DD.MM.YYYY");
    if (
      initialValues &&
      initialValues.folioType &&
      initialValues.folioType._id
    ) {
      initialValues.folioType = initialValues.folioType._id;
    }

    if (initialValues.boxesPrice && initialValues.boxesQuantity) {
      ///posto boxesTotalPrice se ne cuva u bazi potrebno je izracunati ga da bi se odma prikazao cim ocitamo stranicu
      initialValues.boxesTotalPrice = (
        initialValues.boxesPrice * initialValues.boxesQuantity
      ).toFixed(2);
    }

    if (initialValues.circulation) {
      (initialValues.totalElectricity =
        initialValues.circulation * 0.00025).toFixed(2);
    }
  }

  useEffect(() => {
    fetchAllClients(`${SERVER_URL}/new-clients`, []);
    fetchAllBoxes(`${SERVER_URL_PRODUCTION}/boxes`, []);
    fetchRewindingMachines(`${SERVER_URL}/rewinding-machines`, []);
    fetchTypesOfTransport(`${SERVER_URL}/types-of-transport`, []);
    fetchHilzne(`${SERVER_URL_PRODUCTION}/hilzne`, []);
    fetchTypesOfVarnishingTypes();
    fetchTypesOfVarnishingWays();
  }, []);

  useEffect(() => {
    if (data && data.assignWidth) {
      setPrintStepData({
        assignWidth: data.assignWidth,
        widthTable: data.widthTable,
        numberOfItems: data.numberOfItems,
        numberOfCylinders: data.numberOfCylinders,
        volume: data.volume,
      });
    }
  }, [data]);

  useEffect(() => {
    if (form.getFieldValue("graphicPreparation") === "nova") {
      setIsDisabledAuthentication(true);
    }

    if (form.getFieldValue("sendAuthentication")) {
      setHideSendAuthenticationMail(false);
    }
  }, [initialValues]);

  useEffect(() => {
    if (hidePunchTool) {
      setHidePunchToolInput(true);
    } else {
      setHidePunchToolInput(false);
    }
  }, [hidePunchTool]);

  useEffect(() => {
    if (hideTransport) {
      setHideTransportInput(true);
    } else {
      if (form.getFieldValue("transportType") === "kupacdolazi") {
        setHideTransportInput(true);
      } else {
        setHideTransportInput(false);
      }
    }
  }, [hideTransport]);

  useEffect(() => {
    if (!hidePacking) {
      if (form.getFieldValue("packingType") === "kutije") {
        setHideBoxes(false);
      } else {
        setHideBoxes(true);
      }

      if (form.getFieldValue("packingType") === "vakumiranje") {
        setHideVacuumSealing(false);
      } else {
        setHideVacuumSealing(true);
      }
    } else {
      setHideBoxes(true);
      setHideVacuumSealing(true);
    }
  }, [hidePacking]);

  useEffect(() => {
    if (hidePlasticization) {
      setHideFolioTypeWarmFT(true);
    } else {
      if (form.getFieldValue("folioWay") === "hladniFT") {
        setHideFolioTypeWarmFT(true);
      } else if (form.getFieldValue("folioWay") === "topliFT") {
        setHideFolioTypeWarmFT(false);
      }
    }
  }, [hidePlasticization]);

  useEffect(() => {
    form.resetFields();

    if (initialValues?.folioWay) {
      fetchFolioTypes(initialValues.folioWay);
    }

    if (initialValues?.transport && initialValues.transportType) {
      onChangeTransportType(initialValues.transportType);
    }

    if (data && data.rewindingValues) {
      for (let i = 1; i < data.rewindingValues.length; i++) {
        rewindingMachinesArr.push({});
      }
    }

    if (data) {
      fetchMachines(data.machine.printType);
      fetchAllTypesOfLabels(`${SERVER_URL}/types-of-label`, []);

      setTypesOfLabel(data.machine.typesOfLabel);

      if (!data.assignWidth) {
        onChangeLabelWidth(
          form.getFieldValue("width"),
          form.getFieldsValue(),
          form.getFieldValue("typeOfLabel") === data.typeOfLabel.name
            ? data.typeOfLabel._id
            : form.getFieldValue("typeOfLabel"),
        );
      }

      setIsDisabledAuthentication(false);
    } else {
      setIsDisabledAuthentication(true);
    }

    ////prikazivanje polja pri ocitavanju stranice ukoliko su neka od po defaultu skrivenih polja ipak popunjena i treba ih prikazati
    if (initialValues) {
      if (initialValues.varnishing) {
        setHideVarnishing(!initialValues.varnishing);
      }
      if (initialValues.subcontractors) {
        setHideSubcontractors(!initialValues.subcontractors);
      }
      if (initialValues.boxes) {
        setHideBoxes(!initialValues.boxes);
      }
      if (initialValues.rewinding) {
        setHideRewinding(!initialValues.rewinding);
      }
      if (initialValues.folioPrinting) {
        setHidePlasticization(!initialValues.folioPrinting);
      }
      if (initialValues.design) {
        setHideDesign(!initialValues.design);
      }
      if (initialValues.transport) {
        setHideTransport(!initialValues.transport);
      }
      if (initialValues.printingForms) {
        setHidePrintingForm(!initialValues.printingForms);
      }
      if (initialValues.shrinkSleeve) {
        setHideShrinkSleeve(!initialValues.shrinkSleeve);
      }
      if (initialValues.punchTool) {
        ///ukoliko je punchTool true u bazi, prikazace se polje za tip tog alata
        setHidePunchTool(!initialValues.punchTool);

        if (initialValues.punchToolType === "nov") {
          //ukoliko je za tip alata odabrano nov, prikazace se i polje za input vrednosti za alat...ta vrednost se ne racuna u kalkulaciju
          setHidePunchToolInput(false);
        } else {
          setHidePunchToolInput(true);
        }
      }
      if (initialValues.rollPacking) {
        setHidePacking(!initialValues.rollPacking);
        if (initialValues.packingType === "kutije") {
          setHideBoxes(false);
        } else {
          setHideBoxes(true);
        }
      }
      ///////////////////////////////////////

      ///pri editu kalkulacije maxwidth se ne podesava ukoliko ne dolazi do izmene masine tako da max width treba podesiti na pocetku
      if (data.machine.maxWidth) {
        setMaxWidth(data.machine.maxWidth);
      }

      fetchSelectedTypeOfLabel();
      fetchSelectedTransport();
      fetchSelectedVarnishingType();
      fetchSelectedVarnishingWay();
      fetchSelectedMachine();
      fetchSelectedFolioMachine();
      fetchFinishingWorkPlasticization();
      // fetchSelectedBoxType();
      fetchSelectedFolioType();
      fetchSelectedClient();
      fetchSelectedHilzna();
      fetchSelectedRewindingMachines();
    }
  }, [data]);

  useEffect(() => {
    if (totalPrice) {
      calculateHandler();
    }
  }, [totalPrice]);

  useEffect(() => {
    if (selectedMachine) {
      if (selectedMachine.name === "MPS") {
        setHideShrinkSleeveSwitch(false);
        if (form.getFieldValue("shrinkSleeve") === true) {
          setHideShrinkSleeve(false);
        } else {
          setHideShrinkSleeve(true);
        }
      } else {
        setHideShrinkSleeveSwitch(true);
        setHideShrinkSleeve(true);
      }
    }
  }, [selectedMachine]);

  useEffect(() => {
    if (selectedTypeOfLabel) {
      // console.log(selectedTypeOfLabel);
      if (selectedTypeOfLabel.calculateGrossUnit === "kg") {
        setIsHiddenGrossMetersPrinting(true);
        setIsHiddenGrossKgPrinting(false);
      } else if (selectedTypeOfLabel.calculateGrossUnit === "m2") {
        setIsHiddenGrossMetersPrinting(false);
        setIsHiddenGrossKgPrinting(true);
      }

      if (!data) {
        ///ukoliko ne postoji data setuje se vrednosti da bi ih setovalo samo kad se kreira nova kalkulacija a ne i kada se edituje postojeca jer ako se menja i kad se edituje postojeca onda ce se i na samom loadu editovati i nece se prikazati podaci iz baze
        if (selectedTypeOfLabel.materialPrice) {
          form.setFieldsValue({
            materialPrice: selectedTypeOfLabel.materialPrice,
          });
        }

        if (selectedTypeOfLabel.degreeOfPaintConsumption) {
          form.setFieldsValue({
            degreeOfPaintConsumption:
              selectedTypeOfLabel.degreeOfPaintConsumption,
          });
        }
      }
    }
  }, [selectedTypeOfLabel]);

  ////postavljanje typeOfLabel namea
  useEffect(() => {
    if (
      allTypesOfLabels &&
      allTypesOfLabels.data &&
      allTypesOfLabels.data.items
    ) {
      if (allTypesOfLabels.data.items) {
        if (allTypesOfLabels) {
          allTypesOfLabels.data.items.forEach((element) => {
            if (form.getFieldValue("typeOfLabel") === element._id) {
              form.setFieldsValue({ typeOfLabel: element.name });
            }
          });
        }
      }
    }
  }, [allTypesOfLabels]);

  useEffect(() => {
    fetchMachines(printType);
  }, [printType]);

  useEffect(() => {
    fetchSelectedFolioMachine();
  }, [machinesForPlasticization]);

  const fetchMachines = async (printType) => {
    const res = await Axios.get(
      `${SERVER_URL}/machinesbyPrintType/${printType}`,
      {
        withCredentials: false,
        headers: { Authorization: `Bearer ${token}` },
      },
    );
    setMachines(res.data);
  };

  const fetchTypesOfVarnishingTypes = async () => {
    let varnishType = "varnishType";
    const res = await Axios.get(
      `${SERVER_URL}/types-of-varnishing-byType/${varnishType}`,
      {
        withCredentials: false,
        headers: { Authorization: `Bearer ${token}` },
      },
    );
    setTypesOfVarnishingTypes(res.data);
  };

  const fetchTypesOfVarnishingWays = async () => {
    let varnishWay = "varnishWay";
    const res = await Axios.get(
      `${SERVER_URL}/types-of-varnishing-byType/${varnishWay}`,
      {
        withCredentials: false,
        headers: { Authorization: `Bearer ${token}` },
      },
    );
    setTypesOfVarnishingWays(res.data);
  };

  const fetchFolioTypes = async (folioWay) => {
    const res = await Axios.get(
      `${SERVER_URL_PRODUCTION}/types-of-folio-byWay/${folioWay}`,
      {
        withCredentials: false,
        headers: { Authorization: `Bearer ${token}` },
      },
    );

    setFolioTypes(res.data);
  };

  const totalValueOfPrintHandler = async () => {
    form.setFieldsValue({
      totalValueOfPrint:
        parseFloat(form.getFieldValue("printPrice")) *
        parseFloat(form.getFieldValue("totalTimeOfPrinting")),
    });
  };

  const fetchFinishingWorkPlasticization = async () => {
    const res = await Axios.get(
      `${SERVER_URL}/finishing-work-name/Plastifikacija`,
      {
        withCredentials: false,
        headers: { Authorization: `Bearer ${token}` },
      },
    );

    setMachinesForPlasticization(res.data[0].machines);
  };

  const fetchSelectedMachine = async () => {
    if (data && data.machine) {
      const res = await Axios.get(`${SERVER_URL}/machine/${data.machine._id}`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${token}` },
      });
      setIsDigitalPrint(res.data.isDigitalPrint);
      setSelectedMachine(res.data);
    }
  };

  const fetchSelectedFolioMachine = async () => {
    if (data && data.folioPrintingMachine && machinesForPlasticization) {
      for (const iterator of machinesForPlasticization) {
        if (iterator._id === data.folioPrintingMachine) {
          setSelectedFolioMachine(iterator);
        }
      }
    }
  };

  const fetchSelectedHilzna = async () => {
    if (data) {
      const res = await Axios.get(
        `${SERVER_URL_PRODUCTION}/hilzna/${data.promerHilzne}`,
        {
          withCredentials: false,
          headers: { Authorization: `Bearer ${token}` },
        },
      );
      setSelectedHilzna(res.data);
    }
  };

  const onChangeHilzna = async (e) => {
    const res = await Axios.get(`${SERVER_URL_PRODUCTION}/hilzna/${e}`, {
      withCredentials: false,
      headers: { Authorization: `Bearer ${token}` },
    });
    setSelectedHilzna(res.data);
  };

  const onChangeFolioPrintingMachine = (e) => {
    if (machinesForPlasticization) {
      for (const iterator of machinesForPlasticization) {
        if (iterator._id === e) {
          setSelectedFolioMachine(iterator);
        }
      }
    }
  };

  const fetchSelectedClient = async () => {
    if (data) {
      const res = await Axios.get(`${SERVER_URL}/clients/${data.client}`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${token}` },
      });

      setSelectedClient(res.data);
    }
  };

  const fetchSelectedRewindingMachines = async () => {
    if (data && data.rewindingValues && data.rewindingValues.length > 0) {
      setSelectedRewindingMachines([]);
      if (data && data.rewindingValues && data.rewindingValues.length > 0) {
        for (const iterator of data.rewindingValues) {
          const res = await Axios.get(
            `${SERVER_URL}/rewinding-machine/${iterator}`,
            {
              withCredentials: false,
              headers: { Authorization: `Bearer ${token}` },
            },
          );
          setSelectedRewindingMachines((current) => [
            ...current,
            res.data.name,
          ]);
        }
      }
    }
  };

  const fetchSelectedTypeOfLabel = async () => {
    const res = await Axios.get(
      `${SERVER_URL}/type-of-label/${data.typeOfLabel._id}`,
      {
        withCredentials: false,
        headers: { Authorization: `Bearer ${token}` },
      },
    );

    setSelectedTypeOfLabel(res.data);
  };

  const fetchSelectedTransport = async () => {
    if (data && data.transportType) {
      const res = await Axios.get(
        `${SERVER_URL}/type-of-transport/${data.transportType}`,
        {
          withCredentials: false,
          headers: { Authorization: `Bearer ${token}` },
        },
      );

      setSelectedTransport(res.data);
    }
  };

  const fetchSelectedFolioType = async () => {
    if (data && data.folioType) {
      const res = await Axios.get(
        `${SERVER_URL_PRODUCTION}/type-of-folio/${data.folioType}`,
        {
          withCredentials: false,
          headers: { Authorization: `Bearer ${token}` },
        },
      );

      setSelectedFolioType(res.data);
    }
  };

  const fetchSelectedVarnishingType = async () => {
    if (data && data.varnishType) {
      const res = await Axios.get(
        `${SERVER_URL}/type-of-varnishing/${data.varnishType}`,
        {
          withCredentials: false,
          headers: { Authorization: `Bearer ${token}` },
        },
      );
      setSelectedVarnishingType(res.data);
    }
  };

  const fetchSelectedVarnishingWay = async () => {
    if (data && data.varnishWay) {
      const res = await Axios.get(
        `${SERVER_URL}/type-of-varnishing/${data.varnishWay}`,
        {
          withCredentials: false,
          headers: { Authorization: `Bearer ${token}` },
        },
      );

      setSelectedVarnishingWay(res.data);
    }
  };

  const onChangeMachineHandler = async (id) => {
    machines.forEach((element) => {
      if (element._id === id) {
        setIsDigitalPrint(element.isDigitalPrint);

        if (element.maxWidth) {
          ///Podesavanje maksimalne sirine i prikaz ako postoji sirina
          setMaxWidth(element.maxWidth);
          if (!element.isDigitalPrint) {
            notification.info({
              message: `Maksimalna širina za ovu mašinu je ${element.maxWidth}mm!`,
              placement: "bottomRight",
              duration: 3,
            });
          }

          if (element && element.maxColorsNum) {
            notification.info({
              message: `Maksimalan broj boja i lakova je ${element.maxColorsNum}!`,
              placement: "bottomRight",
              duration: 3,
            });
          }
        }
        ///set tipova etiketa za svaku izabranu masinu
        setTypesOfLabel(element.typesOfLabel);
      }
    });

    form.setFieldsValue({ typeOfLabel: null });

    const res = await Axios.get(`${SERVER_URL}/machine/${id}`, {
      withCredentials: false,
      headers: { Authorization: `Bearer ${token}` },
    });

    setSelectedMachine(res.data);
  };

  const onChangeClientHandler = async (id) => {
    const res = await Axios.get(`${SERVER_URL}/clients/${id}`, {
      withCredentials: false,
      headers: { Authorization: `Bearer ${token}` },
    });

    setSelectedClient(res.data);
  };

  const onChangeTypeOfLabelHandler = async (id) => {
    const res = await Axios.get(`${SERVER_URL}/type-of-label/${id}`, {
      withCredentials: false,
      headers: { Authorization: `Bearer ${token}` },
    });

    if (res.data) {
      setSelectedTypeOfLabel(res.data);
    }

    form.setFieldsValue({
      materialPrice: res.data.materialPrice,
      degreeOfPaintConsumption: res.data.degreeOfPaintConsumption,
    });

    for (let i = 0; i < res.data.printSpeed.length; i++) {
      if (res.data.printSpeed[i].machine._id === selectedMachine._id) {
        form.setFieldsValue({
          printingSpeed: res.data.printSpeed[i].printSpeed,
        });
      }
    }
  };

  const onChangeRewindingMachines = async () => {
    let rewindingMachines = form.getFieldValue("rewindingValues");
    if (rewindingMachines.length > 0) {
      setSelectedRewindingMachines([]);
      for (const iterator of rewindingMachines) {
        const res = await Axios.get(
          `${SERVER_URL}/rewinding-machine/${iterator}`,
          {
            withCredentials: false,
            headers: { Authorization: `Bearer ${token}` },
          },
        );
        setSelectedRewindingMachines((current) => [...current, res.data.name]);
      }
    }
  };

  const widthHandler = () => {
    const numberOfRows = form.getFieldValue("numberOfRowsToCut");
    const height = form.getFieldValue("height");
    let materialWidth;

    if (numberOfRows > 0) {
      if (selectedMachine) {
        if (selectedTypeOfLabel) {
          if (selectedTypeOfLabel.name.toLowerCase().includes("samolepljivi")) {
            materialWidth = (height + 4) * (numberOfRows - 1) + height + 10;

            materialWidth = Math.ceil(materialWidth / 10) * 10; ///ceil zaokruzuje na prvi veci integer....cela formula zaokruzuje na prvi veci broj koji je deljiv sa 10

            form.setFieldsValue({ materialWidth });
          } else {
            materialWidth = height * numberOfRows + 10;
            materialWidth = Math.ceil(materialWidth / 10) * 10;

            form.setFieldsValue({ materialWidth });
          }
        } else {
          // notification.error({
          //   message: "Izaberite vrstu etikete.",
          //   duration: 2,
          //   placement: "bottomRight",
          // });
        }
      } else {
        // notification.error({
        //   message: "Izaberite mašinu.",
        //   duration: 2,
        //   placement: "bottomRight",
        // });
      }
    } else {
      // notification.error({
      //   message: "Broj redova za odsecanje mora biti veci od 0.",
      //   duration: 2,
      //   placement: "bottomRight",
      // });
    }
  };

  const grossMetersPrintingHandler = () => {
    if (!isDigitalPrint) {
      ///RADI SAMO NA PROMENU CENE MATERIJALA
      ///i na klik dugmeta izracunaj
      ///A NE I NA OSNOVU PROMENE DRUGIH PARAMETARA JER AKO SE TRIGERUJE NA PROMENU NEKOG OD DRUGIH PARAMETARA POKAZUJE POGRESAN REZULATAT JER VREDNOST KASNI
      ///ako fali neki od podataka pisace samo NaN i ne postoji nikakav notification koji objasnjava korisniku zasto polje nije izracunato
      let grossMeters = parseFloat(form.getFieldValue("grossSquareMeters"));
      let materialPrice = parseFloat(form.getFieldValue("materialPrice"));
      // let PriceOfSpecialColors = parseFloat(
      //   form.getFieldValue("totalPriceOfSpecialColors"),
      // );

      // const SpecialColorstotalPrice =
      //   PriceOfSpecialColors === undefined ? 0 : PriceOfSpecialColors;

      let grossMetersPrinting = grossMeters * materialPrice;

      form.setFieldsValue({
        grossMetersPrinting: grossMetersPrinting.toFixed(2),
      });
    } else {
      const grossCurrentMeters = parseFloat(
        form.getFieldValue("grossCurrentMeters"),
      );
      const exchangeRate = parseFloat(form.getFieldValue("exchangeRate"));

      const grossMeterTablePrice = selectedMachine.pricesForDigitalPrint
        .filter((price) => {
          return price.typeOfLabel === selectedTypeOfLabel._id;
        })
        .filter((price) => {
          if (grossCurrentMeters > 750) {
            return price.circulationMax === null;
          }
          return (
            price.circulationMax >= grossCurrentMeters &&
            price.circulationMin <= grossCurrentMeters
          );
        })[0].price;

      // console.log(grossCurrentMeters, grossMeterTablePrice, exchangeRate);

      let grossMetersPrinting =
        grossCurrentMeters *
        (parseFloat(grossMeterTablePrice) / parseFloat(exchangeRate));

      if (isNaN(grossMeterTablePrice)) {
        form.setFieldsValue({ grossMetersPrinting: 0 });
      } else {
        grossMetersPrinting = grossMetersPrinting.toFixed(2);

        form.setFieldsValue({ grossMetersPrinting });
      }
    }
  };

  const grossSquareMetersHandler = () => {
    const currentMeters = parseFloat(form.getFieldValue("grossCurrentMeters"));

    let grossSquareMeters =
      currentMeters * (form.getFieldValue("materialWidth") / 1000);

    grossSquareMeters = grossSquareMeters.toFixed(2);

    if (!isNaN(grossSquareMeters)) {
      form.setFieldsValue({ grossSquareMeters });
    } else {
      form.setFieldsValue({ grossSquareMeters: 0 });
    }
  };

  const grossKgPrintingHandler = () => {
    if (!isDigitalPrint) {
      const grossKg = selectedTypeOfLabel?.grossKgPrice
        ? selectedTypeOfLabel.grossKgPrice
        : 0;
      const materialWidth =
        parseFloat(form.getFieldValue("materialWidth")) / 1000;
      const currentMeters = parseFloat(
        form.getFieldValue("grossCurrentMeters"),
      );
      const grossMeters = materialWidth * currentMeters;
      const gross = (grossMeters * grossKg) / 1000;
      const materialPrice = parseFloat(form.getFieldValue("materialPrice"));
      let grossKgPrinting = gross * materialPrice;

      if (isNaN(grossKgPrinting)) {
        form.setFieldsValue({ grossKgPrinting: 0 });
      } else {
        grossKgPrinting = grossKgPrinting.toFixed(2);

        form.setFieldsValue({ grossKgPrinting });
      }

      if (selectedMachine && selectedMachine.name === "Konica Minolta Rolna") {
        form.setFieldsValue({ materialWidth: 330 });
      }
    } else {
      const grossCurrentMeters = parseFloat(
        form.getFieldValue("grossCurrentMeters"),
      );
      const exchangeRate = parseFloat(form.getFieldValue("exchangeRate"));

      // console.log(selectedMachine.pricesForDigitalPrint);

      let grossMeterTablePrice = selectedMachine.pricesForDigitalPrint
        .filter((price) => {
          return price.typeOfLabel === selectedTypeOfLabel._id;
        })
        .filter((price) => {
          if (grossCurrentMeters > 750) {
            return price.circulationMax === null;
          }
          return (
            price.circulationMax >= grossCurrentMeters &&
            price.circulationMin <= grossCurrentMeters
          );
        });

      if (
        grossMeterTablePrice &&
        grossMeterTablePrice.length > 0 &&
        grossMeterTablePrice[0] &&
        grossMeterTablePrice[0].price
      ) {
        grossMeterTablePrice = grossMeterTablePrice[0].price;

        let grossKgPrinting =
          grossCurrentMeters *
          (parseFloat(grossMeterTablePrice) / parseFloat(exchangeRate));

        if (isNaN(grossMeterTablePrice)) {
          form.setFieldsValue({ grossKgPrinting: 0 });
        } else {
          grossKgPrinting = grossKgPrinting.toFixed(2);

          form.setFieldsValue({ grossKgPrinting });
        }
      }
    }
  };

  const grossKgHandler = () => {
    const grossKgPrice = selectedTypeOfLabel?.grossKgPrice
      ? selectedTypeOfLabel.grossKgPrice
      : 0;
    const materialWidth =
      parseFloat(form.getFieldValue("materialWidth")) / 1000;
    const currentMeters = parseFloat(form.getFieldValue("grossCurrentMeters"));
    const grossMeters = materialWidth * currentMeters;
    let grossKg = (grossMeters * grossKgPrice) / 1000;

    grossKg = grossKg.toFixed(2);

    if (!isNaN(grossKg)) {
      form.setFieldsValue({ grossKg });
    } else {
      form.setFieldsValue({ grossKg: 0 });
    }
  };

  const printingFormsPriceHandler = () => {
    let printingFormsType = form.getFieldValue("printingFormsType");

    if (printingFormsType === "nove") {
      let numberOfSpecialColors = form.getFieldValue("numberOfSpecialColors");
      let numberOfColors = form.getFieldValue("numberOfColors");
      let totalNumberOfColors = parseFloat(numberOfSpecialColors)
        ? parseFloat(numberOfColors) + parseFloat(numberOfSpecialColors)
        : parseFloat(numberOfColors);

      if (
        form.getFieldValue("folioWay") === "hladniFT" &&
        form.getFieldValue("folioPrinting")
      ) {
        totalNumberOfColors++;
      }
      if (form.getFieldValue("varnishing")) {
        totalNumberOfColors++;
      }
      const priceOfPrintingForm = selectedTypeOfLabel.priceOfPrintingForm;
      const printingFormsPrice = totalNumberOfColors * priceOfPrintingForm;

      form.setFieldsValue({ printingFormsPrice });
    } else if (printingFormsType === "postojece") {
      form.setFieldsValue({ printingFormsPrice: 0 });
    }
  };

  const totalBoxesPriceHandler = () => {
    form.setFieldsValue({
      boxesTotalPrice: (
        form.getFieldValue("boxesPrice") * form.getFieldValue("boxesQuantity")
      ).toFixed(2),
    });
  };

  const totalElectricityHandler = () => {
    form.setFieldsValue({
      totalElectricity: (form.getFieldValue("circulation") * 0.00025).toFixed(
        2,
      ),
    });
  };

  const totalTimeOfPrintingHandler = () => {
    const data = form.getFieldsValue();
    const printingSpeed = form.getFieldValue("printingSpeed");
    const calculatedWastePaper = data.calculatedWastePaper;

    const circulation = data.circulation; ///tiraz

    const numberOfRows = data.numberOfRowsToCut; ///broj redova za odsecanje
    let numberOfItems;
    if (
      document.getElementById("numberOfItems").innerText &&
      document.getElementById("numberOfItems").innerText !== "0"
    ) {
      numberOfItems = parseFloat(
        document.getElementById("numberOfItems").innerText,
      );
    } else {
      return;
    }

    if (
      printingSpeed &&
      circulation &&
      numberOfRows &&
      numberOfItems &&
      calculatedWastePaper
    ) {
      let currentMeters =
        circulation / (numberOfItems * numberOfRows) +
        parseFloat(calculatedWastePaper);
      let minGross = currentMeters / printingSpeed;
      let hourGross = minGross / 60;
      if (parseFloat(hourGross.toFixed(2)) > 1) {
        form.setFieldsValue({
          totalTimeOfPrinting: parseFloat(hourGross.toFixed(2)),
        });
      } else {
        form.setFieldsValue({
          totalTimeOfPrinting: 1,
        });
      }
    } else {
      form.setFieldsValue({ totalTimeOfPrinting: "0" });
    }
  };

  const calculateHandler = async () => {
    if (
      document.getElementById("widthTable").innerText === "0" ||
      document.getElementById("assignWidth").innerText === "0" ||
      document.getElementById("numberOfItems").innerText === "0" ||
      document.getElementById("numberOfCylinders").innerText === "0" ||
      document.getElementById("volume").innerText === "0" ||
      document.getElementById("widthTable").innerText === "" ||
      document.getElementById("assignWidth").innerText === "" ||
      document.getElementById("numberOfItems").innerText === "" ||
      document.getElementById("numberOfCylinders").innerText === "" ||
      document.getElementById("volume").innerText === ""
    ) {
      notification.error({
        message: "Odredite odgovarajuću širinu etikete!",
        duration: 2,
        placement: "bottomRight",
      });
    } else {
      if (selectedMachine.name !== "Konica Minolta Rolna") {
        let tableDataVariable = [];
        let keys = [
          "material",
          "work",
          "circulation",
          "pricePerPiece",
          "pricePerPieceRsd",
          "circulationRsd",
          "workRsd",
          "materialRsd",
          "basicPrice",
        ];

        const circulation =
          parseFloat(form.getFieldValue("totalWorkPrice")) +
          parseFloat(form.getFieldValue("totalPrice"));
        const pricePerPiece =
          (parseFloat(form.getFieldValue("totalWorkPrice")) +
            parseFloat(form.getFieldValue("totalPrice"))) /
          parseFloat(form.getFieldValue("circulation"));
        const materialRsd =
          parseFloat(form.getFieldValue("totalPrice")) *
          parseFloat(form.getFieldValue("exchangeRate"));
        const workRsd =
          parseFloat(form.getFieldValue("totalWorkPrice")) *
          parseFloat(form.getFieldValue("exchangeRate"));
        const circulationRsd =
          parseFloat(circulation) *
          parseFloat(form.getFieldValue("exchangeRate"));
        const pricePerPieceRsd =
          ((parseFloat(form.getFieldValue("totalWorkPrice")) +
            parseFloat(form.getFieldValue("totalPrice"))) /
            parseFloat(form.getFieldValue("circulation"))) *
          parseFloat(form.getFieldValue("exchangeRate"));

        for (let i = 0; i < 6; i++) {
          let value;
          tableDataVariable[i] = {};
          keys.map((key) => {
            if (i === 0) value = 0;
            if (i === 1) value = -0.05;
            if (i === 2) value = 0.1;
            if (i === 3) value = 0.15;
            if (i === 4) value = 0.3;
            if (i === 5) value = 0.4;
            if (key === "material") {
              let result = (
                parseFloat(form.getFieldValue("totalPrice")) +
                parseFloat(form.getFieldValue("totalPrice")) * value
              ).toFixed(2);
              tableDataVariable[i][key] = result;
            } else if (key === "work") {
              let result = (
                parseFloat(form.getFieldValue("totalWorkPrice")) +
                parseFloat(form.getFieldValue("totalWorkPrice")) * value
              ).toFixed(2);
              tableDataVariable[i][key] = result;
            } else if (key === "circulation") {
              let result = (circulation + circulation * value).toFixed(2);
              tableDataVariable[i][key] = result;
            } else if (key === "pricePerPiece") {
              let result = pricePerPiece + pricePerPiece * value;
              result = (parseFloat(result) * 1000).toFixed(2);
              tableDataVariable[i][key] = result;
            } else if (key === "materialRsd") {
              let result = (materialRsd + materialRsd * value).toFixed(2);
              tableDataVariable[i][key] = result;
            } else if (key === "workRsd") {
              let result = (workRsd + workRsd * value).toFixed(2);
              tableDataVariable[i][key] = result;
            } else if (key === "circulationRsd") {
              let result = (circulationRsd + circulationRsd * value).toFixed(2);
              tableDataVariable[i][key] = result;
            } else if (key === "pricePerPieceRsd") {
              let result = (
                pricePerPieceRsd +
                pricePerPieceRsd * value
              ).toFixed(2);
              tableDataVariable[i][key] = result;
            } else {
              if (i === 0) tableDataVariable[i][key] = "/";
              if (i === 1) tableDataVariable[i][key] = "-5%";
              if (i === 2) tableDataVariable[i][key] = "10%";
              if (i === 3) tableDataVariable[i][key] = "15%";
              if (i === 4) tableDataVariable[i][key] = "30%";
              if (i === 5) tableDataVariable[i][key] = "40%";
            }
          });
        }
        setTableData(tableDataVariable);

        let prices = [];
        if (tableDataVariable && tableDataVariable.length > 0) {
          for (let i = 0; i < tableDataVariable.length; i++) {
            prices.push(tableDataVariable[i].pricePerPieceRsd);
          }
        }

        setPricesForSelection(prices);
        setIsVisibleTable(true);
      } else {
        let tableDataVariable = [];
        let keys = [
          "circulation",
          "pricePerPiece",
          "pricePerPieceRsd",
          "circulationRsd",
          "basicPrice",
        ];

        const pricePerM = await Axios.get(
          `${SERVER_URL}/machine-price/${
            selectedMachine._id
          }?meters=${form.getFieldValue("grossCurrentMeters")}&&typeoflabel=${
            selectedTypeOfLabel._id
          }`,
          {
            withCredentials: false,
            headers: { Authorization: `Bearer ${token}` },
          },
        );

        // console.log(parseFloat(form.getFieldValue("totalWorkPrice")));
        // console.log(parseFloat(form.getFieldValue("totalPrice")));
        // console.log(parseFloat(circulation));
        // console.log(parseFloat(form.getFieldValue("exchangeRate")));
        // console.log(pricePerM.data);
        // console.log(parseFloat(form.getFieldValue("grossCurrentMeters")));

        const circulation =
          parseFloat(form.getFieldValue("totalWorkPrice")) +
          parseFloat(form.getFieldValue("totalPrice"));
        const pricePerPiece =
          (parseFloat(form.getFieldValue("totalWorkPrice")) +
            parseFloat(form.getFieldValue("totalPrice"))) /
          parseFloat(form.getFieldValue("circulation"));
        const materialRsd =
          parseFloat(form.getFieldValue("totalPrice")) *
          parseFloat(form.getFieldValue("exchangeRate"));
        const workRsd =
          parseFloat(form.getFieldValue("totalWorkPrice")) *
          parseFloat(form.getFieldValue("exchangeRate"));
        const circulationRsd =
          parseFloat(circulation) *
          parseFloat(form.getFieldValue("exchangeRate"));
        const pricePerPieceRsd =
          circulationRsd / parseFloat(form.getFieldValue("circulation"));

        // const subcontractors = form.getFieldValue("subcontractors");
        // const subcontractorInput = form.getFieldValue("subcontractorInput");
        // const folioPrice = parseFloat(form.getFieldValue("folioPrice"));
        for (let i = 0; i < 6; i++) {
          let value;
          tableDataVariable[i] = {};
          keys.map((key) => {
            if (i === 0) value = 0;
            if (i === 1) value = -0.05;
            if (i === 2) value = 0.1;
            if (i === 3) value = 0.15;
            if (i === 4) value = 0.3;
            if (i === 5) value = 0.4;
            if (key === "material") {
              let result = (
                parseFloat(form.getFieldValue("totalPrice")) +
                parseFloat(form.getFieldValue("totalPrice")) * value
              ).toFixed(2);
              tableDataVariable[i][key] = result;
            } else if (key === "work") {
              let result = (
                parseFloat(form.getFieldValue("totalWorkPrice")) +
                parseFloat(form.getFieldValue("totalWorkPrice")) * value
              ).toFixed(2);
              tableDataVariable[i][key] = result;
            } else if (key === "circulation") {
              let result = (circulation + circulation * value).toFixed(2);
              tableDataVariable[i][key] = result;
            } else if (key === "pricePerPiece") {
              let result = pricePerPiece + pricePerPiece * value;
              result = (parseFloat(result) * 1000).toFixed(2);
              tableDataVariable[i][key] = result;
            } else if (key === "materialRsd") {
              let result = (materialRsd + materialRsd * value).toFixed(2);
              tableDataVariable[i][key] = result;
            } else if (key === "workRsd") {
              let result = (workRsd + workRsd * value).toFixed(2);
              tableDataVariable[i][key] = result;
            } else if (key === "circulationRsd") {
              //   let result = (
              //     pricePerPieceRsd * form.getFieldValue("circulation")
              //   ).toFixed(2);
              let result = parseFloat(
                (circulationRsd + circulationRsd * value).toFixed(2),
              );

              // if (subcontractors && subcontractorInput) {
              // 	result += parseFloat(subcontractorInput);
              // }
              // if (folioPrice) {
              // 		result += parseFloat(folioPrice);

              // }
              tableDataVariable[i][key] = result;
            } else if (key === "pricePerPieceRsd") {
              let result = (
                pricePerPieceRsd +
                pricePerPieceRsd * value
              ).toFixed(2);
              tableDataVariable[i][key] = result;
            } else {
              if (i === 0) tableDataVariable[i][key] = "/";
              if (i === 1) tableDataVariable[i][key] = "-5%";
              if (i === 2) tableDataVariable[i][key] = "10%";
              if (i === 3) tableDataVariable[i][key] = "15%";
              if (i === 4) tableDataVariable[i][key] = "30%";
              if (i === 5) tableDataVariable[i][key] = "40%";
            }
          });
        }
        setTableData(tableDataVariable);

        let prices = [];
        if (tableDataVariable && tableDataVariable.length > 0) {
          for (let i = 0; i < tableDataVariable.length; i++) {
            prices.push(tableDataVariable[i].pricePerPieceRsd);
          }
        }

        setPricesForSelection(prices);
        setIsVisibleTable(true);
      }
    }
  };

  const totalWorkPriceHandler = () => {
    if (!isDigitalPrint) {
      const transportTotalPrice = parseFloat(
        form.getFieldValue("transportInput"),
      );
      const subcontractorTotalPrice = parseFloat(
        form.getFieldValue("subcontractorInput"),
      );
      const shrinkSleeveInput = parseFloat(
        form.getFieldValue("shrinkSleeveInput"),
      );
      const designPrice = parseFloat(form.getFieldValue("designInput"));
      let timeOfPrinting = parseFloat(
        form.getFieldValue("totalTimeOfPrinting"),
      );
      let printPrice = parseFloat(form.getFieldValue("printPrice"));
      let totalTimeOfPrinting = timeOfPrinting * printPrice;
      let totalPreparation = parseFloat(
        form.getFieldValue("preparationOfPrintingPresses"),
      );
      let totalElectricity = form.getFieldValue("totalElectricity");

      let totalWorkPrice =
        totalTimeOfPrinting + totalPreparation + parseFloat(totalElectricity);

      if (
        !isNaN(subcontractorTotalPrice) &&
        form.getFieldValue("subcontractors")
      ) {
        totalWorkPrice += subcontractorTotalPrice;
      }

      if (!isNaN(shrinkSleeveInput) && form.getFieldValue("shrinkSleeve")) {
        totalWorkPrice += shrinkSleeveInput;
      }

      if (
        !isNaN(transportTotalPrice) &&
        form.getFieldValue("transport") &&
        form.getFieldValue("transportType") !== "kupacdolazi"
      ) {
        totalWorkPrice += transportTotalPrice;
      }

      if (!isNaN(designPrice) && form.getFieldValue("design")) {
        totalWorkPrice += designPrice;
      }

      totalWorkPrice = totalWorkPrice.toFixed(2);
      if (isNaN(totalWorkPrice)) {
        form.setFieldsValue({ totalWorkPrice: 0 });
      } else {
        form.setFieldsValue({ totalWorkPrice });
      }
    } else {
      const subcontractorTotalPrice = parseFloat(
        form.getFieldValue("subcontractorInput"),
      );
      const totalElectricity = parseFloat(
        form.getFieldValue("totalElectricity"),
      );

      let totalWorkPrice = 0;

      if (subcontractorTotalPrice && !isNaN(subcontractorTotalPrice)) {
        totalWorkPrice += subcontractorTotalPrice;
      }

      if (totalElectricity && !isNaN(totalElectricity)) {
        totalWorkPrice += totalElectricity;
      }

      // console.log(totalWorkPrice);

      if (isNaN(totalWorkPrice)) {
        form.setFieldsValue({ totalWorkPrice: 0 });
      } else {
        totalWorkPrice = parseFloat(totalWorkPrice).toFixed(2);
        form.setFieldsValue({ totalWorkPrice });
      }
    }
  };

  const totalPriceOfSpecialColorsHandler = () => {
    const materialWidth =
      parseFloat(form.getFieldValue("materialWidth")) / 1000;
    const currentMeters = parseFloat(form.getFieldValue("grossCurrentMeters"));

    const grossMeters = materialWidth * currentMeters;
    const degreeOfSpecialPaintConsumption = form.getFieldValue(
      "degreeOfSpecialPaintConsumption",
    )
      ? parseFloat(form.getFieldValue("degreeOfSpecialPaintConsumption")) / 1000
      : 0;
    // const colorPrice = parseFloat(form.getFieldValue("colorPrice"));

    const colorQuantity = grossMeters * degreeOfSpecialPaintConsumption;
    let totalPriceOfSpecialColors = colorQuantity * 17; ////to 17 je fiksirano colorPrice u prethodnoj verziji kalkulatora, 17 ako je rolna a 8 ako je tabak

    totalPriceOfSpecialColors = totalPriceOfSpecialColors.toFixed(2);

    if (isNaN(totalPriceOfSpecialColors)) {
      form.setFieldsValue({ totalPriceOfSpecialColors: 0 });
    } else {
      form.setFieldsValue({ totalPriceOfSpecialColors });
    }

    totalPriceHandler();
  };

  const grossCurrentMetersHandler = () => {
    let currentMeters =
      parseFloat(form.getFieldValue("circulation")) /
        (parseFloat(document.getElementById("numberOfItems").innerText) *
          parseFloat(form.getFieldValue("numberOfRowsToCut"))) +
      parseFloat(form.getFieldValue("calculatedWastePaper"));

    if (isNaN(currentMeters)) {
      form.setFieldsValue({ grossCurrentMeters: 0 });
    } else {
      form.setFieldsValue({ grossCurrentMeters: currentMeters.toFixed(2) });
    }
  };

  const preparationOfPrintingPressesHandler = () => {
    let preparationOfPrintingPressesHours = form.getFieldValue(
      "preparationOfPrintingPressesHours",
    );
    let preparationOfPrintingPressesPrice = form.getFieldValue(
      "preparationOfPrintingPressesPrice",
    );

    const preparationOfPrintingPresses =
      preparationOfPrintingPressesHours * preparationOfPrintingPressesPrice;

    if (isNaN(preparationOfPrintingPresses)) {
      form.setFieldsValue({ preparationOfPrintingPresses: 0 });
    } else {
      form.setFieldsValue({ preparationOfPrintingPresses });
    }
  };

  const onChangeFolioType = (folioTypeId) => {
    for (const iterator of folioTypes) {
      if (iterator._id === folioTypeId) {
        form.setFieldsValue({ folioPrice: iterator.pricePerSquareMeter });
        setSelectedFolioType(iterator);
      }
    }
  };

  const totalPriceHandler = () => {
    let totalPrice = 0;
    ///////svi parametri koje se dodaju na totalprice (i parametri koji su potrebni za njhivo racunanje) ukoliko postoje/////////////////////
    // const grossMetersFolio = form.getFieldValue("grossMetersFolio");
    // const folioPrice = form.getFieldValue("folioPrice");
    // const grossFolioPrice =
    //   parseFloat(grossMetersFolio) * parseFloat(folioPrice);

    const numberOfColors = form.getFieldValue("numberOfColors");
    const numberOfSpecialColors = form.getFieldValue("numberOfSpecialColors");
    const colorPrice = parseFloat(form.getFieldValue("priceOfColors"));
    const quantityOfSpecialColors = form.getFieldValue(
      "quantityOfSpecialColors",
    );
    const priceOfSpecialColors = form.getFieldValue("priceOfSpecialColors");
    const totalSpecialColorsPrice =
      parseFloat(quantityOfSpecialColors) * parseFloat(priceOfSpecialColors);
    const totalVarnishingPrice = parseFloat(
      form.getFieldValue("varnishTotalPrice"),
    );
    const totalNumberOfColors = !isNaN(numberOfSpecialColors)
      ? parseFloat(numberOfColors) + parseFloat(numberOfSpecialColors)
      : parseFloat(numberOfColors);
    const priceOfPrintingForm = selectedTypeOfLabel?.priceOfPrintingForm;

    // const printingFormsPrice =
    //   parseFloat(totalNumberOfColors) * parseFloat(priceOfPrintingForm);
    // const designPrice = parseFloat(form.getFieldValue("designInput"));
    const boxesPrice = form.getFieldValue("boxesPrice");
    const hilznePrice = parseFloat(form.getFieldValue("hilznePrice"));
    const boxesQuantity = form.getFieldValue("boxesQuantity");
    const boxesTotalPrice = parseFloat(boxesQuantity) * parseFloat(boxesPrice);
    const vacuumSealingTotal = parseFloat(
      form.getFieldValue("vacuumSealingTotal"),
    );

    const grossMetersPrinting = parseFloat(
      form.getFieldValue("grossMetersPrinting"),
    );
    const shrinkSleeveInput = parseFloat(
      form.getFieldValue("shrinkSleeveInput"),
    );

    const subcontractorInput = parseFloat(
      form.getFieldValue("subcontractorInput"),
    );

    const totalElectricity = parseFloat(form.getFieldValue("totalElectricity"));

    const grossKgPrinting = parseFloat(form.getFieldValue("grossKgPrinting"));

    const totalPriceOfSpecialColors = parseFloat(
      form.getFieldValue("totalPriceOfSpecialColors"),
    );

    const folioTotal = parseFloat(form.getFieldValue("folioTotal"));
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    if (selectedTypeOfLabel) {
      if (selectedTypeOfLabel.name) {
        // if (!isNaN(grossFolioPrice)) {
        //   ////da li postoji plastifikacija i da li je broj
        //   totalPrice += grossFolioPrice;
        // }
        if (
          !isNaN(colorPrice) &&
          selectedMachine.name !== "Konica Minolta Rolna"
        ) {
          /////da li postoje broj boja i vrednost boja i masina nije konica ako postoji dodaje se total priceu
          totalPrice += colorPrice;
        }

        if (
          !isNaN(totalSpecialColorsPrice) &&
          selectedMachine.name !== "Konica Minolta Rolna"
        ) {
          /////da li postoje specijalni broj boja i cena spec boja i masina nije konica ako postoji dodaje se total priceu
          totalPrice += totalSpecialColorsPrice;
        }

        if (!isNaN(totalVarnishingPrice) && form.getFieldValue("varnishing")) {
          ///ako postoji lakiranje i cena lakiranja, cenu lakiranja sracunaj u totalPrice
          totalPrice += totalVarnishingPrice;
        }

        // if (!isNaN(printingFormsPrice) && form.getFieldValue("printingForms")) {
        //   totalPrice += printingFormsPrice;
        // }

        if (!isNaN(hilznePrice) && form.getFieldValue("hilznePrice")) {
          totalPrice += hilznePrice;
        }

        if (!isNaN(totalPriceOfSpecialColors)) {
          totalPrice += parseFloat(totalPriceOfSpecialColors);
        }

        // if (!isNaN(shrinkSleeveInput) && form.getFieldValue("shrinkSleeve")) {
        //   totalPrice += shrinkSleeveInput;
        // }

        if (
          !isNaN(boxesTotalPrice) &&
          form.getFieldValue("packingType") === "kutije" &&
          form.getFieldValue("rollPacking")
        ) {
          totalPrice += boxesTotalPrice;
        }

        if (
          !isNaN(vacuumSealingTotal) &&
          form.getFieldValue("packingType") === "vakumiranje" &&
          form.getFieldValue("rollPacking")
        ) {
          totalPrice += vacuumSealingTotal;
        }

        if (!isNaN(folioTotal) && form.getFieldValue("folioPrinting")) {
          totalPrice += folioTotal;
        }

        if (!isNaN(grossKgPrinting) && isHiddenGrossMetersPrinting) {
          totalPrice += grossKgPrinting;
        }
        if (!isNaN(grossMetersPrinting) && isHiddenGrossKgPrinting) {
          totalPrice += grossMetersPrinting;
        }

        // if (
        //   !isNaN(subcontractorInput) &&
        //   !hideSubcontractors &&
        //   isDigitalPrint
        // ) {
        //   totalPrice += subcontractorInput;
        // }

        // if (!isNaN(totalElectricity) && isDigitalPrint) {
        //   totalPrice += totalElectricity;
        // }

        // if(selectedMachine.name === "Konica Minolta Rolna")
        // {
        // 	const grossMeterTablePrice = machines
        //     .filter((machine) => {
        //       return machine.name === 'Konica Minolta Rolna';
        //     })[0]
        //     .pricesForDigitalPrint.filter((price) => {
        //       return price.typeOfLabel === selectedTypeOfLabel._id;
        //     })
        //     .filter((price) => {
        //       if (grossCurrentMeters > 750) {
        //         return price.circulationMax === null;
        //       }
        //       return price.circulationMax >= grossCurrentMeters && price.circulationMin <= grossCurrentMeters;
        //     })[0].price;

        // 	totalPrice += grossMeterTablePrice;

        // }
      }
    }
    totalPrice = totalPrice.toFixed(2);

    if (isNaN(totalPrice)) {
      form.setFieldsValue({ totalPrice: 0 });
    } else {
      form.setFieldsValue({ totalPrice });
    }
    setTotalPrice(totalPrice);
  };

  const priceOfColorsHandler = () => {
    const materialWidth =
      parseFloat(form.getFieldValue("materialWidth")) / 1000;
    const currentMeters = parseFloat(form.getFieldValue("grossCurrentMeters"));

    const grossMeters = materialWidth * currentMeters;
    const degreeOfPaintConsumption = form.getFieldValue(
      "degreeOfPaintConsumption",
    )
      ? parseFloat(form.getFieldValue("degreeOfPaintConsumption")) / 1000
      : 0;
    // const colorPrice = parseFloat(form.getFieldValue("colorPrice"));

    const colorQuantity = grossMeters * degreeOfPaintConsumption;
    let priceOfColors = colorQuantity * 17; ////to 17 je fiksirano colorPrice u prethodnoj verziji kalkulatora, 17 ako je rolna a 8 ako je tabak

    priceOfColors = priceOfColors.toFixed(2);

    if (
      isNaN(priceOfColors) ||
      isNaN(form.getFieldValue("numberOfColors")) ||
      form.getFieldValue("numberOfColors") === 0
    ) {
      form.setFieldsValue({ priceOfColors: 0 });
    } else {
      form.setFieldsValue({ priceOfColors });
    }

    totalPriceHandler();
  };

  const colorQuantityHandler = () => {
    const materialWidth =
      parseFloat(form.getFieldValue("materialWidth")) / 1000;
    const currentMeters = parseFloat(form.getFieldValue("grossCurrentMeters"));
    const grossMeters = materialWidth * currentMeters;
    const degreeOfPaintConsumption = form.getFieldValue(
      "degreeOfPaintConsumption",
    )
      ? parseFloat(form.getFieldValue("degreeOfPaintConsumption")) / 1000
      : 0;
    let colorQuantity = grossMeters * degreeOfPaintConsumption;

    colorQuantity = colorQuantity.toFixed(2);

    if (!isNaN(colorQuantity)) {
      form.setFieldsValue({ colorQuantity });
    } else {
      form.setFieldsValue({ colorQuantity: 0 });
    }
  };

  const quantityOfSpecialColorsHandler = () => {
    const materialWidth =
      parseFloat(form.getFieldValue("materialWidth")) / 1000;
    const currentMeters = parseFloat(form.getFieldValue("grossCurrentMeters"));
    const grossMeters = materialWidth * currentMeters;
    const degreeOfSpecialPaintConsumption = form.getFieldValue(
      "degreeOfSpecialPaintConsumption",
    )
      ? parseFloat(form.getFieldValue("degreeOfSpecialPaintConsumption")) / 1000
      : 0;
    let quantityOfSpecialColors = grossMeters * degreeOfSpecialPaintConsumption;

    quantityOfSpecialColors = quantityOfSpecialColors.toFixed(2);

    if (!isNaN(quantityOfSpecialColors)) {
      form.setFieldsValue({ quantityOfSpecialColors });
    } else {
      form.setFieldsValue({ quantityOfSpecialColors: 0 });
    }
  };

  const createPdfHandler = async () => {
    const dataForm = form.getFieldsValue();

    if (!dataForm.machine) {
      notification.error({
        message: "Odaberite mašinu.",
        duration: 2,
        placement: "bottomRight",
      });
      return;
    }
    if (!dataForm.typeOfLabel) {
      notification.error({
        message: "Odaberite vrstu etikete.",
        duration: 2,
        placement: "bottomRight",
      });
      return;
    }
    if (!dataForm.businessName) {
      notification.error({
        message: "Unesite naziv posla.",
        duration: 2,
        placement: "bottomRight",
      });
      return;
    }
    if (!dataForm.client) {
      notification.error({
        message: "Unesite naziv poručioca.",
        duration: 2,
        placement: "bottomRight",
      });
      return;
    }
    if (!dataForm.calculatedWastePaper) {
      notification.error({
        message: "Unesite kalkulisanu makulaturu.",
        duration: 2,
        placement: "bottomRight",
      });
      return;
    }
    if (!dataForm.totalPrice) {
      notification.error({
        message: "Izračunajte kalkulaciju pre štampanja.",
        duration: 2,
        placement: "bottomRight",
      });
      return;
    }

    const response = await Axios.post(
      `${SERVER_URL}/create-pdf`,
      {
        form: {
          ...dataForm,
          table: JSON.stringify(tableData),
          selectedMachine,
          selectedTypeOfLabel,
          selectedHilzna,
          selectedVarnishingType,
          selectedVarnishingWay,
          selectedRewindingMachines,
          selectedTransport,
          selectedFolioType,
          userId: currentuser.data.id,
          assignWidthLabel: document.getElementById("assignWidth").innerHTML,
          widthLabel: document.getElementById("widthTable").innerHTML,
          numberOfItemsLabel:
            document.getElementById("numberOfItems").innerHTML,
          numberOfCylindersLabel:
            document.getElementById("numberOfCylinders").innerHTML,
          volumeLabel: document.getElementById("volume").innerHTML,
        },
      },
      {
        withCredentials: false,
        headers: {
          Authorization: `Bearer ${currentuser.data.token}`,
          "Content-Type": "application/json",
        },
      },
    );

    if (response.data.filename) {
      const pdfResponse = await Axios.get(
        `${SERVER_URL}/get-pdf?filename=${response.data.filename}`,
        {
          responseType: "arraybuffer",
          withCredentials: false,
          headers: { Authorization: `Bearer ${currentuser.data.token}` },
        },
      );
      const pathArr = response.data.filename.split("\\");
      const blob = new Blob([pdfResponse.data], { type: "application/pdf" });
      let a = document.createElement("a");
      let url = window.URL.createObjectURL(blob);
      a.href = url;
      a.download = pathArr[pathArr.length - 1];
      a.click();
      window.URL.revokeObjectURL(url);
    }
  };

  const onCalculationVefication = async () => {
    let values = form.getFieldsValue();
    let valuesToVerify = {};

    if (!tableData) {
      notification.error({
        message: "Prvo izračunajte kalkulaciju!",
        duration: 5,
        placement: "bottomRight",
      });
      return;
    }
    if (!values.selectedPrice) {
      notification.error({
        message: "Niste odabrali prodajnu cenu!",
        duration: 2,
        placement: "bottomRight",
      });
      return;
    }

    if (!values.priceInCurrency) {
      notification.error({
        message: "Niste odabrali valutu prodajne cene!",
        duration: 2,
        placement: "bottomRight",
      });
      return;
    }

    if (values.selectedPrice) {
      valuesToVerify.offerStatus = "Verifikovana";
    }

    valuesToVerify.selectedPrice = values.selectedPrice;
    valuesToVerify.priceInCurrency = values.priceInCurrency;
    // valuesToVerify.priceRSD = tableData[0].pricePerPieceRsd;
    // valuesToVerify.priceEUR = tableData[0].pricePerPiece;

    await Axios.put(`${SERVER_URL}/calculations/${data._id}`, valuesToVerify, {
      withCredentials: false,
      headers: { Authorization: `Bearer ${token}` },
    });

    notification.success({
      message: "Kalkulacija je verifikovana!",
      duration: 2,
      placement: "bottomRight",
    });

    setTimeout(() => {
      window.location.reload();
    }, 1000);
  };

  const numberOfRowsToCutHandler = () => {
    if (selectedMachine) {
      if (selectedTypeOfLabel) {
        let height = form.getFieldValue("height");
        let maxWidth = selectedMachine.maxWidth;
        let numberOfRowsToCut;
        ///10mm
        ///4mm

        if (selectedTypeOfLabel.name.includes("samolepljivi")) {
          numberOfRowsToCut = (maxWidth - 10) / (height + 4);
          numberOfRowsToCut = Math.floor(numberOfRowsToCut); ///zaokruzuje broj na prvi manji integer...npr 4.03 ili 4.94 zaokruzi na 4

          if (isNaN(numberOfRowsToCut)) {
            form.setFieldsValue({ numberOfRowsToCut: 0 });
          } else {
            form.setFieldsValue({ numberOfRowsToCut });
          }
        } else {
          numberOfRowsToCut = (maxWidth - 10) / height;
          numberOfRowsToCut = Math.floor(numberOfRowsToCut);

          if (isNaN(numberOfRowsToCut)) {
            form.setFieldsValue({ numberOfRowsToCut: 0 });
          } else {
            form.setFieldsValue({ numberOfRowsToCut });
          }
        }
      } else {
        // notification.error({
        //   message: "Izaberite vrstu etikete",
        //   duration: 2,
        //   placement: "bottomRight",
        // });
      }
    } else {
      // notification.error({
      //   message: "Izaberite mašinu",
      //   duration: 2,
      //   placement: "bottomRight",
      // });
    }
  };

  const numberOfRowsToCutZeroHandler = () => {
    // form.setFieldsValue({ numberOfRowsToCut: null });
  };

  const hilznePriceHandler = () => {
    ////preracunavanje kolicine i ukupne cene hilzni za slucaj da se nesto promenilo od parametara koji racunaju hilzne
    let onehilznePrice = selectedHilzna?.price;

    let height = form.getFieldValue("height");
    let numRollsNeeded;
    let numOfHilzneNeeded;
    let hilznePrice = 0;

    if (
      onehilznePrice &&
      selectedHilzna.width &&
      parseFloat(form.getFieldValue("rollPieces")) &&
      height &&
      parseFloat(form.getFieldValue("circulation"))
    ) {
      height = (height * 1.1).toFixed(2);

      numRollsNeeded =
        parseFloat(form.getFieldValue("circulation")) /
        parseFloat(form.getFieldValue("rollPieces"));

      numOfHilzneNeeded = (numRollsNeeded * height) / selectedHilzna.width;
      numOfHilzneNeeded = parseFloat(numOfHilzneNeeded);

      numOfHilzneNeeded = Math.ceil(numOfHilzneNeeded);
      hilznePrice = onehilznePrice * numOfHilzneNeeded;
    }

    hilznePrice = hilznePrice / parseFloat(form.getFieldValue("exchangeRate"));
    hilznePrice = parseFloat(hilznePrice).toFixed(2);

    if (isNaN(hilznePrice)) {
      form.setFieldsValue({ hilznePrice: 0 });
    } else {
      form.setFieldsValue({ hilznePrice });
    }
  };

  const varnishTotalPriceHandler = () => {
    const varnishQuantity = parseFloat(form.getFieldValue("varnishQuantity"));
    const varnishPrice = parseFloat(form.getFieldValue("varnishPrice"));

    let varnishTotalPrice = varnishQuantity * varnishPrice;

    varnishTotalPrice = varnishTotalPrice.toFixed(2);

    if (isNaN(varnishTotalPrice)) {
      form.setFieldsValue({ varnishTotalPrice: 0 });
    } else {
      form.setFieldsValue({ varnishTotalPrice });
    }
  };

  const onChangeVarnishingTypes = async (e) => {
    const res = await Axios.get(`${SERVER_URL}/type-of-varnishing/${e}`, {
      withCredentials: false,
      headers: { Authorization: `Bearer ${token}` },
    });

    setSelectedVarnishingType(res.data);
  };

  const onChangeVarnishingWays = async (e) => {
    const res = await Axios.get(`${SERVER_URL}/type-of-varnishing/${e}`, {
      withCredentials: false,
      headers: { Authorization: `Bearer ${token}` },
    });

    setSelectedVarnishingWay(res.data);
  };

  const varnishQuantityHandler = () => {
    const currentMeters = parseFloat(form.getFieldValue("grossCurrentMeters"));

    const grossMeters =
      currentMeters * (parseFloat(form.getFieldValue("materialWidth")) / 1000);

    const degreeOfVarnishConsumption =
      parseFloat(form.getFieldValue("degreeOfVarnishConsumption")) / 1000;
    let varnishQuantity = grossMeters * degreeOfVarnishConsumption;

    varnishQuantity = varnishQuantity.toFixed(2);

    if (!isNaN(varnishQuantity)) {
      form.setFieldsValue({ varnishQuantity });
    } else {
      form.setFieldsValue({ varnishQuantity: 0 });
    }
  };

  const onChangeTransportType = (id) => {
    if (
      typesOfTransport &&
      typesOfTransport.data &&
      typesOfTransport.data.items
    ) {
      for (const iterator of typesOfTransport.data.items) {
        if (iterator._id === id) {
          setHideTransportInput(!iterator.isPaying);
          setSelectedTransport(iterator);
        }
      }
    }
  };

  const onChangePackingType = (type) => {
    if (type === "kutije") {
      setHideBoxes(false);
    } else {
      setHideBoxes(true);
    }

    if (type === "vakumiranje") {
      setHideVacuumSealing(false);
    } else {
      setHideVacuumSealing(true);
    }
  };

  const onChangeGraphicPreparation = (type) => {
    if (type === "Nova") {
      form.setFieldsValue({ sendAuthentication: true });
      setIsDisabledAuthentication(true);
    } else {
      form.setFieldsValue({ sendAuthentication: null });
      setIsDisabledAuthentication(false);
    }

    setHideSendAuthenticationMail(!form.getFieldValue("sendAuthentication"));
  };

  const onChangeVarnishCoverageHandler = (e) => {
    if (e === "potpuno") {
      form.setFieldsValue({ degreeOfVarnishConsumption: 1.5 });
    } else if (e === "parcijalno") {
      form.setFieldsValue({ degreeOfVarnishConsumption: 0.8 });
    }
  };

  const onChangePunchToolType = (e) => {
    if (e === "nov") {
      setHidePunchToolInput(false);
    } else {
      setHidePunchToolInput(true);
    }
  };

  const vacuumSealingTotalHandler = () => {
    const vacuumSealingQuantity = parseFloat(
      form.getFieldValue("vacuumSealingQuantity"),
    );
    const vacuumSealingPrice = parseFloat(
      form.getFieldValue("vacuumSealingPrice"),
    );
    let vacuumSealingTotal = vacuumSealingQuantity * vacuumSealingPrice;

    vacuumSealingTotal = vacuumSealingTotal.toFixed(2);

    if (isNaN(vacuumSealingTotal)) {
      form.setFieldsValue({ vacuumSealingTotal: 0 });
    } else {
      form.setFieldsValue({ vacuumSealingTotal });
    }
  };

  const onChangeBoxType = async () => {
    const res = await Axios.get(
      `${SERVER_URL_PRODUCTION}/box/${form.getFieldValue("boxType")}`,
      {
        withCredentials: false,
        headers: { Authorization: `Bearer ${token}` },
      },
    );

    if (!isNaN(res.data.price)) {
      form.setFieldsValue({ boxesPrice: res.data.price });
    }
  };

  const handleCalculateShrinkSleeveInput = () => {
    const brutoTekuciMetri = parseFloat(
      form.getFieldValue("grossCurrentMeters"),
    );
    const brzinaStampe = parseFloat(form.getFieldValue("printingSpeed"));

    const vremePripremeStamparskeMasine = parseFloat(
      form.getFieldValue("preparationOfPrintingPressesHours"),
    );

    const shrinkSleeveInput = (brutoTekuciMetri / 60 / 60 + 1) * 30;

    form.setFieldsValue({ shrinkSleeveInput });
  };

  const onChangeFolioWay = (e) => {
    if (e === "hladniFT") {
      setHideFolioTypeWarmFT(true);
    } else {
      setHideFolioTypeWarmFT(false);
    }
    fetchFolioTypes(e);

    form.setFieldsValue({
      folioType: null,
      folioPrice: null,
    });
  };

  const totalFolioHandler = async () => {
    ///univerzalno
    let currentMeters;
    let grossMeters;
    let folioPrice = parseFloat(form.getFieldValue("folioPrice"));

    currentMeters = parseFloat(form.getFieldValue("grossCurrentMeters"));

    ///razlike u racunanju za topli i hladni ft
    if (form.getFieldValue("folioWay") === "hladniFT") {
      const brutoMetaraKvadratnih = parseFloat(
        form.getFieldValue("grossSquareMeters"),
      );
      const cenaFolije = parseFloat(form.getFieldValue("folioPrice"));

      const ukupnaCenaFolije = brutoMetaraKvadratnih * cenaFolije;

      const cenaLepka = brutoMetaraKvadratnih * (1.2 / 1000) * 25;

      const folioTotal = ukupnaCenaFolije + cenaLepka;

      if (!isNaN(folioTotal)) {
        form.setFieldsValue({ folioTotal: folioTotal.toFixed(2) });
      } else {
        form.setFieldsValue({ folioTotal: 0 });
      }
    } else if (form.getFieldValue("folioWay") === "topliFT") {
      grossMeters =
        currentMeters * (parseFloat(form.getFieldValue("rollWidth")) / 1000);

      let selectedFolioMachine;
      if (machinesForPlasticization && machinesForPlasticization.length > 0) {
        for (const iterator of machinesForPlasticization) {
          ////za svaku masinu za plastifikaciju proveri da li je to ova koju smo izabrali ako jeste stavi je u promenljivu
          if (iterator._id === form.getFieldValue("folioPrintingMachine")) {
            selectedFolioMachine = iterator;
            setSelectedFolioMachine(selectedFolioMachine);
          }
        }
      }

      let circulation = parseFloat(form.getFieldValue("circulation"));

      const folioTotal =
        grossMeters * folioPrice +
        (circulation / selectedFolioMachine?.pcsPerH) *
          selectedFolioMachine?.pricePerH;

      if (!isNaN(folioTotal)) {
        form.setFieldsValue({ folioTotal: folioTotal.toFixed(2) });
      } else {
        form.setFieldsValue({ folioTotal: 0 });
      }
    }
  };

  let columns =
    selectedMachine?.name !== "Konica Minolta Rolna" &&
    selectedMachine?.name !== "Konica Minolta Tabak"
      ? [
          {
            key: "1",
            title: "Euro",
            dataIndex: "euro",
            children: [
              {
                id: 11,
                title: "Materijal",
                dataIndex: "material",
                key: "Material",
              },
              {
                id: 12,
                title: "Rad",
                dataIndex: "work",
                key: "work",
              },
              {
                id: 13,
                title: "Ceo tiraž",
                dataIndex: "circulation",
                key: "circulation",
              },
              {
                id: 14,
                title: "Cena na 1000 kom",
                dataIndex: "pricePerPiece",
                key: "pricePerPiece",
              },
            ],
          },
          {
            key: "basic price",
            title: "Osnovna cena",
            dataIndex: "basicPrice",
          },
          {
            key: 2,
            title: "RSD",
            children: [
              {
                id: 21,
                title: "Materijal",
                dataIndex: "materialRsd",
              },
              {
                id: 22,
                title: "Rad",
                dataIndex: "workRsd",
              },
              {
                id: 23,
                title: "Ceo tiraž",
                dataIndex: "circulationRsd",
              },
              {
                id: 24,
                title: "Cena po komadu",
                dataIndex: "pricePerPieceRsd",
              },
            ],
          },
        ]
      : [
          {
            key: "basic price",
            title: "Osnovna cena",
            dataIndex: "basicPrice",
          },
          {
            key: 2,
            title: "RSD",
            children: [
              {
                id: 21,
                title: "Cena po komadu",
                dataIndex: "pricePerPieceRsd",
              },
              {
                id: 22,
                title: "Ceo tiraž",
                dataIndex: "circulationRsd",
              },
            ],
          },
        ];

  const onFinish = (values, isNew) => {
    if (selectedClient && selectedClient.code) {
      if (!values.folioPrinting) {
        values.folioType = undefined;
      }
      if (!values.machine) {
        notification.error({
          message: "Odaberite mašinu.",
          duration: 2,
          placement: "bottomRight",
        });
        return;
      }
      if (!values.typeOfLabel) {
        notification.error({
          message: "Odaberite vrstu etikete.",
          duration: 2,
          placement: "bottomRight",
        });
        return;
      }
      if (!values.businessName) {
        notification.error({
          message: "Unesite naziv posla.",
          duration: 2,
          placement: "bottomRight",
        });
        return;
      }
      if (!values.client) {
        notification.error({
          message: "Unesite naziv poručioca.",
          duration: 2,
          placement: "bottomRight",
        });
        return;
      }
      if (!values.calculatedWastePaper) {
        notification.error({
          message: "Unesite kalkulisanu makulaturu.",
          duration: 2,
          placement: "bottomRight",
        });
        return;
      }
      if (!isNew) {
        if (form.getFieldValue("typeOfLabel") === data.typeOfLabel?.name) {
          values.typeOfLabel = data.typeOfLabel._id;
        }
        if (form.getFieldValue("folioType") === data.folioType?.name) {
          values.folioType = data?.folioType?._id;
        }
      }
      if (values.varnishing) {
        if (
          !values.varnishCoverage ||
          !values.degreeOfVarnishConsumption ||
          !values.varnishPrice ||
          !values.varnishType ||
          !values.varnishWay
        ) {
          notification.error({
            message:
              "Popunite sva polja za lakiranje ili isključite lakiranje.",
            duration: 2,
            placement: "bottomRight",
          });
          return;
        }
      }
      if (values.subcontractors) {
        if (!values.subcontractorInput) {
          notification.error({
            message: "Popunite polje za kooperante ili isključite kooperante.",
            duration: 2,
            placement: "bottomRight",
          });
          return;
        }
      }
      if (values.rollPacking) {
        if (!values.packingType) {
          notification.error({
            message: "Izaberite tip pakovanja.",
            duration: 2,
            placement: "bottomRight",
          });
          return;
        }
        if (!values.packingType === "vakumiranje") {
          if (
            !values.vacuumSealingQuantity ||
            !values.vacuumSealingPrice ||
            !values.vacuumSealingTotal
          ) {
            notification.error({
              message:
                "Unesite sve podatke za vakumiranje ili isključite pakovanje rolne.",
              duration: 2,
              placement: "bottomRight",
            });
            return;
          }
        }
        if (!values.packingType === "kutije") {
          if (
            !values.boxType ||
            !values.boxesPrice ||
            !values.boxesQuantity ||
            !values.boxesTotalPrice
          ) {
            notification.error({
              message:
                "Unesite sve podatke za kutije ili isključite pakovanje rolne.",
              duration: 2,
              placement: "bottomRight",
            });
            return;
          }
        }
      }
      if (values.punchTool) {
        if (!values.punchToolType) {
          notification.error({
            message: "Izaberite tip alata.",
            duration: 2,
            placement: "bottomRight",
          });
          return;
        }
        if (values.punchToolType && values.punchToolType === "nov") {
          if (!values.punchToolInput) {
            notification.error({
              message: "Unesite iznos za alat ili isključite alat.",
              duration: 2,
              placement: "bottomRight",
            });
            return;
          }
        }
      }

      if (values.shrinkSleeve && !values.shrinkSleeveInput) {
        notification.error({
          message:
            "Unesite iznos za sliviranje sa inspekcijom ili isključite sliviranje sa inspekcijom.",
          duration: 2,
          placement: "bottomRight",
        });
        return;
      }

      if (values.design && !values.designInput) {
        notification.error({
          message: "Unesite iznos za dizajn ili isključite dizajn.",
          duration: 2,
          placement: "bottomRight",
        });
        return;
      }

      if (
        values.rewinding &&
        (!values.rewindingValues || values.rewindingValues[0] === null)
      ) {
        notification.error({
          message:
            "Izaberite mašinu/e za premotavanje ili isključite premotavanje.",
          duration: 2,
          placement: "bottomRight",
        });
        return;
      }

      if (values.folioPrinting) {
        if (!values.folioWay) {
          notification.error({
            message: "Izaberite plastifikaju/foliotisak ili je isključite!",
            duration: 5,
            placement: "bottomRight",
          });
          return;
        }

        if (!values.folioTotal) {
          notification.error({
            message: "Izračunajte plastifikaju/foliotisak ili je isključite!",
            duration: 5,
            placement: "bottomRight",
          });
          return;
        }
      }

      if (!tableData) {
        notification.error({
          message: "Prvo izračunajte kalkulaciju!",
          duration: 5,
          placement: "bottomRight",
        });
        return;
      }

      values.priceRSD = tableData[0].pricePerPieceRsd;
      values.priceEUR = tableData[0].pricePerPiece;

      values.isSelfAdhesive = selectedTypeOfLabel.name
        .toLowerCase()
        .includes("samolepljivi");

      if (values.materialWidth <= maxWidth) {
        setTooWide(false);
        values.calculationType = "rolna";
        onSubmit(values, isNew);
      } else {
        setTooWide(true);
        notification.error({
          message: "Prekoračena je maksimalna širina materijala!",
          duration: 5,
          placement: "bottomRight",
        });
      }
    } else {
      notification.error({
        message: "Izabrani klijent nema šifru!",
        duration: 2,
        placement: "bottomRight",
      });
    }
  };

  const onFinishNew = (values, isNew) => {
    values.offerStatus = "Čeka verifikaciju";
    values.selectedPrice = undefined;

    if (selectedClient && selectedClient.code) {
      if (!values.folioPrinting) {
        values.folioType = undefined;
      }
      if (!values.machine) {
        notification.error({
          message: "Odaberite mašinu.",
          duration: 2,
          placement: "bottomRight",
        });
        return;
      }
      if (!values.typeOfLabel) {
        notification.error({
          message: "Odaberite vrstu etikete.",
          duration: 2,
          placement: "bottomRight",
        });
        return;
      }
      if (!values.businessName) {
        notification.error({
          message: "Unesite naziv posla.",
          duration: 2,
          placement: "bottomRight",
        });
        return;
      }
      if (!values.client) {
        notification.error({
          message: "Unesite naziv poručioca.",
          duration: 2,
          placement: "bottomRight",
        });
        return;
      }
      if (!values.calculatedWastePaper) {
        notification.error({
          message: "Unesite kalkulisanu makulaturu.",
          duration: 2,
          placement: "bottomRight",
        });
        return;
      }
      if (!isNew) {
        if (form.getFieldValue("typeOfLabel") === data.typeOfLabel.name) {
          values.typeOfLabel = data.typeOfLabel._id;
        }
      }
      if (values.varnishing) {
        if (
          !values.varnishCoverage ||
          !values.degreeOfVarnishConsumption ||
          !values.varnishPrice ||
          !values.varnishType ||
          !values.varnishWay
        ) {
          notification.error({
            message:
              "Popunite sva polja za lakiranje ili isključite lakiranje.",
            duration: 2,
            placement: "bottomRight",
          });
          return;
        }
      }
      if (values.subcontractors) {
        if (!values.subcontractorInput) {
          notification.error({
            message: "Popunite polje za kooperante ili isključite kooperante.",
            duration: 2,
            placement: "bottomRight",
          });
          return;
        }
      }
      if (values.rollPacking) {
        if (!values.packingType) {
          notification.error({
            message: "Izaberite tip pakovanja.",
            duration: 2,
            placement: "bottomRight",
          });
          return;
        }
        if (!values.packingType === "vakumiranje") {
          if (
            !values.vacuumSealingQuantity ||
            !values.vacuumSealingPrice ||
            !values.vacuumSealingTotal
          ) {
            notification.error({
              message:
                "Unesite sve podatke za vakumiranje ili isključite pakovanje rolne.",
              duration: 2,
              placement: "bottomRight",
            });
            return;
          }
        }
        if (!values.packingType === "kutije") {
          if (
            !values.boxType ||
            !values.boxesPrice ||
            !values.boxesQuantity ||
            !values.boxesTotalPrice
          ) {
            notification.error({
              message:
                "Unesite sve podatke za kutije ili isključite pakovanje rolne.",
              duration: 2,
              placement: "bottomRight",
            });
            return;
          }
        }
      }

      if (values.punchTool) {
        if (!values.punchToolType) {
          notification.error({
            message: "Izaberite tip alata.",
            duration: 2,
            placement: "bottomRight",
          });
          return;
        }
        if (values.punchToolType) {
          if (!values.punchToolInput) {
            notification.error({
              message: "Unesite iznos za alat ili isključite alat.",
              duration: 2,
              placement: "bottomRight",
            });
            return;
          }
        }
      }

      if (values.shrinkSleeve && !values.shrinkSleeveInput) {
        notification.error({
          message:
            "Unesite iznos za sliviranje sa inspekcijom ili isključite sliviranje sa inspekcijom.",
          duration: 2,
          placement: "bottomRight",
        });
        return;
      }

      if (values.design && !values.designInput) {
        notification.error({
          message: "Unesite iznos za dizajn ili isključite dizajn.",
          duration: 2,
          placement: "bottomRight",
        });
        return;
      }

      if (
        values.rewinding &&
        (!values.rewindingValues || values.rewindingValues[0] === null)
      ) {
        notification.error({
          message:
            "Izaberite mašinu/e za premotavanje ili isključite premotavanje.",
          duration: 2,
          placement: "bottomRight",
        });
        return;
      }

      values.isSelfAdhesive = selectedTypeOfLabel.name
        .toLowerCase()
        .includes("samolepljivi");

      if (values.materialWidth <= maxWidth) {
        setTooWide(false);
        values.calculationType = "rolna";
        onSubmitNew(values, isNew);
      } else {
        setTooWide(true);
        notification.error({
          message: "Prekoračena je maksimalna širina materijala!",
          duration: 5,
          placement: "bottomRight",
        });
      }
    } else {
      notification.error({
        message: "Izabrani klijent nema šifru!",
        duration: 2,
        placement: "bottomRight",
      });
    }
  };

  const onCreateOrderToProduction = async (values, isNew) => {
    if (selectedClient && selectedClient.code) {
      try {
        ///funkcija za kreiranje NPP u proizvodnji iz podataka kalkulacije
        if (!tableData) {
          notification.error({
            message: "Prvo izračunajte kalkulaciju!",
            duration: 5,
            placement: "bottomRight",
          });
          return;
        }
        if (!values.height) {
          notification.error({
            message: "Niste uneli visinu etikete!",
            duration: 2,
            placement: "bottomRight",
          });
          return;
        }
        if (!values.circulation) {
          notification.error({
            message: "Niste uneli tiraž!",
            duration: 2,
            placement: "bottomRight",
          });
          return;
        }
        // if (!values.rollPieces) {
        //   notification.error({
        //     message: "Niste uneli br. komada na rolni!",
        //     duration: 2,
        //     placement: "bottomRight",
        //   });
        //   return;
        // }
        if (selectedHilzna) {
          if (!selectedHilzna?.width) {
            notification.error({
              message: "Odabrana hilzna nema širinu!",
              duration: 2,
              placement: "bottomRight",
            });
            return;
          }
        }

        ///cuvanje kalkulacije sa podacima iz forme da bi bili sigurno isti kao u radnom nalogu koji se kreira
        values.createdOrder = true;
        onFinish(values, isNew);
        ///kreiranje NPP-a
        values.calculationId = data._id;
        const res = await Axios.post(
          `${SERVER_URL_PRODUCTION}/ordertoproduction-from-calculation`,
          {
            data: values,
            volume: document.getElementById("volume").innerText,
            rollPiecesNum: document.getElementById("numberOfItems").innerText,
            widthLabel: document.getElementById("widthTable").innerText,
            machineName: selectedMachine.name,
            currentuser: currentuser.data,
            clientCode: selectedClient.code,
            rewindingMachineNames: selectedRewindingMachines,
            varnishType: selectedVarnishingType,
            varnishWay: selectedVarnishingWay,
            hilzna: selectedHilzna,
            transport: selectedTransport?.name,
            material: selectedTypeOfLabel.name,
          },
        );

        notification.success({
          message: "Kreiran nalog u proizvodnji!",
          duration: 2,
          placement: "bottomRight",
        });
      } catch (error) {
        console.error(error);
        notification.error({
          message: `Greška pri kreiranju naloga u prozivodnji!, ${error}`,
          duration: 2,
          placement: "bottomRight",
        });
      }
    } else {
      notification.error({
        message: "Izabrani klijent nema šifru!",
        duration: 2,
        placement: "bottomRight",
      });
    }
  };

  const handleNewItem = async () => {
    setRewindingMachinesArr([...rewindingMachinesArr, {}]);
  };

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      event.preventDefault();
      event.returnValue = ""; // Some browsers require a non-empty string
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  const calculateDimensions = (width) => {
    if (data?.typeOfLabel) {
      onChangeLabelWidth(
        width,
        form.getFieldsValue(),
        form.getFieldValue("typeOfLabel") === data.typeOfLabel.name
          ? data.typeOfLabel._id
          : form.getFieldValue("typeOfLabel"),
      );
    } else {
      onChangeLabelWidth(
        width,
        form.getFieldsValue(),
        form.getFieldValue("typeOfLabel"),
      );
    }
  };

  return (
    <div style={{ textAlign: "start", alignContent: "start" }}>
      <Form
        name="basic"
        form={form}
        initialValues={initialValues}
        onFinish={(values) => onFinish(values, !data)}
      >
        <div>
          <h1
            style={{
              backgroundColor: "#2574ab",
              padding: "10px",
              color: "white",
            }}
          >
            {!data ? "NOVA KALKULACIJA - ROLNA" : "EDITUJ KALKULACIJU - ROLNA"}
          </h1>
        </div>
        <div>
          <div
            style={{
              paddingTop: "10px",
              marginTop: "-7px",
              width: "1360px%",
              paddingLeft: "10px",
            }}
          >
            <div
              style={{ display: "flex", flexDirection: "row", width: "100%" }}
            >
              <div>
                <Form.Item
                  className="firstChildInRowFirstSectionANTD"
                  label="Datum poslednje izmene:"
                  name="date"
                >
                  <Input disabled></Input>
                </Form.Item>
              </div>
              <div>
                <Form.Item
                  className="firstChildInRowFirstSectionANTD"
                  label="Datum kreiranja:"
                  name="createdAt"
                >
                  <Input disabled></Input>
                </Form.Item>
              </div>
            </div>
            <div
              style={{ display: "flex", flexDirection: "row", width: "100%" }}
            >
              <Form.Item
                className="firstChildInRowFirstSectionANTD"
                label="Kurs"
                name="exchangeRate"
              >
                <InputNumber min="0" />
              </Form.Item>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                marginTop: "10px",
              }}
            >
              <Form.Item
                className="firstChildInRowFirstSectionANTD"
                label="Tiraž"
                name="circulation"
              >
                <InputNumber
                  min="0"
                  onChange={() => {
                    grossCurrentMetersHandler();
                    grossKgPrintingHandler();
                    totalTimeOfPrintingHandler();
                    totalElectricityHandler();
                  }}
                />
              </Form.Item>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                marginTop: "10px",
              }}
            >
              <Form.Item
                label="Naziv posla"
                className="firstChildInRowFirstSectionANTD"
                name="businessName"
              >
                <TextArea rows={1} />
              </Form.Item>
              <Form.Item
                className="firstChildInRowFirstSectionANTD"
                label="Poručilac"
                name="client"
              >
                <Select
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children[2]
                      ?.toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  dropdownStyle={{ minWidth: "30%" }}
                  onChange={(id) => {
                    onChangeClientHandler(id);
                  }}
                >
                  {allClients?.data?.items &&
                    allClients.data.items.length > 0 &&
                    allClients.data.items.map((item, index) => (
                      <Option key={index} value={item._id}>
                        {item?.code} - {item.companyName}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
            </div>

            <div style={{ display: "flex" }}>
              <div>
                <Form.Item
                  className="firstChildInRowFirstSectionANTD"
                  label="Mašina:"
                  name="machine"
                >
                  <Select
                    onChange={(e) => {
                      numberOfRowsToCutHandler();
                      onChangeMachineHandler(e);
                      totalTimeOfPrintingHandler();
                      setSelectedTypeOfLabel(null);
                      calculateDimensions(form.getFieldValue("width"));
                      grossKgPrintingHandler();
                    }}
                  >
                    {machines &&
                      machines.length > 0 &&
                      machines.map((item, index) => (
                        <Option key={index} value={item._id}>
                          {item.name}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>
              </div>
              <div>
                <Form.Item
                  className="firstChildInRowFirstSectionANTD"
                  label="Vrsta materijala:"
                  name="typeOfLabel"
                >
                  <Select
                    showSearch
                    onChange={(e) => {
                      onChangeTypeOfLabelHandler(e);
                      numberOfRowsToCutHandler();
                      totalTimeOfPrintingHandler();
                      widthHandler();
                      onChangeLabelWidth(
                        form.getFieldValue("width"),
                        form.getFieldsValue(),
                        e,
                      );
                    }}
                  >
                    <Option value={null}> - </Option>
                    {typesOfLabel &&
                      typesOfLabel.length > 0 &&
                      typesOfLabel.map((item, index) => (
                        <Option key={index} value={item._id}>
                          {item.name}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>
              </div>
            </div>
            <div style={{ width: "100%" }} className="subtitle">
              Opcije
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                marginLeft: "50px",
              }}
            >
              <Form.Item
                style={{ width: "150px" }}
                label="Lakiranje"
                name="varnishing"
              >
                <Switch
                  checkedChildren={<CheckOutlined />}
                  unCheckedChildren={<CloseOutlined />}
                  defaultChecked={initialValues?.varnishing}
                  onChange={() => {
                    onChangeVarnishing();
                    printingFormsPriceHandler();
                  }}
                ></Switch>
              </Form.Item>
              <Form.Item
                style={{ width: "150px" }}
                label="Kooperanti"
                name="subcontractors"
              >
                <Switch
                  checkedChildren={<CheckOutlined />}
                  unCheckedChildren={<CloseOutlined />}
                  defaultChecked={initialValues?.subcontractors}
                  onChange={onChangeSubcontractors}
                ></Switch>
              </Form.Item>
              <Form.Item
                style={{ width: "175px" }}
                label="Pakovanje rolne"
                name="rollPacking"
              >
                <Switch
                  onChange={onChangeRollPacking}
                  checkedChildren={<CheckOutlined />}
                  unCheckedChildren={<CloseOutlined />}
                  defaultChecked={initialValues?.rollPacking}
                />
              </Form.Item>

              <Form.Item
                style={{ width: "160px" }}
                label="Ric/štanc alat:"
                name="punchTool"
              >
                <Switch
                  onChange={onChangePunchTool}
                  checkedChildren={<CheckOutlined />}
                  unCheckedChildren={<CloseOutlined />}
                  defaultChecked={initialValues?.punchTool}
                ></Switch>
              </Form.Item>
              <Form.Item
                style={{ width: "242px" }}
                hidden={hideShrinkSleeveSwitch}
                label="Sliviranje sa inspekcijom:"
                name="shrinkSleeve"
              >
                <Switch
                  onChange={onChangeShrinkSleeve}
                  checkedChildren={<CheckOutlined />}
                  unCheckedChildren={<CloseOutlined />}
                  defaultChecked={initialValues?.shrinkSleeve}
                ></Switch>
              </Form.Item>
            </div>
            <div
              style={{ display: "flex", flexDirection: "row", width: "100%" }}
            >
              <Form.Item
                style={{ width: "200px" }}
                label="Plastifikacija/Foliotisak"
                name="folioPrinting"
              >
                <Switch
                  checkedChildren={<CheckOutlined />}
                  unCheckedChildren={<CloseOutlined />}
                  onChange={() => {
                    onChangePlasticization();
                    fetchFinishingWorkPlasticization();
                    printingFormsPriceHandler();
                  }}
                  defaultChecked={initialValues?.folioPrinting}
                ></Switch>
              </Form.Item>
              <Form.Item
                style={{ width: "175px" }}
                label="Dizajn"
                name="design"
              >
                <Switch
                  onChange={onChangeDesign}
                  checkedChildren={<CheckOutlined />}
                  unCheckedChildren={<CloseOutlined />}
                  defaultChecked={initialValues?.design}
                ></Switch>
              </Form.Item>
              <Form.Item
                style={{ width: "150px" }}
                label="Transport"
                name="transport"
              >
                <Switch
                  onChange={onChangeTransport}
                  checkedChildren={<CheckOutlined />}
                  unCheckedChildren={<CloseOutlined />}
                  defaultChecked={initialValues?.transport}
                ></Switch>
              </Form.Item>
              <Form.Item
                style={{ width: "202px" }}
                label="Premotavanje"
                name="rewinding"
              >
                <Switch
                  checkedChildren={<CheckOutlined />}
                  unCheckedChildren={<CloseOutlined />}
                  defaultChecked={initialValues?.rewinding}
                  onChange={onChangeRewinding}
                />
              </Form.Item>
              {!isDigitalPrint && (
                <Form.Item
                  style={{ width: "200px" }}
                  label="Štamparske forme:"
                  name="printingForms"
                >
                  <Switch
                    onChange={onChangePrintingForm}
                    checkedChildren={<CheckOutlined />}
                    unCheckedChildren={<CloseOutlined />}
                    defaultChecked={initialValues?.printingForms}
                  ></Switch>
                </Form.Item>
              )}
            </div>
            <div style={{ width: "100%" }} className="subtitle">
              Dimenzije
            </div>
            <div style={{ display: "flex" }}>
              <Form.Item
                label="Visina (mm)"
                className="firstChildInRowFirstSectionANTD"
                name="height"
              >
                <InputNumber
                  min="0"
                  onChange={() => {
                    numberOfRowsToCutHandler();
                    widthHandler();
                    grossKgPrintingHandler();
                  }}
                />
              </Form.Item>
              <Form.Item
                label="Širina (mm)"
                className="firstChildInRowFirstSectionANTD"
                name="width"
              >
                <InputNumber
                  min="0"
                  onChange={(event) => {
                    totalTimeOfPrintingHandler();
                    calculateDimensions(event);
                  }}
                />
              </Form.Item>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
              }}
            >
              <Form.Item
                label="Zadata širina"
                name="assignWidth"
                style={{ width: "120px" }}
              >
                <span id="assignWidth"></span>
              </Form.Item>
              <Form.Item
                label="Širina"
                style={{ width: "185px" }}
                name="widthTable"
              >
                <span id="widthTable"></span>
              </Form.Item>
              <Form.Item
                label="Broj komada"
                name="numberOfItems"
                style={{ width: "165px" }}
              >
                <span id="numberOfItems"></span>
              </Form.Item>
              <Form.Item
                label="Broj zubaca"
                name="numberOfCylinders"
                style={{ width: "165px" }}
              >
                <span id="numberOfCylinders"></span>
              </Form.Item>
              <Form.Item label="Obim" name="volume">
                <span id="volume"></span> mm
              </Form.Item>
              <Form.Item
                style={{ marginLeft: "25px" }}
                label="Izaberi korak stampe"
                name="selectedPrintStep"
              >
                <Select
                  style={{ width: "250px" }}
                  dropdownStyle={{ minWidth: "30%" }}
                  onChange={(e) => {
                    let values = e.split("*");
                    setPrintStepData({
                      assignWidth: values[1],
                      widthTable: values[1],
                      numberOfItems: values[3],
                      numberOfCylinders: values[0],
                      volume: values[2],
                    });
                  }}
                >
                  {closestPrintSteps &&
                    closestPrintSteps.length > 0 &&
                    closestPrintSteps.map((item, index) => {
                      return (
                        <Option
                          value={`${item?.br_cilindra}*${item?.sirina}*${item?.obim}*${item?.br_komada_tm}`}
                        >{`Br. zubaca: ${item?.br_cilindra} Sirina: ${item?.sirina} Obim: ${item?.obim} Br. komada: ${item?.br_komada_tm}`}</Option>
                      );
                    })}
                </Select>
              </Form.Item>
            </div>
            <div
              style={{ display: "flex", flexDirection: "row", width: "100%" }}
            >
              <Form.Item
                label="Širina materijala (mm)"
                className="firstChildInRowFirstSectionANTD"
                name="materialWidth"
              >
                <InputNumber
                  min="0"
                  disabled={
                    selectedMachine?.name === "Konica Minolta Rolna" || false
                  }
                  value={
                    selectedMachine?.name === "Konica Minolta Rolna"
                      ? selectedMachine?.maxWidth
                      : undefined
                  }
                  onChange={() => {
                    numberOfRowsToCutZeroHandler();
                  }}
                />
              </Form.Item>
              {tooWide && (
                <span style={{ color: "#ff4d4f" }}>
                  Prekoračena je max. širina!
                </span>
              )}{" "}
              {!isDigitalPrint && (
                <Form.Item
                  className="firstChildInRowFirstSectionANTD"
                  label="Cena materijala"
                  name="materialPrice"
                >
                  <InputNumber
                    onChange={() => {
                      grossMetersPrintingHandler();
                      grossKgPrintingHandler();
                    }}
                    min="0"
                  />
                </Form.Item>
              )}
            </div>
            <div
              style={{ display: "flex", flexDirection: "row", width: "100%" }}
            >
              <Form.Item
                onChange={() => {
                  totalTimeOfPrintingHandler();
                }}
                label="Kalkulisana makulatura (m)"
                className="firstChildInRowFirstSectionANTD"
                name="calculatedWastePaper"
              >
                <InputNumber
                  onChange={() => {
                    grossCurrentMetersHandler();
                    grossKgPrintingHandler();
                  }}
                  min="0"
                />
              </Form.Item>
            </div>
            <div
              style={{ display: "flex", flexDirection: "row", width: "100%" }}
            >
              <Form.Item
                label="Broj redova za odsecanje"
                className="firstChildInRowFirstSectionANTD"
                name="numberOfRowsToCut"
              >
                <InputNumber
                  min="0"
                  onChange={() => {
                    widthHandler();
                    totalTimeOfPrintingHandler();
                    grossCurrentMetersHandler();
                    grossKgPrintingHandler();
                  }}
                />
              </Form.Item>
            </div>
            {!isDigitalPrint && (
              <div style={{ width: "100%" }} className="subtitle">
                Boje i lakovi
              </div>
            )}

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                marginTop: "10px",
              }}
            >
              <Form.Item
                hidden={isDigitalPrint}
                className="firstChildInRowSecondSectionANTD"
                label="Broj boja"
                name="numberOfColors"
              >
                <InputNumber
                  min="0"
                  onChange={() => {
                    printingFormsPriceHandler();
                    totalPriceHandler();
                  }}
                />
              </Form.Item>
              <Form.Item
                className="firstChildInRowSecondSectionANTD"
                hidden={isDigitalPrint}
                label="Stepen utroška boje (g/m2)"
                name="degreeOfPaintConsumption"
              >
                <InputNumber min="0" />
              </Form.Item>
            </div>
            <div
              style={{ display: "flex", flexDirection: "row", width: "100%" }}
            >
              <Form.Item
                hidden={isDigitalPrint}
                className="firstChildInRowSecondSectionANTD"
                label="Količina boja"
                name="colorQuantity"
              >
                <Input disabled></Input>
              </Form.Item>
              <Form.Item
                hidden={isDigitalPrint}
                className="firstChildInRowSecondSectionANTD"
                label="Vrednost boja"
                name="priceOfColors"
              >
                <Input disabled></Input>
              </Form.Item>
            </div>
            <div
              style={{ display: "flex", flexDirection: "row", width: "100%" }}
            >
              <Form.Item
                hidden={isDigitalPrint}
                className="firstChildInRowSecondSectionANTD"
                label="Broj specijalnih boja/adheziva/prajmera"
                name="numberOfSpecialColors"
              >
                <InputNumber
                  onChange={() => {
                    printingFormsPriceHandler();
                    totalPriceHandler();
                  }}
                  min="0"
                />
              </Form.Item>

              <Form.Item
                hidden={isDigitalPrint}
                className="firstChildInRowSecondSectionANTD"
                label="Stepen utroška specijalnih boja"
                name="degreeOfSpecialPaintConsumption"
              >
                <InputNumber
                  onChange={() => totalPriceOfSpecialColorsHandler()}
                  min="0"
                ></InputNumber>
              </Form.Item>
            </div>
            <div
              style={{ display: "flex", flexDirection: "row", width: "100%" }}
            >
              <Form.Item
                hidden={isDigitalPrint}
                className="firstChildInRowSecondSectionANTD"
                label="Količina specijalnih boja/adheziva/prajmera"
                name="quantityOfSpecialColors"
              >
                <InputNumber min="0" disabled />
              </Form.Item>
              <Form.Item
                hidden={isDigitalPrint}
                className="firstChildInRowSecondSectionANTD"
                label="Ukupna cena - spec. boja"
                name="totalPriceOfSpecialColors"
              >
                <Input disabled></Input>
              </Form.Item>
            </div>

            <div
              style={{
                display: "flex",
                width: "100%",
              }}
            >
              <Form.Item
                className="firstChildInRowFirstSectionANTD"
                hidden={hideVarnishing}
                label="Lakiranje"
                name="varnishCoverage"
              >
                <Select
                  onChange={(e) => {
                    onChangeVarnishCoverageHandler(e);
                  }}
                >
                  <Option value="potpuno">Potpuno</Option>
                  <Option value="parcijalno">Parcijalno</Option>
                </Select>
              </Form.Item>

              <Form.Item
                className="firstChildInRowFirstSectionANTD"
                hidden={hideVarnishing}
                label="Stepen utroška laka"
                name="degreeOfVarnishConsumption"
              >
                <InputNumber min="0" />
              </Form.Item>
            </div>

            <div
              style={{
                display: "flex",
                width: "100%",
              }}
            >
              <Form.Item
                className="firstChildInRowFirstSectionANTD"
                hidden={hideVarnishing}
                label="Količina laka"
                name="varnishQuantity"
              >
                <Input disabled />
              </Form.Item>
              <Form.Item
                className="firstChildInRowFirstSectionANTD"
                hidden={hideVarnishing}
                label="Cena laka"
                name="varnishPrice"
              >
                <InputNumber min="0" />
              </Form.Item>
            </div>

            <div
              style={{
                display: "flex",
                width: "100%",
              }}
            >
              <Form.Item
                className="firstChildInRowFirstSectionANTD"
                hidden={hideVarnishing}
                label="Tip lakiranja"
                name="varnishType"
              >
                <Select
                  onChange={(e) => {
                    onChangeVarnishingTypes(e);
                  }}
                >
                  {typesOfVarnishingTypes &&
                    typesOfVarnishingTypes.length > 0 &&
                    typesOfVarnishingTypes.map((item, index) => (
                      <Option value={item._id}>{item.name}</Option>
                    ))}
                </Select>
              </Form.Item>
              <Form.Item
                className="firstChildInRowFirstSectionANTD"
                hidden={hideVarnishing}
                label="Vrsta laka"
                name="varnishWay"
              >
                <Select
                  onChange={(e) => {
                    onChangeVarnishingWays(e);
                  }}
                >
                  {typesOfVarnishingWays &&
                    typesOfVarnishingWays.length > 0 &&
                    typesOfVarnishingWays.map((item, index) => (
                      <Option value={item._id}>{item.name}</Option>
                    ))}
                </Select>
              </Form.Item>
            </div>
            <div
              style={{ display: "flex", flexDirection: "row", width: "100%" }}
            >
              <Form.Item
                className="firstChildInRowFirstSectionANTD"
                hidden={hideVarnishing}
                label="Vrednost laka €"
                name="varnishTotalPrice"
              >
                <Input disabled></Input>
              </Form.Item>
            </div>
            <div
              style={{ display: "flex", flexDirection: "row", width: "100%" }}
            >
              <Form.Item
                className="firstChildInRowFirstSectionANTD"
                hidden={hidePlasticization}
                label="Cena folije (€/m2)"
                name="folioPrice"
              >
                <InputNumber min="0"></InputNumber>
              </Form.Item>
              <Form.Item
                className="firstChildInRowFirstSectionANTD"
                hidden={hidePlasticization}
                label="Vrednost folije €"
                name="folioTotal"
              >
                <Input disabled></Input>
              </Form.Item>
            </div>
          </div>
          <div style={{ backgroundColor: "#D2E1EA" }}>
            <div
              style={{
                display: "flex",
                width: "100%",
              }}
            >
              <Form.Item
                className="firstChildInRowFirstSectionANTD"
                hidden={hidePunchTool}
                label="Ric/štanc alat"
                name="punchToolType"
              >
                <Select
                  onChange={(e) => {
                    onChangePunchToolType(e);
                  }}
                >
                  <Option value="nista">Ništa</Option>
                  <Option value="postojeci">Postojeći</Option>
                  <Option value="nov">Nov</Option>
                </Select>
              </Form.Item>
              <Form.Item
                className="firstChildInRowFirstSectionANTD"
                hidden={hidePunchToolInput}
                label="Ric/štanc alat fakturisanje €"
                name="punchToolInput"
              >
                <InputNumber min="0" />
              </Form.Item>
            </div>

            <div
              style={{ display: "flex", flexDirection: "row", width: "100%" }}
            >
              <Form.Item
                className="firstChildInRowFirstSectionANTD"
                hidden={hidePlasticization}
                label="Plastifikacija/Foliotisak"
                name="folioWay"
              >
                <Select
                  onChange={(e) => {
                    onChangeFolioWay(e);
                    printingFormsPriceHandler();
                  }}
                >
                  <Option value="hladniFT">Plastifikacija/Hladni FT</Option>
                  <Option value="topliFT">Topli FT</Option>
                </Select>
              </Form.Item>
              <Form.Item
                className="firstChildInRowFirstSectionANTD"
                hidden={hideFolioTypeWarmFT}
                label="Izrada na mašini"
                name="folioPrintingMachine"
              >
                <Select
                  onChange={(e) => {
                    onChangeFolioPrintingMachine(e);
                  }}
                >
                  {machinesForPlasticization &&
                    machinesForPlasticization.length > 0 &&
                    machinesForPlasticization.map((item, index) => (
                      <Option key={index} value={item._id}>
                        {item.name}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
            </div>
            <div
              style={{ display: "flex", flexDirection: "row", width: "100%" }}
            >
              <Form.Item
                className="firstChildInRowFirstSectionANTD"
                hidden={hidePlasticization}
                label="Vrsta folije"
                name="folioType"
              >
                <Select
                  dropdownStyle={{ minWidth: "50%" }}
                  onChange={(e) => {
                    onChangeFolioType(e);
                  }}
                >
                  {folioTypes &&
                    folioTypes.length > 0 &&
                    folioTypes.map((item, index) => (
                      <Option value={item._id}>
                        {item.code} - {item.name}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
              <Form.Item
                className="firstChildInRowFirstSectionANTD"
                hidden={hidePlasticization}
                label="Širina rolne (mm)"
                name="rollWidth"
              >
                <InputNumber min="0" />
              </Form.Item>
            </div>

            <div
              style={{ display: "flex", flexDirection: "row", width: "100%" }}
            >
              <Form.Item
                className="firstChildInRowFirstSectionANTD"
                hidden={hideSubcontractors}
                label="Kooperant €"
                name="subcontractorInput"
              >
                <InputNumber min="0" />
              </Form.Item>
              <Form.Item
                className="firstChildInRowFirstSectionANTD"
                hidden={hideSubcontractors}
                label="Kooperant (opis)"
                name="subcontractorTextInput"
              >
                <TextArea rows={1} />
              </Form.Item>
            </div>
            <div
              style={{
                display: "flex",
                width: "100%",
              }}
            >
              <Form.Item
                className="firstChildInRowFirstSectionANTD"
                hidden={hideTransport}
                label="Tip transporta"
                name="transportType"
              >
                <Select
                  onChange={(id) => {
                    onChangeTransportType(id);
                  }}
                >
                  {typesOfTransport &&
                    typesOfTransport.data &&
                    typesOfTransport.data.items &&
                    typesOfTransport.data.items.length > 0 &&
                    typesOfTransport.data.items.map((item, index) => (
                      <Option value={item._id}>{item.name}</Option>
                    ))}
                </Select>
              </Form.Item>
              <Form.Item
                className="firstChildInRowFirstSectionANTD"
                hidden={hideTransportInput}
                label="Transport €"
                name="transportInput"
              >
                <InputNumber min="0" onChange={totalPriceHandler} />
              </Form.Item>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
              }}
            >
              <Form.Item
                className="firstChildInRowFirstSectionANTD"
                hidden={hideDesign}
                label="Dizajn €"
                name="designInput"
              >
                <InputNumber
                  min="0"
                  onChange={() => {
                    totalPriceHandler();
                  }}
                />
              </Form.Item>
            </div>
            <div
              style={{
                display: "flex",
                width: "100%",
              }}
            >
              <Form.Item
                className="firstChildInRowFirstSectionANTD"
                hidden={hidePacking}
                label="Tip pakovanja"
                name="packingType"
              >
                <Select
                  onChange={(type) => {
                    onChangePackingType(type);
                  }}
                >
                  <Option value="vakumiranje">Vakumiranje</Option>
                  <Option value="foliranje">Foliranje</Option>
                  <Option value="kutije">U kutije</Option>
                </Select>
              </Form.Item>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
              }}
            >
              <Form.Item
                className="firstChildInRowFirstSectionANTD"
                hidden={hideBoxes}
                label="Količina kutija"
                name="boxesQuantity"
              >
                <InputNumber
                  min="0"
                  onChange={() => {
                    totalBoxesPriceHandler();
                    totalPriceHandler();
                  }}
                />
              </Form.Item>
              <Form.Item
                className="firstChildInRowFirstSectionANTD"
                hidden={hideBoxes}
                label="Cena kutije "
                name="boxesPrice"
              >
                <InputNumber
                  min="0"
                  onChange={() => {
                    totalBoxesPriceHandler();
                    totalPriceHandler();
                  }}
                />
              </Form.Item>
            </div>
            <div
              style={{ display: "flex", flexDirection: "row", width: "100%" }}
            >
              <Form.Item
                className="firstChildInRowFirstSectionANTD"
                hidden={hideBoxes}
                label="Kutija"
                name="boxType"
              >
                <Select onChange={onChangeBoxType} showSearch>
                  {allBoxes &&
                    allBoxes.data &&
                    allBoxes.data.items &&
                    allBoxes.data.items.length > 0 &&
                    allBoxes.data.items.map((item, index) => (
                      <Option key={index} value={item._id}>
                        {item.code} - {item.name}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
              <Form.Item
                className="firstChildInRowFirstSectionANTD"
                hidden={hideBoxes}
                label="Ukupan iznos - kutije"
                name="boxesTotalPrice"
              >
                <Input disabled></Input>
              </Form.Item>
            </div>
            <div
              style={{
                display: "flex",
                width: "100%",
              }}
            >
              <Form.Item
                className="firstChildInRowFirstSectionANTD"
                hidden={hideVacuumSealing}
                label="Količina rolni za vakumiranje"
                name="vacuumSealingQuantity"
              >
                <InputNumber min="0" onChange={vacuumSealingTotalHandler} />
              </Form.Item>
              <Form.Item
                className="firstChildInRowFirstSectionANTD"
                hidden={hideVacuumSealing}
                label="Cena usluge vakumiranja"
                name="vacuumSealingPrice"
              >
                <InputNumber min="0" onChange={vacuumSealingTotalHandler} />
              </Form.Item>
            </div>
            <div
              style={{
                display: "flex",
                width: "100%",
              }}
            >
              <Form.Item
                className="firstChildInRowFirstSectionANTD"
                hidden={hideVacuumSealing}
                label="Ukupno za vakumiranje"
                name="vacuumSealingTotal"
              >
                <Input disabled></Input>
              </Form.Item>
            </div>

            {rewindingMachinesArr.map((item, index) => (
              <div
                style={{
                  display: "flex",
                  width: "100%",
                }}
              >
                <Form.Item
                  className="firstChildInRowFirstSectionANTD"
                  hidden={hideRewinding}
                  label="Premotavanje na mašini"
                  name={["rewindingValues", index]}
                >
                  <Select
                    onChange={(e) => {
                      onChangeRewindingMachines(e);
                    }}
                  >
                    {rewindingMachines &&
                      rewindingMachines.data &&
                      rewindingMachines.data.items &&
                      rewindingMachines.data.items.length > 0 &&
                      rewindingMachines.data.items.map((item, index) => (
                        <Option value={item._id}>{item.name}</Option>
                      ))}
                  </Select>
                </Form.Item>
              </div>
            ))}
            <Button
              hidden={hideRewinding}
              onClick={handleNewItem}
              type="primary"
              htmlType="button"
            >
              Dodaj premotavačicu
            </Button>
            <Button
              hidden={hideRewinding}
              onClick={() => {
                if (rewindingMachinesArr.length > 0) {
                  setRewindingMachinesArr(rewindingMachinesArr.slice(0, -1));
                }
                onChangeRewindingMachines();
              }}
              type="danger"
              htmlType="button"
            >
              Ukloni premotavačicu
            </Button>
            <div
              style={{
                display: "flex",
                width: "100%",
              }}
            >
              <Form.Item
                className="firstChildInRowFirstSectionANTD"
                hidden={hideShrinkSleeve}
                label="Sliviranje sa inspekcijom"
                name="shrinkSleeveInput"
              >
                <InputNumber disabled min="0" />
              </Form.Item>
            </div>
            {!isDigitalPrint && (
              <div
                style={{ display: "flex", flexDirection: "row", width: "100%" }}
              >
                <Form.Item
                  className="firstChildInRowFirstSectionANTD"
                  hidden={hidePrintingForm}
                  label="Tip štamparskih formi"
                  name="printingFormsType"
                >
                  <Select>
                    <Option value="postojece">Postojece</Option>
                    <Option value="nove">Nove</Option>
                  </Select>
                </Form.Item>
                <Form.Item
                  className="firstChildInRowFirstSectionANTD"
                  hidden={hidePrintingForm}
                  label="Štamparske forme (€)"
                  name="printingFormsPrice"
                >
                  <Input disabled></Input>
                </Form.Item>
              </div>
            )}
          </div>
          <div style={{ width: "100%" }} className="subtitle">
            Rad
          </div>
          <div style={{ display: "flex", flexDirection: "row", width: "100%" }}>
            <Form.Item
              className="firstChildInRowSecondSectionANTD"
              label="Bruto tekućih metara"
              name="grossCurrentMeters"
            >
              <Input disabled></Input>
            </Form.Item>
            {!isHiddenGrossKgPrinting && (
              <Form.Item
                className="firstChildInRowSecondSectionANTD"
                label="Bruto kilograma (kg)"
                name="grossKg"
              >
                <Input disabled></Input>
              </Form.Item>
            )}
            {!isHiddenGrossMetersPrinting && (
              <Form.Item
                className="firstChildInRowSecondSectionANTD"
                label="Bruto metara kvadratnih (m2)"
                name="grossSquareMeters"
              >
                <Input disabled></Input>
              </Form.Item>
            )}
          </div>
          <div style={{ display: "flex", flexDirection: "row", width: "100%" }}>
            <Form.Item
              hidden={isDigitalPrint}
              className="firstChildInRowSecondSectionANTD"
              label="Vrednost trajanja pripreme štamparske mašine"
              name="preparationOfPrintingPressesPrice"
            >
              <InputNumber
                onChange={() => {
                  preparationOfPrintingPressesHandler();
                }}
                min="0"
              />
            </Form.Item>
            <Form.Item
              hidden={isDigitalPrint}
              className="firstChildInRowSecondSectionANTD"
              label="Vreme pripreme štamparske mašine"
              name="preparationOfPrintingPressesHours"
            >
              <InputNumber
                onChange={() => {
                  preparationOfPrintingPressesHandler();
                }}
                min="0"
              />
            </Form.Item>
          </div>
          <div style={{ display: "flex", flexDirection: "row", width: "100%" }}>
            <Form.Item
              hidden={isDigitalPrint}
              className="firstChildInRowSecondSectionANTD"
              label="Priprema štamparske mašine (€)"
              name="preparationOfPrintingPresses"
            >
              <InputNumber disabled min="0" />
            </Form.Item>
            {!isDigitalPrint && (
              <Form.Item
                className="firstChildInRowSecondSectionANTD"
                label="Cena štampe (€/h)"
                name="printPrice"
              >
                <InputNumber min="0" />
              </Form.Item>
            )}
          </div>
          <div style={{ display: "flex", flexDirection: "row", width: "100%" }}>
            <Form.Item
              className="firstChildInRowSecondSectionANTD"
              label="Ukupna vrednost štampe"
              name="totalValueOfPrint"
            >
              <InputNumber min="0" disabled />
            </Form.Item>
          </div>

          <div>
            <div
              style={{ display: "flex", flexDirection: "row", width: "100%" }}
            ></div>
            <div
              style={{ display: "flex", flexDirection: "row", width: "100%" }}
            ></div>
            <div
              style={{ display: "flex", flexDirection: "row", width: "100%" }}
            >
              <Form.Item
                className="firstChildInRowFirstSectionANTD"
                label="Brzina štampe (m/min)"
                name="printingSpeed"
                hidden={isDigitalPrint}
              >
                <InputNumber
                  min="0"
                  onChange={() => {
                    totalTimeOfPrintingHandler();
                  }}
                />
              </Form.Item>
              <Form.Item
                label="Ukupno vreme štampe(h)"
                className="firstChildInRowFirstSectionANTD"
                name="totalTimeOfPrinting"
                hidden={isDigitalPrint}
              >
                <Input disabled />
              </Form.Item>
            </div>
            <div
              style={{ display: "flex", flexDirection: "row", width: "100%" }}
            >
              <Form.Item
                label="Promer hilzne"
                className="firstChildInRowFirstSectionANTD"
                name="promerHilzne"
              >
                <Select
                  onChange={(e) => {
                    onChangeHilzna(e);
                  }}
                >
                  {hilzne &&
                    hilzne.data &&
                    hilzne.data.items &&
                    hilzne.data.items.length > 0 &&
                    hilzne.data.items.map((item, index) => (
                      <Option key={index} value={item._id}>
                        {item.name}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
              <Form.Item
                label="Maksimalan prečnik rolne"
                className="firstChildInRowFirstSectionANTD"
                name="rollDiameter"
              >
                <Input></Input>
              </Form.Item>
            </div>

            <div
              style={{ display: "flex", flexDirection: "row", width: "100%" }}
            >
              <Form.Item
                label="Br. kom. na rolni"
                className="firstChildInRowFirstSectionANTD"
                name="rollPieces"
              >
                <Input />
              </Form.Item>
            </div>
          </div>
          <Form.Item
            hidden
            label="isSelfAdhesive"
            className="firstChildInRowFirstSectionANTD"
            name="isSelfAdhesive"
          >
            <Input />
          </Form.Item>

          <div>
            <div style={{ width: "100%" }} className="subtitle">
              Ukupan trošak/cena materijala
            </div>
            <div
              style={{ display: "flex", flexDirection: "row", width: "100%" }}
            >
              <Form.Item
                className="firstChildInRowSecondSectionANTD"
                label="Trošak el. energije (€)"
                name="totalElectricity"
              >
                <InputNumber min="0" disabled />
              </Form.Item>
              <Form.Item
                className="firstChildInRowSecondSectionANTD"
                label="Ukupna vrednost rada"
                name="totalWorkPrice"
              >
                <Input disabled />
              </Form.Item>
            </div>

            <div
              style={{ display: "flex", flexDirection: "row", width: "100%" }}
            >
              <Form.Item
                label="Ukupna cena za hilzne (€)"
                className="firstChildInRowSecondSectionANTD"
                name="hilznePrice"
              >
                <Input disabled />
              </Form.Item>
            </div>

            <div
              style={{ display: "flex", flexDirection: "row", width: "100%" }}
            >
              {!isHiddenGrossKgPrinting && (
                <Form.Item
                  className="firstChildInRowSecondSectionANTD"
                  label="Vrednost materijala za štampu (€)"
                  name="grossKgPrinting"
                >
                  <Input disabled></Input>
                </Form.Item>
              )}
              {!isHiddenGrossMetersPrinting && (
                <Form.Item
                  className="firstChildInRowSecondSectionANTD"
                  label="Vrednost materijala za štampu (€)"
                  name="grossMetersPrinting"
                >
                  <Input disabled></Input>
                </Form.Item>
              )}
              <Form.Item
                label="Ukupna vrednost svih materijala"
                className="firstChildInRowSecondSectionANTD"
                name="totalPrice"
              >
                <Input disabled />
              </Form.Item>
            </div>
            <div
              style={{ display: "flex", flexDirection: "row", width: "100%" }}
            >
              <Form.Item
                className="firstChildInRowSecondSectionANTD"
                label="Grafička priprema"
                name="graphicPreparation"
              >
                <Select
                  onChange={(e) => {
                    onChangeGraphicPreparation(e);
                  }}
                >
                  <Option value="Nova">Nova</Option>
                  <Option value="Postojeća">Postojeća</Option>
                  <Option value="Postojeća sa izmenom">
                    Postojeća sa izmenom
                  </Option>
                </Select>
              </Form.Item>
              <Form.Item
                label="Slanje na overu"
                className="firstChildInRowSecondSectionANTD"
                name="sendAuthentication"
              >
                <Select
                  onChange={() => {
                    setHideSendAuthenticationMail(
                      !form.getFieldValue("sendAuthentication"),
                    );
                  }}
                  disabled={isDisabledAuthentication}
                >
                  <Option value={true}>Da</Option>
                  <Option value={false}>Ne</Option>
                </Select>
              </Form.Item>
            </div>
            <div
              style={{ display: "flex", flexDirection: "row", width: "100%" }}
            >
              <Form.Item
                label="Ukupan iznos (€)"
                className="firstChildInRowSecondSectionANTD"
                name="totalPrice"
              >
                <Input disabled />
              </Form.Item>
              <Form.Item
                label="Slanje na overu na mejl"
                className="firstChildInRowSecondSectionANTD"
                name="sendAuthenticationMail"
                hidden={hideSendAuthenticationMail}
              >
                <Input />
              </Form.Item>
            </div>

            {data && (
              <div
                style={{ display: "flex", flexDirection: "row", width: "100%" }}
              >
                <Form.Item
                  label="Status ponude"
                  className="firstChildInRowSecondSectionANTD"
                  name="offerStatus"
                >
                  <Input disabled />
                </Form.Item>
              </div>
            )}

            <div
              style={{ display: "flex", flexDirection: "row", width: "100%" }}
            >
              {currentuser.data.role.includes("ordermanager") && (
                <>
                  <Form.Item
                    label="Odabrana prodajna cena"
                    className="firstChildInRowSecondSectionANTD"
                    name="selectedPrice"
                  >
                    <Input
                      step="0.01"
                      type="number"
                      min="0"
                      disabled={data?.offerStatus === "Verifikovana"}
                    ></Input>
                  </Form.Item>
                </>
              )}
            </div>

            <div
              style={{ display: "flex", flexDirection: "row", width: "100%" }}
            >
              {currentuser.data.role.includes("ordermanager") && (
                <>
                  <Form.Item
                    className="firstChildInRowSecondSectionANTD"
                    label="Tiraž 2"
                    name="circulationSecond"
                  >
                    <InputNumber min="0" />
                  </Form.Item>
                  <Form.Item
                    label="Odabrana prodajna cena 2"
                    className="firstChildInRowSecondSectionANTD"
                    name="selectedPriceSecond"
                  >
                    <Input
                      step="0.01"
                      type="number"
                      min="0"
                      disabled={data?.offerStatus === "Verifikovana"}
                    ></Input>
                  </Form.Item>
                </>
              )}
            </div>

            <div
              style={{ display: "flex", flexDirection: "row", width: "100%" }}
            >
              {currentuser.data.role.includes("ordermanager") && (
                <>
                  <Form.Item
                    className="firstChildInRowSecondSectionANTD"
                    label="Tiraž 3"
                    name="circulationThird"
                  >
                    <InputNumber min="0" />
                  </Form.Item>
                  <Form.Item
                    label="Odabrana prodajna cena 3"
                    className="firstChildInRowSecondSectionANTD"
                    name="selectedPriceThird"
                  >
                    <Input
                      step="0.01"
                      type="number"
                      min="0"
                      disabled={data?.offerStatus === "Verifikovana"}
                    ></Input>
                  </Form.Item>
                </>
              )}
            </div>

            <div
              style={{ display: "flex", flexDirection: "row", width: "100%" }}
            >
              {currentuser.data.role.includes("ordermanager") && (
                <>
                  <Form.Item
                    className="firstChildInRowSecondSectionANTD"
                    label="Tiraž 4"
                    name="circulationFourth"
                  >
                    <InputNumber min="0" />
                  </Form.Item>
                  <Form.Item
                    label="Odabrana prodajna cena 4"
                    className="firstChildInRowSecondSectionANTD"
                    name="selectedPriceFourth"
                  >
                    <Input
                      step="0.01"
                      type="number"
                      min="0"
                      disabled={data?.offerStatus === "Verifikovana"}
                    ></Input>
                  </Form.Item>
                </>
              )}
            </div>

            <div
              style={{ display: "flex", flexDirection: "row", width: "100%" }}
            >
              {currentuser.data.role.includes("ordermanager") && (
                <>
                  <Form.Item
                    className="firstChildInRowSecondSectionANTD"
                    label="Tiraž 5"
                    name="circulationFifth"
                  >
                    <InputNumber min="0" />
                  </Form.Item>
                  <Form.Item
                    label="Odabrana prodajna cena 5"
                    className="firstChildInRowSecondSectionANTD"
                    name="selectedPriceFifth"
                  >
                    <Input
                      step="0.01"
                      type="number"
                      min="0"
                      disabled={data?.offerStatus === "Verifikovana"}
                    ></Input>
                  </Form.Item>
                </>
              )}
            </div>

            <div
              style={{ display: "flex", flexDirection: "row", width: "100%" }}
            >
              {currentuser.data.role.includes("ordermanager") && (
                <>
                  {data?.offerStatus === "Čeka verifikaciju" && (
                    <Form.Item
                      name="priceInCurrency"
                      className="ant-form-item-control-input-content"
                      label="Prodajna cena u:"
                    >
                      <Radio.Group buttonStyle="solid">
                        <Radio.Button value="RSD">RSD</Radio.Button>
                        <Radio.Button value="EURO">EURO</Radio.Button>
                      </Radio.Group>
                    </Form.Item>
                  )}
                  {data && data?.offerStatus === "Verifikovana" && (
                    <Form.Item
                      name="priceInCurrency"
                      className="firstChildInRowSecondSectionANTD"
                      label="Prodajna cena u:"
                    >
                      <Input disabled></Input>
                    </Form.Item>
                  )}
                </>
              )}
            </div>

            <div
              style={{ display: "flex", flexDirection: "row", width: "100%" }}
            >
              <Button
                type="primary"
                onClick={createPdfHandler}
                style={{ marginTop: "10px" }}
              >
                Export PDF
              </Button>
              <Button
                onClick={() => {
                  grossCurrentMetersHandler();
                  varnishQuantityHandler();
                  grossKgHandler();
                  colorQuantityHandler();
                  quantityOfSpecialColorsHandler();
                  grossSquareMetersHandler();
                  grossKgPrintingHandler();
                  totalWorkPriceHandler();
                  printingFormsPriceHandler();
                  totalPriceOfSpecialColorsHandler();
                  preparationOfPrintingPressesHandler();
                  priceOfColorsHandler();
                  totalTimeOfPrintingHandler();
                  varnishTotalPriceHandler();
                  totalFolioHandler();
                  hilznePriceHandler();
                  grossMetersPrintingHandler();
                  totalValueOfPrintHandler();
                  handleCalculateShrinkSleeveInput();
                  totalPriceHandler();
                  calculateHandler();
                }}
                style={{ marginLeft: "10px", marginTop: "10px" }}
                type="primary"
              >
                Izračunaj
              </Button>
              <Button
                type="primary"
                htmlType="submit"
                style={{ marginTop: "10px", marginLeft: "10px" }}
              >
                Sačuvaj
              </Button>
              {data && (
                <Button
                  style={{ marginLeft: "10px", marginTop: "10px" }}
                  onClick={() => {
                    onFinishNew(form.getFieldsValue());
                  }}
                  type="primary"
                >
                  Sačuvaj kao novu
                </Button>
              )}
              {data && (
                <Button
                  type="primary"
                  style={{ marginTop: "10px", marginLeft: "10px" }}
                  onClick={() => {
                    onCreateOrderToProduction(form.getFieldsValue(), !data);
                  }}
                >
                  Sačuvaj nalog proizvodnji da proizvede
                </Button>
              )}
              {data && data.offerStatus === "Čeka verifikaciju" && (
                <Button
                  type="primary"
                  onClick={onCalculationVefication}
                  style={{ marginTop: "10px", marginLeft: "10px" }}
                >
                  Verifikuj
                </Button>
              )}
            </div>

            <div ref={ref}>
              {isVisibleTable && (
                <Table
                  pagination={false}
                  rowKey="_id"
                  columns={columns}
                  dataSource={tableData}
                ></Table>
              )}
            </div>
          </div>
        </div>
      </Form>
    </div>
  );
};

export default CalculationForm;
