import React, { useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { LoadingOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { UserContext } from "../../App";
import useAxios from "../../hooks/useAxios";
import { SERVER_URL } from "../../config";
import TypeOfVarnishingViewForm from "../../components/forms/TypeOfVarnishingViewForm";

const ViewTypeOfVarnishing = (props) => {
  const currentuser = useContext(UserContext);
  const [data, fetchData] = useAxios("", {}, currentuser.data.token, "get");
  const { typeofvarnishingId } = props.match.params;

  useEffect(() => {
    if (typeofvarnishingId)
      fetchData(`${SERVER_URL}/type-of-varnishing/${typeofvarnishingId}`, {});
  }, [fetchData, typeofvarnishingId]);

  const isDataFetched = !data.isLoading && data.data && currentuser.language;

  return (
    <div className="edit-panel">
      <div className="actions-block">
        <Link to="/admin/types-of-varnishing">
          <Button type="primary">Svi tipovi lakiranja</Button>
        </Link>
      </div>

      <div>
        {!isDataFetched && (
          <div style={{ textAlign: "center" }}>
            <LoadingOutlined
              spin
              style={{ fontSize: "3rem", marginTop: "5rem" }}
            />
          </div>
        )}

        {!typeofvarnishingId && isDataFetched && (
          <TypeOfVarnishingViewForm
            isNew={true}
            language={currentuser.language}
            SERVER_URL={SERVER_URL}
            token={currentuser.data.token}
          />
        )}

        {typeofvarnishingId && !data.isError && data.data && isDataFetched && (
          <TypeOfVarnishingViewForm
            isNew={false}
            data={data.data}
            language={currentuser.language}
            SERVER_URL={SERVER_URL}
            token={currentuser.data.token}
          />
        )}
      </div>
    </div>
  );
};

export default ViewTypeOfVarnishing;
