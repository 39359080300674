import React, { useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { Avatar, Button, Layout, Tabs, Typography, Card } from "antd";
import { EditOutlined, LoadingOutlined } from "@ant-design/icons";
import { UserContext } from "../../App";
import Table from "../../components/tables/ContactTable";
import useAxios from "../../hooks/useAxios";
import { SERVER_URL } from "../../config";
import { UserLog } from "../../components/forms";
import moment from "moment";

const { Content, Sider } = Layout;
const { Title, Text } = Typography;
const { TabPane } = Tabs;
const TABLE_COLUMN_KEYS = ["_id", "__v"];

const ViewClient = (props, editPath) => {
  const { id } = props.match.params;
  const currentuser = useContext(UserContext);
  const [client, fetchClient] = useAxios(
    "",
    null,
    currentuser.data.token,
    "get",
  );
  const [contact, fetchContact] = useAxios(
    "",
    null,
    currentuser.data.token,
    "get",
  );

  useEffect(() => {
    if (id) {
      fetchClient(`${SERVER_URL}/clients/${id}`, []);
      fetchContact(`${SERVER_URL}/activities/by-client/${id}`, []);
    }
  }, [id, fetchClient, fetchContact]);

  const getUrl = (itemUrl) => {
    let url = itemUrl.includes("http") ? itemUrl : SERVER_URL + itemUrl;
    return url;
  };

  let columnKeys;
  if (contact.data && contact.data.items && contact.data.items.length > 0) {
    const keys = Object.keys(contact.data.items[0]);
    columnKeys = keys.filter((k) => !TABLE_COLUMN_KEYS.includes(k));
  }
  let tableData = [];
  const dateOptions = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
  };
  if (contact.data && contact.data.items && contact.data.items.length > 0) {
    tableData = contact.data.items.map((item) => {
      item.dateTime = new Date(item.dateTime).toLocaleDateString(
        "sr-Latn-RS",
        dateOptions,
      );
      item.updatedAt = new Date(item.updatedAt).toLocaleString();
      item.user = item.userId?.firstName + " " + item.userId?.lastName;
      return item;
    });
  }

  if (tableData) {
    tableData.sort(
      (a, b) =>
        new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime(),
    );
  }

  return (
    <div className="content-wrapper">
      <div className="actions-block">
        <Link to="/admin/clients">
          <Button type="primary">Svi klijenti</Button>
        </Link>
        <Link to="/admin/new-contact">
          <Button
            style={{ marginLeft: "10px" }}
            className="button-import"
            type="primary"
          >
            Nova radna aktivnost
          </Button>
        </Link>
      </div>

      <div className="content content-full-height">
        {id && client.isLoading && <LoadingOutlined className="loader" spin />}
        {id && !client.isLoading && client.isError && (
          <h2 style={{ marginTop: "5rem" }}>Something went wrong :(</h2>
        )}
        {id && client && !client.isLoading && !client.isError && (
          <Layout className="site-layout-background">
            <Sider className="site-layout-background" width={300} theme="light">
              {/* <Sider className='site-layout-background sider-small-screen' theme='light'> */}
              <div className="logo" style={{ height: "auto" }}>
                <Avatar
                  size={100}
                  src={
                    client.data && client.data.avatar
                      ? getUrl(client.data.avatar.url)
                      : "Logo"
                  }
                />

                <Link to={"/admin/edit-client/" + id}>
                  <Button
                    type="primary"
                    block
                    icon={<EditOutlined />}
                    style={{ marginTop: "1rem" }}
                  >
                    EDITUJ KLIJENTA
                  </Button>
                </Link>
              </div>
              <Content style={{ padding: "15px", minHeight: 280 }}>
                {/*<Title style={{ fontSize: '14px' }}> ROLE</Title>*/}
                {/*<p style={{ display: 'block', fontSize: '14px', margin: '0 0 15px', textTransform: 'capitalize' }}> {client.data ? client.data.role[0] : 'Role'}</p>*/}
                <Title style={{ fontSize: "14px" }}> E-MAIL</Title>
                <p
                  style={{
                    display: "block",
                    fontSize: "14px",
                    margin: "0 0 15px",
                  }}
                >
                  {" "}
                  {client.data ? client.data.email : "Email"}
                </p>
              </Content>
            </Sider>
            <Content style={{ padding: "0 15px", minHeight: 280 }}>
              <Tabs defaultActiveKey="1" /* onChange={callback} */>
                <TabPane tab="PROFIL" key="1">
                  <div className="card-wrapper">
                    {/* <Card title='User information' bordered={false}> */}
                    <Card
                      className="profile-title"
                      title="Informacije o klijentu"
                      bordered={false}
                    >
                      <p>
                        <Text strong>Ime kompanije: </Text>
                        {client.data && client.data.companyName
                          ? client.data.companyName
                          : "Nema podataka"}
                      </p>
                      <p>
                        <Text strong>E-mail: </Text>
                        {client.data && client.data.email
                          ? client.data.email
                          : "Nema podataka"}
                      </p>
                      <p>
                        <Text strong>Adresa: </Text>
                        {client.data && client.data.address
                          ? client.data.address
                          : "Nema podataka"}
                      </p>
                      <p>
                        <Text strong>Telefon: </Text>
                        {client.data && client.data.phone
                          ? client.data.phone
                          : "Nema podataka"}
                      </p>
                      <p>
                        <Text strong>Status: </Text>
                        {client.data && client.data.status
                          ? client.data.status
                          : "Nema podataka"}
                      </p>
                      <p>
                        <Text strong>Istorija radnih aktivnosti: </Text>
                      </p>
                      <div style={{ overflowX: "auto" }}>
                        <Table
                          title="Contact"
                          type="client"
                          viewPath="/admin/view-contact/"
                          columnKeys={columnKeys}
                          data={tableData}
                        />
                      </div>
                    </Card>
                  </div>
                </TabPane>
              </Tabs>
            </Content>
          </Layout>
        )}
      </div>
    </div>
  );
};

export default ViewClient;
