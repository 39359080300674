import React, {useContext, useEffect, useState} from 'react';
import {Table, Input, Button, Popconfirm, DatePicker} from 'antd';
import {DeleteOutlined, EditOutlined, EyeOutlined, SearchOutlined} from '@ant-design/icons';
import ResizableAntdTable from 'resizable-antd-table';
import { UserContext } from '../../App';
import {Link} from "react-router-dom";
import ExportExcel from "../csv/ExportExcel";
import Axios from 'axios';
import {SERVER_URL} from "../../config";
import moment from "moment";

const ContactTable= ({ data, deleteHandler, columnKeys, title, editPath, viewPath, type, id  }) => {
    const user = useContext(UserContext);
    let [queryObj, setQueryObj] = useState({});
    let [filteredData, setFilteredData] = useState([]);
    let searchInput;

    useEffect(() => {
        setFilteredData(data)
    }, [data]);

    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
            return (
              <div style={{ padding: 8 }}>
                  { dataIndex === 'dateTime' ?
                    <DatePicker.RangePicker
                      ref={(node) => {
                          searchInput = node;
                      }}
                      value={selectedKeys[0]}
                      onChange={(value) => {handleInputChange(dataIndex, value); setSelectedKeys([value])}}
                      onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                      style={{ width: 188, marginBottom: 8, display: 'block' }}
                      placeholder={['Datum od','Datum do']}
                    />
                    : <Input
                      ref={(node) => {
                          searchInput = node;
                      }}
                      placeholder={`Pretrazi ${dataIndex}`}
                      value={selectedKeys[0]}
                      onChange={(e) => {handleInputChange(dataIndex, e.target.value); setSelectedKeys(e.target.value ? [e.target.value] : [])}}
                      onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                      style={{ width: 188, marginBottom: 8, display: 'block' }}
                    />}
                  <Button type='primary' onClick={() => handleSearch(selectedKeys, confirm, dataIndex)} size='small' style={{ width: 90, marginRight: 8 }}>
                      Search
                  </Button>
                  <Button onClick={() => handleReset(clearFilters, dataIndex)} size='small' style={{ width: 90 }}>
                      Reset
                  </Button>
              </div>
            );
        },
        filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? 'red' : '#bbb', fontSize: '1rem' }} />,
        onFilter: (value, record) => {
            const filterRecord = Array.isArray(dataIndex) ? !dataIndex.includes('category') ? record[dataIndex[0]][dataIndex[1]] : record[dataIndex[0]][dataIndex[1]][dataIndex[2]] : record[dataIndex];
            if (!filterRecord || !value) return false;
            const validate = dataIndex === 'dateTime' ? new Date(filterRecord) >= new Date(value[0]) && new Date(filterRecord) <= new Date(value[1]) : filterRecord.toString().toLowerCase().includes(value.toLowerCase());
            return validate;
        },
        onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
                setTimeout(() => {
                    searchInput.focus();
                });
            }
        },
    });

    const getFilteredData = async (obj) => {
        await Axios.get(`${SERVER_URL}/activities-filter/${id}?dataIndex=${JSON.stringify(obj)}`, { withCredentials: false, headers: { Authorization: `Bearer ${user.data.token}` }}).then((res) => {
            setFilteredData(res.data);
        }).catch(err => {
            console.log(err.message);
        })
    }

    const handleSearch = async (selectedKeys, confirm, dataIndex) => {
        await getFilteredData(queryObj)
        confirm();
    };

    const handleReset = async (clearFilters, dataIndex) => {
        const obj = {...queryObj};
        delete obj[dataIndex];
        setQueryObj(obj);
        await getFilteredData(obj)
        clearFilters();
    };


    const handleInputChange = async (columnName, value) => {
        const obj = {...queryObj};
        obj[columnName] = value;
        setQueryObj(obj);
    }

    const columns = [
        {
          key: 'User',
          title: type === 'user' ? 'Klijent' : 'Prodavac',
          dataIndex: type === 'user' ? 'client' : 'user',
          ...getColumnSearchProps(type === 'user' ? 'client' : 'user')
        },
        {
            key: 'comment',
            title: 'Komentar',
            dataIndex: 'comment',
            ...getColumnSearchProps('comment'),
        },
        {
            key: 'date',
            title: 'Datum',
            dataIndex: 'dateTime',
            ...getColumnSearchProps('dateTime'),
        }
    ]
    columns.push({
        title: 'Akcija',
        render: (text, record) => (
          <div style={{ display: 'inline-flex', justifyContent: 'center', alignItems: 'center', fontSize: '15px' }}>
              {viewPath && (
                <div style={{ margin: '2px', padding: '4px', cursor: 'pointer' }}>
                    <Link to={viewPath + record._id}>
                        <EyeOutlined title={`View ${title.toLowerCase()}`} style={{ textDecoration: 'none', color: 'black' }} />
                    </Link>
                </div>
              )}
          </div>
        ),
    });
   filteredData = filteredData.map((item) => {
       item.clientStatus = item.clientId ? item.clientId.status : '';
       return item;
   })
    return (
      <div>
          <ResizableAntdTable
            size='middle'
            bordered
            dataSource={data}
            columns={columns}
            rowClassName={(record,index) => record.clientId ? record.clientId.status === "AKTIVAN" ? 'table-row-client-active'
                : record.clientId.status === "NEZAINTERESOVAN" ? 'table-row-client-uninterested' : record.clientId.status === "USPOSTAVLJEN KONTAKT" ? 'table-row-client-contacted'
                : record.clientId.status === "POSLATA PONUDA" ? 'table-row-client-offer-sent' : 'table-row-client-uncontacted' : null}

            rowKey='_id'
            pagination={{
                defaultPageSize: 100,
                position: 'bottom',
                showSizeChanger: false,
                pageSizeOptions: ['10', '20', '50', '100'],
                hideOnSinglePage: true,
            }}
          />
          <div style={{ margin: '10px 0px' }}>
              <ExportExcel
                csvData={filteredData != null && filteredData.length >= 1 ? filteredData : []}
                fileName={`Radne aktivnosti prodavca ` + moment(new Date()).format('DD:MM:YYYY HH:MM')}
                propsToRemove={['_id', 'clientId', 'userId', 'updatedAt', '__v', 'createdAt']}
              />
          </div>
      </div>
    );
};
export default ContactTable;
