import React, {useContext} from 'react';
import { UserContext } from '../../App';
import {Route, Switch, Redirect, BrowserRouter as Router} from 'react-router-dom';
import { ROUTES } from '../../config';
import ProtectedRoute from './ProtectedRoute';
import Login from '../../pages/Login';
import Home from '../../pages/Home';
import EditContact from "../../pages/contact/EditContact";
import EmailVerification from "../../pages/EmailVerification";

const Routes = () => {
  const user = useContext(UserContext);

  return (
    <Switch>
        <ProtectedRoute exact user={user} path='/admin/edit-contact/:id' component={EditContact} allowed={['admin', 'user']}/>
        <ProtectedRoute user={user} exact path='/admin' component={Home} allowed={['admin', 'user']} />
      {/* routes from routeConfig file */}
      {ROUTES.map((route) =>
        route.children.map((item, i) => <ProtectedRoute exact user={user} key={`R_${i}`} path={item.path} component={item.component} allowed={item.allowed} />),
      )}
      <Route exact path='/login' render={() => (user && user.isLoggedIn ? <Redirect to='/admin' /> : <Login />)} />
      <ProtectedRoute user={user} exact path='/email-verification/:token' component={EmailVerification}/>
      <Route render={() => <Redirect to='/admin' />} />
    </Switch>
  );
};

export default Routes;
