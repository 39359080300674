import React, { useContext, useState, useEffect } from "react";
import CalculationSheetForm from "../../components/forms/CalculationSheetForm";
import useAxios from "../../hooks/useAxios";
import { Form, notification } from "antd";
import { UserContext } from "../../App";
import { SERVER_URL } from "../../config";
import { useHistory } from "react-router-dom";
import Axios from "axios";

const EditCalculation = (props) => {
  const currentuser = useContext(UserContext);
  const token = currentuser.data.token;
  const history = useHistory();
  const { calculationId } = props.match.params;

  const [calculation, fetchCalculation] = useAxios("", null, token, "get");

  useEffect(() => {
    if (calculationId) {
      fetchCalculation(`${SERVER_URL}/calculations/${calculationId}`, []);
    }
  }, [fetchCalculation, calculationId]);

  const onSubmit = async (formData, isNew) => {
    const method = isNew ? "post" : "put";

    const route = isNew
      ? `${SERVER_URL}/calculations`
      : `${SERVER_URL}/calculations/${calculationId}`;
    formData.date = new Date();

    try {
      await Axios[method](route, formData, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });

      notification.success({
        message: "Kalkulacija je sačuvana.",
        placement: "bottomRight",
      });
      history.push("/admin/calculations");
    } catch (error) {
      const msg = error.response
        ? error.response.data.message
        : error.message
        ? error.message
        : error;

      notification.error({
        message: "Greška pri čuvanju.",
        placement: "bottomRight",
      });
    }
  };

  return (
    <div style={{ padding: "8px" }}>
      <div style={{ textAlign: "center" }}>
        {!calculationId && (
          <CalculationSheetForm data={calculation.data} onSubmit={onSubmit} />
        )}
        {calculationId && (
          <CalculationSheetForm data={calculation.data} onSubmit={onSubmit} />
        )}
      </div>
    </div>
  );
};

export default EditCalculation;
