import React, { useEffect, useContext } from "react";
import Axios from "axios";
import { Link, useHistory } from "react-router-dom";
import { LoadingOutlined } from "@ant-design/icons";
import { notification, Button } from "antd";
import { UserContext } from "../../App";
import useAxios from "../../hooks/useAxios";
import Table from "../../components/tables/BoxTable";
import { SERVER_URL } from "../../config";

const Box = () => {
  const SERVER_URL_PRODUCTION =
    process.env.NODE_ENV === "production"
      ? "https://birografika-api.concordsoft.solutions"
      : "http://localhost:3334";
  const currentuser = useContext(UserContext);
  const [data, fetchData] = useAxios("", [], currentuser.data.token, "get");
  const history = useHistory();

  useEffect(() => {
    fetchData(`${SERVER_URL_PRODUCTION}/boxes`, []);
  }, [fetchData]);

  const deleteDataHandler = async (id) => {
    try {
      await Axios.delete(`${SERVER_URL_PRODUCTION}/box/${id}`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });
      notification.success({
        message: "Kutija je obrisana.",
        placement: "bottomRight",
      });
      history.push("/admin");
      history.push("/admin/boxes");
    } catch (err) {
      notification.error({
        message: "Problem sa brisanjem.Pokušajte kasnije.",
        placement: "bottomRight",
      });
    }
  };

  let tableData = [];
  if (data.data && data.data.items && data.data.items.length > 0) {
    tableData = data.data.items.map((item) => {
      item.createdAt = new Date(item.createdAt).toLocaleString();
      item.updatedAt = new Date(item.updatedAt).toLocaleString();
      return item;
    });
  }

  return (
    <div className="table" style={{ padding: "8px" }}>
      <div className="actions-block">
        <Link to="/admin/new-box">
          <Button type="primary">Kreiraj kutiju</Button>
        </Link>
        <Link to="/admin/settings">
          <Button style={{ marginLeft: "10px" }} type="primary">
            Podešavanja
          </Button>
        </Link>
      </div>

      <div style={{ textAlign: "center" }}>
        {data.isLoading && (
          <LoadingOutlined
            spin
            style={{ fontSize: "3rem", marginTop: "5rem" }}
          />
        )}
        {!data.isLoading &&
          data.data &&
          data.data.items &&
          data.data.items.length > 0 && (
            <Table
              data={tableData}
              deleteHandler={deleteDataHandler}
              title="Kutije"
              editPath="/admin/edit-box/"
            />
          )}
        {!data.isLoading &&
          data.data &&
          data.data.items &&
          data.data.items.length === 0 && (
            <div>
              <h2>Nema dodatih kutija.</h2>
            </div>
          )}
      </div>
    </div>
  );
};

export default Box;
