import React, { useEffect, useState } from "react";
import { Radio, Card, Input, Button, Form, InputNumber, Select } from "antd";
import Axios from "axios";
import useAxios from "../../hooks/useAxios";
import { SERVER_URL } from "../../config";
import moment from "moment";
import TextArea from "antd/lib/input/TextArea";

const layout = {
  labelCol: { span: 5 },
  wrapperCol: { span: 9 },
};
const { Option } = Select;

const OfferForm = ({ isNew, data, onSubmit, token }) => {
  const [form] = Form.useForm();
  const [isPaymentInputVisible, setIsPaymentInputVisible] = useState(false);
  const [calculations, fetchCalculations] = useAxios("", null, token, "get");
  let initialValues = { ...data };

  useEffect(() => {
    fetchCalculations(
      `${SERVER_URL}/calculations?filter={"offerCreated":false, "offerStatus":"Verifikovana" }`,
      [],
    );
  }, []);

  const onFinish = async (values, isNew) => {
    if (!isNew) {
      values._id = data._id;
    }
    onSubmit(values, isNew);
  };

  const onChangePaymentType = async (e) => {
    if (e === "virmanski") {
      setIsPaymentInputVisible(false);
    } else {
      setIsPaymentInputVisible(true);
    }
  };

  const onChangeCalculation = (e) => {
    calculations.data.items.forEach((element) => {
      if (element._id === e[e.length - 1]) {
        form.setFieldsValue({
          email: element.client?.email,
          contactPerson: element.client?.contactPerson,
          subject: element.businessName,
          currency: element.client?.currency,
        });
      }
    });

    if (e.length < 1) {
      form.setFieldsValue({
        email: "",
        contactPerson: "",
        subject: "",
        currency: "",
      });
    }
  };

  return (
    <div className="panel panel-body ">
      <div className="panel-body">
        <Card title={`${isNew ? "NOVA PONUDA" : "IZMENA PONUDE"}`}>
          <Form
            {...layout}
            initialValues={initialValues}
            onFinish={(values) => onFinish(values, !data)}
            layout="vertical"
            form={form}
          >
            <div className="mainCont">
              <Form.Item
                required
                name="calculations"
                className="ant-form-item-control-input-content"
                label="Kalkulacije:"
              >
                <Select
                  mode="multiple"
                  dropdownStyle={{ minWidth: "70%" }}
                  onChange={(e) => {
                    onChangeCalculation(e);
                  }}
                >
                  {calculations &&
                    calculations.data &&
                    calculations.data.items &&
                    calculations.data.items.length > 0 &&
                    calculations.data.items.map((item, index) => {
                      return (
                        <Option value={item._id}>
                          {moment(item.createdAt).format("DD.MM.YYYY")} - {}
                          {item?.client?.companyName} - {item.businessName}
                        </Option>
                      );
                    })}
                </Select>
              </Form.Item>
            </div>

            <div className="panel-body">
              <Form.Item
                required
                name="contactPerson"
                className="ant-form-item-control-input-content"
                label="Kontakt osoba:"
              >
                <Input />
              </Form.Item>
              <Form.Item
                required
                name="email"
                className="ant-form-item-control-input-content"
                label="Email:"
              >
                <Input />
              </Form.Item>
              <Form.Item
                required
                name="subject"
                className="ant-form-item-control-input-content"
                label="Ponuda izrade:"
              >
                <Input />
              </Form.Item>
              <Form.Item
                required
                name="unit"
                className="ant-form-item-control-input-content"
                label="Jedinica mere:"
              >
                <Input />
              </Form.Item>
              <Form.Item
                required
                name="delivery"
                className="ant-form-item-control-input-content"
                label="Isporuka:"
              >
                <Input />
              </Form.Item>
              <Form.Item
                required
                name="deliveryTime"
                className="ant-form-item-control-input-content"
                label="Rok isporuke(do radnih dana):"
              >
                <Input />
              </Form.Item>
              <Form.Item
                required
                name="deliveryDesc"
                className="ant-form-item-control-input-content"
                label="Rok isporuke(do):"
              >
                <Select dropdownStyle={{ minWidth: "80%" }}>
                  <Option value="radnih dana od dana overe elektronske pripreme za štampu">
                    radnih dana od dana overe elektronske pripreme za štampu
                  </Option>
                  <Option value="radnih dana od overe elektronske pripreme za štampu, za prvu isporuku (zbog nabavke alata i izrade štamparskih formi) a za svaku sledeću po dogovoru">
                    radnih dana od overe elektronske pripreme za štampu, za prvu
                    isporuku (zbog nabavke alata i izrade štamparskih formi) a
                    za svaku sledeću po dogovoru
                  </Option>
                </Select>
              </Form.Item>
              <Form.Item
                required
                name="currency"
                className="ant-form-item-control-input-content"
                label="Valuta(dana od dana otpreme robe):"
              >
                <Input />
              </Form.Item>
              <Form.Item
                required
                name="finishing"
                className="ant-form-item-control-input-content"
                label="Dorada:"
              >
                <Input />
              </Form.Item>
              <Form.Item
                required
                name="paymentType"
                className="ant-form-item-control-input-content"
                label="Plaćanje:"
              >
                <Select
                  onChange={(e) => {
                    onChangePaymentType(e);
                  }}
                >
                  <Option value="virmanski">Virmanski</Option>
                  <Option value="drugo">Drugo</Option>
                </Select>
              </Form.Item>

              {isPaymentInputVisible && (
                <Form.Item
                  required
                  name="paymentInput"
                  className="ant-form-item-control-input-content"
                  label="Plaćanje:"
                >
                  <Input />
                </Form.Item>
              )}
            </div>

            <div className="text-right">
              <Button type="primary" htmlType="submit">
                {isNew ? "Dodaj" : "Izmeni"} ponudu
              </Button>
            </div>
          </Form>
        </Card>
      </div>
    </div>
  );
};

export default OfferForm;
