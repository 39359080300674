import React, {useContext, useEffect} from "react";
import {Button, Card, Layout, Typography} from "antd";
import {Link} from "react-router-dom";
import {LoadingOutlined} from "@ant-design/icons";
import useAxios from "../../hooks/useAxios";
import {UserContext} from "../../App";
import {SERVER_URL} from "../../config";
const { Content } = Layout;
const { Text } = Typography;

const ViewContact = (props) => {
	const currentuser = useContext(UserContext);
	const [contact, fetchContact] = useAxios('', null, currentuser.data.token, 'get');
	const { id } = props.match.params;

	useEffect(() => {
		if (id) {
			fetchContact(`${SERVER_URL}/activities/${id}`, []);
		}
	}, [id, fetchContact]);

	const dateOptions = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' };

	return(
		<div className='content-wrapper'>
			<div className='actions-block'>
				<Link to='/admin/contacts'>
					<Button type='primary'>Sve radne aktivnosti</Button>
				</Link>
			</div>

			<div className='content content-full-height'>
				{id && contact.isLoading && <LoadingOutlined className='loader' spin />}
				{id && !contact.isLoading && contact.isError && <h2 style={{ marginTop: '5rem' }}>Something went wrong :(</h2>}
				{id && contact && !contact.isLoading && !contact.isError && (
				<Layout className='site-layout-background'>
					<Content style={{ padding: '0 15px', minHeight: 280 }}>
						<div className='card-wrapper'>
							<Card className="profile-title" title='Informacije o radnoj aktivnosti' bordered={false}>
								<p>
									<Text strong>Prodavac: </Text>
									{contact.data && contact.data.userId ? contact.data.userId.firstName + ' ' + contact.data.userId.lastName : 'Nema podataka'}
								</p>
								<p>
									<Text strong>Klijent: </Text>
									{contact.data && contact.data.clientId ? contact.data.clientId.companyName : 'Nema podataka'}
								</p>
								<p>
									<Text strong>Datum radne aktivnosti: </Text>
									{contact.data && contact.data.dateTime ? new Date(contact.data.dateTime).toLocaleDateString('sr-Latn-RS', dateOptions) : 'Nema podataka'}
								</p>
								<p>
									<Text strong>Komentar: </Text>
									{contact.data && contact.data.comment ? contact.data.comment : 'Nema podataka'}
								</p>
							</Card>
						</div>
					</Content>
				</Layout>)}
			</div>
		</div>
	)
}

export default ViewContact;
