import React, {useContext, useEffect, useState} from 'react';
import { Form, Input, Button, Card } from 'antd';
import { UserContext } from '../App';
import useAxios from "../hooks/useAxios";
import {SERVER_URL} from "../config";
import Axios from "axios";

const layout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 18 },
};
const tailLayout = {
    wrapperCol: { span: 24 },
};

const EmailVerification = (props) => {
    const user = useContext(UserContext);
    const onFinish = (values) => {
        user.handleLogin(values);
    };
    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    useEffect(() => {
        Axios.get(`${SERVER_URL}/email-verification/${props.match.params.token}`).then((resp) => {
        }).catch((err) => {
            console.log(err.response ? err.response.data.message : err.message);
        });
    }, []);

    return (
        <div className="log">
            <div className="card-wrapper">
                <Card title="Universal API dashboard" bordered={false}>
                    <Form {...layout} name='basic' onFinish={onFinish} onFinishFailed={onFinishFailed}>
                        <Form.Item label='Email' name='email' rules={[{ required: true, message: 'Please enter your email!' }]}>
                            <Input />
                        </Form.Item>

                        <Form.Item label='Password' name='password' rules={[{ required: true, message: 'Please enter your   password!' }]}>
                            <Input.Password />
                        </Form.Item>

                        <Form.Item {...tailLayout} style={{ marginBottom: 0 }}>
                            <Button type='primary' htmlType='submit' style={{ float: "right" }}>
                                Submit
                            </Button>
                        </Form.Item>
                    </Form>
                </Card>
            </div>
        </div>
    );
};

export default EmailVerification;
