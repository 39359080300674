import React from "react";
import {
  Card,
  Form,
  Row,
  Col,
  Input,
  Select,
  Divider,
  Radio,
  Typography,
  Button,
  Table,
  InputNumber,
} from "antd";
import moment from "moment";
import { useState } from "react";
import { useEffect } from "react";
const { TextArea } = Input;
const { Option } = Select;
const { Text } = Typography;

const formInit = {
  date: undefined,
  exchangeRate: undefined,
  businessName: undefined,
  client: undefined,
  typeOfLabel: undefined,
  height: undefined,
  width: undefined,
  circulation: undefined,
  calculatedWastePaper: undefined,
  calculatedWastePaperSheet: undefined,
  numberOfRowsToCut: 1,
  numberOfColors: 1,
  degreeOfPaintConsumption: undefined,
  degreeOfVarnishConsumption: undefined,
  numberOfSpecialColors: undefined,
  quantityOfSpecialColors: undefined,
  varnishing: false,
  folioPrinting: false,
  printingForms: false,
  design: false,
  boxes: false,
  transport: false,
  subcontractors: false,
  tigl: false,
  bending: false,
  materialWidth: undefined,
  materialPrice: undefined,
  preparationOfPrintingPresses: undefined,
  // totalPreparationOfPrintingPresses: undefined,
  colorQuantity: undefined,
  colorPrice: 17,
  varnishPrice: 11,
  varnishQuantity: undefined,
  folioPrice: undefined,
  folioWidth: undefined,
  designInput: undefined,
  boxesPrice: undefined,
  boxesQuantity: undefined,
  transportInput: undefined,
  subcontractorInput: undefined,
  priceOfSpecialColors: 0,
  printingFormsPrice: undefined,
  printingSpeed: 1,
  totalTimeOfPrinting: 1,
  grossCurrentMeters: undefined,
  grossMetersPrinting: undefined,
  grossKgPrinting: undefined,
  grossMetersFolio: undefined,
  totalPrice: undefined,
  printPrice: undefined,
  machine: undefined,
};

const OldCalculationForm = (props) => {
  // console.log("props", props);
  const [sheetLabel, setSheetLabel] = useState();
  const [isPrintingSpeedHidden, setIsPrintingSpeedHidden] = useState(false);
  const [printPages, setPrintPages] = useState();
  const [tooWide, setTooWide] = useState(false);
  let layout = {
    labelCol: { span: 12 },
    wrapperCol: { span: 20 },
  };

  const {
    data,
    calculate,
    onSubmit,
    createPdf,
    onChangeNumberOfRows,
    onChangeLabelHeight,
    onChangeLabelWidth,
    onChangeNumberOfColors,
    hideColorQuantity,
    onChangeQuantityOfSpecialColors,
    hideQuantitySpecialColors,
    onChangeFolioPrinting,
    hideFolioWidth,
    hideGrossFolio,
    hideFolioPrice,
    hidePrintingForm,
    onChangePrintingForm,
    totalPrintingTimeHandler,
    onChangeDesign,
    hideDesign,
    hideBoxes,
    onChangeBoxes,
    hideTransport,
    onChangeTransport,
    hideSubcontractors,
    onChangeSubcontractor,
    onChangeVarnishing,
    hideDegreeOfVarnishConsumption,
    onChangeTypeOfLabel,
    onChangeMachine,
    hidePrintingSpeed,
    hidePrintingSpeedOther,
    hideTotalTimeOfPrinting,
    hideTotalTimeOfPrintingOther,
    materialPricePlaceholder,
    hideGrossCurrentMeters,
    hideGrossMetersPrinting,
    hideGrossKgPrinting,
    hideVarnishQuantity,
    hideVarnishPrice,
    visible,
    hideColorPrice,
    onChangeCalculatedWastePaper,
    hideTotalPreparationOfPrintingPresses,
    degreeOfVarnishConsumptionPlaceholder,
    tableVisible,
    dataTable,
    hideTotal,
    form,
    hideTotalPrice,
    isDigitalPrint,
    printType,
    setPrintType,
    calculateSheet,
    changeMachine,
    hideFolioPage,
    materialPriceHandler,
    hideTotalElectricityOther,
    hideLabelNum,
    onChangeTigl,
    hideFolioPrinting,
    hideTigl,
    hideBending,
    onChangeBending,
    onChangeMachineSheet,
    maxWidthCheck,
    machine,
    materialWidth,
  } = props;

  let initialValues = {
    ...formInit,
    ...{
      ...data,
      date: data
        ? moment(data.date).format("DD.MM.YYYY")
        : moment().format("DD.MM.YYYY"),
    },
  };

  initialValues.printingSpeedOtherNumber = initialValues.printingSpeed;
  initialValues.printingSpeed = initialValues.printingSpeed;

  const typeOfLabel = isDigitalPrint
    ? ["samolepljiva papirna", "samolepljiva PP"]
    : [
        "OPP beli",
        "OPP transparentni 35 mic",
        "OPP transparentni 40 mic",
        "SHRINK PVC 45 mic",
        "SHRINK PVC 50 mic",
        "SHRINK PET 45 mic",
        "SHRINK PET 50 mic",
        "samolepljiva papirna",
        "samolepljiva termo ECO",
        "samolepljiva termo TOP",
        "samolepljiva PP",
        "drugo",
      ];
  const typeLabelSheet = [
    "OFSET",
    "KUNST.",
    "SAMOLEPLJIVI",
    "TRIPLEX/KARTON",
    "Ostalo",
  ];
  let columns =
    form.getFieldValue("machine") !== "Konica Minolta" &&
    form.getFieldValue("machine") !== "Konica Minolta Tabak"
      ? [
          {
            key: "1",
            title: "Euro",
            dataIndex: "euro",
            children: [
              {
                id: 11,
                title: "Materijal",
                dataIndex: "material",
                key: "Material",
              },
              {
                id: 12,
                title: "Rad",
                dataIndex: "work",
                key: "work",
              },
              {
                id: 13,
                title: "Ceo tiraž",
                dataIndex: "circulation",
                key: "circulation",
              },
              {
                id: 14,
                title: "Cena po komadu (na 1000 kom)",
                dataIndex: "pricePerPiece",
                key: "pricePerPiece",
              },
            ],
          },
          {
            key: "basic price",
            title: "Osnovna cena",
            dataIndex: "basicPrice",
          },
          {
            key: 2,
            title: "RSD",
            children: [
              {
                id: 21,
                title: "Cena po komadu (na 1000 kom)",
                dataIndex: "pricePerPieceRsd",
              },
              {
                id: 22,
                title: "Ceo tiraž",
                dataIndex: "circulationRsd",
              },
              {
                id: 23,
                title: "Rad",
                dataIndex: "workRsd",
              },

              {
                id: 24,
                title: "Materijal",
                dataIndex: "materialRsd",
              },
            ],
          },
        ]
      : [
          {
            key: "basic price",
            title: "Osnovna cena",
            dataIndex: "basicPrice",
          },
          {
            key: 2,
            title: "RSD",
            children: [
              {
                id: 21,
                title: "Cena po komadu (na 1000 kom)",
                dataIndex: "pricePerPieceRsd",
              },
              {
                id: 22,
                title: "Ceo tiraž",
                dataIndex: "circulationRsd",
              },
            ],
          },
        ];

  if (isDigitalPrint) {
    form.setFieldsValue({ materialWidth: 330 });
  }
  if (!form.getFieldValue("calculatedWastePaperSheet")) {
    if (printPages === "dvostrana") {
      form.setFieldsValue({ calculatedWastePaperSheet: 250 });
    } else if (sheetLabel === "SAMOLEPLJIVI" || printPages === "jednostrana") {
      form.setFieldsValue({ calculatedWastePaperSheet: 200 });
    } else if (
      data &&
      data.calculatedWastePaperSheet ===
        form.getFieldValue("calculatedWastePaperSheet")
    ) {
      form.setFieldsValue({
        calculatedWastePaperSheet: data.calculatedWastePaperSheet,
      });
    }
  }
  if (
    sheetLabel === "SAMOLEPLJIVI" &&
    (printPages || form.getFieldValue("printPages"))
  ) {
    setPrintPages(undefined);
    form.setFieldsValue({
      printPages: undefined,
    });
  }

  useEffect(() => {
    if (materialWidth > maxWidthCheck) {
      setTooWide(true);
    } else {
      setTooWide(false);
    }
  }, [form, materialWidth, maxWidthCheck]);

  return (
    <div id="container-div">
      <div className="dashboard calc-form">
        {/* eslint-disable-next-line no-useless-concat */}
        <Card title="PREGLED STARE KALKULACIJE - ARHIVA" bordered={false}>
          <Form
            {...layout}
            name="basic"
            layout="horizontal"
            form={form}
            initialValues={initialValues}
          >
            {!printType && !data && (
              <div style={{ width: "37%" }}>
                <Row type="flex" gutter={16} style={{ width: "100%" }}>
                  <Col xs={24} md={15}>
                    <Form.Item label="Izaberite vrstu štampe" name="printType">
                      <Select>
                        <Option value="tabak">Tabak</Option>
                        <Option value="rolna">Rolna</Option>
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Button
                    type="primary"
                    onClick={() =>
                      setPrintType(form.getFieldValue("printType"))
                    }
                  >
                    Potvrdi
                  </Button>
                </Row>
              </div>
            )}

            {(printType || data) && (
              <Row type="flex" gutter={16}>
                <Col xs={24} md={7}>
                  <div className="first-column">
                    <Form.Item label="Datum" name="date">
                      <Input />
                    </Form.Item>
                    <Form.Item label="Kurs" name="exchangeRate">
                      <Input />
                    </Form.Item>
                    <Form.Item label="Naziv posla" name="businessName">
                      <TextArea rows={3} />
                    </Form.Item>
                    <Form.Item label="Poručilac" name="client">
                      <TextArea rows={3} />
                    </Form.Item>
                    <Form.Item label="Mašina" name="machine">
                      <Select
                        onChange={
                          printType === "tabak"
                            ? () => onChangeMachineSheet(form)
                            : onChangeMachine
                        }
                        // disabled={printType === 'tabak'}
                      >
                        {printType === "rolna" && (
                          <>
                            <Option value="Arsoma">Arsoma</Option>
                            <Option value="Mark Andy">Mark Andy</Option>
                            <Option value="MPS">MPS</Option>
                            <Option value="Konica Minolta">
                              Konica Minolta
                            </Option>
                          </>
                        )}
                        {printType === "tabak" && (
                          <>
                            <Option value="Konica Minolta Tabak">
                              Konica Minolta Tabak
                            </Option>
                            <Option value="Roland">Roland</Option>
                          </>
                        )}
                      </Select>
                    </Form.Item>
                    <Form.Item label="Vrsta materijala" name="typeOfLabel">
                      <Select
                        onChange={(value) => {
                          setSheetLabel(value);
                          onChangeTypeOfLabel(value, form);
                        }}
                      >
                        {printType === "rolna"
                          ? typeOfLabel.map((item, index) => (
                              <Option key={index} value={item}>
                                {item}
                              </Option>
                            ))
                          : typeLabelSheet.map((item, index) => (
                              <Option key={index} value={item}>
                                {item}
                              </Option>
                            ))}
                      </Select>
                    </Form.Item>
                    {(form.getFieldValue("typeOfLabel") === "OFSET" ||
                      form.getFieldValue("typeOfLabel") === "KUNST." ||
                      form.getFieldValue("typeOfLabel") ===
                        "TRIPLEX/KARTON") && (
                      <Form.Item label="Tip štampe" name="printPages">
                        <Select onChange={(value) => setPrintPages(value)}>
                          <Option value="jednostrana">Jednostrana</Option>
                          <Option value="dvostrana">Dvostrana</Option>
                        </Select>
                      </Form.Item>
                    )}
                    {form.getFieldValue("machine") ===
                      "Konica Minolta Tabak" && (
                      <Form.Item label="Boja štampe" name="colorPrint">
                        <Select>
                          <Option value="crno-bela">crno-bela</Option>
                          <Option value="u boji">u boji</Option>
                        </Select>
                      </Form.Item>
                    )}
                    <Form.Item hidden={true} name="grossKgPrice">
                      <Input />
                    </Form.Item>
                    <Divider>Dimenzije</Divider>
                    {form.getFieldValue("machine") === "Roland" && (
                      <div>
                        <Form.Item
                          label="Visina tabaka (mm)"
                          name="heightSheet"
                        >
                          <Input onChange={() => changeMachine(form)} />
                        </Form.Item>
                        <Form.Item label="Širina tabaka (mm)" name="widthSheet">
                          <Input onChange={() => changeMachine(form)} />
                        </Form.Item>
                      </div>
                    )}
                    <Form.Item label="Visina (mm)" name="height">
                      <Input
                        onChange={(event) =>
                          printType === "rolna"
                            ? onChangeLabelHeight(event, form)
                            : changeMachine(form)
                        }
                      />
                    </Form.Item>
                    <Form.Item
                      label="Širina (mm)"
                      name="width"
                      // rules={[
                      //   {
                      //     validator: (rule, value) => {
                      //       if (!machine) return Promise.reject('Izaberite masinu');
                      //       return value > maxWidthCheck ? Promise.reject('Prekoracena je maksimalna sirina') : Promise.resolve();
                      //     }
                      //   }
                      // ]}
                    >
                      <Input
                        onChange={(event) =>
                          printType === "rolna"
                            ? onChangeLabelWidth(event.target.value, form, true)
                            : changeMachine(form)
                        }
                      />
                    </Form.Item>
                    {printType === "tabak" && (
                      <Form.Item label="Broj etiketa/tabak" name="labelNum">
                        <Input
                          id="labelNum"
                          style={{ border: 0, pointerEvents: "none" }}
                        />
                      </Form.Item>
                    )}
                    <div hidden={!visible || !printType === "rolna"}>
                      <Form.Item label="Zadata širina" name="assignWidth">
                        <span id="assignWidth" style={{ float: "left" }}></span>
                      </Form.Item>
                      <Form.Item label="Širina" name="widthTable">
                        <span id="widthTable" style={{ float: "left" }}></span>
                      </Form.Item>
                      <Form.Item label="Broj komada" name="numberOfItems">
                        <span
                          id="numberOfItems"
                          style={{ float: "left" }}
                        ></span>
                      </Form.Item>
                      <Form.Item
                        label="Broj cilindara"
                        name="numberOfCylinders"
                      >
                        <span
                          id="numberOfCylinders"
                          style={{ float: "left" }}
                        ></span>
                      </Form.Item>
                      <Form.Item label="Obim" name="volume">
                        <span id="volume" style={{ float: "left" }}></span>
                      </Form.Item>
                    </div>
                    <Form.Item label="Tiraž (kom)" name="circulation">
                      <Input
                        onChange={() =>
                          printType === "tabak" && changeMachine(form)
                        }
                      />
                    </Form.Item>
                    {printType === "rolna" && (
                      <Form.Item
                        onChange={(event) =>
                          onChangeCalculatedWastePaper(event, form)
                        }
                        label="Kalkulisana makulatura (m)"
                        name="calculatedWastePaper"
                      >
                        <Input />
                      </Form.Item>
                    )}
                    {form.getFieldValue("machine") === "Roland" && (
                      <Form.Item
                        label="Kalkulisana makulatura (tabak)"
                        name="calculatedWastePaperSheet"
                      >
                        <Input />
                      </Form.Item>
                    )}
                    {printType === "tabak" &&
                      form.getFieldValue("typeOfLabel") &&
                      form.getFieldValue("typeOfLabel") !== "SAMOLEPLJIVI" &&
                      form.getFieldValue("machine") !==
                        "Konica Minolta Tabak" && (
                        <Form.Item
                          label="Gramatura materijala (g/m2)"
                          name="materialWeight"
                        >
                          <Input onChange={materialPriceHandler} />
                        </Form.Item>
                      )}
                    {printType === "tabak" &&
                      form.getFieldValue("typeOfLabel") &&
                      form.getFieldValue("typeOfLabel") === "Ostalo" && (
                        <Form.Item label="Cena po kg (€/kg)" name="pricePerKg">
                          <Input />
                        </Form.Item>
                      )}
                    {printType === "rolna" && (
                      <Form.Item
                        label="Broj redova za odsecanje (m)"
                        name="numberOfRowsToCut"
                      >
                        <Input
                          onChange={(event) =>
                            onChangeNumberOfRows(event, form)
                          }
                        />
                      </Form.Item>
                    )}
                    <Form.Item
                      hidden={
                        isDigitalPrint ||
                        form.getFieldValue("machine") === "Konica Minolta Tabak"
                      }
                      label="Broj boja"
                      name="numberOfColors"
                    >
                      <Input
                        onChange={(event) =>
                          onChangeNumberOfColors(event, form)
                        }
                      />
                    </Form.Item>
                    <Form.Item
                      hidden={
                        isDigitalPrint ||
                        form.getFieldValue("machine") === "Konica Minolta Tabak"
                      }
                      label="Stepen utroška boje (g/m2)"
                      name="degreeOfPaintConsumption"
                    >
                      <Input placeholder="Unesite vrednost" />
                    </Form.Item>
                    <Form.Item
                      hidden={
                        isDigitalPrint ||
                        form.getFieldValue("machine") === "Konica Minolta Tabak"
                      }
                      label="Broj specijalnih boja/adheziva/prajmera"
                      name="numberOfSpecialColors"
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item
                      hidden={
                        isDigitalPrint ||
                        form.getFieldValue("machine") === "Konica Minolta Tabak"
                      }
                      label="Količina specijalnih boja/adheziva/prajmera"
                      name="quantityOfSpecialColors"
                    >
                      <Input
                        onChange={(event) =>
                          onChangeQuantityOfSpecialColors(event, form)
                        }
                      />
                    </Form.Item>
                  </div>
                </Col>
                <Col xs={24} md={17}>
                  <Row>
                    <Col md={7}>
                      <Form.Item label="Lakiranje" name="varnishing">
                        <Radio.Group>
                          <Radio onChange={onChangeVarnishing} value={true}>
                            Da
                          </Radio>
                          <Radio onChange={onChangeVarnishing} value={false}>
                            Ne
                          </Radio>
                        </Radio.Group>
                      </Form.Item>
                      <Form.Item
                        hidden={hideDegreeOfVarnishConsumption}
                        label="Stepen utroška laka"
                        name="degreeOfVarnishConsumption"
                      >
                        <Input
                          placeholder={degreeOfVarnishConsumptionPlaceholder}
                        />
                      </Form.Item>
                      <Form.Item label="Plastifikacija" name="folioPrinting">
                        <Radio.Group>
                          <Radio onChange={onChangeFolioPrinting} value={true}>
                            Da
                          </Radio>
                          <Radio onChange={onChangeFolioPrinting} value={false}>
                            Ne
                          </Radio>
                        </Radio.Group>
                      </Form.Item>
                      <Form.Item
                        hidden={isDigitalPrint}
                        label="Štamparske forme"
                        name="printingForms"
                      >
                        <Radio.Group>
                          <Radio onChange={onChangePrintingForm} value={true}>
                            Da
                          </Radio>
                          <Radio onChange={onChangePrintingForm} value={false}>
                            Ne
                          </Radio>
                        </Radio.Group>
                      </Form.Item>
                      <Form.Item label="Dizajn" name="design">
                        <Radio.Group>
                          <Radio onChange={onChangeDesign} value={true}>
                            Da
                          </Radio>
                          <Radio onChange={onChangeDesign} value={false}>
                            Ne
                          </Radio>
                        </Radio.Group>
                      </Form.Item>
                      <Form.Item label="Kutije" name="boxes">
                        <Radio.Group>
                          <Radio onChange={onChangeBoxes} value={true}>
                            Da
                          </Radio>
                          <Radio onChange={onChangeBoxes} value={false}>
                            Ne
                          </Radio>
                        </Radio.Group>
                      </Form.Item>
                      <Form.Item label="Transport" name="transport">
                        <Radio.Group>
                          <Radio onChange={onChangeTransport} value={true}>
                            Da
                          </Radio>
                          <Radio onChange={onChangeTransport} value={false}>
                            Ne
                          </Radio>
                        </Radio.Group>
                      </Form.Item>
                      <Form.Item label="Kooperanti" name="subcontractors">
                        <Radio.Group>
                          <Radio onChange={onChangeSubcontractor} value={true}>
                            Da
                          </Radio>
                          <Radio onChange={onChangeSubcontractor} value={false}>
                            Ne
                          </Radio>
                        </Radio.Group>
                      </Form.Item>
                      {printType === "tabak" && (
                        <Form.Item label="TIGL" name="tigl">
                          <Radio.Group>
                            <Radio onChange={onChangeTigl} value={true}>
                              Da
                            </Radio>
                            <Radio onChange={onChangeTigl} value={false}>
                              Ne
                            </Radio>
                          </Radio.Group>
                        </Form.Item>
                      )}
                      {printType === "tabak" && (
                        <Form.Item label="Savijanje" name="bending">
                          <Radio.Group>
                            <Radio onChange={onChangeBending} value={true}>
                              Da
                            </Radio>
                            <Radio onChange={onChangeBending} value={false}>
                              Ne
                            </Radio>
                          </Radio.Group>
                        </Form.Item>
                      )}
                      {printType !== "tabak" && (
                        <div>
                          <Form.Item
                            label="Širina materijala (mm)"
                            name="materialWidth"
                            style={{ marginBottom: tooWide ? "10px" : "24px" }}
                          >
                            <Input disabled={isDigitalPrint} />
                          </Form.Item>
                          {tooWide && (
                            <span style={{ color: "#ff4d4f" }}>
                              Prekoračena je maksimalna širina!
                            </span>
                          )}
                        </div>
                      )}
                      {form.getFieldValue("machine") !==
                        "Konica Minolta Tabak" && (
                        <Form.Item
                          style={{ marginTop: "10px" }}
                          label="Cena materijala"
                          name="materialPrice"
                        >
                          <Input placeholder={materialPricePlaceholder} />
                        </Form.Item>
                      )}
                      {form.getFieldValue("machine") !==
                        "Konica Minolta Tabak" &&
                        printType === "rolna" && (
                          <Form.Item
                            hidden={hideFolioWidth}
                            label="Širina folije"
                            name="folioWidth"
                          >
                            <Input />
                          </Form.Item>
                        )}
                      {printType === "rolna" && (
                        <Form.Item
                          hidden={hideFolioPrice}
                          label="Cena folije"
                          name="folioPrice"
                        >
                          <Select>
                            <Option value="0.380">Foliotisak</Option>
                            <Option
                              value={
                                form.getFieldValue("machine") === "Roland"
                                  ? "0.071"
                                  : "0.051"
                              }
                            >
                              Mat laminacija
                            </Option>
                            <Option
                              value={
                                form.getFieldValue("machine") === "Roland"
                                  ? "0.07"
                                  : "0.050"
                              }
                            >
                              Sjajna laminacija
                            </Option>
                          </Select>
                        </Form.Item>
                      )}
                      <Form.Item
                        hidden={hideFolioPrice}
                        label="Tip plastifikacije"
                        name="folioPrice"
                      >
                        <Select>
                          <Option
                            value={
                              form.getFieldValue("machine") === "Roland"
                                ? "15"
                                : "10"
                            }
                          >
                            Jednostrana
                          </Option>
                          <Option
                            value={
                              form.getFieldValue("machine") === "Roland"
                                ? "30"
                                : "20"
                            }
                          >
                            Dvostrana
                          </Option>
                        </Select>
                      </Form.Item>
                      {/*(printPages === 'dvostrana' || form.getFieldValue('printPages') === 'dvostrana') && <Form.Item hidden={hideFolioPage} label="Vrsta laminacije" name="folioPage">
                        <Select>
                          <Option value="jednostrani">Sa jedne strane</Option>
                          <Option value='dvostrani'>Sa dve strane</Option>
                        </Select>
                        </Form.Item>*/}
                    </Col>
                    <Col xs={24} md={8}>
                      <div
                        hidden={
                          isDigitalPrint ||
                          form.getFieldValue("machine") ===
                            "Konica Minolta Tabak"
                        }
                        style={{ textAlign: "center", marginBottom: "20px" }}
                      >
                        <Text style={{ fontSize: "20px" }} strong>
                          Rad
                        </Text>
                      </div>
                      <Form.Item
                        hidden={
                          isDigitalPrint ||
                          form.getFieldValue("machine") ===
                            "Konica Minolta Tabak"
                        }
                        label="Priprema štamparske mašine (h)"
                      >
                        <Form.Item name="preparationOfPrintingPresses">
                          <Input />
                        </Form.Item>
                        <span hidden={hideTotalPreparationOfPrintingPresses}>
                          {form.getFieldValue(
                            "totalPreparationOfPrintingPresses",
                          )}
                        </span>
                      </Form.Item>

                      {/*<Form.Item hidden={hideTotalPreparationOfPrintingPresses} name='totalPreparationOfPrintingPresses'>*/}
                      {/*	<span id='totalPreparationOfPrintingPresses'></span>*/}
                      {/*</Form.Item>	*/}

                      <div>
                        <Form.Item label="Brzina štampe" name="printingSpeed">
                          <Input
                            id="printingSpeedOtherNumber"
                            defaultValue={
                              initialValues.printingSpeedOtherNumber
                            }
                            onChange={() => {
                              totalPrintingTimeHandler(
                                form.getFieldValue("typeOfLabel"),
                              );
                            }}
                          />
                          <div id="printingSpeed"></div>
                        </Form.Item>
                      </div>
                      <div>
                        <Form.Item
                          hidden={
                            isDigitalPrint ||
                            form.getFieldValue("machine") ===
                              "Konica Minolta Tabak"
                              ? true
                              : hideTotalTimeOfPrinting
                          }
                          label="Ukupno vreme štampe"
                          name=""
                        >
                          <Input hidden={hideTotalTimeOfPrintingOther} />
                          <div id="totalTimeOfPrinting"></div>
                        </Form.Item>
                      </div>

                      <Form.Item
                        hidden={
                          isDigitalPrint ||
                          form.getFieldValue("machine") ===
                            "Konica Minolta Tabak"
                        }
                        label="Cena štampe (€/h)"
                        name="printPrice"
                      >
                        <Input />
                      </Form.Item>

                      <div>
                        <Form.Item
                          hidden={
                            isDigitalPrint ||
                            form.getFieldValue("machine") ===
                              "Konica Minolta Tabak"
                              ? true
                              : hideTotalTimeOfPrinting
                          }
                          label="Ukupan trošak el. energije"
                          name="totalElectricity"
                        >
                          <Input hidden={hideTotalElectricityOther} />
                          <div id="totalElectricity"></div>
                        </Form.Item>
                      </div>
                      {!isDigitalPrint &&
                        form.getFieldValue("machine") !==
                          "Konica Minolta Tabak" && (
                          <Form.Item hidden={hideTotal} label="Ukupno">
                            <div
                              style={{ marginRight: "70px" }}
                              id="totalTime"
                            ></div>
                          </Form.Item>
                        )}
                    </Col>
                    <Col xs={24} md={8}>
                      <div
                        hidden={
                          isDigitalPrint ||
                          form.getFieldValue("machine") ===
                            "Konica Minolta Tabak"
                        }
                        style={{ textAlign: "center", marginBottom: "20px" }}
                      >
                        <Text style={{ fontSize: "20px" }} strong>
                          Količina i cene
                        </Text>
                      </div>
                      <Form.Item
                        hidden={printType === "rolna"}
                        label="Broj tabaka (sa makulaturom)"
                        name="sheetNum"
                      >
                        <span>{form.getFieldValue("sheetNum")}</span>
                      </Form.Item>
                      <Form.Item
                        hidden={printType === "rolna"}
                        label="Količina tabaka u m2"
                        name="sheetAmount"
                      >
                        <span>{form.getFieldValue("sheetAmount")}</span>
                      </Form.Item>
                      <Form.Item
                        hidden={
                          printType === "rolna" ||
                          form.getFieldValue("machine") !== "Roland"
                        }
                        label="Težina tabaka u kg"
                        name="sheetWeight"
                      >
                        <span>{form.getFieldValue("sheetWeight")}</span>
                      </Form.Item>
                      <Form.Item
                        hidden={
                          printType === "rolna" ||
                          form.getFieldValue("machine") !== "Roland"
                        }
                        label="Vrednost materijala"
                        name="totalMaterialPrice"
                      >
                        <span>{form.getFieldValue("totalMaterialPrice")}</span>
                      </Form.Item>
                      <Form.Item
                        hidden={hideGrossCurrentMeters}
                        label="Bruto tekuÄ‡ih metara"
                        name="grossCurrentMeters"
                      >
                        <span style={{ float: "left" }}>
                          {form.getFieldValue("grossCurrentMeters")} m
                        </span>
                      </Form.Item>
                      <Form.Item
                        hidden={hideGrossKgPrinting}
                        label="Vrednost materijala za štampu"
                        name="grossKgPrinting"
                      >
                        <span style={{ float: "left" }}>
                          {form.getFieldValue("grossKgPrinting")}
                        </span>
                      </Form.Item>
                      {(!isDigitalPrint ||
                        form.getFieldValue("machine") !==
                          "Konica Minolta Tabak") && (
                        <Form.Item
                          hidden={hideGrossMetersPrinting}
                          label="Bruto m2 za štampu"
                          name="grossMetersPrinting"
                        >
                          <span style={{ float: "left" }}>
                            {form.getFieldValue("grossMetersPrinting")}
                          </span>
                        </Form.Item>
                      )}
                      {printType === "rolna" && (
                        <Form.Item
                          hidden={hideGrossFolio}
                          label="Bruto m2 za folije"
                          name="grossMetersFolio"
                        >
                          <span>{form.getFieldValue("grossMetersFolio")}</span>
                        </Form.Item>
                      )}
                      {!isDigitalPrint &&
                        form.getFieldValue("machine") !==
                          "Konica Minolta Tabak" && (
                          <Form.Item
                            hidden={isDigitalPrint}
                            // hidden={hideColorQuantity}
                            label="Količina boje"
                            name="colorQuantity"
                          >
                            <span>{form.getFieldValue("colorQuantity")}</span>
                          </Form.Item>
                        )}
                      {!isDigitalPrint &&
                        form.getFieldValue("machine") !==
                          "Konica Minolta Tabak" && (
                          <Form.Item
                            hidden={
                              isDigitalPrint ? isDigitalPrint : hideColorPrice
                            }
                            label="Količina boje"
                            name="colorPrice"
                          >
                            <span style={{ float: "left" }}>
                              {printType === "tabak" ? 8 : 17}
                            </span>
                          </Form.Item>
                        )}
                      <Form.Item
                        hidden={hideDesign}
                        label="Dizajn"
                        name="designInput"
                      >
                        <Input />
                      </Form.Item>
                      <Form.Item
                        hidden={hideBoxes}
                        label="Kutije"
                        name="boxesInputs"
                      >
                        <Form.Item label="Količina" name="boxesQuantity">
                          <Input />
                        </Form.Item>
                        <div
                          style={{ marginTop: "20px", marginBottom: "20px" }}
                        >
                          <Form.Item label="Cena" name="boxesPrice">
                            <Input />
                          </Form.Item>
                        </div>
                        <Form.Item label="Ukupno" name="boxesTotalPrice">
                          <span
                            style={{ float: "left" }}
                            id="boxesTotalPrice"
                          ></span>
                        </Form.Item>
                      </Form.Item>
                      <Form.Item
                        hidden={hideTransport}
                        label="Transport"
                        name="transportInput"
                      >
                        <Input />
                      </Form.Item>
                      <Form.Item
                        hidden={hideSubcontractors}
                        label="Kooperant"
                        name="subcontractorInput"
                      >
                        <Input />
                      </Form.Item>

                      <Form.Item
                        hidden={
                          isDigitalPrint ||
                          form.getFieldValue("machine") ===
                            "Konica Minolta Tabak"
                            ? true
                            : hideQuantitySpecialColors
                        }
                        id="FormItem"
                        label="Količina spec. boja"
                        style={{
                          display:
                            !isDigitalPrint &&
                            form.getFieldValue("machine") !==
                              "Konica Minolta Tabak"
                              ? "block"
                              : "none",
                          marginLeft: "0",
                          overflow: "visible",
                        }}
                      >
                        <Form.Item
                          hidden={
                            isDigitalPrint ||
                            form.getFieldValue("machine") ===
                              "Konica Minolta Tabak"
                          }
                          label="Cena (€/kg):"
                          id="form-item-colors"
                          name="priceOfSpecialColors"
                          style={{ position: "relative", right: "-50px" }}
                        >
                          <div
                            style={{
                              marginTop: "10px",
                              marginBottom: "10px",
                              marginLeft: "50px",
                              width: "70px",
                              textAlign: "left",
                            }}
                          >
                            <Input
                              style={{
                                position: "relative",
                                right: "50px",
                                top: "-7px",
                              }}
                            />
                          </div>
                        </Form.Item>
                        <Form.Item
                          style={{ position: "relative", right: "-50px" }}
                        >
                          <div hidden={!tableVisible} style={{ float: "left" }}>
                            <label>Ukupno: </label>
                            <span id="totalPriceOfSpecialColors">
                              {form.getFieldValue("totalPriceOfSpecialColors")}
                            </span>
                          </div>
                        </Form.Item>
                      </Form.Item>
                      <Form.Item
                        hidden={hidePrintingForm}
                        label="Štamparske forme"
                        name="printingFormsPrice"
                      >
                        <span style={{ float: "left" }} id="printingFormsPrice">
                          {form.getFieldValue("printingFormsPrice")}
                        </span>
                      </Form.Item>
                      <Form.Item
                        hidden={hideFolioPrinting}
                        label="Plastifikacija"
                        name="folioPrintingPrice"
                      >
                        <span style={{ float: "left" }} id="folioPrintingPrice">
                          {form.getFieldValue("folioPrintingPrice")}
                        </span>
                      </Form.Item>
                      <Form.Item
                        hidden={hideTigl}
                        label="TIGL"
                        name="tiglPrice"
                      >
                        <span style={{ float: "left" }} id="tiglPrice">
                          {form.getFieldValue("tiglPrice")}
                        </span>
                      </Form.Item>
                      <Form.Item
                        hidden={hideBending}
                        label="Savijanje"
                        name="bendingPrice"
                      >
                        <span style={{ float: "left" }} id="bendingPrice">
                          {form.getFieldValue("bendingPrice")}
                        </span>
                      </Form.Item>
                      <Form.Item
                        hidden={hideVarnishQuantity}
                        label="Količina laka"
                        name="varnishQuantity"
                      >
                        <span id="varnishQuantity" style={{ float: "left" }}>
                          {form.getFieldValue("varnishQuantity")}
                        </span>
                      </Form.Item>
                      <Form.Item
                        hidden={hideVarnishPrice}
                        label="Količina laka"
                        name="varnishPrice"
                      >
                        <span style={{ float: "left" }} id="varnishPrice">
                          11
                        </span>
                      </Form.Item>
                      {!isDigitalPrint && (
                        <Form.Item
                          hidden={hideTotalPrice}
                          label="Ukupno cene"
                          name="totalPrice"
                        >
                          <span style={{ float: "left" }} id="totalPrice">
                            {form.getFieldValue("totalPrice")}
                          </span>
                        </Form.Item>
                      )}
                      <div
                        id="buttons"
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                          marginTop: "50px",
                        }}
                        className="button-wrapper"
                      >
                        <Button
                          type="primary"
                          onClick={createPdf}
                          style={{ marginTop: "10px" }}
                        >
                          Export PDF
                        </Button>
                        <Button
                          onClick={() => {
                            printType === "rolna"
                              ? calculate("table", form)
                              : calculateSheet("table", form);
                            setIsPrintingSpeedHidden(true);
                          }}
                          style={{ marginLeft: "10px", marginTop: "10px" }}
                          type="primary"
                        >
                          Izračunaj
                        </Button>
                      </div>
                    </Col>
                  </Row>
                  {tableVisible && (
                    <Table
                      pagination={false}
                      rowKey="_id"
                      columns={columns}
                      dataSource={dataTable}
                    />
                  )}
                </Col>
              </Row>
            )}
          </Form>
        </Card>
      </div>
    </div>
  );
};

export default OldCalculationForm;
