import React, { useState, useEffect } from "react";
import { Tabs, Card, Input, Form, Switch } from "antd";

const layout = {
  labelCol: { span: 5 },
  wrapperCol: { span: 9 },
};

const { TextArea } = Input;

const MachineViewForm = ({ data }) => {
  const [form] = Form.useForm();
  const [isMachineForSheetCalc, setIsMachineForSheetCalc] = useState(false);
  const [typesOfLabelArr, setTypesOfLabelArr] = useState(false);

  let initialValues = { ...data };

  useEffect(() => {
    if (data.printType === "tabak") {
      setIsMachineForSheetCalc(true);
    }
    let typesOfLabelArray = [];

    if (data && data.typesOfLabel) {
      if (data.typesOfLabel.length > 0) {
        for (const iterator of data?.typesOfLabel) {
          typesOfLabelArray?.push(` ${iterator.name}`);
        }
      }
    }

    setTypesOfLabelArr(typesOfLabelArray);
  }, [data]);

  return (
    <div className="panel panel-body ">
      <div className="panel-body">
        <Card title={`PREGLED MAŠINE`}>
          <Form
            {...layout}
            initialValues={initialValues}
            layout="vertical"
            form={form}
          >
            <div className="mainCont">
              <Form.Item
                required
                name="code"
                className="ant-form-item-control-input-content"
                label="Šifra mašine:"
              >
                <Input disabled />
              </Form.Item>
            </div>

            <Tabs forceRender defaultActiveKey="1">
              <Tabs.TabPane
                forceRender
                tab="Osnove informacije o mašini"
                key="1"
              >
                <div>
                  <div className="panel-body">
                    <Form.Item
                      required
                      className="formitemANTD"
                      name="name"
                      label="Naziv mašine:"
                    >
                      <Input disabled />
                    </Form.Item>
                  </div>
                  <div className="panel-body">
                    <Form.Item
                      required
                      className="formitemANTD"
                      label="Tipovi etiketa na mašini:"
                    >
                      <TextArea
                        rows={5}
                        style={{ overflow: "hidden" }}
                        disabled
                        value={typesOfLabelArr}
                      />
                    </Form.Item>
                  </div>
                  <div className="panel-body">
                    <Form.Item
                      className="formitemANTD"
                      name="maxWidth"
                      label="Maksimalna širina na mašini:"
                    >
                      <Input disabled />
                    </Form.Item>
                  </div>

                  <div className="panel-body">
                    <Form.Item
                      className="formitemANTD"
                      name="maxColorsNum"
                      label="Maksimalna broj boja i lakova:"
                    >
                      <Input disabled />
                    </Form.Item>
                  </div>
                  <div className="panel-body">
                    <Form.Item
                      required
                      className="formitemANTD"
                      name="isDigitalPrint"
                      label="Da li je digitalni print?"
                    >
                      <Switch
                        defaultChecked={initialValues.isDigitalPrint}
                        disabled
                      />
                    </Form.Item>
                  </div>
                  <div className="panel-body">
                    <Form.Item
                      required
                      className="formitemANTD"
                      name="printType"
                      label="Vrsta štampe:"
                    >
                      <Input disabled />
                    </Form.Item>
                    {isMachineForSheetCalc && (
                      <div>
                        <p
                          style={{
                            borderBottom: "1px solid black",
                            width: "450px",
                          }}
                        >
                          Osobine tabak mašine:
                        </p>
                        <Form.Item
                          required
                          className="formitemANTD"
                          name="existsColorPrint"
                          label="Podržava boju štampe:"
                        >
                          <Switch
                            defaultChecked={initialValues.existsColorPrint}
                            disabled
                          />
                        </Form.Item>

                        <Form.Item
                          required
                          className="formitemANTD"
                          name="existsCalculatedWaste"
                          label="Podržava kalkulisanu makulaturu:"
                        >
                          <Switch
                            defaultChecked={initialValues.existsCalculatedWaste}
                            disabled
                          />
                        </Form.Item>

                        <Form.Item
                          required
                          className="formitemANTD"
                          name="existsMaterialWeight"
                          label="Podržava gramaturu materijala (g/m2):"
                        >
                          <Switch
                            defaultChecked={initialValues.existsMaterialWeight}
                            disabled
                          />
                        </Form.Item>
                        <Form.Item
                          required
                          className="formitemANTD"
                          name="existsWidthAndHeightSheet"
                          label="Podržava visinu i širinu tabaka (mm):"
                        >
                          <Switch
                            defaultChecked={
                              initialValues.existsWidthAndHeightSheet
                            }
                            disabled
                          />
                        </Form.Item>

                        <Form.Item
                          required
                          className="formitemANTD"
                          name="existsTotalElectricity"
                          label="Podržava trošak el. energije (€):"
                        >
                          <Switch
                            disabled
                            defaultChecked={
                              initialValues.existsTotalElectricity
                            }
                          />
                        </Form.Item>

                        <Form.Item
                          required
                          className="formitemANTD"
                          name="existsSheetWeight"
                          label="Podržava težinu tabaka (kg):"
                        >
                          <Switch
                            disabled
                            defaultChecked={initialValues.existsSheetWeight}
                          />
                        </Form.Item>
                      </div>
                    )}
                  </div>
                </div>
              </Tabs.TabPane>
            </Tabs>
          </Form>
        </Card>
      </div>
    </div>
  );
};

export default MachineViewForm;
