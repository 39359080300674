import React, { useContext } from "react";
import { Table, Popconfirm } from "antd";
import { DeleteOutlined, EditOutlined, EyeOutlined } from "@ant-design/icons";
import { UserContext } from "../../App";
import { Link } from "react-router-dom";

const TypeOfFolioTable = ({ data, deleteHandler, editPath }) => {
  const user = useContext(UserContext);

  const columns = [
    {
      title: "Naziv folije",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Način plastifikacije",
      dataIndex: "folioWay",
      key: "folioWay",
    },
    {
      title: "Jedinica mere",
      dataIndex: "unit",
      key: "unit",
    },
  ];

  columns.push({
    title: "Akcije",
    width:
      "10vw" /*bilo je bez width,ali se tada ne poravnaju linije header i body*/,
    render: (text, record) => (
      <div
        style={{
          display: "inline-flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: "15px",
        }}
      >
        <div
          style={{ margin: "2px", padding: "4px", cursor: "pointer" }}
          className="lock"
        >
          <Link to={editPath + record._id}>
            <EditOutlined
              className="icon-unlock"
              title={`Izmeni tip folije`}
              style={{ textDecoration: "none", color: "black" }}
            />
          </Link>
        </div>

        <div
          style={{ margin: "2px", padding: "4px", cursor: "pointer" }}
          className="lock"
        >
          <Link to={`/admin/view-typeoffolio/${record._id}`}>
            <EyeOutlined
              className="icon-unlock"
              title={`Pregledaj tip folije`}
              style={{ textDecoration: "none", color: "black" }}
            />
          </Link>
        </div>

        <div
          style={{ margin: "2px", padding: "4px", cursor: "pointer" }}
          className="lock"
        >
          <Popconfirm
            disabled={record._id === user.data.id}
            placement="left"
            title={`Ovo će obrisati tip folije`}
            onConfirm={() => {
              deleteHandler(record._id);
            }}
            okText="OK"
            cancelText="Cancel"
            cancelButtonProps={{ type: "secondary" }}
            okButtonProps={{ type: "primary" }}
          >
            <DeleteOutlined
              className="icon-unlock"
              title={`Obriši tip folije`}
            />
          </Popconfirm>
        </div>
      </div>
    ),
  });

  return (
    <div className="table-data-okvir">
      <Table
        size="middle"
        bordered
        dataSource={data}
        columns={columns}
        rowKey="_id"
        pagination={{
          defaultPageSize: 20,
          position: ["topCenter"],
          showSizeChanger: false,
          pageSizeOptions: ["10", "20", "50", "100"],
          hideOnSinglePage: true,
        }}
      />
    </div>
  );
};

export default TypeOfFolioTable;
