import React from "react";
import { useHistory } from "react-router-dom";
import slugify from "slugify";
import JoditEditor from "jodit-react";
import {
  Tabs,
  Input,
  Select,
  /* DatePicker, */ Button,
  Form,
  Row,
  Col,
} from "antd";
import UploadBox from "../base/UploadBox";

const { Option } = Select;

const configJodit = {
  readonly: false, // all options from https://xdsoft.net/jodit/doc/
  toolbarAdaptive: false,
  minHeight: "300",
  buttons: [
    "source",
    "|",
    "bold",
    "strikethrough",
    "underline",
    "italic",
    "eraser",
    "|",
    "superscript",
    "subscript",
    "|",
    "ul",
    "ol",
    "|",
    "outdent",
    "indent",
    "|",
    // 'font',
    "fontsize",
    "\n",
    "brush",
    "paragraph",
    "link",
    "align",
    // '|',
    "undo",
    "redo",
    "selectall",
    "cut",
    "copy",
    "paste",
    "copyformat",
    "|",
    "hr",
    "symbol",
    "fullsize",
    "print",
    "preview",
    "find",
  ],
};

const formInit = {
  _id: null,
  title: {},
  googleDescription: {},
  keywords: {},
  content: {},
  url: "",
  fullUrl: "",
  status: "",
  image: undefined,
};

const PageForm = ({ isNew, data, image, onSubmit, language }) => {
  const [form] = Form.useForm();
  const history = useHistory();
  let initialValues = { ...formInit, ...data };

  const prevForm = localStorage.getItem("pageForm");
  if (prevForm) initialValues = JSON.parse(prevForm);
  let pageImage;

  if (data && data.image) pageImage = data.image.url;
  if (image && image.url) pageImage = image.url;

  const onFinish = async (values, isNew) => {
    localStorage.removeItem("pageForm");
    if (!isNew) {
      values._id = data._id;
      if (image) {
        values.image = image
          ? image._id
          : data.image
          ? data.image._id
          : undefined;
      }
    } else {
      if (image) {
        values.image = image ? image._id : undefined;
      }
    }
    onSubmit(values, isNew);
  };

  const imageHandler = () => {
    const values = form.getFieldsValue();
    localStorage.setItem("pageForm", JSON.stringify(values));
    history.push(
      `/admin/gallery/edit-content?fromPage=${
        isNew ? "new-page" : "edit-page/" + data._id
      }${values.image ? "&imageIds=" + values.image._id.toString() : ""}`,
    );
  };
  return (
    <div className="panel panel-body">
      <div className="panel-body">
        <Form
          className="form-horizontal"
          initialValues={initialValues}
          onFinish={(values) => onFinish(values, !data)}
          layout="vertical"
          form={form}
        >
          <Row type="flex" gutter={16}>
            <Col xs={24} md={16}>
              <div className="panel-body">
                <Tabs
                  tabPosition="left"
                  defaultActiveKey={language.default.code}
                >
                  {language.list.map((lang) => (
                    <Tabs.TabPane tab={lang.code} key={lang.code}>
                      <Form.Item
                        label="Title"
                        rules={[
                          {
                            required:
                              lang.code === language.default.code
                                ? true
                                : false,
                            message: "Please enter title!",
                          },
                        ]}
                        name={["title", lang.code]}
                        onChange={(e) =>
                          form.setFieldsValue({
                            url: slugify(e.target.value, { lower: true }),
                          })
                        }
                      >
                        <Input />
                      </Form.Item>

                      <Form.Item
                        label="Google Description"
                        name={["googleDescription", lang.code]}
                      >
                        <Input />
                      </Form.Item>

                      <Form.Item
                        label="Google Keywords"
                        name={["keywords", lang.code]}
                      >
                        <Input />
                      </Form.Item>

                      <Form.Item label="Content" name={["content", lang.code]}>
                        <JoditEditor
                          name={`content[${lang.code}]`}
                          style={{ margin: "2px 0px", minHeight: "350px" }}
                          config={configJodit}
                        />
                      </Form.Item>
                      <Form.Item
                        label="Status"
                        name={["status"]}
                        rules={[
                          { required: true, message: "Please select status!" },
                        ]}
                      >
                        <Select>
                          <Option value="ACTIVE">Active</Option>
                          <Option value="INACTIVE">Inactive</Option>
                        </Select>
                      </Form.Item>
                    </Tabs.TabPane>
                  ))}
                </Tabs>

                <Form.Item hidden={true} label="Url" name="url">
                  <Input disabled />
                </Form.Item>

                {/*
                <Divider type='horizontal' />

                <Form.Item label='Url' name='url'>
                  <Input disabled />
                </Form.Item>

                <Row type='flex' gutter={4} justify='start' align='top'>
                  <Col xs={24} md={8}>
                    <Form.Item label='Featured' name='featuredAd' valuePropName='checked'>
                      <Checkbox />
                    </Form.Item>
                  </Col>

                  <Col xs={24} md={8}>
                    <Form.Item label='Featured until' name='featuredAdUntil' getValueProps={() => {}} rules={[{ type: 'object' }]}>
                      <DatePicker style={{ width: '100%' }} size='large' rules={[{ type: 'object' }]} />
                    </Form.Item>
                  </Col>
                </Row> */}
              </div>
            </Col>

            <Col xs={24} md={8}>
              {/* <div className='panel panel-primary'>
                <div className='panel-heading'>
                  <h4 className='panel-title'>Category</h4>
                </div>

                <Form.Item label='Category' name='category' className='panel-body' rules={[{ required: true, message: 'Please select category!' }]}>
                  <TreeSelect
                    style={{ width: '100%', height: 30 }}
                    dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                    name='category'
                    treeData={treeData}
                    placeholder='Please select category'
                    onChange={(value) => {
                      form.setFieldsValue({ attributes: [] });
                      const categoryPath = getCategoryPath(value, categories);
                      fetchAttributes(`${SERVER_URL}/attributes/category/${categoryPath}`, []);
                    }}
                  />
                </Form.Item>
              </div> */}

              <div className="panel panel-primary">
                <div className="panel-heading">
                  <h4 className="panel-title">Page image</h4>
                </div>

                <Form.Item name="image" valuePropName="image">
                  <div className="panel-body">
                    <UploadBox handler={imageHandler} image={pageImage} />
                  </div>
                </Form.Item>
              </div>
            </Col>
          </Row>

          <div className="text-right">
            <Button type="primary" htmlType="submit">
              {isNew ? "Add" : "update"} page
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default PageForm;
