import React, { useState } from "react";
import { Button, Card, Form, Input, Result, Select, notification } from "antd";
const { TextArea } = Input;
const tailLayout = {
  // wrapperCol: { offset: 8, span: 24 },
  wrapperCol: { span: 24 },
};

let layout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 20 },
};

const initialValues = {
  email: undefined,
  companyName: undefined,
  idNumber: undefined,
  phone: undefined,
  address: undefined,
  city: undefined,
  status: undefined,
  comment: undefined,
};

const ClientForm = ({ data, updateHandler, createHandler }) => {
  const [form] = Form.useForm();
  let user = data ? { ...data } : initialValues;
  const isNew = data ? false : true;
  const [status, setStatus] = useState(data ? data.status : undefined);

  const onFinish = (values, isNew) => {
    localStorage.removeItem("clientForm");
    if (isNew) {
      createHandler(values);
    } else {
      updateHandler(values);
    }
  };

  layout = !isNew && { ...layout, labelCol: { span: 6 } };

  return (
    <div>
      {/* <h2>{isNew ? 'NEW' : 'EDIT'} USER</h2> */}
      <div className="dashboard">
        <div className="card-wrapper">
          {/* eslint-disable-next-line no-useless-concat */}
          <Card
            title={`${isNew ? "NOVI KLIJENT" : "EDITUJ KLIJENTA"}`}
            bordered={false}
          >
            <Form
              {...layout}
              name="basic"
              initialValues={user}
              onFinish={(values) => onFinish(values, isNew)}
              layout="horizontal"
              form={form}
            >
              <Form.Item label="Šifra klijenta" name="code">
                <Input />
              </Form.Item>

              <Form.Item
                label="Naziv firme"
                name="companyName"
                rules={[
                  { required: true, message: "Molimo vas unesite ime firme!" },
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item label="PTT" name="zip">
                <Input />
              </Form.Item>

              <Form.Item label="Grad" name="city">
                <Input />
              </Form.Item>

              <Form.Item label="Adresa za isporuku" name="deliveryAddress">
                <Input />
              </Form.Item>

              <Form.Item
                label="Kontakt osoba za isporuku"
                name="contactDeliveryPerson"
              >
                <Input />
              </Form.Item>

              <Form.Item label="Telefon" name="deliveryPersonPhone">
                <Input />
              </Form.Item>

              <Form.Item label="Adresa za fakturisanje" name="invoiceAddress">
                <Input />
              </Form.Item>

              <Form.Item label="Kontakt osoba za fakture" name="contactPerson">
                <Input />
              </Form.Item>

              <Form.Item
                label="Valuta (dana od dana otpreme robe)"
                name="currency"
              >
                <Input />
              </Form.Item>

              <Form.Item label="Telefon" name="phone">
                <Input />
              </Form.Item>

              <Form.Item label="Mobilni telefon" name="mobile">
                <Input />
              </Form.Item>

              <Form.Item label="E-mail" name="email">
                <Input />
              </Form.Item>

              <Form.Item label="PIB" name="pib">
                <Input />
              </Form.Item>

              <Form.Item label="Maticni broj" name="idNumber">
                <Input />
              </Form.Item>

              <Form.Item label="Reg. broj" name="regNumber">
                <Input />
              </Form.Item>

              <Form.Item label="Šifra del." name="industryCode">
                <Input />
              </Form.Item>

              <Form.Item label="Komercijalista" name="salesRepresentative">
                <Input />
              </Form.Item>

              <Form.Item label="Status" name="status">
                <Select>
                  <Select.Option value="NE KONTAKTIRAN">
                    NEKONTAKTIRAN
                  </Select.Option>
                  <Select.Option value="USPOSTAVLJEN KONTAKT">
                    USPOSTAVLJEN KONTAKT
                  </Select.Option>
                  <Select.Option value="POSLATA PONUDA">
                    POSLATA PONUDA
                  </Select.Option>
                  <Select.Option value="AKTIVAN">AKTIVAN</Select.Option>
                  <Select.Option value="NEZAINTERESOVAN">
                    NEZAINTERSEOVAN
                  </Select.Option>
                </Select>
              </Form.Item>
              <Form.Item {...tailLayout}>
                <Button
                  type="primary"
                  htmlType="submit"
                  style={{ float: "right" }}
                >
                  Potvrdi
                </Button>
              </Form.Item>
            </Form>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default ClientForm;
