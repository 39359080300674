import React, { useState, useEffect, useContext } from "react";
import {
  Tabs,
  Card,
  Input,
  Form,
  InputNumber,
  Button,
  notification,
  Modal,
} from "antd";
import { PlusSquareOutlined } from "@ant-design/icons";
import moment from "moment";
import Axios from "axios";
import { UserContext } from "../../App";
import { SERVER_URL } from "../../config";

const layout = {
  labelCol: { span: 5 },
  wrapperCol: { span: 9 },
};
const { TextArea } = Input;

const OfferViewForm = ({ data }) => {
  const [form] = Form.useForm();
  const currentuser = useContext(UserContext);
  const [calculationsList, setCalculationList] = useState([]);
  const [offerItems, setOfferItems] = useState([]);
  const [itemPrice, setItemPrice] = useState([]);
  const [itemPriceSecond, setItemPriceSecond] = useState([]);
  const [itemPriceThird, setItemPriceThird] = useState([]);
  const [itemPriceFourth, setItemPriceFourth] = useState([]);
  const [itemPriceFifth, setItemPriceFifth] = useState([]);
  const [itemPriceSixth, setItemPriceSixth] = useState([]);
  const [itemCirculation, setItemCirculation] = useState([]);
  const [itemCirculationSecond, setItemCirculationSecond] = useState([]);
  const [itemCirculationThird, setItemCirculationThird] = useState([]);
  const [itemCirculationFourth, setItemCirculationFourth] = useState([]);
  const [itemCirculationFifth, setItemCirculationFifth] = useState([]);
  const [itemCirculationSixth, setItemCirculationSixth] = useState([]);
  const [reRender, setReRender] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [mailsArr, setMailsArr] = useState([]);
  const [emailValue, setEmailValue] = useState([]);

  useEffect(() => {
    setEmail();
  }, [data]);

  const setEmail = () => {
    if (initialValues && data.email !== "") {
      setEmailValue([initialValues.email]);
      setMailsArr([{}]);
    }
  };

  const onChangeEmailValue = (event, index) => {
    event.persist();

    let arr = emailValue;

    arr[index] = event.target.value;

    setEmailValue(arr);
    setReRender(!reRender);
  };

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  let initialValues = { ...data };
  useEffect(() => {
    if (data && data.calculations && data.calculations.length > 0) {
      ///spisak naloga u potvrdi
      data.calculations.forEach((element) => {
        setCalculationList((current) => [
          ...current,
          `${moment(element.date).format("DD.MM.YYYY")} - ${
            element.client.companyName
          } - ${element.businessName}`,
        ]);
      });
    }

    fetchOfferItems();
  }, []);

  const fetchOfferItems = async () => {
    const items = await Axios.get(`${SERVER_URL}/offer-items/${data._id}`, {
      withCredentials: false,
      headers: { Authorization: `Bearer ${currentuser.data.token}` },
    });

    setOfferItems(items.data);
    let prices = [];
    let pricesSecond = [];
    let pricesThird = [];
    let pricesFourth = [];
    let pricesFifth = [];
    let pricesSixth = [];
    let circulations = [];
    let circulationsSecond = [];
    let circulationsThird = [];
    let circulationsFourth = [];
    let circulationsFifth = [];
    let circulationsSixth = [];

    for (let i = 0; i < items.data.length; i++) {
      prices.push(items.data[i].price);
      pricesSecond.push(items.data[i].priceSecond);
      pricesThird.push(items.data[i].priceThird);
      pricesFourth.push(items.data[i].priceFourth);
      pricesFifth.push(items.data[i].priceFifth);
      pricesSixth.push(items.data[i].priceSixth);
      circulations.push(items.data[i].circulation);
      circulationsSecond.push(items.data[i].circulationSecond);
      circulationsThird.push(items.data[i].circulationThird);
      circulationsFourth.push(items.data[i].circulationFourth);
      circulationsFifth.push(items.data[i].circulationFifth);
      circulationsSixth.push(items.data[i].circulationSixth);
    }

    setItemCirculation(circulations);
    setItemCirculationSecond(circulationsSecond);
    setItemCirculationThird(circulationsThird);
    setItemCirculationFourth(circulationsFourth);
    setItemCirculationFifth(circulationsFifth);
    setItemCirculationSixth(circulationsSixth);
    setItemPrice(prices);
    setItemPriceSecond(pricesSecond);
    setItemPriceThird(pricesThird);
    setItemPriceFourth(pricesFourth);
    setItemPriceFifth(pricesFifth);
    setItemPriceSixth(pricesSixth);
  };

  const onChangePrice = (e, index) => {
    let prices = itemPrice;

    prices[index] = parseFloat(e);

    setItemPrice(prices);

    setReRender((current) => !current);
  };

  const onChangePriceSecond = (e, index) => {
    let prices = itemPriceSecond;

    prices[index] = parseFloat(e);

    setItemPriceSecond(prices);

    setReRender((current) => !current);
  };

  const onChangePriceThird = (e, index) => {
    let prices = itemPriceThird;

    prices[index] = parseFloat(e);

    setItemPriceThird(prices);

    setReRender((current) => !current);
  };

  const onChangePriceFourth = (e, index) => {
    let prices = itemPriceFourth;

    prices[index] = parseFloat(e);

    setItemPriceFourth(prices);

    setReRender((current) => !current);
  };

  const onChangePriceFifth = (e, index) => {
    let prices = itemPriceFifth;

    prices[index] = parseFloat(e);

    setItemPriceFifth(prices);

    setReRender((current) => !current);
  };

  const onChangePriceSixth = (e, index) => {
    let prices = itemPriceSixth;

    prices[index] = parseFloat(e);

    setItemPriceSixth(prices);

    setReRender((current) => !current);
  };

  const onChangeCirculation = (e, index) => {
    let circulations = itemCirculation;

    circulations[index] = parseFloat(e);

    setItemCirculation(circulations);

    setReRender((current) => !current);
  };

  const onChangeCirculationSecond = (e, index) => {
    let circulations = itemCirculationSecond;

    circulations[index] = parseFloat(e);

    setItemCirculationSecond(circulations);

    setReRender((current) => !current);
  };

  const onChangeCirculationThird = (e, index) => {
    let circulations = itemCirculationThird;

    circulations[index] = parseFloat(e);

    setItemCirculationThird(circulations);

    setReRender((current) => !current);
  };

  const onChangeCirculationFourth = (e, index) => {
    let circulations = itemCirculationFourth;

    circulations[index] = parseFloat(e);

    setItemCirculationFourth(circulations);

    setReRender((current) => !current);
  };

  const onChangeCirculationFifth = (e, index) => {
    let circulations = itemCirculationFifth;

    circulations[index] = parseFloat(e);

    setItemCirculationFifth(circulations);

    setReRender((current) => !current);
  };

  const onChangeCirculationSixth = (e, index) => {
    let circulations = itemCirculationSixth;

    circulations[index] = parseFloat(e);

    setItemCirculationSixth(circulations);

    setReRender((current) => !current);
  };

  const createPdfHandler = async () => {
    for (let i = 0; i < offerItems.length; i++) {
      offerItems[i].price = itemPrice[i];
      offerItems[i].priceSecond = itemPriceSecond[i];
      offerItems[i].priceThird = itemPriceThird[i];
      offerItems[i].priceFourth = itemPriceFourth[i];
      offerItems[i].priceFifth = itemPriceFifth[i];
      offerItems[i].priceSixth = itemPriceSixth[i];
      offerItems[i].circulation = itemCirculation[i];
      offerItems[i].circulationSecond = itemCirculationSecond[i];
      offerItems[i].circulationThird = itemCirculationThird[i];
      offerItems[i].circulationFourth = itemCirculationFourth[i];
      offerItems[i].circulationFifth = itemCirculationFifth[i];
      offerItems[i].circulationSixth = itemCirculationSixth[i];
    }

    ///save offer with changed prices
    const save = await Axios.put(
      `${SERVER_URL}/offer-items/${data._id}`,
      offerItems,
      {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      },
    );

    ///createPDf
    const response = await Axios.post(
      `${SERVER_URL}/create-pdf-offer`,
      {
        items: offerItems,
        data,
        user: `${currentuser.data.firstName} ${currentuser.data.lastName}`,
      },
      {
        withCredentials: false,
        headers: {
          Authorization: `Bearer ${currentuser.data.token}`,
          "Content-Type": "application/json",
        },
      },
    );

    if (response.data.filename) {
      const pdfResponse = await Axios.get(
        `${SERVER_URL}/get-pdf?filename=${response.data.filename}`,
        {
          responseType: "arraybuffer",
          withCredentials: false,
          headers: { Authorization: `Bearer ${currentuser.data.token}` },
        },
      );
      const pathArr = response.data.filename.split("\\");
      const blob = new Blob([pdfResponse.data], { type: "application/pdf" });
      let a = document.createElement("a");
      let url = window.URL.createObjectURL(blob);
      a.href = url;
      a.download = pathArr[pathArr.length - 1];
      a.click();
      window.URL.revokeObjectURL(url);
    }

    notification.success({
      message: "Cene su ažurirane i PDF je kreiran",
      duration: 2,
      placement: "bottomRight",
    });
  };

  const handleSendEmail = async () => {
    for (let i = 0; i < offerItems.length; i++) {
      offerItems[i].price = itemPrice[i];
    }

    ///save offer with changed prices
    const save = await Axios.put(
      `${SERVER_URL}/offer-items/${data._id}`,
      offerItems,
      {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      },
    );

    /////////////////////stampanje pdfa/////////////
    const response = await Axios.post(
      `${SERVER_URL}/create-pdf-offer`,
      {
        items: offerItems,
        data,
        user: `${currentuser.data.firstName} ${currentuser.data.lastName}`,
      },
      {
        withCredentials: false,
        headers: {
          Authorization: `Bearer ${currentuser.data.token}`,
          "Content-Type": "application/json",
        },
      },
    );

    if (response.data.filename) {
      const pdfResponse = await Axios.get(
        `${SERVER_URL}/get-pdf?filename=${response.data.filename}`,
        {
          responseType: "arraybuffer",
          withCredentials: false,
          headers: { Authorization: `Bearer ${currentuser.data.token}` },
        },
      );
      const pathArr = response.data.filename.split("\\");
      const blob = new Blob([pdfResponse.data], { type: "application/pdf" });

      // console.log(response.data.response);
      let a = document.createElement("a");
      let url = window.URL.createObjectURL(blob);
      a.href = url;
      a.download = pathArr[pathArr.length - 1];
      a.click();
      window.URL.revokeObjectURL(url);
    }
    //////////////////////////////////////////////////////////

    const email = emailValue;
    ////////slanje mejla
    const res = await Axios.post(
      `${SERVER_URL}/offer-sendEmail`,
      {
        filename: response.data.filename,
        filepath: response.data.response,
        items: offerItems,
        data,
        email,
        user: `${currentuser.data.firstName} ${currentuser.data.lastName}`,
      },
      {
        withCredentials: false,
        headers: {
          Authorization: `Bearer ${currentuser.data.token}`,
          "Content-Type": "application/json",
        },
      },
    );
    // console.log("poslato");
    closeModal();
    notification.success({
      message: "Cene su ažurirane i mejl sa PDF-om je poslat",
      duration: 2,
      placement: "bottomRight",
    });
  };

  // console.log(offerItems);

  return (
    <div className="panel panel-body ">
      <div className="panel-body">
        <Card className="workOrderForm " title={`PREGLED PONUDE`}>
          <Form
            {...layout}
            initialValues={initialValues}
            layout="vertical"
            form={form}
          >
            <div className="mainCont">
              <Form.Item
                className="ant-form-item-control-input-content"
                label="Kalkulacije:"
              >
                <TextArea
                  rows={calculationsList?.length}
                  style={{ minWidth: "900px" }}
                  value={calculationsList.join("\n")}
                  disabled
                />
              </Form.Item>
            </div>
            <div className="panel-body">
              <Form.Item
                className="ant-form-item-control-input-content"
                label="Klijent:"
              >
                <Input
                  value={
                    data?.calculations?.length > 0
                      ? data.calculations[0].client.companyName
                      : ""
                  }
                  disabled
                />
              </Form.Item>
              <Form.Item
                className="ant-form-item-control-input-content"
                label="Kontakt osoba:"
              >
                <Input
                  value={
                    data.contactPerson
                      ? data.contactPerson
                      : data?.calculations?.length > 0 &&
                        data?.calculations[0]?.client?.contactPerson
                      ? data?.calculations[0]?.client?.contactPerson
                      : data?.contactPerson
                  }
                  disabled
                />
              </Form.Item>
              <Form.Item
                required
                name="email"
                className="ant-form-item-control-input-content"
                label="Email:"
              >
                <Input disabled />
              </Form.Item>
              <Form.Item
                name="subject"
                className="ant-form-item-control-input-content"
                label="Ponuda izrade:"
              >
                <Input disabled />
              </Form.Item>
              <Form.Item
                name="unit"
                className="ant-form-item-control-input-content"
                label="Jedinica mere:"
              >
                <Input disabled />
              </Form.Item>
              <Form.Item
                name="delivery"
                className="ant-form-item-control-input-content"
                label="Isporuka:"
              >
                <Input disabled />
              </Form.Item>
              <Form.Item
                name="deliveryTime"
                className="ant-form-item-control-input-content"
                label="Rok isporuke(do radnih dana):"
              >
                <Input disabled />
              </Form.Item>
              <Form.Item
                name="currency"
                className="ant-form-item-control-input-content"
                label="Valuta(dana od dana otpreme robe):"
              >
                <Input disabled />
              </Form.Item>
              <Form.Item
                name="finishing"
                className="ant-form-item-control-input-content"
                label="Dorada:"
              >
                <TextArea disabled />
              </Form.Item>
              <Form.Item
                name="paymentInput"
                className="ant-form-item-control-input-content"
                label="Plaćanje:"
              >
                <Input disabled />
              </Form.Item>
              <div style={{ width: "100%" }} className="subtitle">
                Proizvodi
              </div>
              {offerItems &&
                offerItems.length > 0 &&
                offerItems.map((item, index) => {
                  return (
                    <div>
                      <label>
                        <strong>
                          {index + 1}.{item.name}
                        </strong>
                      </label>
                      <div style={{ display: "flex" }}>
                        <label>Dimenzija: </label>
                        {item.dimension}
                      </div>
                      <div style={{ display: "flex" }}>
                        <label>Materijal: </label>
                        {item.typeOfLabel}
                      </div>
                      <div style={{ display: "flex" }}>
                        <label>Boje:</label>
                        {item.numOfColors} boja
                      </div>
                      <div style={{ display: "flex", marginTop: "15px" }}>
                        <label>Tiraž: </label>
                        <InputNumber
                          onChange={(e) => {
                            onChangeCirculation(e, index);
                          }}
                          style={{ width: "200px" }}
                          type="number"
                          min="0"
                          value={itemCirculation[index]}
                        ></InputNumber>
                        <label>Cena: </label>

                        <InputNumber
                          onChange={(e) => {
                            onChangePrice(e, index);
                          }}
                          style={{ width: "200px" }}
                          type="number"
                          min="0"
                          value={itemPrice[index]}
                        ></InputNumber>
                      </div>
                      <div style={{ display: "flex", marginTop: "15px" }}>
                        <label>Tiraž: </label>

                        <InputNumber
                          onChange={(e) => {
                            onChangeCirculationSecond(e, index);
                          }}
                          style={{ width: "200px" }}
                          type="number"
                          min="0"
                          value={itemCirculationSecond[index]}
                        ></InputNumber>
                        <label>Cena: </label>

                        <InputNumber
                          onChange={(e) => {
                            onChangePriceSecond(e, index);
                          }}
                          style={{ width: "200px" }}
                          type="number"
                          min="0"
                          value={itemPriceSecond[index]}
                        ></InputNumber>
                      </div>

                      <div style={{ display: "flex", marginTop: "15px" }}>
                        <label>Tiraž: </label>

                        <InputNumber
                          onChange={(e) => {
                            onChangeCirculationThird(e, index);
                          }}
                          style={{ width: "200px" }}
                          type="number"
                          min="0"
                          value={itemCirculationThird[index]}
                        ></InputNumber>
                        <label>Cena: </label>

                        <InputNumber
                          onChange={(e) => {
                            onChangePriceThird(e, index);
                          }}
                          style={{ width: "200px" }}
                          type="number"
                          min="0"
                          value={itemPriceThird[index]}
                        ></InputNumber>
                      </div>

                      <div style={{ display: "flex", marginTop: "15px" }}>
                        <label>Tiraž: </label>

                        <InputNumber
                          onChange={(e) => {
                            onChangeCirculationFourth(e, index);
                          }}
                          style={{ width: "200px" }}
                          type="number"
                          min="0"
                          value={itemCirculationFourth[index]}
                        ></InputNumber>
                        <label>Cena: </label>

                        <InputNumber
                          onChange={(e) => {
                            onChangePriceFourth(e, index);
                          }}
                          style={{ width: "200px" }}
                          type="number"
                          min="0"
                          value={itemPriceFourth[index]}
                        ></InputNumber>
                      </div>

                      <div style={{ display: "flex", marginTop: "15px" }}>
                        <label>Tiraž: </label>

                        <InputNumber
                          onChange={(e) => {
                            onChangeCirculationFifth(e, index);
                          }}
                          style={{ width: "200px" }}
                          type="number"
                          min="0"
                          value={itemCirculationFifth[index]}
                        ></InputNumber>
                        <label>Cena: </label>

                        <InputNumber
                          onChange={(e) => {
                            onChangePriceFifth(e, index);
                          }}
                          style={{ width: "200px" }}
                          type="number"
                          min="0"
                          value={itemPriceFifth[index]}
                        ></InputNumber>
                      </div>

                      <div style={{ display: "flex", marginTop: "15px" }}>
                        <label>Tiraž: </label>

                        <InputNumber
                          onChange={(e) => {
                            onChangeCirculationSixth(e, index);
                          }}
                          style={{ width: "200px" }}
                          type="number"
                          min="0"
                          value={itemCirculationSixth[index]}
                        ></InputNumber>
                        <label>Cena: </label>

                        <InputNumber
                          onChange={(e) => {
                            onChangePriceSixth(e, index);
                          }}
                          style={{ width: "200px" }}
                          type="number"
                          min="0"
                          value={itemPriceSixth[index]}
                        ></InputNumber>
                      </div>
                      {item.printingForms && (
                        <div style={{ display: "flex", marginTop: "10px" }}>
                          <label>
                            <strong>*</strong>Štamparske forme:{" "}
                          </label>{" "}
                          {item.priceInCurrency === "EURO"
                            ? `${item.printingForms} ${item.priceInCurrency}`
                            : `${item.printingForms * item.exchangeRate} ${
                                item.priceInCurrency
                              }`}
                        </div>
                      )}
                      {item.tools && (
                        <div style={{ display: "flex", marginTop: "10px" }}>
                          <label>
                            <strong>*</strong> Ric/Štanc alat:{" "}
                          </label>{" "}
                          {item.priceInCurrency === "EURO"
                            ? `${item.tools} ${item.priceInCurrency}`
                            : `${item.tools * item.exchangeRate} ${
                                item.priceInCurrency
                              }`}
                        </div>
                      )}
                      {item.printingForms || item.tools ? (
                        <p style={{ marginTop: "15px" }}>
                          <strong>* </strong>jednokratni trošak koji se
                          naplaćuje pri prvoj isporuci, a pri svakoj sledećoj
                          samo u slučaju da se menja tekst, izgled, dimenzija
                        </p>
                      ) : null}
                    </div>
                  );
                })}
            </div>
            <div className="text-right">
              <Button
                type="primary"
                onClick={openModal}
                style={{ marginRight: "10px" }}
              >
                Pošalji ponudu putem email-a
              </Button>

              <Modal
                open={showModal}
                onCancel={closeModal}
                title="Slanje mail-a ponude"
                footer={[]}
              >
                <div style={{ display: "flex", flexDirection: "column" }}>
                  {mailsArr && mailsArr.length < 3 && (
                    <Button
                      onClick={() => {
                        setMailsArr([...mailsArr, {}]);
                      }}
                      style={{ marginBottom: "20px" }}
                      icon={<PlusSquareOutlined />}
                    >
                      Dodaj novi mejl
                    </Button>
                  )}
                  {mailsArr &&
                    mailsArr.length > 0 &&
                    mailsArr.map((item, index) => {
                      return (
                        <Input
                          style={{ margin: "5px" }}
                          value={emailValue[index]}
                          onChange={(e) => {
                            onChangeEmailValue(e, index);
                          }}
                        />
                      );
                    })}
                  <Button
                    style={{ textAlign: "center" }}
                    onClick={handleSendEmail}
                  >
                    Posalji
                  </Button>
                </div>
              </Modal>

              <Button type="primary" onClick={createPdfHandler}>
                Odštampaj PDF
              </Button>
            </div>
          </Form>
        </Card>
      </div>
    </div>
  );
};

export default OfferViewForm;
