import React, { useContext, useState } from "react";
import { Table, Popconfirm, Input, Button } from "antd";
import { EditOutlined, SearchOutlined } from "@ant-design/icons";
import { UserContext } from "../../App";
import { Link } from "react-router-dom";
import moment from "moment";

const OldCalculationTable = ({ data }) => {
  const user = useContext(UserContext);
  let [search, setSearch] = useState({});
  let searchInput;
  const [sortedInfo, setSortedInfo] = useState({});

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      let filter =
        localStorage.getItem("filter") &&
        JSON.parse(localStorage.getItem("filter"));
      if (filter) {
        for (const property in filter) {
          if (dataIndex === property) {
            if (
              Array.isArray(selectedKeys) &&
              selectedKeys.length === 0 &&
              selectedKeys[0] !== filter[property]
            ) {
              setSelectedKeys([`${filter[property]}`]);
              confirm();
            }
          }
        }
      }

      return (
        <div style={{ padding: 8 }}>
          <Input
            ref={(node) => {
              searchInput = node;
            }}
            placeholder={`Search ${dataIndex}`}
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : "")
            }
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{ width: 188, marginBottom: 8, display: "block" }}
          />
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            size="small"
            style={{ width: 90, marginRight: 8 }}
          >
            Search
          </Button>
          <Button
            onClick={() => {
              handleReset(clearFilters, dataIndex, setSelectedKeys);
              setSortedInfo({});
            }}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </div>
      );
    },
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{ color: filtered ? "red" : "#bbb", fontSize: "1rem" }}
      />
    ),
    onFilter: (value, record) => {
      const filterRecord = Array.isArray(dataIndex)
        ? record[dataIndex[0]][dataIndex[1]]
        : record[dataIndex];
      if (!filterRecord || !value) return false;
      return filterRecord
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase());
    },
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => {
          searchInput.select();
        });
      }
    },
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    let filter = localStorage.getItem("filter")
      ? JSON.parse(localStorage.getItem("filter"))
      : {};
    filter[`${dataIndex}`] = selectedKeys[0];
    localStorage.setItem("filter", JSON.stringify(filter));
    confirm();
  };

  const handleReset = (clearFilters, dataIndex, setSelectedKeys) => {
    let filter =
      localStorage.getItem("filter") &&
      JSON.parse(localStorage.getItem("filter"));
    if (filter) {
      for (const property in filter) {
        if (dataIndex === property) {
          setSelectedKeys([]);
          delete filter[property];
          clearFilters();
        }
      }
    }
    localStorage.setItem("filter", JSON.stringify(filter));
  };

  const columns = [
    {
      title: "Datum kreiranja",
      render: (text, record) => (
        <p>{moment(text.createdAt).format("DD.MM.YYYY.")}</p>
      ),
    },
    {
      title: "Poručilac",
      dataIndex: "client",
      key: "client",
      ...getColumnSearchProps("client"),
    },
    {
      title: "Naziv posla",
      dataIndex: "businessName",
      key: "businessName",
    },
  ];

  columns.push({
    title: "Akcije",
    width:
      "10vw" /*bilo je bez width,ali se tada ne poravnaju linije header i body*/,
    render: (text, record) => (
      <div
        style={{
          display: "inline-flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: "15px",
        }}
      >
        <div
          style={{ margin: "2px", padding: "4px", cursor: "pointer" }}
          className="lock"
        >
          <Link to={`/admin/edit-old-calculations/${record._id}`}>
            <EditOutlined
              className="icon-unlock"
              title={`Izmeni kalkulaciju`}
              style={{ textDecoration: "none", color: "black" }}
            />
          </Link>
        </div>
      </div>
    ),
  });

  return (
    <div className="table-data-okvir">
      <Table
        size="middle"
        bordered
        dataSource={data}
        columns={columns}
        rowKey="_id"
        pagination={{
          defaultPageSize: 20,
          position: ["topCenter"],
          showSizeChanger: false,
          pageSizeOptions: ["10", "20", "50", "100"],
          hideOnSinglePage: true,
        }}
      />
    </div>
  );
};

export default OldCalculationTable;
