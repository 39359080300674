import React, { useContext, useEffect, useState } from "react";
import { SERVER_URL } from "../../config";
import useAxios from "../../hooks/useAxios";
import { UserContext } from "../../App";
import ContactForm from "../../components/forms/ContactForm";
import { LoadingOutlined } from "@ant-design/icons";
import Axios from "axios";
import { notification } from "antd";
import { useHistory } from "react-router-dom";
import { UserForm } from "../../components/forms";

const EditContact = (props) => {
  const currentuser = useContext(UserContext);
  const history = useHistory();
  const [allClients, setAllClients] = useState();
  const [clients, fetchClients] = useAxios(
    "",
    null,
    currentuser.data.token,
    "get",
  );
  const [contact, fetchContact] = useAxios(
    "",
    null,
    currentuser.data.token,
    "get",
  );
  const [client, setClient] = useState({});
  const userId = currentuser.data.id;
  const { id } = props.match.params;

  const fetchClientsForForward = async () => {
    const res = await Axios.get(`${SERVER_URL}/new-clients`, {
      withCredentials: false,
      headers: { Authorization: `Bearer ${currentuser.data.token}` },
    });

    if (client) {
      setAllClients([...res.data.items, client]);
    } else {
      setAllClients([...res.data.items]);
    }
  };

  useEffect(() => {
    fetchClients(`${SERVER_URL}/new-clients`, []);
    fetchClientsForForward();
    if (id) fetchContact(`${SERVER_URL}/activities/${id}`);
    if (contact && contact.data) setClient(contact.data.clientId);
  }, [fetchClients, fetchContact, id, contact, client]);

  const createContact = async (data) => {
    try {
      let comment =
        !data.comment && data.status !== client.status
          ? "PROMENJEN STATUS KLIJENTA"
          : data.comment;
      await Axios.post(
        `${SERVER_URL}/activities`,
        { userId, ...data, comment },
        {
          withCredentials: false,
          headers: { Authorization: `Bearer ${currentuser.data.token}` },
        },
      );
      // await Axios.put(
      //   `${SERVER_URL}/clients/${client._id}`,
      //   { status: data.status },
      //   {
      //     withCredentials: false,
      //     headers: { Authorization: `Bearer ${currentuser.data.token}` },
      //   },
      // );
      notification.success({
        message: "Radna aktivnost je kreirana.",
        placement: "bottomRight",
      });
      history.push("/admin/contacts");
    } catch (err) {
      notification.error({
        message:
          err.response.data && err.response.data.message
            ? err.response.data.message
            : "Problem sa kreiranjem radne aktivnosti. Molimo vas pokusajte kasnije.",
        placement: "bottomRight",
      });
    }
  };

  const updateContact = async (data) => {
    try {
      let form =
        data.status !== client.status
          ? { status: data.status, editedBy: userId }
          : { status: data.status };
      await Axios.put(
        `${SERVER_URL}/activities/${id}`,
        { ...data, status: data.status },
        {
          withCredentials: false,
          headers: { Authorization: `Bearer ${currentuser.data.token}` },
        },
      );
      await Axios.put(`${SERVER_URL}/clients/${client._id}`, form, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });
      notification.success({
        message: "Radna aktivnost je editovana.",
        placement: "bottomRight",
      });
      history.push("/admin/contacts");
    } catch (err) {
      notification.error({
        message: "Problem sa editovanjem. Molimo vas pokusajte kasnije.",
        placement: "bottomRight",
      });
    }
  };

  const handleSelectChange = async (id, form) => {
    await Axios.get(`${SERVER_URL}/clients/${id}`, {
      withCredentials: false,
      headers: { Authorization: `Bearer ${currentuser.data.token}` },
    })
      .then((res) => {
        setClient(res.data);
        form.setFieldsValue({ status: res.data.status });
      })
      .catch((err) => console.log(err.message));
  };

  return (
    <div style={{ padding: "8px" }}>
      <div style={{ textAlign: "center" }}>
        {id && contact.isLoading && (
          <LoadingOutlined
            spin
            style={{ fontSize: "3rem", marginTop: "5rem" }}
          />
        )}
        {id &&
          !contact.isLoading &&
          !clients.isLoading &&
          clients.data &&
          !contact.isError &&
          contact.data && (
            <ContactForm
              client={client}
              isNew={false}
              allClients={allClients}
              clients={clients.data}
              data={contact.data}
              contactHandler={updateContact}
            />
          )}
        {clients && !clients.isLoading && clients.isError && (
          <h2 style={{ marginTop: "5rem" }}>Something went wrong :(</h2>
        )}
        {!id &&
          contact &&
          !contact.data &&
          !clients.isLoading &&
          clients.data && (
            <ContactForm
              isNew={true}
              client={client}
              allClients={allClients}
              clients={clients.data}
              data={null}
              contactHandler={createContact}
              selectHandler={handleSelectChange}
            />
          )}
        {/*{!clientId && client && !client.data && <ClientForm data={null} createHandler={createClient} result={result} setResult={setResult} />}*/}
      </div>
    </div>
  );
};

export default EditContact;
