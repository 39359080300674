import React, { useContext, useEffect } from 'react';
import { Form, Input, Button, Card } from 'antd';
import { UserContext } from '../App';
import useAxios from "../hooks/useAxios";
import { SERVER_URL } from "../config";

const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 18 },
};
const tailLayout = {
  wrapperCol: { span: 24 },
};

const Login = (props) => {
  const user = useContext(UserContext);
  console.log(props);
  const onFinish = (values) => {
    user.handleLogin(values);
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <div className="log">
      <div className="card-wrapper">
        <img className="login-logo" src="images/logoNew.png" alt="birografika login logo" />
        <Card title="PRIJAVI SE" className="login-header" bordered={false}>
          <Form {...layout} name='basic' onFinish={onFinish} onFinishFailed={onFinishFailed}>
            <Form.Item label='Email' name='email' rules={[{ required: true, message: 'Please enter your email!' }]}>
              <Input />
            </Form.Item>

            <Form.Item label='Lozinka' name='password' rules={[{ required: true, message: 'Please enter your   password!' }]}>
              <Input.Password />
            </Form.Item>

            <Form.Item {...tailLayout} style={{ marginBottom: 0 }}>
              <Button type='primary' htmlType='submit' style={{ backgroundColor: "#216c97", borderRadius: '0', width: '120px' }}>
                POTVRDI
            </Button>
            </Form.Item>
          </Form>
        </Card>
      </div>
    </div>
  );
};

export default Login;
