import React, { useContext, useState } from "react";
import {
  Table,
  Input,
  Button,
  Popconfirm,
  Checkbox,
  Row,
  Col,
  DatePicker,
  Badge,
  Select,
} from "antd";
import ResizableAntdTable from "resizable-antd-table";
import {
  DeleteOutlined,
  EditOutlined,
  EyeOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import { UserContext } from "../../App";
import { Link } from "react-router-dom";
import moment from "moment";

const ActivityTable = ({
  data,
  deleteHandler,
  title,
  editPath,
  viewPath,
  filterData,
  status,
}) => {
  const user = useContext(UserContext);
  let [search, setSearch] = useState({});
  let searchInput;

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <div style={{ padding: 8 }}>
          {dataIndex === "date" ? (
            <DatePicker.RangePicker
              ref={(node) => {
                searchInput = node;
              }}
              value={selectedKeys[0]}
              onChange={(value) => {
                handleInputChange(dataIndex, value);
                setSelectedKeys([value]);
              }}
              onPressEnter={() =>
                handleSearch(selectedKeys, confirm, dataIndex)
              }
              style={{ width: 188, marginBottom: 8, display: "block" }}
              placeholder={["Datum od", "Datum do"]}
            />
          ) : dataIndex === "clientStatus" ? (
            <div style={{ width: 220, marginBottom: 8, display: "block" }}>
              {status.map((item, index) => (
                <div>
                  <Checkbox
                    key={index}
                    value={item}
                    checked={selectedKeys.includes(item)}
                    onChange={(e) => {
                      if (e.target.checked) {
                        setSelectedKeys(
                          e.target.value
                            ? [...selectedKeys, e.target.value]
                            : [],
                        );
                      } else {
                        setSelectedKeys(
                          selectedKeys.filter((key) => key !== e.target.value),
                        );
                      }
                      handleInputChange(dataIndex, [
                        ...selectedKeys,
                        e.target.value,
                      ]);
                    }}
                    onPressEnter={() =>
                      handleSearch(selectedKeys, confirm, dataIndex)
                    }
                    ref={(node) => {
                      searchInput = node;
                    }}
                  >
                    {item}
                  </Checkbox>
                </div>
              ))}
            </div>
          ) : (
            <Input
              ref={(node) => {
                searchInput = node;
              }}
              placeholder={`Pretrazi ${dataIndex}`}
              value={selectedKeys[0]}
              onChange={(e) => {
                handleInputChange(dataIndex, e.target.value);
                setSelectedKeys(e.target.value ? [e.target.value] : []);
              }}
              onPressEnter={() =>
                handleSearch(selectedKeys, confirm, dataIndex)
              }
              style={{ width: 188, marginBottom: 8, display: "block" }}
            />
          )}
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            size="small"
            style={{ width: 90, marginRight: 8 }}
          >
            Pretrazi
          </Button>
          <Button
            onClick={() => handleReset(clearFilters, dataIndex)}
            size="small"
            style={{ width: 90 }}
          >
            Resetuj
          </Button>
        </div>
      );
    },
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{ color: filtered ? "red" : "#bbb", fontSize: "1rem" }}
      />
    ),
    onFilter: (value, record) => {
      const filterRecord = Array.isArray(dataIndex)
        ? !dataIndex.includes("category")
          ? record[dataIndex[0]][dataIndex[1]]
          : record[dataIndex[0]][dataIndex[1]][dataIndex[2]]
        : dataIndex === "date"
        ? record["dateTime"]
        : record[dataIndex];
      if (!filterRecord || !value) return false;
      return dataIndex === "date"
        ? new Date(filterRecord) >= new Date(value[0]) &&
            new Date(filterRecord) <= new Date(value[1])
        : filterRecord.toString().toLowerCase().includes(value.toLowerCase());
    },
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => {
          searchInput.focus();
        });
      }
    },
  });

  const handleSearch = async (selectedKeys, confirm) => {
    await filterData(search);
    confirm();
  };

  const handleReset = async (clearFilters, dataIndex) => {
    const q = { ...search };
    delete q[dataIndex];
    setSearch(q);
    await filterData(q);
    clearFilters();
  };

  const handleInputChange = async (columnName, value) => {
    const q = { ...search };
    q[columnName] = value;
    setSearch(q);
  };

  const columns = [
    {
      key: "Client",
      title: "Klijent",
      dataIndex: "client",
      ...getColumnSearchProps("client"),
    },
    {
      key: "User",
      title: "Prodavac",
      dataIndex: "user",
      ...getColumnSearchProps("user"),
    },
    {
      key: "comment",
      title: "Komentar",
      dataIndex: "comment",
      ...getColumnSearchProps("comment"),
    },
    {
      key: "dateTime",
      title: "Datum",
      dataIndex: "date",
      ...getColumnSearchProps("date"),
    },
    {
      key: "clientStatus",
      title: "Status klijenta",
      dataIndex: "clientStatus",
      ...getColumnSearchProps("clientStatus"),
    },
  ];

  columns.push({
    title: "Opcije",
    render: (text, record) => (
      <div
        style={{
          display: "inline-flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: "15px",
        }}
      >
        <div style={{ margin: "2px", padding: "4px", cursor: "pointer" }}>
          <Link to={editPath + record._id}>
            <EditOutlined
              title={`Edit ${title.toLowerCase()}`}
              style={{ textDecoration: "none", color: "black" }}
            />
          </Link>
        </div>

        {viewPath && (
          <div style={{ margin: "2px", padding: "4px", cursor: "pointer" }}>
            <Link to={viewPath + record._id}>
              <EyeOutlined
                title={`View ${title.toLowerCase()}`}
                style={{ textDecoration: "none", color: "black" }}
              />
            </Link>
          </div>
        )}

        <div style={{ margin: "2px", padding: "4px" }}>
          <Popconfirm
            disabled={record._id === user.data.id}
            placement="left"
            title={`Ovo ce obrisati potencijalnog klijenta`}
            onConfirm={() => {
              deleteHandler(record._id);
            }}
            okText="Ok"
            cancelText="Cancel"
          >
            <DeleteOutlined title={`Ovo ce obrisati potencijalnog klijenta`} />
          </Popconfirm>
        </div>
      </div>
    ),
  });

  data.sort(
    (a, b) => new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime(),
  );

  return (
    <div>
      <ResizableAntdTable
        size="middle"
        bordered
        dataSource={data}
        columns={columns}
        rowClassName={(record, index) =>
          record.clientId
            ? record.clientId.status === "AKTIVAN"
              ? "table-row-client-active"
              : record.clientId.status === "NEZAINTERESOVAN"
              ? "table-row-client-uninterested"
              : record.clientId.status === "USPOSTAVLJEN KONTAKT"
              ? "table-row-client-contacted"
              : record.clientId.status === "POSLATA PONUDA"
              ? "table-row-client-offer-sent"
              : "table-row-client-uncontacted"
            : null
        }
        rowKey="_id"
        pagination={{
          defaultPageSize: 100,
          position: "bottom",
          showSizeChanger: false,
          pageSizeOptions: ["10", "20", "50", "100"],
          hideOnSinglePage: true,
        }}
      />
    </div>
  );
};

export default ActivityTable;
