import React from "react";
import { useHistory } from "react-router-dom";
import { Form, Input, Button, Card, Select, Result } from "antd";
import UploadBox from "../base/UploadBox";

const tailLayout = {
  // wrapperCol: { offset: 8, span: 24 },
  wrapperCol: { span: 24 },
};

const layout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 20 },
};

const initialValues = {
  email: undefined,
  password: undefined,
  address: undefined,
  city: undefined,
  zip: undefined,
  country: undefined,
  avatar: undefined,
  role: [],
};

const UserForm = ({
  data,
  updateHandler,
  createHandler,
  result,
  setResult,
  avatar,
}) => {
  const history = useHistory();
  const [form] = Form.useForm();
  let user = data ? { ...data } : initialValues;
  const isNew = data ? false : true;

  const prevForm = localStorage.getItem("userForm");
  if (prevForm) user = JSON.parse(prevForm);

  let image;
  if (data && data.avatar) image = data.avatar.url;
  if (avatar) image = avatar.url;

  const onFinish = (values, isNew) => {
    localStorage.removeItem("userForm");
    if (isNew) {
      if (avatar) {
        values.avatar = avatar._id;
      }
      createHandler(values);
    } else {
      if (data.avatar) values.avatar = data.avatar._id;
      if (avatar) values.avatar = avatar._id;
      updateHandler(values);
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const avatarHandler = () => {
    const values = form.getFieldsValue();
    localStorage.setItem("userForm", JSON.stringify(values));
    history.push(
      `/admin/gallery/edit-content?fromPage=${
        isNew ? "new-user" : "edit-user/" + data._id
      }${values.avatar ? "&imageIds=" + values.avatar._id.toString() : ""}`,
    );
  };

  return (
    <div>
      {/* <h2>{isNew ? 'NEW' : 'EDIT'} USER</h2> */}
      <div className="dashboard">
        <div className="card-wrapper">
          {/* eslint-disable-next-line no-useless-concat */}
          <Card
            title={`${isNew ? "NOVI PRODAVAC" : "EDITUJ PRODAVCA"}`}
            bordered={false}
          >
            {!result && (
              <Form
                {...layout}
                name="basic"
                initialValues={user}
                onFinish={(values) => onFinish(values, isNew)}
                onFinishFailed={onFinishFailed}
                form={form}
              >
                <Form.Item
                  label="E-mail"
                  name="email"
                  rules={[
                    { required: true, message: "Molimo vas unesite email!" },
                  ]}
                >
                  <Input placeholder="E-mail" />
                </Form.Item>

                <Form.Item
                  label="Ime"
                  name="firstName"
                  rules={[
                    { required: true, message: "Molimo vas unesite ime!" },
                  ]}
                >
                  <Input placeholder="Ime" />
                </Form.Item>

                <Form.Item
                  label="Prezime"
                  name="lastName"
                  rules={[
                    { required: true, message: "Molimo vas unesite prezime!" },
                  ]}
                >
                  <Input placeholder="Prezime" />
                </Form.Item>

                <Form.Item label="Adresa" name="address">
                  <Input placeholder="Adresa" />
                </Form.Item>

                <Form.Item label="Grad" name="city">
                  <Input placeholder="Grad" />
                </Form.Item>

                <Form.Item label="PTT" name="zip">
                  <Input placeholder="Postanski broj" />
                </Form.Item>

                {/*<Form.Item label='Drzava' name='country'>*/}
                {/*  <Input placeholder='Drzava' />*/}
                {/*</Form.Item>*/}

                {isNew && (
                  <Form.Item
                    label="Lozinka"
                    name="password"
                    rules={[
                      {
                        required: true,
                        message: "Molimo vas unesite lozinku!",
                      },
                    ]}
                  >
                    <Input.Password placeholder="Lozinka" />
                  </Form.Item>
                )}

                <Form.Item
                  label="Rola"
                  name="role"
                  rules={[
                    { required: true, message: "Molimo vas izaberite rolu!" },
                  ]}
                >
                  <Select mode="multiple" placeholder="Role">
                    <Select.Option value="admin">Admin</Select.Option>
                    <Select.Option value="user">Prodavac</Select.Option>
                    <Select.Option value="ordermanager">
                      Menadžer ponuda
                    </Select.Option>
                  </Select>
                </Form.Item>

                {!isNew && (
                  <Form.Item
                    label="Status"
                    name="status"
                    rules={[
                      {
                        required: true,
                        message: "Molimo vas izaberite status!",
                      },
                    ]}
                  >
                    <Select>
                      <Select.Option value="AKTIVAN">AKTIVAN</Select.Option>
                      <Select.Option value="SUSPENDOVAN">
                        SUSPENDOVAN
                      </Select.Option>
                      <Select.Option value="OBRISAN">OBRISAN</Select.Option>
                      <Select.Option value="CEKANJE AKTIVACIJE">
                        CEKANJE AKTIVACIJE
                      </Select.Option>
                    </Select>
                  </Form.Item>
                )}

                {/*<Form.Item label='Avatar' className='upload-wrapper right' name='avatar'>*/}
                {/*  <UploadBox handler={avatarHandler} image={image} />*/}
                {/*</Form.Item>*/}

                <Form.Item {...tailLayout}>
                  <Button
                    type="primary"
                    htmlType="submit"
                    style={{ float: "right" }}
                  >
                    Potvrdi
                  </Button>
                </Form.Item>
              </Form>
            )}
            {result && (
              <Result
                title="Korisnik je kreiran i poslat je verifikacioni e-mail"
                extra={
                  <Button
                    type="primary"
                    key="console"
                    onClick={() => (window.location.href = "/")}
                  >
                    Ok
                  </Button>
                }
              />
            )}
          </Card>
        </div>
      </div>
    </div>
  );
};

export default UserForm;
