const printingStepsOne = [
  {
    number: 64,
    maxWidth: 203.2,
    numberOfCylinders: "8",
    steps: [
      { width: 203.2, numberOfItems: 4.921, numberOfElements: 1 },
      { width: 101.6, numberOfItems: 9.843, numberOfElements: 2 },
      { width: 67.733, numberOfItems: 14.764, numberOfElements: 3 },
      { width: 50.8, numberOfItems: 19.685, numberOfElements: 4 },
      { width: 40.64, numberOfItems: 24.606, numberOfElements: 5 },
      { width: 33.867, numberOfItems: 29.528, numberOfElements: 6 },
      { width: 29.029, numberOfItems: 34.449, numberOfElements: 7 },
      { width: 25.4, numberOfItems: 39.37, numberOfElements: 8 },
      { width: 22.578, numberOfItems: 44.291, numberOfElements: 9 },
      { width: 20.32, numberOfItems: 49.213, numberOfElements: 10 },
    ],
  },
  {
    number: 70,
    maxWidth: 222.25,
    numberOfCylinders: "8 3/4",
    steps: [
      { width: 222.25, numberOfItems: 4.499, numberOfElements: 1 },
      { width: 111.125, numberOfItems: 8.999, numberOfElements: 2 },
      { width: 74.083, numberOfItems: 13.498, numberOfElements: 3 },
      { width: 55.563, numberOfItems: 17.998, numberOfElements: 4 },
      { width: 44.45, numberOfItems: 22.497, numberOfElements: 5 },
      { width: 37.042, numberOfItems: 26.997, numberOfElements: 6 },
      { width: 31.75, numberOfItems: 31.496, numberOfElements: 7 },
      { width: 27.781, numberOfItems: 35.996, numberOfElements: 8 },
      { width: 24.694, numberOfItems: 40.495, numberOfElements: 9 },
      { width: 22.225, numberOfItems: 44.994, numberOfElements: 10 },
    ],
  },
  {
    number: 72,
    maxWidth: 228.6,
    numberOfCylinders: "9",
    steps: [
      { width: 228.6, numberOfItems: 4.374, numberOfElements: 1 },
      { width: 114.3, numberOfItems: 8.749, numberOfElements: 2 },
      { width: 76.2, numberOfItems: 13.123, numberOfElements: 3 },
      { width: 57.15, numberOfItems: 17.498, numberOfElements: 4 },
      { width: 45.72, numberOfItems: 21.872, numberOfElements: 5 },
      { width: 38.1, numberOfItems: 26.247, numberOfElements: 6 },
      { width: 32.657, numberOfItems: 30.621, numberOfElements: 7 },
      { width: 28.575, numberOfItems: 34.996, numberOfElements: 8 },
      { width: 25.4, numberOfItems: 39.37, numberOfElements: 9 },
      { width: 22.86, numberOfItems: 43.745, numberOfElements: 10 },
    ],
  },
  {
    number: 74,
    maxWidth: 234.95,
    numberOfCylinders: "9 1/4",
    steps: [
      { width: 234.95, numberOfItems: 4.256, numberOfElements: 1 },
      { width: 117.475, numberOfItems: 8.512, numberOfElements: 2 },
      { width: 78.317, numberOfItems: 12.769, numberOfElements: 3 },
      { width: 58.738, numberOfItems: 17.025, numberOfElements: 4 },
      { width: 46.99, numberOfItems: 21.281, numberOfElements: 5 },
      { width: 39.158, numberOfItems: 25.537, numberOfElements: 6 },
      { width: 33.564, numberOfItems: 29.794, numberOfElements: 7 },
      { width: 29.369, numberOfItems: 34.05, numberOfElements: 8 },
      { width: 26.106, numberOfItems: 38.306, numberOfElements: 9 },
      { width: 23.495, numberOfItems: 42.562, numberOfElements: 10 },
    ],
  },
  {
    number: 76,
    maxWidth: 241.3,
    numberOfCylinders: "9 1/2",
    steps: [
      { width: 241.3, numberOfItems: 4.144 },
      { width: 120.65, numberOfItems: 8.288 },
      { width: 80.433, numberOfItems: 12.433 },
      { width: 60.325, numberOfItems: 16.577 },
      { width: 48.26, numberOfItems: 20.721 },
      { width: 40.217, numberOfItems: 24.865 },
      { width: 34.471, numberOfItems: 29.01 },
      { width: 30.163, numberOfItems: 33.154 },
      { width: 26.811, numberOfItems: 37.298 },
      { width: 24.13, numberOfItems: 41.442 },
    ],
  },
  {
    number: 80,
    maxWidth: 254.0,
    numberOfCylinders: "10",
    steps: [
      { width: 254.0, numberOfItems: 3.937 },
      { width: 127.0, numberOfItems: 7.784 },
      { width: 84.667, numberOfItems: 11.811 },
      { width: 63.5, numberOfItems: 15.748 },
      { width: 50.8, numberOfItems: 19.685 },
      { width: 42.333, numberOfItems: 23.622 },
      { width: 36.286, numberOfItems: 27.559 },
      { width: 31.75, numberOfItems: 31.496 },
      { width: 28.222, numberOfItems: 35.433 },
      { width: 25.4, numberOfItems: 39.37 },
    ],
  },
  {
    number: 87,
    maxWidth: 276.23,
    numberOfCylinders: "10 7/8",
    steps: [
      { width: 276.23, numberOfItems: 3.62 },
      { width: 138.115, numberOfItems: 7.24 },
      { width: 92.077, numberOfItems: 10.861 },
      { width: 69.058, numberOfItems: 14.481 },
      { width: 55.246, numberOfItems: 18.101 },
      { width: 46.038, numberOfItems: 21.721 },
      { width: 39.461, numberOfItems: 25.341 },
      { width: 34.529, numberOfItems: 28.961 },
      { width: 30.692, numberOfItems: 32.582 },
      { width: 27.623, numberOfItems: 36.202 },
    ],
  },
  {
    number: 88,
    maxWidth: 279.4,
    numberOfCylinders: "11",
    steps: [
      { width: 279.4, numberOfItems: 3.579, numberOfElements: 1 },
      { width: 139.7, numberOfItems: 7.158, numberOfElements: 2 },
      { width: 93.133, numberOfItems: 10.737, numberOfElements: 3 },
      { width: 69.85, numberOfItems: 14.316, numberOfElements: 4 },
      { width: 55.88, numberOfItems: 17.895, numberOfElements: 5 },
      { width: 46.567, numberOfItems: 21.475, numberOfElements: 6 },
      { width: 39.914, numberOfItems: 25.054, numberOfElements: 7 },
      { width: 34.925, numberOfItems: 28.633, numberOfElements: 8 },
      { width: 31.044, numberOfItems: 32.212, numberOfElements: 9 },
      { width: 27.94, numberOfItems: 35.791, numberOfElements: 10 },
    ],
  },
  {
    number: 89,
    maxWidth: 282.58,
    numberOfCylinders: "11 1/8",
    steps: [
      { width: 282.58, numberOfItems: 3.539, numberOfElements: 1 },
      { width: 141.288, numberOfItems: 7.078, numberOfElements: 2 },
      { width: 94.192, numberOfItems: 10.617, numberOfElements: 3 },
      { width: 70.644, numberOfItems: 14.156, numberOfElements: 4 },
      { width: 56.515, numberOfItems: 17.694, numberOfElements: 5 },
      { width: 47.096, numberOfItems: 21.233, numberOfElements: 6 },
      { width: 40.368, numberOfItems: 24.772, numberOfElements: 7 },
      { width: 35.322, numberOfItems: 28.311, numberOfElements: 8 },
      { width: 31.397, numberOfItems: 31.85, numberOfElements: 9 },
      { width: 28.258, numberOfItems: 35.389, numberOfElements: 10 },
    ],
  },

  {
    number: 90,
    maxWidth: 285.75,
    numberOfCylinders: "11 1/4",
    steps: [
      { width: 285.75, numberOfItems: 3.5 },
      { width: 142.875, numberOfItems: 6.999 },
      { width: 95.25, numberOfItems: 10.499 },
      { width: 71.438, numberOfItems: 13.998 },
      { width: 57.15, numberOfItems: 17.498 },
      { width: 47.625, numberOfItems: 20.997 },
      { width: 40.821, numberOfItems: 24.497 },
      { width: 35.719, numberOfItems: 20.997 },
      { width: 31.75, numberOfItems: 31.496 },
      { width: 28.575, numberOfItems: 34.996 },
    ],
  },
  {
    number: 91,
    maxWidth: 288.93,
    numberOfCylinders: "11 3/8",
    steps: [
      { width: 288.93, numberOfItems: 3.461 },
      { width: 144.465, numberOfItems: 6.922 },
      { width: 96.31, numberOfItems: 10.383 },
      { width: 72.233, numberOfItems: 13.844 },
      { width: 57.786, numberOfItems: 17.305 },
      { width: 48.155, numberOfItems: 20.766 },
      { width: 41.276, numberOfItems: 24.227 },
      { width: 36.116, numberOfItems: 27.689 },
      { width: 32.103, numberOfItems: 31.149 },
      { width: 28.893, numberOfItems: 34.61 },
    ],
  },
  {
    number: 98,
    maxWidth: 311.15,
    numberOfCylinders: "12 1/4",
    steps: [
      { width: 311.15, numberOfItems: 3.214 },
      { width: 155.575, numberOfItems: 6.428 },
      { width: 103.717, numberOfItems: 9.642 },
      { width: 77.788, numberOfItems: 12.856 },
      { width: 62.23, numberOfItems: 16.069 },
      { width: 51.858, numberOfItems: 19.283 },
      { width: 44.45, numberOfItems: 22.497 },
      { width: 38.894, numberOfItems: 25.711 },
      { width: 34.572, numberOfItems: 28.925 },
      { width: 31.115, numberOfItems: 32.139 },
    ],
  },
  {
    number: 103,
    maxWidth: 327.03,
    numberOfCylinders: "12 7/8",
    steps: [
      { width: 327.03, numberOfItems: 3.058 },
      { width: 163.515, numberOfItems: 6.116 },
      { width: 109.01, numberOfItems: 9.173 },
      { width: 81.758, numberOfItems: 12.231 },
      { width: 65.406, numberOfItems: 15.289 },
      { width: 54.505, numberOfItems: 18.347 },
      { width: 46.719, numberOfItems: 21.405 },
      { width: 40.879, numberOfItems: 24.463 },
      { width: 36.337, numberOfItems: 27.52 },
      { width: 32.703, numberOfItems: 30.578 },
    ],
  },
  {
    number: 106,
    maxWidth: 336.55,
    numberOfCylinders: "13 1/4",
    steps: [
      { width: 336.55, numberOfItems: 2.971 },
      { width: 168.275, numberOfItems: 5.943 },
      { width: 112.183, numberOfItems: 8.914 },
      { width: 84.138, numberOfItems: 11.885 },
      { width: 67.31, numberOfItems: 14.857 },
      { width: 56.092, numberOfItems: 17.828 },
      { width: 48.079, numberOfItems: 20.799 },
      { width: 42.069, numberOfItems: 23.771 },
      { width: 37.394, numberOfItems: 26.742 },
      { width: 33.655, numberOfItems: 29.713 },
    ],
  },
  {
    number: 109,
    maxWidth: 346.075,
    numberOfCylinders: "13 5/8",
    steps: [
      { width: 346.075, numberOfItems: 2.89 },
      { width: 173.038, numberOfItems: 5.779 },
      { width: 115.358, numberOfItems: 8.669 },
      { width: 86.519, numberOfItems: 11.558 },
      { width: 69.215, numberOfItems: 14.448 },
      { width: 57.679, numberOfItems: 17.337 },
      { width: 49.439, numberOfItems: 20.227 },
      { width: 43.259, numberOfItems: 23.116 },
      { width: 38.453, numberOfItems: 26.006 },
      { width: 34.608, numberOfItems: 28.895 },
    ],
  },
  {
    number: 114,
    maxWidth: 361.95,
    numberOfCylinders: "14 1/4",
    steps: [
      { width: 361.95, numberOfItems: 2.763 },
      { width: 180.975, numberOfItems: 5.526 },
      { width: 120.65, numberOfItems: 8.288 },
      { width: 90.488, numberOfItems: 11.051 },
      { width: 72.39, numberOfItems: 13.814 },
      { width: 60.325, numberOfItems: 16.577 },
      { width: 51.707, numberOfItems: 19.34 },
      { width: 45.244, numberOfItems: 22.103 },
      { width: 40.217, numberOfItems: 24.865 },
      { width: 36.195, numberOfItems: 27.628 },
    ],
  },
  {
    number: 116,
    maxWidth: 368.3,
    numberOfCylinders: "14 1/2",
    steps: [
      { width: 368.3, numberOfItems: 2.715 },
      { width: 184.15, numberOfItems: 5.43 },
      { width: 122.767, numberOfItems: 8.146 },
      { width: 92.075, numberOfItems: 10.861 },
      { width: 73.66, numberOfItems: 13.576 },
      { width: 61.383, numberOfItems: 16.291 },
      { width: 52.614, numberOfItems: 19.006 },
      { width: 46.038, numberOfItems: 21.721 },
      { width: 40.922, numberOfItems: 24.437 },
      { width: 36.83, numberOfItems: 27.152 },
    ],
  },
  {
    number: 119,
    maxWidth: 377.83,
    numberOfCylinders: "14 7/8",
    steps: [
      { width: 377.83, numberOfItems: 2.647, numberOfElements: 1 },
      { width: 188.913, numberOfItems: 5.293, numberOfElements: 2 },
      { width: 125.942, numberOfItems: 7.94, numberOfElements: 3 },
      { width: 94.456, numberOfItems: 10.587, numberOfElements: 4 },
      { width: 75.565, numberOfItems: 13.234, numberOfElements: 5 },
      { width: 62.971, numberOfItems: 15.88, numberOfElements: 6 },
      { width: 53.975, numberOfItems: 18.527, numberOfElements: 7 },
      { width: 47.228, numberOfItems: 21.174, numberOfElements: 8 },
      { width: 41.981, numberOfItems: 23.821, numberOfElements: 9 },
      { width: 37.783, numberOfItems: 26.467, numberOfElements: 10 },
    ],
  },

  {
    number: 124,
    maxWidth: 393.7,
    numberOfCylinders: "15 1/2",
    steps: [
      { width: 393.7, numberOfItems: 2.54 },
      { width: 196.85, numberOfItems: 5.08 },
      { width: 131.233, numberOfItems: 7.62 },
      { width: 98.425, numberOfItems: 10.16 },
      { width: 78.74, numberOfItems: 12.7 },
      { width: 65.617, numberOfItems: 15.24 },
      { width: 56.243, numberOfItems: 17.78 },
      { width: 49.213, numberOfItems: 20.32 },
      { width: 43.744, numberOfItems: 22.86 },
      { width: 39.37, numberOfItems: 25.4 },
    ],
  },
  {
    number: 128,
    maxWidth: 406.4,
    numberOfCylinders: "16",
    steps: [
      { width: 406.4, numberOfItems: 2.461 },
      { width: 203.2, numberOfItems: 4.921 },
      { width: 135.467, numberOfItems: 7.382 },
      { width: 101.6, numberOfItems: 9.843 },
      { width: 81.28, numberOfItems: 12.303 },
      { width: 67.733, numberOfItems: 14.764 },
      { width: 58.057, numberOfItems: 17.224 },
      { width: 50.8, numberOfItems: 19.685 },
      { width: 45.156, numberOfItems: 22.146 },
      { width: 40.64, numberOfItems: 24.606 },
    ],
  },
  {
    number: 132,
    maxWidth: 419.1,
    numberOfCylinders: "16 1/2",
    steps: [
      { width: 419.1, numberOfItems: 2.386 },
      { width: 209.55, numberOfItems: 4.772 },
      { width: 139.7, numberOfItems: 7.158 },
      { width: 104.775, numberOfItems: 9.544 },
      { width: 83.82, numberOfItems: 11.93 },
      { width: 69.85, numberOfItems: 14.316 },
      { width: 59.871, numberOfItems: 16.702 },
      { width: 52.388, numberOfItems: 19.089 },
      { width: 45.567, numberOfItems: 21.475 },
      { width: 41.91, numberOfItems: 23.861 },
    ],
  },
  {
    number: 136,
    maxWidth: 431.8,
    numberOfCylinders: "17",
    steps: [
      { width: 431.8, numberOfItems: 2.316 },
      { width: 215.9, numberOfItems: 4.632 },
      { width: 143.933, numberOfItems: 6.948 },
      { width: 107.95, numberOfItems: 9.264 },
      { width: 86.36, numberOfItems: 11.579 },
      { width: 71.967, numberOfItems: 13.895 },
      { width: 61.686, numberOfItems: 16.211 },
      { width: 53.975, numberOfItems: 18.527 },
      { width: 20.843, numberOfItems: 49.036 },
      { width: 43.18, numberOfItems: 23.159 },
    ],
  },
  {
    number: 146,
    maxWidth: 463.55,
    numberOfCylinders: "18 1/4",
    steps: [
      { width: 463.55, numberOfItems: 2.157, numberOfElements: 1 },
      { width: 231.775, numberOfItems: 4.315, numberOfElements: 2 },
      { width: 154.517, numberOfItems: 6.472, numberOfElements: 3 },
      { width: 115.888, numberOfItems: 8.629, numberOfElements: 4 },
      { width: 92.71, numberOfItems: 10.786, numberOfElements: 5 },
      { width: 77.258, numberOfItems: 12.944, numberOfElements: 6 },
      { width: 66.221, numberOfItems: 15.101, numberOfElements: 7 },
      { width: 57.944, numberOfItems: 17.258, numberOfElements: 8 },
      { width: 51.506, numberOfItems: 19.415, numberOfElements: 9 },
      { width: 46.355, numberOfItems: 21.573, numberOfElements: 10 },
    ],
  },
  {
    number: 156,
    maxWidth: 495.3,
    numberOfCylinders: "19 1/2",
    steps: [
      { width: 495.3, numberOfItems: 2.019, numberOfElements: 1 },
      { width: 247.65, numberOfItems: 4.038, numberOfElements: 2 },
      { width: 165.1, numberOfItems: 6.057, numberOfElements: 3 },
      { width: 123.825, numberOfItems: 8.076, numberOfElements: 4 },
      { width: 99.06, numberOfItems: 10.095, numberOfElements: 5 },
      { width: 82.55, numberOfItems: 12.114, numberOfElements: 6 },
      { width: 70.757, numberOfItems: 14.133, numberOfElements: 7 },
      { width: 61.913, numberOfItems: 16.152, numberOfElements: 8 },
      { width: 55.033, numberOfItems: 16.152, numberOfElements: 9 },
      { width: 49.53, numberOfItems: 20.19, numberOfElements: 10 },
    ],
  },
];

const printingStepsTwo = [
  {
    number: 73,
    maxWidth: 231.78,
    numberOfCylinders: "9 1/8",
    steps: [
      { width: 231.78, numberOfItems: 4.315 },
      { width: 115.888, numberOfItems: 8.629 },
      { width: 77.258, numberOfItems: 12.944 },
      { width: 57.944, numberOfItems: 17.258 },
      { width: 46.355, numberOfItems: 21.573 },
      { width: 38.629, numberOfItems: 25.887 },
      { width: 33.111, numberOfItems: 30.202 },
      { width: 28.972, numberOfItems: 34.516 },
      { width: 25.753, numberOfItems: 38.831 },
      { width: 23.178, numberOfItems: 43.145 },
    ],
  },
  {
    number: 78,
    maxWidth: 247.65,
    numberOfCylinders: "9 3/4",
    steps: [
      { width: 247.65, numberOfItems: 4.038 },
      { width: 123.825, numberOfItems: 8.076 },
      { width: 82.55, numberOfItems: 12.114 },
      { width: 61.913, numberOfItems: 16.152 },
      { width: 49.53, numberOfItems: 20.19 },
      { width: 41.275, numberOfItems: 24.228 },
      { width: 35.379, numberOfItems: 28.266 },
      { width: 30.956, numberOfItems: 32.304 },
      { width: 25.517, numberOfItems: 36.342 },
      { width: 24.765, numberOfItems: 40.38 },
    ],
  },
  {
    number: 84,
    maxWidth: 266.7,
    numberOfCylinders: "10 1/2",
    steps: [
      { width: 266.7, numberOfItems: 3.75 },
      { width: 133.35, numberOfItems: 7.499 },
      { width: 88.9, numberOfItems: 11.249 },
      { width: 66.675, numberOfItems: 14.998 },
      { width: 53.34, numberOfItems: 18.748 },
      { width: 44.45, numberOfItems: 22.497 },
      { width: 38.1, numberOfItems: 26.247 },
      { width: 33.338, numberOfItems: 29.996 },
      { width: 29.633, numberOfItems: 33.746 },
      { width: 26.67, numberOfItems: 37.495 },
    ],
  },
  {
    number: 86,
    maxWidth: 273.05,
    numberOfCylinders: "10 3/4",
    steps: [
      { width: 273.05, numberOfItems: 3.662 },
      { width: 136.525, numberOfItems: 7.325 },
      { width: 91.017, numberOfItems: 10.987 },
      { width: 68.263, numberOfItems: 14.649 },
      { width: 54.61, numberOfItems: 18.312 },
      { width: 45.508, numberOfItems: 21.974 },
      { width: 39.007, numberOfItems: 25.636 },
      { width: 34.131, numberOfItems: 29.299 },
      { width: 30.339, numberOfItems: 32.961 },
      { width: 27.305, numberOfItems: 36.623 },
    ],
  },
  {
    number: 87,
    maxWidth: 276.23,
    numberOfCylinders: "10 7/8",
    steps: [
      { width: 276.23, numberOfItems: 3.62 },
      { width: 138.115, numberOfItems: 7.24 },
      { width: 92.077, numberOfItems: 10.861 },
      { width: 69.058, numberOfItems: 14.481 },
      { width: 55.246, numberOfItems: 18.101 },
      { width: 46.038, numberOfItems: 21.721 },
      { width: 39.461, numberOfItems: 25.341 },
      { width: 34.529, numberOfItems: 28.961 },
      { width: 30.692, numberOfItems: 32.582 },
      { width: 27.623, numberOfItems: 36.202 },
    ],
  },
  {
    number: 91,
    maxWidth: 288.93,
    numberOfCylinders: "11 3/8",
    steps: [
      { width: 288.93, numberOfItems: 3.461 },
      { width: 144.465, numberOfItems: 6.922 },
      { width: 96.31, numberOfItems: 10.383 },
      { width: 72.233, numberOfItems: 13.844 },
      { width: 57.786, numberOfItems: 17.305 },
      { width: 48.155, numberOfItems: 20.766 },
      { width: 41.276, numberOfItems: 24.227 },
      { width: 36.116, numberOfItems: 27.689 },
      { width: 32.103, numberOfItems: 31.149 },
      { width: 28.893, numberOfItems: 34.61 },
    ],
  },
  {
    number: 97,
    maxWidth: 307.98,
    numberOfCylinders: "12 1/8",
    steps: [
      { width: 307.98, numberOfItems: 3.247 },
      { width: 153.99, numberOfItems: 6.494 },
      { width: 102.66, numberOfItems: 9.741 },
      { width: 76.995, numberOfItems: 12.988 },
      { width: 61.596, numberOfItems: 16.235 },
      { width: 51.33, numberOfItems: 19.482 },
      { width: 43.997, numberOfItems: 22.729 },
      { width: 38.498, numberOfItems: 25.976 },
      { width: 34.22, numberOfItems: 29.223 },
      { width: 30.798, numberOfItems: 32.47 },
    ],
  },
  {
    number: 99,
    maxWidth: 314.33,
    numberOfCylinders: "12 3/8",
    steps: [
      { width: 314.33, numberOfItems: 3.181 },
      { width: 157.163, numberOfItems: 6.363 },
      { width: 104.775, numberOfItems: 90.544 },
      { width: 78.581, numberOfItems: 12.726 },
      { width: 62.865, numberOfItems: 15.907 },
      { width: 52.388, numberOfItems: 19.089 },
      { width: 44.904, numberOfItems: 22.27 },
      { width: 39.291, numberOfItems: 25.451 },
      { width: 34.925, numberOfItems: 28.633 },
      { width: 31.433, numberOfItems: 31.814 },
    ],
  },
  {
    number: 109,
    maxWidth: 346.075,
    numberOfCylinders: "13 5/8",
    steps: [
      { width: 346.075, numberOfItems: 2.89 },
      { width: 173.038, numberOfItems: 5.779 },
      { width: 115.358, numberOfItems: 8.669 },
      { width: 86.519, numberOfItems: 11.558 },
      { width: 69.215, numberOfItems: 14.448 },
      { width: 57.679, numberOfItems: 17.337 },
      { width: 49.439, numberOfItems: 20.227 },
      { width: 49.439, numberOfItems: 20.227 },
      { width: 43.259, numberOfItems: 23.116 },
      { width: 38.453, numberOfItems: 26.006 },
      { width: 34.608, numberOfItems: 28.895 },
    ],
  },
  {
    number: 118,
    maxWidth: 374.65,
    numberOfCylinders: "14 3/4",
    steps: [
      { width: 374.65, numberOfItems: 2.669 },
      { width: 187.325, numberOfItems: 5.338 },
      { width: 124.883, numberOfItems: 8.007 },
      { width: 93.663, numberOfItems: 10.677 },
      { width: 74.93, numberOfItems: 13.346 },
      { width: 62.442, numberOfItems: 16.015 },
      { width: 53.521, numberOfItems: 18.684 },
      { width: 46.831, numberOfItems: 21.353 },
      { width: 41.628, numberOfItems: 24.022 },
      { width: 37.465, numberOfItems: 26.692 },
    ],
  },
  {
    number: 156,
    maxWidth: 495.3,
    numberOfCylinders: "19 1/2",
    steps: [
      { width: 495.3, numberOfItems: 2.019, numberOfElements: 1 },
      { width: 247.65, numberOfItems: 4.038, numberOfElements: 2 },
      { width: 165.1, numberOfItems: 6.057, numberOfElements: 3 },
      { width: 123.825, numberOfItems: 8.076, numberOfElements: 4 },
      { width: 99.06, numberOfItems: 10.095, numberOfElements: 5 },
      { width: 82.55, numberOfItems: 12.114, numberOfElements: 6 },
      { width: 70.757, numberOfItems: 14.133, numberOfElements: 7 },
      { width: 61.913, numberOfItems: 16.152, numberOfElements: 8 },
      { width: 55.033, numberOfItems: 18.171, numberOfElements: 9 },
      { width: 49.53, numberOfItems: 20.19, numberOfElements: 10 },
    ],
  },
];

module.exports = { printingStepsOne, printingStepsTwo };
