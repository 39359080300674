import React, { useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { LoadingOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { UserContext } from "../../App";
import useAxios from "../../hooks/useAxios";
import { SERVER_URL } from "../../config";
import OfferViewForm from "../../components/forms/OfferViewForm";

const ViewOffer = (props) => {
  const currentuser = useContext(UserContext);
  const [data, fetchData] = useAxios("", {}, currentuser.data.token, "get");
  const { id } = props.match.params;

  useEffect(() => {
    if (id) fetchData(`${SERVER_URL}/offer/${id}`, {});
  }, [fetchData, id]);

  const isDataFetched = !data.isLoading && data.data && currentuser.language;

  return (
    <div className="edit-panel">
      <div className="actions-block">
        <Link to="/admin/offers">
          <Button type="primary">Sve ponude</Button>
        </Link>
      </div>

      <div>
        {!isDataFetched && (
          <div style={{ textAlign: "center" }}>
            <LoadingOutlined
              spin
              style={{ fontSize: "3rem", marginTop: "5rem" }}
            />
          </div>
        )}

        {!id && isDataFetched && (
          <OfferViewForm
            isNew={true}
            language={currentuser.language}
            SERVER_URL={SERVER_URL}
            token={currentuser.data.token}
          />
        )}

        {id && !data.isError && data.data && isDataFetched && (
          <OfferViewForm
            isNew={false}
            data={data.data}
            language={currentuser.language}
            SERVER_URL={SERVER_URL}
            token={currentuser.data.token}
          />
        )}
      </div>
    </div>
  );
};

export default ViewOffer;
