import React, { useEffect, useContext, useRef, useState } from "react";
import Axios from "axios";
import useAxios from "../../hooks/useAxios";
import { SERVER_URL } from "../../config";
import { UserContext } from "../../App";
import dayjs from "dayjs";
import {
  Form,
  notification,
  Button,
  Select,
  Input,
  InputNumber,
  Switch,
} from "antd";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";

const { Option } = Select;
const { TextArea } = Input;

const CalculationSheetForm = ({ data, onSubmit }) => {
  const SERVER_URL_PRODUCTION =
    process.env.NODE_ENV === "production"
      ? "https://birografika-api.concordsoft.solutions"
      : "http://localhost:3334";
  const ref = useRef(null);
  const [form] = Form.useForm();
  const currentuser = useContext(UserContext);
  const token = currentuser.data.token;
  const [printType, setPrintType] = useState("tabak"); ///obavezno ostaviti!
  const [machines, setMachines] = useState();
  const [isDigitalPrint, setIsDigitalPrint] = useState();
  const [selectedMachine, setSelectedMachine] = useState();
  const [selectedTypeOfLabel, setSelectedTypeOfLabel] = useState();
  const [typesOfLabel, setTypesOfLabel] = useState();
  const [allClients, fetchAllClients] = useAxios("", null, token, "get");
  const [allBoxes, fetchAllBoxes] = useAxios("", null, token, "get");
  const [folioTypes, fetchFolioTypes] = useAxios("", null, token, "get");
  const [typesOfTransport, fetchTypesOfTransport] = useAxios(
    "",
    null,
    token,
    "get",
  );

  const [typesOfVarnishing, fetchTypesOfVarnishing] = useAxios(
    "",
    null,
    token,
    "get",
  );

  const [machinesForPlasticization, fetchMachinesForPlasticization] = useAxios(
    "",
    null,
    token,
    "get",
  );

  let initialValues;
  if (data) {
    initialValues = { ...data };
    initialValues.machine = initialValues.machine._id;
    initialValues.typeOfLabel = initialValues.typeOfLabel._id;
    initialValues.date = dayjs(data.date).format("DD.MM.YYYY");

    if (initialValues.boxesPrice && initialValues.boxesQuantity) {
      ///posto boxesTotalPrice se ne cuva u bazi potrebno je izracunati ga da bi se odma prikazao cim ocitamo stranicu
      initialValues.boxesTotalPrice = (
        initialValues.boxesPrice * initialValues.boxesQuantity
      ).toFixed(2);
    }
  }

  ////////////////////////HIDE/SHOW USE STATES//////////////////////////////////////////
  const [hideVarnishing, setHideVarnishing] = useState(true);
  const [hideSubcontractors, setHideSubcontractors] = useState(true);
  const [hidePlasticization, setHidePlasticization] = useState(true);
  const [hideDesign, setHideDesign] = useState(true);
  const [hideTransport, setHideTransport] = useState(true);
  const [hideTransportInput, setHideTransportInput] = useState(true);
  const [hidePrintingForm, setHidePrintingForm] = useState(true);
  const [hideShrinkSleeve, setHideShrinkSleeve] = useState(true);
  const [hideShrinkSleeveSwitch, setHideShrinkSleeveSwitch] = useState(true);
  const [hidePacking, setHidePacking] = useState(true);
  const [hideBoxes, setHideBoxes] = useState(true);
  const [hideVacuumSealing, setHideVacuumSealing] = useState(true);
  const [hideFolioTypeWarmFT, setHideFolioTypeWarmFT] = useState(true);
  //////////////////////////HANDLE CHANGE OF SWITCH FIELDS//////////////////////////////////
  const onChangeVarnishing = () => {
    setHideVarnishing((current) => !current);
  };
  const onChangeSubcontractors = () => {
    setHideSubcontractors((current) => !current);
  };
  const onChangeRollPacking = () => {
    setHidePacking((current) => !current);
  };
  const onChangePlasticization = () => {
    setHidePlasticization((current) => !current);
  };
  const onChangeDesign = () => {
    setHideDesign((current) => !current);
  };
  const onChangeTransport = () => {
    setHideTransport((current) => !current);
  };
  const onChangePrintingForm = () => {
    setHidePrintingForm((current) => !current);
  };
  const onChangeShrinkSleeve = () => {
    setHideShrinkSleeve((current) => !current);
  };
  /////////////////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    fetchMachines(printType);
  }, [printType]);

  useEffect(() => {
    fetchAllClients(`${SERVER_URL}/clients`, []);
    fetchAllBoxes(`${SERVER_URL_PRODUCTION}/boxes`, []);
    fetchTypesOfVarnishing(`${SERVER_URL}/types-of-varnishing`, []);
    fetchTypesOfTransport(`${SERVER_URL}/types-of-transport`, []);
  }, []);

  useEffect(() => {
    form.resetFields();

    if (data) {
      fetchMachines(data.machine.printType);

      setTypesOfLabel(data.machine.typesOfLabel);
    }

    ////prikazivanje polja pri ocitavanju stranice ukoliko su neka od po defaultu skrivenih polja ipak popunjena i treba ih prikazati
    if (initialValues) {
      if (initialValues.varnishing) {
        setHideVarnishing(!initialValues.varnishing);
      }
      if (initialValues.subcontractors) {
        setHideSubcontractors(!initialValues.subcontractors);
      }
      if (initialValues.boxes) {
        setHideBoxes(!initialValues.boxes);
      }
      if (initialValues.folioPrinting) {
        setHidePlasticization(!initialValues.folioPrinting);
      }
      if (initialValues.design) {
        setHideDesign(!initialValues.design);
      }
      if (initialValues.transport) {
        setHideTransport(!initialValues.transport);
      }
      if (initialValues.printingForms) {
        setHidePrintingForm(!initialValues.printingForms);
      }
      if (initialValues.shrinkSleeve) {
        setHideShrinkSleeve(!initialValues.shrinkSleeve);
      }

      if (initialValues.rollPacking) {
        setHidePacking(!initialValues.rollPacking);
        if (initialValues.packingType === "kutije") {
          setHideBoxes(false);
        } else {
          setHideBoxes(true);
        }
      }
      ///////////////////////////////////////

      fetchSelectedTypeOfLabel();
      fetchSelectedMachine();
    }
  }, [data]);

  const fetchMachines = async (printType) => {
    const res = await Axios.get(
      `${SERVER_URL}/machinesbyPrintType/${printType}`,
      {
        withCredentials: false,
        headers: { Authorization: `Bearer ${token}` },
      },
    );
    setMachines(res.data);
  };

  const fetchSelectedMachine = async () => {
    if (data) {
      const res = await Axios.get(`${SERVER_URL}/machine/${data.machine._id}`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${token}` },
      });

      setSelectedMachine(res.data);
    }
  };

  const fetchSelectedTypeOfLabel = async () => {
    const res = await Axios.get(
      `${SERVER_URL}/type-of-label/${data.typeOfLabel._id}`,
      {
        withCredentials: false,
        headers: { Authorization: `Bearer ${token}` },
      },
    );

    setSelectedTypeOfLabel(res.data);
  };

  const onChangeMachineHandler = async (id) => {
    machines.forEach((element) => {
      if (element._id === id) {
        setIsDigitalPrint(element.isDigitalPrint);

        ///set tipova etiketa za svaku izabranu masinu
        setTypesOfLabel(element.typesOfLabel);
      }
    });

    form.setFieldsValue({ typeOfLabel: null });

    const res = await Axios.get(`${SERVER_URL}/machine/${id}`, {
      withCredentials: false,
      headers: { Authorization: `Bearer ${token}` },
    });

    setSelectedMachine(res.data);
  };

  const onChangeTypeOfLabelHandler = async (id) => {
    const res = await Axios.get(`${SERVER_URL}/type-of-label/${id}`, {
      withCredentials: false,
      headers: { Authorization: `Bearer ${token}` },
    });

    if (res.data) {
      setSelectedTypeOfLabel(res.data);
    }

    ///materialPriceHandler
    if (res.data.materialPrice) {
      form.setFieldsValue({
        materialPrice: res.data.materialPrice,
      });
    } else {
      form.setFieldsValue({
        materialPrice: 0,
      });
    }
  };

  const preparationOfPrintingPressesHandler = () => {
    let preparationOfPrintingPressesHours = form.getFieldValue(
      "preparationOfPrintingPressesHours",
    );
    let preparationOfPrintingPressesPrice = form.getFieldValue(
      "preparationOfPrintingPressesPrice",
    );

    const preparationOfPrintingPresses =
      preparationOfPrintingPressesHours * preparationOfPrintingPressesPrice;

    if (isNaN(preparationOfPrintingPresses)) {
      form.setFieldsValue({ preparationOfPrintingPresses: 0 });
    } else {
      form.setFieldsValue({ preparationOfPrintingPresses });
    }
  };

  const totalTimeOfPrintingHandler = () => {
    let sheetNum = parseFloat(form.getFieldValue("sheetNum"));
    let totalTimeOfPrinting =
      sheetNum / parseFloat(form.getFieldValue("printingSpeed"));

    if (totalTimeOfPrinting < 0.25) {
      totalTimeOfPrinting = 0.25;
    }
    if (form.getFieldValue("printPages") === "dvostrana") {
      totalTimeOfPrinting *= 2;
    }

    if (isNaN(totalTimeOfPrinting)) {
      form.setFieldsValue({ totalTimeOfPrinting: 0 });
    } else {
      form.setFieldsValue({ totalTimeOfPrinting });
    }
  };

  const totalElectricityHandler = () => {
    form.setFieldsValue({
      totalElectricity: (form.getFieldValue("circulation") * 0.00025).toFixed(
        2,
      ),
    });
  };

  // const sheetNumHandler = () => {
  //   let sheetNum;
  //   const width = form.getFieldValue("width");
  //   const height = form.getFieldValue("height");

  //   if (isNaN(sheetNum)) {
  //     form.setFieldsValue({ sheetNum: 0 });
  //   } else {
  //     form.setFieldsValue({ sheetNum });
  //   }
  // };

  const sheetWeightHandler = () => {
    ///Mora se prvi izracunati sheetNum da bi moglo ovo
    let sheetHeightRoland = form.getFieldValue("heightSheet")
      ? form.getFieldValue("heightSheet")
      : 700;
    let sheetWidthRoland = form.getFieldValue("widthSheet")
      ? form.getFieldValue("widthSheet")
      : 500;

    // let materialAmount =
    //   ((sheetHeightRoland * sheetWidthRoland) / 1000000) *
    //   (sheetNum + calculatedWastePaper);
  };

  const totalPriceHandler = () => {
    console.log("racunanje ukupne cene placeholder");
  };

  const onFinish = (values, isNew) => {
    values.calculationType = "tabak";
    onSubmit(values, isNew);
  };

  return (
    <div style={{ textAlign: "start", alignContent: "start" }}>
      <Form
        name="basic"
        form={form}
        initialValues={initialValues}
        onFinish={(values) => onFinish(values, !data)}
      >
        <div>
          <h1
            style={{
              backgroundColor: "#2574ab",
              padding: "10px",
              color: "white",
            }}
          >
            {!data ? "NOVA KALKULACIJA - TABAK" : "EDITUJ KALKULACIJU - TABAK"}
          </h1>
          <div style={{ display: "flex" }}>
            <div>
              <Form.Item
                className="firstChildHeaderRowANTD"
                label="Mašina:"
                name="machine"
              >
                <Select
                  onChange={(id) => {
                    onChangeMachineHandler(id);
                  }}
                >
                  {machines &&
                    machines.length > 0 &&
                    machines.map((item, index) => (
                      <Option key={index} value={item._id}>
                        {item.name}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
            </div>
            <div>
              <Form.Item
                className="secondChildHeaderRowANTD"
                label="Vrsta materijala:"
                name="typeOfLabel"
              >
                <Select
                  showSearch
                  onChange={(e) => {
                    onChangeTypeOfLabelHandler(e);
                  }}
                >
                  <Option value={null}> - </Option>
                  {typesOfLabel &&
                    typesOfLabel.length > 0 &&
                    typesOfLabel.map((item, index) => (
                      <Option key={index} value={item._id}>
                        {item.name}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
            </div>
            <div>
              <Form.Item
                className="thirdChildHeaderRowANTD"
                label="Datum:"
                name="date"
              >
                <Input disabled></Input>
              </Form.Item>
            </div>
          </div>
          <div>
            <div
              style={{ display: "flex", flexDirection: "row", width: "100%" }}
            >
              <Form.Item
                style={{ width: "150px" }}
                label="Lakiranje"
                name="varnishing"
              >
                <Switch
                  defaultChecked={initialValues?.varnishing}
                  onChange={onChangeVarnishing}
                  checkedChildren={<CheckOutlined />}
                  unCheckedChildren={<CloseOutlined />}
                ></Switch>
              </Form.Item>
              <Form.Item
                style={{ width: "150px" }}
                label="Kooperanti"
                name="subcontractors"
              >
                <Switch
                  defaultChecked={initialValues?.subcontractors}
                  onChange={onChangeSubcontractors}
                  checkedChildren={<CheckOutlined />}
                  unCheckedChildren={<CloseOutlined />}
                ></Switch>
              </Form.Item>
              <Form.Item
                style={{ width: "215px" }}
                label="Pakovanje rolne"
                name="rollPacking"
              >
                <Switch
                  defaultChecked={initialValues?.rollPacking}
                  onChange={onChangeRollPacking}
                  checkedChildren={<CheckOutlined />}
                  unCheckedChildren={<CloseOutlined />}
                />
              </Form.Item>
              <Form.Item
                style={{ width: "175px" }}
                label="TIGL"
                name="crucible"
              >
                <Switch
                  checkedChildren={<CheckOutlined />}
                  unCheckedChildren={<CloseOutlined />}
                />
              </Form.Item>
            </div>
            <div
              style={{ display: "flex", flexDirection: "row", width: "100%" }}
            >
              <Form.Item
                style={{ width: "150px" }}
                label="Plastifikacija"
                name="folioPrinting"
              >
                <Switch
                  defaultChecked={initialValues?.plastication}
                  onChange={onChangePlasticization}
                  checkedChildren={<CheckOutlined />}
                  unCheckedChildren={<CloseOutlined />}
                ></Switch>
              </Form.Item>
              <Form.Item
                style={{ width: "175px" }}
                label="Dizajn"
                name="design"
              >
                <Switch
                  defaultChecked={initialValues?.design}
                  onChange={onChangeDesign}
                  checkedChildren={<CheckOutlined />}
                  unCheckedChildren={<CloseOutlined />}
                ></Switch>
              </Form.Item>
              <Form.Item
                style={{ width: "150px" }}
                label="Transport"
                name="transport"
              >
                <Switch
                  defaultChecked={initialValues?.transport}
                  onChange={onChangeTransport}
                  checkedChildren={<CheckOutlined />}
                  unCheckedChildren={<CloseOutlined />}
                ></Switch>
              </Form.Item>
              <Form.Item
                style={{ width: "200px" }}
                label="Štamparske forme:"
                name="printingForms"
              >
                <Switch
                  defaultChecked={initialValues?.printingForms}
                  onChange={onChangePrintingForm}
                  checkedChildren={<CheckOutlined />}
                  unCheckedChildren={<CloseOutlined />}
                ></Switch>
              </Form.Item>
              <Form.Item
                style={{ width: "200px" }}
                label="Savijanje:"
                name="bending"
              >
                <Switch
                  checkedChildren={<CheckOutlined />}
                  unCheckedChildren={<CloseOutlined />}
                ></Switch>
              </Form.Item>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              marginTop: "10px",
            }}
          >
            <Form.Item
              label="Naziv posla"
              className="firstChildInRowFirstSectionANTD"
              name="businessName"
            >
              <TextArea rows={1} />
            </Form.Item>
            <Form.Item
              className="firstChildInRowFirstSectionANTD"
              label="Poručilac"
              name="client"
            >
              <Select showSearch>
                {allClients?.data?.items &&
                  allClients.data.items.length > 0 &&
                  allClients.data.items.map((item, index) => (
                    <Option key={index} value={item._id}>
                      {item.companyName}
                    </Option>
                  ))}
              </Select>
            </Form.Item>
          </div>
          <div style={{ display: "flex", flexDirection: "row", width: "100%" }}>
            <Form.Item
              className="firstChildInRowFirstSectionANTD"
              label="Vrsta materijala"
              name="typeOfMaterial"
            >
              <Select />
            </Form.Item>
          </div>
          <div style={{ display: "flex", flexDirection: "row", width: "100%" }}>
            <Form.Item
              className="firstChildInRowFirstSectionANTD"
              label="Kurs"
              name="exchangeRate"
            >
              <InputNumber min="0" />
            </Form.Item>
            {selectedTypeOfLabel?.existsPricePerKg && (
              <Form.Item
                className="firstChildInRowFirstSectionANTD"
                label="Cena po kg (€/kg)"
                name="pricePerKg"
              >
                <InputNumber min="0" />
              </Form.Item>
            )}
          </div>
          <div style={{ display: "flex", flexDirection: "row", width: "100%" }}>
            {selectedTypeOfLabel?.name !== "SAMOLEPLJIVI" && (
              <Form.Item
                className="firstChildInRowFirstSectionANTD"
                label="Tip štampe"
                name="printPages"
              >
                <Select>
                  <Option value="jednostrana">Jednostrana</Option>
                  <Option value="dvostrana">Dvostrana</Option>
                </Select>
              </Form.Item>
            )}
            {selectedMachine?.existsColorPrint && (
              <Form.Item
                className="firstChildInRowFirstSectionANTD"
                label="Boja štampe"
                name="colorPrint"
              >
                <Select>
                  <Option value="u boji">U boji</Option>
                  <Option value="crno-bela">Crno-bela</Option>
                </Select>
              </Form.Item>
            )}
          </div>
          <div style={{ display: "flex", flexDirection: "row", width: "100%" }}>
            <Form.Item
              label="Visina (mm)"
              className="firstChildInRowFirstSectionANTD"
              name="height"
            >
              <InputNumber min="0" />
            </Form.Item>
            <Form.Item
              label="Širina (mm)"
              className="firstChildInRowFirstSectionANTD"
              name="width"
            >
              <InputNumber min="0" />
            </Form.Item>
          </div>
          {selectedMachine?.existsWidthAndHeightSheet && (
            <div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                }}
              >
                {selectedMachine?.existsWidthAndHeightSheet && (
                  <Form.Item
                    className="firstChildInRowFirstSectionANTD"
                    label="Visina tabaka (mm)"
                    name="heightSheet"
                  >
                    <InputNumber min="0" />
                  </Form.Item>
                )}

                {selectedMachine?.existsWidthAndHeightSheet && (
                  <Form.Item
                    className="firstChildInRowFirstSectionANTD"
                    label="Širina tabaka (mm)"
                    name="widthSheet"
                  >
                    <InputNumber min="0" />
                  </Form.Item>
                )}
              </div>
            </div>
          )}
          <div style={{ display: "flex", flexDirection: "row", width: "100%" }}>
            <Form.Item
              label="Cena materijala"
              className="firstChildInRowFirstSectionANTD"
              name="materialPrice"
            >
              <InputNumber min="0" />
            </Form.Item>
            <Form.Item
              className="firstChildInRowFirstSectionANTD"
              label="Broj etiketa/tabak"
              name="labelNum"
            >
              <Input disabled />
            </Form.Item>
          </div>
          <div style={{ display: "flex", flexDirection: "row", width: "100%" }}>
            <Form.Item
              className="firstChildInRowFirstSectionANTD"
              label="Tiraž (kom)"
              name="circulation"
            >
              <InputNumber min="0" />
            </Form.Item>
          </div>
          <div style={{ backgroundColor: "#d7fca2" }}>
            <div
              style={{
                display: "flex",
                width: "100%",
              }}
            >
              <Form.Item
                hidden={hideVarnishing}
                className="firstChildInRowFirstSectionANTD"
                label="Lakiranje"
                name="varnishCoverage"
              >
                <Select>
                  <Option value="potpuno">Potpuno</Option>
                  <Option value="parcijalno">Parcijalno</Option>
                </Select>
              </Form.Item>

              <Form.Item
                hidden={hideVarnishing}
                className="firstChildInRowFirstSectionANTD"
                label="Stepen utroška laka"
                name="degreeOfVarnishConsumption"
              >
                <InputNumber min="0" />
              </Form.Item>
            </div>
            <div
              style={{
                display: "flex",
                width: "100%",
              }}
            >
              <Form.Item
                hidden={hideVarnishing}
                className="firstChildInRowFirstSectionANTD"
                label="Količina laka"
                name="varnishQuantity"
              >
                <Input disabled />
              </Form.Item>
              <Form.Item
                hidden={hideVarnishing}
                className="firstChildInRowFirstSectionANTD"
                label="Cena laka"
                name="varnishPrice"
              >
                <InputNumber min="0" />
              </Form.Item>
            </div>
            <div
              style={{
                display: "flex",
                width: "100%",
              }}
            >
              <Form.Item
                hidden={hideVarnishing}
                className="firstChildInRowFirstSectionANTD"
                label="Tip lakiranja"
                name="varnishType"
              >
                <Select>
                  {typesOfVarnishing &&
                    typesOfVarnishing.data &&
                    typesOfVarnishing.data.items &&
                    typesOfVarnishing.data.items.length > 0 &&
                    typesOfVarnishing.data.items.map((item, index) => (
                      <Option value={item._id}>{item.name}</Option>
                    ))}
                </Select>
              </Form.Item>
              <Form.Item
                hidden={hideVarnishing}
                className="firstChildInRowFirstSectionANTD"
                label="Vrednost laka €"
                name="varnishTotalPrice"
              >
                <Input disabled></Input>
              </Form.Item>
            </div>
            <div
              style={{ display: "flex", flexDirection: "row", width: "100%" }}
            >
              <Form.Item
                hidden={hidePlasticization}
                className="firstChildInRowFirstSectionANTD"
                label="Način plastifikacije"
                name="folioWay"
              >
                <Select>
                  <Option value="hladniFT">Hladni FT</Option>
                  <Option value="topliFT">Topli FT</Option>
                </Select>
              </Form.Item>
              <Form.Item
                hidden={hidePlasticization}
                className="firstChildInRowFirstSectionANTD"
                label="Izrada na mašini"
                name="folioPrintingMachine"
              >
                <Select>
                  {machinesForPlasticization &&
                    machinesForPlasticization.length > 0 &&
                    machinesForPlasticization.map((item, index) => (
                      <Option key={index} value={item._id}>
                        {item.name}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
            </div>
            <div
              style={{ display: "flex", flexDirection: "row", width: "100%" }}
            >
              <Form.Item
                hidden={hidePlasticization}
                className="firstChildInRowFirstSectionANTD"
                label="Širina rolne"
                name="rollWidth"
              >
                <InputNumber min="0" />
              </Form.Item>
              <Form.Item
                hidden={hidePlasticization}
                className="firstChildInRowFirstSectionANTD"
                label="Vrsta folije"
                name="folioType"
              >
                <Select>
                  {folioTypes &&
                    folioTypes.length > 0 &&
                    folioTypes.map((item, index) => (
                      <Option value={item._id}>{item.name}</Option>
                    ))}
                </Select>
              </Form.Item>
            </div>

            <div
              style={{ display: "flex", flexDirection: "row", width: "100%" }}
            >
              <Form.Item
                hidden={hidePlasticization}
                className="firstChildInRowFirstSectionANTD"
                label="Cena folije (€/m2)"
                name="folioPrice"
              >
                <Input disabled></Input>
              </Form.Item>
              <Form.Item
                hidden={hidePlasticization}
                className="firstChildInRowFirstSectionANTD"
                label="Ukupno folije €"
                name="folioTotal"
              >
                <Input disabled></Input>
              </Form.Item>
            </div>

            <div
              style={{ display: "flex", flexDirection: "row", width: "100%" }}
            >
              <Form.Item
                hidden={hideSubcontractors}
                className="firstChildInRowFirstSectionANTD"
                label="Kooperant €"
                name="subcontractorInput"
              >
                <InputNumber min="0" />
              </Form.Item>
            </div>
            <div
              style={{
                display: "flex",
                width: "100%",
              }}
            >
              <Form.Item
                hidden={hideTransport}
                className="firstChildInRowFirstSectionANTD"
                label="Tip transporta"
                name="transportType"
              >
                <Select>
                  {typesOfTransport &&
                    typesOfTransport.data &&
                    typesOfTransport.data.items &&
                    typesOfTransport.data.items.length > 0 &&
                    typesOfTransport.data.items.map((item, index) => (
                      <Option value={item._id}>{item.name}</Option>
                    ))}
                </Select>
              </Form.Item>
              <Form.Item
                hidden={hideTransport}
                className="firstChildInRowFirstSectionANTD"
                label="Transport €"
                name="transportInput"
              >
                <InputNumber min="0" />
              </Form.Item>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
              }}
            >
              <Form.Item
                hidden={hideDesign}
                className="firstChildInRowFirstSectionANTD"
                label="Dizajn €"
                name="designInput"
              >
                <InputNumber min="0" />
              </Form.Item>
            </div>
            <div
              style={{
                display: "flex",
                width: "100%",
              }}
            >
              <Form.Item
                hidden={hidePacking}
                className="firstChildInRowFirstSectionANTD"
                label="Tip pakovanja"
                name="packingType"
              >
                <Select>
                  <Option value="vakumiranje">Vakumiranje</Option>
                  <Option value="foliranje">Foliranje</Option>
                  <Option value="kutije">U kutije</Option>
                </Select>
              </Form.Item>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
              }}
            >
              <Form.Item
                hidden={hidePacking}
                className="firstChildInRowFirstSectionANTD"
                label="Količina kutija"
                name="boxesQuantity"
              >
                <InputNumber min="0" />
              </Form.Item>
              <Form.Item
                hidden={hidePacking}
                className="firstChildInRowFirstSectionANTD"
                label="Cena kutije"
                name="boxesPrice"
              >
                <InputNumber min="0" />
              </Form.Item>
            </div>
            <div
              style={{ display: "flex", flexDirection: "row", width: "100%" }}
            >
              <Form.Item
                hidden={hidePacking}
                className="firstChildInRowFirstSectionANTD"
                label="Kutija"
                name="boxType"
              >
                <Select showSearch>
                  {allBoxes &&
                    allBoxes.data &&
                    allBoxes.data.items &&
                    allBoxes.data.items.length > 0 &&
                    allBoxes.data.items.map((item, index) => (
                      <Option key={index} value={item._id}>
                        {item.code} - {item.name}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
              <Form.Item
                hidden={hidePacking}
                className="firstChildInRowFirstSectionANTD"
                label="Ukupan iznos - kutije"
                name="boxesTotalPrice"
              >
                <Input disabled></Input>
              </Form.Item>
            </div>
            <div
              style={{
                display: "flex",
                width: "100%",
              }}
            >
              <Form.Item
                hidden={hidePacking}
                className="firstChildInRowFirstSectionANTD"
                label="Količina rolni za vakumiranje"
                name="vacuumSealingQuantity"
              >
                <InputNumber min="0" />
              </Form.Item>
              <Form.Item
                hidden={hidePacking}
                className="firstChildInRowFirstSectionANTD"
                label="Cena usluge vakumiranja"
                name="vacuumSealingPrice"
              >
                <InputNumber min="0" />
              </Form.Item>
            </div>
            <div
              style={{
                display: "flex",
                width: "100%",
              }}
            >
              <Form.Item
                hidden={hidePacking}
                className="firstChildInRowFirstSectionANTD"
                label="Ukupno za vakumiranje"
                name="vacuumSealingTotal"
              >
                <Input disabled></Input>
              </Form.Item>
            </div>
            <div
              style={{ display: "flex", flexDirection: "row", width: "100%" }}
            >
              <Form.Item
                hidden={hidePrintingForm}
                className="firstChildInRowFirstSectionANTD"
                label="Tip štamparskih formi"
                name="printingFormsType"
              >
                <Select>
                  <Option value="postojece">Postojece</Option>
                  <Option value="nove">Nove</Option>
                </Select>
              </Form.Item>
              <Form.Item
                hidden={hidePrintingForm}
                className="firstChildInRowFirstSectionANTD"
                label="Štamparske forme (€)"
                name="printingFormsPrice"
              >
                <Input disabled></Input>
              </Form.Item>
            </div>
          </div>

          {(selectedMachine?.existsCalculatedWaste ||
            selectedMachine?.existsMaterialWeight) && (
            <div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                }}
              >
                {selectedMachine?.existsCalculatedWaste && (
                  <Form.Item
                    className="firstChildInRowSecondSectionANTD"
                    label="Kalkulisana makulatura"
                    name="calculatedWastePaper"
                  >
                    <InputNumber min="0" />
                  </Form.Item>
                )}

                {selectedMachine?.existsMaterialWeight && (
                  <Form.Item
                    className="firstChildInRowSecondSectionANTD"
                    label="Gramatura materijala (g/m2)"
                    name="materialWeight"
                  >
                    <InputNumber min="0" />
                  </Form.Item>
                )}
              </div>
            </div>
          )}
          {selectedMachine?.existsSheetWeight && (
            <div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                }}
              >
                <Form.Item
                  className="firstChildInRowSecondSectionANTD"
                  label="Količina materijala"
                  name="materialAmount"
                >
                  <InputNumber min="0" disabled />
                </Form.Item>

                <Form.Item
                  className="firstChildInRowSecondSectionANTD"
                  label="Težina tabaka u kg"
                  name="sheetWeight"
                >
                  <InputNumber min="0" disabled />
                </Form.Item>
              </div>
            </div>
          )}

          {selectedMachine?.existsTotalElectricity && (
            <div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                }}
              >
                <Form.Item
                  className="firstChildInRowSecondSectionANTD"
                  label="Trošak el. energije (€)"
                  name="totalElectricity"
                >
                  <InputNumber disabled />
                </Form.Item>
                <Form.Item
                  className="firstChildInRowSecondSectionANTD"
                  label="Ukupna cena rada"
                  name="totalWorkTime"
                >
                  <InputNumber disabled />
                </Form.Item>
              </div>
            </div>
          )}
          {!selectedMachine?.isDigitalPrint && (
            <div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                }}
              >
                <Form.Item
                  className="firstChildInRowSecondSectionANTD"
                  label="Broj boja"
                  name="numberOfColors"
                >
                  <InputNumber min="0" />
                </Form.Item>
                <Form.Item
                  className="firstChildInRowSecondSectionANTD"
                  label="Stepen utroška boje (g/m2)"
                  name="degreeOfPaintConsumption"
                >
                  <InputNumber min="0" />
                </Form.Item>
              </div>
              <div
                style={{ display: "flex", flexDirection: "row", width: "100%" }}
              >
                <Form.Item
                  className="firstChildInRowSecondSectionANTD"
                  label="Količina boja"
                  name="colorQuantity"
                >
                  <Input disabled></Input>
                </Form.Item>
                <Form.Item
                  className="firstChildInRowSecondSectionANTD"
                  label="Vrednost boja"
                  name="priceOfColors"
                >
                  <Input disabled></Input>
                </Form.Item>
              </div>

              <div
                style={{ display: "flex", flexDirection: "row", width: "100%" }}
              >
                <Form.Item
                  className="firstChildInRowSecondSectionANTD"
                  label="Broj specijalnih boja/adheziva/prajmera"
                  name="numberOfSpecialColors"
                >
                  <InputNumber min="0" />
                </Form.Item>

                <Form.Item
                  className="firstChildInRowSecondSectionANTD"
                  label="Cena specijalnih boja"
                  name="priceOfSpecialColors"
                >
                  <InputNumber min="0"></InputNumber>
                </Form.Item>
              </div>
              <div
                style={{ display: "flex", flexDirection: "row", width: "100%" }}
              >
                <Form.Item
                  className="firstChildInRowSecondSectionANTD"
                  label="Količina specijalnih boja/adheziva/prajmera"
                  name="quantityOfSpecialColors"
                >
                  <InputNumber min="0" />
                </Form.Item>
                <Form.Item
                  className="firstChildInRowSecondSectionANTD"
                  label="Ukupna cena - spec. boja"
                  name="totalPriceOfSpecialColors"
                >
                  <Input disabled></Input>
                </Form.Item>
              </div>
            </div>
          )}
          <div style={{ display: "flex", flexDirection: "row", width: "100%" }}>
            <Form.Item
              className="firstChildInRowSecondSectionANTD"
              label="Priprema štamparske mašine (€)"
              name="preparationOfPrintingPressesPrice"
            >
              <InputNumber
                min="0"
                onChange={() => {
                  preparationOfPrintingPressesHandler();
                }}
              />
            </Form.Item>
            <Form.Item
              className="firstChildInRowSecondSectionANTD"
              label="Priprema štamparske mašine (h)"
              name="preparationOfPrintingPressesHours"
            >
              <InputNumber
                min="0"
                onChange={() => {
                  preparationOfPrintingPressesHandler();
                }}
              />
            </Form.Item>
          </div>
          <div style={{ display: "flex", flexDirection: "row", width: "100%" }}>
            <Form.Item
              className="firstChildInRowSecondSectionANTD"
              label="Priprema štamparske mašine - iznos"
              name="preparationOfPrintingPresses"
            >
              <InputNumber disabled />
            </Form.Item>
            <Form.Item
              className="firstChildInRowSecondSectionANTD"
              label="Cena štampe (€/h)"
              name="printPrice"
            >
              <InputNumber min="0" />
            </Form.Item>
          </div>
          <div style={{ display: "flex", flexDirection: "row", width: "100%" }}>
            <Form.Item
              className="firstChildInRowSecondSectionANTD"
              label="Brzina štampe (m/min)"
              name="printingSpeed"
            >
              <InputNumber min="0" />
            </Form.Item>
            <Form.Item
              label="Ukupno vreme štampe(h)"
              className="firstChildInRowSecondSectionANTD"
              name="totalTimeOfPrinting"
            >
              <Input disabled />
            </Form.Item>
          </div>
          <div style={{ display: "flex", flexDirection: "row", width: "100%" }}>
            <Form.Item
              className="firstChildInRowSecondSectionANTD"
              label="Broj tabaka (sa makulaturom)"
              name="sheetNum"
            >
              <InputNumber disabled />
            </Form.Item>
            <Form.Item
              className="firstChildInRowSecondSectionANTD"
              label="Količina tabaka u m2"
              name="sheetAmount"
            >
              <InputNumber disabled />
            </Form.Item>
          </div>
          <div>
            <Form.Item
              className="firstChildInRowSecondSectionANTD"
              label="Ukupno €"
              name="totalPrice"
            >
              <InputNumber disabled />
            </Form.Item>
          </div>
          <div style={{ display: "flex", flexDirection: "row", width: "100%" }}>
            <Button type="primary" style={{ marginTop: "10px" }}>
              Export PDF
            </Button>
            <Button
              style={{ marginLeft: "10px", marginTop: "10px" }}
              type="primary"
              onClick={() => {
                preparationOfPrintingPressesHandler();
                totalTimeOfPrintingHandler();
                totalElectricityHandler();
                totalPriceHandler();
              }}
            >
              Izračunaj
            </Button>
            <Button
              type="primary"
              htmlType="submit"
              style={{ marginTop: "10px", marginLeft: "10px" }}
            >
              Sačuvaj
            </Button>
            {data && (
              <Button
                style={{ marginLeft: "10px", marginTop: "10px" }}
                onClick={() => {
                  onFinish(form.getFieldValue(), true);
                }}
                type="primary"
              >
                Sačuvaj kao novu
              </Button>
            )}
          </div>
        </div>
      </Form>
    </div>
  );
};

export default CalculationSheetForm;
