import React, { useContext, useEffect, useState } from "react";
import { UserContext } from "../../App";
import useAxios from "../../hooks/useAxios";
import { SERVER_URL } from "../../config";
import { Link } from "react-router-dom";
import { Button, notification } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import Table from "../../components/tables/ActivityTable";
import Axios from "axios";
import ExportExcel from "../../components/csv/ExportExcel";
import moment from "moment";

const TABLE_COLUMN_KEYS = [
  "_id",
  "__v",
  "avatar",
  "address",
  "zip",
  "date",
  "dateTime",
];

const Contacts = (props) => {
  const currentuser = useContext(UserContext);
  const [contacts, fetchContacts] = useAxios(
    "",
    [],
    currentuser.data.token,
    "get",
  );
  const [clients, fetchClients] = useAxios(
    "",
    [],
    currentuser.data.token,
    "get",
  );
  const [filteredData, setFilteredData] = useState([]);

  useEffect(() => {
    fetchContacts(`${SERVER_URL}/activities`, []);
    fetchClients(`${SERVER_URL}/clients`, []);
  }, [fetchContacts, fetchClients]);

  useEffect(() => {
    setFilteredData(contacts.data.items);
  }, [contacts]);

  let clientStatus = [];
  if (clients.data.items && clients.data.items.length > 0) {
    clients.data.items.map((client) => {
      if (!clientStatus.includes(client.status)) {
        clientStatus.push(client.status);
      }
    });
  }
  // console.log(clientStatus)
  const deleteClientHandler = async (id) => {
    try {
      await Axios.delete(`${SERVER_URL}/activities/${id}`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });
      notification.success({
        message: "Radna aktivnost je obrisana.",
        placement: "bottomRight",
      });
      setTimeout(() => {
        window.location.reload();
      }, 1200);
    } catch (err) {
      notification.error({
        message: "Problem sa brisanjem. Molimo vas pokusajte kasnije.",
        placement: "bottomRight",
      });
    }
  };

  let columnKeys;
  if (contacts.data.items && contacts.data.items.length > 0) {
    const keys = Object.keys(contacts.data.items[0]);
    columnKeys = keys.filter((k) => !TABLE_COLUMN_KEYS.includes(k));
  }

  let tableData = [];
  const dateOptions = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
  };
  if (contacts.data.items && contacts.data.items.length > 0) {
    tableData = contacts.data.items.map((item) => {
      if (Array.isArray(item.role)) item.role = item.role.join(", ");
      // item.createdAt = new Date(item.createdAt).toLocaleDateString('sr-Latn-RS', dateOptions);
      item.date = new Date(item.updatedAt).toLocaleDateString(
        "sr-Latn-RS",
        dateOptions,
      );
      item.user =
        item.userId && item.userId.firstName + " " + item.userId.lastName;
      item.client = item.clientId ? item.clientId.companyName : "";
      item.clientStatus = item.clientId ? item.clientId.status : "";
      return item;
    });
  }

  const getFilteredData = async (obj) => {
    await Axios.get(
      `${SERVER_URL}/activities-filter?dataIndex=${JSON.stringify(obj)}`,
      {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      },
    )
      .then((res) => {
        setFilteredData(res.data);
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  return (
    <div style={{ padding: "8px" }}>
      <div className="actions-block">
        <Link to="/admin/new-contact">
          <Button type="primary">Nova radna aktivnost</Button>
        </Link>
        <span style={{ margin: "10px 10px" }}>
          <ExportExcel
            header={["user", "client", "comment", "clientStatus", "dateTime"]}
            csvData={
              filteredData != null && filteredData.length >= 1
                ? filteredData
                : []
            }
            fileName={
              `Radne aktivnosti prodavca ` +
              moment(new Date()).format("DD:MM:YYYY HH:MM")
            }
            propsToRemove={[
              "_id",
              "clientId",
              "userId",
              "updatedAt",
              "__v",
              "createdAt",
            ]}
          />
        </span>
      </div>

      <div style={{ textAlign: "center", overflowX: "auto" }}>
        {contacts.isLoading && (
          <LoadingOutlined
            spin
            style={{ fontSize: "3rem", marginTop: "5rem" }}
          />
        )}
        {!contacts.isLoading &&
          contacts.data.items &&
          contacts.data.items.length > 0 && (
            <Table
              status={clientStatus}
              filterData={getFilteredData}
              data={tableData}
              deleteHandler={deleteClientHandler}
              columnKeys={columnKeys}
              title="Client"
              editPath="/admin/edit-contact/"
              viewPath="/admin/view-contact/"
            />
          )}
        {!contacts.isLoading &&
          contacts.data.items &&
          contacts.data.items.length === 0 && <h2>NEMA PODATAKA</h2>}
      </div>
    </div>
  );
};

export default Contacts;
