import React, { useEffect, useContext } from "react";
import Axios from "axios";
import { Link, useHistory } from "react-router-dom";
import { LoadingOutlined } from "@ant-design/icons";
import { notification, Button } from "antd";
import { UserContext } from "../../App";
import useAxios from "../../hooks/useAxios";
import Table from "../../components/tables/HilzneTable";
import { SERVER_URL } from "../../config";
const SERVER_URL_PRODUCTION =
  process.env.NODE_ENV === "production"
    ? "https://birografika-api.concordsoft.solutions"
    : "http://localhost:3334";

const Hilzne = () => {
  const currentuser = useContext(UserContext);
  const [data, fetchData] = useAxios("", [], currentuser.data.token, "get");
  const history = useHistory();

  useEffect(() => {
    fetchData(`${SERVER_URL_PRODUCTION}/hilzne`, []);
  }, [fetchData]);

  const deleteDataHandler = async (id) => {
    try {
      await Axios.delete(`${SERVER_URL_PRODUCTION}/hilzna/${id}`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });
      notification.success({
        message: "Hilzna je obrisana.",
        placement: "bottomRight",
      });
      history.push("/admin");
      history.push("/admin/hilzne");
    } catch (err) {
      notification.error({
        message: "Problem sa brisanjem.Pokušajte kasnije.",
        placement: "bottomRight",
      });
    }
  };

  let tableData = [];
  if (data.data && data.data.items && data.data.items.length > 0) {
    tableData = data.data.items.map((item) => {
      item.createdAt = new Date(item.createdAt).toLocaleString();
      item.updatedAt = new Date(item.updatedAt).toLocaleString();
      return item;
    });
  }

  return (
    <div className="table" style={{ padding: "8px" }}>
      <div className="actions-block">
        <Link to="/admin/new-hilzna">
          <Button type="primary">Kreiraj hilznu</Button>
        </Link>
        <Link to="/admin/settings">
          <Button style={{ marginLeft: "10px" }} type="primary">
            Podešavanja
          </Button>
        </Link>
      </div>

      <div style={{ textAlign: "center" }}>
        {data.isLoading && (
          <LoadingOutlined
            spin
            style={{ fontSize: "3rem", marginTop: "5rem" }}
          />
        )}
        {!data.isLoading &&
          data.data &&
          data.data.items &&
          data.data.items.length > 0 && (
            <Table
              data={tableData}
              deleteHandler={deleteDataHandler}
              title="Hilzne"
              editPath="/admin/edit-hilzna/"
            />
          )}
        {!data.isLoading &&
          data.data &&
          data.data.items &&
          data.data.items.length === 0 && (
            <div>
              <h2>Nema dodatih hilzni.</h2>
            </div>
          )}
      </div>
    </div>
  );
};

export default Hilzne;
