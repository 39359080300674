const stepsArray = [
  {
    br_cilindra: "14 3/4",
    obim: 374.65,
    br_zubaca: 118,
    sirina: 374.65,
    br_komada_tm: 2.669,
    br_elemenata: 1,
  },
  {
    br_cilindra: "13 5/8",
    obim: 346.08,
    br_zubaca: 109,
    sirina: 346.08,
    br_komada_tm: 2.89,
    br_elemenata: 1,
  },
  {
    br_cilindra: "18 1/4-2",
    obim: 463.55,
    br_zubaca: 146.0,
    sirina: 463.55,
    br_komada_tm: 2.157,
    br_elemenata: 1,
  },
  {
    br_cilindra: "18 1/4",
    obim: 463.55,
    br_zubaca: 146.0,
    sirina: 463.55,
    br_komada_tm: 2.157,
    br_elemenata: 1,
  },

  {
    br_cilindra: "18 1/4",
    obim: 463.55,
    br_zubaca: 146.0,
    sirina: 231.775,
    br_komada_tm: 4.315,
    br_elemenata: 2,
  },
  {
    br_cilindra: "18 1/4",
    obim: 463.55,
    br_zubaca: 146.0,
    sirina: 154.517,
    br_komada_tm: 6.472,
    br_elemenata: 3,
  },
  {
    br_cilindra: "18 1/4",
    obim: 463.55,
    br_zubaca: 146.0,
    sirina: 115.888,
    br_komada_tm: 8.629,
    br_elemenata: 4,
  },
  {
    br_cilindra: "18 1/4",
    obim: 463.55,
    br_zubaca: 146.0,
    sirina: 92.71,
    br_komada_tm: 10.786,
    br_elemenata: 5,
  },
  {
    br_cilindra: "18 1/4",
    obim: 463.55,
    br_zubaca: 146.0,
    sirina: 77.258,
    br_komada_tm: 12.944,
    br_elemenata: 6,
  },

  {
    br_cilindra: "18 1/4",
    obim: 463.55,
    br_zubaca: 146.0,
    sirina: 66.221,
    br_komada_tm: 15.101,
    br_elemenata: 7,
  },

  {
    br_cilindra: "18 1/4",
    obim: 463.55,
    br_zubaca: 146.0,
    sirina: 57.944,
    br_komada_tm: 17.258,
    br_elemenata: 8,
  },

  {
    br_cilindra: "18 1/4",
    obim: 463.55,
    br_zubaca: 146.0,
    sirina: 51.506,
    br_komada_tm: 19.415,
    br_elemenata: 9,
  },

  {
    br_cilindra: "18 1/4",
    obim: 463.55,
    br_zubaca: 146.0,
    sirina: 46.355,
    br_komada_tm: 21.573,
    br_elemenata: 10,
  },

  {
    br_cilindra: "14 7/8",
    obim: 377.83,
    br_zubaca: 119.0,
    sirina: 377.83,
    br_komada_tm: 2.647,
    br_elemenata: 1,
  },
  {
    br_cilindra: "14 7/8",
    obim: 377.83,
    br_zubaca: 119.0,
    sirina: 188.913,
    br_komada_tm: 5.293,
    br_elemenata: 2,
  },
  {
    br_cilindra: "14 7/8",
    obim: 377.83,
    br_zubaca: 119.0,
    sirina: 125.942,
    br_komada_tm: 7.94,
    br_elemenata: 3,
  },
  {
    br_cilindra: "14 7/8",
    obim: 377.83,
    br_zubaca: 119.0,
    sirina: 94.456,
    br_komada_tm: 10.587,
    br_elemenata: 4,
  },

  {
    br_cilindra: "14 7/8",
    obim: 377.83,
    br_zubaca: 119.0,
    sirina: 75.565,
    br_komada_tm: 13.234,
    br_elemenata: 5,
  },

  {
    br_cilindra: "14 7/8",
    obim: 377.83,
    br_zubaca: 119.0,
    sirina: 62.971,
    br_komada_tm: 15.88,
    br_elemenata: 6,
  },

  {
    br_cilindra: "14 7/8",
    obim: 377.83,
    br_zubaca: 119.0,
    sirina: 53.971,
    br_komada_tm: 18.527,
    br_elemenata: 7,
  },

  {
    br_cilindra: "14 7/8",
    obim: 377.83,
    br_zubaca: 119.0,
    sirina: 47.228,
    br_komada_tm: 21.174,
    br_elemenata: 8,
  },

  {
    br_cilindra: "14 7/8",
    obim: 377.83,
    br_zubaca: 119.0,
    sirina: 41.981,
    br_komada_tm: 23.821,
    br_elemenata: 9,
  },

  {
    br_cilindra: "14 7/8",
    obim: 377.83,
    br_zubaca: 119.0,
    sirina: 37.783,
    br_komada_tm: 26.467,
    br_elemenata: 10,
  },

  {
    br_cilindra: "11 1/8",
    obim: 282.58,
    br_zubaca: 89.0,
    sirina: 282.58,
    br_komada_tm: 3.539,
    br_elemenata: 1,
  },
  {
    br_cilindra: "11 1/8",
    obim: 282.58,
    br_zubaca: 89.0,
    sirina: 141.288,
    br_komada_tm: 7.078,
    br_elemenata: 2,
  },
  {
    br_cilindra: "11 1/8",
    obim: 282.58,
    br_zubaca: 89.0,
    sirina: 94.192,
    br_komada_tm: 10.617,
    br_elemenata: 3,
  },

  {
    br_cilindra: "11 1/8",
    obim: 282.58,
    br_zubaca: 89.0,
    sirina: 70.644,
    br_komada_tm: 14.156,
    br_elemenata: 4,
  },

  {
    br_cilindra: "11 1/8",
    obim: 282.58,
    br_zubaca: 89.0,
    sirina: 56.515,
    br_komada_tm: 17.694,
    br_elemenata: 5,
  },

  {
    br_cilindra: "11 1/8",
    obim: 282.58,
    br_zubaca: 89.0,
    sirina: 47.096,
    br_komada_tm: 21.233,
    br_elemenata: 6,
  },

  {
    br_cilindra: "11 1/8",
    obim: 282.58,
    br_zubaca: 89.0,
    sirina: 40.368,
    br_komada_tm: 24.772,
    br_elemenata: 7,
  },

  {
    br_cilindra: "11 1/8",
    obim: 282.58,
    br_zubaca: 89.0,
    sirina: 35.322,
    br_komada_tm: 28.311,
    br_elemenata: 8,
  },

  {
    br_cilindra: "11 1/8",
    obim: 282.58,
    br_zubaca: 89.0,
    sirina: 31.397,
    br_komada_tm: 31.85,
    br_elemenata: 9,
  },

  {
    br_cilindra: "11 1/8",
    obim: 282.58,
    br_zubaca: 89.0,
    sirina: 28.258,
    br_komada_tm: 35.389,
    br_elemenata: 10,
  },

  {
    br_cilindra: "8",
    obim: 203.2,
    br_zubaca: 64.0,
    sirina: 203.2,
    br_komada_tm: 4.921,
    br_elemenata: 1,
  },
  {
    br_cilindra: "8",
    obim: 203.2,
    br_zubaca: 64.0,
    sirina: 101.6,
    br_komada_tm: 9.843,
    br_elemenata: 2,
  },
  {
    br_cilindra: "8",
    obim: 203.2,
    br_zubaca: 64.0,
    sirina: 67.733,
    br_komada_tm: 14.764,
    br_elemenata: 3,
  },
  {
    br_cilindra: "8",
    obim: 203.2,
    br_zubaca: 64.0,
    sirina: 50.8,
    br_komada_tm: 19.685,
    br_elemenata: 4,
  },
  {
    br_cilindra: "8",
    obim: 203.2,
    br_zubaca: 64.0,
    sirina: 40.64,
    br_komada_tm: 24.606,
    br_elemenata: 5,
  },
  {
    br_cilindra: "8",
    obim: 203.2,
    br_zubaca: 64.0,
    sirina: 33.867,
    br_komada_tm: 29.528,
    br_elemenata: 6,
  },
  {
    br_cilindra: "8",
    obim: 203.2,
    br_zubaca: 64.0,
    sirina: 29.029,
    br_komada_tm: 34.449,
    br_elemenata: 7,
  },
  {
    br_cilindra: "8",
    obim: 203.2,
    br_zubaca: 64.0,
    sirina: 25.4,
    br_komada_tm: 39.37,
    br_elemenata: 8,
  },
  {
    br_cilindra: "8",
    obim: 203.2,
    br_zubaca: 64.0,
    sirina: 22.578,
    br_komada_tm: 44.291,
    br_elemenata: 9,
  },
  {
    br_cilindra: "8",
    obim: 203.2,
    br_zubaca: 64.0,
    sirina: 20.32,
    br_komada_tm: 49.213,
    br_elemenata: 10,
  },
  {
    br_cilindra: "8 3/4",
    obim: 222.25,
    br_zubaca: 70.0,
    sirina: 222.25,
    br_komada_tm: 4.499,
    br_elemenata: 1,
  },
  {
    br_cilindra: "8 3/4",
    obim: 222.25,
    br_zubaca: 70.0,
    sirina: 111.125,
    br_komada_tm: 8.999,
    br_elemenata: 2,
  },
  {
    br_cilindra: "8 3/4",
    obim: 222.25,
    br_zubaca: 70.0,
    sirina: 74.083,
    br_komada_tm: 13.498,
    br_elemenata: 3,
  },
  {
    br_cilindra: "8 3/4",
    obim: 222.25,
    br_zubaca: 70.0,
    sirina: 55.563,
    br_komada_tm: 17.998,
    br_elemenata: 4,
  },
  {
    br_cilindra: "8 3/4",
    obim: 222.25,
    br_zubaca: 70.0,
    sirina: 44.45,
    br_komada_tm: 22.497,
    br_elemenata: 5,
  },
  {
    br_cilindra: "8 3/4",
    obim: 222.25,
    br_zubaca: 70.0,
    sirina: 37.042,
    br_komada_tm: 26.997,
    br_elemenata: 6,
  },
  {
    br_cilindra: "8 3/4",
    obim: 222.25,
    br_zubaca: 70.0,
    sirina: 31.75,
    br_komada_tm: 31.496,
    br_elemenata: 7,
  },
  {
    br_cilindra: "8 3/4",
    obim: 222.25,
    br_zubaca: 70.0,
    sirina: 27.781,
    br_komada_tm: 35.996,
    br_elemenata: 8,
  },
  {
    br_cilindra: "8 3/4",
    obim: 222.25,
    br_zubaca: 70.0,
    sirina: 24.694,
    br_komada_tm: 40.495,
    br_elemenata: 9,
  },
  {
    br_cilindra: "8 3/4",
    obim: 222.25,
    br_zubaca: 70.0,
    sirina: 22.225,
    br_komada_tm: 44.994,
    br_elemenata: 10,
  },
  {
    br_cilindra: "9",
    obim: 228.6,
    br_zubaca: 72.0,
    sirina: 228.6,
    br_komada_tm: 4.374,
    br_elemenata: 1,
  },
  {
    br_cilindra: "9",
    obim: 228.6,
    br_zubaca: 72.0,
    sirina: 114.3,
    br_komada_tm: 8.749,
    br_elemenata: 2,
  },
  {
    br_cilindra: "9",
    obim: 228.6,
    br_zubaca: 72.0,
    sirina: 76.2,
    br_komada_tm: 13.123,
    br_elemenata: 3,
  },
  {
    br_cilindra: "9",
    obim: 228.6,
    br_zubaca: 72.0,
    sirina: 57.15,
    br_komada_tm: 17.498,
    br_elemenata: 4,
  },
  {
    br_cilindra: "9",
    obim: 228.6,
    br_zubaca: 72.0,
    sirina: 45.72,
    br_komada_tm: 21.872,
    br_elemenata: 5,
  },
  {
    br_cilindra: "9",
    obim: 228.6,
    br_zubaca: 72.0,
    sirina: 38.1,
    br_komada_tm: 26.247,
    br_elemenata: 6,
  },
  {
    br_cilindra: "9",
    obim: 228.6,
    br_zubaca: 72.0,
    sirina: 32.657,
    br_komada_tm: 30.621,
    br_elemenata: 7,
  },
  {
    br_cilindra: "9",
    obim: 228.6,
    br_zubaca: 72.0,
    sirina: 28.575,
    br_komada_tm: 34.996,
    br_elemenata: 8,
  },
  {
    br_cilindra: "9",
    obim: 228.6,
    br_zubaca: 72.0,
    sirina: 25.4,
    br_komada_tm: 39.37,
    br_elemenata: 9,
  },
  {
    br_cilindra: "9",
    obim: 228.6,
    br_zubaca: 72.0,
    sirina: 22.86,
    br_komada_tm: 43.745,
    br_elemenata: 10,
  },
  {
    br_cilindra: "9 1/4",
    obim: 234.95,
    br_zubaca: 74.0,
    sirina: 234.95,
    br_komada_tm: 4.256,
    br_elemenata: 1,
  },
  {
    br_cilindra: "9 1/4",
    obim: 234.95,
    br_zubaca: 74.0,
    sirina: 117.475,
    br_komada_tm: 8.512,
    br_elemenata: 2,
  },
  {
    br_cilindra: "9 1/4",
    obim: 234.95,
    br_zubaca: 74.0,
    sirina: 78.317,
    br_komada_tm: 12.769,
    br_elemenata: 3,
  },
  {
    br_cilindra: "9 1/4",
    obim: 234.95,
    br_zubaca: 74.0,
    sirina: 58.738,
    br_komada_tm: 17.025,
    br_elemenata: 4,
  },
  {
    br_cilindra: "9 1/4",
    obim: 234.95,
    br_zubaca: 74.0,
    sirina: 46.99,
    br_komada_tm: 21.281,
    br_elemenata: 5,
  },
  {
    br_cilindra: "9 1/4",
    obim: 234.95,
    br_zubaca: 74.0,
    sirina: 39.158,
    br_komada_tm: 25.537,
    br_elemenata: 6,
  },
  {
    br_cilindra: "9 1/4",
    obim: 234.95,
    br_zubaca: 74.0,
    sirina: 33.564,
    br_komada_tm: 29.794,
    br_elemenata: 7,
  },
  {
    br_cilindra: "9 1/4",
    obim: 234.95,
    br_zubaca: 74.0,
    sirina: 29.369,
    br_komada_tm: 34.05,
    br_elemenata: 8,
  },
  {
    br_cilindra: "9 1/4",
    obim: 234.95,
    br_zubaca: 74.0,
    sirina: 26.106,
    br_komada_tm: 38.306,
    br_elemenata: 9,
  },
  {
    br_cilindra: "9 1/4",
    obim: 234.95,
    br_zubaca: 74.0,
    sirina: 23.495,
    br_komada_tm: 42.562,
    br_elemenata: 10,
  },
  {
    br_cilindra: "9 3/4",
    obim: 247.65,
    br_zubaca: 78.0,
    sirina: 247.65,
    br_komada_tm: 4.038,
    br_elemenata: 1,
  },
  {
    br_cilindra: "9 3/4",
    obim: 247.65,
    br_zubaca: 78.0,
    sirina: 123.825,
    br_komada_tm: 8.076,
    br_elemenata: 2,
  },
  {
    br_cilindra: "9 3/4",
    obim: 247.65,
    br_zubaca: 78.0,
    sirina: 82.55,
    br_komada_tm: 12.114,
    br_elemenata: 3,
  },
  {
    br_cilindra: "9 3/4",
    obim: 247.65,
    br_zubaca: 78.0,
    sirina: 61.913,
    br_komada_tm: 16.152,
    br_elemenata: 4,
  },
  {
    br_cilindra: "9 3/4",
    obim: 247.65,
    br_zubaca: 78.0,
    sirina: 49.53,
    br_komada_tm: 20.19,
    br_elemenata: 5,
  },
  {
    br_cilindra: "9 3/4",
    obim: 247.65,
    br_zubaca: 78.0,
    sirina: 41.275,
    br_komada_tm: 24.228,
    br_elemenata: 6,
  },
  {
    br_cilindra: "9 3/4",
    obim: 247.65,
    br_zubaca: 78.0,
    sirina: 35.379,
    br_komada_tm: 28.266,
    br_elemenata: 7,
  },
  {
    br_cilindra: "9 3/4",
    obim: 247.65,
    br_zubaca: 78.0,
    sirina: 30.956,
    br_komada_tm: 32.304,
    br_elemenata: 8,
  },
  {
    br_cilindra: "9 3/4",
    obim: 247.65,
    br_zubaca: 78.0,
    sirina: 25.517,
    br_komada_tm: 36.342,
    br_elemenata: 9,
  },
  {
    br_cilindra: "9 3/4",
    obim: 247.65,
    br_zubaca: 78.0,
    sirina: 24.765,
    br_komada_tm: 40.38,
    br_elemenata: 10,
  },
  {
    br_cilindra: "10",
    obim: 254.0,
    br_zubaca: 80.0,
    sirina: 254.0,
    br_komada_tm: 3.937,
    br_elemenata: 1,
  },
  {
    br_cilindra: "10",
    obim: 254.0,
    br_zubaca: 80.0,
    sirina: 127.0,
    br_komada_tm: 7.874,
    br_elemenata: 2,
  },
  {
    br_cilindra: "10",
    obim: 254.0,
    br_zubaca: 80.0,
    sirina: 84.667,
    br_komada_tm: 11.811,
    br_elemenata: 3,
  },
  {
    br_cilindra: "10",
    obim: 254.0,
    br_zubaca: 80.0,
    sirina: 63.5,
    br_komada_tm: 15.748,
    br_elemenata: 4,
  },
  {
    br_cilindra: "10",
    obim: 254.0,
    br_zubaca: 80.0,
    sirina: 50.8,
    br_komada_tm: 19.685,
    br_elemenata: 5,
  },
  {
    br_cilindra: "10",
    obim: 254.0,
    br_zubaca: 80.0,
    sirina: 42.333,
    br_komada_tm: 23.622,
    br_elemenata: 6,
  },
  {
    br_cilindra: "10",
    obim: 254.0,
    br_zubaca: 80.0,
    sirina: 36.286,
    br_komada_tm: 27.559,
    br_elemenata: 7,
  },
  {
    br_cilindra: "10",
    obim: 254.0,
    br_zubaca: 80.0,
    sirina: 31.75,
    br_komada_tm: 31.496,
    br_elemenata: 8,
  },
  {
    br_cilindra: "10",
    obim: 254.0,
    br_zubaca: 80.0,
    sirina: 28.222,
    br_komada_tm: 35.433,
    br_elemenata: 9,
  },
  {
    br_cilindra: "10",
    obim: 254.0,
    br_zubaca: 80.0,
    sirina: 25.4,
    br_komada_tm: 39.37,
    br_elemenata: 10,
  },
  {
    br_cilindra: "10",
    obim: 254.0,
    br_zubaca: 80.0,
    sirina: 23.091,
    br_komada_tm: 43.307,
    br_elemenata: 11,
  },
  {
    br_cilindra: "10",
    obim: 254.0,
    br_zubaca: 80.0,
    sirina: 21.167,
    br_komada_tm: 47.244,
    br_elemenata: 12,
  },
  {
    br_cilindra: "10",
    obim: 254.0,
    br_zubaca: 80.0,
    sirina: 19.538,
    br_komada_tm: 51.181,
    br_elemenata: 13,
  },
  {
    br_cilindra: "10",
    obim: 254.0,
    br_zubaca: 80.0,
    sirina: 18.143,
    br_komada_tm: 55.118,
    br_elemenata: 14,
  },
  {
    br_cilindra: "10",
    obim: 254.0,
    br_zubaca: 80.0,
    sirina: 16.933,
    br_komada_tm: 59.055,
    br_elemenata: 15,
  },
  {
    br_cilindra: "10",
    obim: 254.0,
    br_zubaca: 80.0,
    sirina: 15.875,
    br_komada_tm: 62.992,
    br_elemenata: 16,
  },
  {
    br_cilindra: "10",
    obim: 254.0,
    br_zubaca: 80.0,
    sirina: 14.941,
    br_komada_tm: 66.929,
    br_elemenata: 17,
  },
  {
    br_cilindra: "10",
    obim: 254.0,
    br_zubaca: 80.0,
    sirina: 14.111,
    br_komada_tm: 70.866,
    br_elemenata: 18,
  },
  {
    br_cilindra: "10",
    obim: 254.0,
    br_zubaca: 80.0,
    sirina: 13.368,
    br_komada_tm: 74.803,
    br_elemenata: 19,
  },
  {
    br_cilindra: "10 1/8",
    obim: 257.18,
    br_zubaca: 81.0,
    sirina: 257.18,
    br_komada_tm: 3.888,
    br_elemenata: 1,
  },
  {
    br_cilindra: "10 1/8",
    obim: 257.18,
    br_zubaca: 81.0,
    sirina: 128.588,
    br_komada_tm: 7.777,
    br_elemenata: 2,
  },
  {
    br_cilindra: "10 1/8",
    obim: 257.18,
    br_zubaca: 81.0,
    sirina: 85.725,
    br_komada_tm: 11.665,
    br_elemenata: 3,
  },
  {
    br_cilindra: "10 1/8",
    obim: 257.18,
    br_zubaca: 81.0,
    sirina: 64.294,
    br_komada_tm: 15.553,
    br_elemenata: 4,
  },
  {
    br_cilindra: "10 1/8",
    obim: 257.18,
    br_zubaca: 81.0,
    sirina: 51.435,
    br_komada_tm: 19.442,
    br_elemenata: 5,
  },
  {
    br_cilindra: "10 1/8",
    obim: 257.18,
    br_zubaca: 81.0,
    sirina: 42.863,
    br_komada_tm: 23.33,
    br_elemenata: 6,
  },
  {
    br_cilindra: "10 1/8",
    obim: 257.18,
    br_zubaca: 81.0,
    sirina: 36.739,
    br_komada_tm: 27.218,
    br_elemenata: 7,
  },
  {
    br_cilindra: "10 1/8",
    obim: 257.18,
    br_zubaca: 81.0,
    sirina: 32.148,
    br_komada_tm: 31.107,
    br_elemenata: 8,
  },
  {
    br_cilindra: "10 1/8",
    obim: 257.18,
    br_zubaca: 81.0,
    sirina: 28.575,
    br_komada_tm: 34.995,
    br_elemenata: 9,
  },
  {
    br_cilindra: "10 1/8",
    obim: 257.18,
    br_zubaca: 81.0,
    sirina: 25.718,
    br_komada_tm: 38.883,
    br_elemenata: 10,
  },
  {
    br_cilindra: "10 1/8",
    obim: 257.18,
    br_zubaca: 81.0,
    sirina: 23.38,
    br_komada_tm: 42.772,
    br_elemenata: 11,
  },
  {
    br_cilindra: "10 1/8",
    obim: 257.18,
    br_zubaca: 81.0,
    sirina: 21.432,
    br_komada_tm: 46.66,
    br_elemenata: 12,
  },
  {
    br_cilindra: "10 1/8",
    obim: 257.18,
    br_zubaca: 81.0,
    sirina: 19.783,
    br_komada_tm: 50.548,
    br_elemenata: 13,
  },
  {
    br_cilindra: "10 1/8",
    obim: 257.18,
    br_zubaca: 81.0,
    sirina: 18.37,
    br_komada_tm: 54.437,
    br_elemenata: 14,
  },
  {
    br_cilindra: "10 1/8",
    obim: 257.18,
    br_zubaca: 81.0,
    sirina: 17.145,
    br_komada_tm: 58.325,
    br_elemenata: 15,
  },
  {
    br_cilindra: "10 1/8",
    obim: 257.18,
    br_zubaca: 81.0,
    sirina: 16.074,
    br_komada_tm: 62.213,
    br_elemenata: 16,
  },
  {
    br_cilindra: "10 1/8",
    obim: 257.18,
    br_zubaca: 81.0,
    sirina: 15.128,
    br_komada_tm: 66.102,
    br_elemenata: 17,
  },
  {
    br_cilindra: "10 1/8",
    obim: 257.18,
    br_zubaca: 81.0,
    sirina: 14.288,
    br_komada_tm: 69.99,
    br_elemenata: 18,
  },
  {
    br_cilindra: "10 1/8",
    obim: 257.18,
    br_zubaca: 81.0,
    sirina: 13.536,
    br_komada_tm: 73.878,
    br_elemenata: 19,
  },
  {
    br_cilindra: "10 1/8",
    obim: 257.18,
    br_zubaca: 81.0,
    sirina: 12.859,
    br_komada_tm: 77.767,
    br_elemenata: 20,
  },
  {
    br_cilindra: "10 1/2",
    obim: 266.7,
    br_zubaca: 84.0,
    sirina: 266.7,
    br_komada_tm: 3.75,
    br_elemenata: 1,
  },
  {
    br_cilindra: "10 1/2",
    obim: 266.7,
    br_zubaca: 84.0,
    sirina: 133.35,
    br_komada_tm: 7.499,
    br_elemenata: 2,
  },
  {
    br_cilindra: "10 1/2",
    obim: 266.7,
    br_zubaca: 84.0,
    sirina: 88.9,
    br_komada_tm: 11.249,
    br_elemenata: 3,
  },
  {
    br_cilindra: "10 1/2",
    obim: 266.7,
    br_zubaca: 84.0,
    sirina: 66.675,
    br_komada_tm: 14.998,
    br_elemenata: 4,
  },
  {
    br_cilindra: "10 1/2",
    obim: 266.7,
    br_zubaca: 84.0,
    sirina: 53.34,
    br_komada_tm: 18.748,
    br_elemenata: 5,
  },
  {
    br_cilindra: "10 1/2",
    obim: 266.7,
    br_zubaca: 84.0,
    sirina: 44.45,
    br_komada_tm: 22.497,
    br_elemenata: 6,
  },
  {
    br_cilindra: "10 1/2",
    obim: 266.7,
    br_zubaca: 84.0,
    sirina: 38.1,
    br_komada_tm: 26.247,
    br_elemenata: 7,
  },
  {
    br_cilindra: "10 1/2",
    obim: 266.7,
    br_zubaca: 84.0,
    sirina: 33.338,
    br_komada_tm: 29.296,
    br_elemenata: 8,
  },
  {
    br_cilindra: "10 1/2",
    obim: 266.7,
    br_zubaca: 84.0,
    sirina: 29.633,
    br_komada_tm: 33.746,
    br_elemenata: 9,
  },
  {
    br_cilindra: "10 1/2",
    obim: 266.7,
    br_zubaca: 84.0,
    sirina: 26.67,
    br_komada_tm: 37.495,
    br_elemenata: 10,
  },
  {
    br_cilindra: "10 7/8",
    obim: 276.23,
    br_zubaca: 87.0,
    sirina: 276.23,
    br_komada_tm: 3.62,
    br_elemenata: 1,
  },
  {
    br_cilindra: "10 7/8",
    obim: 276.23,
    br_zubaca: 87.0,
    sirina: 138.115,
    br_komada_tm: 7.24,
    br_elemenata: 2,
  },
  {
    br_cilindra: "10 7/8",
    obim: 276.23,
    br_zubaca: 87.0,
    sirina: 92.077,
    br_komada_tm: 10.861,
    br_elemenata: 3,
  },
  {
    br_cilindra: "10 7/8",
    obim: 276.23,
    br_zubaca: 87.0,
    sirina: 69.058,
    br_komada_tm: 14.481,
    br_elemenata: 4,
  },
  {
    br_cilindra: "10 7/8",
    obim: 276.23,
    br_zubaca: 87.0,
    sirina: 55.246,
    br_komada_tm: 18.101,
    br_elemenata: 5,
  },
  {
    br_cilindra: "10 7/8",
    obim: 276.23,
    br_zubaca: 87.0,
    sirina: 46.038,
    br_komada_tm: 21.721,
    br_elemenata: 6,
  },
  {
    br_cilindra: "10 7/8",
    obim: 276.23,
    br_zubaca: 87.0,
    sirina: 39.461,
    br_komada_tm: 25.341,
    br_elemenata: 7,
  },
  {
    br_cilindra: "10 7/8",
    obim: 276.23,
    br_zubaca: 87.0,
    sirina: 34.529,
    br_komada_tm: 28.961,
    br_elemenata: 8,
  },
  {
    br_cilindra: "10 7/8",
    obim: 276.23,
    br_zubaca: 87.0,
    sirina: 30.692,
    br_komada_tm: 32.582,
    br_elemenata: 9,
  },
  {
    br_cilindra: "10 7/8",
    obim: 276.23,
    br_zubaca: 87.0,
    sirina: 27.623,
    br_komada_tm: 36.202,
    br_elemenata: 10,
  },
  {
    br_cilindra: "11",
    obim: 279.4,
    br_zubaca: 88.0,
    sirina: 279.4,
    br_komada_tm: 3.579,
    br_elemenata: 1,
  },
  {
    br_cilindra: "11",
    obim: 279.4,
    br_zubaca: 88.0,
    sirina: 139.7,
    br_komada_tm: 7.158,
    br_elemenata: 2,
  },
  {
    br_cilindra: "11",
    obim: 279.4,
    br_zubaca: 88.0,
    sirina: 93.133,
    br_komada_tm: 10.737,
    br_elemenata: 3,
  },
  {
    br_cilindra: "11",
    obim: 279.4,
    br_zubaca: 88.0,
    sirina: 69.85,
    br_komada_tm: 14.316,
    br_elemenata: 4,
  },
  {
    br_cilindra: "11",
    obim: 279.4,
    br_zubaca: 88.0,
    sirina: 55.88,
    br_komada_tm: 17.895,
    br_elemenata: 5,
  },
  {
    br_cilindra: "11",
    obim: 279.4,
    br_zubaca: 88.0,
    sirina: 46.567,
    br_komada_tm: 21.475,
    br_elemenata: 6,
  },
  {
    br_cilindra: "11",
    obim: 279.4,
    br_zubaca: 88.0,
    sirina: 39.914,
    br_komada_tm: 25.054,
    br_elemenata: 7,
  },
  {
    br_cilindra: "11",
    obim: 279.4,
    br_zubaca: 88.0,
    sirina: 34.925,
    br_komada_tm: 28.633,
    br_elemenata: 8,
  },
  {
    br_cilindra: "11",
    obim: 279.4,
    br_zubaca: 88.0,
    sirina: 31.044,
    br_komada_tm: 32.212,
    br_elemenata: 9,
  },
  {
    br_cilindra: "11",
    obim: 279.4,
    br_zubaca: 88.0,
    sirina: 27.94,
    br_komada_tm: 35.791,
    br_elemenata: 10,
  },
  {
    br_cilindra: "11",
    obim: 279.4,
    br_zubaca: 88.0,
    sirina: 25.4,
    br_komada_tm: 39.37,
    br_elemenata: 11,
  },
  {
    br_cilindra: "11",
    obim: 279.4,
    br_zubaca: 88.0,
    sirina: 23.283,
    br_komada_tm: 42.949,
    br_elemenata: 12,
  },
  {
    br_cilindra: "11",
    obim: 279.4,
    br_zubaca: 88.0,
    sirina: 21.492,
    br_komada_tm: 46.528,
    br_elemenata: 13,
  },
  {
    br_cilindra: "11",
    obim: 279.4,
    br_zubaca: 88.0,
    sirina: 19.957,
    br_komada_tm: 50.107,
    br_elemenata: 14,
  },
  {
    br_cilindra: "11",
    obim: 279.4,
    br_zubaca: 88.0,
    sirina: 18.627,
    br_komada_tm: 53.686,
    br_elemenata: 15,
  },
  {
    br_cilindra: "11",
    obim: 279.4,
    br_zubaca: 88.0,
    sirina: 17.463,
    br_komada_tm: 57.266,
    br_elemenata: 16,
  },
  {
    br_cilindra: "11",
    obim: 279.4,
    br_zubaca: 88.0,
    sirina: 16.435,
    br_komada_tm: 60.845,
    br_elemenata: 17,
  },
  {
    br_cilindra: "11",
    obim: 279.4,
    br_zubaca: 88.0,
    sirina: 15.552,
    br_komada_tm: 64.424,
    br_elemenata: 18,
  },
  {
    br_cilindra: "11",
    obim: 279.4,
    br_zubaca: 88.0,
    sirina: 14.705,
    br_komada_tm: 68.003,
    br_elemenata: 19,
  },
  {
    br_cilindra: "11",
    obim: 279.4,
    br_zubaca: 88.0,
    sirina: 13.97,
    br_komada_tm: 71.582,
    br_elemenata: 20,
  },
  {
    br_cilindra: "11 1/4",
    obim: 285.75,
    br_zubaca: 90.0,
    sirina: 285.75,
    br_komada_tm: 3.5,
    br_elemenata: 1,
  },
  {
    br_cilindra: "11 1/4",
    obim: 285.75,
    br_zubaca: 90.0,
    sirina: 142.875,
    br_komada_tm: 6.999,
    br_elemenata: 2,
  },
  {
    br_cilindra: "11 1/4",
    obim: 285.75,
    br_zubaca: 90.0,
    sirina: 95.25,
    br_komada_tm: 10.499,
    br_elemenata: 3,
  },
  {
    br_cilindra: "11 1/4",
    obim: 285.75,
    br_zubaca: 90.0,
    sirina: 71.438,
    br_komada_tm: 13.998,
    br_elemenata: 4,
  },
  {
    br_cilindra: "11 1/4",
    obim: 285.75,
    br_zubaca: 90.0,
    sirina: 57.15,
    br_komada_tm: 17.498,
    br_elemenata: 5,
  },
  {
    br_cilindra: "11 1/4",
    obim: 285.75,
    br_zubaca: 90.0,
    sirina: 47.625,
    br_komada_tm: 20.997,
    br_elemenata: 6,
  },
  {
    br_cilindra: "11 1/4",
    obim: 285.75,
    br_zubaca: 90.0,
    sirina: 40.821,
    br_komada_tm: 24.497,
    br_elemenata: 7,
  },
  {
    br_cilindra: "11 1/4",
    obim: 285.75,
    br_zubaca: 90.0,
    sirina: 35.719,
    br_komada_tm: 27.997,
    br_elemenata: 8,
  },
  {
    br_cilindra: "11 1/4",
    obim: 285.75,
    br_zubaca: 90.0,
    sirina: 31.75,
    br_komada_tm: 31.496,
    br_elemenata: 9,
  },
  {
    br_cilindra: "11 1/4",
    obim: 285.75,
    br_zubaca: 90.0,
    sirina: 28.575,
    br_komada_tm: 34.996,
    br_elemenata: 10,
  },
  {
    br_cilindra: "11 1/4",
    obim: 285.75,
    br_zubaca: 90.0,
    sirina: 25.977,
    br_komada_tm: 38.495,
    br_elemenata: 11,
  },
  {
    br_cilindra: "11 1/4",
    obim: 285.75,
    br_zubaca: 90.0,
    sirina: 23.813,
    br_komada_tm: 41.995,
    br_elemenata: 12,
  },
  {
    br_cilindra: "11 1/4",
    obim: 285.75,
    br_zubaca: 90.0,
    sirina: 21.981,
    br_komada_tm: 45.494,
    br_elemenata: 13,
  },
  {
    br_cilindra: "11 1/4",
    obim: 285.75,
    br_zubaca: 90.0,
    sirina: 20.411,
    br_komada_tm: 48.994,
    br_elemenata: 14,
  },
  {
    br_cilindra: "11 1/4",
    obim: 285.75,
    br_zubaca: 90.0,
    sirina: 19.05,
    br_komada_tm: 52.493,
    br_elemenata: 15,
  },
  {
    br_cilindra: "11 1/4",
    obim: 285.75,
    br_zubaca: 90.0,
    sirina: 17.859,
    br_komada_tm: 55.993,
    br_elemenata: 16,
  },
  {
    br_cilindra: "11 1/4",
    obim: 285.75,
    br_zubaca: 90.0,
    sirina: 16.809,
    br_komada_tm: 59.493,
    br_elemenata: 17,
  },
  {
    br_cilindra: "11 1/4",
    obim: 285.75,
    br_zubaca: 90.0,
    sirina: 15.875,
    br_komada_tm: 62.992,
    br_elemenata: 18,
  },
  {
    br_cilindra: "11 1/4",
    obim: 285.75,
    br_zubaca: 90.0,
    sirina: 15.039,
    br_komada_tm: 66.492,
    br_elemenata: 19,
  },
  {
    br_cilindra: "11 1/4",
    obim: 285.75,
    br_zubaca: 90.0,
    sirina: 14.288,
    br_komada_tm: 69.991,
    br_elemenata: 20,
  },
  {
    br_cilindra: "11 3/8",
    obim: 288.93,
    br_zubaca: 91.0,
    sirina: 288.93,
    br_komada_tm: 3.461,
    br_elemenata: 1,
  },
  {
    br_cilindra: "11 3/8",
    obim: 288.93,
    br_zubaca: 91.0,
    sirina: 144.465,
    br_komada_tm: 6.922,
    br_elemenata: 2,
  },
  {
    br_cilindra: "11 3/8",
    obim: 288.93,
    br_zubaca: 91.0,
    sirina: 96.31,
    br_komada_tm: 10.383,
    br_elemenata: 3,
  },
  {
    br_cilindra: "11 3/8",
    obim: 288.93,
    br_zubaca: 91.0,
    sirina: 72.233,
    br_komada_tm: 13.844,
    br_elemenata: 4,
  },
  {
    br_cilindra: "11 3/8",
    obim: 288.93,
    br_zubaca: 91.0,
    sirina: 57.786,
    br_komada_tm: 17.305,
    br_elemenata: 5,
  },
  {
    br_cilindra: "11 3/8",
    obim: 288.93,
    br_zubaca: 91.0,
    sirina: 48.155,
    br_komada_tm: 20.766,
    br_elemenata: 6,
  },
  {
    br_cilindra: "11 3/8",
    obim: 288.93,
    br_zubaca: 91.0,
    sirina: 41.276,
    br_komada_tm: 24.227,
    br_elemenata: 7,
  },
  {
    br_cilindra: "11 3/8",
    obim: 288.93,
    br_zubaca: 91.0,
    sirina: 36.116,
    br_komada_tm: 27.689,
    br_elemenata: 8,
  },
  {
    br_cilindra: "11 3/8",
    obim: 288.93,
    br_zubaca: 91.0,
    sirina: 32.103,
    br_komada_tm: 31.149,
    br_elemenata: 9,
  },
  {
    br_cilindra: "11 3/8",
    obim: 288.93,
    br_zubaca: 91.0,
    sirina: 28.893,
    br_komada_tm: 34.61,
    br_elemenata: 10,
  },
  {
    br_cilindra: "11 1/2",
    obim: 292.1,
    br_zubaca: 92.0,
    sirina: 292.1,
    br_komada_tm: 3.423,
    br_elemenata: 1,
  },
  {
    br_cilindra: "11 1/2",
    obim: 292.1,
    br_zubaca: 92.0,
    sirina: 146.05,
    br_komada_tm: 6.847,
    br_elemenata: 2,
  },
  {
    br_cilindra: "11 1/2",
    obim: 292.1,
    br_zubaca: 92.0,
    sirina: 97.367,
    br_komada_tm: 10.27,
    br_elemenata: 3,
  },
  {
    br_cilindra: "11 1/2",
    obim: 292.1,
    br_zubaca: 92.0,
    sirina: 73.025,
    br_komada_tm: 13.694,
    br_elemenata: 4,
  },
  {
    br_cilindra: "11 1/2",
    obim: 292.1,
    br_zubaca: 92.0,
    sirina: 58.42,
    br_komada_tm: 17.117,
    br_elemenata: 5,
  },
  {
    br_cilindra: "11 1/2",
    obim: 292.1,
    br_zubaca: 92.0,
    sirina: 48.683,
    br_komada_tm: 20.541,
    br_elemenata: 6,
  },
  {
    br_cilindra: "11 1/2",
    obim: 292.1,
    br_zubaca: 92.0,
    sirina: 41.729,
    br_komada_tm: 23.964,
    br_elemenata: 7,
  },
  {
    br_cilindra: "11 1/2",
    obim: 292.1,
    br_zubaca: 92.0,
    sirina: 36.513,
    br_komada_tm: 27.388,
    br_elemenata: 8,
  },
  {
    br_cilindra: "11 1/2",
    obim: 292.1,
    br_zubaca: 92.0,
    sirina: 32.456,
    br_komada_tm: 30.811,
    br_elemenata: 9,
  },
  {
    br_cilindra: "11 1/2",
    obim: 292.1,
    br_zubaca: 92.0,
    sirina: 29.21,
    br_komada_tm: 34.235,
    br_elemenata: 10,
  },
  // {
  //   br_cilindra: "11 1/2",
  //   obim: 292.1,
  //   br_zubaca: 92.0,
  //   sirina: 26.555,
  //   br_komada_tm: 37.658,
  //   br_elemenata: 11,
  // },
  // {
  //   br_cilindra: "11 1/2",
  //   obim: 292.1,
  //   br_zubaca: 92.0,
  //   sirina: 24.342,
  //   br_komada_tm: 41.082,
  //   br_elemenata: 12,
  // },
  // {
  //   br_cilindra: "11 1/2",
  //   obim: 292.1,
  //   br_zubaca: 92.0,
  //   sirina: 22.469,
  //   br_komada_tm: 44.505,
  //   br_elemenata: 13,
  // },
  // {
  //   br_cilindra: "11 1/2",
  //   obim: 292.1,
  //   br_zubaca: 92.0,
  //   sirina: 20.864,
  //   br_komada_tm: 47.929,
  //   br_elemenata: 14,
  // },
  // {
  //   br_cilindra: "11 1/2",
  //   obim: 292.1,
  //   br_zubaca: 92.0,
  //   sirina: 19.473,
  //   br_komada_tm: 51.352,
  //   br_elemenata: 15,
  // },
  // {
  //   br_cilindra: "11 1/2",
  //   obim: 292.1,
  //   br_zubaca: 92.0,
  //   sirina: 18.256,
  //   br_komada_tm: 54.776,
  //   br_elemenata: 16,
  // },
  // {
  //   br_cilindra: "11 1/2",
  //   obim: 292.1,
  //   br_zubaca: 92.0,
  //   sirina: 17.182,
  //   br_komada_tm: 58.199,
  //   br_elemenata: 17,
  // },
  // {
  //   br_cilindra: "11 1/2",
  //   obim: 292.1,
  //   br_zubaca: 92.0,
  //   sirina: 16.228,
  //   br_komada_tm: 61.623,
  //   br_elemenata: 18,
  // },
  // {
  //   br_cilindra: "11 1/2",
  //   obim: 292.1,
  //   br_zubaca: 92.0,
  //   sirina: 15.374,
  //   br_komada_tm: 65.046,
  //   br_elemenata: 19,
  // },
  // {
  //   br_cilindra: "11 1/2",
  //   obim: 292.1,
  //   br_zubaca: 92.0,
  //   sirina: 14.605,
  //   br_komada_tm: 68.47,
  //   br_elemenata: 20,
  // },
  {
    br_cilindra: "12",
    obim: 304.8,
    br_zubaca: 96.0,
    sirina: 304.8,
    br_komada_tm: 3.281,
    br_elemenata: 1,
  },
  {
    br_cilindra: "12",
    obim: 304.8,
    br_zubaca: 96.0,
    sirina: 152.4,
    br_komada_tm: 6.562,
    br_elemenata: 2,
  },
  {
    br_cilindra: "12",
    obim: 304.8,
    br_zubaca: 96.0,
    sirina: 101.6,
    br_komada_tm: 9.843,
    br_elemenata: 3,
  },
  {
    br_cilindra: "12",
    obim: 304.8,
    br_zubaca: 96.0,
    sirina: 76.2,
    br_komada_tm: 13.123,
    br_elemenata: 4,
  },
  {
    br_cilindra: "12",
    obim: 304.8,
    br_zubaca: 96.0,
    sirina: 60.96,
    br_komada_tm: 16.404,
    br_elemenata: 5,
  },
  {
    br_cilindra: "12",
    obim: 304.8,
    br_zubaca: 96.0,
    sirina: 50.8,
    br_komada_tm: 19.685,
    br_elemenata: 6,
  },
  {
    br_cilindra: "12",
    obim: 304.8,
    br_zubaca: 96.0,
    sirina: 43.543,
    br_komada_tm: 22.966,
    br_elemenata: 7,
  },
  {
    br_cilindra: "12",
    obim: 304.8,
    br_zubaca: 96.0,
    sirina: 38.1,
    br_komada_tm: 26.247,
    br_elemenata: 8,
  },
  {
    br_cilindra: "12",
    obim: 304.8,
    br_zubaca: 96.0,
    sirina: 33.867,
    br_komada_tm: 29.528,
    br_elemenata: 9,
  },
  {
    br_cilindra: "12",
    obim: 304.8,
    br_zubaca: 96.0,
    sirina: 30.48,
    br_komada_tm: 32.808,
    br_elemenata: 10,
  },
  {
    br_cilindra: "12",
    obim: 304.8,
    br_zubaca: 96.0,
    sirina: 27.709,
    br_komada_tm: 36.089,
    br_elemenata: 11,
  },
  {
    br_cilindra: "12",
    obim: 304.8,
    br_zubaca: 96.0,
    sirina: 25.4,
    br_komada_tm: 39.37,
    br_elemenata: 12,
  },
  {
    br_cilindra: "12",
    obim: 304.8,
    br_zubaca: 96.0,
    sirina: 23.446,
    br_komada_tm: 42.651,
    br_elemenata: 13,
  },
  {
    br_cilindra: "12",
    obim: 304.8,
    br_zubaca: 96.0,
    sirina: 21.771,
    br_komada_tm: 45.932,
    br_elemenata: 14,
  },
  {
    br_cilindra: "12",
    obim: 304.8,
    br_zubaca: 96.0,
    sirina: 20.32,
    br_komada_tm: 49.213,
    br_elemenata: 15,
  },
  {
    br_cilindra: "12",
    obim: 304.8,
    br_zubaca: 96.0,
    sirina: 19.05,
    br_komada_tm: 52.493,
    br_elemenata: 16,
  },
  {
    br_cilindra: "12",
    obim: 304.8,
    br_zubaca: 96.0,
    sirina: 17.929,
    br_komada_tm: 55.774,
    br_elemenata: 17,
  },
  {
    br_cilindra: "12",
    obim: 304.8,
    br_zubaca: 96.0,
    sirina: 16.933,
    br_komada_tm: 59.055,
    br_elemenata: 18,
  },
  {
    br_cilindra: "12",
    obim: 304.8,
    br_zubaca: 96.0,
    sirina: 16.042,
    br_komada_tm: 62.336,
    br_elemenata: 19,
  },
  {
    br_cilindra: "12",
    obim: 304.8,
    br_zubaca: 96.0,
    sirina: 15.24,
    br_komada_tm: 65.617,
    br_elemenata: 20,
  },
  {
    br_cilindra: "12 1/8",
    obim: 307.98,
    br_zubaca: 97.0,
    sirina: 307.98,
    br_komada_tm: 3.247,
    br_elemenata: 1,
  },
  {
    br_cilindra: "12 1/8",
    obim: 307.98,
    br_zubaca: 97.0,
    sirina: 153.99,
    br_komada_tm: 6.494,
    br_elemenata: 2,
  },
  {
    br_cilindra: "12 1/8",
    obim: 307.98,
    br_zubaca: 97.0,
    sirina: 102.66,
    br_komada_tm: 9.741,
    br_elemenata: 3,
  },
  {
    br_cilindra: "12 1/8",
    obim: 307.98,
    br_zubaca: 97.0,
    sirina: 76.995,
    br_komada_tm: 12.988,
    br_elemenata: 4,
  },
  {
    br_cilindra: "12 1/8",
    obim: 307.98,
    br_zubaca: 97.0,
    sirina: 61.596,
    br_komada_tm: 16.235,
    br_elemenata: 5,
  },
  {
    br_cilindra: "12 1/8",
    obim: 307.98,
    br_zubaca: 97.0,
    sirina: 51.33,
    br_komada_tm: 19.482,
    br_elemenata: 6,
  },
  {
    br_cilindra: "12 1/8",
    obim: 307.98,
    br_zubaca: 97.0,
    sirina: 43.997,
    br_komada_tm: 22.729,
    br_elemenata: 7,
  },
  {
    br_cilindra: "12 1/8",
    obim: 307.98,
    br_zubaca: 97.0,
    sirina: 38.498,
    br_komada_tm: 25.976,
    br_elemenata: 8,
  },
  {
    br_cilindra: "12 1/8",
    obim: 307.98,
    br_zubaca: 97.0,
    sirina: 34.22,
    br_komada_tm: 29.223,
    br_elemenata: 9,
  },
  {
    br_cilindra: "12 1/8",
    obim: 307.98,
    br_zubaca: 97.0,
    sirina: 30.798,
    br_komada_tm: 32.47,
    br_elemenata: 10,
  },
  {
    br_cilindra: "12 1/4",
    obim: 311.15,
    br_zubaca: 98.0,
    sirina: 311.15,
    br_komada_tm: 3.214,
    br_elemenata: 1,
  },
  {
    br_cilindra: "12 1/4",
    obim: 311.15,
    br_zubaca: 98.0,
    sirina: 155.575,
    br_komada_tm: 6.428,
    br_elemenata: 2,
  },
  {
    br_cilindra: "12 1/4",
    obim: 311.15,
    br_zubaca: 98.0,
    sirina: 103.717,
    br_komada_tm: 9.642,
    br_elemenata: 3,
  },
  {
    br_cilindra: "12 1/4",
    obim: 311.15,
    br_zubaca: 98.0,
    sirina: 77.788,
    br_komada_tm: 12.856,
    br_elemenata: 4,
  },
  {
    br_cilindra: "12 1/4",
    obim: 311.15,
    br_zubaca: 98.0,
    sirina: 62.23,
    br_komada_tm: 16.069,
    br_elemenata: 5,
  },
  {
    br_cilindra: "12 1/4",
    obim: 311.15,
    br_zubaca: 98.0,
    sirina: 51.858,
    br_komada_tm: 19.283,
    br_elemenata: 6,
  },
  {
    br_cilindra: "12 1/4",
    obim: 311.15,
    br_zubaca: 98.0,
    sirina: 44.45,
    br_komada_tm: 22.497,
    br_elemenata: 7,
  },
  {
    br_cilindra: "12 1/4",
    obim: 311.15,
    br_zubaca: 98.0,
    sirina: 38.894,
    br_komada_tm: 25.711,
    br_elemenata: 8,
  },
  {
    br_cilindra: "12 1/4",
    obim: 311.15,
    br_zubaca: 98.0,
    sirina: 34.572,
    br_komada_tm: 28.925,
    br_elemenata: 9,
  },
  {
    br_cilindra: "12 1/4",
    obim: 311.15,
    br_zubaca: 98.0,
    sirina: 31.115,
    br_komada_tm: 32.139,
    br_elemenata: 10,
  },
  {
    br_cilindra: "12 1/4",
    obim: 311.15,
    br_zubaca: 98.0,
    sirina: 28.286,
    br_komada_tm: 35.353,
    br_elemenata: 11,
  },
  {
    br_cilindra: "12 1/4",
    obim: 311.15,
    br_zubaca: 98.0,
    sirina: 25.929,
    br_komada_tm: 38.567,
    br_elemenata: 12,
  },
  {
    br_cilindra: "12 1/4",
    obim: 311.15,
    br_zubaca: 98.0,
    sirina: 23.935,
    br_komada_tm: 41.78,
    br_elemenata: 13,
  },
  {
    br_cilindra: "12 1/4",
    obim: 311.15,
    br_zubaca: 98.0,
    sirina: 22.225,
    br_komada_tm: 44.994,
    br_elemenata: 14,
  },
  {
    br_cilindra: "12 1/4",
    obim: 311.15,
    br_zubaca: 98.0,
    sirina: 20.743,
    br_komada_tm: 48.208,
    br_elemenata: 15,
  },
  {
    br_cilindra: "12 1/4",
    obim: 311.15,
    br_zubaca: 98.0,
    sirina: 19.447,
    br_komada_tm: 51.422,
    br_elemenata: 16,
  },
  {
    br_cilindra: "12 1/4",
    obim: 311.15,
    br_zubaca: 98.0,
    sirina: 18.303,
    br_komada_tm: 54.636,
    br_elemenata: 17,
  },
  {
    br_cilindra: "12 1/4",
    obim: 311.15,
    br_zubaca: 98.0,
    sirina: 17.286,
    br_komada_tm: 57.85,
    br_elemenata: 18,
  },
  {
    br_cilindra: "12 1/4",
    obim: 311.15,
    br_zubaca: 98.0,
    sirina: 16.376,
    br_komada_tm: 61.064,
    br_elemenata: 19,
  },
  {
    br_cilindra: "12 1/4",
    obim: 311.15,
    br_zubaca: 98.0,
    sirina: 15.558,
    br_komada_tm: 64.278,
    br_elemenata: 20,
  },
  {
    br_cilindra: "12 3/8",
    obim: 314.33,
    br_zubaca: 99.0,
    sirina: 314.33,
    br_komada_tm: 3.181,
    br_elemenata: 1,
  },
  {
    br_cilindra: "12 3/8",
    obim: 314.33,
    br_zubaca: 99.0,
    sirina: 157.163,
    br_komada_tm: 6.362,
    br_elemenata: 2,
  },
  {
    br_cilindra: "12 3/8",
    obim: 314.33,
    br_zubaca: 99.0,
    sirina: 104.775,
    br_komada_tm: 9.544,
    br_elemenata: 3,
  },
  {
    br_cilindra: "12 3/8",
    obim: 314.33,
    br_zubaca: 99.0,
    sirina: 78.581,
    br_komada_tm: 12.725,
    br_elemenata: 4,
  },
  {
    br_cilindra: "12 3/8",
    obim: 314.33,
    br_zubaca: 99.0,
    sirina: 62.865,
    br_komada_tm: 15.906,
    br_elemenata: 5,
  },
  {
    br_cilindra: "12 3/8",
    obim: 314.33,
    br_zubaca: 99.0,
    sirina: 52.388,
    br_komada_tm: 19.088,
    br_elemenata: 6,
  },
  {
    br_cilindra: "12 3/8",
    obim: 314.33,
    br_zubaca: 99.0,
    sirina: 44.904,
    br_komada_tm: 22.269,
    br_elemenata: 7,
  },
  {
    br_cilindra: "12 3/8",
    obim: 314.33,
    br_zubaca: 99.0,
    sirina: 39.291,
    br_komada_tm: 25.451,
    br_elemenata: 8,
  },
  {
    br_cilindra: "12 3/8",
    obim: 314.33,
    br_zubaca: 99.0,
    sirina: 34.926,
    br_komada_tm: 28.632,
    br_elemenata: 9,
  },
  {
    br_cilindra: "12 3/8",
    obim: 314.33,
    br_zubaca: 99.0,
    sirina: 31.433,
    br_komada_tm: 31.813,
    br_elemenata: 10,
  },
  {
    br_cilindra: "12 3/4",
    obim: 323.85,
    br_zubaca: 102.0,
    sirina: 323.85,
    br_komada_tm: 3.088,
    br_elemenata: 1,
  },
  {
    br_cilindra: "12 3/4",
    obim: 323.85,
    br_zubaca: 102.0,
    sirina: 161.925,
    br_komada_tm: 6.176,
    br_elemenata: 2,
  },
  {
    br_cilindra: "12 3/4",
    obim: 323.85,
    br_zubaca: 102.0,
    sirina: 107.95,
    br_komada_tm: 9.264,
    br_elemenata: 3,
  },
  {
    br_cilindra: "12 3/4",
    obim: 323.85,
    br_zubaca: 102.0,
    sirina: 80.963,
    br_komada_tm: 12.351,
    br_elemenata: 4,
  },
  {
    br_cilindra: "12 3/4",
    obim: 323.85,
    br_zubaca: 102.0,
    sirina: 64.77,
    br_komada_tm: 15.439,
    br_elemenata: 5,
  },
  {
    br_cilindra: "12 3/4",
    obim: 323.85,
    br_zubaca: 102.0,
    sirina: 53.975,
    br_komada_tm: 18.527,
    br_elemenata: 6,
  },
  {
    br_cilindra: "12 3/4",
    obim: 323.85,
    br_zubaca: 102.0,
    sirina: 46.264,
    br_komada_tm: 21.615,
    br_elemenata: 7,
  },
  {
    br_cilindra: "12 3/4",
    obim: 323.85,
    br_zubaca: 102.0,
    sirina: 40.481,
    br_komada_tm: 24.703,
    br_elemenata: 8,
  },
  {
    br_cilindra: "12 3/4",
    obim: 323.85,
    br_zubaca: 102.0,
    sirina: 35.983,
    br_komada_tm: 27.791,
    br_elemenata: 9,
  },
  {
    br_cilindra: "12 3/4",
    obim: 323.85,
    br_zubaca: 102.0,
    sirina: 32.386,
    br_komada_tm: 30.878,
    br_elemenata: 10,
  },
  {
    br_cilindra: "12 3/4",
    obim: 323.85,
    br_zubaca: 102.0,
    sirina: 29.441,
    br_komada_tm: 33.966,
    br_elemenata: 11,
  },
  {
    br_cilindra: "12 3/4",
    obim: 323.85,
    br_zubaca: 102.0,
    sirina: 26.988,
    br_komada_tm: 37.054,
    br_elemenata: 12,
  },
  {
    br_cilindra: "12 3/4",
    obim: 323.85,
    br_zubaca: 102.0,
    sirina: 24.912,
    br_komada_tm: 40.142,
    br_elemenata: 13,
  },
  {
    br_cilindra: "12 3/4",
    obim: 323.85,
    br_zubaca: 102.0,
    sirina: 23.132,
    br_komada_tm: 43.23,
    br_elemenata: 14,
  },
  {
    br_cilindra: "12 3/4",
    obim: 323.85,
    br_zubaca: 102.0,
    sirina: 21.59,
    br_komada_tm: 46.318,
    br_elemenata: 15,
  },
  {
    br_cilindra: "12 3/4",
    obim: 323.85,
    br_zubaca: 102.0,
    sirina: 20.241,
    br_komada_tm: 49.406,
    br_elemenata: 16,
  },
  {
    br_cilindra: "12 3/4",
    obim: 323.85,
    br_zubaca: 102.0,
    sirina: 19.05,
    br_komada_tm: 52.493,
    br_elemenata: 17,
  },
  {
    br_cilindra: "12 3/4",
    obim: 323.85,
    br_zubaca: 102.0,
    sirina: 17.992,
    br_komada_tm: 55.581,
    br_elemenata: 18,
  },
  {
    br_cilindra: "12 3/4",
    obim: 323.85,
    br_zubaca: 102.0,
    sirina: 17.045,
    br_komada_tm: 58.669,
    br_elemenata: 19,
  },
  {
    br_cilindra: "12 3/4",
    obim: 323.85,
    br_zubaca: 102.0,
    sirina: 16.193,
    br_komada_tm: 61.757,
    br_elemenata: 20,
  },
  {
    br_cilindra: "12 7/8",
    obim: 327.03,
    br_zubaca: 102.998,
    sirina: 327.03,
    br_komada_tm: 3.058,
    br_elemenata: 1,
  },
  {
    br_cilindra: "12 7/8",
    obim: 327.03,
    br_zubaca: 102.998,
    sirina: 163.515,
    br_komada_tm: 6.116,
    br_elemenata: 2,
  },
  {
    br_cilindra: "12 7/8",
    obim: 327.03,
    br_zubaca: 102.998,
    sirina: 109.01,
    br_komada_tm: 9.174,
    br_elemenata: 3,
  },
  {
    br_cilindra: "12 7/8",
    obim: 327.03,
    br_zubaca: 102.998,
    sirina: 81.758,
    br_komada_tm: 12.232,
    br_elemenata: 4,
  },
  {
    br_cilindra: "12 7/8",
    obim: 327.03,
    br_zubaca: 102.998,
    sirina: 65.406,
    br_komada_tm: 15.29,
    br_elemenata: 5,
  },
  {
    br_cilindra: "12 7/8",
    obim: 327.03,
    br_zubaca: 102.998,
    sirina: 54.505,
    br_komada_tm: 18.348,
    br_elemenata: 6,
  },
  {
    br_cilindra: "12 7/8",
    obim: 327.03,
    br_zubaca: 102.998,
    sirina: 46.719,
    br_komada_tm: 21.405,
    br_elemenata: 7,
  },
  {
    br_cilindra: "12 7/8",
    obim: 327.03,
    br_zubaca: 102.998,
    sirina: 40.879,
    br_komada_tm: 24.463,
    br_elemenata: 8,
  },
  {
    br_cilindra: "12 7/8",
    obim: 327.03,
    br_zubaca: 102.998,
    sirina: 36.337,
    br_komada_tm: 27.521,
    br_elemenata: 9,
  },
  {
    br_cilindra: "12 7/8",
    obim: 327.03,
    br_zubaca: 102.998,
    sirina: 32.703,
    br_komada_tm: 30.579,
    br_elemenata: 10,
  },
  {
    br_cilindra: "12 7/8",
    obim: 327.03,
    br_zubaca: 102.998,
    sirina: 29.729,
    br_komada_tm: 33.637,
    br_elemenata: 11,
  },
  {
    br_cilindra: "12 7/8",
    obim: 327.03,
    br_zubaca: 102.998,
    sirina: 27.252,
    br_komada_tm: 36.695,
    br_elemenata: 12,
  },
  {
    br_cilindra: "12 7/8",
    obim: 327.03,
    br_zubaca: 102.998,
    sirina: 25.155,
    br_komada_tm: 39.753,
    br_elemenata: 13,
  },
  {
    br_cilindra: "12 7/8",
    obim: 327.03,
    br_zubaca: 102.998,
    sirina: 23.359,
    br_komada_tm: 42.811,
    br_elemenata: 14,
  },
  {
    br_cilindra: "12 7/8",
    obim: 327.03,
    br_zubaca: 102.998,
    sirina: 21.801,
    br_komada_tm: 45.869,
    br_elemenata: 15,
  },
  {
    br_cilindra: "12 7/8",
    obim: 327.03,
    br_zubaca: 102.998,
    sirina: 20.439,
    br_komada_tm: 48.927,
    br_elemenata: 16,
  },
  {
    br_cilindra: "12 7/8",
    obim: 327.03,
    br_zubaca: 102.998,
    sirina: 19.236,
    br_komada_tm: 51.985,
    br_elemenata: 17,
  },
  {
    br_cilindra: "12 7/8",
    obim: 327.03,
    br_zubaca: 102.998,
    sirina: 18.168,
    br_komada_tm: 55.043,
    br_elemenata: 18,
  },
  {
    br_cilindra: "12 7/8",
    obim: 327.03,
    br_zubaca: 102.998,
    sirina: 17.212,
    br_komada_tm: 58.1,
    br_elemenata: 19,
  },
  {
    br_cilindra: "12 7/8",
    obim: 327.03,
    br_zubaca: 102.998,
    sirina: 16.351,
    br_komada_tm: 61.158,
    br_elemenata: 20,
  },
  {
    br_cilindra: "13",
    obim: 330.2,
    br_zubaca: 104.0,
    sirina: 330.2,
    br_komada_tm: 3.028,
    br_elemenata: 1,
  },
  {
    br_cilindra: "13",
    obim: 330.2,
    br_zubaca: 104.0,
    sirina: 165.1,
    br_komada_tm: 6.057,
    br_elemenata: 2,
  },
  {
    br_cilindra: "13",
    obim: 330.2,
    br_zubaca: 104.0,
    sirina: 110.067,
    br_komada_tm: 9.085,
    br_elemenata: 3,
  },
  {
    br_cilindra: "13",
    obim: 330.2,
    br_zubaca: 104.0,
    sirina: 82.55,
    br_komada_tm: 12.114,
    br_elemenata: 4,
  },
  {
    br_cilindra: "13",
    obim: 330.2,
    br_zubaca: 104.0,
    sirina: 66.04,
    br_komada_tm: 15.142,
    br_elemenata: 5,
  },
  {
    br_cilindra: "13",
    obim: 330.2,
    br_zubaca: 104.0,
    sirina: 55.033,
    br_komada_tm: 18.171,
    br_elemenata: 6,
  },
  {
    br_cilindra: "13",
    obim: 330.2,
    br_zubaca: 104.0,
    sirina: 47.171,
    br_komada_tm: 21.199,
    br_elemenata: 7,
  },
  {
    br_cilindra: "13",
    obim: 330.2,
    br_zubaca: 104.0,
    sirina: 41.275,
    br_komada_tm: 24.228,
    br_elemenata: 8,
  },
  {
    br_cilindra: "13",
    obim: 330.2,
    br_zubaca: 104.0,
    sirina: 36.689,
    br_komada_tm: 27.256,
    br_elemenata: 9,
  },
  {
    br_cilindra: "13",
    obim: 330.2,
    br_zubaca: 104.0,
    sirina: 33.02,
    br_komada_tm: 30.285,
    br_elemenata: 10,
  },
  {
    br_cilindra: "13",
    obim: 330.2,
    br_zubaca: 104.0,
    sirina: 30.018,
    br_komada_tm: 33.313,
    br_elemenata: 11,
  },
  {
    br_cilindra: "13",
    obim: 330.2,
    br_zubaca: 104.0,
    sirina: 27.517,
    br_komada_tm: 36.342,
    br_elemenata: 12,
  },
  {
    br_cilindra: "13",
    obim: 330.2,
    br_zubaca: 104.0,
    sirina: 25.4,
    br_komada_tm: 39.37,
    br_elemenata: 13,
  },
  {
    br_cilindra: "13",
    obim: 330.2,
    br_zubaca: 104.0,
    sirina: 23.586,
    br_komada_tm: 42.399,
    br_elemenata: 14,
  },
  {
    br_cilindra: "13",
    obim: 330.2,
    br_zubaca: 104.0,
    sirina: 22.013,
    br_komada_tm: 45.427,
    br_elemenata: 15,
  },
  {
    br_cilindra: "13",
    obim: 330.2,
    br_zubaca: 104.0,
    sirina: 20.638,
    br_komada_tm: 48.455,
    br_elemenata: 16,
  },
  {
    br_cilindra: "13",
    obim: 330.2,
    br_zubaca: 104.0,
    sirina: 19.424,
    br_komada_tm: 51.484,
    br_elemenata: 17,
  },
  {
    br_cilindra: "13",
    obim: 330.2,
    br_zubaca: 104.0,
    sirina: 18.344,
    br_komada_tm: 54.512,
    br_elemenata: 18,
  },
  {
    br_cilindra: "13",
    obim: 330.2,
    br_zubaca: 104.0,
    sirina: 17.379,
    br_komada_tm: 57.541,
    br_elemenata: 19,
  },
  {
    br_cilindra: "13",
    obim: 330.2,
    br_zubaca: 104.0,
    sirina: 16.51,
    br_komada_tm: 60.569,
    br_elemenata: 20,
  },
  {
    br_cilindra: "13 1/4",
    obim: 336.55,
    br_zubaca: 106.0,
    sirina: 336.55,
    br_komada_tm: 2.971,
    br_elemenata: 1,
  },
  {
    br_cilindra: "13 1/4-2",
    obim: 336.55,
    br_zubaca: 106.0,
    sirina: 336.55,
    br_komada_tm: 2.971,
    br_elemenata: 1,
  },
  {
    br_cilindra: "13 1/4",
    obim: 336.55,
    br_zubaca: 106.0,
    sirina: 168.275,
    br_komada_tm: 5.943,
    br_elemenata: 2,
  },
  {
    br_cilindra: "13 1/4",
    obim: 336.55,
    br_zubaca: 106.0,
    sirina: 112.183,
    br_komada_tm: 8.914,
    br_elemenata: 3,
  },
  {
    br_cilindra: "13 1/4",
    obim: 336.55,
    br_zubaca: 106.0,
    sirina: 84.138,
    br_komada_tm: 11.885,
    br_elemenata: 4,
  },
  {
    br_cilindra: "13 1/4",
    obim: 336.55,
    br_zubaca: 106.0,
    sirina: 67.31,
    br_komada_tm: 14.857,
    br_elemenata: 5,
  },
  {
    br_cilindra: "13 1/4",
    obim: 336.55,
    br_zubaca: 106.0,
    sirina: 56.092,
    br_komada_tm: 17.828,
    br_elemenata: 6,
  },
  {
    br_cilindra: "13 1/4",
    obim: 336.55,
    br_zubaca: 106.0,
    sirina: 48.079,
    br_komada_tm: 20.799,
    br_elemenata: 7,
  },
  {
    br_cilindra: "13 1/4",
    obim: 336.55,
    br_zubaca: 106.0,
    sirina: 42.069,
    br_komada_tm: 23.771,
    br_elemenata: 8,
  },
  {
    br_cilindra: "13 1/4",
    obim: 336.55,
    br_zubaca: 106.0,
    sirina: 37.394,
    br_komada_tm: 26.742,
    br_elemenata: 9,
  },
  {
    br_cilindra: "13 1/4",
    obim: 336.55,
    br_zubaca: 106.0,
    sirina: 33.655,
    br_komada_tm: 29.713,
    br_elemenata: 10,
  },
  {
    br_cilindra: "13 1/4",
    obim: 336.55,
    br_zubaca: 106.0,
    sirina: 30.595,
    br_komada_tm: 32.685,
    br_elemenata: 11,
  },
  {
    br_cilindra: "13 1/4",
    obim: 336.55,
    br_zubaca: 106.0,
    sirina: 28.046,
    br_komada_tm: 35.656,
    br_elemenata: 12,
  },
  {
    br_cilindra: "13 1/4",
    obim: 336.55,
    br_zubaca: 106.0,
    sirina: 25.888,
    br_komada_tm: 38.627,
    br_elemenata: 13,
  },
  {
    br_cilindra: "13 1/4",
    obim: 336.55,
    br_zubaca: 106.0,
    sirina: 24.039,
    br_komada_tm: 41.599,
    br_elemenata: 14,
  },
  {
    br_cilindra: "13 1/4",
    obim: 336.55,
    br_zubaca: 106.0,
    sirina: 22.437,
    br_komada_tm: 44.57,
    br_elemenata: 15,
  },
  {
    br_cilindra: "13 1/4",
    obim: 336.55,
    br_zubaca: 106.0,
    sirina: 21.034,
    br_komada_tm: 47.541,
    br_elemenata: 16,
  },
  {
    br_cilindra: "13 1/4",
    obim: 336.55,
    br_zubaca: 106.0,
    sirina: 19.797,
    br_komada_tm: 50.513,
    br_elemenata: 17,
  },
  {
    br_cilindra: "13 1/4",
    obim: 336.55,
    br_zubaca: 106.0,
    sirina: 18.697,
    br_komada_tm: 53.484,
    br_elemenata: 18,
  },
  {
    br_cilindra: "13 1/4",
    obim: 336.55,
    br_zubaca: 106.0,
    sirina: 17.713,
    br_komada_tm: 56.455,
    br_elemenata: 19,
  },
  {
    br_cilindra: "13 1/4",
    obim: 336.55,
    br_zubaca: 106.0,
    sirina: 16.828,
    br_komada_tm: 0.0,
    br_elemenata: 20,
  },
  {
    br_cilindra: "13 3/8",
    obim: 339.73,
    br_zubaca: 107,
    sirina: 339.73,
    br_komada_tm: 2.944,
    br_elemenata: 1,
  },
  {
    br_cilindra: "13 3/8",
    obim: 339.73,
    br_zubaca: 107,
    sirina: 169.865,
    br_komada_tm: 5.887,
    br_elemenata: 2,
  },
  {
    br_cilindra: "13 3/8",
    obim: 339.73,
    br_zubaca: 107,
    sirina: 113.243,
    br_komada_tm: 8.831,
    br_elemenata: 3,
  },
  {
    br_cilindra: "13 3/8",
    obim: 339.73,
    br_zubaca: 107,
    sirina: 84.933,
    br_komada_tm: 11.774,
    br_elemenata: 4,
  },
  {
    br_cilindra: "13 3/8",
    obim: 339.73,
    br_zubaca: 107,
    sirina: 67.946,
    br_komada_tm: 14.718,
    br_elemenata: 5,
  },
  {
    br_cilindra: "13 3/8",
    obim: 339.73,
    br_zubaca: 107,
    sirina: 56.622,
    br_komada_tm: 17.661,
    br_elemenata: 6,
  },
  {
    br_cilindra: "13 3/8",
    obim: 339.73,
    br_zubaca: 107,
    sirina: 48.533,
    br_komada_tm: 20.605,
    br_elemenata: 7,
  },
  {
    br_cilindra: "13 3/8",
    obim: 339.73,
    br_zubaca: 107,
    sirina: 42.466,
    br_komada_tm: 23.548,
    br_elemenata: 8,
  },
  {
    br_cilindra: "13 3/8",
    obim: 339.73,
    br_zubaca: 107,
    sirina: 37.748,
    br_komada_tm: 26.492,
    br_elemenata: 9,
  },
  {
    br_cilindra: "13 3/8",
    obim: 339.73,
    br_zubaca: 107,
    sirina: 33.973,
    br_komada_tm: 29.435,
    br_elemenata: 10,
  },
  {
    br_cilindra: "13 3/8",
    obim: 339.73,
    br_zubaca: 107,
    sirina: 30.885,
    br_komada_tm: 32.379,
    br_elemenata: 11,
  },
  {
    br_cilindra: "13 3/8",
    obim: 339.73,
    br_zubaca: 107,
    sirina: 28.311,
    br_komada_tm: 35.322,
    br_elemenata: 12,
  },
  {
    br_cilindra: "13 3/8",
    obim: 339.73,
    br_zubaca: 107,
    sirina: 26.133,
    br_komada_tm: 38.266,
    br_elemenata: 13,
  },
  {
    br_cilindra: "13 3/8",
    obim: 339.73,
    br_zubaca: 107,
    sirina: 24.266,
    br_komada_tm: 41.209,
    br_elemenata: 14,
  },
  {
    br_cilindra: "13 3/8",
    obim: 339.73,
    br_zubaca: 107,
    sirina: 22.649,
    br_komada_tm: 44.153,
    br_elemenata: 15,
  },
  {
    br_cilindra: "13 3/8",
    obim: 339.73,
    br_zubaca: 107,
    sirina: 21.233,
    br_komada_tm: 47.096,
    br_elemenata: 16,
  },
  {
    br_cilindra: "13 3/8",
    obim: 339.73,
    br_zubaca: 107,
    sirina: 19.984,
    br_komada_tm: 50.04,
    br_elemenata: 17,
  },
  {
    br_cilindra: "13 3/8",
    obim: 339.73,
    br_zubaca: 107,
    sirina: 18.874,
    br_komada_tm: 52.983,
    br_elemenata: 18,
  },
  {
    br_cilindra: "13 3/8",
    obim: 339.73,
    br_zubaca: 107,
    sirina: 17.881,
    br_komada_tm: 55.927,
    br_elemenata: 19,
  },
  {
    br_cilindra: "13 3/8",
    obim: 339.73,
    br_zubaca: 107,
    sirina: 16.987,
    br_komada_tm: 58.87,
    br_elemenata: 20,
  },
  {
    br_cilindra: "13 5/8",
    obim: 346.075,
    br_zubaca: 109.0,
    sirina: 346.075,
    br_komada_tm: 2.89,
    br_elemenata: 1,
  },
  {
    br_cilindra: "13 5/8",
    obim: 346.075,
    br_zubaca: 109.0,
    sirina: 173.038,
    br_komada_tm: 5.779,
    br_elemenata: 2,
  },
  {
    br_cilindra: "13 5/8",
    obim: 346.075,
    br_zubaca: 109.0,
    sirina: 115.358,
    br_komada_tm: 8.669,
    br_elemenata: 3,
  },
  {
    br_cilindra: "13 5/8",
    obim: 346.075,
    br_zubaca: 109.0,
    sirina: 86.519,
    br_komada_tm: 11.558,
    br_elemenata: 4,
  },
  {
    br_cilindra: "13 5/8",
    obim: 346.075,
    br_zubaca: 109.0,
    sirina: 69.215,
    br_komada_tm: 14.448,
    br_elemenata: 5,
  },
  {
    br_cilindra: "13 5/8",
    obim: 346.075,
    br_zubaca: 109.0,
    sirina: 57.679,
    br_komada_tm: 17.337,
    br_elemenata: 6,
  },
  {
    br_cilindra: "13 5/8",
    obim: 346.075,
    br_zubaca: 109.0,
    sirina: 49.439,
    br_komada_tm: 20.227,
    br_elemenata: 7,
  },
  {
    br_cilindra: "13 5/8",
    obim: 346.075,
    br_zubaca: 109.0,
    sirina: 43.259,
    br_komada_tm: 23.116,
    br_elemenata: 8,
  },
  {
    br_cilindra: "13 5/8",
    obim: 346.075,
    br_zubaca: 109.0,
    sirina: 38.453,
    br_komada_tm: 26.006,
    br_elemenata: 9,
  },
  {
    br_cilindra: "13 5/8",
    obim: 346.075,
    br_zubaca: 109.0,
    sirina: 34.608,
    br_komada_tm: 28.895,
    br_elemenata: 10,
  },
  {
    br_cilindra: "14",
    obim: 355.6,
    br_zubaca: 112.0,
    sirina: 355.6,
    br_komada_tm: 2.812,
    br_elemenata: 1,
  },
  {
    br_cilindra: "14",
    obim: 355.6,
    br_zubaca: 112.0,
    sirina: 177.8,
    br_komada_tm: 5.624,
    br_elemenata: 2,
  },
  {
    br_cilindra: "14",
    obim: 355.6,
    br_zubaca: 112.0,
    sirina: 118.533,
    br_komada_tm: 8.436,
    br_elemenata: 3,
  },
  {
    br_cilindra: "14",
    obim: 355.6,
    br_zubaca: 112.0,
    sirina: 88.9,
    br_komada_tm: 11.249,
    br_elemenata: 4,
  },
  {
    br_cilindra: "14",
    obim: 355.6,
    br_zubaca: 112.0,
    sirina: 71.12,
    br_komada_tm: 14.061,
    br_elemenata: 5,
  },
  {
    br_cilindra: "14",
    obim: 355.6,
    br_zubaca: 112.0,
    sirina: 59.267,
    br_komada_tm: 16.873,
    br_elemenata: 6,
  },
  {
    br_cilindra: "14",
    obim: 355.6,
    br_zubaca: 112.0,
    sirina: 50.8,
    br_komada_tm: 19.685,
    br_elemenata: 7,
  },
  {
    br_cilindra: "14",
    obim: 355.6,
    br_zubaca: 112.0,
    sirina: 44.45,
    br_komada_tm: 22.497,
    br_elemenata: 8,
  },
  {
    br_cilindra: "14",
    obim: 355.6,
    br_zubaca: 112.0,
    sirina: 39.511,
    br_komada_tm: 25.309,
    br_elemenata: 9,
  },
  {
    br_cilindra: "14",
    obim: 355.6,
    br_zubaca: 112.0,
    sirina: 35.56,
    br_komada_tm: 28.121,
    br_elemenata: 10,
  },
  {
    br_cilindra: "14",
    obim: 355.6,
    br_zubaca: 112.0,
    sirina: 32.327,
    br_komada_tm: 30.934,
    br_elemenata: 11,
  },
  {
    br_cilindra: "14",
    obim: 355.6,
    br_zubaca: 112.0,
    sirina: 29.633,
    br_komada_tm: 33.746,
    br_elemenata: 12,
  },
  {
    br_cilindra: "14",
    obim: 355.6,
    br_zubaca: 112.0,
    sirina: 27.354,
    br_komada_tm: 36.558,
    br_elemenata: 13,
  },
  {
    br_cilindra: "14",
    obim: 355.6,
    br_zubaca: 112.0,
    sirina: 25.4,
    br_komada_tm: 39.37,
    br_elemenata: 14,
  },
  {
    br_cilindra: "14",
    obim: 355.6,
    br_zubaca: 112.0,
    sirina: 23.707,
    br_komada_tm: 42.182,
    br_elemenata: 15,
  },
  {
    br_cilindra: "14",
    obim: 355.6,
    br_zubaca: 112.0,
    sirina: 22.225,
    br_komada_tm: 44.994,
    br_elemenata: 16,
  },
  {
    br_cilindra: "14",
    obim: 355.6,
    br_zubaca: 112.0,
    sirina: 20.918,
    br_komada_tm: 47.807,
    br_elemenata: 17,
  },
  {
    br_cilindra: "14",
    obim: 355.6,
    br_zubaca: 112.0,
    sirina: 19.756,
    br_komada_tm: 50.619,
    br_elemenata: 18,
  },
  {
    br_cilindra: "14",
    obim: 355.6,
    br_zubaca: 112.0,
    sirina: 18.716,
    br_komada_tm: 53.431,
    br_elemenata: 19,
  },
  {
    br_cilindra: "14",
    obim: 355.6,
    br_zubaca: 112.0,
    sirina: 17.78,
    br_komada_tm: 56.243,
    br_elemenata: 20,
  },
  {
    br_cilindra: "14 1/4-2",
    obim: 361.95,
    br_zubaca: 114.0,
    sirina: 361.95,
    br_komada_tm: 2.763,
    br_elemenata: 1,
  },
  {
    br_cilindra: "14 1/4",
    obim: 361.95,
    br_zubaca: 114.0,
    sirina: 361.95,
    br_komada_tm: 2.763,
    br_elemenata: 1,
  },
  {
    br_cilindra: "14 1/4",
    obim: 361.95,
    br_zubaca: 114.0,
    sirina: 180.975,
    br_komada_tm: 5.526,
    br_elemenata: 2,
  },
  {
    br_cilindra: "14 1/4",
    obim: 361.95,
    br_zubaca: 114.0,
    sirina: 120.65,
    br_komada_tm: 8.288,
    br_elemenata: 3,
  },
  {
    br_cilindra: "14 1/4",
    obim: 361.95,
    br_zubaca: 114.0,
    sirina: 90.488,
    br_komada_tm: 11.051,
    br_elemenata: 4,
  },
  {
    br_cilindra: "14 1/4",
    obim: 361.95,
    br_zubaca: 114.0,
    sirina: 72.39,
    br_komada_tm: 13.814,
    br_elemenata: 5,
  },
  {
    br_cilindra: "14 1/4",
    obim: 361.95,
    br_zubaca: 114.0,
    sirina: 60.325,
    br_komada_tm: 16.577,
    br_elemenata: 6,
  },
  {
    br_cilindra: "14 1/4",
    obim: 361.95,
    br_zubaca: 114.0,
    sirina: 51.707,
    br_komada_tm: 19.34,
    br_elemenata: 7,
  },
  {
    br_cilindra: "14 1/4",
    obim: 361.95,
    br_zubaca: 114.0,
    sirina: 45.244,
    br_komada_tm: 22.103,
    br_elemenata: 8,
  },
  {
    br_cilindra: "14 1/4",
    obim: 361.95,
    br_zubaca: 114.0,
    sirina: 40.217,
    br_komada_tm: 24.865,
    br_elemenata: 9,
  },
  {
    br_cilindra: "14 1/4",
    obim: 361.95,
    br_zubaca: 114.0,
    sirina: 36.195,
    br_komada_tm: 27.628,
    br_elemenata: 10,
  },
  {
    br_cilindra: "14 1/2-2",
    obim: 368.3,
    br_zubaca: 116.0,
    sirina: 368.3,
    br_komada_tm: 2.715,
    br_elemenata: 1,
  },
  {
    br_cilindra: "14 1/2",
    obim: 368.3,
    br_zubaca: 116.0,
    sirina: 368.3,
    br_komada_tm: 2.715,
    br_elemenata: 1,
  },
  {
    br_cilindra: "14 1/2",
    obim: 368.3,
    br_zubaca: 116.0,
    sirina: 184.15,
    br_komada_tm: 5.43,
    br_elemenata: 2,
  },
  {
    br_cilindra: "14 1/2",
    obim: 368.3,
    br_zubaca: 116.0,
    sirina: 122.767,
    br_komada_tm: 8.146,
    br_elemenata: 3,
  },
  {
    br_cilindra: "14 1/2",
    obim: 368.3,
    br_zubaca: 116.0,
    sirina: 92.075,
    br_komada_tm: 10.861,
    br_elemenata: 4,
  },
  {
    br_cilindra: "14 1/2",
    obim: 368.3,
    br_zubaca: 116.0,
    sirina: 73.66,
    br_komada_tm: 13.576,
    br_elemenata: 5,
  },
  {
    br_cilindra: "14 1/2",
    obim: 368.3,
    br_zubaca: 116.0,
    sirina: 61.383,
    br_komada_tm: 16.291,
    br_elemenata: 6,
  },
  {
    br_cilindra: "14 1/2",
    obim: 368.3,
    br_zubaca: 116.0,
    sirina: 52.614,
    br_komada_tm: 19.006,
    br_elemenata: 7,
  },
  {
    br_cilindra: "14 1/2",
    obim: 368.3,
    br_zubaca: 116.0,
    sirina: 46.038,
    br_komada_tm: 21.721,
    br_elemenata: 8,
  },
  {
    br_cilindra: "14 1/2",
    obim: 368.3,
    br_zubaca: 116.0,
    sirina: 40.922,
    br_komada_tm: 24.437,
    br_elemenata: 9,
  },
  {
    br_cilindra: "14 1/2",
    obim: 368.3,
    br_zubaca: 116.0,
    sirina: 36.83,
    br_komada_tm: 27.152,
    br_elemenata: 10,
  },
  {
    br_cilindra: "14 1/2",
    obim: 368.3,
    br_zubaca: 116.0,
    sirina: 33.482,
    br_komada_tm: 29.867,
    br_elemenata: 11,
  },
  {
    br_cilindra: "14 1/2",
    obim: 368.3,
    br_zubaca: 116.0,
    sirina: 30.692,
    br_komada_tm: 32.582,
    br_elemenata: 12,
  },
  {
    br_cilindra: "14 1/2",
    obim: 368.3,
    br_zubaca: 116.0,
    sirina: 28.331,
    br_komada_tm: 35.297,
    br_elemenata: 13,
  },
  {
    br_cilindra: "14 1/2",
    obim: 368.3,
    br_zubaca: 116.0,
    sirina: 26.307,
    br_komada_tm: 38.012,
    br_elemenata: 14,
  },
  {
    br_cilindra: "14 1/2",
    obim: 368.3,
    br_zubaca: 116.0,
    sirina: 24.553,
    br_komada_tm: 40.728,
    br_elemenata: 15,
  },
  {
    br_cilindra: "14 1/2",
    obim: 368.3,
    br_zubaca: 116.0,
    sirina: 23.019,
    br_komada_tm: 43.443,
    br_elemenata: 16,
  },
  {
    br_cilindra: "14 1/2",
    obim: 368.3,
    br_zubaca: 116.0,
    sirina: 21.665,
    br_komada_tm: 46.158,
    br_elemenata: 17,
  },
  {
    br_cilindra: "14 1/2",
    obim: 368.3,
    br_zubaca: 116.0,
    sirina: 20.461,
    br_komada_tm: 48.873,
    br_elemenata: 18,
  },
  {
    br_cilindra: "14 1/2",
    obim: 368.3,
    br_zubaca: 116.0,
    sirina: 19.384,
    br_komada_tm: 51.588,
    br_elemenata: 19,
  },
  {
    br_cilindra: "14 1/2",
    obim: 368.3,
    br_zubaca: 116.0,
    sirina: 18.415,
    br_komada_tm: 54.304,
    br_elemenata: 20,
  },
  {
    br_cilindra: "15 1/2-2",
    obim: 393.7,
    br_zubaca: 124.0,
    sirina: 393.7,
    br_komada_tm: 2.54,
    br_elemenata: 1,
  },
  {
    br_cilindra: "15 1/2",
    obim: 393.7,
    br_zubaca: 124.0,
    sirina: 393.7,
    br_komada_tm: 2.54,
    br_elemenata: 1,
  },
  {
    br_cilindra: "15 1/2",
    obim: 393.7,
    br_zubaca: 124.0,
    sirina: 196.85,
    br_komada_tm: 5.08,
    br_elemenata: 2,
  },
  {
    br_cilindra: "15 1/2",
    obim: 393.7,
    br_zubaca: 124.0,
    sirina: 131.233,
    br_komada_tm: 7.62,
    br_elemenata: 3,
  },
  {
    br_cilindra: "15 1/2",
    obim: 393.7,
    br_zubaca: 124.0,
    sirina: 98.425,
    br_komada_tm: 10.16,
    br_elemenata: 4,
  },
  {
    br_cilindra: "15 1/2",
    obim: 393.7,
    br_zubaca: 124.0,
    sirina: 78.74,
    br_komada_tm: 12.7,
    br_elemenata: 5,
  },
  {
    br_cilindra: "15 1/2",
    obim: 393.7,
    br_zubaca: 124.0,
    sirina: 65.617,
    br_komada_tm: 15.24,
    br_elemenata: 6,
  },
  {
    br_cilindra: "15 1/2",
    obim: 393.7,
    br_zubaca: 124.0,
    sirina: 56.243,
    br_komada_tm: 17.78,
    br_elemenata: 7,
  },
  {
    br_cilindra: "15 1/2",
    obim: 393.7,
    br_zubaca: 124.0,
    sirina: 49.213,
    br_komada_tm: 20.32,
    br_elemenata: 8,
  },
  {
    br_cilindra: "15 1/2",
    obim: 393.7,
    br_zubaca: 124.0,
    sirina: 43.744,
    br_komada_tm: 22.86,
    br_elemenata: 9,
  },
  {
    br_cilindra: "15 1/2",
    obim: 393.7,
    br_zubaca: 124.0,
    sirina: 39.37,
    br_komada_tm: 25.4,
    br_elemenata: 10,
  },
  {
    br_cilindra: "15 1/2",
    obim: 393.7,
    br_zubaca: 124.0,
    sirina: 35.791,
    br_komada_tm: 27.94,
    br_elemenata: 11,
  },
  {
    br_cilindra: "15 1/2",
    obim: 393.7,
    br_zubaca: 124.0,
    sirina: 32.808,
    br_komada_tm: 30.48,
    br_elemenata: 12,
  },
  {
    br_cilindra: "15 1/2",
    obim: 393.7,
    br_zubaca: 124.0,
    sirina: 30.285,
    br_komada_tm: 33.02,
    br_elemenata: 13,
  },
  {
    br_cilindra: "15 1/2",
    obim: 393.7,
    br_zubaca: 124.0,
    sirina: 28.121,
    br_komada_tm: 35.56,
    br_elemenata: 14,
  },
  {
    br_cilindra: "15 1/2",
    obim: 393.7,
    br_zubaca: 124.0,
    sirina: 26.247,
    br_komada_tm: 38.1,
    br_elemenata: 15,
  },
  {
    br_cilindra: "15 1/2",
    obim: 393.7,
    br_zubaca: 124.0,
    sirina: 24.606,
    br_komada_tm: 40.64,
    br_elemenata: 16,
  },
  {
    br_cilindra: "15 1/2",
    obim: 393.7,
    br_zubaca: 124.0,
    sirina: 23.159,
    br_komada_tm: 43.18,
    br_elemenata: 17,
  },
  {
    br_cilindra: "15 1/2",
    obim: 393.7,
    br_zubaca: 124.0,
    sirina: 21.872,
    br_komada_tm: 45.72,
    br_elemenata: 18,
  },
  {
    br_cilindra: "15 1/2",
    obim: 393.7,
    br_zubaca: 124.0,
    sirina: 20.721,
    br_komada_tm: 48.26,
    br_elemenata: 19,
  },
  {
    br_cilindra: "15 1/2",
    obim: 393.7,
    br_zubaca: 124.0,
    sirina: 19.685,
    br_komada_tm: 50.8,
    br_elemenata: 20,
  },
  {
    br_cilindra: "16-2",
    obim: 406.4,
    br_zubaca: 128.0,
    sirina: 406.4,
    br_komada_tm: 2.461,
    br_elemenata: 1,
  },
  {
    br_cilindra: "16",
    obim: 406.4,
    br_zubaca: 128.0,
    sirina: 406.4,
    br_komada_tm: 2.461,
    br_elemenata: 1,
  },
  {
    br_cilindra: "16",
    obim: 406.4,
    br_zubaca: 128.0,
    sirina: 203.2,
    br_komada_tm: 4.921,
    br_elemenata: 2,
  },
  {
    br_cilindra: "16",
    obim: 406.4,
    br_zubaca: 128.0,
    sirina: 135.467,
    br_komada_tm: 7.382,
    br_elemenata: 3,
  },
  {
    br_cilindra: "16",
    obim: 406.4,
    br_zubaca: 128.0,
    sirina: 101.6,
    br_komada_tm: 9.843,
    br_elemenata: 4,
  },
  {
    br_cilindra: "16",
    obim: 406.4,
    br_zubaca: 128.0,
    sirina: 81.28,
    br_komada_tm: 12.303,
    br_elemenata: 5,
  },
  {
    br_cilindra: "16",
    obim: 406.4,
    br_zubaca: 128.0,
    sirina: 67.733,
    br_komada_tm: 14.764,
    br_elemenata: 6,
  },
  {
    br_cilindra: "16",
    obim: 406.4,
    br_zubaca: 128.0,
    sirina: 58.057,
    br_komada_tm: 17.224,
    br_elemenata: 7,
  },
  {
    br_cilindra: "16",
    obim: 406.4,
    br_zubaca: 128.0,
    sirina: 50.8,
    br_komada_tm: 19.685,
    br_elemenata: 8,
  },
  {
    br_cilindra: "16",
    obim: 406.4,
    br_zubaca: 128.0,
    sirina: 45.156,
    br_komada_tm: 22.146,
    br_elemenata: 9,
  },
  {
    br_cilindra: "16",
    obim: 406.4,
    br_zubaca: 128.0,
    sirina: 40.64,
    br_komada_tm: 24.606,
    br_elemenata: 10,
  },
  {
    br_cilindra: "16",
    obim: 406.4,
    br_zubaca: 128.0,
    sirina: 36.945,
    br_komada_tm: 27.067,
    br_elemenata: 11,
  },
  {
    br_cilindra: "16",
    obim: 406.4,
    br_zubaca: 128.0,
    sirina: 33.867,
    br_komada_tm: 29.528,
    br_elemenata: 12,
  },
  {
    br_cilindra: "16",
    obim: 406.4,
    br_zubaca: 128.0,
    sirina: 31.262,
    br_komada_tm: 31.988,
    br_elemenata: 13,
  },
  {
    br_cilindra: "16",
    obim: 406.4,
    br_zubaca: 128.0,
    sirina: 29.029,
    br_komada_tm: 34.449,
    br_elemenata: 14,
  },
  {
    br_cilindra: "16",
    obim: 406.4,
    br_zubaca: 128.0,
    sirina: 27.093,
    br_komada_tm: 36.909,
    br_elemenata: 15,
  },
  {
    br_cilindra: "16",
    obim: 406.4,
    br_zubaca: 128.0,
    sirina: 25.4,
    br_komada_tm: 39.37,
    br_elemenata: 16,
  },
  {
    br_cilindra: "16",
    obim: 406.4,
    br_zubaca: 128.0,
    sirina: 23.906,
    br_komada_tm: 41.831,
    br_elemenata: 17,
  },
  {
    br_cilindra: "16",
    obim: 406.4,
    br_zubaca: 128.0,
    sirina: 22.578,
    br_komada_tm: 44.291,
    br_elemenata: 18,
  },
  {
    br_cilindra: "16",
    obim: 406.4,
    br_zubaca: 128.0,
    sirina: 21.389,
    br_komada_tm: 46.752,
    br_elemenata: 19,
  },
  {
    br_cilindra: "16",
    obim: 406.4,
    br_zubaca: 128.0,
    sirina: 20.32,
    br_komada_tm: 49.213,
    br_elemenata: 20,
  },
  {
    br_cilindra: "16 1/2-2",
    obim: 419.1,
    br_zubaca: 132.0,
    sirina: 419.1,
    br_komada_tm: 2.386,
    br_elemenata: 1,
  },
  {
    br_cilindra: "16 1/2",
    obim: 419.1,
    br_zubaca: 132.0,
    sirina: 419.1,
    br_komada_tm: 2.386,
    br_elemenata: 1,
  },
  {
    br_cilindra: "16 1/2",
    obim: 419.1,
    br_zubaca: 132.0,
    sirina: 209.55,
    br_komada_tm: 4.772,
    br_elemenata: 2,
  },
  {
    br_cilindra: "16 1/2",
    obim: 419.1,
    br_zubaca: 132.0,
    sirina: 139.7,
    br_komada_tm: 7.158,
    br_elemenata: 3,
  },
  {
    br_cilindra: "16 1/2",
    obim: 419.1,
    br_zubaca: 132.0,
    sirina: 104.775,
    br_komada_tm: 9.544,
    br_elemenata: 4,
  },
  {
    br_cilindra: "16 1/2",
    obim: 419.1,
    br_zubaca: 132.0,
    sirina: 83.82,
    br_komada_tm: 11.93,
    br_elemenata: 5,
  },
  {
    br_cilindra: "16 1/2",
    obim: 419.1,
    br_zubaca: 132.0,
    sirina: 69.85,
    br_komada_tm: 14.316,
    br_elemenata: 6,
  },
  {
    br_cilindra: "16 1/2",
    obim: 419.1,
    br_zubaca: 132.0,
    sirina: 59.871,
    br_komada_tm: 16.702,
    br_elemenata: 7,
  },
  {
    br_cilindra: "16 1/2",
    obim: 419.1,
    br_zubaca: 132.0,
    sirina: 52.388,
    br_komada_tm: 19.089,
    br_elemenata: 8,
  },
  {
    br_cilindra: "16 1/2",
    obim: 419.1,
    br_zubaca: 132.0,
    sirina: 46.567,
    br_komada_tm: 21.475,
    br_elemenata: 9,
  },
  {
    br_cilindra: "16 1/2",
    obim: 419.1,
    br_zubaca: 132.0,
    sirina: 41.91,
    br_komada_tm: 23.861,
    br_elemenata: 10,
  },
  {
    br_cilindra: "16 1/2",
    obim: 419.1,
    br_zubaca: 132.0,
    sirina: 38.1,
    br_komada_tm: 26.247,
    br_elemenata: 11,
  },
  {
    br_cilindra: "16 1/2",
    obim: 419.1,
    br_zubaca: 132.0,
    sirina: 34.925,
    br_komada_tm: 28.633,
    br_elemenata: 12,
  },
  {
    br_cilindra: "16 1/2",
    obim: 419.1,
    br_zubaca: 132.0,
    sirina: 32.238,
    br_komada_tm: 31.019,
    br_elemenata: 13,
  },
  {
    br_cilindra: "16 1/2",
    obim: 419.1,
    br_zubaca: 132.0,
    sirina: 29.936,
    br_komada_tm: 33.405,
    br_elemenata: 14,
  },
  {
    br_cilindra: "16 1/2",
    obim: 419.1,
    br_zubaca: 132.0,
    sirina: 27.94,
    br_komada_tm: 35.791,
    br_elemenata: 15,
  },
  {
    br_cilindra: "16 1/2",
    obim: 419.1,
    br_zubaca: 132.0,
    sirina: 26.194,
    br_komada_tm: 38.177,
    br_elemenata: 16,
  },
  {
    br_cilindra: "16 1/2",
    obim: 419.1,
    br_zubaca: 132.0,
    sirina: 24.653,
    br_komada_tm: 40.563,
    br_elemenata: 17,
  },
  {
    br_cilindra: "16 1/2",
    obim: 419.1,
    br_zubaca: 132.0,
    sirina: 23.283,
    br_komada_tm: 42.949,
    br_elemenata: 18,
  },
  {
    br_cilindra: "16 1/2",
    obim: 419.1,
    br_zubaca: 132.0,
    sirina: 22.058,
    br_komada_tm: 45.335,
    br_elemenata: 19,
  },
  {
    br_cilindra: "16 1/2",
    obim: 419.1,
    br_zubaca: 132.0,
    sirina: 20.955,
    br_komada_tm: 47.721,
    br_elemenata: 20,
  },
  {
    br_cilindra: "16 7/8",
    obim: 428.63,
    br_zubaca: 135.002,
    sirina: 428.63,
    br_komada_tm: 2.333,
    br_elemenata: 1,
  },
  {
    br_cilindra: "16 7/8",
    obim: 428.63,
    br_zubaca: 135.002,
    sirina: 214.313,
    br_komada_tm: 4.666,
    br_elemenata: 2,
  },
  {
    br_cilindra: "16 7/8",
    obim: 428.63,
    br_zubaca: 135.002,
    sirina: 142.875,
    br_komada_tm: 6.999,
    br_elemenata: 3,
  },
  {
    br_cilindra: "16 7/8",
    obim: 428.63,
    br_zubaca: 135.002,
    sirina: 107.156,
    br_komada_tm: 9.332,
    br_elemenata: 4,
  },
  {
    br_cilindra: "16 7/8",
    obim: 428.63,
    br_zubaca: 135.002,
    sirina: 85.725,
    br_komada_tm: 11.665,
    br_elemenata: 5,
  },
  {
    br_cilindra: "16 7/8",
    obim: 428.63,
    br_zubaca: 135.002,
    sirina: 71.438,
    br_komada_tm: 13.998,
    br_elemenata: 6,
  },
  {
    br_cilindra: "16 7/8",
    obim: 428.63,
    br_zubaca: 135.002,
    sirina: 61.232,
    br_komada_tm: 16.331,
    br_elemenata: 7,
  },
  {
    br_cilindra: "16 7/8",
    obim: 428.63,
    br_zubaca: 135.002,
    sirina: 53.578,
    br_komada_tm: 18.664,
    br_elemenata: 8,
  },
  {
    br_cilindra: "16 7/8",
    obim: 428.63,
    br_zubaca: 135.002,
    sirina: 47.625,
    br_komada_tm: 20.997,
    br_elemenata: 9,
  },
  {
    br_cilindra: "16 7/8",
    obim: 428.63,
    br_zubaca: 135.002,
    sirina: 42.863,
    br_komada_tm: 23.33,
    br_elemenata: 10,
  },
  {
    br_cilindra: "16 7/8",
    obim: 428.63,
    br_zubaca: 135.002,
    sirina: 38.966,
    br_komada_tm: 25.663,
    br_elemenata: 11,
  },
  {
    br_cilindra: "16 7/8",
    obim: 428.63,
    br_zubaca: 135.002,
    sirina: 35.719,
    br_komada_tm: 27.996,
    br_elemenata: 12,
  },
  {
    br_cilindra: "16 7/8",
    obim: 428.63,
    br_zubaca: 135.002,
    sirina: 32.972,
    br_komada_tm: 30.329,
    br_elemenata: 13,
  },
  {
    br_cilindra: "16 7/8",
    obim: 428.63,
    br_zubaca: 135.002,
    sirina: 30.616,
    br_komada_tm: 32.662,
    br_elemenata: 14,
  },
  {
    br_cilindra: "16 7/8",
    obim: 428.63,
    br_zubaca: 135.002,
    sirina: 28.575,
    br_komada_tm: 34.995,
    br_elemenata: 15,
  },
  {
    br_cilindra: "16 7/8",
    obim: 428.63,
    br_zubaca: 135.002,
    sirina: 26.789,
    br_komada_tm: 37.328,
    br_elemenata: 16,
  },
  {
    br_cilindra: "16 7/8",
    obim: 428.63,
    br_zubaca: 135.002,
    sirina: 25.214,
    br_komada_tm: 39.661,
    br_elemenata: 17,
  },
  {
    br_cilindra: "16 7/8",
    obim: 428.63,
    br_zubaca: 135.002,
    sirina: 23.813,
    br_komada_tm: 41.994,
    br_elemenata: 18,
  },
  {
    br_cilindra: "16 7/8",
    obim: 428.63,
    br_zubaca: 135.002,
    sirina: 22.559,
    br_komada_tm: 44.327,
    br_elemenata: 19,
  },
  {
    br_cilindra: "16 7/8",
    obim: 428.63,
    br_zubaca: 135.002,
    sirina: 21.432,
    br_komada_tm: 46.66,
    br_elemenata: 20,
  },
  {
    br_cilindra: "17-2",
    obim: 431.8,
    br_zubaca: 136.0,
    sirina: 431.8,
    br_komada_tm: 2.316,
    br_elemenata: 1,
  },
  {
    br_cilindra: "17",
    obim: 431.8,
    br_zubaca: 136.0,
    sirina: 431.8,
    br_komada_tm: 2.316,
    br_elemenata: 1,
  },
  {
    br_cilindra: "17",
    obim: 431.8,
    br_zubaca: 136.0,
    sirina: 215.9,
    br_komada_tm: 4.632,
    br_elemenata: 2,
  },
  {
    br_cilindra: "17",
    obim: 431.8,
    br_zubaca: 136.0,
    sirina: 143.933,
    br_komada_tm: 6.948,
    br_elemenata: 3,
  },
  {
    br_cilindra: "17",
    obim: 431.8,
    br_zubaca: 136.0,
    sirina: 107.95,
    br_komada_tm: 9.264,
    br_elemenata: 4,
  },
  {
    br_cilindra: "17",
    obim: 431.8,
    br_zubaca: 136.0,
    sirina: 86.36,
    br_komada_tm: 11.579,
    br_elemenata: 5,
  },
  {
    br_cilindra: "17",
    obim: 431.8,
    br_zubaca: 136.0,
    sirina: 71.967,
    br_komada_tm: 13.895,
    br_elemenata: 6,
  },
  {
    br_cilindra: "17",
    obim: 431.8,
    br_zubaca: 136.0,
    sirina: 61.686,
    br_komada_tm: 16.211,
    br_elemenata: 7,
  },
  {
    br_cilindra: "17",
    obim: 431.8,
    br_zubaca: 136.0,
    sirina: 53.975,
    br_komada_tm: 18.527,
    br_elemenata: 8,
  },
  {
    br_cilindra: "17",
    obim: 431.8,
    br_zubaca: 136.0,
    sirina: 47.978,
    br_komada_tm: 20.843,
    br_elemenata: 9,
  },
  {
    br_cilindra: "17",
    obim: 431.8,
    br_zubaca: 136.0,
    sirina: 43.18,
    br_komada_tm: 23.159,
    br_elemenata: 10,
  },
  {
    br_cilindra: "17",
    obim: 431.8,
    br_zubaca: 136.0,
    sirina: 39.255,
    br_komada_tm: 25.475,
    br_elemenata: 11,
  },
  {
    br_cilindra: "17",
    obim: 431.8,
    br_zubaca: 136.0,
    sirina: 35.983,
    br_komada_tm: 27.791,
    br_elemenata: 12,
  },
  {
    br_cilindra: "17",
    obim: 431.8,
    br_zubaca: 136.0,
    sirina: 33.215,
    br_komada_tm: 30.107,
    br_elemenata: 13,
  },
  {
    br_cilindra: "17",
    obim: 431.8,
    br_zubaca: 136.0,
    sirina: 30.843,
    br_komada_tm: 32.422,
    br_elemenata: 14,
  },
  {
    br_cilindra: "17",
    obim: 431.8,
    br_zubaca: 136.0,
    sirina: 28.787,
    br_komada_tm: 34.738,
    br_elemenata: 15,
  },
  {
    br_cilindra: "17",
    obim: 431.8,
    br_zubaca: 136.0,
    sirina: 26.988,
    br_komada_tm: 37.054,
    br_elemenata: 16,
  },
  {
    br_cilindra: "17",
    obim: 431.8,
    br_zubaca: 136.0,
    sirina: 25.4,
    br_komada_tm: 39.37,
    br_elemenata: 17,
  },
  {
    br_cilindra: "17",
    obim: 431.8,
    br_zubaca: 136.0,
    sirina: 23.989,
    br_komada_tm: 41.686,
    br_elemenata: 18,
  },
  {
    br_cilindra: "17",
    obim: 431.8,
    br_zubaca: 136.0,
    sirina: 22.726,
    br_komada_tm: 44.002,
    br_elemenata: 19,
  },
  {
    br_cilindra: "17",
    obim: 431.8,
    br_zubaca: 136.0,
    sirina: 21.59,
    br_komada_tm: 46.318,
    br_elemenata: 20,
  },
  {
    br_cilindra: "17 3/8",
    obim: 441.33,
    br_zubaca: 139.0,
    sirina: 441.325,
    br_komada_tm: 2.266,
    br_elemenata: 1,
  },
  {
    br_cilindra: "17 3/8",
    obim: 441.33,
    br_zubaca: 139.0,
    sirina: 220.663,
    br_komada_tm: 4.532,
    br_elemenata: 2,
  },
  {
    br_cilindra: "17 3/8",
    obim: 441.33,
    br_zubaca: 139.0,
    sirina: 147.108,
    br_komada_tm: 6.798,
    br_elemenata: 3,
  },
  {
    br_cilindra: "17 3/8",
    obim: 441.33,
    br_zubaca: 139.0,
    sirina: 110.331,
    br_komada_tm: 9.064,
    br_elemenata: 4,
  },
  {
    br_cilindra: "17 3/8",
    obim: 441.33,
    br_zubaca: 139.0,
    sirina: 88.265,
    br_komada_tm: 11.33,
    br_elemenata: 5,
  },
  {
    br_cilindra: "17 3/8",
    obim: 441.33,
    br_zubaca: 139.0,
    sirina: 73.554,
    br_komada_tm: 13.595,
    br_elemenata: 6,
  },
  {
    br_cilindra: "17 3/8",
    obim: 441.33,
    br_zubaca: 139.0,
    sirina: 63.046,
    br_komada_tm: 15.861,
    br_elemenata: 7,
  },
  {
    br_cilindra: "17 3/8",
    obim: 441.33,
    br_zubaca: 139.0,
    sirina: 55.166,
    br_komada_tm: 18.127,
    br_elemenata: 8,
  },
  {
    br_cilindra: "17 3/8",
    obim: 441.33,
    br_zubaca: 139.0,
    sirina: 49.036,
    br_komada_tm: 20.393,
    br_elemenata: 9,
  },
  {
    br_cilindra: "17 3/8",
    obim: 441.33,
    br_zubaca: 139.0,
    sirina: 44.133,
    br_komada_tm: 22.659,
    br_elemenata: 10,
  },
  {
    br_cilindra: "17 3/8",
    obim: 441.33,
    br_zubaca: 139.0,
    sirina: 40.12,
    br_komada_tm: 24.925,
    br_elemenata: 11,
  },
  {
    br_cilindra: "17 3/8",
    obim: 441.33,
    br_zubaca: 139.0,
    sirina: 36.777,
    br_komada_tm: 27.191,
    br_elemenata: 12,
  },
  {
    br_cilindra: "17 3/8",
    obim: 441.33,
    br_zubaca: 139.0,
    sirina: 33.948,
    br_komada_tm: 29.457,
    br_elemenata: 13,
  },
  {
    br_cilindra: "17 3/8",
    obim: 441.33,
    br_zubaca: 139.0,
    sirina: 31.523,
    br_komada_tm: 31.723,
    br_elemenata: 14,
  },
  {
    br_cilindra: "17 3/8",
    obim: 441.33,
    br_zubaca: 139.0,
    sirina: 29.422,
    br_komada_tm: 33.989,
    br_elemenata: 15,
  },
  {
    br_cilindra: "17 3/8",
    obim: 441.33,
    br_zubaca: 139.0,
    sirina: 27.583,
    br_komada_tm: 36.254,
    br_elemenata: 16,
  },
  {
    br_cilindra: "17 3/8",
    obim: 441.33,
    br_zubaca: 139.0,
    sirina: 25.96,
    br_komada_tm: 38.52,
    br_elemenata: 17,
  },
  {
    br_cilindra: "17 3/8",
    obim: 441.33,
    br_zubaca: 139.0,
    sirina: 24.518,
    br_komada_tm: 40.786,
    br_elemenata: 18,
  },
  {
    br_cilindra: "17 3/8",
    obim: 441.33,
    br_zubaca: 139.0,
    sirina: 23.228,
    br_komada_tm: 43.052,
    br_elemenata: 19,
  },
  {
    br_cilindra: "17 3/8",
    obim: 441.33,
    br_zubaca: 139.0,
    sirina: 22.066,
    br_komada_tm: 45.318,
    br_elemenata: 20,
  },
  {
    br_cilindra: "19 1/2",
    obim: 495.3,
    br_zubaca: 156.0,
    sirina: 495.3,
    br_komada_tm: 2.019,
    br_elemenata: 1,
  },
  {
    br_cilindra: "19 1/2",
    obim: 495.3,
    br_zubaca: 156.0,
    sirina: 247.65,
    br_komada_tm: 4.038,
    br_elemenata: 2,
  },
  {
    br_cilindra: "19 1/2",
    obim: 495.3,
    br_zubaca: 156.0,
    sirina: 165.1,
    br_komada_tm: 6.057,
    br_elemenata: 3,
  },
  {
    br_cilindra: "19 1/2",
    obim: 495.3,
    br_zubaca: 156.0,
    sirina: 123.825,
    br_komada_tm: 8.076,
    br_elemenata: 4,
  },
  {
    br_cilindra: "19 1/2",
    obim: 495.3,
    br_zubaca: 156.0,
    sirina: 99.06,
    br_komada_tm: 10.095,
    br_elemenata: 5,
  },
  {
    br_cilindra: "19 1/2",
    obim: 495.3,
    br_zubaca: 156.0,
    sirina: 82.55,
    br_komada_tm: 12.114,
    br_elemenata: 6,
  },
  {
    br_cilindra: "19 1/2",
    obim: 495.3,
    br_zubaca: 156.0,
    sirina: 70.757,
    br_komada_tm: 14.133,
    br_elemenata: 7,
  },
  {
    br_cilindra: "19 1/2",
    obim: 495.3,
    br_zubaca: 156.0,
    sirina: 61.913,
    br_komada_tm: 16.152,
    br_elemenata: 8,
  },
  {
    br_cilindra: "19 1/2",
    obim: 495.3,
    br_zubaca: 156.0,
    sirina: 55.033,
    br_komada_tm: 18.171,
    br_elemenata: 9,
  },
  {
    br_cilindra: "19 1/2",
    obim: 495.3,
    br_zubaca: 156.0,
    sirina: 49.53,
    br_komada_tm: 20.19,
    br_elemenata: 10,
  },
  {
    br_cilindra: "24",
    obim: 609.6,
    br_zubaca: 192.0,
    sirina: 609.6,
    br_komada_tm: 1.64,
    br_elemenata: 1,
  },
  {
    br_cilindra: "24",
    obim: 609.6,
    br_zubaca: 192.0,
    sirina: 304.8,
    br_komada_tm: 3.281,
    br_elemenata: 2,
  },
  {
    br_cilindra: "24",
    obim: 609.6,
    br_zubaca: 192.0,
    sirina: 203.2,
    br_komada_tm: 4.921,
    br_elemenata: 3,
  },
  {
    br_cilindra: "24",
    obim: 609.6,
    br_zubaca: 192.0,
    sirina: 152.4,
    br_komada_tm: 6.562,
    br_elemenata: 4,
  },
  {
    br_cilindra: "24",
    obim: 609.6,
    br_zubaca: 192.0,
    sirina: 121.92,
    br_komada_tm: 8.202,
    br_elemenata: 5,
  },
  {
    br_cilindra: "24",
    obim: 609.6,
    br_zubaca: 192.0,
    sirina: 101.6,
    br_komada_tm: 9.843,
    br_elemenata: 6,
  },
  {
    br_cilindra: "24",
    obim: 609.6,
    br_zubaca: 192.0,
    sirina: 87.086,
    br_komada_tm: 11.483,
    br_elemenata: 7,
  },
  {
    br_cilindra: "24",
    obim: 609.6,
    br_zubaca: 192.0,
    sirina: 76.2,
    br_komada_tm: 13.123,
    br_elemenata: 8,
  },
  {
    br_cilindra: "24",
    obim: 609.6,
    br_zubaca: 192.0,
    sirina: 67.733,
    br_komada_tm: 14.764,
    br_elemenata: 9,
  },
  {
    br_cilindra: "24",
    obim: 609.6,
    br_zubaca: 192.0,
    sirina: 60.96,
    br_komada_tm: 16.404,
    br_elemenata: 10,
  },
  {
    br_cilindra: "24",
    obim: 609.6,
    br_zubaca: 192.0,
    sirina: 55.418,
    br_komada_tm: 18.045,
    br_elemenata: 11,
  },
  {
    br_cilindra: "24",
    obim: 609.6,
    br_zubaca: 192.0,
    sirina: 50.8,
    br_komada_tm: 19.685,
    br_elemenata: 12,
  },
  {
    br_cilindra: "24",
    obim: 609.6,
    br_zubaca: 192.0,
    sirina: 46.892,
    br_komada_tm: 21.325,
    br_elemenata: 13,
  },
  {
    br_cilindra: "24",
    obim: 609.6,
    br_zubaca: 192.0,
    sirina: 43.543,
    br_komada_tm: 22.966,
    br_elemenata: 14,
  },
  {
    br_cilindra: "24",
    obim: 609.6,
    br_zubaca: 192.0,
    sirina: 40.64,
    br_komada_tm: 24.606,
    br_elemenata: 15,
  },
  {
    br_cilindra: "24",
    obim: 609.6,
    br_zubaca: 192.0,
    sirina: 38.1,
    br_komada_tm: 26.247,
    br_elemenata: 16,
  },
  {
    br_cilindra: "24",
    obim: 609.6,
    br_zubaca: 192.0,
    sirina: 35.859,
    br_komada_tm: 27.887,
    br_elemenata: 17,
  },
  {
    br_cilindra: "24",
    obim: 609.6,
    br_zubaca: 192.0,
    sirina: 33.867,
    br_komada_tm: 29.528,
    br_elemenata: 18,
  },
  {
    br_cilindra: "24",
    obim: 609.6,
    br_zubaca: 192.0,
    sirina: 32.084,
    br_komada_tm: 31.168,
    br_elemenata: 19,
  },
  {
    br_cilindra: "24",
    obim: 609.6,
    br_zubaca: 192.0,
    sirina: 30.48,
    br_komada_tm: 32.808,
    br_elemenata: 20,
  },
];

export default stepsArray;
