import React, { useEffect, useContext } from "react";
import Axios from "axios";
import { Link, useHistory } from "react-router-dom";
import { LoadingOutlined } from "@ant-design/icons";
import { notification, Button } from "antd";
import { UserContext } from "../../App";
import useAxios from "../../hooks/useAxios";
import Table from "../../components/tables/OfferTable";
import { SERVER_URL } from "../../config";
import moment from "moment";

const TABLE_COLUMN_KEYS = [
  "_id",
  "__v",
  "contacted",
  "gallery",
  "discount",
  "featureImage",
  "follow",
  "views",
  "googleDescription",
  "featuredProduct",
  "keywords",
  "content",
  "gallery",
  "categoryPath",
  "fullUrl",
  "url",
  "comments",
  "attributes",
  "featuredAd",
  "featuredAdUntil",
  "user",
  "declaration",
];

const Offer = () => {
  const currentuser = useContext(UserContext);
  const [data, fetchData] = useAxios("", [], currentuser.data.token, "get");
  const history = useHistory();

  useEffect(() => {
    fetchData(`${SERVER_URL}/offers`, []);
  }, [fetchData]);

  const deleteDataHandler = async (id) => {
    try {
      await Axios.delete(`${SERVER_URL}/offer/${id}`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });
      notification.success({
        message: "Ponuda je obrisana.",
        placement: "bottomRight",
      });
      history.push("/admin");
      history.push("/admin/offers");
    } catch (err) {
      notification.error({
        message: "Problem sa brisanjem.Pokušajte kasnije.",
        placement: "bottomRight",
      });
    }
  };

  let columnKeys;
  if (data.data && data.data.items && data.data.items.length > 0) {
    const keys = Object.keys(data.data.items[0]);
    columnKeys = keys.filter((k) => !TABLE_COLUMN_KEYS.includes(k));
  }

  let tableData = [];
  if (data.data && data.data.items && data.data.items.length > 0) {
    tableData = data.data.items.map((item) => {
      item.createdAt = new Date(item.createdAt).toLocaleString();
      item.updatedAt = new Date(item.updatedAt).toLocaleString();

      if (
        item &&
        item.calculations &&
        item.calculations[0]?.client?.companyName
      ) {
        item.client = item.calculations[0].client.companyName;
      }

      // console.log("createdAt", item.createdAt);
      if (item.createdAt.includes("/")) {
        const date = new Date(item.createdAt);
        // console.log("date", date);

        const day = date.getDate();
        const month = date.getMonth() + 1; // months are 0-indexed, so we add 1
        const year = date.getFullYear();

        // console.log(day, month, year);

        item.cre_date = `${day}.${month}.${year}.`;

        // console.log(item.cre_date);
      } else if (item.createdAt.includes(".")) {
        let date_parts = item.createdAt.split(".");

        // console.log(day, month, year);

        item.cre_date = `${date_parts[0]}.${date_parts[1]}.${date_parts[2]}.`;
      }

      return item;
    });
  }
  // console.log(tableData)
  return (
    <div className="table" style={{ padding: "8px" }}>
      <div className="actions-block">
        <Link to="/admin/new-offer">
          <Button type="primary">Kreiraj ponudu</Button>
        </Link>
      </div>

      <div style={{ textAlign: "center" }}>
        {data.isLoading && (
          <LoadingOutlined
            spin
            style={{ fontSize: "3rem", marginTop: "5rem" }}
          />
        )}
        {!data.isLoading &&
          data.data &&
          data.data.items &&
          data.data.items.length > 0 && (
            <Table
              data={tableData}
              deleteHandler={deleteDataHandler}
              columnKeys={columnKeys}
              title="Ponude"
              editPath="/admin/edit-offer/"
            />
          )}
        {!data.isLoading &&
          data.data &&
          data.data.items &&
          data.data.items.length === 0 && (
            <div>
              <h2>Nema dodatih ponuda.</h2>
            </div>
          )}
      </div>
    </div>
  );
};

export default Offer;
