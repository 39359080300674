import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { Table, Popconfirm, Input, Button } from 'antd';
import { EditOutlined, DeleteOutlined, EyeOutlined, SearchOutlined } from '@ant-design/icons';
import { UserContext } from '../../App';
import ResizableAntdTable from 'resizable-antd-table';

const UsersTable = ({ data, deleteHandler, columnKeys, title, editPath, viewPath }) => {
    const user = useContext(UserContext);
    let searchInput;

    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
            return (
                <div style={{ padding: 8 }}>
                    <Input
                        ref={(node) => {
                            searchInput = node;
                        }}
                        placeholder={`Pretrazi ${dataIndex}`}
                        value={selectedKeys[0]}
                        onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                        onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        style={{ width: 188, marginBottom: 8, display: 'block' }}
                    />
                    <Button type='primary' onClick={() => handleSearch(selectedKeys, confirm, dataIndex)} size='small' style={{ width: 90, marginRight: 8 }}>
                        Pretrazi
                    </Button>
                    <Button onClick={() => handleReset(clearFilters)} size='small' style={{ width: 90 }}>
                        Resetuj
                    </Button>
                </div>
            );
        },
        filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? 'red' : '#bbb', fontSize: '1rem' }} />,
        onFilter: (value, record) => {
            const filterRecord = Array.isArray(dataIndex) ? record[dataIndex[0]][dataIndex[1]] : record[dataIndex];
            if (!filterRecord || !value) return false;
            return filterRecord.toString().toLowerCase().includes(value.toLowerCase());
        },
        onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
                setTimeout(() => {
                    searchInput.select();
                });
            }
        },
    });

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
    };

    const handleReset = (clearFilters) => {
        clearFilters();
    };

    const sortTable = (a, b, title) => {

        if(a[title] > b[title]){
            return 1;
        }
        return -1;
    }

    const sortTableDate = (a, b, title) => {
      if (a[title].dateTime > b[title].dateTime) {
        return 1;
      }
      return -1;
    };

    const columns = [
        {
            key: 'companyName',
            title: 'NAZIV KLIJENTA',
            dataIndex: 'companyName',
            ...getColumnSearchProps('companyName'),
            sorter: (a, b) => sortTable(a, b, 'companyName'),
        },
        {
            key: 'status',
            title: 'STATUS',
            dataIndex: 'status',
            ...getColumnSearchProps('status'),
            sorter: (a, b) => sortTable(a, b, 'status')
        },
        {
            key: 'userName',
            title: 'PRODAVAC',
            dataIndex: 'userName',
            ...getColumnSearchProps('userName'),
        },
        {
            key: 'lastContactDate',
            title: 'DATUM POSLEDNJE RADNE AKTIVNOSTI',
            dataIndex: 'lastContactDate',
            ...getColumnSearchProps('lastContactDate'),
            sorter: (a, b) => sortTableDate(a, b, 'contacts'),
        },
        {
            key: 'comment',
            title: 'KOMENTAR POSLEDNJE RADNE AKTIVNOSTI',
            dataIndex: 'comment',
            ...getColumnSearchProps('comment'),
        }
    ];
    columns.push({
        title: 'Opcije',
        render: (text, record) => (
            <div style={{ display: 'inline-flex', justifyContent: 'center', alignItems: 'center', fontSize: '15px' }}>
                <div style={{ margin: '2px', padding: '4px', cursor: 'pointer' }}>
                    <Link to={editPath + (record.contacts.length > 0 ? record.contacts[0]._id : '')}>
                        <EditOutlined title={`Edit ${title.toLowerCase()}`} style={{ textDecoration: 'none', color: 'black' }} />
                    </Link>
                </div>
                {viewPath && (
                  <div style={{ margin: '2px', padding: '4px', cursor: 'pointer' }}>
                      <Link to={viewPath + (record.contacts.length > 0 ? record.contacts[0]._id : '')}>
                          <EyeOutlined title={`View ${title.toLowerCase()}`} style={{ textDecoration: 'none', color: 'black' }} />
                      </Link>
                  </div>
                )}
                <div style={{ margin: '2px', padding: '4px' }}>
                    <Popconfirm
                        disabled={record.contacts.length === 0}
                        placement='left'
                        title={`Ovo ce obrisati radnu aktivnost`}
                        onConfirm={() => {
                            deleteHandler(record.contacts[0]._id);
                        }}
                        okText='Ok'
                        cancelText='Cancel'
                    >
                        <DeleteOutlined title={`Delete ${title.toLowerCase()}`} />
                    </Popconfirm>
                </div>
            </div>
        ),
    });

    data.sort((a, b) => new Date(b.contacts[0].updatedAt).getTime() - new Date(a.contacts[0].updatedAt).getTime());
    
    return (
        <div>
            <ResizableAntdTable
                size='middle'
                bordered
                dataSource={data}
                columns={columns}
                rowClassName={(record,index) => record.status === "AKTIVAN" ? 'table-row-client-active'
                    : record.status === "NEZAINTERESOVAN" ? 'table-row-client-uninterested' : record.status === "USPOSTAVLJEN KONTAKT" ? 'table-row-client-contacted'
                        : record.status === "POSLATA PONUDA" ? 'table-row-client-offer-sent' : 'table-row-client-uncontacted'}
                rowKey='_id'
                pagination={{
                    defaultPageSize: 100,
                    position: 'bottom',
                    showSizeChanger: false,
                    pageSizeOptions: ['10', '20', '50', '100'],
                    hideOnSinglePage: true,
                }}
            />
        </div>
    );
};

export default UsersTable;
