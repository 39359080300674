import React from 'react';
import { Route, Redirect } from 'react-router-dom';
// import { useSelector, shallowEqual } from 'react-redux';

const ProtectedRoute = ({ component: Component, allowed, user, ...rest }) => {
    const userRoles = user && user.data && user.data.role.sort((a, b) => (a.trim().toLowerCase() > b.trim().toLowerCase() ? 1 : -1));
    const allowedRoles = allowed && allowed.sort((a, b) => (a.trim().toLowerCase() > b.trim().toLowerCase() ? 1 : -1));

    return (
        <Route
            {...rest}
            render={(props) => user && user.data && userRoles && allowedRoles && allowedRoles.some((item) => userRoles.includes(item))  ? ( // if signed in and user's role is allowed
                    <Component {...props} /> // render component
                ) : (
                    user && !user.data && props.location.pathname.includes('email-verification') ?
                        <Component {...props}/> :
                        user && user.data ? <Redirect to='/admin'/> :
                        <Redirect to='/login'/>

                )
            }
        />
    );
};
export default ProtectedRoute;
