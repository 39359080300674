const machineSteps = [
  {
    name: "Arsoma",
    values: [
      231.78, 247.65, 254, 257.18, 266.7, 273.05, 279.4, 285.75, 292.1, 304.8,
      311.15, 314.33, 323.85, 327.03, 330.2, 336.55, 339.73, 346.075, 355.6,
      361.95, 368.3, 374.65, 393.7, 406.4, 419.1, 428.63, 431.8, 441.33, 609.6,
    ],
  },
  {
    name: "Mark Andy",
    values: [
      203.2, 222.25, 228.6, 234.95, 241.3, 279.4, 304.8, 311.15, 323.85, 330.2,
      361.95, 282.58, 141.288, 94.192, 70.644, 56.515, 47.096, 40.368, 35.322,
      31.397, 28.258,
    ],
  },
  {
    name: "MPS",
    values: [
      377.83, 279.4, 254, 266.7, 276.23, 285.75, 288.93, 304.8, 307.98, 311.15,
      314.33, 327.03, 336.55, 346.075, 361.95, 368.3, 393.7, 406.4, 419.1,
      431.8, 495.3, 609.6, 463.55, 231.775, 154.517, 115.888, 92.71, 77.258,
      66.221, 57.944, 51.506, 46.355, 188.913, 125.942, 94.456, 75.565, 62.971,
      53.975, 47.228, 41.981, 37.783, 155.575, 103.717, 77.788, 62.23, 51.858,
      44.45, 38.894, 34.572, 31.115,
    ],
  },
  {
    name: "Grafotronic",
    values: [
      254, 266.7, 276.23, 285.75, 288.93, 304.8, 307.98, 314.33, 327.03, 336.55,
      346.075, 361.95, 368.3, 393.7, 406.4, 419.1, 431.8, 495.3, 609.6,
    ],
  },
  {
    name: "Konica Minolta Rolna",
    values: [
      203.2, 282.58, 222.25, 228.6, 231.78, 234.95, 241.3, 247.65, 254, 257.18,
      266.7, 273.05, 276.23, 279.4, 285.75, 288.93, 292.1, 304.8, 307.98,
      311.15, 314.33, 323.85, 327.03, 330.2, 336.55, 339.73, 346.075, 355.6,
      361.95, 368.3, 374.65, 393.7, 406.4, 419.1, 428.63, 431.8, 441.33, 495.3,
      609.6, 346.08, 377.83, 463.55,
    ],
  },
];

export default machineSteps;
