import React, { useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import { Avatar, Button, Layout, Tabs, Typography, Card } from 'antd';
import { EditOutlined, LoadingOutlined } from '@ant-design/icons';
import { UserContext } from '../../App';
import useAxios from '../../hooks/useAxios';
import { SERVER_URL } from '../../config';
import { UserLog } from '../../components/forms';
import moment from 'moment';
import Table from "../../components/tables/ContactTable";
import ExportExcel from "../../components/csv/ExportExcel";

const { Content, Sider } = Layout;
const { Title, Text } = Typography;
const { TabPane } = Tabs;

const TABLE_COLUMN_KEYS = [
  '_id',
  '__v',
];

const ViewUser = (props, editPath) => {
  const { id } = props.match.params;
  const currentuser = useContext(UserContext);
  const [user, fetchUser] = useAxios('', null, currentuser.data.token, 'get');
  const [contact, fetchContact] = useAxios('', null, currentuser.data.token, 'get');
  const [log, fetchLog] = useAxios('', [], currentuser.data.token, 'get');
  const [csv, fetchCSV] = useAxios('', null, currentuser.data.token, 'get');

  useEffect(() => {
    if (id) {
      fetchUser(`${SERVER_URL}/users/${id}`, []);
      fetchLog(`${SERVER_URL}/logs/${id}`, []);
      fetchContact(`${SERVER_URL}/activities/by-user/${id}`, []);
    }
  }, [id, fetchUser, fetchLog, fetchContact]);

  const getUrl = (itemUrl) => {
    let url = itemUrl.includes('http') ? itemUrl : SERVER_URL + itemUrl;
    return url;
  };

  useEffect(() => {
    if (id) {
      let sevenDayBefor = moment().subtract(7, 'day').format('YYYY-MM-DD');
      let sevenDayBeforGet = moment(sevenDayBefor).format('YYYY-MM-DD');
      let from = sevenDayBeforGet;
      let to = new Date();
      let fromTo = from + '||' + to;
      fetchCSV(`${SERVER_URL}/logsCSV/${id}/${JSON.stringify(fromTo)}`, []);
    }
  }, [id, fetchCSV]);

  const updateUser = async (data) => {
    if (data) {
      let fromTo = data.from + '||' + data.to;
      fetchCSV(`${SERVER_URL}/logsCSV/${id}/${JSON.stringify(fromTo)}`, []);
    }
  };

  let columnKeys;
  if (contact.data && contact.data.items && contact.data.items.length > 0) {
    const keys = Object.keys(contact.data.items[0]);
    columnKeys = keys.filter((k) => !TABLE_COLUMN_KEYS.includes(k));
  }
  let tableData = [];
  const dateOptions = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' };
  if (contact.data && contact.data.items && contact.data.items.length > 0) {
    tableData = contact.data.items.map((item) => {
      item.client = item.clientId ? item.clientId.companyName : '';
      item.date = new Date(item.dateTime).toLocaleDateString('sr-Latn-RS', dateOptions);
      item.updatedAt = new Date(item.updatedAt).toLocaleDateString();
      return item;
    });
  }

  if (tableData){
    tableData.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime())
  }
  // console.log(contact)
  return (
    <div className='content-wrapper'>
      <div className='actions-block'>
        <Link to='/admin/users'>
          <Button type='primary'>Svi prodavci</Button>
        </Link>
      </div>

      <div className='content content-full-height'>
        {id && user.isLoading && <LoadingOutlined className='loader' spin />}
        {id && !user.isLoading && user.isError && <h2 style={{ marginTop: '5rem' }}>Something went wrong :(</h2>}
        {id && user && !user.isLoading && !user.isError && (
          <Layout className='site-layout-background'>
            <Sider className='site-layout-background' width={300} theme='light'>
              <div className='logo' style={{ height: 'auto' }}>
                <Avatar size={100} src={user.data && user.data.avatar ? getUrl(user.data.avatar.url) : 'Logo'} />

                <Link to={'/admin/edit-user/' + id}>
                  <Button disabled={currentuser.data.id !== id && !currentuser.data.role.includes('admin')} type='primary' block icon={<EditOutlined />} style={{ marginTop: '1rem' }}>
                    EDITUJ PRODAVCA
                  </Button>
                </Link>
              </div>
              <Content style={{ padding: '15px', minHeight: 280 }}>
                <Title style={{ fontSize: '14px' }}> ROLA</Title>
                <p style={{ display: 'block', fontSize: '14px', margin: '0 0 15px', textTransform: 'capitalize' }}> {user.data ? user.data.role[0] : 'Role'}</p>
                <Title style={{ fontSize: '14px' }}> E-MAIL</Title>
                <p style={{ display: 'block', fontSize: '14px', margin: '0 0 15px' }}> {user.data ? user.data.email : 'Email'}</p>
              </Content>
            </Sider>
            <Content style={{ padding: '0 15px', minHeight: 280 }}>
              <Tabs defaultActiveKey='1' /* onChange={callback} */>
                <TabPane tab='PROFIL' key='1'>
                  <div className='card-wrapper'>
                    <Card className="profile-title" title='Podaci prodavca' bordered={false}>
                      <p>
                        <Text strong>Ime: </Text>
                        {user.data && user.data.firstName ? user.data.firstName : 'Nema podataka'}
                      </p>
                      <p>
                        <Text strong>Prezime: </Text>
                        {user.data && user.data.lastName ? user.data.lastName : 'Nema podataka'}
                      </p>
                      <p>
                        <Text strong>E-mail: </Text>
                        {user.data && user.data.email ? user.data.email : 'Nema podataka'}
                      </p>
                      <p>
                        <Text strong>Adresa: </Text>
                        {user.data && user.data.address ? user.data.address : 'Nema podataka'}
                      </p>
                      <p>
                        <Text strong>Grad: </Text>
                        {user.data && user.data.city ? user.data.city : 'Nema podataka'}
                      </p>
                      <p>
                        <Text strong>Rola: </Text>
                        {user.data ? user.data.role[0] : 'Nema podataka'}
                      </p>
                      <p>
                        <Text strong>Status: </Text>
                        {user.data && user.data.status ? user.data.status : 'Nema podataka'}
                      </p>
                      <p>
                        <Text strong>Istorija radnih aktivnosti: </Text>
                      </p>
                      <div style={{ overflowX: 'auto' }}>
                        <Table
                          id={id}
                          title='Contact'
                          type='user'
                          viewPath='/admin/view-contact/'
                          columnKeys={columnKeys}
                          data={tableData}
                        />
                      </div>
                    </Card>
                  </div>
                </TabPane>
                <TabPane tab='LOGS' key='3'>
                  {log.data.length > 0 ? <UserLog data={log.data} userId={id} updateHandler={updateUser} csv={csv.data} /> : 'No Data'}
                </TabPane>
              </Tabs>
            </Content>
          </Layout>
        )}
      </div>
    </div>
  );
};

export default ViewUser;
