import React, { useState } from "react";
import { Tabs, Card, Input, Button, Form, Switch } from "antd";

const layout = {
  labelCol: { span: 5 },
  wrapperCol: { span: 9 },
};

const TypeOfTransportForm = ({ isNew, data, onSubmit }) => {
  const [form] = Form.useForm();

  let initialValues = { ...data };

  const onFinish = async (values, isNew) => {
    if (!isNew) {
      values._id = data._id;
    }
    onSubmit(values, isNew);
  };

  return (
    <div className="panel panel-body ">
      <div className="panel-body">
        <Card
          title={`${isNew ? "NOVI TIP TRANSPORTA" : "IZMENA TIPA TRANSPORTA"}`}
        >
          <Form
            {...layout}
            initialValues={initialValues}
            onFinish={(values) => onFinish(values, !data)}
            layout="vertical"
            form={form}
          >
            <div className="mainCont">
              <Form.Item
                required
                name="code"
                className="ant-form-item-control-input-content"
                label="Šifra tipa transporta:"
              >
                <Input />
              </Form.Item>
            </div>

            <Tabs forceRender defaultActiveKey="1">
              <Tabs.TabPane
                forceRender
                tab="Osnove informacije o tipu transporta"
                key="1"
              >
                <div>
                  <div className="panel-body">
                    <Form.Item
                      required
                      className="formitemANTD"
                      name="name"
                      label="Naziv tipa transporta:"
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item
                      required
                      className="formitemANTD"
                      name="isPaying"
                      label="Da li se naplaćuje?"
                    >
                      <Switch defaultChecked={initialValues.isPaying} />
                    </Form.Item>
                  </div>
                </div>
              </Tabs.TabPane>
            </Tabs>

            <div className="text-right">
              <Button type="primary" htmlType="submit">
                {isNew ? "Dodaj" : "Izmeni"} tip transporta
              </Button>
            </div>
          </Form>
        </Card>
      </div>
    </div>
  );
};

export default TypeOfTransportForm;
