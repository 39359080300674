import React, { useContext, useState, useEffect } from "react";
import CalculationViewForm from "../../components/forms/CalculationViewForm";
import useAxios from "../../hooks/useAxios";
import { Form, notification } from "antd";
import { UserContext } from "../../App";
import { SERVER_URL } from "../../config";
import { useHistory } from "react-router-dom";
import Axios from "axios";
import {
  printingStepsOne,
  printingStepsTwo,
} from "../../components/base/PrintingSteps";
import machineSteps from "./MachineSteps";
import stepsArray from "./StepsArray";

const EditCalculation = (props) => {
  const currentuser = useContext(UserContext);
  const token = currentuser.data.token;
  const history = useHistory();
  const { calculationId } = props.match.params;

  const [calculation, fetchCalculation] = useAxios("", null, token, "get");

  const [printStepData, setPrintStepData] = useState({
    assignWidth: 0,
    widthTable: 0,
    numberOfItems: 0,
    numberOfCylinders: 0,
    volume: 0,
  });

  useEffect(() => {
    if (calculationId) {
      fetchCalculation(`${SERVER_URL}/calculations/${calculationId}`, []);
    }
  }, [fetchCalculation, calculationId]);

  useEffect(() => {
    document.getElementById("assignWidth").innerText =
      printStepData.assignWidth;
    document.getElementById("widthTable").innerText = printStepData.widthTable;
    document.getElementById("numberOfItems").innerText = String(
      printStepData.numberOfItems,
    );
    document.getElementById("numberOfCylinders").innerText =
      printStepData.numberOfCylinders;
    document.getElementById("volume").innerText = printStepData.volume;
  }, [printStepData]);

  const calculateMinWidth = (steps, width) => {
    let minWidth = Math.abs(steps[0].steps[0].width - width);
    let n = 0,
      m = 0,
      p = 0,
      q = 0;
    for (let i = 0; i < steps.length; i++) {
      for (let j = 0; j < steps[i].steps.length; j++) {
        // if ((steps[i].steps[j].width - width < minWidth || steps[i].steps[j].width === 180.975) && steps[i].steps[j].width >= width) {
        if (
          steps[i].steps[j].width - width < minWidth &&
          steps[i].steps[j].width >= width
        ) {
          minWidth = steps[i].steps[j].width - width;
          n = i;
          m = j;
        }
      }
    }
    return { minWidth, n, m };
  };

  const onChangeLabelWidth = async (width, formData, typeOfLabelId) => {
    if (formData.machine && typeOfLabelId && width) {
      const resMachine = await Axios.get(
        `${SERVER_URL}/machine/${formData.machine}`,
        {
          withCredentials: false,
          headers: { Authorization: `Bearer ${token}` },
        },
      );
      const resTypeOfLabel = await Axios.get(
        `${SERVER_URL}/type-of-label/${typeOfLabelId}`,
        {
          withCredentials: false,
          headers: { Authorization: `Bearer ${token}` },
        },
      );

      const machine = resMachine.data;
      const typeOfLabel = resTypeOfLabel.data;
      const widthPlusTwo = parseFloat(width) + 2.5;

      const index = machineSteps.findIndex(
        (step) => step.name === machine.name,
      );

      if (machine.name === "Mark Andy" || machine.name === "Arsoma") {
        if (
          typeOfLabel.name === "Samolepljivi papir" ||
          typeOfLabel.name === "Samolepljivi termo ECO" ||
          typeOfLabel.name === "Samolepljivi termo TOP" ||
          typeOfLabel.name === "Samolepljivi PP"
        ) {
          let findedStep;
          let findedSteps = [];
          for (const step of stepsArray) {
            if (
              ((step.br_cilindra === "8" ||
                step.br_cilindra === "8 3/4" ||
                step.br_cilindra === "9" ||
                step.br_cilindra === "11" ||
                step.br_cilindra === "11 1/8" ||
                step.br_cilindra === "12" ||
                step.br_cilindra === "12 1/4" ||
                step.br_cilindra === "13" ||
                step.br_cilindra === "14 1/4") &&
                step.sirina > widthPlusTwo) ||
              (machine === "Mark Andy" &&
                width === 150 &&
                typeOfLabel.name === "Samolepljivi PP" &&
                step.sirina > width)
            ) {
              findedSteps.push(step);
            }
          }
          findedSteps.sort((a, b) =>
            a.sirina > b.sirina ? 1 : b.sirina > a.sirina ? -1 : 0,
          );
          findedStep = findedSteps[0];

          console.log(findedSteps);

          let { minWidth, n, m } = calculateMinWidth(
            printingStepsOne,
            widthPlusTwo,
          );

          if (
            minWidth < findedStep?.sirina - widthPlusTwo &&
            machineSteps[index]?.values.includes(
              printingStepsOne[n].maxWidth,
            ) &&
            width
          ) {
            document.getElementById("assignWidth").innerText = widthPlusTwo;
            document.getElementById("widthTable").innerText =
              printingStepsOne[n].steps[m].width;
            document.getElementById("numberOfItems").innerText =
              printingStepsOne[n].steps[m].numberOfItems;
            document.getElementById("numberOfCylinders").innerText = (
              parseFloat(printingStepsOne[n].maxWidth) / 3.175
            ).toFixed(0);
            document.getElementById("volume").innerText =
              printingStepsOne[n].maxWidth;
          } else if (
            machineSteps[index]?.values.includes(
              parseFloat(findedStep?.obim),
            ) &&
            width
          ) {
            document.getElementById("assignWidth").innerText = widthPlusTwo;
            document.getElementById("widthTable").innerText = findedStep.sirina;
            document.getElementById("numberOfItems").innerText =
              findedStep.br_komada_tm;
            document.getElementById("numberOfCylinders").innerText = (
              parseFloat(findedStep.obim) / 3.175
            ).toFixed(3);
            document.getElementById("volume").innerText = findedStep.obim;
          } else {
            document.getElementById("assignWidth").innerText = 0;
            document.getElementById("widthTable").innerText = 0;
            document.getElementById("numberOfItems").innerText = 0;
            document.getElementById("numberOfCylinders").innerText = 0;
            document.getElementById("volume").innerText = 0;
          }
        } else {
          let findedStep;
          let findedSteps = [];
          for (const step of stepsArray) {
            if (step.sirina >= width) {
              findedSteps.push(step);
            }
          }
          findedSteps.sort((a, b) =>
            a.sirina > b.sirina ? 1 : b.sirina > a.sirina ? -1 : 0,
          );
          findedStep = findedSteps[0];
          let { minWidth, n, m } =
            findedStep?.br_cilindra === "12 7/8"
              ? calculateMinWidth(printingStepsOne, width)
              : calculateMinWidth(printingStepsTwo, width);
          if (
            findedStep?.br_cilindra === "12 7/8" &&
            machineSteps[index].values.includes(printingStepsOne[n].maxWidth)
          ) {
            document.getElementById("assignWidth").innerText = width;
            document.getElementById("widthTable").innerText =
              printingStepsOne[n].steps[m].width;
            document.getElementById("numberOfItems").innerText =
              printingStepsOne[n].steps[m].numberOfItems;
            document.getElementById("numberOfCylinders").innerText = parseFloat(
              printingStepsOne[n].maxWidth,
            ).toFixed(0);
            document.getElementById("volume").innerText =
              printingStepsOne[n].maxWidth;
          } else if (
            minWidth < findedStep?.sirina - width &&
            machineSteps[index].values.includes(printingStepsTwo[n].maxWidth)
          ) {
            document.getElementById("assignWidth").innerText = width;
            document.getElementById("widthTable").innerText =
              printingStepsTwo[n].steps[m].width;
            document.getElementById("numberOfItems").innerText =
              printingStepsTwo[n].steps[m].numberOfItems;
            document.getElementById("numberOfCylinders").innerText = parseFloat(
              printingStepsTwo[n].maxWidth,
            ).toFixed(0);
            document.getElementById("volume").innerText =
              printingStepsTwo[n].maxWidth;
          } else if (
            machineSteps[index]?.values.includes(parseFloat(findedStep?.obim))
          ) {
            document.getElementById("assignWidth").innerText = width;
            document.getElementById("widthTable").innerText = findedStep.sirina;
            document.getElementById("numberOfItems").innerText =
              findedStep.br_komada_tm;
            document.getElementById("numberOfCylinders").innerText = (
              parseFloat(findedStep.obim) / 3.175
            ).toFixed(0);
            document.getElementById("volume").innerText = findedStep.obim;
          } else {
            document.getElementById("assignWidth").innerText = 0;
            document.getElementById("widthTable").innerText = 0;
            document.getElementById("numberOfItems").innerText = 0;
            document.getElementById("numberOfCylinders").innerText = 0;
            document.getElementById("volume").innerText = 0;
          }
        }
      } else if (machine.name === "Konica Minolta Rolna") {
        let findedStep;
        let findedSteps = [];
        for (const step of stepsArray) {
          if (typeOfLabel.name.toLowerCase().includes("etiketni")) {
            if (
              (step.br_cilindra === "11" ||
                step.br_cilindra === "10 1/8" ||
                step.br_cilindra === "11 1/8" ||
                step.br_cilindra === "12 3/4" ||
                step.br_cilindra === "11 1/2" ||
                step.br_cilindra === "14 1/4-2" ||
                step.br_cilindra === "17 3/8" ||
                step.br_cilindra === "14 1/2-2" ||
                step.br_cilindra === "15 1/2-2" ||
                step.br_cilindra === "14 3/4" ||
                step.br_cilindra === "14 7/8" ||
                step.br_cilindra === "18 1/4-2" ||
                step.br_cilindra === "13 3/8" ||
                step.br_cilindra === "16-2" ||
                step.br_cilindra === "16 7/8" ||
                step.br_cilindra === "16 1/2-2" ||
                step.br_cilindra === "17" ||
                step.br_cilindra === "14" ||
                step.br_cilindra === "17-2" ||
                step.br_cilindra === "13 5/8" ||
                step.br_cilindra === "13 1/4-2" ||
                step.br_cilindra === "12" ||
                step.br_cilindra === "13") &&
              step.sirina >= parseFloat(width)
            ) {
              findedSteps.push(step);
            }
          } else {
            if (
              (step.br_cilindra === "11" ||
                step.br_cilindra === "10 1/8" ||
                step.br_cilindra === "11 1/8" ||
                step.br_cilindra === "12 3/4" ||
                step.br_cilindra === "11 1/2" ||
                step.br_cilindra === "14 1/4-2" ||
                step.br_cilindra === "17 3/8" ||
                step.br_cilindra === "14 1/2-2" ||
                step.br_cilindra === "15 1/2-2" ||
                step.br_cilindra === "14 3/4" ||
                step.br_cilindra === "14 7/8" ||
                step.br_cilindra === "18 1/4-2" ||
                step.br_cilindra === "13 3/8" ||
                step.br_cilindra === "16-2" ||
                step.br_cilindra === "16 7/8" ||
                step.br_cilindra === "16 1/2-2" ||
                step.br_cilindra === "14" ||
                step.br_cilindra === "17" ||
                step.br_cilindra === "17-2" ||
                step.br_cilindra === "13 5/8" ||
                step.br_cilindra === "13 1/4-2" ||
                step.br_cilindra === "12" ||
                step.br_cilindra === "13") &&
              step.sirina >= widthPlusTwo - 0.01
            ) {
              findedSteps.push(step);
            }
          }
        }

        findedSteps.sort((a, b) =>
          a.sirina > b.sirina ? 1 : b.sirina > a.sirina ? -1 : 0,
        );
        console.log(findedSteps);

        findedStep = findedSteps[0];

        let allPrintSteps = printingStepsOne.concat(printingStepsTwo);
        let { minWidth, n, m } = calculateMinWidth(allPrintSteps, widthPlusTwo);
        if (
          minWidth < findedStep?.sirina - widthPlusTwo &&
          machineSteps[index]?.values.includes(allPrintSteps[n].maxWidth) &&
          width
        ) {
          document.getElementById("assignWidth").innerText =
            widthPlusTwo.toFixed(3);
          document.getElementById("widthTable").innerText =
            allPrintSteps[n].steps[m].width;
          document.getElementById("numberOfItems").innerText =
            allPrintSteps[n].steps[m].numberOfItems;
          document.getElementById("numberOfCylinders").innerText = (
            parseFloat(allPrintSteps[n].maxWidth) / 3.175
          ).toFixed(0);
          document.getElementById("volume").innerText =
            allPrintSteps[n].maxWidth;
        } else if (
          machineSteps[index]?.values.includes(parseFloat(findedStep?.obim)) &&
          width
        ) {
          document.getElementById("assignWidth").innerText =
            widthPlusTwo.toFixed(3);
          document.getElementById("widthTable").innerText = findedStep.sirina;
          document.getElementById("numberOfItems").innerText =
            findedStep.br_komada_tm;
          document.getElementById("numberOfCylinders").innerText = (
            parseFloat(findedStep.obim) / 3.175
          ).toFixed(0);
          document.getElementById("volume").innerText = findedStep.obim;
        } else {
          document.getElementById("assignWidth").innerText = 0;
          document.getElementById("widthTable").innerText = 0;
          document.getElementById("numberOfItems").innerText = 0;
          document.getElementById("numberOfCylinders").innerText = 0;
          document.getElementById("volume").innerText = 0;
        }
      } else if (machine.name === "MPS" || machine.name === "Grafotronic") {
        const stepsMPS = [
          "10",
          "10 1/2",
          "10 7/8",
          "11 1/4",
          "11 1/8",
          "11",
          "11 3/8",
          "12",
          "12 1/4",
          "12 1/8",
          "12 3/8",
          "12 7/8",
          "13 1/4",
          "13 5/8",
          "14 1/2",
          "14 7/8",
          "18 1/4",
          "15 1/2",
          "16",
          "16 1/2",
          "17",
          "19 1/2",
        ];

        if (
          typeOfLabel.name === "Samolepljivi papir" ||
          typeOfLabel.name === "Samolepljivi termo ECO" ||
          typeOfLabel.name === "Samolepljivi termo TOP" ||
          typeOfLabel.name === "Samolepljivi PP"
        ) {
          let findedStep;
          let findedSteps = [];
          for (const step of stepsArray) {
            if (
              step.sirina >= widthPlusTwo &&
              stepsMPS.includes(step.br_cilindra)
            ) {
              findedSteps.push(step);
            }
          }
          findedSteps.sort((a, b) =>
            a.sirina > b.sirina ? 1 : b.sirina > a.sirina ? -1 : 0,
          );
          findedStep = findedSteps[0];

          // console.log(findedStep);
          // console.log(findedSteps);
          let assignWidth;
          assignWidth = widthPlusTwo;
          // let { minWidth, n, m } =
          //   findedStep?.br_cilindra === "12 7/8"
          //     ? calculateMinWidth(
          //         printingStepsOne.filter((item) =>
          //           stepsMPS.includes(item.numberOfCylinders),
          //         ),
          //         widthPlusTwo,
          //       )
          //     : calculateMinWidth(
          //         printingStepsTwo.filter((item) =>
          //           stepsMPS.includes(item.numberOfCylinders),
          //         ),
          //         widthPlusTwo,
          //       );
          let { minWidth, n, m } =
            findedStep?.br_cilindra === "12 7/8"
              ? calculateMinWidth(
                  printingStepsTwo.filter((item) =>
                    stepsMPS.includes(item.numberOfCylinders),
                  ),
                  widthPlusTwo,
                )
              : calculateMinWidth(
                  printingStepsTwo.filter((item) =>
                    stepsMPS.includes(item.numberOfCylinders),
                  ),
                  widthPlusTwo,
                );
          if (
            findedStep?.br_cilindra === "12 7/8" &&
            machineSteps[index].values.includes(printingStepsOne[n].maxWidth)
          ) {
            document.getElementById("assignWidth").innerText = assignWidth;
            document.getElementById("widthTable").innerText = findedStep.sirina;
            document.getElementById("numberOfItems").innerText =
              findedStep.br_komada_tm;
            document.getElementById("numberOfCylinders").innerText = (
              parseFloat(findedStep.obim) / 3.175
            ).toFixed(0);
            document.getElementById("volume").innerText = findedStep.obim;
          } else if (
            minWidth < findedStep?.sirina - widthPlusTwo &&
            machineSteps[index].values.includes(printingStepsTwo[n].maxWidth)
          ) {
            document.getElementById("assignWidth").innerText = assignWidth;
            document.getElementById("widthTable").innerText =
              printingStepsTwo[n].steps[m].width;
            document.getElementById("numberOfItems").innerText =
              printingStepsTwo[n].steps[m].numberOfItems;
            document.getElementById("numberOfCylinders").innerText = (
              parseFloat(printingStepsTwo[n].maxWidth) / 3.175
            ).toFixed(0);
            document.getElementById("volume").innerText =
              printingStepsTwo[n].maxWidth;
          } else if (
            machineSteps[index]?.values.includes(parseFloat(findedStep?.obim))
          ) {
            document.getElementById("assignWidth").innerText = assignWidth;
            document.getElementById("widthTable").innerText = findedStep.sirina;
            document.getElementById("numberOfItems").innerText =
              findedStep.br_komada_tm;
            document.getElementById("numberOfCylinders").innerText = (
              parseFloat(findedStep.obim) / 3.175
            ).toFixed(0);
            document.getElementById("volume").innerText = findedStep.obim;
          } else {
            document.getElementById("assignWidth").innerText = 0;
            document.getElementById("widthTable").innerText = 0;
            document.getElementById("numberOfItems").innerText = 0;
            document.getElementById("numberOfCylinders").innerText = 0;
            document.getElementById("volume").innerText = 0;
          }
        } else {
          let findedStep;
          let findedSteps = [];
          for (const step of stepsArray) {
            if (step.sirina >= width && stepsMPS.includes(step.br_cilindra)) {
              findedSteps.push(step);
            }
          }
          findedSteps.sort((a, b) =>
            a.sirina > b.sirina ? 1 : b.sirina > a.sirina ? -1 : 0,
          );
          findedStep = findedSteps[0];
          let assignWidth;
          assignWidth = width;
          let { minWidth, n, m } =
            findedStep?.br_cilindra === "12 7/8"
              ? calculateMinWidth(
                  printingStepsOne.filter((item) =>
                    stepsMPS.includes(item.numberOfCylinders),
                  ),
                  width,
                )
              : calculateMinWidth(
                  printingStepsTwo.filter((item) =>
                    stepsMPS.includes(item.numberOfCylinders),
                  ),
                  width,
                );
          if (
            findedStep?.br_cilindra === "12 7/8" &&
            machineSteps[index].values.includes(printingStepsOne[n].maxWidth)
          ) {
            document.getElementById("assignWidth").innerText = assignWidth;
            document.getElementById("widthTable").innerText = findedStep.sirina;
            document.getElementById("numberOfItems").innerText =
              findedStep.br_komada_tm;
            document.getElementById("numberOfCylinders").innerText = (
              parseFloat(findedStep.obim) / 3.175
            ).toFixed(0);
            document.getElementById("volume").innerText = findedStep.obim;
          } else if (
            minWidth < findedStep?.sirina - width &&
            machineSteps[index].values.includes(printingStepsTwo[n].maxWidth)
          ) {
            document.getElementById("assignWidth").innerText = assignWidth;
            document.getElementById("widthTable").innerText =
              printingStepsTwo[n].steps[m].width;
            document.getElementById("numberOfItems").innerText =
              printingStepsTwo[n].steps[m].numberOfItems;
            document.getElementById("numberOfCylinders").innerText = (
              parseFloat(printingStepsTwo[n].maxWidth) / 3.175
            ).toFixed(0);
            document.getElementById("volume").innerText =
              printingStepsTwo[n].maxWidth;
          } else if (
            machineSteps[index]?.values.includes(parseFloat(findedStep?.obim))
          ) {
            document.getElementById("assignWidth").innerText = assignWidth;
            document.getElementById("widthTable").innerText = findedStep.sirina;
            document.getElementById("numberOfItems").innerText =
              findedStep.br_komada_tm;
            document.getElementById("numberOfCylinders").innerText = (
              parseFloat(findedStep.obim) / 3.175
            ).toFixed(0);
            document.getElementById("volume").innerText = findedStep.obim;
          } else {
            document.getElementById("widthTable").innerText = 0;
            document.getElementById("assignWidth").innerText = 0;
            document.getElementById("numberOfItems").innerText = 0;
            document.getElementById("numberOfCylinders").innerText = 0;
            document.getElementById("volume").innerText = 0;
          }
        }
      }

      if (width === 0 || !width) {
        document.getElementById("widthTable").innerText = 0;
        document.getElementById("assignWidth").innerText = 0;
        document.getElementById("numberOfItems").innerText = 0;
        document.getElementById("numberOfCylinders").innerText = 0;
        document.getElementById("volume").innerText = 0;
      }
    } else {
      document.getElementById("widthTable").innerText = 0;
      document.getElementById("assignWidth").innerText = 0;
      document.getElementById("numberOfItems").innerText = 0;
      document.getElementById("numberOfCylinders").innerText = 0;
      document.getElementById("volume").innerText = 0;
      // notification.error({
      //   message: "Izaberite mašinu i tip etikete i unesite širinu!",
      //   placement: "bottomRight",
      //   duration: 2,
      // });
    }
  };

  const onSubmit = async (formData, isNew) => {
    const method = isNew ? "post" : "put";

    ////kada je nova kalkulacija ovo polje ce biti undefined uvek a treba da se salje na overu kada je kalkulacija nova
    if (formData.sendAuthentication === undefined) {
      formData.sendAuthentication = true;
    }

    const route = isNew
      ? `${SERVER_URL}/calculations`
      : `${SERVER_URL}/calculations/${calculationId}`;
    formData.date = new Date();

    try {
      await Axios[method](route, formData, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });

      notification.success({
        message: "Kalkulacija je sačuvana.",
        placement: "bottomRight",
      });
      history.push("/admin/calculations");
    } catch (error) {
      const msg = error.response
        ? error.response.data.message
        : error.message
        ? error.message
        : error;

      notification.error({
        message: "Greška pri čuvanju.",
        placement: "bottomRight",
      });
    }
  };

  const onSubmitNew = async (formData, isNew) => {
    ////kada je nova kalkulacija ovo polje ce biti undefined uvek a treba da se salje na overu kada je kalkulacija nova
    if (formData.sendAuthentication === undefined) {
      formData.sendAuthentication = true;
    }

    formData.date = new Date();

    try {
      await Axios.post(`${SERVER_URL}/calculationsSaveAsNew`, formData, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });

      notification.success({
        message: "Kalkulacija je sačuvana.",
        placement: "bottomRight",
      });
      history.push("/admin/calculations");
    } catch (error) {
      const msg = error.response
        ? error.response.data.message
        : error.message
        ? error.message
        : error;

      notification.error({
        message: "Greška pri čuvanju.",
        placement: "bottomRight",
      });
    }
  };

  return (
    <div style={{ padding: "8px" }}>
      <div style={{ textAlign: "center" }}>
        {!calculationId && (
          <CalculationViewForm
            data={calculation.data}
            printStepData={printStepData}
            setPrintStepData={setPrintStepData}
            onSubmit={onSubmit}
            onSubmitNew={onSubmitNew}
            onChangeLabelWidth={onChangeLabelWidth}
          />
        )}
        {calculationId && (
          <CalculationViewForm
            data={calculation.data}
            onSubmit={onSubmit}
            printStepData={printStepData}
            setPrintStepData={setPrintStepData}
            onSubmitNew={onSubmitNew}
            onChangeLabelWidth={onChangeLabelWidth}
          />
        )}
      </div>
    </div>
  );
};

export default EditCalculation;
