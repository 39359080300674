import React, { createContext, useEffect, useState } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { message } from "antd";
import Axios from "axios";
import { SERVER_URL } from "./config";
import Layout from "./components/base/Layout";
import Login from "./pages/Login";
import "./App.scss";
export const UserContext = createContext(null);

const App = () => {
  const [isLoggedIn, setLoggedIn] = useState(true);
  const [language, setLanguage] = useState();
  let user = localStorage.getItem("user");
  if (user) user = JSON.parse(user);

  const handleLogin = async (user) => {
    try {
      const userResponse = await Axios.post(
        `${SERVER_URL}/login`,
        { email: user.email, password: user.password },
        { withCredentials: false },
      );
      if (
        userResponse.data.message === "Auth successful" &&
        userResponse.data.role.some((item) => ["admin", "user"].includes(item))
      ) {
        localStorage.setItem("user", JSON.stringify(userResponse.data));
        window.location.reload();
      } else if (
        userResponse.data.message === "Auth successful" &&
        !userResponse.data.role.some((item) => ["admin", "user"].includes(item))
      ) {
        return message.info("You don't have access to dashboard", 3);
      }
    } catch (err) {
      console.log("\n Login error = ", err.response.data.message);
      return message.warning(err.response.data.message, 3);
    }
  };

  useEffect(() => {
    if (user && new Date() > new Date(user.expires)) {
      console.log("Session expired!");
      setLoggedIn(false);
    } else if (user === null) {
      setLoggedIn(false);
    } else {
      setLoggedIn(true);
    }
  }, [setLoggedIn, user]);

  useEffect(() => {
    Axios.get(`${SERVER_URL}/languages-selected`)
      .then((resp) => {
        const defaultLanguage = resp.data.find((l) => l.default);
        setLanguage({
          selected: defaultLanguage,
          list: resp.data,
          default: defaultLanguage,
        });
      })
      .catch((err) =>
        console.log(err.response ? err.response.data.message : err.message),
      );
  }, [setLanguage]);

  return (
    <UserContext.Provider
      value={{ isLoggedIn, handleLogin, data: user, language, setLanguage }}
    >
      <Router>
        {isLoggedIn && <Layout />}
        {!isLoggedIn && <Login />}
      </Router>
    </UserContext.Provider>
  );
};

export default App;
