import React from "react";
import { Button, Card, Form, Popconfirm, Select } from "antd";
import { useHistory } from "react-router-dom";

const { Option } = Select;
const tailLayout = {
  // wrapperCol: { offset: 8, span: 24 },
  wrapperCol: { span: 24 },
};

const CalculationForm = ({ data, deleteCalculation }) => {
  const history = useHistory();
  const [form] = Form.useForm();

  const editCalculation = () => {
    if (form.getFieldValue("calculation")) {
      history.push(
        "/admin/edit-calculations/" + form.getFieldValue("calculation"),
      );
    }
  };

  data.items.sort(function (a, b) {
    if (a.client < b.client) {
      return -1;
    }
    if (a.client > b.client) {
      return 1;
    }
    return 0;
  });

  return (
    <div>
      <div className="dashboard">
        <div className="card-wrapper">
          <Card title="KALKULACIJA" bordered={false}>
            <Form layout="horizontal" form={form}>
              <Form.Item
                label="Sve kalkulacije"
                name="calculation"
                rules={[
                  {
                    required: true,
                    message: "Molimo vas izaberite kalkulaciju!",
                  },
                ]}
              >
                <Select
                  showSearch
                  placeholder="Izaberite kalkulaciju"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.props.children
                      .join("")
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {data.items.map((item, index) => {
                    let date = new Date(item.date);
                    return (
                      <Option value={item._id} key={index}>
                        {date.getDate()}.{date.getMonth() + 1}.
                        {date.getFullYear()}. - {item.client.companyName} -{" "}
                        {item.businessName}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
              <Form.Item {...tailLayout}>
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                  <Button
                    onClick={editCalculation}
                    type="primary"
                    htmlType="submit"
                  >
                    Učitaj
                  </Button>
                  <Popconfirm
                    placement="rightBottom"
                    title="Da li ste sigurni da želite da obrišete kalkulaciju?"
                    description="Obriši"
                    onConfirm={() => {
                      if (form.getFieldValue("calculation")) {
                        deleteCalculation(form.getFieldValue("calculation"));
                      }
                    }}
                    okText="Da"
                    cancelText="Ne"
                  >
                    <Button type="primary" style={{ marginLeft: "10px" }}>
                      Obriši
                    </Button>
                  </Popconfirm>
                  <Button
                    type="primary"
                    onClick={() => history.push("/admin/new-calculation")}
                    htmlType="button"
                    style={{ marginLeft: "10px" }}
                  >
                    Nova kalkulacija
                  </Button>
                </div>
              </Form.Item>
            </Form>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default CalculationForm;
