import React, { useEffect, useContext } from "react";
import Axios from "axios";
import { Link } from "react-router-dom";
import { LoadingOutlined } from "@ant-design/icons";
import { notification, Button } from "antd";
import { UserContext } from "../../App";
import useAxios from "../../hooks/useAxios";
import { Table } from "../../components/tables";

import { SERVER_URL } from "../../config";

const TABLE_COLUMN_KEYS = ["_id", "__v", "avatar", "address", "zip", "clients"];

const Users = () => {
  const currentuser = useContext(UserContext);
  const [users, fetchUsers] = useAxios("", [], currentuser.data.token, "get");

  useEffect(() => {
    fetchUsers(`${SERVER_URL}/users`, []);
  }, [fetchUsers]);

  const deleteUserHandler = async (id) => {
    try {
      await Axios.delete(`${SERVER_URL}/users/${id}`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });
      notification.success({
        message: "Prodavac je obrisan",
        placement: "bottomRight",
      });
      setTimeout(() => {
        window.location.reload();
      }, 1200);
    } catch (err) {
      notification.error({
        message: "Problem sa brisanjem. Molimo vas pokusajte kasnije.",
        placement: "bottomRight",
      });
    }
  };
  // console.log(users)
  let columnKeys;
  if (users.data.items && users.data.items.length > 0) {
    const keys = Object.keys(users.data.items[0]);
    columnKeys = keys.filter((k) => !TABLE_COLUMN_KEYS.includes(k));
  }

  let tableData = [];
  const dateOptions = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
  };
  if (users.data.items && users.data.items?.length > 0) {
    tableData = users.data.items.map((item) => {
      if (Array.isArray(item.role)) item.role = item.role.join(", ");
      item.createdAtLocale = new Date(item.createdAt).toLocaleDateString(
        "sr-Latn-RS",
        dateOptions,
      );
      item.updatedAtLocale = new Date(item.updatedAt).toLocaleDateString(
        "sr-Latn-RS",
        dateOptions,
      );
      item.lastActivityDate =
        item.contacts?.length > 0
          ? new Date(
              item.contacts[item.contacts.length - 1].createdAt,
            ).toLocaleDateString("sr-Latn-RS", dateOptions)
          : "";
      return item;
    });
  }

  return (
    <div style={{ padding: "8px" }}>
      <div className="actions-block">
        <Link to="/admin/new-user">
          <Button type="primary">Novi prodavac</Button>
        </Link>
      </div>

      <div style={{ textAlign: "center", overflowX: "auto" }}>
        {users.isLoading && (
          <LoadingOutlined
            spin
            style={{ fontSize: "3rem", marginTop: "5rem" }}
          />
        )}
        {!users.isLoading &&
          users.data.items &&
          users.data.items.length > 0 && (
            <Table
              data={tableData}
              deleteHandler={deleteUserHandler}
              columnKeys={columnKeys}
              title="User"
              editPath="/admin/edit-user/"
              viewPath="/admin/view-user/"
            />
          )}
        {!users.isLoading &&
          users.data.items &&
          users.data.items.length === 0 && <h2>NO DATA</h2>}
      </div>
    </div>
  );
};

export default Users;
