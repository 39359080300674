import React, { useEffect, useContext } from "react";
import { Link, useHistory } from "react-router-dom";
import Axios from "axios";
import { LoadingOutlined } from "@ant-design/icons";
import { Button, notification } from "antd";
import { UserContext } from "../../App";
import useAxios from "../../hooks/useAxios";
import { SERVER_URL } from "../../config";
import HilzneForm from "../../components/forms/HilzneForm";
const SERVER_URL_PRODUCTION =
  process.env.NODE_ENV === "production"
    ? "https://birografika-api.concordsoft.solutions"
    : "http://localhost:3334";

const EditHilzne = (props) => {
  const history = useHistory();
  const currentuser = useContext(UserContext);
  const [data, fetchData] = useAxios("", {}, currentuser.data.token, "get");
  const { hilznaId } = props.match.params;

  useEffect(() => {
    if (hilznaId) fetchData(`${SERVER_URL_PRODUCTION}/hilzna/${hilznaId}`, {});
  }, [fetchData, hilznaId]);

  const onSubmit = async (formData, isNew) => {
    const method = isNew ? "post" : "put";
    const route = isNew
      ? `${SERVER_URL_PRODUCTION}/hilzne`
      : `${SERVER_URL_PRODUCTION}/hilzna/${hilznaId}`;
    try {
      await Axios[method](route, formData, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });

      notification.success({
        message: `Hilzna je ${isNew ? "kreirana." : "izmenjena."}`,
        placement: "bottomRight",
      });
      history.push("/admin/hilzne");
    } catch (error) {
      const msg = error.response
        ? error.response.data.message
        : error.message
        ? error.message
        : error;
      console.log(msg);
      notification.error({
        message: msg,
        placement: "bottomRight",
      });
    }
  };

  const isDataFetched = !data.isLoading && data.data && currentuser.language;

  return (
    <div className="edit-panel">
      <div className="actions-block">
        <Link to="/admin/hilzne">
          <Button type="primary">Sve hilzne</Button>
        </Link>
      </div>

      <div>
        {!isDataFetched && (
          <div style={{ textAlign: "center" }}>
            <LoadingOutlined
              spin
              style={{ fontSize: "3rem", marginTop: "5rem" }}
            />
          </div>
        )}

        {!hilznaId && isDataFetched && (
          <HilzneForm
            isNew={true}
            language={currentuser.language}
            onSubmit={onSubmit}
            SERVER_URL={SERVER_URL}
            token={currentuser.data.token}
          />
        )}

        {hilznaId && !data.isError && data.data && isDataFetched && (
          <HilzneForm
            isNew={false}
            data={data.data}
            language={currentuser.language}
            onSubmit={onSubmit}
            SERVER_URL={SERVER_URL}
            token={currentuser.data.token}
          />
        )}
      </div>
    </div>
  );
};

export default EditHilzne;
