import React, { useEffect, useContext } from "react";
import { Link, useHistory } from "react-router-dom";
import Axios from "axios";
import { LoadingOutlined } from "@ant-design/icons";
import { Button, notification } from "antd";
import { UserContext } from "../../App";
import useAxios from "../../hooks/useAxios";
import { SERVER_URL } from "../../config";
import TypeOfFolioForm from "../../components/forms/TypeOfFolioForm";
const SERVER_URL_PRODUCTION =
  process.env.NODE_ENV === "production"
    ? "https://birografika-api.concordsoft.solutions"
    : "http://localhost:3334";

const EditTypeOfFolio = (props) => {
  const history = useHistory();
  const currentuser = useContext(UserContext);
  const [data, fetchData] = useAxios("", {}, currentuser.data.token, "get");
  const { typeoffolioId } = props.match.params;

  useEffect(() => {
    if (typeoffolioId)
      fetchData(`${SERVER_URL_PRODUCTION}/type-of-folio/${typeoffolioId}`, {});
  }, [fetchData, typeoffolioId]);

  const onSubmit = async (formData, isNew) => {
    if (!formData.name) {
      notification.error({
        message: "Unesite naziv folije!",
        duration: 2,
        placement: "bottomRight",
      });
      return;
    }
    if (!formData.folioWay) {
      notification.error({
        message: "Odaberite način plastifikacije!",
        duration: 2,
        placement: "bottomRight",
      });
      return;
    }
    if (!formData.pricePerSquareMeter) {
      notification.error({
        message: "Unesite cenu folije (€/m2)!",
        duration: 2,
        placement: "bottomRight",
      });
      return;
    }

    const method = isNew ? "post" : "put";
    const route = isNew
      ? `${SERVER_URL_PRODUCTION}/types-of-folio`
      : `${SERVER_URL_PRODUCTION}/type-of-folio/${typeoffolioId}`;
    try {
      await Axios[method](route, formData, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });

      notification.success({
        message: `Tip folije je ${isNew ? "kreiran." : "izmenjen."}`,
        placement: "bottomRight",
      });
      history.push("/admin/types-of-folio");
    } catch (error) {
      const msg = error.response
        ? error.response.data.message
        : error.message
        ? error.message
        : error;
      console.log(msg);
      notification.error({
        message: msg,
        placement: "bottomRight",
      });
    }
  };

  const isDataFetched = !data.isLoading && data.data && currentuser.language;

  return (
    <div className="edit-panel">
      <div className="actions-block">
        <Link to="/admin/types-of-folio">
          <Button type="primary">Svi tipovi folija</Button>
        </Link>
      </div>

      <div>
        {!isDataFetched && (
          <div style={{ textAlign: "center" }}>
            <LoadingOutlined
              spin
              style={{ fontSize: "3rem", marginTop: "5rem" }}
            />
          </div>
        )}

        {!typeoffolioId && isDataFetched && (
          <TypeOfFolioForm
            isNew={true}
            language={currentuser.language}
            onSubmit={onSubmit}
            SERVER_URL={SERVER_URL}
            token={currentuser.data.token}
          />
        )}

        {typeoffolioId && !data.isError && data.data && isDataFetched && (
          <TypeOfFolioForm
            isNew={false}
            data={data.data}
            language={currentuser.language}
            onSubmit={onSubmit}
            SERVER_URL={SERVER_URL}
            token={currentuser.data.token}
          />
        )}
      </div>
    </div>
  );
};

export default EditTypeOfFolio;
