import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { SERVER_URL } from "../../config";
import { useHistory } from "react-router-dom";

import { Button, Card, Table, Popconfirm, notification } from "antd";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { UserContext } from "../../App";
import useAxios from "../../hooks/useAxios";
import Axios from "axios";

const Settings = () => {
  const history = useHistory();
  const currentuser = useContext(UserContext);
  const token = currentuser.data.token;
  const [showTableFinishingWorks, setShowTableFinishingWorks] = useState(false);
  const [finishingWorksData, fetchFinishingWorksData] = useAxios(
    "",
    null,
    token,
    "get",
  );

  useEffect(() => {
    fetchFinishingWorksData(`${SERVER_URL}/finishing-works`, []);
  }, []);

  const handleFinishingWorksView = () => {
    setShowTableFinishingWorks(!showTableFinishingWorks); ///menja se true/false u zavisnosti sta je trenutno pa ce svaki klik prikazati odnosno sakriti tabelu
  };

  const deleteHandler = async (id) => {
    try {
      await Axios.delete(`${SERVER_URL}/finishing-work/${id}`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });
      notification.success({
        message: "Doradna radnja je obrisana!",
        placement: "bottomRight",
      });
      history.push("/admin");
      history.push("/admin/settings");
    } catch (error) {
      const msg = error.response ? error.response.data.message : error.message;
      console.log(msg);
      notification.error({
        message: msg,
        placement: "bottomRight",
      });
    }
  };

  let columns = [{ key: "1", title: "Naziv", dataIndex: "name" }];

  columns.push({
    title: "Akcije",
    render: (text, record) => (
      <div
        style={{
          display: "inline-flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: "15px",
        }}
      >
        <div style={{ margin: "2px", padding: "4px", cursor: "pointer" }}>
          <Link to={"/admin/edit-finishingwork/" + record._id}>
            <EditOutlined
              title={`Izmenite doradnu radnju`}
              style={{ textDecoration: "none", color: "black" }}
            />
          </Link>
        </div>

        <div style={{ margin: "2px", padding: "4px" }}>
          <Popconfirm
            placement="left"
            title={`Ovo ce obrisati doradnu radnju`}
            onConfirm={() => {
              deleteHandler(record._id);
            }}
            okText="Ok"
            cancelText="Cancel"
          >
            <DeleteOutlined title={`Obrišite doradnu radnju`} />
          </Popconfirm>
        </div>
      </div>
    ),
  });

  return (
    <div>
      <Card
        title="PODEŠAVANJA"
        bordered={false}
        style={{ marginTop: "8px", marginLeft: "8px" }}
      >
        <div style={{ textAlign: "center" }}>
          <Link to="/admin/machines">
            <Button
              style={{
                width: "400px",
                marginRight: "30px",
                marginBottom: "15px",
              }}
              type="secondary"
            >
              Sve mašine
            </Button>
          </Link>
          <Link to="/admin/new-machine">
            <Button
              style={{
                width: "400px",
                marginRight: "30px",
                marginBottom: "15px",
              }}
              type="secondary"
            >
              Nova mašina
            </Button>
          </Link>
        </div>
        <div style={{ textAlign: "center" }}>
          <Link to="/admin/rewinding-machines">
            <Button
              style={{
                width: "400px",
                marginRight: "30px",
                marginBottom: "15px",
              }}
              type="secondary"
            >
              Sve mašine za premotavanje
            </Button>
          </Link>
          <Link to="/admin/new-rewindingmachine">
            <Button
              style={{
                width: "400px",
                marginRight: "30px",
                marginBottom: "15px",
              }}
              type="secondary"
            >
              Nova mašina za premotavanje
            </Button>
          </Link>
        </div>
        <div style={{ textAlign: "center" }}>
          <Link to="/admin/types-of-label">
            <Button
              style={{
                width: "400px",
                marginRight: "30px",
                marginBottom: "15px",
              }}
              type="secondary"
            >
              Svi tipovi materijala
            </Button>
          </Link>
          <Link to="/admin/new-typeoflabel">
            <Button
              style={{
                width: "400px",
                marginRight: "30px",
                marginBottom: "15px",
              }}
              type="secondary"
            >
              Novi tip materijala
            </Button>
          </Link>
        </div>

        <div style={{ textAlign: "center" }}>
          <Link to="/admin/types-of-folio">
            <Button
              style={{
                width: "400px",
                marginRight: "30px",
                marginBottom: "15px",
              }}
              type="secondary"
            >
              Svi tipovi folije za plastifikaciju
            </Button>
          </Link>
          <Link to="/admin/new-typeoffolio">
            <Button
              style={{
                width: "400px",
                marginRight: "30px",
                marginBottom: "15px",
              }}
              type="secondary"
            >
              Novi tip folije za plastifikaciju
            </Button>
          </Link>
        </div>
        <div style={{ textAlign: "center" }}>
          <Link to="/admin/types-of-varnishing">
            <Button
              style={{
                width: "400px",
                marginRight: "30px",
                marginBottom: "15px",
              }}
              type="secondary"
            >
              Svi tipovi lakiranja
            </Button>
          </Link>
          <Link to="/admin/new-typeofvarnishing">
            <Button
              style={{
                width: "400px",
                marginRight: "30px",
                marginBottom: "15px",
              }}
              type="secondary"
            >
              Novi tip lakiranja
            </Button>
          </Link>
        </div>
        <div style={{ textAlign: "center" }}>
          <Link to="/admin/boxes">
            <Button
              style={{
                width: "400px",
                marginRight: "30px",
                marginBottom: "15px",
              }}
              type="secondary"
            >
              Sve kutije
            </Button>
          </Link>
          <Link to="/admin/new-box">
            <Button
              style={{
                width: "400px",
                marginRight: "30px",
                marginBottom: "15px",
              }}
              type="secondary"
            >
              Nova kutija
            </Button>
          </Link>
        </div>
        <div style={{ textAlign: "center" }}>
          <Link to="/admin/types-of-transport">
            <Button
              style={{
                width: "400px",
                marginRight: "30px",
                marginBottom: "15px",
              }}
              type="secondary"
            >
              Svi tipovi transporta
            </Button>
          </Link>
          <Link to="/admin/new-typeoftransport">
            <Button
              style={{
                width: "400px",
                marginRight: "30px",
                marginBottom: "15px",
              }}
              type="secondary"
            >
              Novi tip transporta
            </Button>
          </Link>
        </div>
        <div style={{ textAlign: "center" }}>
          <Link to="/admin/hilzne">
            <Button
              style={{
                width: "400px",
                marginRight: "30px",
                marginBottom: "15px",
              }}
              type="secondary"
            >
              Sve hilzne
            </Button>
          </Link>
          <Link to="/admin/new-hilzna">
            <Button
              style={{
                width: "400px",
                marginRight: "30px",
                marginBottom: "15px",
              }}
              type="secondary"
            >
              Nova hilzna
            </Button>
          </Link>
        </div>
        <div style={{ textAlign: "center" }}>
          <Button
            style={{
              width: "400px",
              marginRight: "30px",
              marginBottom: "15px",
            }}
            onClick={handleFinishingWorksView}
            type="secondary"
          >
            Doradne radnje
          </Button>
        </div>
        {showTableFinishingWorks && (
          <div>
            <Link to="/admin/new-finishingwork">
              <Button type="primary" ghost>
                Dodaj doradnu radnju
              </Button>
            </Link>
            <Table
              dataSource={finishingWorksData.data.items}
              columns={columns}
            ></Table>
          </div>
        )}
      </Card>
    </div>
  );
};

export default Settings;
