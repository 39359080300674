import React from "react";
import { Card, message, notification, Upload } from "antd";
import { SERVER_URL } from "../../config";
import { InboxOutlined } from "@ant-design/icons";

const { Dragger } = Upload;

const GalleryForm = ({
  token,
  selectHandler,
  imageSavePath,
  imageType,
  imageHeight,
}) => {
  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  return (
    <div className="dashboard">
      <div className="card-wrapper">
        <Card title="Add new content" bordered={false}>
          <Dragger
            multiple
            action={`${SERVER_URL}/upload-local-image`}
            accept=".jpg,.jpeg,.png,.bmp,.svg"
            name="image"
            getValueFromEvent={normFile}
            headers={{
              type: imageType,
              resize: true,
              // resizemob: false,
              height: imageHeight,
              Authorization: `Bearer ${token}`,
              uri: imageSavePath,
              // urimob: imageSavePath + 'mob/',
            }}
            beforeUpload={(file) => {
              const isAllowedFormat = [
                "image/jpeg",
                "image/png",
                "image/svg+xml",
              ].includes(file.type);
              if (!isAllowedFormat) {
                notification.error({
                  message: "You can only upload JPG/PNG/SVG file!",
                  placement: "bottomRight",
                });
              }
              const isAllowedSize = file.size / 1024 / 1024 < 5;
              if (!isAllowedSize) {
                notification.error({
                  message: "Image must smaller than 5MB!",
                  placement: "bottomRight",
                });
              }
              return isAllowedFormat && isAllowedSize;
            }}
            onChange={async ({ file }) => {
              if (file.status === "done") {
                if (file.response && file.response.file) {
                  message.success(
                    `${file.response.file.name} file uploaded successfully.`,
                  );
                  let uploaded = localStorage.getItem("uploaded");
                  if (uploaded) {
                    localStorage.setItem(
                      "uploaded",
                      `${uploaded},${file.response.image._id}`,
                    );
                  } else {
                    localStorage.setItem("uploaded", file.response.image._id);
                  }
                  // if (selectHandler) {
                  //   selectHandler(file.response.image._id);
                  // }
                }
              } else if (file.status === "error") {
                message.error(`${file.response.file.name} file upload failed.`);
              }
            }}
          >
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="ant-upload-text">
              Click or drag file to this area to upload
            </p>
            <p className="ant-upload-hint">
              Support for a single or bulk upload. Strictly prohibit from
              uploading company data or other band files
            </p>
          </Dragger>
        </Card>
      </div>
    </div>
  );
};

export default GalleryForm;
