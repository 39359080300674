import React, { useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { LoadingOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { UserContext } from "../../App";
import useAxios from "../../hooks/useAxios";
import { SERVER_URL } from "../../config";
import TypeOfLabelViewForm from "../../components/forms/TypeOfLabelViewForm";

const ViewTypeOfLabel = (props) => {
  const currentuser = useContext(UserContext);
  const [data, fetchData] = useAxios("", {}, currentuser.data.token, "get");
  const { typeoflabelId } = props.match.params;

  useEffect(() => {
    if (typeoflabelId)
      fetchData(`${SERVER_URL}/type-of-label/${typeoflabelId}`, {});
  }, [fetchData, typeoflabelId]);

  const isDataFetched = !data.isLoading && data.data && currentuser.language;

  return (
    <div className="edit-panel">
      <div className="actions-block">
        <Link to="/admin/types-of-label">
          <Button type="primary">Svi tipovi materijala</Button>
        </Link>
      </div>

      <div>
        {!isDataFetched && (
          <div style={{ textAlign: "center" }}>
            <LoadingOutlined
              spin
              style={{ fontSize: "3rem", marginTop: "5rem" }}
            />
          </div>
        )}

        {!typeoflabelId && isDataFetched && (
          <TypeOfLabelViewForm
            isNew={true}
            language={currentuser.language}
            SERVER_URL={SERVER_URL}
            token={currentuser.data.token}
          />
        )}

        {typeoflabelId && !data.isError && data.data && isDataFetched && (
          <TypeOfLabelViewForm
            isNew={false}
            data={data.data}
            language={currentuser.language}
            SERVER_URL={SERVER_URL}
            token={currentuser.data.token}
          />
        )}
      </div>
    </div>
  );
};

export default ViewTypeOfLabel;
