import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { UserContext } from "../../App";
import NavMenu from "./NavMenu";
import Routes from "../routes/Routes";
import { Layout, Menu, Dropdown, Select, Button } from "antd";
import {
  DownOutlined,
  MenuUnfoldOutlined,
  MenuFoldOutlined,
} from "@ant-design/icons";

const { Header, Content } = Layout;

const AdminLayout = () => {
  const user = useContext(UserContext);
  const [isCollapsed, setCollapsed] = useState(false);
  const [collapsedNav, setCollapsedNav] = useState(false);

  const handleSignOutSubmit = async (event) => {
    event.preventDefault();
    localStorage.removeItem("user");
    window.location.href = "/login";
  };

  const userMenu = (
    <Menu>
      <Menu.Item>
        <Link to={`/admin/edit-user/${user.data ? user.data.id : ""}`}>
          Moj nalog
        </Link>
      </Menu.Item>
      <Menu.Item>
        <span onClick={handleSignOutSubmit}>Odjavi se</span>
      </Menu.Item>
    </Menu>
  );

  return (
    <Layout
      style={{
        minHeight: "100vh",
      }}
    >
      <NavMenu
        isCollapsed={isCollapsed}
        setCollapsed={setCollapsed}
        collapsedNav={collapsedNav}
      />
      <Layout>
        {/* <Header className='admin_header' style={{ color: '#fff', position: 'relative' }}> */}
        <Header
          className="admin_header"
          style={{
            color: "#fff",
            position: "fixed",
            width: "100%",
            zIndex: "1",
          }}
        >
          <Button
            type="primary"
            className="btn-collapse"
            onClick={() => setCollapsedNav(!collapsedNav)}
          >
            {React.createElement(
              collapsedNav ? MenuUnfoldOutlined : MenuFoldOutlined,
            )}
          </Button>
          <Link to="/admin" className="logo-mobile">
            <div className="logo">
              <img src="/images/logo.png" alt="birografika logo" />
            </div>
          </Link>
          {/* {isCollapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />} */}

          {/* <HomeOutlined /> */}

          {/* <Dropdown overlay={userMenu} trigger={['click', 'hover']}> */}
          <Dropdown overlay={userMenu} trigger={["click"]}>
            <span
              className="ant-dropdown-link"
              style={{ color: "rgb(123, 123, 123)", cursor: "pointer" }}
            >
              <span>
                {user.data
                  ? user.data.firstName + " " + user.data.lastName
                  : "email"}
              </span>
              <DownOutlined />
            </span>
          </Dropdown>

          {/*<Select*/}
          {/*  style={{ position: 'absolute', right: '2rem', top: '50%', transform: 'translateY(-50%)' }}*/}
          {/*  value={user.language && user.language.selected.name}*/}
          {/*  onChange={(lang) => user.setLanguage({ ...user.language, selected: user.language.list.find((l) => l.name === lang) })}*/}
          {/*>*/}
          {/*  {user.language &&*/}
          {/*    user.language.list.map((lang) => (*/}
          {/*      <Select.Option value={lang.name} key={lang.name}>*/}
          {/*        {lang.name}*/}
          {/*      </Select.Option>*/}
          {/*    ))}*/}
          {/*</Select>*/}
        </Header>

        <Content className="content-space">
          <Routes />
        </Content>
      </Layout>
    </Layout>
  );
};

export default AdminLayout;
