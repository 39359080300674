import React, { useContext, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { Button, Layout, Menu, Modal } from "antd";
import { ROUTES } from "../../config";
import { MenuFoldOutlined, MenuUnfoldOutlined } from "@ant-design/icons";
import { UserContext } from "../../App";
const { SubMenu } = Menu;
const { Sider } = Layout;

const NavMenu = (props) => {
  const history = useHistory();
  const { isCollapsed, setCollapsed, collapsedNav } = props;
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isSecondModalVisible, setIsSecondModalVisible] = useState(false);
  const [latestPath, setLatestPath] = useState("");
  const user = useContext(UserContext);

  const handleClick = () => {
    if (
      window.location.pathname.includes("edit-calculations") ||
      window.location.pathname.includes("new-calculation")
    ) {
      setIsModalVisible(true);
    } else {
      history.push("/admin/calculations");
    }
  };

  const handleClickMenuItem = (e) => {
    setLatestPath(e);
    if (
      window.location.pathname.includes("edit-calculations") ||
      window.location.pathname.includes("new-calculation")
    ) {
      setIsSecondModalVisible(true);
    } else {
      history.push(e);
    }
  };

  return (
    <Sider
      breakpoint="lg"
      className={collapsedNav ? "nav-menu-collapsed" : "nav-display"}
      trigger={null}
      collapsible
      collapsed={isCollapsed}
    >
      <div to="/admin/calculations" className="logo-desktop">
        <div onClick={handleClick} className="logo">
          <img src="/images/logo.png" alt="birografika logo" />
        </div>
      </div>
      <Menu
        theme="dark"
        mode="inline"
        defaultSelectedKeys={[window.location.pathname]}
      >
        {ROUTES.map((route) => {
          let visible = true;
          if (
            user &&
            user.data &&
            !user.data.role.includes("admin") &&
            route.label === "Kalkulacija"
          )
            visible = false;
          return visible ? (
            <SubMenu
              key={route.label.toLowerCase()}
              title={
                isCollapsed ? (
                  <route.icon />
                ) : (
                  // <img src={route.icon} />
                  <span>
                    <route.icon />
                    {/* <img src={route.icon} /> */}
                    {route.label}
                  </span>
                )
              }
            >
              {route.children
                .filter((item) => item.showInMenu)
                .map((item) => (
                  <Menu.Item
                    onClick={() => {
                      handleClickMenuItem(item.path);
                    }}
                    key={item.path}
                  >
                    <div class="link_menuitem">{item.label}</div>
                  </Menu.Item>
                ))}
            </SubMenu>
          ) : null;
        })}
      </Menu>

      <Modal
        width="50%"
        visible={isModalVisible}
        title={
          <strong>Da li ste sigurni da želite da napustite stranicu?</strong>
        }
        footer={[]}
        onCancel={() => {
          setIsModalVisible(false);
        }}
        maskClosable={true}
      >
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Button
            type="primary"
            style={{ width: "50%" }}
            onClick={() => {
              history.push("/admin/calculations");
              setIsModalVisible(false);
            }}
          >
            DA
          </Button>
        </div>
      </Modal>

      <Modal
        width="50%"
        visible={isSecondModalVisible}
        title={
          <strong>Da li ste sigurni da želite da napustite stranicu?</strong>
        }
        footer={[]}
        onCancel={() => {
          setIsSecondModalVisible(false);
        }}
        maskClosable={true}
      >
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Button
            type="primary"
            style={{ width: "50%" }}
            onClick={() => {
              history.push(latestPath);
              setIsSecondModalVisible(false);
            }}
          >
            DA
          </Button>
        </div>
      </Modal>
    </Sider>
  );
};
export default NavMenu;
