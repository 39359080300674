import React, { useState, useEffect } from "react";
import useAxios from "../../hooks/useAxios";
import Axios from "axios";
import {
  Tabs,
  Card,
  Input,
  Button,
  Form,
  notification,
  InputNumber,
  Switch,
  Select,
} from "antd";
import { SERVER_URL } from "../../config";
import { DeleteOutlined } from "@ant-design/icons";

const { Option } = Select;

const layout = {
  labelCol: { span: 5 },
  wrapperCol: { span: 9 },
};

const TypeOfLabelForm = ({ isNew, data, onSubmit, token }) => {
  const [form] = Form.useForm();
  const [printSpeedArr, setPrintSpeedArr] = useState([]);
  const [machines, setMachines] = useState();

  let initialValues = { ...data };

  useEffect(() => {
    fetchMachines();
  }, []);

  const fetchMachines = async () => {
    const res = await Axios.get(
      `${SERVER_URL}/machines`,

      {
        withCredentials: false,
        headers: { Authorization: `Bearer ${token}` },
      },
    );

    let machinesArr = [];
    if (data && data.printSpeed) {
      for (let i = 0; i < data.printSpeed.length; i++) {
        machinesArr.push(data.printSpeed[i].machine._id);
      }
    }

    const filteredArr = res.data.items.filter(
      (obj) => !machinesArr.includes(obj._id.toString()),
    );

    setMachines(filteredArr);
  };

  const onFinish = async (values, isNew) => {
    if (!isNew) {
      values._id = data._id;
    }
    onSubmit(values, isNew);
  };

  const handleNewItem = async () => {
    setPrintSpeedArr([...printSpeedArr, {}]);
  };

  const handleRemoveFromAdding = (index) => {
    const data = form.getFieldsValue();
    let dataMachine = data.machine;
    let dataSpeed = data.printSpeed;

    dataMachine.splice(index, 1); // 2nd parameter means remove one item only
    dataSpeed.splice(index, 1); // 2nd parameter means remove one item only

    form.setFieldsValue({ machine: dataMachine, printSpeed: dataSpeed });

    setPrintSpeedArr(printSpeedArr.slice(0, -1));
  };

  const removePrintSpeed = async (id) => {
    try {
      ///brisanje itema za brzinu stampe odredjene masine
      await Axios.put(
        `${SERVER_URL}/type-of-label-printspeed/${data._id}`,
        { id },
        {
          withCredentials: false,
          headers: { Authorization: `Bearer ${token}` },
        },
      );

      setTimeout(() => {
        window.location.reload();
      }, 500);
    } catch (e) {
      notification.error({
        message: "Problem sa brisanjem. Pokušajte kasnije.",
        placement: "bottomRight",
      });
    }
  };

  return (
    <div className="panel panel-body ">
      <div className="panel-body">
        <Card
          title={`${isNew ? "NOVI TIP MATERIJALA" : "IZMENA TIPA MATERIJALA"}`}
        >
          <Form
            {...layout}
            initialValues={initialValues}
            onFinish={(values) => onFinish(values, !data)}
            layout="vertical"
            form={form}
          >
            <div className="mainCont">
              <Form.Item
                name="code"
                className="ant-form-item-control-input-content"
                label="Šifra tipa etikete:"
              >
                <Input />
              </Form.Item>
            </div>

            <Tabs forceRender defaultActiveKey="1">
              <Tabs.TabPane
                forceRender
                tab="Osnove informacije o tipu  materijala"
                key="1"
              >
                <div>
                  <div className="panel-body">
                    <Form.Item
                      required
                      className="formitemANTD"
                      name="name"
                      label="Naziv tipa materijala:"
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item
                      required
                      className="formitemANTD"
                      name="calculateGrossUnit"
                      label="Računanje bruto vr. u kg/m2:"
                    >
                      <Select defaultValue={initialValues.calculateGrossUnit}>
                        <Option value="kg">kg</Option>
                        <Option value="m2">m2</Option>
                      </Select>
                    </Form.Item>
                    <Form.Item
                      required
                      className="formitemANTD"
                      name="existsPricePerKg"
                      label="Podržava cenu po kg(u tabak kalk.) (€/kg):"
                    >
                      <Switch defaultChecked={initialValues.existsPricePerKg} />
                    </Form.Item>
                    <Form.Item
                      className="formitemANTD"
                      name="grossKgPrice"
                      label="Specifična težina g/m2:"
                    >
                      <InputNumber min="0" />
                    </Form.Item>
                    <Form.Item
                      className="formitemANTD"
                      name="priceOfPrintingForm"
                      label="Cena štamparske forme:"
                    >
                      <InputNumber min="0" />
                    </Form.Item>
                    <Form.Item
                      className="formitemANTD"
                      name="materialPrice"
                      label="Cena materijala:"
                    >
                      <InputNumber min="0" />
                    </Form.Item>
                    <Form.Item
                      className="formitemANTD"
                      name="degreeOfPaintConsumption"
                      label="Stepen utroška boje:"
                    >
                      <InputNumber min="0" />
                    </Form.Item>
                    <Form.Item
                      className="formitemANTD"
                      name="materialWeight"
                      label="Težina materijala:"
                    >
                      <InputNumber min="0" />
                    </Form.Item>

                    {!isNew &&
                      data &&
                      data.printSpeed?.map((item, index) => (
                        <div style={{ display: "flex" }}>
                          <label>Mašina:</label>
                          <Form.Item>
                            <Input value={item.machine.name} disabled />
                          </Form.Item>

                          <label>Brzina štampe:</label>
                          <Form.Item>
                            <Input disabled value={item.printSpeed} />
                          </Form.Item>

                          <Button
                            onClick={() => removePrintSpeed(item._id)}
                            icon={<DeleteOutlined />}
                            type="primary"
                            style={{
                              padding: "0px 0px 0px 20px",
                              width: "60px",
                              display: "flex",
                              alignItems: "center",
                            }}
                            danger
                          ></Button>
                        </div>
                      ))}

                    <Button
                      hidden={isNew}
                      onClick={handleNewItem}
                      type="primary"
                      htmlType="button"
                    >
                      Dodaj brzinu štampe za mašinu
                    </Button>
                    {printSpeedArr.map((item, index) => (
                      <div
                        hidden={isNew}
                        style={{
                          display: "flex",
                          width: "100%",
                          alignItems: "center",
                        }}
                      >
                        <Form.Item label="Mašina" name={["machine", index]}>
                          <Select
                            style={{ width: "250px", marginRight: "10px" }}
                          >
                            {machines &&
                              machines.length > 0 &&
                              machines.map((item, index) => (
                                <Option value={item._id}>{item.name}</Option>
                              ))}
                          </Select>
                        </Form.Item>
                        <Form.Item
                          labelCol={{ span: 24 }}
                          name={["printSpeed", index]}
                          label="Brzina štampe:"
                        >
                          <InputNumber
                            style={{ width: "250px", marginRight: "10px" }}
                            min="0"
                          />
                        </Form.Item>
                        <Button
                          onClick={() => handleRemoveFromAdding(index)}
                          icon={<DeleteOutlined />}
                          type="primary"
                          style={{
                            padding: "0px 0px 0px 20px",
                            width: "60px",
                            display: "flex",
                            alignItems: "center",
                          }}
                          danger
                        ></Button>
                      </div>
                    ))}
                  </div>
                </div>
              </Tabs.TabPane>
            </Tabs>

            <div className="text-right">
              <Button type="primary" htmlType="submit">
                {isNew ? "Dodaj" : "Izmeni"} tip etikete
              </Button>
            </div>
          </Form>
        </Card>
      </div>
    </div>
  );
};

export default TypeOfLabelForm;
