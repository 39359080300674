import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { Table, Popconfirm, Input, Button } from "antd";
import ResizableAntdTable from "resizable-antd-table";
import {
  EditOutlined,
  DeleteOutlined,
  EyeOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import { UserContext } from "../../App";

const UsersTable = ({
  data,
  deleteHandler,
  columnKeys,
  title,
  editPath,
  viewPath,
}) => {
  const user = useContext(UserContext);
  let searchInput;

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <div style={{ padding: 8 }}>
          <Input
            ref={(node) => {
              searchInput = node;
            }}
            placeholder={`Pretrazi ${dataIndex}`}
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{ width: 188, marginBottom: 8, display: "block" }}
          />
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            size="small"
            style={{ width: 90, marginRight: 8 }}
          >
            Pretrazi
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Resetuj
          </Button>
        </div>
      );
    },
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{ color: filtered ? "red" : "#bbb", fontSize: "1rem" }}
      />
    ),
    onFilter: (value, record) => {
      const filterRecord = Array.isArray(dataIndex)
        ? record[dataIndex[0]][dataIndex[1]]
        : record[dataIndex];
      if (!filterRecord || !value) return false;
      return filterRecord
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase());
    },
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => {
          searchInput.select();
        });
      }
    },
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
  };

  const handleReset = (clearFilters) => {
    clearFilters();
  };

  const columns = [
    {
      key: "code",
      title: "Šifra",
      dataIndex: "code",
    },
    {
      key: "companyName",
      title: "Naziv kompanije",
      dataIndex: "companyName",
      ...getColumnSearchProps("companyName"),
    },
    {
      key: "address",
      title: "Adresa",
      dataIndex: "address",
    },
    {
      key: "city",
      title: "Grad",
      dataIndex: "city",
      ...getColumnSearchProps("city"),
      className: "table-column-city",
    },
    {
      key: "phone",
      title: "Telefon",
      dataIndex: "phone",
    },
    {
      key: "idNumber",
      title: "MB",
      dataIndex: "idNumber",
      ...getColumnSearchProps("idNumber"),
      className: "table-column-idNumber",
    },
    {
      key: "pib",
      title: "PIB",
      dataIndex: "pib",
    },
  ];
  columns.push({
    title: "Opcije",
    render: (text, record) => (
      <div
        style={{
          display: "inline-flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: "15px",
        }}
      >
        <div style={{ margin: "2px", padding: "4px", cursor: "pointer" }}>
          <Link to={editPath + record._id}>
            <EditOutlined
              title={`Edit ${title.toLowerCase()}`}
              style={{ textDecoration: "none", color: "black" }}
            />
          </Link>
        </div>

        {viewPath && (
          <div style={{ margin: "2px", padding: "4px", cursor: "pointer" }}>
            <Link to={viewPath + record._id}>
              <EyeOutlined
                title={`View ${title.toLowerCase()}`}
                style={{ textDecoration: "none", color: "black" }}
              />
            </Link>
          </div>
        )}

        {/* <div style={{ margin: "2px", padding: "4px" }}>
          <Popconfirm
            disabled={record._id === user.data.id}
            placement="left"
            title={`Ovo ce obrisati klijenta`}
            onConfirm={() => {
              deleteHandler(record._id);
            }}
            okText="Ok"
            cancelText="Cancel"
          >
            <DeleteOutlined title={`Delete ${title.toLowerCase()}`} />
          </Popconfirm>
        </div> */}
      </div>
    ),
  });

  return (
    <div>
      <ResizableAntdTable
        size="middle"
        bordered
        dataSource={data}
        columns={columns}
        rowClassName={(record, index) =>
          record.status === "AKTIVAN"
            ? "table-row-client-active"
            : record.status === "NEZAINTERESOVAN"
            ? "table-row-client-uninterested"
            : record.status === "USPOSTAVLJEN KONTAKT"
            ? "table-row-client-contacted"
            : record.status === "POSLATA PONUDA"
            ? "table-row-client-offer-sent"
            : "table-row-client-uncontacted"
        }
        rowKey="_id"
        className="client-table"
        pagination={{
          defaultPageSize: 100,
          position: "bottom",
          showSizeChanger: false,
          pageSizeOptions: ["10", "20", "50", "100"],
          hideOnSinglePage: true,
        }}
      />
    </div>
  );
};

export default UsersTable;
