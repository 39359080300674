import React, { useContext, useEffect } from 'react';
import useAxios from '../hooks/useAxios';
import { SERVER_URL } from '../config';
import { UserContext } from '../App';
import { Link } from "react-router-dom";
import { Button, notification } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import Table from "../components/tables/ClientTableHome";
import Axios from "axios";

const TABLE_COLUMN_KEYS = ['_id', '__v', 'avatar', 'address', 'zip', 'users', 'contacts', 'clientContacts'];

const Home = () => {
  const currentuser = useContext(UserContext);
  const [clients, fetchClients] = useAxios('', [], currentuser.data.token, 'get');

  useEffect(() => {
    fetchClients(`${SERVER_URL}/clients-home-page`, []);
  }, [fetchClients]);

  const deleteContactHandler = async (id) => {
    try {
      await Axios.delete(`${SERVER_URL}/activities/${id}`, { withCredentials: false, headers: { Authorization: `Bearer ${currentuser.data.token}` } });
      notification.success({
        message: 'Radna aktivnost je obrisana.',
        placement: 'bottomRight',
      });
      setTimeout(() => {
        window.location.reload();
      }, 1200);
    } catch (err) {
      notification.error({
        message: 'Problem sa brisanjem. Molimo vas pokusajte kasnije.',
        placement: 'bottomRight',
      });
    }
  };

  let columnKeys;
  if (clients.data.items && clients.data.items.length > 0) {
    const keys = Object.keys(clients.data.items[0]);
    columnKeys = keys.filter((k) => !TABLE_COLUMN_KEYS.includes(k));
    columnKeys.splice(2, 0, 'user');
  }
  let tableData = [];
  const dateOptions = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' };
  if (clients.data.items && clients.data.items.length > 0) {
    tableData = clients.data.items.map((item) => {
      if (Array.isArray(item.role)) item.role = item.role.join(', ');
      item.createdAt = new Date(item.createdAt).toLocaleDateString();
      // item.updatedAt = new Date(item.updatedAt).toLocaleDateString();
      item.lastContactDate = new Date(item.updatedAt).toLocaleDateString('sr-Latn-RS', dateOptions);
      item.comment = item.contacts.length > 0 ? item.contacts[0].comment : '';
      item.userName = item.user ? item.user.firstName + ' ' + item.user.lastName : '';
      return item;
    });
  }
  return (
    <div style={{ textAlign: 'center', overflowX: 'auto', padding: '8px' }}>
      {clients.isLoading && <LoadingOutlined spin style={{ fontSize: '3rem', marginTop: '5rem' }} />}
      {!clients.isLoading && clients.data.items && clients.data.items.length > 0 && (
        <Table data={tableData} deleteHandler={deleteContactHandler} columnKeys={columnKeys} title='Client' editPath='/admin/edit-contact/' viewPath='/admin/view-contact/' />
      )}
      {!clients.isLoading && clients.data.items && clients.data.items.length === 0 && <h2>NEMA PODATAKA O RADNIM AKTIVNOSTIMA</h2>}
    </div>
  );
};

export default Home;
