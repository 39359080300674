import React, { useState, useEffect, useContext } from "react";
import { UserContext } from "../../App";
import useAxios from "../../hooks/useAxios";
import { SERVER_URL } from "../../config";
import { LoadingOutlined } from "@ant-design/icons";
import ClientForm from "../../components/forms/ClientForm";
import Axios from "axios";
import { notification } from "antd";
import { useHistory } from "react-router-dom";

const EditClient = (props) => {
  const currentuser = useContext(UserContext);
  const [clients, fetchCLients] = useAxios(
    "",
    null,
    currentuser.data.token,
    "get",
  );

  const SERVER_URL_PRODUCTION =
    process.env.NODE_ENV === "production"
      ? "https://birografika-api.concordsoft.solutions"
      : "http://localhost:3334";
  const [client, fetchClient] = useAxios(
    "",
    null,
    currentuser.data.token,
    "get",
  );
  const history = useHistory();
  const { clientId } = props.match.params;
  const userId = currentuser.data.id;

  useEffect(() => {
    if (clientId) {
      fetchClient(`${SERVER_URL}/clients/${clientId}`, []);
    }
    fetchCLients(`${SERVER_URL}/new-clients`, []);
  }, [fetchClient, clientId]);

  const createClient = async (data) => {
    try {
      let clientExists = false;
      if (data.code) {
        clientExists = clients.data.items.filter(
          (client) => client.code === data.code,
        );
      }
      if (clientExists.length === 1) {
        notification.error({
          message: "Klijent sa ovom šifrom već postoji.",
          placement: "bottomRight",
        });
      } else {
        ///cuvanje klijenta u bazi prodaje
        const res = await Axios.post(
          `${SERVER_URL}/clients`,
          { ...data, createdBy: userId },
          {
            withCredentials: false,
            headers: { Authorization: `Bearer ${currentuser.data.token}` },
          },
        );

        let values = { ...data, code: res.data.code };

        ///cuvanje klijenta u bazi proizvodnje
        await Axios.post(
          `${SERVER_URL_PRODUCTION}/client-from-calculation`,
          {
            data: values,
            createdBy: currentuser,
          },
          {
            withCredentials: false,
            headers: { Authorization: `Bearer ${currentuser.data.token}` },
          },
        );

        notification.success({
          message: "Klijent je kreiran u kalkulatoru i proizvodnji.",
          placement: "bottomRight",
        });

        history.push("/admin/clients");
      }
    } catch (err) {
      notification.error({
        message:
          err.response.data && err.response.data.message
            ? err.response.data.message
            : "Problem sa kreiranjem klijenta. Molimo vas pokusajte kasnije.",
        placement: "bottomRight",
      });
    }
  };

  const updateClient = async (data) => {
    let form =
      data.status !== client.data.status ? { ...data, editedBy: userId } : data;
    try {
      await Axios.put(`${SERVER_URL}/clients/${clientId}`, form, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });

      await Axios.put(
        `${SERVER_URL_PRODUCTION}/client-from-calculation`,
        {
          oldclient: client,
          data,
          editedBy: currentuser,
        },
        {
          withCredentials: false,
          headers: { Authorization: `Bearer ${currentuser.data.token}` },
        },
      );
      notification.success({
        message: "Klijent je editovan u kalkulatoru i u proizvodnji.",
        placement: "bottomRight",
      });

      let comment;
      if (data.status !== client.data.status) {
        comment = data.comment ? data.comment : "PROMENJEN STATUS KLIJENTA";
      } else {
        comment = data.comment;
      }
      let isModified = false;
      for (let key in data) {
        if (key !== "comment" && data[key] !== client.data[key]) {
          isModified = true;
          break;
        } else if (
          key === "comment" &&
          data[key] !== undefined &&
          data[key] !== ""
        ) {
          isModified = true;
          break;
        }
      }
      if (isModified) {
        await Axios.post(
          `${SERVER_URL}/activities`,
          {
            clientId,
            userId,
            comment,
            dateTime: new Date(),
          },
          {
            withCredentials: false,
            headers: { Authorization: `Bearer ${currentuser.data.token}` },
          },
        );
      }
      history.push("/admin");
      history.push("/admin/clients");
    } catch (err) {
      notification.error({
        message: "Problem sa editovanjem. Molimo vas pokusajte kasnije.",
        placement: "bottomRight",
      });
    }
  };
  return (
    <div style={{ padding: "8px" }}>
      <div style={{ textAlign: "center" }}>
        {clientId && client.isLoading && (
          <LoadingOutlined
            spin
            style={{ fontSize: "3rem", marginTop: "5rem" }}
          />
        )}
        {clientId && !client.isLoading && !client.isError && client.data && (
          <ClientForm data={client.data} updateHandler={updateClient} />
        )}
        {client && !client.isLoading && client.isError && (
          <h2 style={{ marginTop: "5rem" }}>Something went wrong :(</h2>
        )}
        {!clientId && client && !client.data && (
          <ClientForm data={null} createHandler={createClient} />
        )}
      </div>
    </div>
  );
};

export default EditClient;
