import React, { useEffect, useContext } from "react";
import Axios from "axios";
import { Link, useHistory } from "react-router-dom";
import { LoadingOutlined } from "@ant-design/icons";
import { notification, Button } from "antd";
import { UserContext } from "../../App";
import useAxios from "../../hooks/useAxios";
import Table from "../../components/tables/TypeOfTransportTable";
import { SERVER_URL } from "../../config";

const TABLE_COLUMN_KEYS = [
  "_id",
  "__v",
  "contacted",
  "gallery",
  "discount",
  "featureImage",
  "follow",
  "views",
  "googleDescription",
  "featuredProduct",
  "keywords",
  "content",
  "gallery",
  "categoryPath",
  "fullUrl",
  "url",
  "comments",
  "attributes",
  "featuredAd",
  "featuredAdUntil",
  "user",
  "declaration",
];

const TypeOfTransport = () => {
  const currentuser = useContext(UserContext);
  const [data, fetchData] = useAxios("", [], currentuser.data.token, "get");
  const history = useHistory();

  useEffect(() => {
    fetchData(`${SERVER_URL}/types-of-transport`, []);
  }, [fetchData]);

  const deleteDataHandler = async (id) => {
    try {
      await Axios.delete(`${SERVER_URL}/type-of-transport/${id}`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });
      notification.success({
        message: "Tip transporta je obrisan.",
        placement: "bottomRight",
      });
      history.push("/admin");
      history.push("/admin/types-of-transport");
    } catch (err) {
      notification.error({
        message: "Problem sa brisanjem.Pokušajte kasnije.",
        placement: "bottomRight",
      });
    }
  };

  let columnKeys;
  if (data.data && data.data.items && data.data.items.length > 0) {
    const keys = Object.keys(data.data.items[0]);
    columnKeys = keys.filter((k) => !TABLE_COLUMN_KEYS.includes(k));
  }

  let tableData = [];
  if (data.data && data.data.items && data.data.items.length > 0) {
    tableData = data.data.items.map((item) => {
      item.createdAt = new Date(item.createdAt).toLocaleString();
      item.updatedAt = new Date(item.updatedAt).toLocaleString();
      return item;
    });
  }
  // console.log(tableData)
  return (
    <div className="table" style={{ padding: "8px" }}>
      <div className="actions-block">
        <Link to="/admin/new-typeoftransport">
          <Button type="primary">Kreiraj tip transporta</Button>
        </Link>
        <Link to="/admin/settings">
          <Button style={{ marginLeft: "10px" }} type="primary">
            Podešavanja
          </Button>
        </Link>
      </div>

      <div style={{ textAlign: "center" }}>
        {data.isLoading && (
          <LoadingOutlined
            spin
            style={{ fontSize: "3rem", marginTop: "5rem" }}
          />
        )}
        {!data.isLoading &&
          data.data &&
          data.data.items &&
          data.data.items.length > 0 && (
            <Table
              data={tableData}
              deleteHandler={deleteDataHandler}
              columnKeys={columnKeys}
              title="Tipovi etiketa"
              editPath="/admin/edit-typeoftransport/"
            />
          )}
        {!data.isLoading &&
          data.data &&
          data.data.items &&
          data.data.items.length === 0 && (
            <div>
              <h2>Nema dodatih tipova transporta.</h2>
            </div>
          )}
      </div>
    </div>
  );
};

export default TypeOfTransport;
