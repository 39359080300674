import React, { useEffect, useContext } from "react";
import Axios from "axios";
import { Link } from "react-router-dom";
import { LoadingOutlined, UploadOutlined } from "@ant-design/icons";
import { notification, Button } from "antd";
import { UserContext } from "../../App";
import useAxios from "../../hooks/useAxios";
import Table from "../../components/tables/ClientTable";

import { SERVER_URL } from "../../config";

const TABLE_COLUMN_KEYS = ["_id", "__v", "avatar", "address", "zip"];

const Clients = () => {
  const currentuser = useContext(UserContext);
  const [clients, fetchClients] = useAxios(
    "",
    [],
    currentuser.data.token,
    "get",
  );

  useEffect(() => {
    fetchClients(`${SERVER_URL}/new-clients`, []);
  }, [fetchClients]);

  const deleteClientHandler = async (id) => {
    try {
      await Axios.delete(`${SERVER_URL}/clients/${id}`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });
      notification.success({
        message: "Klijent je obrisan.",
        placement: "bottomRight",
      });
      setTimeout(() => {
        window.location.reload();
      }, 1200);
    } catch (err) {
      notification.error({
        message: "Problem sa brisanjem. Molimo vas pokusajte kasnije.",
        placement: "bottomRight",
      });
    }
  };

  let columnKeys;
  if (clients.data.items && clients.data.items.length > 0) {
    const keys = Object.keys(clients.data.items[0]);
    columnKeys = keys.filter((k) => !TABLE_COLUMN_KEYS.includes(k));
  }

  let tableData = [];
  const dateOptions = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
  };
  if (clients.data.items && clients.data.items.length > 0) {
    tableData = clients.data.items.map((item) => {
      item.createdAt = new Date(item.createdAt).toLocaleDateString(
        "sr-Latn-RS",
        dateOptions,
      );
      item.updatedAt = new Date(item.updatedAt).toLocaleDateString(
        "sr-Latn-RS",
        dateOptions,
      );
      return item;
    });
  }

  const handleImport = async (event) => {
    const file = event.target.files[0];
    let formData = new FormData();
    formData.append("excelData", file);

    const response = await Axios.post(
      `${SERVER_URL}/import-clients`,
      formData,
      {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
        "Content-Type": "application/json",
      },
    );
    notification.success({
      message: response.data.message,
      placement: "bottomRight",
    });
    setTimeout(() => {
      window.location.reload();
    }, 1200);
  };

  return (
    <div style={{ padding: "8px" }}>
      <div className="actions-block">
        <Link to="/admin/new-client">
          <Button type="primary">Novi klijent</Button>
        </Link>
        <Button
          icon={<UploadOutlined />}
          style={{ marginLeft: "10px" }}
          type="secondary"
          onClick={() => document.getElementById("input-file").click()}
        >
          Import Klijenata
        </Button>
        {
          <input
            style={{ display: "none" }}
            id="input-file"
            type="file"
            accept=".XLSX"
            onChange={handleImport}
          />
        }
      </div>
      <div style={{ textAlign: "center", overflowX: "auto" }}>
        {clients.isLoading && (
          <LoadingOutlined
            spin
            style={{ fontSize: "3rem", marginTop: "5rem" }}
          />
        )}
        {!clients.isLoading &&
          clients.data.items &&
          clients.data.items.length > 0 && (
            <Table
              data={clients.data.items}
              deleteHandler={deleteClientHandler}
              columnKeys={columnKeys}
              title="Client"
              editPath="/admin/edit-client/"
              viewPath="/admin/view-client/"
            />
          )}
        {!clients.isLoading &&
          clients.data.items &&
          clients.data.items.length === 0 && <h2>NEMA PODATAKA</h2>}
      </div>
    </div>
  );
};

export default Clients;
