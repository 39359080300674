import React, { useContext } from "react";
import {
  Button,
  Card,
  Form,
  Input,
  Result,
  Select,
  DatePicker,
  ConfigProvider,
  notification,
} from "antd";
import moment from "moment";
import Axios from "axios";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { SERVER_URL } from "../../config";
import { UserContext } from "../../App";
const { TextArea } = Input;
const { Option } = Select;

const tailLayout = {
  // wrapperCol: { offset: 8, span: 24 },
  wrapperCol: { span: 24 },
};

const initialValues = {
  clientId: undefined,
  comment: undefined,
  dateTime: undefined,
  status: undefined,
};
const SERVER_URL_PRODUCTION =
  process.env.NODE_ENV === "production"
    ? "https://birografika-api.concordsoft.solutions"
    : "http://localhost:3334";

const ContactForm = ({
  clients,
  data,
  allClients,
  contactHandler,
  isNew,
  selectHandler,
  client,
}) => {
  const history = useHistory();
  const currentuser = useContext(UserContext);
  const [form] = Form.useForm();
  const time = data ? new Date(data.updatedAt).getTime() : undefined;
  let contact = data
    ? {
        comment: data.comment,
        clientId: data.clientId ? data.clientId._id : undefined,
        dateTime: moment(new Date(time), "YYYY-MM-DD"),
        status: client?.status,
      }
    : initialValues;

  if (data && data.isArchived === false) {
    contact.status = data.status;
    contact.potentional_client_name = data.potentional_client_name;
  }

  const onFinish = (values) => {
    localStorage.removeItem("contactForm");
    contactHandler(values);
  };

  const handleNewClient = async () => {
    try {
      let formData = form.getFieldsValue();
      formData.companyName = formData.potentional_client_name;
      formData.code = data.potentional_client_code;

      ///cuvanje klijenta u bazi prodaje
      await Axios.post(
        `${SERVER_URL}/clients`,
        { ...formData, createdBy: currentuser.data.id },
        {
          withCredentials: false,
          headers: { Authorization: `Bearer ${currentuser.data.token}` },
        },
      );

      ///cuvanje klijenta u bazi proizvodnje
      await Axios.post(
        `${SERVER_URL_PRODUCTION}/client-from-calculation`,
        {
          data: { ...formData },
          createdBy: currentuser.data.id,
        },
        {
          withCredentials: false,
          headers: { Authorization: `Bearer ${currentuser.data.token}` },
        },
      );

      notification.success({
        message: "Klijent je kreiran u kalkulatoru i proizvodnji.",
        placement: "bottomRight",
      });

      history.push("/admin/clients");
    } catch (error) {
      notification.error({
        message: "Problem sa dodavanjem klijenta u bazu.",
      });
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const status = [
    "NE KONTAKTIRAN",
    "USPOSTAVLJEN KONTAKT",
    "POSLATA PONUDA",
    "AKTIVAN",
    "NEZAINTERESOVAN",
  ];

  return (
    <div>
      {/* <h2>{isNew ? 'NEW' : 'EDIT'} USER</h2> */}
      <div className="dashboard">
        <div className="card-wrapper">
          {/* eslint-disable-next-line no-useless-concat */}
          <Card
            title={
              isNew
                ? "DODAJTE NOVOG POTENCIJALNOG KLIJENTA"
                : "EDITUJ POTENCIJALNOG KLIJENTA"
            }
            bordered={false}
          >
            <Form
              name="basic"
              initialValues={contact}
              onFinish={(values) => onFinish(values)}
              onFinishFailed={onFinishFailed}
              layout="horizontal"
              form={form}
            >
              {data && data.isArchived === true && (
                <div>
                  <Form.Item
                    label="Klijent"
                    name="clientId"
                    rules={[
                      {
                        required: true,
                        message: "Molimo vas izaberite klijenta!",
                      },
                    ]}
                  >
                    <Select
                      showSearch
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.props.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      onChange={(value) => {
                        selectHandler(value, form);
                      }}
                    >
                      {allClients.length > 0 &&
                        allClients.map((item, index) => (
                          <Option key={index} value={item._id}>
                            {item.companyName}
                          </Option>
                        ))}
                    </Select>
                  </Form.Item>
                </div>
              )}
              {(!data || data.isArchived === false) && (
                <div>
                  <Form.Item
                    label="Klijent"
                    name="potentional_client_name"
                    rules={[
                      {
                        required: true,
                        message: "Molimo vas izaberite klijenta!",
                      },
                    ]}
                  >
                    <Input></Input>
                  </Form.Item>
                </div>
              )}
              <Form.Item label="Status" name="status">
                <Select
                // onChange={statusHandler}
                >
                  {status.map((item, index) => (
                    <Option key={index} value={item}>
                      {item}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                label="Datum"
                name="dateTime"
                rules={[
                  {
                    required: true,
                    message: "Molimo vas izaberite datum radne aktivnosti!",
                  },
                ]}
              >
                <DatePicker />
              </Form.Item>

              <Form.Item label="Komentar" name="comment">
                <TextArea rows={10} />
              </Form.Item>

              <Form.Item {...tailLayout}>
                {data && data.isArchived === false && (
                  <Button
                    type="primary"
                    onClick={() => {
                      handleNewClient();
                    }}
                    style={{ float: "right", marginLeft: "10px" }}
                  >
                    Sačuvaj u tabelu klijenata
                  </Button>
                )}
                {(!data || data.isArchived === true) && (
                  <Button
                    type="primary"
                    htmlType="submit"
                    style={{ float: "right" }}
                  >
                    {isNew ? "Dodaj" : "Edituj"} potencijalnog klijenta
                  </Button>
                )}
              </Form.Item>
            </Form>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default ContactForm;
