import React, { useEffect, useContext, useState } from "react";
import Axios from "axios";
import { Link, useHistory } from "react-router-dom";
import { LoadingOutlined } from "@ant-design/icons";
import { notification, Button } from "antd";
import { UserContext } from "../../App";
import useAxios from "../../hooks/useAxios";
import Table from "../../components/tables/CalculationTable";
import { SERVER_URL, SERVER_URL_TWO } from "../../config";
import dayjs from "dayjs";
const TABLE_COLUMN_KEYS = [
  "_id",
  "__v",
  "createdDate",
  "updatedAt",
  "client",
  "businessName",
  "offerStatus",
  "calculationType",
  "createdOrder",
];

const Calculation = () => {
  const currentuser = useContext(UserContext);
  const [data, fetchData] = useAxios("", [], currentuser.data.token, "get");
  const history = useHistory();

  const [allowMultipleSelect, setAllowMultipleSelect] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);

  useEffect(() => {
    fetchData(`${SERVER_URL}/calculations`, []);
  }, [fetchData]);

  const deleteDataHandler = async (id) => {
    try {
      await Axios.delete(`${SERVER_URL}/calculations/${id}`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });
      notification.success({
        message: "Kalkulacija je obrisana.",
        placement: "bottomRight",
      });
      history.push("/admin");
      history.push("/admin/calculations");
    } catch (err) {
      notification.error({
        message: "Problem sa brisanjem.Pokušajte kasnije.",
        placement: "bottomRight",
      });
    }
  };

  let columnKeys;
  let newColumnKeys = [];
  if (data.data.items && data.data.items.length > 0) {
    const keys = Object.keys(data.data.items[0]);
    columnKeys = keys.filter((k) => TABLE_COLUMN_KEYS.includes(k));

    columnKeys.push("cre_date");
    columnKeys.push("cre_Order");

    let datumkreiranja,
      porucilac,
      nazivposla,
      statusponude,
      tip,
      kreirannpp,
      test;

    for (const columnKey of columnKeys) {
      if (columnKey === "cre_date") {
        datumkreiranja = {
          originalName: columnKey,
          alternativeName: "Datum kreiranja",
        };
      } else if (columnKey === "client") {
        porucilac = {
          originalName: columnKey,
          alternativeName: "Kupac",
        };
      } else if (columnKey === "businessName") {
        nazivposla = {
          originalName: columnKey,
          alternativeName: "naziv posla",
        };
      } else if (columnKey === "offerStatus") {
        statusponude = {
          originalName: columnKey,
          alternativeName: "status ponude",
        };
      } else if (columnKey === "calculationType") {
        tip = {
          originalName: columnKey,
          alternativeName: "Tip",
        };
      } else if (columnKey === "cre_Order") {
        kreirannpp = {
          originalName: columnKey,
          alternativeName: "kreiran npp",
        };
      }
    }
    newColumnKeys.push(
      datumkreiranja,
      porucilac,
      nazivposla,
      statusponude,
      tip,
      kreirannpp,
    );
  }

  let tableData = [];
  if (data.data && data.data.items && data.data.items.length > 0) {
    tableData = data.data.items.map((item) => {
      const date = new Date(item.createdAt);

      const day = date.getDate();
      const month = date.getMonth() + 1; // months are 0-indexed, so we add 1
      const year = date.getFullYear();

      item.cre_date = `${day}.${month}.${year}.`;

      if (item.client && item.client.code) {
        item.clientCode = item.client.code;
      }

      if (item.client && item.client.companyName) {
        item.client = item.client.companyName;
      }

      if (item.createdOrder === true) {
        item.cre_Order = "DA";
      } else if (item.createdOrder === false || !item.cre_Order) {
        item.cre_Order = "NE";
      }
      return item;
    });
  }

  const onCreateOrderToProduction = async (values, isNew) => {
    try {
      const res = await Axios.post(
        `${SERVER_URL_TWO}/ordertoproduction-from-multiplecalculations`,
        {
          data: values,
          currentuser: currentuser.data,
        },
      );

      notification.success({
        message: "Kreiran nalog u proizvodnji!",
        duration: 2,
        placement: "bottomRight",
      });

      setSelectedItems([]);
      setAllowMultipleSelect(false);
    } catch (error) {
      notification.error({
        message: `Greška pri kreiranju naloga u prozivodnji!, ${error}`,
        duration: 2,
        placement: "bottomRight",
      });
    }
  };

  const multipleSelectHandler = () => {
    if (allowMultipleSelect) {
      setSelectedItems([]);
      setAllowMultipleSelect(false);
      return;
    }
    setAllowMultipleSelect(true);
  };

  return (
    <div className="table" style={{ padding: "8px" }}>
      <div className="actions-block">
        <Link to="/admin/new-calculation">
          <Button type="primary">Nova kalkulacija - rolna</Button>
        </Link>
        <Link to="/admin/new-calculation-sheet">
          <Button style={{ marginLeft: "10px" }} type="primary">
            Nova kalkulacija - tabak
          </Button>
        </Link>
        <Link to="/admin/old-calculations">
          <Button style={{ marginLeft: "10px" }} type="secondary">
            Stare kalkulacije
          </Button>
        </Link>
        <Button
          style={{ marginLeft: "10px" }}
          type="secondary"
          onClick={() => multipleSelectHandler()}
        >
          {!allowMultipleSelect
            ? " Izaberi samolepljive kalkulacije "
            : " Odustani "}
        </Button>
        {allowMultipleSelect && selectedItems.length > 1 && (
          <Button
            type="primary"
            style={{ marginTop: "10px", marginLeft: "10px" }}
            onClick={() => onCreateOrderToProduction(selectedItems, false)}
          >
            Sačuvaj nalog proizvodnji da proizvede
          </Button>
        )}
      </div>

      <div style={{ textAlign: "center" }}>
        {data.isLoading && (
          <LoadingOutlined
            spin
            style={{ fontSize: "3rem", marginTop: "5rem" }}
          />
        )}
        {!data.isLoading &&
          data.data &&
          data.data.items &&
          data.data.items.length > 0 && (
            <Table
              data={tableData}
              deleteHandler={deleteDataHandler}
              columnKeys={newColumnKeys}
              title="Kalkulacije"
              editPath="/admin/edit-calculations/"
              allowMultipleSelect={allowMultipleSelect}
              selectedItems={selectedItems}
              setSelectedItems={setSelectedItems}
            />
          )}
        {!data.isLoading &&
          data.data &&
          data.data.items &&
          data.data.items.length === 0 && (
            <div>
              <h2>Nema dodatih kalkulacija.</h2>
            </div>
          )}
      </div>
    </div>
  );
};

export default Calculation;
