import React from 'react';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { Button } from 'antd';
import { ExportOutlined } from '@ant-design/icons';

const ExportToExcel = ({ csvData, fileName, propsToRemove, header, format }) => {
  const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const fileExtension = '.xlsx';
  const removeProperties = (object, ...keys) =>
    Object.entries(object).reduce(
      (prev, [key, value]) => ({
        ...prev,
        ...(!keys.includes(key) && { [key]: value }),
      }),
      {},
    );

  const dataForExcel = async (data) => {
    let retData = [];
    // Ako zelis da promenis imena polja u xlsx tabeli po imenu format propsa iz glavne komponente
    if (format === 'custom_name') {
      let promises = data.map(async (item) => {
        // item["email"] = item["userName"];  // dodeli vrednost starog polja novom
        // delete item['staro_ipepolja'];  // obrisi staro polje
        // retData.push(item[i]);  // push to new array
      });
      await Promise.all(promises);
    } else {
      retData = await data.map((item) => removeProperties(item, ...propsToRemove));
    }
    return retData;
  };

  const exportToCSV = async (csvData, fileName) => {
    let filtered = await dataForExcel(csvData);
    const ws = XLSX.utils.json_to_sheet(filtered, { header: header });
    const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  return (
    <Button type='primary' size='small' title='Export to Excel' onClick={(e) => exportToCSV(csvData, fileName)}>
      <ExportOutlined /> Exportuj u Excel
    </Button>
  );
};

export default ExportToExcel;
