import React, { useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { LoadingOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { UserContext } from "../../App";
import useAxios from "../../hooks/useAxios";
import { SERVER_URL } from "../../config";
import BoxViewForm from "../../components/forms/BoxViewForm";

const ViewBox = (props) => {
  const SERVER_URL_PRODUCTION =
    process.env.NODE_ENV === "production"
      ? "https://birografika-api.concordsoft.solutions"
      : "http://localhost:3334";
  const currentuser = useContext(UserContext);
  const [data, fetchData] = useAxios("", {}, currentuser.data.token, "get");
  const { boxId } = props.match.params;

  useEffect(() => {
    if (boxId) fetchData(`${SERVER_URL_PRODUCTION}/box/${boxId}`, {});
  }, [fetchData, boxId]);

  const isDataFetched = !data.isLoading && data.data && currentuser.language;

  return (
    <div className="edit-panel">
      <div className="actions-block">
        <Link to="/admin/boxes">
          <Button type="primary">Sve kutije</Button>
        </Link>
      </div>

      <div>
        {!isDataFetched && (
          <div style={{ textAlign: "center" }}>
            <LoadingOutlined
              spin
              style={{ fontSize: "3rem", marginTop: "5rem" }}
            />
          </div>
        )}

        {!boxId && isDataFetched && (
          <BoxViewForm
            isNew={true}
            language={currentuser.language}
            SERVER_URL={SERVER_URL}
            token={currentuser.data.token}
          />
        )}

        {boxId && !data.isError && data.data && isDataFetched && (
          <BoxViewForm
            isNew={false}
            data={data.data}
            language={currentuser.language}
            SERVER_URL={SERVER_URL}
            token={currentuser.data.token}
          />
        )}
      </div>
    </div>
  );
};

export default ViewBox;
