import React, { useContext, useState, useEffect } from "react";
import OldCalculationForm from "../../components/forms/OldCalculationForm";
import Axios from "axios";
import useAxios from "../../hooks/useAxios";
import { Form } from "antd";
import { UserContext } from "../../App";
import { SERVER_URL } from "../../config";
import { notification } from "antd";
import { useHistory } from "react-router-dom";
import {
  printingStepsOne,
  printingStepsTwo,
} from "../../components/base/PrintingSteps";

const OldEditCalculation = (props) => {
  const currentuser = useContext(UserContext);
  const history = useHistory();
  const [form] = Form.useForm();
  const { calculationId } = props.match.params;
  const [calculation, fetchCalculation] = useAxios(
    "",
    null,
    currentuser.data.token,
    "get",
  );

  const [hideColorQuantity, setHideColorQuantity] = useState(true);
  const [hideQuantitySpecialColors, setHideQuantitySpecialColors] =
    useState(true);
  const [hideFolioWidth, setHideFolioWidth] = useState(true);
  const [hideFolioPrice, setHideFolioPrice] = useState(true);
  const [hideFolioPage, setHideFolioPage] = useState(true);
  const [hideGrossFolio, setHideGrossFolio] = useState(true);
  const [hidePrintingForm, setHidePrintingForm] = useState(true);
  const [hideDesign, setHideDesign] = useState(true);
  const [hideBoxes, setHideBoxes] = useState(true);
  const [hideTransport, setHideTransport] = useState(true);
  const [hideSubcontractors, setHideSubcontractors] = useState(true);
  const [hideTigl, setHideTigl] = useState(true);
  const [hideDegreeOfVarnishConsumption, setHideDegreeOfVarnishConsumption] =
    useState(true);
  const [hidePrintingSpeed, setHidePrintingSpeed] = useState(true);
  const [hidePrintingSpeedOther, setHidePrintingSpeedOther] = useState(true);
  const [hideTotalTimeOfPrinting, setHideTotalTimeOfPrinting] = useState(true);
  const [hideTotalTimeOfPrintingOther, setHideTotalTimeOfPrintingOther] =
    useState(true);
  const [hideTotalElectricityOther, setHideTotalElectricityOther] =
    useState(true);
  const [hideGrossCurrentMeters, setHideGrossCurrentMeters] = useState(true);
  const [hideGrossMetersPrinting, setHideGrossMetersPrinting] = useState(true);
  const [materialPricePlaceholder, setMaterialPricePlaceholder] = useState(
    "Izaberite materijal",
  );
  const [hideGrossKgPrinting, setHideGrossKgPrinting] = useState(true);
  const [hideVarnishQuantity, setHideVarnishQuantity] = useState(true);
  const [hideVarnishPrice, setHideVarnishPrice] = useState(true);
  const [hideColorPrice, setHideColorPrice] = useState(false);
  const [printingSpeedState, setPrintingSpeedState] = useState(false);
  const [
    degreeOfVarnishConsumptionPlaceholder,
    setDegreeOfVarnishConsumptionPlaceholder,
  ] = useState("");
  const [
    hideTotalPreparationOfPrintingPresses,
    setHideTotalPreparationOfPrintingPresses,
  ] = useState(true);
  const [hideTotal, setHideTotal] = useState(true);
  const [hideTotalPrice, setHideTotalPrice] = useState(true);
  // const [hideTotalPrintingSpeed, setHideTotalPrintingSpeed] = useState(true);
  const [visible, setVisible] = useState(false);
  const [tableVisible, setTableVisible] = useState(false);
  const [dataTable, setDataTable] = useState([]);
  const [machine, setMachine] = useState(undefined);
  const [isDigitalPrint, setIsDigitalPrint] = useState(false);
  const [printType, setPrintType] = useState();
  const [hideFolioPrinting, setHideFolioPrinting] = useState(true);
  const [hideBending, setHideBending] = useState(true);
  const [maxWidthCheck, setMaxWidthCheck] = useState(1000000);
  const [materialWidth, setMaterialWidth] = useState();

  const machineSteps = [
    {
      name: "Arsoma",
      values: [
        231.78, 247.65, 254, 257.18, 266.7, 273.05, 279.4, 285.75, 292.1, 304.8,
        311.15, 314.33, 323.85, 327.03, 330.2, 336.55, 339.73, 346.075, 355.6,
        361.95, 368.3, 374.65, 393.7, 406.4, 419.1, 428.63, 431.8, 441.33,
        609.6,
      ],
    },
    {
      name: "Mark Andy",
      values: [
        203.2, 222.25, 228.6, 234.95, 241.3, 279.4, 304.8, 311.15, 323.85,
        330.2, 361.95,
      ],
    },
    {
      name: "MPS",
      values: [
        254, 266.7, 276.23, 285.75, 288.93, 304.8, 307.98, 314.33, 327.03,
        336.55, 346.075, 361.95, 368.3, 393.7, 406.4, 419.1, 431.8, 495.3,
        609.6,
      ],
    },
    {
      name: "Konica Minolta",
      values: [
        203.2, 222.25, 228.6, 231.78, 234.95, 241.3, 247.65, 254, 257.18, 266.7,
        273.05, 276.23, 279.4, 285.75, 288.93, 292.1, 304.8, 307.98, 311.15,
        314.33, 323.85, 327.03, 330.2, 336.55, 339.73, 346.075, 355.6, 361.95,
        368.3, 374.65, 393.7, 406.4, 419.1, 428.63, 431.8, 441.33, 495.3, 609.6,
      ],
    },
  ];

  const stepsArray = [
    {
      br_cilindra: "8",
      obim: 203.2,
      br_zubaca: 64.0,
      sirina: 203.2,
      br_komada_tm: 4.921,
      br_elemenata: 1,
    },
    {
      br_cilindra: "8",
      obim: 203.2,
      br_zubaca: 64.0,
      sirina: 101.6,
      br_komada_tm: 9.843,
      br_elemenata: 2,
    },
    {
      br_cilindra: "8",
      obim: 203.2,
      br_zubaca: 64.0,
      sirina: 67.733,
      br_komada_tm: 14.764,
      br_elemenata: 3,
    },
    {
      br_cilindra: "8",
      obim: 203.2,
      br_zubaca: 64.0,
      sirina: 50.8,
      br_komada_tm: 19.685,
      br_elemenata: 4,
    },
    {
      br_cilindra: "8",
      obim: 203.2,
      br_zubaca: 64.0,
      sirina: 40.64,
      br_komada_tm: 24.606,
      br_elemenata: 5,
    },
    {
      br_cilindra: "8",
      obim: 203.2,
      br_zubaca: 64.0,
      sirina: 33.867,
      br_komada_tm: 29.528,
      br_elemenata: 6,
    },
    {
      br_cilindra: "8",
      obim: 203.2,
      br_zubaca: 64.0,
      sirina: 29.029,
      br_komada_tm: 34.449,
      br_elemenata: 7,
    },
    {
      br_cilindra: "8",
      obim: 203.2,
      br_zubaca: 64.0,
      sirina: 25.4,
      br_komada_tm: 39.37,
      br_elemenata: 8,
    },
    {
      br_cilindra: "8",
      obim: 203.2,
      br_zubaca: 64.0,
      sirina: 22.578,
      br_komada_tm: 44.291,
      br_elemenata: 9,
    },
    {
      br_cilindra: "8",
      obim: 203.2,
      br_zubaca: 64.0,
      sirina: 20.32,
      br_komada_tm: 49.213,
      br_elemenata: 10,
    },
    {
      br_cilindra: "8 3/4",
      obim: 222.25,
      br_zubaca: 70.0,
      sirina: 222.25,
      br_komada_tm: 4.499,
      br_elemenata: 1,
    },
    {
      br_cilindra: "8 3/4",
      obim: 222.25,
      br_zubaca: 70.0,
      sirina: 111.125,
      br_komada_tm: 8.999,
      br_elemenata: 2,
    },
    {
      br_cilindra: "8 3/4",
      obim: 222.25,
      br_zubaca: 70.0,
      sirina: 74.083,
      br_komada_tm: 13.498,
      br_elemenata: 3,
    },
    {
      br_cilindra: "8 3/4",
      obim: 222.25,
      br_zubaca: 70.0,
      sirina: 55.563,
      br_komada_tm: 17.998,
      br_elemenata: 4,
    },
    {
      br_cilindra: "8 3/4",
      obim: 222.25,
      br_zubaca: 70.0,
      sirina: 44.45,
      br_komada_tm: 22.497,
      br_elemenata: 5,
    },
    {
      br_cilindra: "8 3/4",
      obim: 222.25,
      br_zubaca: 70.0,
      sirina: 37.042,
      br_komada_tm: 26.997,
      br_elemenata: 6,
    },
    {
      br_cilindra: "8 3/4",
      obim: 222.25,
      br_zubaca: 70.0,
      sirina: 31.75,
      br_komada_tm: 31.496,
      br_elemenata: 7,
    },
    {
      br_cilindra: "8 3/4",
      obim: 222.25,
      br_zubaca: 70.0,
      sirina: 27.781,
      br_komada_tm: 35.996,
      br_elemenata: 8,
    },
    {
      br_cilindra: "8 3/4",
      obim: 222.25,
      br_zubaca: 70.0,
      sirina: 24.694,
      br_komada_tm: 40.495,
      br_elemenata: 9,
    },
    {
      br_cilindra: "8 3/4",
      obim: 222.25,
      br_zubaca: 70.0,
      sirina: 22.225,
      br_komada_tm: 44.994,
      br_elemenata: 10,
    },
    {
      br_cilindra: "9",
      obim: 228.6,
      br_zubaca: 72.0,
      sirina: 228.6,
      br_komada_tm: 4.374,
      br_elemenata: 1,
    },
    {
      br_cilindra: "9",
      obim: 228.6,
      br_zubaca: 72.0,
      sirina: 114.3,
      br_komada_tm: 8.749,
      br_elemenata: 2,
    },
    {
      br_cilindra: "9",
      obim: 228.6,
      br_zubaca: 72.0,
      sirina: 76.2,
      br_komada_tm: 13.123,
      br_elemenata: 3,
    },
    {
      br_cilindra: "9",
      obim: 228.6,
      br_zubaca: 72.0,
      sirina: 57.15,
      br_komada_tm: 17.498,
      br_elemenata: 4,
    },
    {
      br_cilindra: "9",
      obim: 228.6,
      br_zubaca: 72.0,
      sirina: 45.72,
      br_komada_tm: 21.872,
      br_elemenata: 5,
    },
    {
      br_cilindra: "9",
      obim: 228.6,
      br_zubaca: 72.0,
      sirina: 38.1,
      br_komada_tm: 26.247,
      br_elemenata: 6,
    },
    {
      br_cilindra: "9",
      obim: 228.6,
      br_zubaca: 72.0,
      sirina: 32.657,
      br_komada_tm: 30.621,
      br_elemenata: 7,
    },
    {
      br_cilindra: "9",
      obim: 228.6,
      br_zubaca: 72.0,
      sirina: 28.575,
      br_komada_tm: 34.996,
      br_elemenata: 8,
    },
    {
      br_cilindra: "9",
      obim: 228.6,
      br_zubaca: 72.0,
      sirina: 25.4,
      br_komada_tm: 39.37,
      br_elemenata: 9,
    },
    {
      br_cilindra: "9",
      obim: 228.6,
      br_zubaca: 72.0,
      sirina: 22.86,
      br_komada_tm: 43.745,
      br_elemenata: 10,
    },
    {
      br_cilindra: "9 3/4",
      obim: 247.65,
      br_zubaca: 78.0,
      sirina: 247.65,
      br_komada_tm: 4.038,
      br_elemenata: 1,
    },
    {
      br_cilindra: "9 3/4",
      obim: 247.65,
      br_zubaca: 78.0,
      sirina: 123.825,
      br_komada_tm: 8.076,
      br_elemenata: 2,
    },
    {
      br_cilindra: "9 3/4",
      obim: 247.65,
      br_zubaca: 78.0,
      sirina: 82.55,
      br_komada_tm: 12.114,
      br_elemenata: 3,
    },
    {
      br_cilindra: "9 3/4",
      obim: 247.65,
      br_zubaca: 78.0,
      sirina: 61.913,
      br_komada_tm: 16.152,
      br_elemenata: 4,
    },
    {
      br_cilindra: "9 3/4",
      obim: 247.65,
      br_zubaca: 78.0,
      sirina: 49.53,
      br_komada_tm: 20.19,
      br_elemenata: 5,
    },
    {
      br_cilindra: "9 3/4",
      obim: 247.65,
      br_zubaca: 78.0,
      sirina: 41.275,
      br_komada_tm: 24.228,
      br_elemenata: 6,
    },
    {
      br_cilindra: "9 3/4",
      obim: 247.65,
      br_zubaca: 78.0,
      sirina: 35.379,
      br_komada_tm: 28.266,
      br_elemenata: 7,
    },
    {
      br_cilindra: "9 3/4",
      obim: 247.65,
      br_zubaca: 78.0,
      sirina: 30.956,
      br_komada_tm: 32.304,
      br_elemenata: 8,
    },
    {
      br_cilindra: "9 3/4",
      obim: 247.65,
      br_zubaca: 78.0,
      sirina: 25.517,
      br_komada_tm: 36.342,
      br_elemenata: 9,
    },
    {
      br_cilindra: "9 3/4",
      obim: 247.65,
      br_zubaca: 78.0,
      sirina: 24.765,
      br_komada_tm: 40.38,
      br_elemenata: 10,
    },
    {
      br_cilindra: "10",
      obim: 254.0,
      br_zubaca: 80.0,
      sirina: 254.0,
      br_komada_tm: 3.937,
      br_elemenata: 1,
    },
    {
      br_cilindra: "10",
      obim: 254.0,
      br_zubaca: 80.0,
      sirina: 127.0,
      br_komada_tm: 7.874,
      br_elemenata: 2,
    },
    {
      br_cilindra: "10",
      obim: 254.0,
      br_zubaca: 80.0,
      sirina: 84.667,
      br_komada_tm: 11.811,
      br_elemenata: 3,
    },
    {
      br_cilindra: "10",
      obim: 254.0,
      br_zubaca: 80.0,
      sirina: 63.5,
      br_komada_tm: 15.748,
      br_elemenata: 4,
    },
    {
      br_cilindra: "10",
      obim: 254.0,
      br_zubaca: 80.0,
      sirina: 50.8,
      br_komada_tm: 19.685,
      br_elemenata: 5,
    },
    {
      br_cilindra: "10",
      obim: 254.0,
      br_zubaca: 80.0,
      sirina: 42.333,
      br_komada_tm: 23.622,
      br_elemenata: 6,
    },
    {
      br_cilindra: "10",
      obim: 254.0,
      br_zubaca: 80.0,
      sirina: 36.286,
      br_komada_tm: 27.559,
      br_elemenata: 7,
    },
    {
      br_cilindra: "10",
      obim: 254.0,
      br_zubaca: 80.0,
      sirina: 31.75,
      br_komada_tm: 31.496,
      br_elemenata: 8,
    },
    {
      br_cilindra: "10",
      obim: 254.0,
      br_zubaca: 80.0,
      sirina: 28.222,
      br_komada_tm: 35.433,
      br_elemenata: 9,
    },
    {
      br_cilindra: "10",
      obim: 254.0,
      br_zubaca: 80.0,
      sirina: 25.4,
      br_komada_tm: 39.37,
      br_elemenata: 10,
    },
    {
      br_cilindra: "10",
      obim: 254.0,
      br_zubaca: 80.0,
      sirina: 23.091,
      br_komada_tm: 43.307,
      br_elemenata: 11,
    },
    {
      br_cilindra: "10",
      obim: 254.0,
      br_zubaca: 80.0,
      sirina: 21.167,
      br_komada_tm: 47.244,
      br_elemenata: 12,
    },
    {
      br_cilindra: "10",
      obim: 254.0,
      br_zubaca: 80.0,
      sirina: 19.538,
      br_komada_tm: 51.181,
      br_elemenata: 13,
    },
    {
      br_cilindra: "10",
      obim: 254.0,
      br_zubaca: 80.0,
      sirina: 18.143,
      br_komada_tm: 55.118,
      br_elemenata: 14,
    },
    {
      br_cilindra: "10",
      obim: 254.0,
      br_zubaca: 80.0,
      sirina: 16.933,
      br_komada_tm: 59.055,
      br_elemenata: 15,
    },
    {
      br_cilindra: "10",
      obim: 254.0,
      br_zubaca: 80.0,
      sirina: 15.875,
      br_komada_tm: 62.992,
      br_elemenata: 16,
    },
    {
      br_cilindra: "10",
      obim: 254.0,
      br_zubaca: 80.0,
      sirina: 14.941,
      br_komada_tm: 66.929,
      br_elemenata: 17,
    },
    {
      br_cilindra: "10",
      obim: 254.0,
      br_zubaca: 80.0,
      sirina: 14.111,
      br_komada_tm: 70.866,
      br_elemenata: 18,
    },
    {
      br_cilindra: "10",
      obim: 254.0,
      br_zubaca: 80.0,
      sirina: 13.368,
      br_komada_tm: 74.803,
      br_elemenata: 19,
    },
    {
      br_cilindra: "10 1/8",
      obim: 257.18,
      br_zubaca: 81.0,
      sirina: 257.18,
      br_komada_tm: 3.888,
      br_elemenata: 1,
    },
    {
      br_cilindra: "10 1/8",
      obim: 257.18,
      br_zubaca: 81.0,
      sirina: 128.59,
      br_komada_tm: 7.777,
      br_elemenata: 2,
    },
    {
      br_cilindra: "10 1/8",
      obim: 257.18,
      br_zubaca: 81.0,
      sirina: 85.727,
      br_komada_tm: 11.665,
      br_elemenata: 3,
    },
    {
      br_cilindra: "10 1/8",
      obim: 257.18,
      br_zubaca: 81.0,
      sirina: 64.295,
      br_komada_tm: 15.553,
      br_elemenata: 4,
    },
    {
      br_cilindra: "10 1/8",
      obim: 257.18,
      br_zubaca: 81.0,
      sirina: 51.436,
      br_komada_tm: 19.442,
      br_elemenata: 5,
    },
    {
      br_cilindra: "10 1/8",
      obim: 257.18,
      br_zubaca: 81.0,
      sirina: 42.863,
      br_komada_tm: 23.33,
      br_elemenata: 6,
    },
    {
      br_cilindra: "10 1/8",
      obim: 257.18,
      br_zubaca: 81.0,
      sirina: 36.74,
      br_komada_tm: 27.218,
      br_elemenata: 7,
    },
    {
      br_cilindra: "10 1/8",
      obim: 257.18,
      br_zubaca: 81.0,
      sirina: 32.148,
      br_komada_tm: 31.107,
      br_elemenata: 8,
    },
    {
      br_cilindra: "10 1/8",
      obim: 257.18,
      br_zubaca: 81.0,
      sirina: 28.576,
      br_komada_tm: 34.995,
      br_elemenata: 9,
    },
    {
      br_cilindra: "10 1/8",
      obim: 257.18,
      br_zubaca: 81.0,
      sirina: 25.718,
      br_komada_tm: 38.883,
      br_elemenata: 10,
    },
    {
      br_cilindra: "10 1/8",
      obim: 257.18,
      br_zubaca: 81.0,
      sirina: 23.38,
      br_komada_tm: 42.772,
      br_elemenata: 11,
    },
    {
      br_cilindra: "10 1/8",
      obim: 257.18,
      br_zubaca: 81.0,
      sirina: 21.432,
      br_komada_tm: 46.66,
      br_elemenata: 12,
    },
    {
      br_cilindra: "10 1/8",
      obim: 257.18,
      br_zubaca: 81.0,
      sirina: 19.783,
      br_komada_tm: 50.548,
      br_elemenata: 13,
    },
    {
      br_cilindra: "10 1/8",
      obim: 257.18,
      br_zubaca: 81.0,
      sirina: 18.37,
      br_komada_tm: 54.437,
      br_elemenata: 14,
    },
    {
      br_cilindra: "10 1/8",
      obim: 257.18,
      br_zubaca: 81.0,
      sirina: 17.145,
      br_komada_tm: 58.325,
      br_elemenata: 15,
    },
    {
      br_cilindra: "10 1/8",
      obim: 257.18,
      br_zubaca: 81.0,
      sirina: 16.074,
      br_komada_tm: 62.213,
      br_elemenata: 16,
    },
    {
      br_cilindra: "10 1/8",
      obim: 257.18,
      br_zubaca: 81.0,
      sirina: 15.128,
      br_komada_tm: 66.102,
      br_elemenata: 17,
    },
    {
      br_cilindra: "10 1/8",
      obim: 257.18,
      br_zubaca: 81.0,
      sirina: 14.288,
      br_komada_tm: 69.99,
      br_elemenata: 18,
    },
    {
      br_cilindra: "10 1/8",
      obim: 257.18,
      br_zubaca: 81.0,
      sirina: 13.536,
      br_komada_tm: 73.878,
      br_elemenata: 19,
    },
    {
      br_cilindra: "10 1/8",
      obim: 257.18,
      br_zubaca: 81.0,
      sirina: 12.859,
      br_komada_tm: 77.767,
      br_elemenata: 20,
    },
    {
      br_cilindra: "10 1/2",
      obim: 266.7,
      br_zubaca: 84.0,
      sirina: 266.7,
      br_komada_tm: 3.75,
      br_elemenata: 1,
    },
    {
      br_cilindra: "10 1/2",
      obim: 266.7,
      br_zubaca: 84.0,
      sirina: 133.35,
      br_komada_tm: 7.499,
      br_elemenata: 2,
    },
    {
      br_cilindra: "10 1/2",
      obim: 266.7,
      br_zubaca: 84.0,
      sirina: 88.9,
      br_komada_tm: 11.249,
      br_elemenata: 3,
    },
    {
      br_cilindra: "10 1/2",
      obim: 266.7,
      br_zubaca: 84.0,
      sirina: 66.675,
      br_komada_tm: 14.998,
      br_elemenata: 4,
    },
    {
      br_cilindra: "10 1/2",
      obim: 266.7,
      br_zubaca: 84.0,
      sirina: 53.34,
      br_komada_tm: 18.748,
      br_elemenata: 5,
    },
    {
      br_cilindra: "10 1/2",
      obim: 266.7,
      br_zubaca: 84.0,
      sirina: 44.45,
      br_komada_tm: 22.497,
      br_elemenata: 6,
    },
    {
      br_cilindra: "10 1/2",
      obim: 266.7,
      br_zubaca: 84.0,
      sirina: 38.1,
      br_komada_tm: 26.247,
      br_elemenata: 7,
    },
    {
      br_cilindra: "10 1/2",
      obim: 266.7,
      br_zubaca: 84.0,
      sirina: 33.338,
      br_komada_tm: 29.296,
      br_elemenata: 8,
    },
    {
      br_cilindra: "10 1/2",
      obim: 266.7,
      br_zubaca: 84.0,
      sirina: 29.633,
      br_komada_tm: 33.746,
      br_elemenata: 9,
    },
    {
      br_cilindra: "10 1/2",
      obim: 266.7,
      br_zubaca: 84.0,
      sirina: 26.67,
      br_komada_tm: 37.495,
      br_elemenata: 10,
    },
    {
      br_cilindra: "10 7/8",
      obim: 276.23,
      br_zubaca: 87.0,
      sirina: 276.23,
      br_komada_tm: 3.62,
      br_elemenata: 1,
    },
    {
      br_cilindra: "10 7/8",
      obim: 276.23,
      br_zubaca: 87.0,
      sirina: 138.115,
      br_komada_tm: 7.24,
      br_elemenata: 2,
    },
    {
      br_cilindra: "10 7/8",
      obim: 276.23,
      br_zubaca: 87.0,
      sirina: 92.077,
      br_komada_tm: 10.861,
      br_elemenata: 3,
    },
    {
      br_cilindra: "10 7/8",
      obim: 276.23,
      br_zubaca: 87.0,
      sirina: 69.058,
      br_komada_tm: 14.481,
      br_elemenata: 4,
    },
    {
      br_cilindra: "10 7/8",
      obim: 276.23,
      br_zubaca: 87.0,
      sirina: 55.246,
      br_komada_tm: 18.101,
      br_elemenata: 5,
    },
    {
      br_cilindra: "10 7/8",
      obim: 276.23,
      br_zubaca: 87.0,
      sirina: 46.038,
      br_komada_tm: 21.721,
      br_elemenata: 6,
    },
    {
      br_cilindra: "10 7/8",
      obim: 276.23,
      br_zubaca: 87.0,
      sirina: 39.461,
      br_komada_tm: 25.341,
      br_elemenata: 7,
    },
    {
      br_cilindra: "10 7/8",
      obim: 276.23,
      br_zubaca: 87.0,
      sirina: 34.529,
      br_komada_tm: 28.961,
      br_elemenata: 8,
    },
    {
      br_cilindra: "10 7/8",
      obim: 276.23,
      br_zubaca: 87.0,
      sirina: 30.692,
      br_komada_tm: 32.582,
      br_elemenata: 9,
    },
    {
      br_cilindra: "10 7/8",
      obim: 276.23,
      br_zubaca: 87.0,
      sirina: 27.623,
      br_komada_tm: 36.202,
      br_elemenata: 10,
    },
    {
      br_cilindra: "11",
      obim: 279.4,
      br_zubaca: 88.0,
      sirina: 279.4,
      br_komada_tm: 3.579,
      br_elemenata: 1,
    },
    {
      br_cilindra: "11",
      obim: 279.4,
      br_zubaca: 88.0,
      sirina: 139.7,
      br_komada_tm: 7.158,
      br_elemenata: 2,
    },
    {
      br_cilindra: "11",
      obim: 279.4,
      br_zubaca: 88.0,
      sirina: 93.133,
      br_komada_tm: 10.737,
      br_elemenata: 3,
    },
    {
      br_cilindra: "11",
      obim: 279.4,
      br_zubaca: 88.0,
      sirina: 69.85,
      br_komada_tm: 14.316,
      br_elemenata: 4,
    },
    {
      br_cilindra: "11",
      obim: 279.4,
      br_zubaca: 88.0,
      sirina: 55.88,
      br_komada_tm: 17.895,
      br_elemenata: 5,
    },
    {
      br_cilindra: "11",
      obim: 279.4,
      br_zubaca: 88.0,
      sirina: 46.567,
      br_komada_tm: 21.475,
      br_elemenata: 6,
    },
    {
      br_cilindra: "11",
      obim: 279.4,
      br_zubaca: 88.0,
      sirina: 39.914,
      br_komada_tm: 25.054,
      br_elemenata: 7,
    },
    {
      br_cilindra: "11",
      obim: 279.4,
      br_zubaca: 88.0,
      sirina: 34.925,
      br_komada_tm: 28.633,
      br_elemenata: 8,
    },
    {
      br_cilindra: "11",
      obim: 279.4,
      br_zubaca: 88.0,
      sirina: 31.044,
      br_komada_tm: 32.212,
      br_elemenata: 9,
    },
    {
      br_cilindra: "11",
      obim: 279.4,
      br_zubaca: 88.0,
      sirina: 27.94,
      br_komada_tm: 35.791,
      br_elemenata: 10,
    },
    {
      br_cilindra: "11",
      obim: 279.4,
      br_zubaca: 88.0,
      sirina: 25.4,
      br_komada_tm: 39.37,
      br_elemenata: 11,
    },
    {
      br_cilindra: "11",
      obim: 279.4,
      br_zubaca: 88.0,
      sirina: 23.283,
      br_komada_tm: 42.949,
      br_elemenata: 12,
    },
    {
      br_cilindra: "11",
      obim: 279.4,
      br_zubaca: 88.0,
      sirina: 21.492,
      br_komada_tm: 46.528,
      br_elemenata: 13,
    },
    {
      br_cilindra: "11",
      obim: 279.4,
      br_zubaca: 88.0,
      sirina: 19.957,
      br_komada_tm: 50.107,
      br_elemenata: 14,
    },
    {
      br_cilindra: "11",
      obim: 279.4,
      br_zubaca: 88.0,
      sirina: 18.627,
      br_komada_tm: 53.686,
      br_elemenata: 15,
    },
    {
      br_cilindra: "11",
      obim: 279.4,
      br_zubaca: 88.0,
      sirina: 17.463,
      br_komada_tm: 57.266,
      br_elemenata: 16,
    },
    {
      br_cilindra: "11",
      obim: 279.4,
      br_zubaca: 88.0,
      sirina: 16.435,
      br_komada_tm: 60.845,
      br_elemenata: 17,
    },
    {
      br_cilindra: "11",
      obim: 279.4,
      br_zubaca: 88.0,
      sirina: 15.552,
      br_komada_tm: 64.424,
      br_elemenata: 18,
    },
    {
      br_cilindra: "11",
      obim: 279.4,
      br_zubaca: 88.0,
      sirina: 14.705,
      br_komada_tm: 68.003,
      br_elemenata: 19,
    },
    {
      br_cilindra: "11",
      obim: 279.4,
      br_zubaca: 88.0,
      sirina: 13.97,
      br_komada_tm: 71.582,
      br_elemenata: 20,
    },
    {
      br_cilindra: "11 1/4",
      obim: 285.75,
      br_zubaca: 90.0,
      sirina: 285.75,
      br_komada_tm: 3.5,
      br_elemenata: 1,
    },
    {
      br_cilindra: "11 1/4",
      obim: 285.75,
      br_zubaca: 90.0,
      sirina: 142.875,
      br_komada_tm: 6.999,
      br_elemenata: 2,
    },
    {
      br_cilindra: "11 1/4",
      obim: 285.75,
      br_zubaca: 90.0,
      sirina: 95.25,
      br_komada_tm: 10.499,
      br_elemenata: 3,
    },
    {
      br_cilindra: "11 1/4",
      obim: 285.75,
      br_zubaca: 90.0,
      sirina: 71.438,
      br_komada_tm: 13.998,
      br_elemenata: 4,
    },
    {
      br_cilindra: "11 1/4",
      obim: 285.75,
      br_zubaca: 90.0,
      sirina: 57.15,
      br_komada_tm: 17.498,
      br_elemenata: 5,
    },
    {
      br_cilindra: "11 1/4",
      obim: 285.75,
      br_zubaca: 90.0,
      sirina: 47.625,
      br_komada_tm: 20.997,
      br_elemenata: 6,
    },
    {
      br_cilindra: "11 1/4",
      obim: 285.75,
      br_zubaca: 90.0,
      sirina: 40.821,
      br_komada_tm: 24.497,
      br_elemenata: 7,
    },
    {
      br_cilindra: "11 1/4",
      obim: 285.75,
      br_zubaca: 90.0,
      sirina: 35.719,
      br_komada_tm: 27.997,
      br_elemenata: 8,
    },
    {
      br_cilindra: "11 1/4",
      obim: 285.75,
      br_zubaca: 90.0,
      sirina: 31.75,
      br_komada_tm: 31.496,
      br_elemenata: 9,
    },
    {
      br_cilindra: "11 1/4",
      obim: 285.75,
      br_zubaca: 90.0,
      sirina: 28.575,
      br_komada_tm: 34.996,
      br_elemenata: 10,
    },
    {
      br_cilindra: "11 1/4",
      obim: 285.75,
      br_zubaca: 90.0,
      sirina: 25.977,
      br_komada_tm: 38.495,
      br_elemenata: 11,
    },
    {
      br_cilindra: "11 1/4",
      obim: 285.75,
      br_zubaca: 90.0,
      sirina: 23.813,
      br_komada_tm: 41.995,
      br_elemenata: 12,
    },
    {
      br_cilindra: "11 1/4",
      obim: 285.75,
      br_zubaca: 90.0,
      sirina: 21.981,
      br_komada_tm: 45.494,
      br_elemenata: 13,
    },
    {
      br_cilindra: "11 1/4",
      obim: 285.75,
      br_zubaca: 90.0,
      sirina: 20.411,
      br_komada_tm: 48.994,
      br_elemenata: 14,
    },
    {
      br_cilindra: "11 1/4",
      obim: 285.75,
      br_zubaca: 90.0,
      sirina: 19.05,
      br_komada_tm: 52.493,
      br_elemenata: 15,
    },
    {
      br_cilindra: "11 1/4",
      obim: 285.75,
      br_zubaca: 90.0,
      sirina: 17.859,
      br_komada_tm: 55.993,
      br_elemenata: 16,
    },
    {
      br_cilindra: "11 1/4",
      obim: 285.75,
      br_zubaca: 90.0,
      sirina: 16.809,
      br_komada_tm: 59.493,
      br_elemenata: 17,
    },
    {
      br_cilindra: "11 1/4",
      obim: 285.75,
      br_zubaca: 90.0,
      sirina: 15.875,
      br_komada_tm: 62.992,
      br_elemenata: 18,
    },
    {
      br_cilindra: "11 1/4",
      obim: 285.75,
      br_zubaca: 90.0,
      sirina: 15.039,
      br_komada_tm: 66.492,
      br_elemenata: 19,
    },
    {
      br_cilindra: "11 1/4",
      obim: 285.75,
      br_zubaca: 90.0,
      sirina: 14.288,
      br_komada_tm: 69.991,
      br_elemenata: 20,
    },
    {
      br_cilindra: "11 3/8",
      obim: 288.93,
      br_zubaca: 91.0,
      sirina: 288.93,
      br_komada_tm: 3.461,
      br_elemenata: 1,
    },
    {
      br_cilindra: "11 3/8",
      obim: 288.93,
      br_zubaca: 91.0,
      sirina: 144.465,
      br_komada_tm: 6.922,
      br_elemenata: 2,
    },
    {
      br_cilindra: "11 3/8",
      obim: 288.93,
      br_zubaca: 91.0,
      sirina: 96.31,
      br_komada_tm: 10.383,
      br_elemenata: 3,
    },
    {
      br_cilindra: "11 3/8",
      obim: 288.93,
      br_zubaca: 91.0,
      sirina: 72.233,
      br_komada_tm: 13.844,
      br_elemenata: 4,
    },
    {
      br_cilindra: "11 3/8",
      obim: 288.93,
      br_zubaca: 91.0,
      sirina: 57.786,
      br_komada_tm: 17.305,
      br_elemenata: 5,
    },
    {
      br_cilindra: "11 3/8",
      obim: 288.93,
      br_zubaca: 91.0,
      sirina: 48.155,
      br_komada_tm: 20.766,
      br_elemenata: 6,
    },
    {
      br_cilindra: "11 3/8",
      obim: 288.93,
      br_zubaca: 91.0,
      sirina: 41.276,
      br_komada_tm: 24.227,
      br_elemenata: 7,
    },
    {
      br_cilindra: "11 3/8",
      obim: 288.93,
      br_zubaca: 91.0,
      sirina: 36.116,
      br_komada_tm: 27.689,
      br_elemenata: 8,
    },
    {
      br_cilindra: "11 3/8",
      obim: 288.93,
      br_zubaca: 91.0,
      sirina: 32.103,
      br_komada_tm: 31.149,
      br_elemenata: 9,
    },
    {
      br_cilindra: "11 3/8",
      obim: 288.93,
      br_zubaca: 91.0,
      sirina: 28.893,
      br_komada_tm: 34.61,
      br_elemenata: 10,
    },
    {
      br_cilindra: "11 1/2",
      obim: 292.1,
      br_zubaca: 92.0,
      sirina: 292.1,
      br_komada_tm: 3.423,
      br_elemenata: 1,
    },
    {
      br_cilindra: "11 1/2",
      obim: 292.1,
      br_zubaca: 92.0,
      sirina: 146.05,
      br_komada_tm: 6.847,
      br_elemenata: 2,
    },
    {
      br_cilindra: "11 1/2",
      obim: 292.1,
      br_zubaca: 92.0,
      sirina: 97.367,
      br_komada_tm: 10.27,
      br_elemenata: 3,
    },
    {
      br_cilindra: "11 1/2",
      obim: 292.1,
      br_zubaca: 92.0,
      sirina: 73.025,
      br_komada_tm: 13.694,
      br_elemenata: 4,
    },
    {
      br_cilindra: "11 1/2",
      obim: 292.1,
      br_zubaca: 92.0,
      sirina: 58.42,
      br_komada_tm: 17.117,
      br_elemenata: 5,
    },
    {
      br_cilindra: "11 1/2",
      obim: 292.1,
      br_zubaca: 92.0,
      sirina: 48.683,
      br_komada_tm: 20.541,
      br_elemenata: 6,
    },
    {
      br_cilindra: "11 1/2",
      obim: 292.1,
      br_zubaca: 92.0,
      sirina: 41.729,
      br_komada_tm: 23.964,
      br_elemenata: 7,
    },
    {
      br_cilindra: "11 1/2",
      obim: 292.1,
      br_zubaca: 92.0,
      sirina: 36.513,
      br_komada_tm: 27.388,
      br_elemenata: 8,
    },
    {
      br_cilindra: "11 1/2",
      obim: 292.1,
      br_zubaca: 92.0,
      sirina: 32.456,
      br_komada_tm: 30.811,
      br_elemenata: 9,
    },
    {
      br_cilindra: "11 1/2",
      obim: 292.1,
      br_zubaca: 92.0,
      sirina: 29.21,
      br_komada_tm: 34.235,
      br_elemenata: 10,
    },
    {
      br_cilindra: "11 1/2",
      obim: 292.1,
      br_zubaca: 92.0,
      sirina: 26.555,
      br_komada_tm: 37.658,
      br_elemenata: 11,
    },
    {
      br_cilindra: "11 1/2",
      obim: 292.1,
      br_zubaca: 92.0,
      sirina: 24.342,
      br_komada_tm: 41.082,
      br_elemenata: 12,
    },
    {
      br_cilindra: "11 1/2",
      obim: 292.1,
      br_zubaca: 92.0,
      sirina: 22.469,
      br_komada_tm: 44.505,
      br_elemenata: 13,
    },
    {
      br_cilindra: "11 1/2",
      obim: 292.1,
      br_zubaca: 92.0,
      sirina: 20.864,
      br_komada_tm: 47.929,
      br_elemenata: 14,
    },
    {
      br_cilindra: "11 1/2",
      obim: 292.1,
      br_zubaca: 92.0,
      sirina: 19.473,
      br_komada_tm: 51.352,
      br_elemenata: 15,
    },
    {
      br_cilindra: "11 1/2",
      obim: 292.1,
      br_zubaca: 92.0,
      sirina: 18.256,
      br_komada_tm: 54.776,
      br_elemenata: 16,
    },
    {
      br_cilindra: "11 1/2",
      obim: 292.1,
      br_zubaca: 92.0,
      sirina: 17.182,
      br_komada_tm: 58.199,
      br_elemenata: 17,
    },
    {
      br_cilindra: "11 1/2",
      obim: 292.1,
      br_zubaca: 92.0,
      sirina: 16.228,
      br_komada_tm: 61.623,
      br_elemenata: 18,
    },
    {
      br_cilindra: "11 1/2",
      obim: 292.1,
      br_zubaca: 92.0,
      sirina: 15.374,
      br_komada_tm: 65.046,
      br_elemenata: 19,
    },
    {
      br_cilindra: "11 1/2",
      obim: 292.1,
      br_zubaca: 92.0,
      sirina: 14.605,
      br_komada_tm: 68.47,
      br_elemenata: 20,
    },
    {
      br_cilindra: "12",
      obim: 304.8,
      br_zubaca: 96.0,
      sirina: 304.8,
      br_komada_tm: 3.281,
      br_elemenata: 1,
    },
    {
      br_cilindra: "12",
      obim: 304.8,
      br_zubaca: 96.0,
      sirina: 152.4,
      br_komada_tm: 6.562,
      br_elemenata: 2,
    },
    {
      br_cilindra: "12",
      obim: 304.8,
      br_zubaca: 96.0,
      sirina: 101.6,
      br_komada_tm: 9.843,
      br_elemenata: 3,
    },
    {
      br_cilindra: "12",
      obim: 304.8,
      br_zubaca: 96.0,
      sirina: 76.2,
      br_komada_tm: 13.123,
      br_elemenata: 4,
    },
    {
      br_cilindra: "12",
      obim: 304.8,
      br_zubaca: 96.0,
      sirina: 60.96,
      br_komada_tm: 16.404,
      br_elemenata: 5,
    },
    {
      br_cilindra: "12",
      obim: 304.8,
      br_zubaca: 96.0,
      sirina: 50.8,
      br_komada_tm: 19.685,
      br_elemenata: 6,
    },
    {
      br_cilindra: "12",
      obim: 304.8,
      br_zubaca: 96.0,
      sirina: 43.543,
      br_komada_tm: 22.966,
      br_elemenata: 7,
    },
    {
      br_cilindra: "12",
      obim: 304.8,
      br_zubaca: 96.0,
      sirina: 38.1,
      br_komada_tm: 26.247,
      br_elemenata: 8,
    },
    {
      br_cilindra: "12",
      obim: 304.8,
      br_zubaca: 96.0,
      sirina: 33.867,
      br_komada_tm: 29.528,
      br_elemenata: 9,
    },
    {
      br_cilindra: "12",
      obim: 304.8,
      br_zubaca: 96.0,
      sirina: 30.48,
      br_komada_tm: 32.808,
      br_elemenata: 10,
    },
    {
      br_cilindra: "12",
      obim: 304.8,
      br_zubaca: 96.0,
      sirina: 27.709,
      br_komada_tm: 36.089,
      br_elemenata: 11,
    },
    {
      br_cilindra: "12",
      obim: 304.8,
      br_zubaca: 96.0,
      sirina: 25.4,
      br_komada_tm: 39.37,
      br_elemenata: 12,
    },
    {
      br_cilindra: "12",
      obim: 304.8,
      br_zubaca: 96.0,
      sirina: 23.446,
      br_komada_tm: 42.651,
      br_elemenata: 13,
    },
    {
      br_cilindra: "12",
      obim: 304.8,
      br_zubaca: 96.0,
      sirina: 21.771,
      br_komada_tm: 45.932,
      br_elemenata: 14,
    },
    {
      br_cilindra: "12",
      obim: 304.8,
      br_zubaca: 96.0,
      sirina: 20.32,
      br_komada_tm: 49.213,
      br_elemenata: 15,
    },
    {
      br_cilindra: "12",
      obim: 304.8,
      br_zubaca: 96.0,
      sirina: 19.05,
      br_komada_tm: 52.493,
      br_elemenata: 16,
    },
    {
      br_cilindra: "12",
      obim: 304.8,
      br_zubaca: 96.0,
      sirina: 17.929,
      br_komada_tm: 55.774,
      br_elemenata: 17,
    },
    {
      br_cilindra: "12",
      obim: 304.8,
      br_zubaca: 96.0,
      sirina: 16.933,
      br_komada_tm: 59.055,
      br_elemenata: 18,
    },
    {
      br_cilindra: "12",
      obim: 304.8,
      br_zubaca: 96.0,
      sirina: 16.042,
      br_komada_tm: 62.336,
      br_elemenata: 19,
    },
    {
      br_cilindra: "12",
      obim: 304.8,
      br_zubaca: 96.0,
      sirina: 15.24,
      br_komada_tm: 65.617,
      br_elemenata: 20,
    },
    {
      br_cilindra: "12 1/8",
      obim: 307.98,
      br_zubaca: 97.0,
      sirina: 307.98,
      br_komada_tm: 3.247,
      br_elemenata: 1,
    },
    {
      br_cilindra: "12 1/8",
      obim: 307.98,
      br_zubaca: 97.0,
      sirina: 153.99,
      br_komada_tm: 6.494,
      br_elemenata: 2,
    },
    {
      br_cilindra: "12 1/8",
      obim: 307.98,
      br_zubaca: 97.0,
      sirina: 102.66,
      br_komada_tm: 9.741,
      br_elemenata: 3,
    },
    {
      br_cilindra: "12 1/8",
      obim: 307.98,
      br_zubaca: 97.0,
      sirina: 76.995,
      br_komada_tm: 12.988,
      br_elemenata: 4,
    },
    {
      br_cilindra: "12 1/8",
      obim: 307.98,
      br_zubaca: 97.0,
      sirina: 61.596,
      br_komada_tm: 16.235,
      br_elemenata: 5,
    },
    {
      br_cilindra: "12 1/8",
      obim: 307.98,
      br_zubaca: 97.0,
      sirina: 51.33,
      br_komada_tm: 19.482,
      br_elemenata: 6,
    },
    {
      br_cilindra: "12 1/8",
      obim: 307.98,
      br_zubaca: 97.0,
      sirina: 43.997,
      br_komada_tm: 22.729,
      br_elemenata: 7,
    },
    {
      br_cilindra: "12 1/8",
      obim: 307.98,
      br_zubaca: 97.0,
      sirina: 38.498,
      br_komada_tm: 25.976,
      br_elemenata: 8,
    },
    {
      br_cilindra: "12 1/8",
      obim: 307.98,
      br_zubaca: 97.0,
      sirina: 34.22,
      br_komada_tm: 29.223,
      br_elemenata: 9,
    },
    {
      br_cilindra: "12 1/8",
      obim: 307.98,
      br_zubaca: 97.0,
      sirina: 30.798,
      br_komada_tm: 32.47,
      br_elemenata: 10,
    },
    {
      br_cilindra: "12 1/4",
      obim: 311.15,
      br_zubaca: 98.0,
      sirina: 311.15,
      br_komada_tm: 3.214,
      br_elemenata: 1,
    },
    {
      br_cilindra: "12 1/4",
      obim: 311.15,
      br_zubaca: 98.0,
      sirina: 155.575,
      br_komada_tm: 6.428,
      br_elemenata: 2,
    },
    {
      br_cilindra: "12 1/4",
      obim: 311.15,
      br_zubaca: 98.0,
      sirina: 103.717,
      br_komada_tm: 9.642,
      br_elemenata: 3,
    },
    {
      br_cilindra: "12 1/4",
      obim: 311.15,
      br_zubaca: 98.0,
      sirina: 77.788,
      br_komada_tm: 12.856,
      br_elemenata: 4,
    },
    {
      br_cilindra: "12 1/4",
      obim: 311.15,
      br_zubaca: 98.0,
      sirina: 62.23,
      br_komada_tm: 16.069,
      br_elemenata: 5,
    },
    {
      br_cilindra: "12 1/4",
      obim: 311.15,
      br_zubaca: 98.0,
      sirina: 51.858,
      br_komada_tm: 19.283,
      br_elemenata: 6,
    },
    {
      br_cilindra: "12 1/4",
      obim: 311.15,
      br_zubaca: 98.0,
      sirina: 44.45,
      br_komada_tm: 22.497,
      br_elemenata: 7,
    },
    {
      br_cilindra: "12 1/4",
      obim: 311.15,
      br_zubaca: 98.0,
      sirina: 38.894,
      br_komada_tm: 25.711,
      br_elemenata: 8,
    },
    {
      br_cilindra: "12 1/4",
      obim: 311.15,
      br_zubaca: 98.0,
      sirina: 34.572,
      br_komada_tm: 28.925,
      br_elemenata: 9,
    },
    {
      br_cilindra: "12 1/4",
      obim: 311.15,
      br_zubaca: 98.0,
      sirina: 31.115,
      br_komada_tm: 32.139,
      br_elemenata: 10,
    },
    {
      br_cilindra: "12 1/4",
      obim: 311.15,
      br_zubaca: 98.0,
      sirina: 28.286,
      br_komada_tm: 35.353,
      br_elemenata: 11,
    },
    {
      br_cilindra: "12 1/4",
      obim: 311.15,
      br_zubaca: 98.0,
      sirina: 25.929,
      br_komada_tm: 38.567,
      br_elemenata: 12,
    },
    {
      br_cilindra: "12 1/4",
      obim: 311.15,
      br_zubaca: 98.0,
      sirina: 23.935,
      br_komada_tm: 41.78,
      br_elemenata: 13,
    },
    {
      br_cilindra: "12 1/4",
      obim: 311.15,
      br_zubaca: 98.0,
      sirina: 22.225,
      br_komada_tm: 44.994,
      br_elemenata: 14,
    },
    {
      br_cilindra: "12 1/4",
      obim: 311.15,
      br_zubaca: 98.0,
      sirina: 20.743,
      br_komada_tm: 48.208,
      br_elemenata: 15,
    },
    {
      br_cilindra: "12 1/4",
      obim: 311.15,
      br_zubaca: 98.0,
      sirina: 19.447,
      br_komada_tm: 51.422,
      br_elemenata: 16,
    },
    {
      br_cilindra: "12 1/4",
      obim: 311.15,
      br_zubaca: 98.0,
      sirina: 18.303,
      br_komada_tm: 54.636,
      br_elemenata: 17,
    },
    {
      br_cilindra: "12 1/4",
      obim: 311.15,
      br_zubaca: 98.0,
      sirina: 17.286,
      br_komada_tm: 57.85,
      br_elemenata: 18,
    },
    {
      br_cilindra: "12 1/4",
      obim: 311.15,
      br_zubaca: 98.0,
      sirina: 16.376,
      br_komada_tm: 61.064,
      br_elemenata: 19,
    },
    {
      br_cilindra: "12 1/4",
      obim: 311.15,
      br_zubaca: 98.0,
      sirina: 15.558,
      br_komada_tm: 64.278,
      br_elemenata: 20,
    },
    {
      br_cilindra: "12 3/8",
      obim: 314.33,
      br_zubaca: 99.0,
      sirina: 314.33,
      br_komada_tm: 3.181,
      br_elemenata: 1,
    },
    {
      br_cilindra: "12 3/8",
      obim: 314.33,
      br_zubaca: 99.0,
      sirina: 157.163,
      br_komada_tm: 6.362,
      br_elemenata: 2,
    },
    {
      br_cilindra: "12 3/8",
      obim: 314.33,
      br_zubaca: 99.0,
      sirina: 104.775,
      br_komada_tm: 9.544,
      br_elemenata: 3,
    },
    {
      br_cilindra: "12 3/8",
      obim: 314.33,
      br_zubaca: 99.0,
      sirina: 78.581,
      br_komada_tm: 12.725,
      br_elemenata: 4,
    },
    {
      br_cilindra: "12 3/8",
      obim: 314.33,
      br_zubaca: 99.0,
      sirina: 62.865,
      br_komada_tm: 15.906,
      br_elemenata: 5,
    },
    {
      br_cilindra: "12 3/8",
      obim: 314.33,
      br_zubaca: 99.0,
      sirina: 52.388,
      br_komada_tm: 19.088,
      br_elemenata: 6,
    },
    {
      br_cilindra: "12 3/8",
      obim: 314.33,
      br_zubaca: 99.0,
      sirina: 44.904,
      br_komada_tm: 22.269,
      br_elemenata: 7,
    },
    {
      br_cilindra: "12 3/8",
      obim: 314.33,
      br_zubaca: 99.0,
      sirina: 39.291,
      br_komada_tm: 25.451,
      br_elemenata: 8,
    },
    {
      br_cilindra: "12 3/8",
      obim: 314.33,
      br_zubaca: 99.0,
      sirina: 34.926,
      br_komada_tm: 28.632,
      br_elemenata: 9,
    },
    {
      br_cilindra: "12 3/8",
      obim: 314.33,
      br_zubaca: 99.0,
      sirina: 31.433,
      br_komada_tm: 31.813,
      br_elemenata: 10,
    },
    {
      br_cilindra: "12 3/4",
      obim: 323.85,
      br_zubaca: 102.0,
      sirina: 323.85,
      br_komada_tm: 3.088,
      br_elemenata: 1,
    },
    {
      br_cilindra: "12 3/4",
      obim: 323.85,
      br_zubaca: 102.0,
      sirina: 161.925,
      br_komada_tm: 6.176,
      br_elemenata: 2,
    },
    {
      br_cilindra: "12 3/4",
      obim: 323.85,
      br_zubaca: 102.0,
      sirina: 107.95,
      br_komada_tm: 9.264,
      br_elemenata: 3,
    },
    {
      br_cilindra: "12 3/4",
      obim: 323.85,
      br_zubaca: 102.0,
      sirina: 80.963,
      br_komada_tm: 12.351,
      br_elemenata: 4,
    },
    {
      br_cilindra: "12 3/4",
      obim: 323.85,
      br_zubaca: 102.0,
      sirina: 64.77,
      br_komada_tm: 15.439,
      br_elemenata: 5,
    },
    {
      br_cilindra: "12 3/4",
      obim: 323.85,
      br_zubaca: 102.0,
      sirina: 53.975,
      br_komada_tm: 18.527,
      br_elemenata: 6,
    },
    {
      br_cilindra: "12 3/4",
      obim: 323.85,
      br_zubaca: 102.0,
      sirina: 46.264,
      br_komada_tm: 21.615,
      br_elemenata: 7,
    },
    {
      br_cilindra: "12 3/4",
      obim: 323.85,
      br_zubaca: 102.0,
      sirina: 40.481,
      br_komada_tm: 24.703,
      br_elemenata: 8,
    },
    {
      br_cilindra: "12 3/4",
      obim: 323.85,
      br_zubaca: 102.0,
      sirina: 35.983,
      br_komada_tm: 27.791,
      br_elemenata: 9,
    },
    {
      br_cilindra: "12 3/4",
      obim: 323.85,
      br_zubaca: 102.0,
      sirina: 32.385,
      br_komada_tm: 30.878,
      br_elemenata: 10,
    },
    {
      br_cilindra: "12 3/4",
      obim: 323.85,
      br_zubaca: 102.0,
      sirina: 29.441,
      br_komada_tm: 33.966,
      br_elemenata: 11,
    },
    {
      br_cilindra: "12 3/4",
      obim: 323.85,
      br_zubaca: 102.0,
      sirina: 26.988,
      br_komada_tm: 37.054,
      br_elemenata: 12,
    },
    {
      br_cilindra: "12 3/4",
      obim: 323.85,
      br_zubaca: 102.0,
      sirina: 24.912,
      br_komada_tm: 40.142,
      br_elemenata: 13,
    },
    {
      br_cilindra: "12 3/4",
      obim: 323.85,
      br_zubaca: 102.0,
      sirina: 23.132,
      br_komada_tm: 43.23,
      br_elemenata: 14,
    },
    {
      br_cilindra: "12 3/4",
      obim: 323.85,
      br_zubaca: 102.0,
      sirina: 21.59,
      br_komada_tm: 46.318,
      br_elemenata: 15,
    },
    {
      br_cilindra: "12 3/4",
      obim: 323.85,
      br_zubaca: 102.0,
      sirina: 20.241,
      br_komada_tm: 49.406,
      br_elemenata: 16,
    },
    {
      br_cilindra: "12 3/4",
      obim: 323.85,
      br_zubaca: 102.0,
      sirina: 19.05,
      br_komada_tm: 52.493,
      br_elemenata: 17,
    },
    {
      br_cilindra: "12 3/4",
      obim: 323.85,
      br_zubaca: 102.0,
      sirina: 17.992,
      br_komada_tm: 55.581,
      br_elemenata: 18,
    },
    {
      br_cilindra: "12 3/4",
      obim: 323.85,
      br_zubaca: 102.0,
      sirina: 17.045,
      br_komada_tm: 58.669,
      br_elemenata: 19,
    },
    {
      br_cilindra: "12 3/4",
      obim: 323.85,
      br_zubaca: 102.0,
      sirina: 16.193,
      br_komada_tm: 61.757,
      br_elemenata: 20,
    },
    {
      br_cilindra: "12 7/8",
      obim: 327.03,
      br_zubaca: 102.998,
      sirina: 327.03,
      br_komada_tm: 3.058,
      br_elemenata: 1,
    },
    {
      br_cilindra: "12 7/8",
      obim: 327.03,
      br_zubaca: 102.998,
      sirina: 163.515,
      br_komada_tm: 6.116,
      br_elemenata: 2,
    },
    {
      br_cilindra: "12 7/8",
      obim: 327.03,
      br_zubaca: 102.998,
      sirina: 109.01,
      br_komada_tm: 9.174,
      br_elemenata: 3,
    },
    {
      br_cilindra: "12 7/8",
      obim: 327.03,
      br_zubaca: 102.998,
      sirina: 81.758,
      br_komada_tm: 12.232,
      br_elemenata: 4,
    },
    {
      br_cilindra: "12 7/8",
      obim: 327.03,
      br_zubaca: 102.998,
      sirina: 65.406,
      br_komada_tm: 15.29,
      br_elemenata: 5,
    },
    {
      br_cilindra: "12 7/8",
      obim: 327.03,
      br_zubaca: 102.998,
      sirina: 54.505,
      br_komada_tm: 18.348,
      br_elemenata: 6,
    },
    {
      br_cilindra: "12 7/8",
      obim: 327.03,
      br_zubaca: 102.998,
      sirina: 46.719,
      br_komada_tm: 21.405,
      br_elemenata: 7,
    },
    {
      br_cilindra: "12 7/8",
      obim: 327.03,
      br_zubaca: 102.998,
      sirina: 40.879,
      br_komada_tm: 24.463,
      br_elemenata: 8,
    },
    {
      br_cilindra: "12 7/8",
      obim: 327.03,
      br_zubaca: 102.998,
      sirina: 36.337,
      br_komada_tm: 27.521,
      br_elemenata: 9,
    },
    {
      br_cilindra: "12 7/8",
      obim: 327.03,
      br_zubaca: 102.998,
      sirina: 32.703,
      br_komada_tm: 30.579,
      br_elemenata: 10,
    },
    {
      br_cilindra: "12 7/8",
      obim: 327.03,
      br_zubaca: 102.998,
      sirina: 29.729,
      br_komada_tm: 33.637,
      br_elemenata: 11,
    },
    {
      br_cilindra: "12 7/8",
      obim: 327.03,
      br_zubaca: 102.998,
      sirina: 27.252,
      br_komada_tm: 36.695,
      br_elemenata: 12,
    },
    {
      br_cilindra: "12 7/8",
      obim: 327.03,
      br_zubaca: 102.998,
      sirina: 25.155,
      br_komada_tm: 39.753,
      br_elemenata: 13,
    },
    {
      br_cilindra: "12 7/8",
      obim: 327.03,
      br_zubaca: 102.998,
      sirina: 23.359,
      br_komada_tm: 42.811,
      br_elemenata: 14,
    },
    {
      br_cilindra: "12 7/8",
      obim: 327.03,
      br_zubaca: 102.998,
      sirina: 21.801,
      br_komada_tm: 45.869,
      br_elemenata: 15,
    },
    {
      br_cilindra: "12 7/8",
      obim: 327.03,
      br_zubaca: 102.998,
      sirina: 20.439,
      br_komada_tm: 48.927,
      br_elemenata: 16,
    },
    {
      br_cilindra: "12 7/8",
      obim: 327.03,
      br_zubaca: 102.998,
      sirina: 19.236,
      br_komada_tm: 51.985,
      br_elemenata: 17,
    },
    {
      br_cilindra: "12 7/8",
      obim: 327.03,
      br_zubaca: 102.998,
      sirina: 18.168,
      br_komada_tm: 55.043,
      br_elemenata: 18,
    },
    {
      br_cilindra: "12 7/8",
      obim: 327.03,
      br_zubaca: 102.998,
      sirina: 17.212,
      br_komada_tm: 58.1,
      br_elemenata: 19,
    },
    {
      br_cilindra: "12 7/8",
      obim: 327.03,
      br_zubaca: 102.998,
      sirina: 16.351,
      br_komada_tm: 61.158,
      br_elemenata: 20,
    },
    {
      br_cilindra: "13",
      obim: 330.2,
      br_zubaca: 104.0,
      sirina: 330.2,
      br_komada_tm: 3.028,
      br_elemenata: 1,
    },
    {
      br_cilindra: "13",
      obim: 330.2,
      br_zubaca: 104.0,
      sirina: 165.1,
      br_komada_tm: 6.057,
      br_elemenata: 2,
    },
    {
      br_cilindra: "13",
      obim: 330.2,
      br_zubaca: 104.0,
      sirina: 110.067,
      br_komada_tm: 9.085,
      br_elemenata: 3,
    },
    {
      br_cilindra: "13",
      obim: 330.2,
      br_zubaca: 104.0,
      sirina: 82.55,
      br_komada_tm: 12.114,
      br_elemenata: 4,
    },
    {
      br_cilindra: "13",
      obim: 330.2,
      br_zubaca: 104.0,
      sirina: 66.04,
      br_komada_tm: 15.142,
      br_elemenata: 5,
    },
    {
      br_cilindra: "13",
      obim: 330.2,
      br_zubaca: 104.0,
      sirina: 55.033,
      br_komada_tm: 18.171,
      br_elemenata: 6,
    },
    {
      br_cilindra: "13",
      obim: 330.2,
      br_zubaca: 104.0,
      sirina: 47.171,
      br_komada_tm: 21.199,
      br_elemenata: 7,
    },
    {
      br_cilindra: "13",
      obim: 330.2,
      br_zubaca: 104.0,
      sirina: 41.275,
      br_komada_tm: 24.228,
      br_elemenata: 8,
    },
    {
      br_cilindra: "13",
      obim: 330.2,
      br_zubaca: 104.0,
      sirina: 36.689,
      br_komada_tm: 27.256,
      br_elemenata: 9,
    },
    {
      br_cilindra: "13",
      obim: 330.2,
      br_zubaca: 104.0,
      sirina: 33.02,
      br_komada_tm: 30.285,
      br_elemenata: 10,
    },
    {
      br_cilindra: "13",
      obim: 330.2,
      br_zubaca: 104.0,
      sirina: 30.018,
      br_komada_tm: 33.313,
      br_elemenata: 11,
    },
    {
      br_cilindra: "13",
      obim: 330.2,
      br_zubaca: 104.0,
      sirina: 27.517,
      br_komada_tm: 36.342,
      br_elemenata: 12,
    },
    {
      br_cilindra: "13",
      obim: 330.2,
      br_zubaca: 104.0,
      sirina: 25.4,
      br_komada_tm: 39.37,
      br_elemenata: 13,
    },
    {
      br_cilindra: "13",
      obim: 330.2,
      br_zubaca: 104.0,
      sirina: 23.586,
      br_komada_tm: 42.399,
      br_elemenata: 14,
    },
    {
      br_cilindra: "13",
      obim: 330.2,
      br_zubaca: 104.0,
      sirina: 22.013,
      br_komada_tm: 45.427,
      br_elemenata: 15,
    },
    {
      br_cilindra: "13",
      obim: 330.2,
      br_zubaca: 104.0,
      sirina: 20.638,
      br_komada_tm: 48.455,
      br_elemenata: 16,
    },
    {
      br_cilindra: "13",
      obim: 330.2,
      br_zubaca: 104.0,
      sirina: 19.424,
      br_komada_tm: 51.484,
      br_elemenata: 17,
    },
    {
      br_cilindra: "13",
      obim: 330.2,
      br_zubaca: 104.0,
      sirina: 18.344,
      br_komada_tm: 54.512,
      br_elemenata: 18,
    },
    {
      br_cilindra: "13",
      obim: 330.2,
      br_zubaca: 104.0,
      sirina: 17.379,
      br_komada_tm: 57.541,
      br_elemenata: 19,
    },
    {
      br_cilindra: "13",
      obim: 330.2,
      br_zubaca: 104.0,
      sirina: 16.51,
      br_komada_tm: 60.569,
      br_elemenata: 20,
    },
    {
      br_cilindra: "13 1/4",
      obim: 336.55,
      br_zubaca: 106.0,
      sirina: 336.55,
      br_komada_tm: 2.971,
      br_elemenata: 1,
    },
    {
      br_cilindra: "13 1/4",
      obim: 336.55,
      br_zubaca: 106.0,
      sirina: 168.275,
      br_komada_tm: 5.943,
      br_elemenata: 2,
    },
    {
      br_cilindra: "13 1/4",
      obim: 336.55,
      br_zubaca: 106.0,
      sirina: 112.183,
      br_komada_tm: 8.914,
      br_elemenata: 3,
    },
    {
      br_cilindra: "13 1/4",
      obim: 336.55,
      br_zubaca: 106.0,
      sirina: 84.138,
      br_komada_tm: 11.885,
      br_elemenata: 4,
    },
    {
      br_cilindra: "13 1/4",
      obim: 336.55,
      br_zubaca: 106.0,
      sirina: 67.31,
      br_komada_tm: 14.857,
      br_elemenata: 5,
    },
    {
      br_cilindra: "13 1/4",
      obim: 336.55,
      br_zubaca: 106.0,
      sirina: 56.092,
      br_komada_tm: 17.828,
      br_elemenata: 6,
    },
    {
      br_cilindra: "13 1/4",
      obim: 336.55,
      br_zubaca: 106.0,
      sirina: 48.079,
      br_komada_tm: 20.799,
      br_elemenata: 7,
    },
    {
      br_cilindra: "13 1/4",
      obim: 336.55,
      br_zubaca: 106.0,
      sirina: 42.069,
      br_komada_tm: 23.771,
      br_elemenata: 8,
    },
    {
      br_cilindra: "13 1/4",
      obim: 336.55,
      br_zubaca: 106.0,
      sirina: 37.394,
      br_komada_tm: 26.742,
      br_elemenata: 9,
    },
    {
      br_cilindra: "13 1/4",
      obim: 336.55,
      br_zubaca: 106.0,
      sirina: 33.655,
      br_komada_tm: 29.713,
      br_elemenata: 10,
    },
    {
      br_cilindra: "13 1/4",
      obim: 336.55,
      br_zubaca: 106.0,
      sirina: 30.595,
      br_komada_tm: 32.685,
      br_elemenata: 11,
    },
    {
      br_cilindra: "13 1/4",
      obim: 336.55,
      br_zubaca: 106.0,
      sirina: 28.046,
      br_komada_tm: 35.656,
      br_elemenata: 12,
    },
    {
      br_cilindra: "13 1/4",
      obim: 336.55,
      br_zubaca: 106.0,
      sirina: 25.888,
      br_komada_tm: 38.627,
      br_elemenata: 13,
    },
    {
      br_cilindra: "13 1/4",
      obim: 336.55,
      br_zubaca: 106.0,
      sirina: 24.039,
      br_komada_tm: 41.599,
      br_elemenata: 14,
    },
    {
      br_cilindra: "13 1/4",
      obim: 336.55,
      br_zubaca: 106.0,
      sirina: 22.437,
      br_komada_tm: 44.57,
      br_elemenata: 15,
    },
    {
      br_cilindra: "13 1/4",
      obim: 336.55,
      br_zubaca: 106.0,
      sirina: 21.034,
      br_komada_tm: 47.541,
      br_elemenata: 16,
    },
    {
      br_cilindra: "13 1/4",
      obim: 336.55,
      br_zubaca: 106.0,
      sirina: 19.797,
      br_komada_tm: 50.513,
      br_elemenata: 17,
    },
    {
      br_cilindra: "13 1/4",
      obim: 336.55,
      br_zubaca: 106.0,
      sirina: 18.697,
      br_komada_tm: 53.484,
      br_elemenata: 18,
    },
    {
      br_cilindra: "13 1/4",
      obim: 336.55,
      br_zubaca: 106.0,
      sirina: 17.713,
      br_komada_tm: 56.455,
      br_elemenata: 19,
    },
    {
      br_cilindra: "13 1/4",
      obim: 336.55,
      br_zubaca: 106.0,
      sirina: 16.828,
      br_komada_tm: 0.0,
      br_elemenata: 20,
    },
    {
      br_cilindra: "13 3/8",
      obim: 339.73,
      br_zubaca: 107.002,
      sirina: 339.73,
      br_komada_tm: 2.944,
      br_elemenata: 1,
    },
    {
      br_cilindra: "13 3/8",
      obim: 339.73,
      br_zubaca: 107.002,
      sirina: 169.865,
      br_komada_tm: 5.887,
      br_elemenata: 2,
    },
    {
      br_cilindra: "13 3/8",
      obim: 339.73,
      br_zubaca: 107.002,
      sirina: 113.243,
      br_komada_tm: 8.831,
      br_elemenata: 3,
    },
    {
      br_cilindra: "13 3/8",
      obim: 339.73,
      br_zubaca: 107.002,
      sirina: 84.933,
      br_komada_tm: 11.774,
      br_elemenata: 4,
    },
    {
      br_cilindra: "13 3/8",
      obim: 339.73,
      br_zubaca: 107.002,
      sirina: 67.946,
      br_komada_tm: 14.718,
      br_elemenata: 5,
    },
    {
      br_cilindra: "13 3/8",
      obim: 339.73,
      br_zubaca: 107.002,
      sirina: 56.622,
      br_komada_tm: 17.661,
      br_elemenata: 6,
    },
    {
      br_cilindra: "13 3/8",
      obim: 339.73,
      br_zubaca: 107.002,
      sirina: 48.533,
      br_komada_tm: 20.605,
      br_elemenata: 7,
    },
    {
      br_cilindra: "13 3/8",
      obim: 339.73,
      br_zubaca: 107.002,
      sirina: 42.466,
      br_komada_tm: 23.548,
      br_elemenata: 8,
    },
    {
      br_cilindra: "13 3/8",
      obim: 339.73,
      br_zubaca: 107.002,
      sirina: 37.748,
      br_komada_tm: 26.492,
      br_elemenata: 9,
    },
    {
      br_cilindra: "13 3/8",
      obim: 339.73,
      br_zubaca: 107.002,
      sirina: 33.973,
      br_komada_tm: 29.435,
      br_elemenata: 10,
    },
    {
      br_cilindra: "13 3/8",
      obim: 339.73,
      br_zubaca: 107.002,
      sirina: 30.885,
      br_komada_tm: 32.379,
      br_elemenata: 11,
    },
    {
      br_cilindra: "13 3/8",
      obim: 339.73,
      br_zubaca: 107.002,
      sirina: 28.311,
      br_komada_tm: 35.322,
      br_elemenata: 12,
    },
    {
      br_cilindra: "13 3/8",
      obim: 339.73,
      br_zubaca: 107.002,
      sirina: 26.133,
      br_komada_tm: 38.266,
      br_elemenata: 13,
    },
    {
      br_cilindra: "13 3/8",
      obim: 339.73,
      br_zubaca: 107.002,
      sirina: 24.266,
      br_komada_tm: 41.209,
      br_elemenata: 14,
    },
    {
      br_cilindra: "13 3/8",
      obim: 339.73,
      br_zubaca: 107.002,
      sirina: 22.649,
      br_komada_tm: 44.153,
      br_elemenata: 15,
    },
    {
      br_cilindra: "13 3/8",
      obim: 339.73,
      br_zubaca: 107.002,
      sirina: 21.233,
      br_komada_tm: 47.096,
      br_elemenata: 16,
    },
    {
      br_cilindra: "13 3/8",
      obim: 339.73,
      br_zubaca: 107.002,
      sirina: 19.984,
      br_komada_tm: 50.04,
      br_elemenata: 17,
    },
    {
      br_cilindra: "13 3/8",
      obim: 339.73,
      br_zubaca: 107.002,
      sirina: 18.874,
      br_komada_tm: 52.983,
      br_elemenata: 18,
    },
    {
      br_cilindra: "13 3/8",
      obim: 339.73,
      br_zubaca: 107.002,
      sirina: 17.881,
      br_komada_tm: 55.927,
      br_elemenata: 19,
    },
    {
      br_cilindra: "13 3/8",
      obim: 339.73,
      br_zubaca: 107.002,
      sirina: 16.987,
      br_komada_tm: 58.87,
      br_elemenata: 20,
    },
    {
      br_cilindra: "13 5/8",
      obim: 346.075,
      br_zubaca: 109.0,
      sirina: 346.075,
      br_komada_tm: 2.89,
      br_elemenata: 1,
    },
    {
      br_cilindra: "13 5/8",
      obim: 346.075,
      br_zubaca: 109.0,
      sirina: 173.038,
      br_komada_tm: 5.779,
      br_elemenata: 2,
    },
    {
      br_cilindra: "13 5/8",
      obim: 346.075,
      br_zubaca: 109.0,
      sirina: 115.358,
      br_komada_tm: 8.669,
      br_elemenata: 3,
    },
    {
      br_cilindra: "13 5/8",
      obim: 346.075,
      br_zubaca: 109.0,
      sirina: 86.519,
      br_komada_tm: 11.558,
      br_elemenata: 4,
    },
    {
      br_cilindra: "13 5/8",
      obim: 346.075,
      br_zubaca: 109.0,
      sirina: 69.215,
      br_komada_tm: 14.448,
      br_elemenata: 5,
    },
    {
      br_cilindra: "13 5/8",
      obim: 346.075,
      br_zubaca: 109.0,
      sirina: 57.679,
      br_komada_tm: 17.337,
      br_elemenata: 6,
    },
    {
      br_cilindra: "13 5/8",
      obim: 346.075,
      br_zubaca: 109.0,
      sirina: 49.439,
      br_komada_tm: 20.227,
      br_elemenata: 7,
    },
    {
      br_cilindra: "13 5/8",
      obim: 346.075,
      br_zubaca: 109.0,
      sirina: 43.259,
      br_komada_tm: 23.116,
      br_elemenata: 8,
    },
    {
      br_cilindra: "13 5/8",
      obim: 346.075,
      br_zubaca: 109.0,
      sirina: 38.453,
      br_komada_tm: 26.006,
      br_elemenata: 9,
    },
    {
      br_cilindra: "13 5/8",
      obim: 346.075,
      br_zubaca: 109.0,
      sirina: 34.608,
      br_komada_tm: 28.895,
      br_elemenata: 10,
    },
    {
      br_cilindra: "14",
      obim: 355.6,
      br_zubaca: 112.0,
      sirina: 355.6,
      br_komada_tm: 2.812,
      br_elemenata: 1,
    },
    {
      br_cilindra: "14",
      obim: 355.6,
      br_zubaca: 112.0,
      sirina: 177.8,
      br_komada_tm: 5.624,
      br_elemenata: 2,
    },
    {
      br_cilindra: "14",
      obim: 355.6,
      br_zubaca: 112.0,
      sirina: 118.533,
      br_komada_tm: 8.436,
      br_elemenata: 3,
    },
    {
      br_cilindra: "14",
      obim: 355.6,
      br_zubaca: 112.0,
      sirina: 88.9,
      br_komada_tm: 11.249,
      br_elemenata: 4,
    },
    {
      br_cilindra: "14",
      obim: 355.6,
      br_zubaca: 112.0,
      sirina: 71.12,
      br_komada_tm: 14.061,
      br_elemenata: 5,
    },
    {
      br_cilindra: "14",
      obim: 355.6,
      br_zubaca: 112.0,
      sirina: 59.267,
      br_komada_tm: 16.873,
      br_elemenata: 6,
    },
    {
      br_cilindra: "14",
      obim: 355.6,
      br_zubaca: 112.0,
      sirina: 50.8,
      br_komada_tm: 19.685,
      br_elemenata: 7,
    },
    {
      br_cilindra: "14",
      obim: 355.6,
      br_zubaca: 112.0,
      sirina: 44.45,
      br_komada_tm: 22.497,
      br_elemenata: 8,
    },
    {
      br_cilindra: "14",
      obim: 355.6,
      br_zubaca: 112.0,
      sirina: 39.511,
      br_komada_tm: 25.309,
      br_elemenata: 9,
    },
    {
      br_cilindra: "14",
      obim: 355.6,
      br_zubaca: 112.0,
      sirina: 35.56,
      br_komada_tm: 28.121,
      br_elemenata: 10,
    },
    {
      br_cilindra: "14",
      obim: 355.6,
      br_zubaca: 112.0,
      sirina: 32.327,
      br_komada_tm: 30.934,
      br_elemenata: 11,
    },
    {
      br_cilindra: "14",
      obim: 355.6,
      br_zubaca: 112.0,
      sirina: 29.633,
      br_komada_tm: 33.746,
      br_elemenata: 12,
    },
    {
      br_cilindra: "14",
      obim: 355.6,
      br_zubaca: 112.0,
      sirina: 27.354,
      br_komada_tm: 36.558,
      br_elemenata: 13,
    },
    {
      br_cilindra: "14",
      obim: 355.6,
      br_zubaca: 112.0,
      sirina: 25.4,
      br_komada_tm: 39.37,
      br_elemenata: 14,
    },
    {
      br_cilindra: "14",
      obim: 355.6,
      br_zubaca: 112.0,
      sirina: 23.707,
      br_komada_tm: 42.182,
      br_elemenata: 15,
    },
    {
      br_cilindra: "14",
      obim: 355.6,
      br_zubaca: 112.0,
      sirina: 22.225,
      br_komada_tm: 44.994,
      br_elemenata: 16,
    },
    {
      br_cilindra: "14",
      obim: 355.6,
      br_zubaca: 112.0,
      sirina: 20.918,
      br_komada_tm: 47.807,
      br_elemenata: 17,
    },
    {
      br_cilindra: "14",
      obim: 355.6,
      br_zubaca: 112.0,
      sirina: 19.756,
      br_komada_tm: 50.619,
      br_elemenata: 18,
    },
    {
      br_cilindra: "14",
      obim: 355.6,
      br_zubaca: 112.0,
      sirina: 18.716,
      br_komada_tm: 53.431,
      br_elemenata: 19,
    },
    {
      br_cilindra: "14",
      obim: 355.6,
      br_zubaca: 112.0,
      sirina: 17.78,
      br_komada_tm: 56.243,
      br_elemenata: 20,
    },
    {
      br_cilindra: "14 1/4",
      obim: 361.95,
      br_zubaca: 114.0,
      sirina: 361.95,
      br_komada_tm: 2.763,
      br_elemenata: 1,
    },
    {
      br_cilindra: "14 1/4",
      obim: 361.95,
      br_zubaca: 114.0,
      sirina: 180.975,
      br_komada_tm: 5.526,
      br_elemenata: 2,
    },
    {
      br_cilindra: "14 1/4",
      obim: 361.95,
      br_zubaca: 114.0,
      sirina: 120.65,
      br_komada_tm: 8.288,
      br_elemenata: 3,
    },
    {
      br_cilindra: "14 1/4",
      obim: 361.95,
      br_zubaca: 114.0,
      sirina: 90.488,
      br_komada_tm: 11.051,
      br_elemenata: 4,
    },
    {
      br_cilindra: "14 1/4",
      obim: 361.95,
      br_zubaca: 114.0,
      sirina: 72.39,
      br_komada_tm: 13.814,
      br_elemenata: 5,
    },
    {
      br_cilindra: "14 1/4",
      obim: 361.95,
      br_zubaca: 114.0,
      sirina: 60.325,
      br_komada_tm: 16.577,
      br_elemenata: 6,
    },
    {
      br_cilindra: "14 1/4",
      obim: 361.95,
      br_zubaca: 114.0,
      sirina: 51.707,
      br_komada_tm: 19.34,
      br_elemenata: 7,
    },
    {
      br_cilindra: "14 1/4",
      obim: 361.95,
      br_zubaca: 114.0,
      sirina: 45.244,
      br_komada_tm: 22.103,
      br_elemenata: 8,
    },
    {
      br_cilindra: "14 1/4",
      obim: 361.95,
      br_zubaca: 114.0,
      sirina: 40.217,
      br_komada_tm: 24.865,
      br_elemenata: 9,
    },
    {
      br_cilindra: "14 1/4",
      obim: 361.95,
      br_zubaca: 114.0,
      sirina: 36.195,
      br_komada_tm: 27.628,
      br_elemenata: 10,
    },
    {
      br_cilindra: "14 1/2",
      obim: 368.3,
      br_zubaca: 116.0,
      sirina: 368.3,
      br_komada_tm: 2.715,
      br_elemenata: 1,
    },
    {
      br_cilindra: "14 1/2",
      obim: 368.3,
      br_zubaca: 116.0,
      sirina: 184.15,
      br_komada_tm: 5.43,
      br_elemenata: 2,
    },
    {
      br_cilindra: "14 1/2",
      obim: 368.3,
      br_zubaca: 116.0,
      sirina: 122.767,
      br_komada_tm: 8.146,
      br_elemenata: 3,
    },
    {
      br_cilindra: "14 1/2",
      obim: 368.3,
      br_zubaca: 116.0,
      sirina: 92.075,
      br_komada_tm: 10.861,
      br_elemenata: 4,
    },
    {
      br_cilindra: "14 1/2",
      obim: 368.3,
      br_zubaca: 116.0,
      sirina: 73.66,
      br_komada_tm: 13.576,
      br_elemenata: 5,
    },
    {
      br_cilindra: "14 1/2",
      obim: 368.3,
      br_zubaca: 116.0,
      sirina: 61.383,
      br_komada_tm: 16.291,
      br_elemenata: 6,
    },
    {
      br_cilindra: "14 1/2",
      obim: 368.3,
      br_zubaca: 116.0,
      sirina: 52.614,
      br_komada_tm: 19.006,
      br_elemenata: 7,
    },
    {
      br_cilindra: "14 1/2",
      obim: 368.3,
      br_zubaca: 116.0,
      sirina: 46.038,
      br_komada_tm: 21.721,
      br_elemenata: 8,
    },
    {
      br_cilindra: "14 1/2",
      obim: 368.3,
      br_zubaca: 116.0,
      sirina: 40.922,
      br_komada_tm: 24.437,
      br_elemenata: 9,
    },
    {
      br_cilindra: "14 1/2",
      obim: 368.3,
      br_zubaca: 116.0,
      sirina: 36.83,
      br_komada_tm: 27.152,
      br_elemenata: 10,
    },
    {
      br_cilindra: "14 1/2",
      obim: 368.3,
      br_zubaca: 116.0,
      sirina: 33.482,
      br_komada_tm: 29.867,
      br_elemenata: 11,
    },
    {
      br_cilindra: "14 1/2",
      obim: 368.3,
      br_zubaca: 116.0,
      sirina: 30.692,
      br_komada_tm: 32.582,
      br_elemenata: 12,
    },
    {
      br_cilindra: "14 1/2",
      obim: 368.3,
      br_zubaca: 116.0,
      sirina: 28.331,
      br_komada_tm: 35.297,
      br_elemenata: 13,
    },
    {
      br_cilindra: "14 1/2",
      obim: 368.3,
      br_zubaca: 116.0,
      sirina: 26.307,
      br_komada_tm: 38.012,
      br_elemenata: 14,
    },
    {
      br_cilindra: "14 1/2",
      obim: 368.3,
      br_zubaca: 116.0,
      sirina: 24.553,
      br_komada_tm: 40.728,
      br_elemenata: 15,
    },
    {
      br_cilindra: "14 1/2",
      obim: 368.3,
      br_zubaca: 116.0,
      sirina: 23.019,
      br_komada_tm: 43.443,
      br_elemenata: 16,
    },
    {
      br_cilindra: "14 1/2",
      obim: 368.3,
      br_zubaca: 116.0,
      sirina: 21.665,
      br_komada_tm: 46.158,
      br_elemenata: 17,
    },
    {
      br_cilindra: "14 1/2",
      obim: 368.3,
      br_zubaca: 116.0,
      sirina: 20.461,
      br_komada_tm: 48.873,
      br_elemenata: 18,
    },
    {
      br_cilindra: "14 1/2",
      obim: 368.3,
      br_zubaca: 116.0,
      sirina: 19.384,
      br_komada_tm: 51.588,
      br_elemenata: 19,
    },
    {
      br_cilindra: "14 1/2",
      obim: 368.3,
      br_zubaca: 116.0,
      sirina: 18.415,
      br_komada_tm: 54.304,
      br_elemenata: 20,
    },
    {
      br_cilindra: "15 1/2",
      obim: 393.7,
      br_zubaca: 124.0,
      sirina: 393.7,
      br_komada_tm: 2.54,
      br_elemenata: 1,
    },
    {
      br_cilindra: "15 1/2",
      obim: 393.7,
      br_zubaca: 124.0,
      sirina: 196.85,
      br_komada_tm: 5.08,
      br_elemenata: 2,
    },
    {
      br_cilindra: "15 1/2",
      obim: 393.7,
      br_zubaca: 124.0,
      sirina: 131.233,
      br_komada_tm: 7.62,
      br_elemenata: 3,
    },
    {
      br_cilindra: "15 1/2",
      obim: 393.7,
      br_zubaca: 124.0,
      sirina: 98.425,
      br_komada_tm: 10.16,
      br_elemenata: 4,
    },
    {
      br_cilindra: "15 1/2",
      obim: 393.7,
      br_zubaca: 124.0,
      sirina: 78.74,
      br_komada_tm: 12.7,
      br_elemenata: 5,
    },
    {
      br_cilindra: "15 1/2",
      obim: 393.7,
      br_zubaca: 124.0,
      sirina: 65.617,
      br_komada_tm: 15.24,
      br_elemenata: 6,
    },
    {
      br_cilindra: "15 1/2",
      obim: 393.7,
      br_zubaca: 124.0,
      sirina: 56.243,
      br_komada_tm: 17.78,
      br_elemenata: 7,
    },
    {
      br_cilindra: "15 1/2",
      obim: 393.7,
      br_zubaca: 124.0,
      sirina: 49.213,
      br_komada_tm: 20.32,
      br_elemenata: 8,
    },
    {
      br_cilindra: "15 1/2",
      obim: 393.7,
      br_zubaca: 124.0,
      sirina: 43.744,
      br_komada_tm: 22.86,
      br_elemenata: 9,
    },
    {
      br_cilindra: "15 1/2",
      obim: 393.7,
      br_zubaca: 124.0,
      sirina: 39.37,
      br_komada_tm: 25.4,
      br_elemenata: 10,
    },
    {
      br_cilindra: "15 1/2",
      obim: 393.7,
      br_zubaca: 124.0,
      sirina: 35.791,
      br_komada_tm: 27.94,
      br_elemenata: 11,
    },
    {
      br_cilindra: "15 1/2",
      obim: 393.7,
      br_zubaca: 124.0,
      sirina: 32.808,
      br_komada_tm: 30.48,
      br_elemenata: 12,
    },
    {
      br_cilindra: "15 1/2",
      obim: 393.7,
      br_zubaca: 124.0,
      sirina: 30.285,
      br_komada_tm: 33.02,
      br_elemenata: 13,
    },
    {
      br_cilindra: "15 1/2",
      obim: 393.7,
      br_zubaca: 124.0,
      sirina: 28.121,
      br_komada_tm: 35.56,
      br_elemenata: 14,
    },
    {
      br_cilindra: "15 1/2",
      obim: 393.7,
      br_zubaca: 124.0,
      sirina: 26.247,
      br_komada_tm: 38.1,
      br_elemenata: 15,
    },
    {
      br_cilindra: "15 1/2",
      obim: 393.7,
      br_zubaca: 124.0,
      sirina: 24.606,
      br_komada_tm: 40.64,
      br_elemenata: 16,
    },
    {
      br_cilindra: "15 1/2",
      obim: 393.7,
      br_zubaca: 124.0,
      sirina: 23.159,
      br_komada_tm: 43.18,
      br_elemenata: 17,
    },
    {
      br_cilindra: "15 1/2",
      obim: 393.7,
      br_zubaca: 124.0,
      sirina: 21.872,
      br_komada_tm: 45.72,
      br_elemenata: 18,
    },
    {
      br_cilindra: "15 1/2",
      obim: 393.7,
      br_zubaca: 124.0,
      sirina: 20.721,
      br_komada_tm: 48.26,
      br_elemenata: 19,
    },
    {
      br_cilindra: "15 1/2",
      obim: 393.7,
      br_zubaca: 124.0,
      sirina: 19.685,
      br_komada_tm: 50.8,
      br_elemenata: 20,
    },
    {
      br_cilindra: "16",
      obim: 406.4,
      br_zubaca: 128.0,
      sirina: 406.4,
      br_komada_tm: 2.461,
      br_elemenata: 1,
    },
    {
      br_cilindra: "16",
      obim: 406.4,
      br_zubaca: 128.0,
      sirina: 203.2,
      br_komada_tm: 4.921,
      br_elemenata: 2,
    },
    {
      br_cilindra: "16",
      obim: 406.4,
      br_zubaca: 128.0,
      sirina: 135.467,
      br_komada_tm: 7.382,
      br_elemenata: 3,
    },
    {
      br_cilindra: "16",
      obim: 406.4,
      br_zubaca: 128.0,
      sirina: 101.6,
      br_komada_tm: 9.843,
      br_elemenata: 4,
    },
    {
      br_cilindra: "16",
      obim: 406.4,
      br_zubaca: 128.0,
      sirina: 81.28,
      br_komada_tm: 12.303,
      br_elemenata: 5,
    },
    {
      br_cilindra: "16",
      obim: 406.4,
      br_zubaca: 128.0,
      sirina: 67.733,
      br_komada_tm: 14.764,
      br_elemenata: 6,
    },
    {
      br_cilindra: "16",
      obim: 406.4,
      br_zubaca: 128.0,
      sirina: 58.057,
      br_komada_tm: 17.224,
      br_elemenata: 7,
    },
    {
      br_cilindra: "16",
      obim: 406.4,
      br_zubaca: 128.0,
      sirina: 50.8,
      br_komada_tm: 19.685,
      br_elemenata: 8,
    },
    {
      br_cilindra: "16",
      obim: 406.4,
      br_zubaca: 128.0,
      sirina: 45.156,
      br_komada_tm: 22.146,
      br_elemenata: 9,
    },
    {
      br_cilindra: "16",
      obim: 406.4,
      br_zubaca: 128.0,
      sirina: 40.64,
      br_komada_tm: 24.606,
      br_elemenata: 10,
    },
    {
      br_cilindra: "16",
      obim: 406.4,
      br_zubaca: 128.0,
      sirina: 36.945,
      br_komada_tm: 27.067,
      br_elemenata: 11,
    },
    {
      br_cilindra: "16",
      obim: 406.4,
      br_zubaca: 128.0,
      sirina: 33.867,
      br_komada_tm: 29.528,
      br_elemenata: 12,
    },
    {
      br_cilindra: "16",
      obim: 406.4,
      br_zubaca: 128.0,
      sirina: 31.262,
      br_komada_tm: 31.988,
      br_elemenata: 13,
    },
    {
      br_cilindra: "16",
      obim: 406.4,
      br_zubaca: 128.0,
      sirina: 29.029,
      br_komada_tm: 34.449,
      br_elemenata: 14,
    },
    {
      br_cilindra: "16",
      obim: 406.4,
      br_zubaca: 128.0,
      sirina: 27.093,
      br_komada_tm: 36.909,
      br_elemenata: 15,
    },
    {
      br_cilindra: "16",
      obim: 406.4,
      br_zubaca: 128.0,
      sirina: 25.4,
      br_komada_tm: 39.37,
      br_elemenata: 16,
    },
    {
      br_cilindra: "16",
      obim: 406.4,
      br_zubaca: 128.0,
      sirina: 23.906,
      br_komada_tm: 41.831,
      br_elemenata: 17,
    },
    {
      br_cilindra: "16",
      obim: 406.4,
      br_zubaca: 128.0,
      sirina: 22.578,
      br_komada_tm: 44.291,
      br_elemenata: 18,
    },
    {
      br_cilindra: "16",
      obim: 406.4,
      br_zubaca: 128.0,
      sirina: 21.389,
      br_komada_tm: 46.752,
      br_elemenata: 19,
    },
    {
      br_cilindra: "16",
      obim: 406.4,
      br_zubaca: 128.0,
      sirina: 20.32,
      br_komada_tm: 49.213,
      br_elemenata: 20,
    },
    {
      br_cilindra: "16 1/2",
      obim: 419.1,
      br_zubaca: 132.0,
      sirina: 419.1,
      br_komada_tm: 2.386,
      br_elemenata: 1,
    },
    {
      br_cilindra: "16 1/2",
      obim: 419.1,
      br_zubaca: 132.0,
      sirina: 209.55,
      br_komada_tm: 4.772,
      br_elemenata: 2,
    },
    {
      br_cilindra: "16 1/2",
      obim: 419.1,
      br_zubaca: 132.0,
      sirina: 139.7,
      br_komada_tm: 7.158,
      br_elemenata: 3,
    },
    {
      br_cilindra: "16 1/2",
      obim: 419.1,
      br_zubaca: 132.0,
      sirina: 104.775,
      br_komada_tm: 9.544,
      br_elemenata: 4,
    },
    {
      br_cilindra: "16 1/2",
      obim: 419.1,
      br_zubaca: 132.0,
      sirina: 83.82,
      br_komada_tm: 11.93,
      br_elemenata: 5,
    },
    {
      br_cilindra: "16 1/2",
      obim: 419.1,
      br_zubaca: 132.0,
      sirina: 69.85,
      br_komada_tm: 14.316,
      br_elemenata: 6,
    },
    {
      br_cilindra: "16 1/2",
      obim: 419.1,
      br_zubaca: 132.0,
      sirina: 59.871,
      br_komada_tm: 16.702,
      br_elemenata: 7,
    },
    {
      br_cilindra: "16 1/2",
      obim: 419.1,
      br_zubaca: 132.0,
      sirina: 52.388,
      br_komada_tm: 19.089,
      br_elemenata: 8,
    },
    {
      br_cilindra: "16 1/2",
      obim: 419.1,
      br_zubaca: 132.0,
      sirina: 46.567,
      br_komada_tm: 21.475,
      br_elemenata: 9,
    },
    {
      br_cilindra: "16 1/2",
      obim: 419.1,
      br_zubaca: 132.0,
      sirina: 41.91,
      br_komada_tm: 23.861,
      br_elemenata: 10,
    },
    {
      br_cilindra: "16 1/2",
      obim: 419.1,
      br_zubaca: 132.0,
      sirina: 38.1,
      br_komada_tm: 26.247,
      br_elemenata: 11,
    },
    {
      br_cilindra: "16 1/2",
      obim: 419.1,
      br_zubaca: 132.0,
      sirina: 34.925,
      br_komada_tm: 28.633,
      br_elemenata: 12,
    },
    {
      br_cilindra: "16 1/2",
      obim: 419.1,
      br_zubaca: 132.0,
      sirina: 32.238,
      br_komada_tm: 31.019,
      br_elemenata: 13,
    },
    {
      br_cilindra: "16 1/2",
      obim: 419.1,
      br_zubaca: 132.0,
      sirina: 29.936,
      br_komada_tm: 33.405,
      br_elemenata: 14,
    },
    {
      br_cilindra: "16 1/2",
      obim: 419.1,
      br_zubaca: 132.0,
      sirina: 27.94,
      br_komada_tm: 35.791,
      br_elemenata: 15,
    },
    {
      br_cilindra: "16 1/2",
      obim: 419.1,
      br_zubaca: 132.0,
      sirina: 26.194,
      br_komada_tm: 38.177,
      br_elemenata: 16,
    },
    {
      br_cilindra: "16 1/2",
      obim: 419.1,
      br_zubaca: 132.0,
      sirina: 24.653,
      br_komada_tm: 40.563,
      br_elemenata: 17,
    },
    {
      br_cilindra: "16 1/2",
      obim: 419.1,
      br_zubaca: 132.0,
      sirina: 23.283,
      br_komada_tm: 42.949,
      br_elemenata: 18,
    },
    {
      br_cilindra: "16 1/2",
      obim: 419.1,
      br_zubaca: 132.0,
      sirina: 22.058,
      br_komada_tm: 45.335,
      br_elemenata: 19,
    },
    {
      br_cilindra: "16 1/2",
      obim: 419.1,
      br_zubaca: 132.0,
      sirina: 20.955,
      br_komada_tm: 47.721,
      br_elemenata: 20,
    },
    {
      br_cilindra: "16 7/8",
      obim: 428.63,
      br_zubaca: 135.002,
      sirina: 428.63,
      br_komada_tm: 2.333,
      br_elemenata: 1,
    },
    {
      br_cilindra: "16 7/8",
      obim: 428.63,
      br_zubaca: 135.002,
      sirina: 214.315,
      br_komada_tm: 4.666,
      br_elemenata: 2,
    },
    {
      br_cilindra: "16 7/8",
      obim: 428.63,
      br_zubaca: 135.002,
      sirina: 142.877,
      br_komada_tm: 6.999,
      br_elemenata: 3,
    },
    {
      br_cilindra: "16 7/8",
      obim: 428.63,
      br_zubaca: 135.002,
      sirina: 107.158,
      br_komada_tm: 9.332,
      br_elemenata: 4,
    },
    {
      br_cilindra: "16 7/8",
      obim: 428.63,
      br_zubaca: 135.002,
      sirina: 85.726,
      br_komada_tm: 11.665,
      br_elemenata: 5,
    },
    {
      br_cilindra: "16 7/8",
      obim: 428.63,
      br_zubaca: 135.002,
      sirina: 71.438,
      br_komada_tm: 13.998,
      br_elemenata: 6,
    },
    {
      br_cilindra: "16 7/8",
      obim: 428.63,
      br_zubaca: 135.002,
      sirina: 61.233,
      br_komada_tm: 16.331,
      br_elemenata: 7,
    },
    {
      br_cilindra: "16 7/8",
      obim: 428.63,
      br_zubaca: 135.002,
      sirina: 53.579,
      br_komada_tm: 18.664,
      br_elemenata: 8,
    },
    {
      br_cilindra: "16 7/8",
      obim: 428.63,
      br_zubaca: 135.002,
      sirina: 47.626,
      br_komada_tm: 20.997,
      br_elemenata: 9,
    },
    {
      br_cilindra: "16 7/8",
      obim: 428.63,
      br_zubaca: 135.002,
      sirina: 42.863,
      br_komada_tm: 23.33,
      br_elemenata: 10,
    },
    {
      br_cilindra: "16 7/8",
      obim: 428.63,
      br_zubaca: 135.002,
      sirina: 38.966,
      br_komada_tm: 25.663,
      br_elemenata: 11,
    },
    {
      br_cilindra: "16 7/8",
      obim: 428.63,
      br_zubaca: 135.002,
      sirina: 35.719,
      br_komada_tm: 27.996,
      br_elemenata: 12,
    },
    {
      br_cilindra: "16 7/8",
      obim: 428.63,
      br_zubaca: 135.002,
      sirina: 32.972,
      br_komada_tm: 30.329,
      br_elemenata: 13,
    },
    {
      br_cilindra: "16 7/8",
      obim: 428.63,
      br_zubaca: 135.002,
      sirina: 30.616,
      br_komada_tm: 32.662,
      br_elemenata: 14,
    },
    {
      br_cilindra: "16 7/8",
      obim: 428.63,
      br_zubaca: 135.002,
      sirina: 28.575,
      br_komada_tm: 34.995,
      br_elemenata: 15,
    },
    {
      br_cilindra: "16 7/8",
      obim: 428.63,
      br_zubaca: 135.002,
      sirina: 26.789,
      br_komada_tm: 37.328,
      br_elemenata: 16,
    },
    {
      br_cilindra: "16 7/8",
      obim: 428.63,
      br_zubaca: 135.002,
      sirina: 25.214,
      br_komada_tm: 39.661,
      br_elemenata: 17,
    },
    {
      br_cilindra: "16 7/8",
      obim: 428.63,
      br_zubaca: 135.002,
      sirina: 23.813,
      br_komada_tm: 41.994,
      br_elemenata: 18,
    },
    {
      br_cilindra: "16 7/8",
      obim: 428.63,
      br_zubaca: 135.002,
      sirina: 22.559,
      br_komada_tm: 44.327,
      br_elemenata: 19,
    },
    {
      br_cilindra: "16 7/8",
      obim: 428.63,
      br_zubaca: 135.002,
      sirina: 21.432,
      br_komada_tm: 46.66,
      br_elemenata: 20,
    },
    {
      br_cilindra: "17",
      obim: 431.8,
      br_zubaca: 136.0,
      sirina: 431.8,
      br_komada_tm: 2.316,
      br_elemenata: 1,
    },
    {
      br_cilindra: "17",
      obim: 431.8,
      br_zubaca: 136.0,
      sirina: 215.9,
      br_komada_tm: 4.632,
      br_elemenata: 2,
    },
    {
      br_cilindra: "17",
      obim: 431.8,
      br_zubaca: 136.0,
      sirina: 143.933,
      br_komada_tm: 6.948,
      br_elemenata: 3,
    },
    {
      br_cilindra: "17",
      obim: 431.8,
      br_zubaca: 136.0,
      sirina: 107.95,
      br_komada_tm: 9.264,
      br_elemenata: 4,
    },
    {
      br_cilindra: "17",
      obim: 431.8,
      br_zubaca: 136.0,
      sirina: 86.36,
      br_komada_tm: 11.579,
      br_elemenata: 5,
    },
    {
      br_cilindra: "17",
      obim: 431.8,
      br_zubaca: 136.0,
      sirina: 71.967,
      br_komada_tm: 13.895,
      br_elemenata: 6,
    },
    {
      br_cilindra: "17",
      obim: 431.8,
      br_zubaca: 136.0,
      sirina: 61.686,
      br_komada_tm: 16.211,
      br_elemenata: 7,
    },
    {
      br_cilindra: "17",
      obim: 431.8,
      br_zubaca: 136.0,
      sirina: 53.975,
      br_komada_tm: 18.527,
      br_elemenata: 8,
    },
    {
      br_cilindra: "17",
      obim: 431.8,
      br_zubaca: 136.0,
      sirina: 47.978,
      br_komada_tm: 20.843,
      br_elemenata: 9,
    },
    {
      br_cilindra: "17",
      obim: 431.8,
      br_zubaca: 136.0,
      sirina: 43.18,
      br_komada_tm: 23.159,
      br_elemenata: 10,
    },
    {
      br_cilindra: "17",
      obim: 431.8,
      br_zubaca: 136.0,
      sirina: 39.255,
      br_komada_tm: 25.475,
      br_elemenata: 11,
    },
    {
      br_cilindra: "17",
      obim: 431.8,
      br_zubaca: 136.0,
      sirina: 35.983,
      br_komada_tm: 27.791,
      br_elemenata: 12,
    },
    {
      br_cilindra: "17",
      obim: 431.8,
      br_zubaca: 136.0,
      sirina: 33.215,
      br_komada_tm: 30.107,
      br_elemenata: 13,
    },
    {
      br_cilindra: "17",
      obim: 431.8,
      br_zubaca: 136.0,
      sirina: 30.843,
      br_komada_tm: 32.422,
      br_elemenata: 14,
    },
    {
      br_cilindra: "17",
      obim: 431.8,
      br_zubaca: 136.0,
      sirina: 28.787,
      br_komada_tm: 34.738,
      br_elemenata: 15,
    },
    {
      br_cilindra: "17",
      obim: 431.8,
      br_zubaca: 136.0,
      sirina: 26.988,
      br_komada_tm: 37.054,
      br_elemenata: 16,
    },
    {
      br_cilindra: "17",
      obim: 431.8,
      br_zubaca: 136.0,
      sirina: 25.4,
      br_komada_tm: 39.37,
      br_elemenata: 17,
    },
    {
      br_cilindra: "17",
      obim: 431.8,
      br_zubaca: 136.0,
      sirina: 23.989,
      br_komada_tm: 41.686,
      br_elemenata: 18,
    },
    {
      br_cilindra: "17",
      obim: 431.8,
      br_zubaca: 136.0,
      sirina: 22.726,
      br_komada_tm: 44.002,
      br_elemenata: 19,
    },
    {
      br_cilindra: "17",
      obim: 431.8,
      br_zubaca: 136.0,
      sirina: 21.59,
      br_komada_tm: 46.318,
      br_elemenata: 20,
    },
    {
      br_cilindra: "17 3/8",
      obim: 441.33,
      br_zubaca: 139.0,
      sirina: 441.325,
      br_komada_tm: 2.266,
      br_elemenata: 1,
    },
    {
      br_cilindra: "17 3/8",
      obim: 441.33,
      br_zubaca: 139.0,
      sirina: 220.663,
      br_komada_tm: 4.532,
      br_elemenata: 2,
    },
    {
      br_cilindra: "17 3/8",
      obim: 441.33,
      br_zubaca: 139.0,
      sirina: 147.108,
      br_komada_tm: 6.798,
      br_elemenata: 3,
    },
    {
      br_cilindra: "17 3/8",
      obim: 441.33,
      br_zubaca: 139.0,
      sirina: 110.331,
      br_komada_tm: 9.064,
      br_elemenata: 4,
    },
    {
      br_cilindra: "17 3/8",
      obim: 441.33,
      br_zubaca: 139.0,
      sirina: 88.265,
      br_komada_tm: 11.33,
      br_elemenata: 5,
    },
    {
      br_cilindra: "17 3/8",
      obim: 441.33,
      br_zubaca: 139.0,
      sirina: 73.554,
      br_komada_tm: 13.595,
      br_elemenata: 6,
    },
    {
      br_cilindra: "17 3/8",
      obim: 441.33,
      br_zubaca: 139.0,
      sirina: 63.046,
      br_komada_tm: 15.861,
      br_elemenata: 7,
    },
    {
      br_cilindra: "17 3/8",
      obim: 441.33,
      br_zubaca: 139.0,
      sirina: 55.166,
      br_komada_tm: 18.127,
      br_elemenata: 8,
    },
    {
      br_cilindra: "17 3/8",
      obim: 441.33,
      br_zubaca: 139.0,
      sirina: 49.036,
      br_komada_tm: 20.393,
      br_elemenata: 9,
    },
    {
      br_cilindra: "17 3/8",
      obim: 441.33,
      br_zubaca: 139.0,
      sirina: 44.133,
      br_komada_tm: 22.659,
      br_elemenata: 10,
    },
    {
      br_cilindra: "17 3/8",
      obim: 441.33,
      br_zubaca: 139.0,
      sirina: 40.12,
      br_komada_tm: 24.925,
      br_elemenata: 11,
    },
    {
      br_cilindra: "17 3/8",
      obim: 441.33,
      br_zubaca: 139.0,
      sirina: 36.777,
      br_komada_tm: 27.191,
      br_elemenata: 12,
    },
    {
      br_cilindra: "17 3/8",
      obim: 441.33,
      br_zubaca: 139.0,
      sirina: 33.948,
      br_komada_tm: 29.457,
      br_elemenata: 13,
    },
    {
      br_cilindra: "17 3/8",
      obim: 441.33,
      br_zubaca: 139.0,
      sirina: 31.523,
      br_komada_tm: 31.723,
      br_elemenata: 14,
    },
    {
      br_cilindra: "17 3/8",
      obim: 441.33,
      br_zubaca: 139.0,
      sirina: 29.422,
      br_komada_tm: 33.989,
      br_elemenata: 15,
    },
    {
      br_cilindra: "17 3/8",
      obim: 441.33,
      br_zubaca: 139.0,
      sirina: 27.583,
      br_komada_tm: 36.254,
      br_elemenata: 16,
    },
    {
      br_cilindra: "17 3/8",
      obim: 441.33,
      br_zubaca: 139.0,
      sirina: 25.96,
      br_komada_tm: 38.52,
      br_elemenata: 17,
    },
    {
      br_cilindra: "17 3/8",
      obim: 441.33,
      br_zubaca: 139.0,
      sirina: 24.518,
      br_komada_tm: 40.786,
      br_elemenata: 18,
    },
    {
      br_cilindra: "17 3/8",
      obim: 441.33,
      br_zubaca: 139.0,
      sirina: 23.228,
      br_komada_tm: 43.052,
      br_elemenata: 19,
    },
    {
      br_cilindra: "17 3/8",
      obim: 441.33,
      br_zubaca: 139.0,
      sirina: 22.066,
      br_komada_tm: 45.318,
      br_elemenata: 20,
    },
    {
      br_cilindra: "19 1/2",
      obim: 495.3,
      br_zubaca: 156.0,
      sirina: 495.3,
      br_komada_tm: 2.019,
      br_elemenata: 1,
    },
    {
      br_cilindra: "19 1/2",
      obim: 495.3,
      br_zubaca: 156.0,
      sirina: 247.65,
      br_komada_tm: 4.038,
      br_elemenata: 2,
    },
    {
      br_cilindra: "19 1/2",
      obim: 495.3,
      br_zubaca: 156.0,
      sirina: 165.1,
      br_komada_tm: 6.057,
      br_elemenata: 3,
    },
    {
      br_cilindra: "19 1/2",
      obim: 495.3,
      br_zubaca: 156.0,
      sirina: 123.825,
      br_komada_tm: 8.076,
      br_elemenata: 4,
    },
    {
      br_cilindra: "19 1/2",
      obim: 495.3,
      br_zubaca: 156.0,
      sirina: 99.06,
      br_komada_tm: 10.095,
      br_elemenata: 5,
    },
    {
      br_cilindra: "19 1/2",
      obim: 495.3,
      br_zubaca: 156.0,
      sirina: 82.55,
      br_komada_tm: 12.114,
      br_elemenata: 6,
    },
    {
      br_cilindra: "19 1/2",
      obim: 495.3,
      br_zubaca: 156.0,
      sirina: 70.757,
      br_komada_tm: 14.133,
      br_elemenata: 7,
    },
    {
      br_cilindra: "19 1/2",
      obim: 495.3,
      br_zubaca: 156.0,
      sirina: 61.913,
      br_komada_tm: 16.152,
      br_elemenata: 8,
    },
    {
      br_cilindra: "19 1/2",
      obim: 495.3,
      br_zubaca: 156.0,
      sirina: 55.033,
      br_komada_tm: 18.171,
      br_elemenata: 9,
    },
    {
      br_cilindra: "19 1/2",
      obim: 495.3,
      br_zubaca: 156.0,
      sirina: 49.53,
      br_komada_tm: 20.19,
      br_elemenata: 10,
    },
    {
      br_cilindra: "24",
      obim: 609.6,
      br_zubaca: 192.0,
      sirina: 609.6,
      br_komada_tm: 1.64,
      br_elemenata: 1,
    },
    {
      br_cilindra: "24",
      obim: 609.6,
      br_zubaca: 192.0,
      sirina: 304.8,
      br_komada_tm: 3.281,
      br_elemenata: 2,
    },
    {
      br_cilindra: "24",
      obim: 609.6,
      br_zubaca: 192.0,
      sirina: 203.2,
      br_komada_tm: 4.921,
      br_elemenata: 3,
    },
    {
      br_cilindra: "24",
      obim: 609.6,
      br_zubaca: 192.0,
      sirina: 152.4,
      br_komada_tm: 6.562,
      br_elemenata: 4,
    },
    {
      br_cilindra: "24",
      obim: 609.6,
      br_zubaca: 192.0,
      sirina: 121.92,
      br_komada_tm: 8.202,
      br_elemenata: 5,
    },
    {
      br_cilindra: "24",
      obim: 609.6,
      br_zubaca: 192.0,
      sirina: 101.6,
      br_komada_tm: 9.843,
      br_elemenata: 6,
    },
    {
      br_cilindra: "24",
      obim: 609.6,
      br_zubaca: 192.0,
      sirina: 87.086,
      br_komada_tm: 11.483,
      br_elemenata: 7,
    },
    {
      br_cilindra: "24",
      obim: 609.6,
      br_zubaca: 192.0,
      sirina: 76.2,
      br_komada_tm: 13.123,
      br_elemenata: 8,
    },
    {
      br_cilindra: "24",
      obim: 609.6,
      br_zubaca: 192.0,
      sirina: 67.733,
      br_komada_tm: 14.764,
      br_elemenata: 9,
    },
    {
      br_cilindra: "24",
      obim: 609.6,
      br_zubaca: 192.0,
      sirina: 60.96,
      br_komada_tm: 16.404,
      br_elemenata: 10,
    },
    {
      br_cilindra: "24",
      obim: 609.6,
      br_zubaca: 192.0,
      sirina: 55.418,
      br_komada_tm: 18.045,
      br_elemenata: 11,
    },
    {
      br_cilindra: "24",
      obim: 609.6,
      br_zubaca: 192.0,
      sirina: 50.8,
      br_komada_tm: 19.685,
      br_elemenata: 12,
    },
    {
      br_cilindra: "24",
      obim: 609.6,
      br_zubaca: 192.0,
      sirina: 46.892,
      br_komada_tm: 21.325,
      br_elemenata: 13,
    },
    {
      br_cilindra: "24",
      obim: 609.6,
      br_zubaca: 192.0,
      sirina: 43.543,
      br_komada_tm: 22.966,
      br_elemenata: 14,
    },
    {
      br_cilindra: "24",
      obim: 609.6,
      br_zubaca: 192.0,
      sirina: 40.64,
      br_komada_tm: 24.606,
      br_elemenata: 15,
    },
    {
      br_cilindra: "24",
      obim: 609.6,
      br_zubaca: 192.0,
      sirina: 38.1,
      br_komada_tm: 26.247,
      br_elemenata: 16,
    },
    {
      br_cilindra: "24",
      obim: 609.6,
      br_zubaca: 192.0,
      sirina: 35.859,
      br_komada_tm: 27.887,
      br_elemenata: 17,
    },
    {
      br_cilindra: "24",
      obim: 609.6,
      br_zubaca: 192.0,
      sirina: 33.867,
      br_komada_tm: 29.528,
      br_elemenata: 18,
    },
    {
      br_cilindra: "24",
      obim: 609.6,
      br_zubaca: 192.0,
      sirina: 32.084,
      br_komada_tm: 31.168,
      br_elemenata: 19,
    },
    {
      br_cilindra: "24",
      obim: 609.6,
      br_zubaca: 192.0,
      sirina: 30.48,
      br_komada_tm: 32.808,
      br_elemenata: 20,
    },
  ];
  useEffect(() => {
    if (calculationId) {
      fetchCalculation(`${SERVER_URL}/old-calculations/${calculationId}`, []);
    }
  }, [fetchCalculation, calculationId]);

  useEffect(() => {
    if (calculation.data && calculation.data.varnishing)
      setHideDegreeOfVarnishConsumption(false);

    if (calculation.data && calculation.data.folioPrinting) {
      setHideFolioWidth(false);
      setHideFolioPrice(false);
      setHideGrossFolio(false);
      setHideFolioPage(false);
    }
    if (calculation.data && calculation.data.printingForms)
      setHidePrintingForm(false);
    if (calculation.data && calculation.data.design) setHideDesign(false);
    if (calculation.data && calculation.data.boxes) setHideBoxes(false);
    if (calculation.data && calculation.data.transport) setHideTransport(false);
    if (calculation.data && calculation.data.subcontractors)
      setHideSubcontractors(false);
    if (calculation.data && calculation.data.tigl) setHideTigl(false);
    if (calculation.data && calculation.data.bending) setHideBending(false);
    if (
      calculation.data &&
      calculation.data.quantityOfSpecialColors &&
      parseFloat(calculation.data.quantityOfSpecialColors) > 0
    )
      setHideQuantitySpecialColors(false);
    if (calculation.data && calculation.data.machine)
      setMachine(calculation.data.machine);

    if (calculation.data && calculation.data.typeOfLabel === "drugo") {
      setHidePrintingSpeedOther(false);
      setHideTotalTimeOfPrintingOther(false);
      setHideTotalElectricityOther(false);
      setMaterialPricePlaceholder("Unesite cenu");
    }

    if (calculation.data && calculation.data.typeOfLabel !== "drugo") {
      setHidePrintingSpeed(false);
      setHidePrintingSpeedOther(true);
      setHideTotalTimeOfPrinting(false);
    }

    if (calculation.data && calculation.data.width) {
      let width = calculation.data.width;
      labelWidthHandler(width, form, false);
    }

    if (calculation.data && calculation.data.typeOfLabel) {
      let value = calculation.data.typeOfLabel;
      // Axios.get(`https://www.birografikamb.co.rs/toster2.php?cena=${value}&cache_buster=l5h3lg634lk34g63g398vefhb083`).then((res) => {
      // 	let response = res.data;
      // 	form.setFieldsValue({grossKgPrice: response.cena});
      // })
      if (value === "OPP beli") {
        form.setFieldsValue({ grossKgPrice: 23.56 });
      }
      if (value === "OPP transparentni 35 mic") {
        form.setFieldsValue({ grossKgPrice: 31.85 });
      }
      if (value === "OPP transparentni 40 mic") {
        form.setFieldsValue({ grossKgPrice: 36.4 });
      }
      if (value === "SHRINK PVC 50 mic") {
        form.setFieldsValue({ grossKgPrice: 65.91 });
      }
      if (value === "SHRINK PVC 45 mic") {
        form.setFieldsValue({ grossKgPrice: 59.32 });
      }
      if (value === "SHRINK PET 45 mic") {
        form.setFieldsValue({ grossKgPrice: 58.48 });
      }
      if (value === "SHRINK PET 50 mic") {
        form.setFieldsValue({ grossKgPrice: 64.93 });
      }
    }
  }, [calculation]);

  useEffect(() => {
    if (calculation.data && calculation.data.width) {
      let width = calculation.data.width;
      labelWidthHandler(width, form, false);
    }
  }, [machine]);

  if (!printType && calculation.data) {
    setPrintType(
      calculation.data &&
        (calculation.data.machine === "Konica Minolta Tabak" ||
          calculation.data.machine === "Roland")
        ? "tabak"
        : "rolna",
    );
  }

  const calculateHandler = (action, form) => {
    const data = form.getFieldsValue();
    if (data.typeOfLabel === "drugo") {
      document.getElementById("printingSpeed").innerText = " m/min";
    }
    if (data.machine === "Konica Minolta") {
      form.setFieldsValue({
        printingForms: false,
        printingFormsPrice: undefined,
      });
    }
    if (
      !visible &&
      machine &&
      data.typeOfLabel &&
      data.width &&
      printType === "rolna"
    ) {
      alert("Nije pronadjen štamparski korak za mašinu!");
    }
    const typeOfLabel = data.typeOfLabel;
    const materialPrice = data.materialPrice;
    const circulation = data.circulation;
    const calculatedWastePaper = data.calculatedWastePaper;
    const degreeOfPaintConsumption = data.degreeOfPaintConsumption
      ? data.degreeOfPaintConsumption / 1000
      : 0;
    const folioWidth = data.folioWidth / 1000;
    const folioPrice = data.folioPrice;
    const degreeOfVarnishConsumption = data.degreeOfVarnishConsumption / 1000;
    const colorPrice = data.colorPrice;
    const varnishPrice = data.varnishPrice;
    const quantityOfSpecialColors = data.quantityOfSpecialColors;
    const numberOfSpecialColors = data.numberOfSpecialColors;
    const priceOfSpecialColors = data.priceOfSpecialColors;
    const numberOfColors = data.numberOfColors ? data.numberOfColors : 0;
    let volume = document.getElementById("volume").innerText;
    const printingCylinder = volume / 1000;
    const numberOfRows = data.numberOfRowsToCut;
    let numberOfItems = parseFloat(
      document.getElementById("numberOfItems").innerText,
    );
    let currentMeters =
      circulation / (numberOfItems * numberOfRows) +
      parseFloat(calculatedWastePaper);
    const materialWidth = parseFloat(data.materialWidth) / 1000;
    const grossKg = parseFloat(form.getFieldValue("grossKgPrice"));
    let grossMeters = materialWidth * currentMeters;
    const grossOppWhite = (grossMeters * grossKg) / 1000;
    const grossOppTrans = (grossMeters * grossKg) / 1000;
    const grossShrinkSleeve = (grossMeters * grossKg) / 1000;
    const priceGrossMeters = grossMeters * materialPrice;
    const priceOppWhite = grossOppWhite * materialPrice;
    const priceOppTrans = grossOppTrans * materialPrice;
    const priceShrinkSleeve = grossShrinkSleeve * materialPrice;
    const grossMetersFolio = folioWidth * currentMeters;
    const grossFolioPrice = grossMetersFolio * folioPrice;
    let colorQuantity = grossMeters * degreeOfPaintConsumption;
    let totalColor = colorQuantity * colorPrice;
    let totalSpecialColor =
      quantityOfSpecialColors && priceOfSpecialColors
        ? quantityOfSpecialColors * priceOfSpecialColors
        : 0;
    let varnishQuantity = grossMeters * degreeOfVarnishConsumption;
    let totalVarnish = varnishQuantity * varnishPrice;
    let totalNumberOfColors = numberOfSpecialColors
      ? parseFloat(numberOfColors) + parseFloat(numberOfSpecialColors)
      : parseFloat(numberOfColors);
    let priceOfPrintingForm = 0;
    let designInput = data.designInput;
    let boxesQuantity = data.boxesQuantity;
    let boxesPrice = data.boxesPrice;
    let boxes = boxesQuantity * boxesPrice;
    let transport = data.transportInput;
    let subcontractor = data.subcontractorInput;
    let preparation = parseFloat(data.preparationOfPrintingPresses);
    let totalPreparation = preparation * 25;
    let exchangeRate = data.exchangeRate;

    if (
      typeOfLabel === "samolepljiva papirna" ||
      typeOfLabel === "samolepljiva termo ECO" ||
      typeOfLabel === "samolepljiva PP" ||
      typeOfLabel === "samolepljiva termo TOP"
    ) {
      priceOfPrintingForm = 15;
    }
    if (
      typeOfLabel === "OPP beli" ||
      typeOfLabel === "OPP transparentni 35 mic" ||
      typeOfLabel === "OPP transparentni 40 mic"
    ) {
      priceOfPrintingForm = 20;
    }
    if (
      typeOfLabel === "SHRINK PVC 45 mic" ||
      typeOfLabel === "SHRINK PVC 50 mic" ||
      typeOfLabel === "SHRINK PET 45 mic" ||
      typeOfLabel === "SHRINK PET 50 mic"
    ) {
      priceOfPrintingForm = 30;
    }
    if (data.machine === "Konica Minolta") {
      priceOfPrintingForm = 0;
    }
    if (action === "table") {
      setHideGrossCurrentMeters(false);
      setHideGrossMetersPrinting(false);
      setHideTotalPreparationOfPrintingPresses(false);
      setHideTotalPrice(false);
      setHideVarnishPrice(true);
      setHideColorPrice(true);
    }

    form.setFieldsValue({
      grossCurrentMeters: currentMeters.toFixed(2),
      grossMetersPrinting:
        grossMeters.toFixed(2) +
        " m2 * " +
        parseFloat(materialPrice) +
        " €/m = " +
        priceGrossMeters.toFixed(2) +
        " €",
    });

    switch (typeOfLabel) {
      case "OPP beli":
        setHideGrossKgPrinting(false);
        form.setFieldsValue({
          grossKgPrinting:
            grossOppWhite.toFixed(2) +
            " kg * " +
            parseFloat(materialPrice) +
            " €/m = " +
            priceOppWhite.toFixed(2) +
            " €",
        });
        break;
      case "OPP transparentni 35 mic":
      case "OPP transparentni 40 mic":
        setHideGrossKgPrinting(false);
        form.setFieldsValue({
          grossKgPrinting:
            grossOppTrans.toFixed(2) +
            "  kg * " +
            parseFloat(materialPrice) +
            " €/m = " +
            priceOppTrans.toFixed(2) +
            " €",
        });
        break;
      case "SHRINK PVC 45 mic":
      case "SHRINK PVC 50 mic":
      case "SHRINK PET 45 mic":
      case "SHRINK PET 50 mic":
        setHideGrossKgPrinting(false);
        form.setFieldsValue({
          grossKgPrinting:
            grossShrinkSleeve.toFixed(2) +
            " kg * " +
            parseFloat(materialPrice) +
            " €/m = " +
            priceShrinkSleeve.toFixed(2) +
            " €",
        });
        break;
      default:
        setHideGrossKgPrinting(true);
    }

    if (data.varnishing && action === "table") setHideVarnishQuantity(false);
    form.setFieldsValue({
      grossMetersFolio:
        grossMetersFolio.toFixed(2) +
        " kg * " +
        parseFloat(folioPrice) +
        " €/m = " +
        grossFolioPrice.toFixed(2) +
        " €",
      varnishQuantity:
        varnishQuantity.toFixed(2) +
        " kg * " +
        parseFloat(varnishPrice) +
        " €/kg = " +
        totalVarnish.toFixed(2) +
        " €",
    });

    if (
      parseFloat(numberOfColors) > 0 &&
      numberOfColors !== "" &&
      action === "table"
    ) {
      form.setFieldsValue({
        colorQuantity:
          colorQuantity.toFixed(2) +
          " kg * " +
          colorPrice +
          " €/kg = " +
          totalColor.toFixed(2) +
          " €",
      });
      setHideColorQuantity(false);
    } else if (action === "table") {
      setHideColorQuantity(true);
    }

    form.setFieldsValue({
      totalPriceOfSpecialColors: totalSpecialColor.toFixed(2) + " €",
      totalPreparationOfPrintingPresses: !isNaN(totalPreparation)
        ? totalPreparation.toFixed(2) + " €"
        : "0 €",
      printingFormsPrice:
        (totalNumberOfColors * priceOfPrintingForm).toFixed(2) + " €",
    });
    document.getElementById("boxesTotalPrice").innerText = !isNaN(boxes)
      ? boxes.toFixed(2) + " €"
      : "0.00 €";

    if (machine) {
      if (document.getElementById("printingSpeedOtherNumber").value !== "") {
        switch (typeOfLabel) {
          case "OPP beli":
            document.getElementById("printingSpeed").innerText =
              document.getElementById("printingSpeedOtherNumber").value +
              " m/min";
            break;
          case "OPP transparentni 35 mic":
          case "OPP transparentni 40 mic":
            document.getElementById("printingSpeed").innerText =
              document.getElementById("printingSpeedOtherNumber").value +
              " m/min";
            break;
          case "samolepljiva papirna":
          case "samolepljiva termo ECO":
          case "samolepljiva termo TOP":
          case "samolepljiva PP":
            document.getElementById("printingSpeed").innerText =
              document.getElementById("printingSpeedOtherNumber").value +
              " m/min";
            break;
          case "SHRINK PVC 45 mic":
          case "SHRINK PVC 50 mic":
          case "SHRINK PET 45 mic":
          case "SHRINK PET 50 mic":
            document.getElementById("printingSpeed").innerText =
              document.getElementById("printingSpeedOtherNumber").value +
              " m/min";
            break;
          default:
            document.getElementById("printingSpeed").innerText =
              form.getFieldValue("printingSpeed") + " m/min";
        }
        // if (typeOfLabel.includes("drugo")) {
        //   document.getElementById("printingSpeed").innerText =
        //     document.getElementById("printingSpeedOtherNumber").value +
        //     " m/min";
        // } else if (machine === "MPS" && !typeOfLabel.includes("SHRINK")) {
        //   document.getElementById("printingSpeed").innerText = 133 + " m/min";
        // }
      } else {
        switch (typeOfLabel) {
          case "OPP beli":
            document.getElementById("printingSpeed").innerText = 70 + " m/min";
            break;
          case "OPP transparentni 35 mic":
          case "OPP transparentni 40 mic":
            document.getElementById("printingSpeed").innerText = 55 + " m/min";
            break;
          case "samolepljiva papirna":
          case "samolepljiva termo ECO":
          case "samolepljiva termo TOP":
          case "samolepljiva PP":
            document.getElementById("printingSpeed").innerText = 40 + " m/min";
            break;
          case "SHRINK PVC 45 mic":
          case "SHRINK PVC 50 mic":
          case "SHRINK PET 45 mic":
          case "SHRINK PET 50 mic":
            document.getElementById("printingSpeed").innerText = 100 + " m/min";
            break;
          default:
            document.getElementById("printingSpeed").innerText =
              form.getFieldValue("printingSpeed") + " m/min";
        }
        if (typeOfLabel.includes("drugo")) {
          document.getElementById("printingSpeed").innerText =
            document.getElementById("printingSpeedOtherNumber").value +
            " m/min";
        } else if (machine === "MPS" && !typeOfLabel.includes("SHRINK")) {
          document.getElementById("printingSpeed").innerText = 133 + " m/min";
        }
      }
    } else {
      document.getElementById("printingSpeed").innerText = 133 + " m/min";
    }

    if (document.getElementById("printingSpeedOtherNumber").value !== "") {
      switch (typeOfLabel) {
        case "OPP beli":
          let minGrossOppWhite =
            currentMeters /
            document.getElementById("printingSpeedOtherNumber").value;
          if (machine === "MPS") {
            minGrossOppWhite =
              currentMeters /
              document.getElementById("printingSpeedOtherNumber").value;
          }
          let hourGrossOppWhite = minGrossOppWhite / 60;
          document.getElementById("totalTimeOfPrinting").innerText =
            hourGrossOppWhite.toFixed(2) + " h";
          break;
        case "OPP transparentni 35 mic":
        case "OPP transparentni 40 mic":
          let minGrossOppTransparent =
            currentMeters /
            document.getElementById("printingSpeedOtherNumber").value;
          if (machine === "MPS") {
            minGrossOppTransparent =
              currentMeters /
              document.getElementById("printingSpeedOtherNumber").value;
          }
          let hourGrossOppTransparent = minGrossOppTransparent / 60;
          document.getElementById("totalTimeOfPrinting").innerText =
            hourGrossOppTransparent.toFixed(2) + " h";
          break;
        case "samolepljiva papirna":
        case "samolepljiva termo ECO":
        case "samolepljiva termo TOP":
        case "samolepljiva PP":
          let minGrossOppAll =
            currentMeters /
            document.getElementById("printingSpeedOtherNumber").value;
          if (machine === "MPS") {
            minGrossOppAll =
              currentMeters /
              document.getElementById("printingSpeedOtherNumber").value;
          }
          let hourGrossOppAll = minGrossOppAll / 60;
          document.getElementById("totalTimeOfPrinting").innerText =
            hourGrossOppAll.toFixed(2) + " h";
          break;
        default:
          // let totalTimeOfPrinting = form.getFieldValue('totalTimeOfPrinting');
          let totalTimeOfPrinting = document
            .getElementById("printingSpeed")
            .innerText.replace(" m/min", "");
          let minGrossDefault = currentMeters / totalTimeOfPrinting;
          let hourMinGrossDefault = minGrossDefault / 60;
          document.getElementById("totalTimeOfPrinting").innerText =
            hourMinGrossDefault.toFixed(2) + " h";
          if (action === "table") {
            setHideTotalTimeOfPrinting(false);
            setHidePrintingSpeed(false);
          }
      }
    } else {
      switch (typeOfLabel) {
        case "OPP beli":
          let minGrossOppWhite = currentMeters / 70;
          if (machine === "MPS") {
            minGrossOppWhite = currentMeters / 133;
          }
          let hourGrossOppWhite = minGrossOppWhite / 60;
          document.getElementById("totalTimeOfPrinting").innerText =
            hourGrossOppWhite.toFixed(2) + " h";
          break;
        case "OPP transparentni 35 mic":
        case "OPP transparentni 40 mic":
          let minGrossOppTransparent = currentMeters / 55;
          if (machine === "MPS") {
            minGrossOppTransparent = currentMeters / 133;
          }
          let hourGrossOppTransparent = minGrossOppTransparent / 60;
          document.getElementById("totalTimeOfPrinting").innerText =
            hourGrossOppTransparent.toFixed(2) + " h";
          break;
        case "samolepljiva papirna":
        case "samolepljiva termo ECO":
        case "samolepljiva termo TOP":
        case "samolepljiva PP":
          let minGrossOppAll = currentMeters / 40;
          if (machine === "MPS") {
            minGrossOppAll = currentMeters / 133;
          }
          let hourGrossOppAll = minGrossOppAll / 60;
          document.getElementById("totalTimeOfPrinting").innerText =
            hourGrossOppAll.toFixed(2) + " h";
          break;
        default:
          // let totalTimeOfPrinting = form.getFieldValue('totalTimeOfPrinting');
          let totalTimeOfPrinting = document
            .getElementById("printingSpeed")
            .innerText.replace(" m/min", "");
          let minGrossDefault = currentMeters / totalTimeOfPrinting;
          let hourMinGrossDefault = minGrossDefault / 60;
          document.getElementById("totalTimeOfPrinting").innerText =
            hourMinGrossDefault.toFixed(2) + " h";
          if (action === "table") {
            setHideTotalTimeOfPrinting(false);
            setHidePrintingSpeed(false);
          }
      }
    }

    if (action === "table") {
      setTableVisible(true);
    }
    document.getElementById("totalElectricity").innerText =
      (parseInt(circulation) * 0.00025).toFixed(2) + " €";
    let timeOfPrinting = document.getElementById(
      "totalTimeOfPrinting",
    ).innerText;
    let electricity = document.getElementById("totalElectricity").innerText;
    let priceOfPrinting = form.getFieldValue("printPrice");
    let totalTimeOfPrinting =
      parseFloat(timeOfPrinting) * parseFloat(priceOfPrinting);
    if (action === "table") {
      setHideTotal(false);
    }

    let totalWorkPrice = parseFloat(totalTimeOfPrinting) + totalPreparation;
    totalWorkPrice += parseFloat(electricity);
    if (document.getElementById("totalTime")) {
      document.getElementById("totalTime").innerText =
        totalWorkPrice.toFixed(2) + " €";
    }
    let totalMatMeters = priceGrossMeters;
    let totalMatOppWhite = priceOppWhite;
    // console.log(totalMatOppWhite)
    let totalMatOppTrans = priceOppTrans;
    let totalMatShrink = priceShrinkSleeve;
    let tableData = [];
    let keys =
      machine !== "Konica Minolta"
        ? [
            "material",
            "work",
            "circulation",
            "pricePerPiece",
            "pricePerPieceRsd",
            "circulationRsd",
            "workRsd",
            "materialRsd",
            "basicPrice",
          ]
        : [
            "circulation",
            "pricePerPiece",
            "pricePerPieceRsd",
            "circulationRsd",
            "basicPrice",
          ];

    if (
      typeOfLabel === "OPP beli" ||
      typeOfLabel === "OPP transparentni 35 mic" ||
      typeOfLabel === "OPP transparentni 40 mic" ||
      typeOfLabel === "SHRINK PVC 45 mic" ||
      typeOfLabel === "SHRINK PVC 50 mic" ||
      typeOfLabel === "SHRINK PET 45 mic" ||
      typeOfLabel === "SHRINK PET 50 mic"
    )
      setHideGrossMetersPrinting(true);

    if (typeOfLabel === "OPP beli") {
      if (data.folioPrinting) {
        totalMatOppWhite += grossFolioPrice;
        form.setFieldsValue({ totalPrice: totalMatOppWhite.toFixed(2) + " €" });
      } else {
        totalMatOppWhite += 0;
        form.setFieldsValue({ totalPrice: totalMatOppWhite.toFixed(2) + " €" });
      }
      if (parseFloat(numberOfColors) > 0 && numberOfColors !== "") {
        totalMatOppWhite += totalColor;
        form.setFieldsValue({ totalPrice: totalMatOppWhite.toFixed(2) + " €" });
      } else {
        totalMatOppWhite += 0;
        form.setFieldsValue({ totalPrice: totalMatOppWhite.toFixed(2) + " €" });
      }
      if (data.quantityOfSpecialColors) {
        totalMatOppWhite += totalSpecialColor;
        form.setFieldsValue({ totalPrice: totalMatOppWhite.toFixed(2) + " €" });
      } else {
        totalMatOppWhite += 0;
        form.setFieldsValue({ totalPrice: totalMatOppWhite.toFixed(2) + " €" });
      }
      if (data.varnishing) {
        totalMatOppWhite += totalVarnish;
        form.setFieldsValue({ totalPrice: totalMatOppWhite.toFixed(2) + " €" });
      } else {
        totalMatOppWhite += 0;
        form.setFieldsValue({ totalPrice: totalMatOppWhite.toFixed(2) + " €" });
      }
      if (data.printingForms) {
        totalMatOppWhite += totalNumberOfColors * priceOfPrintingForm;
        form.setFieldsValue({ totalPrice: totalMatOppWhite.toFixed(2) + " €" });
      } else {
        totalMatOppWhite += 0;
        form.setFieldsValue({ totalPrice: totalMatOppWhite.toFixed(2) + " €" });
      }
      if (data.design) {
        totalMatOppWhite += parseFloat(designInput);
        form.setFieldsValue({ totalPrice: totalMatOppWhite.toFixed(2) + " €" });
      } else {
        totalMatOppWhite += 0;
        form.setFieldsValue({ totalPrice: totalMatOppWhite.toFixed(2) + " €" });
      }
      if (data.boxes) {
        totalMatOppWhite += boxes;
        form.setFieldsValue({ totalPrice: totalMatOppWhite.toFixed(2) + " €" });
      } else {
        totalMatOppWhite += 0;
        form.setFieldsValue({ totalPrice: totalMatOppWhite.toFixed(2) + " €" });
      }
      if (data.transport) {
        totalMatOppWhite += parseFloat(transport);
        form.setFieldsValue({ totalPrice: totalMatOppWhite.toFixed(2) + " €" });
      } else {
        totalMatOppWhite += 0;
        form.setFieldsValue({ totalPrice: totalMatOppWhite.toFixed(2) + " €" });
      }
      if (data.subcontractors) {
        totalMatOppWhite += parseFloat(subcontractor);
        form.setFieldsValue({ totalPrice: totalMatOppWhite.toFixed(2) + " €" });
      } else {
        totalMatOppWhite += 0;
        form.setFieldsValue({ totalPrice: totalMatOppWhite.toFixed(2) + " €" });
      }

      // totalMatOppWhite+=parseFloat(document.getElementById('totalTime').innerText);
      form.setFieldsValue({ totalPrice: totalMatOppWhite.toFixed(2) + " €" });
      for (let i = 0; i < 6; i++) {
        tableData[i] = {};

        let thirdAndFourth = totalMatOppWhite + totalWorkPrice;
        let fifthTotal = (thirdAndFourth / parseInt(circulation)) * 1000;
        let value;
        keys.map((key) => {
          if (i === 0) value = 0;
          if (i === 1) value = -0.05;
          if (i === 2) value = 0.1;
          if (i === 3) value = 0.15;
          if (i === 4) value = 0.3;
          if (i === 5) value = 0.4;
          if (key === "material") {
            let result = (totalMatOppWhite + totalMatOppWhite * value).toFixed(
              2,
            );
            tableData[i][key] = !isNaN(Number(result))
              ? result.replace(".", ",")
              : "0,00";
          } else if (key === "work") {
            let result = (totalWorkPrice + totalWorkPrice * value).toFixed(2);
            tableData[i][key] = !isNaN(Number(result))
              ? result.replace(".", ",")
              : "0,00";
          } else if (key === "circulation") {
            let result = (thirdAndFourth + thirdAndFourth * value).toFixed(2);
            tableData[i][key] = !isNaN(Number(result))
              ? result.replace(".", ",")
              : "0,00";
          } else if (key === "pricePerPiece") {
            let result = (fifthTotal + fifthTotal * value).toFixed(8);
            tableData[i][key] = !isNaN(Number(result))
              ? result.replace(".", ",")
              : "0,00";
          } else if (key === "pricePerPieceRsd") {
            let discount = fifthTotal + fifthTotal * value;
            let result = (discount * exchangeRate).toFixed(3);
            tableData[i][key] = !isNaN(Number(result))
              ? result.replace(".", ",")
              : "0,00";
          } else if (key === "circulationRsd") {
            let discount = thirdAndFourth + thirdAndFourth * value;
            let result = (discount * exchangeRate).toFixed(2);
            tableData[i][key] = !isNaN(Number(result))
              ? result.replace(".", ",")
              : "0,00";
          } else if (key === "workRsd") {
            let discount = totalWorkPrice + totalWorkPrice * value;
            let result = (discount * exchangeRate).toFixed(2);
            tableData[i][key] = !isNaN(Number(result))
              ? result.replace(".", ",")
              : "0,00";
          } else if (key === "materialRsd") {
            let discount = totalMatOppWhite + totalMatOppWhite * value;
            let result = (discount * exchangeRate).toFixed(2);
            tableData[i][key] = !isNaN(Number(result))
              ? result.replace(".", ",")
              : "0,00";
          } else {
            if (i === 0) tableData[i][key] = "/";
            if (i === 1) tableData[i][key] = "-5%";
            if (i === 2) tableData[i][key] = "10%";
            if (i === 3) tableData[i][key] = "15%";
            if (i === 4) tableData[i][key] = "30%";
            if (i === 5) tableData[i][key] = "40%";
          }
        });
      }
      if (action === "table") {
        setDataTable(tableData);
      }
    } else if (
      typeOfLabel === "OPP transparentni 35 mic" ||
      typeOfLabel === "OPP transparentni 40 mic"
    ) {
      if (data.folioPrinting) {
        totalMatOppTrans += grossFolioPrice;
        form.setFieldsValue({ totalPrice: totalMatOppTrans.toFixed(2) + " €" });
      } else {
        totalMatOppTrans += 0;
        form.setFieldsValue({ totalPrice: totalMatOppTrans.toFixed(2) + " €" });
      }
      if (parseFloat(numberOfColors) > 0 && numberOfColors !== "") {
        totalMatOppTrans += totalColor;
        form.setFieldsValue({ totalPrice: totalMatOppTrans.toFixed(2) + " €" });
      } else {
        totalMatOppTrans += 0;
        form.setFieldsValue({ totalPrice: totalMatOppTrans.toFixed(2) + " €" });
      }
      if (data.quantityOfSpecialColors) {
        totalMatOppTrans += totalSpecialColor;
        form.setFieldsValue({ totalPrice: totalMatOppTrans.toFixed(2) + " €" });
      } else {
        totalMatOppTrans += 0;
        form.setFieldsValue({ totalPrice: totalMatOppTrans.toFixed(2) + " €" });
      }
      if (data.varnishing) {
        totalMatOppTrans += totalVarnish;
        form.setFieldsValue({ totalPrice: totalMatOppTrans.toFixed(2) + " €" });
      } else {
        totalMatOppTrans += 0;
        form.setFieldsValue({ totalPrice: totalMatOppTrans.toFixed(2) + " €" });
      }
      if (data.printingForms) {
        totalMatOppTrans += totalNumberOfColors * priceOfPrintingForm;
        form.setFieldsValue({ totalPrice: totalMatOppTrans.toFixed(2) + " €" });
      } else {
        totalMatOppTrans += 0;
        form.setFieldsValue({ totalPrice: totalMatOppTrans.toFixed(2) + " €" });
      }
      if (data.design) {
        totalMatOppTrans += parseFloat(designInput);
        form.setFieldsValue({ totalPrice: totalMatOppTrans.toFixed(2) + " €" });
      } else {
        totalMatOppTrans += 0;
        form.setFieldsValue({ totalPrice: totalMatOppTrans.toFixed(2) + " €" });
      }
      if (data.boxes) {
        totalMatOppTrans += boxes;
        form.setFieldsValue({ totalPrice: totalMatOppTrans.toFixed(2) + " €" });
      } else {
        totalMatOppTrans += 0;
        form.setFieldsValue({ totalPrice: totalMatOppTrans.toFixed(2) + " €" });
      }
      if (data.transport) {
        totalMatOppTrans += parseFloat(transport);
        form.setFieldsValue({ totalPrice: totalMatOppTrans.toFixed(2) + " €" });
      } else {
        totalMatOppTrans += 0;
        form.setFieldsValue({ totalPrice: totalMatOppTrans.toFixed(2) + " €" });
      }
      if (data.subcontractors) {
        totalMatOppTrans += parseFloat(subcontractor);
        form.setFieldsValue({ totalPrice: totalMatOppTrans.toFixed(2) + " €" });
      } else {
        totalMatOppTrans += 0;
        form.setFieldsValue({ totalPrice: totalMatOppTrans.toFixed(2) + " €" });
      }

      // totalMatOppTrans+=parseFloat(document.getElementById('totalTime').innerText);
      form.setFieldsValue({ totalPrice: totalMatOppTrans.toFixed(2) + " €" });

      for (let i = 0; i < 6; i++) {
        tableData[i] = {};

        let thirdAndFourth = totalMatOppTrans + totalWorkPrice;
        let fifthTotal = thirdAndFourth / parseInt(circulation);
        let value;
        keys.map((key) => {
          if (i === 0) value = 0;
          if (i === 1) value = -0.05;
          if (i === 2) value = 0.1;
          if (i === 3) value = 0.15;
          if (i === 4) value = 0.3;
          if (i === 5) value = 0.4;
          if (key === "material") {
            let result = (totalMatOppTrans + totalMatOppTrans * value).toFixed(
              2,
            );
            tableData[i][key] = !isNaN(Number(result))
              ? result.replace(".", ",")
              : "0,00";
          } else if (key === "work") {
            let result = (totalWorkPrice + totalWorkPrice * value).toFixed(2);
            tableData[i][key] = !isNaN(Number(result))
              ? result.replace(".", ",")
              : "0,00";
          } else if (key === "circulation") {
            let result = (thirdAndFourth + thirdAndFourth * value).toFixed(2);
            tableData[i][key] = !isNaN(Number(result))
              ? result.replace(".", ",")
              : "0,00";
          } else if (key === "pricePerPiece") {
            let result = (fifthTotal + fifthTotal * value).toFixed(8);
            tableData[i][key] = !isNaN(Number(result))
              ? result.replace(".", ",")
              : "0,00";
          } else if (key === "pricePerPieceRsd") {
            let discount = fifthTotal + fifthTotal * value;
            let result = (discount * exchangeRate).toFixed(3);
            tableData[i][key] = !isNaN(Number(result))
              ? result.replace(".", ",")
              : "0,00";
          } else if (key === "circulationRsd") {
            let discount = thirdAndFourth + thirdAndFourth * value;
            let result = (discount * exchangeRate).toFixed(2);
            tableData[i][key] = !isNaN(Number(result))
              ? result.replace(".", ",")
              : "0,00";
          } else if (key === "workRsd") {
            let discount = totalWorkPrice + totalWorkPrice * value;
            let result = (discount * exchangeRate).toFixed(2);
            tableData[i][key] = !isNaN(Number(result))
              ? result.replace(".", ",")
              : "0,00";
          } else if (key === "materialRsd") {
            let discount = totalMatOppTrans + totalMatOppTrans * value;
            let result = (discount * exchangeRate).toFixed(2);
            tableData[i][key] = !isNaN(Number(result))
              ? result.replace(".", ",")
              : "0,00";
          } else {
            if (i === 0) tableData[i][key] = "/";
            if (i === 1) tableData[i][key] = "-5%";
            if (i === 2) tableData[i][key] = "10%";
            if (i === 3) tableData[i][key] = "15%";
            if (i === 4) tableData[i][key] = "30%";
            if (i === 5) tableData[i][key] = "40%";
          }
        });
      }
      if (action === "table") {
        setDataTable(tableData);
      }
    } else if (
      typeOfLabel === "SHRINK PVC 45 mic" ||
      typeOfLabel === "SHRINK PVC 50 mic" ||
      typeOfLabel === "SHRINK PET 45 mic" ||
      typeOfLabel === "SHRINK PET 50 mic"
    ) {
      if (data.folioPrinting) {
        totalMatShrink += grossFolioPrice;
        form.setFieldsValue({ totalPrice: totalMatShrink.toFixed(2) + " €" });
      } else {
        totalMatShrink += 0;
        form.setFieldsValue({ totalPrice: totalMatShrink.toFixed(2) + " €" });
      }
      if (parseFloat(numberOfColors) > 0 && numberOfColors !== "") {
        totalMatShrink += totalColor;
        form.setFieldsValue({ totalPrice: totalMatShrink.toFixed(2) + " €" });
      } else {
        totalMatShrink += 0;
        form.setFieldsValue({ totalPrice: totalMatShrink.toFixed(2) + " €" });
      }
      if (data.quantityOfSpecialColors) {
        totalMatShrink += totalSpecialColor;
        form.setFieldsValue({ totalPrice: totalMatShrink.toFixed(2) + " €" });
      } else {
        totalMatShrink += 0;
        form.setFieldsValue({ totalPrice: totalMatShrink.toFixed(2) + " €" });
      }
      if (data.varnishing) {
        totalMatShrink += totalVarnish;
        form.setFieldsValue({ totalPrice: totalMatShrink.toFixed(2) + " €" });
      } else {
        totalMatShrink += 0;
        form.setFieldsValue({ totalPrice: totalMatShrink.toFixed(2) + " €" });
      }
      if (data.printingForms) {
        totalMatShrink += totalNumberOfColors * priceOfPrintingForm;
        form.setFieldsValue({ totalPrice: totalMatShrink.toFixed(2) + " €" });
      } else {
        totalMatShrink += 0;
        form.setFieldsValue({ totalPrice: totalMatShrink.toFixed(2) + " €" });
      }
      if (data.design) {
        totalMatShrink += parseFloat(designInput);
        form.setFieldsValue({ totalPrice: totalMatShrink.toFixed(2) + " €" });
      } else {
        totalMatShrink += 0;
        form.setFieldsValue({ totalPrice: totalMatShrink.toFixed(2) + " €" });
      }
      if (data.boxes) {
        totalMatShrink += boxes;
        form.setFieldsValue({ totalPrice: totalMatShrink.toFixed(2) + " €" });
      } else {
        totalMatShrink += 0;
        form.setFieldsValue({ totalPrice: totalMatShrink.toFixed(2) + " €" });
      }
      if (data.transport) {
        totalMatShrink += parseFloat(transport);
        form.setFieldsValue({ totalPrice: totalMatShrink.toFixed(2) + " €" });
      } else {
        totalMatShrink += 0;
        form.setFieldsValue({ totalPrice: totalMatShrink.toFixed(2) + " €" });
      }
      if (data.subcontractors) {
        totalMatShrink += parseFloat(subcontractor);
        form.setFieldsValue({ totalPrice: totalMatShrink.toFixed(2) + " €" });
      } else {
        totalMatShrink += 0;
        form.setFieldsValue({ totalPrice: totalMatShrink.toFixed(2) + " €" });
      }

      // totalMatShrink+=parseFloat(document.getElementById('totalTime').innerText);
      form.setFieldsValue({ totalPrice: totalMatShrink.toFixed(2) + " €" });

      for (let i = 0; i < 6; i++) {
        tableData[i] = {};
        // console.log(totalWorkPrice)
        let thirdAndFourth = totalMatShrink + totalWorkPrice;
        let fifthTotal = thirdAndFourth / parseInt(circulation);
        let value;
        keys.map((key) => {
          if (i === 0) value = 0;
          if (i === 1) value = -0.05;
          if (i === 2) value = 0.1;
          if (i === 3) value = 0.15;
          if (i === 4) value = 0.3;
          if (i === 5) value = 0.4;
          if (key === "material") {
            let result = (totalMatShrink + totalMatShrink * value).toFixed(2);
            tableData[i][key] = !isNaN(Number(result))
              ? result.replace(".", ",")
              : "0,00";
          } else if (key === "work") {
            let result = (totalWorkPrice + totalWorkPrice * value).toFixed(2);
            tableData[i][key] = !isNaN(Number(result))
              ? result.replace(".", ",")
              : "0,00";
          } else if (key === "circulation") {
            let result = (thirdAndFourth + thirdAndFourth * value).toFixed(2);
            tableData[i][key] = !isNaN(Number(result))
              ? result.replace(".", ",")
              : "0,00";
          } else if (key === "pricePerPiece") {
            let result = (fifthTotal + fifthTotal * value).toFixed(8);
            tableData[i][key] = !isNaN(Number(result))
              ? result.replace(".", ",")
              : "0,00";
          } else if (key === "pricePerPieceRsd") {
            let discount = fifthTotal + fifthTotal * value;
            let result = (discount * exchangeRate).toFixed(3);
            tableData[i][key] = !isNaN(Number(result))
              ? result.replace(".", ",")
              : "0,00";
          } else if (key === "circulationRsd") {
            let discount = thirdAndFourth + thirdAndFourth * value;
            let result = (discount * exchangeRate).toFixed(2);
            tableData[i][key] = !isNaN(Number(result))
              ? result.replace(".", ",")
              : "0,00";
          } else if (key === "workRsd") {
            let discount = totalWorkPrice + totalWorkPrice * value;
            let result = (discount * exchangeRate).toFixed(2);
            tableData[i][key] = !isNaN(Number(result))
              ? result.replace(".", ",")
              : "0,00";
          } else if (key === "materialRsd") {
            let discount = totalMatShrink + totalMatShrink * value;
            let result = (discount * exchangeRate).toFixed(2);
            tableData[i][key] = !isNaN(Number(result))
              ? result.replace(".", ",")
              : "0,00";
          } else {
            if (i === 0) tableData[i][key] = "/";
            if (i === 1) tableData[i][key] = "-5%";
            if (i === 2) tableData[i][key] = "10%";
            if (i === 3) tableData[i][key] = "15%";
            if (i === 4) tableData[i][key] = "30%";
            if (i === 5) tableData[i][key] = "40%";
          }
        });
      }
      if (action === "table") {
        setDataTable(tableData);
      }
    } else {
      if (machine === "Konica Minolta") {
        totalMatMeters = 0;
      }
      if (data.folioPrinting) {
        totalMatMeters += grossFolioPrice;
        form.setFieldsValue({ totalPrice: totalMatMeters.toFixed(2) + " €" });
      } else {
        totalMatMeters += 0;
        form.setFieldsValue({ totalPrice: totalMatMeters.toFixed(2) + " €" });
      }
      if (
        parseFloat(numberOfColors) > 0 &&
        numberOfColors !== "" &&
        machine !== "Konica Minolta"
      ) {
        totalMatMeters += totalColor;
        form.setFieldsValue({ totalPrice: totalMatMeters.toFixed(2) + " €" });
      } else {
        totalMatMeters += 0;
        form.setFieldsValue({ totalPrice: totalMatMeters.toFixed(2) + " €" });
      }
      if (data.quantityOfSpecialColors && machine !== "Konica Minolta") {
        totalMatMeters += totalSpecialColor;
        form.setFieldsValue({ totalPrice: totalMatMeters.toFixed(2) + " €" });
      } else {
        totalMatMeters += 0;
        form.setFieldsValue({ totalPrice: totalMatMeters.toFixed(2) + " €" });
      }
      if (data.varnishing) {
        totalMatMeters += totalVarnish;
        form.setFieldsValue({ totalPrice: totalMatMeters.toFixed(2) + " €" });
      } else {
        totalMatMeters += 0;
        form.setFieldsValue({ totalPrice: totalMatMeters.toFixed(2) + " €" });
      }
      if (data.printingForms) {
        totalMatMeters += totalNumberOfColors * priceOfPrintingForm;
        form.setFieldsValue({ totalPrice: totalMatMeters.toFixed(2) + " €" });
      } else {
        totalMatMeters += 0;
        form.setFieldsValue({ totalPrice: totalMatMeters.toFixed(2) + " €" });
      }
      if (data.design) {
        totalMatMeters += parseFloat(designInput);
        form.setFieldsValue({ totalPrice: totalMatMeters.toFixed(2) + " €" });
      } else {
        totalMatMeters += 0;
        form.setFieldsValue({ totalPrice: totalMatMeters.toFixed(2) + " €" });
      }
      if (data.boxes) {
        totalMatMeters += boxes;
        form.setFieldsValue({ totalPrice: totalMatMeters.toFixed(2) + " €" });
      } else {
        totalMatMeters += 0;
        form.setFieldsValue({ totalPrice: totalMatMeters.toFixed(2) + " €" });
      }
      if (data.transport) {
        totalMatMeters += parseFloat(transport);
        form.setFieldsValue({ totalPrice: totalMatMeters.toFixed(2) + " €" });
      } else {
        totalMatMeters += 0;
        form.setFieldsValue({ totalPrice: totalMatMeters.toFixed(2) + " €" });
      }
      if (data.subcontractors) {
        totalMatMeters += parseFloat(subcontractor);
        form.setFieldsValue({ totalPrice: totalMatMeters.toFixed(2) + " €" });
      } else {
        totalMatMeters += 0;
        form.setFieldsValue({ totalPrice: totalMatMeters.toFixed(2) + " €" });
      }

      // totalMatMeters += parseFloat(document.getElementById('totalTime').innerText);
      form.setFieldsValue({ totalPrice: totalMatMeters.toFixed(2) + " €" });

      let pricePerMeter;
      // console.log("currentMeters", currentMeters);
      if (machine === "Konica Minolta") {
        if (typeOfLabel === "samolepljiva papirna") {
          // console.log("uso");
          if (currentMeters >= 1 && currentMeters < 10) {
            pricePerMeter = 110;
          } else if (currentMeters >= 10 && currentMeters < 25) {
            pricePerMeter = 95;
          } else if (currentMeters >= 25 && currentMeters < 100) {
            pricePerMeter = 83;
          } else if (currentMeters >= 100 && currentMeters < 250) {
            pricePerMeter = 77;
          } else if (currentMeters >= 250 && currentMeters < 400) {
            pricePerMeter = 69;
          } else if (currentMeters >= 400 && currentMeters < 600) {
            pricePerMeter = 62;
          } else if (currentMeters >= 600 && currentMeters < 750) {
            pricePerMeter = 53;
          } else if (currentMeters >= 750) {
            pricePerMeter = 45;
          }
        } else {
          if (currentMeters >= 1 && currentMeters < 10) {
            pricePerMeter = 127;
          } else if (currentMeters >= 10 && currentMeters < 25) {
            pricePerMeter = 109;
          } else if (currentMeters >= 25 && currentMeters < 100) {
            pricePerMeter = 99;
          } else if (currentMeters >= 100 && currentMeters < 250) {
            pricePerMeter = 87;
          } else if (currentMeters >= 250 && currentMeters < 400) {
            pricePerMeter = 84;
          } else if (currentMeters >= 400 && currentMeters < 600) {
            pricePerMeter = 72;
          } else if (currentMeters >= 600 && currentMeters < 750) {
            pricePerMeter = 61;
          } else if (currentMeters >= 750) {
            pricePerMeter = 55;
          }
        }
      }
      // console.log("pricePerMeter", pricePerMeter);
      // console.log("exchangeRate", exchangeRate);
      for (let i = 0; i < 6; i++) {
        tableData[i] = {};
        let thirdAndFourth = totalMatMeters + totalWorkPrice;
        let fifthTotal = thirdAndFourth / parseInt(circulation);
        let value;
        let totalPrice =
          currentMeters.toFixed(2) * pricePerMeter +
          totalMatMeters * exchangeRate;
        let pricePerItem = totalPrice / parseInt(circulation);
        keys.map((key) => {
          if (i === 0) value = 0;
          if (i === 1) value = -0.05;
          if (i === 2) value = 0.1;
          if (i === 3) value = 0.15;
          if (i === 4) value = 0.3;
          if (i === 5) value = 0.4;
          if (key === "material") {
            let result = (totalMatMeters + totalMatMeters * value).toFixed(2);
            tableData[i][key] = !isNaN(Number(result))
              ? result.replace(".", ",")
              : "0,00";
          } else if (key === "work") {
            let result = (totalWorkPrice + totalWorkPrice * value).toFixed(2);
            tableData[i][key] = !isNaN(Number(result))
              ? result.replace(".", ",")
              : "0,00";
          } else if (key === "circulation") {
            let result = (thirdAndFourth + thirdAndFourth * value).toFixed(2);
            tableData[i][key] = !isNaN(Number(result))
              ? result.replace(".", ",")
              : "0,00";
            if (machine === "Konica Minolta") {
              result = (totalPrice + totalPrice * value).toFixed(2);
              tableData[i][key] = !isNaN(Number(result))
                ? result.replace(".", ",")
                : "0,00";
            }
          } else if (key === "pricePerPiece") {
            let result = (fifthTotal + fifthTotal * value).toFixed(8);
            tableData[i][key] = !isNaN(Number(result))
              ? result.replace(".", ",")
              : "0,00";
            if (machine === "Konica Minolta") {
              result = (pricePerItem + pricePerItem * value).toFixed(2);
              tableData[i][key] = !isNaN(Number(result))
                ? result.replace(".", ",")
                : "0,00";
            }
          } else if (key === "pricePerPieceRsd") {
            let discount = fifthTotal + fifthTotal * value;
            let result = (discount * exchangeRate).toFixed(3);
            tableData[i][key] = !isNaN(Number(result))
              ? result.replace(".", ",")
              : "0,00";
            if (machine === "Konica Minolta") {
              discount = pricePerItem + pricePerItem * value;
              result = discount.toFixed(3);
              tableData[i][key] = !isNaN(Number(result))
                ? result.replace(".", ",")
                : "0,00";
            }
          } else if (key === "circulationRsd") {
            let discount = thirdAndFourth + thirdAndFourth * value;
            let result = (discount * exchangeRate).toFixed(2);
            tableData[i][key] = !isNaN(Number(result))
              ? result.replace(".", ",")
              : "0,00";
            if (machine === "Konica Minolta") {
              discount = totalPrice + totalPrice * value;
              result = discount.toFixed(2);
              tableData[i][key] = !isNaN(Number(result))
                ? result.replace(".", ",")
                : "0,00";
            }
          } else if (key === "workRsd") {
            let discount = totalWorkPrice + totalWorkPrice * value;
            let result = (discount * exchangeRate).toFixed(2);
            tableData[i][key] = !isNaN(Number(result))
              ? result.replace(".", ",")
              : "0,00";
          } else if (key === "materialRsd") {
            let discount = totalMatMeters + totalMatMeters * value;
            let result = (discount * exchangeRate).toFixed(2);
            tableData[i][key] = !isNaN(Number(result))
              ? result.replace(".", ",")
              : "0,00";
          } else {
            if (i === 0) tableData[i][key] = "/";
            if (i === 1) tableData[i][key] = "-5%";
            if (i === 2) tableData[i][key] = "10%";
            if (i === 3) tableData[i][key] = "15%";
            if (i === 4) tableData[i][key] = "30%";
            if (i === 5) tableData[i][key] = "40%";
          }
        });
      }
      if (action === "table") {
        setDataTable(tableData);
      }
    }
    return tableData;
  };

  const calculateSheet = (action, form) => {
    document.getElementById("buttons").style.marginTop = "120px";
    const data = form.getFieldsValue();
    const typeOfLabel = data.typeOfLabel;
    const colorPrint = data.colorPrint;
    let circulation = data.circulation;
    const degreeOfPaintConsumption = data.degreeOfPaintConsumption
      ? data.degreeOfPaintConsumption / 1000
      : 0;
    let folioPrice = data.folioPrice;
    const colorPrice = 8;
    const numberOfSpecialColors = data.numberOfSpecialColors
      ? data.numberOfSpecialColors
      : 0;
    let numberOfColors =
      parseInt(data.numberOfColors ? data.numberOfColors : 0) +
      parseInt(numberOfSpecialColors);
    let preparation = parseFloat(data.preparationOfPrintingPresses);
    let totalPreparation = preparation * 25;
    let exchangeRate = data.exchangeRate ? data.exchangeRate : 0;
    let squareMeters;
    let colorQuantity;
    let totalColor;
    const printPages = data.printPages;
    let width = data.width;
    let height = data.height;
    let labelNum = 0;
    let labelNumSecond = 0;
    let sheetNum = 0;
    let price = 0;
    let totalPrintCost = 0;
    let tableData = [];
    const heightSheet = data.heightSheet;
    const widthSheet = data.widthSheet;
    const materialWeight = data.materialWeight;
    let materialCost = 0;
    let sheetHeightRoland = heightSheet ? heightSheet : 700;
    let sheetWidthRoland = widthSheet ? widthSheet : 500;
    let pricePerKg = data.pricePerKg
      ? parseFloat(data.pricePerKg.replace(",", "."))
      : 0;
    let calculatedWastePaperSheet =
      parseFloat(data.calculatedWastePaperSheet) || 0;
    let materialAmount = 0;
    const quantityOfSpecialColors = data.quantityOfSpecialColors;
    const priceOfSpecialColors =
      data.priceOfSpecialColors && data.priceOfSpecialColors.replace(",", ".");
    let totalSpecialColor =
      quantityOfSpecialColors && priceOfSpecialColors
        ? quantityOfSpecialColors * priceOfSpecialColors
        : 0;
    const degreeOfVarnishConsumption = data.degreeOfVarnishConsumption / 1000;
    const varnishPrice = data.varnishPrice;
    let designInput = data.designInput;
    let boxesQuantity = data.boxesQuantity;
    let boxesPrice = data.boxesPrice;
    let boxes = boxesQuantity * boxesPrice;
    let transport = parseFloat(data.transportInput);
    let subcontractor = parseFloat(data.subcontractorInput);
    let materialValueForm = "";

    const onePagePrint = [
      { typeOfLabel: "OFSET", color: "black", min: 1, max: 25, price: 18.0 },
      { typeOfLabel: "OFSET", color: "black", min: 25, max: 50, price: 16.0 },
      { typeOfLabel: "OFSET", color: "black", min: 50, max: 100, price: 15.0 },
      { typeOfLabel: "OFSET", color: "black", min: 100, max: 250, price: 13.0 },
      { typeOfLabel: "OFSET", color: "black", min: 250, price: 11.0 },
      { typeOfLabel: "OFSET", color: "multi", min: 1, max: 25, price: 62.0 },
      { typeOfLabel: "OFSET", color: "multi", min: 25, max: 50, price: 52.0 },
      { typeOfLabel: "OFSET", color: "multi", min: 50, max: 100, price: 42.0 },
      { typeOfLabel: "OFSET", color: "multi", min: 100, max: 250, price: 32.0 },
      { typeOfLabel: "OFSET", color: "multi", min: 250, price: 28.0 },
      { typeOfLabel: "KUNST.", color: "black", min: 1, max: 25, price: 20.0 },
      { typeOfLabel: "KUNST.", color: "black", min: 25, max: 50, price: 19.0 },
      { typeOfLabel: "KUNST.", color: "black", min: 50, max: 100, price: 17.0 },
      {
        typeOfLabel: "KUNST.",
        color: "black",
        min: 100,
        max: 250,
        price: 15.0,
      },
      { typeOfLabel: "KUNST.", color: "black", min: 250, price: 13.0 },
      { typeOfLabel: "KUNST.", color: "multi", min: 1, max: 25, price: 68.0 },
      { typeOfLabel: "KUNST.", color: "multi", min: 25, max: 50, price: 58.0 },
      { typeOfLabel: "KUNST.", color: "multi", min: 50, max: 100, price: 48.0 },
      {
        typeOfLabel: "KUNST.",
        color: "multi",
        min: 100,
        max: 250,
        price: 38.0,
      },
      { typeOfLabel: "KUNST.", color: "multi", min: 250, price: 34.0 },
      {
        typeOfLabel: "SAMOLEPLJIVI",
        color: "black",
        min: 1,
        max: 25,
        price: 31.0,
      },
      {
        typeOfLabel: "SAMOLEPLJIVI",
        color: "black",
        min: 25,
        max: 50,
        price: 30.0,
      },
      {
        typeOfLabel: "SAMOLEPLJIVI",
        color: "black",
        min: 50,
        max: 100,
        price: 29.0,
      },
      {
        typeOfLabel: "SAMOLEPLJIVI",
        color: "black",
        min: 100,
        max: 250,
        price: 26.0,
      },
      { typeOfLabel: "SAMOLEPLJIVI", color: "black", min: 250, price: 24.0 },
      {
        typeOfLabel: "SAMOLEPLJIVI",
        color: "multi",
        min: 1,
        max: 25,
        price: 76.0,
      },
      {
        typeOfLabel: "SAMOLEPLJIVI",
        color: "multi",
        min: 25,
        max: 50,
        price: 66.0,
      },
      {
        typeOfLabel: "SAMOLEPLJIVI",
        color: "multi",
        min: 50,
        max: 100,
        price: 56.0,
      },
      {
        typeOfLabel: "SAMOLEPLJIVI",
        color: "multi",
        min: 100,
        max: 250,
        price: 46.0,
      },
      { typeOfLabel: "SAMOLEPLJIVI", color: "multi", min: 250, price: 42.0 },
    ];

    const twoPagesPrint = [
      { typeOfLabel: "OFSET", color: "black", min: 1, max: 25, price: 27.0 },
      { typeOfLabel: "OFSET", color: "black", min: 25, max: 50, price: 26.0 },
      { typeOfLabel: "OFSET", color: "black", min: 50, max: 100, price: 25.0 },
      { typeOfLabel: "OFSET", color: "black", min: 100, max: 250, price: 23.0 },
      { typeOfLabel: "OFSET", color: "black", min: 250, price: 21.0 },
      { typeOfLabel: "OFSET", color: "multi", min: 1, max: 25, price: 90.0 },
      { typeOfLabel: "OFSET", color: "multi", min: 25, max: 50, price: 80.0 },
      { typeOfLabel: "OFSET", color: "multi", min: 50, max: 100, price: 70.0 },
      { typeOfLabel: "OFSET", color: "multi", min: 100, max: 250, price: 60.0 },
      { typeOfLabel: "OFSET", color: "multi", min: 250, price: 55.0 },
      { typeOfLabel: "KUNST.", color: "black", min: 1, max: 25, price: 30.0 },
      { typeOfLabel: "KUNST.", color: "black", min: 25, max: 50, price: 29.0 },
      { typeOfLabel: "KUNST.", color: "black", min: 50, max: 100, price: 28.0 },
      {
        typeOfLabel: "KUNST.",
        color: "black",
        min: 100,
        max: 250,
        price: 26.0,
      },
      { typeOfLabel: "KUNST.", color: "black", min: 250, price: 25.0 },
      { typeOfLabel: "KUNST.", color: "multi", min: 1, max: 25, price: 98.0 },
      { typeOfLabel: "KUNST.", color: "multi", min: 25, max: 50, price: 88.0 },
      { typeOfLabel: "KUNST.", color: "multi", min: 50, max: 100, price: 78.0 },
      {
        typeOfLabel: "KUNST.",
        color: "multi",
        min: 100,
        max: 250,
        price: 68.0,
      },
      { typeOfLabel: "KUNST.", color: "multi", min: 250, price: 60.0 },
    ];

    if (action === "table") {
      setHideTotalPreparationOfPrintingPresses(false);
      // setHideTotalPrice(false);
      setHideVarnishPrice(true);
      setHideColorPrice(true);
    }

    if (isNaN(circulation)) {
      circulation = 0;
    }

    labelNum =
      Math.floor(310 / parseFloat(width)) *
      Math.floor(470 / parseFloat(height));
    labelNumSecond =
      Math.floor(310 / parseFloat(height)) *
      Math.floor(470 / parseFloat(width));
    if (labelNum > labelNumSecond) {
      sheetNum = Math.ceil(parseFloat(circulation) / labelNum);
    } else {
      sheetNum = Math.ceil(parseFloat(circulation) / labelNumSecond);
    }

    if (sheetNum > 250) {
      labelNum =
        Math.floor(500 / parseFloat(width)) *
        Math.floor(700 / parseFloat(height));
      labelNumSecond =
        Math.floor(500 / parseFloat(height)) *
        Math.floor(700 / parseFloat(width));
      if (labelNum > labelNumSecond) {
        sheetNum = Math.ceil(parseFloat(circulation) / labelNum);
      } else {
        sheetNum = Math.ceil(parseFloat(circulation) / labelNumSecond);
      }
    }

    if (heightSheet && widthSheet) {
      let labelNum =
        Math.floor(parseFloat(widthSheet) / parseFloat(width)) *
        Math.floor(parseFloat(heightSheet) / parseFloat(height));
      let labelNumSecond =
        Math.floor(parseFloat(widthSheet) / parseFloat(height)) *
        Math.floor(parseFloat(heightSheet) / parseFloat(width));
      if (labelNum > labelNumSecond) {
        sheetNum = Math.ceil(parseFloat(circulation) / labelNum);
      } else {
        sheetNum = Math.ceil(parseFloat(circulation) / labelNumSecond);
      }
    }
    if (sheetNum === Infinity) {
      sheetNum = 0;
    }
    materialAmount =
      ((sheetHeightRoland * sheetWidthRoland) / 1000000) *
      (sheetNum + calculatedWastePaperSheet);
    if (machine === "Roland") {
    }

    if (machine === "Konica Minolta Tabak") {
      if (typeOfLabel === "SAMOLEPLJIVI") {
        onePagePrint.forEach((item) => {
          if (
            item.typeOfLabel === typeOfLabel &&
            ((item.min <= sheetNum && item.max >= sheetNum) ||
              sheetNum > 250) &&
            ((item.color === "black" && numberOfColors === 1) ||
              (item.color === "black" && colorPrint === "crno-bela") ||
              (item.color === "multi" && numberOfColors > 1) ||
              (item.color === "multi" && colorPrint === "u boji"))
          ) {
            price = item.price;
          }
        });
      } else {
        if (printPages === "dvostrana") {
          twoPagesPrint.forEach((item) => {
            if (
              item.typeOfLabel === typeOfLabel &&
              ((item.min <= sheetNum && item.max >= sheetNum) ||
                sheetNum > 250) &&
              ((item.color === "black" && numberOfColors === 1) ||
                (item.color === "black" && colorPrint === "crno-bela") ||
                (item.color === "multi" && numberOfColors > 1) ||
                (item.color === "multi" && colorPrint === "u boji"))
            ) {
              price = item.price;
            }
          });
        } else {
          onePagePrint.forEach((item) => {
            if (
              item.typeOfLabel === typeOfLabel &&
              ((item.min <= sheetNum && item.max >= sheetNum) ||
                sheetNum > 250) &&
              ((item.color === "black" && numberOfColors === 1) ||
                (item.color === "black" && colorPrint === "crno-bela") ||
                (item.color === "multi" && numberOfColors > 1) ||
                (item.color === "multi" && colorPrint === "u boji"))
            ) {
              price = item.price;
            }
          });
        }
      }
    }

    if (materialWeight) {
      switch (typeOfLabel) {
        case "TRIPLEX/KARTON":
          pricePerKg = 1.7;
          break;
        case "OFSET":
          if (materialWeight >= 60 && materialWeight < 80) {
            pricePerKg = 1.7;
          } else if (materialWeight >= 80 && materialWeight < 300) {
            pricePerKg = 1.6;
          }
          break;
        case "KUNST.":
          if (materialWeight >= 80 && materialWeight < 100) {
            pricePerKg = 1.5;
          } else if (materialWeight >= 100 && materialWeight < 200) {
            pricePerKg = 1.47;
          } else if (materialWeight >= 250 && materialWeight < 350) {
            pricePerKg = 1.5;
          }
          break;
        default:
          break;
      }
      let materialInKg = (materialAmount * materialWeight) / 1000;
      materialCost = materialInKg * pricePerKg;
      materialValueForm =
        materialInKg.toFixed(2) +
        " kg * " +
        pricePerKg +
        " €/kg = " +
        materialCost.toFixed(2) +
        " €";
      form.setFieldsValue({
        sheetWeight:
          materialAmount +
          " m2 * " +
          materialWeight +
          " g/m2 = " +
          materialInKg * 1000 +
          " g / 1000 = " +
          materialInKg.toFixed(2) +
          " kg",
      });
    }
    if (typeOfLabel === "SAMOLEPLJIVI" && machine === "Roland") {
      materialCost = 0.206 * (sheetNum + calculatedWastePaperSheet);
      materialValueForm =
        0.206 +
        " €/tabak * " +
        (sheetNum + calculatedWastePaperSheet) +
        " tabaka = " +
        materialCost.toFixed(2) +
        " €";
    }
    form.setFieldsValue({
      totalMaterialPrice: materialValueForm,
      sheetNum: sheetNum + calculatedWastePaperSheet,
      sheetAmount:
        (sheetHeightRoland * sheetWidthRoland) / 1000000 +
        " m2/tabak * " +
        (sheetNum + calculatedWastePaperSheet) +
        " tabak(a) = " +
        materialAmount.toFixed(2) +
        " m2",
      folioPrintingPrice:
        ((folioPrice / exchangeRate) * sheetNum).toFixed(2) + " €",
    });
    if (folioPrice && data.folioPrinting) {
      materialCost += sheetNum * (folioPrice / exchangeRate);
    }

    if (!data.folioPrinting) {
      form.setFieldsValue({
        folioPrice: undefined,
        folioPage: undefined,
      });
    }
    if (printPages === "dvostrana") {
      numberOfColors *= 2;
    }

    if (
      numberOfColors &&
      data.machine !== "Konica Minolta Tabak" &&
      !hidePrintingForm
    ) {
      materialCost += numberOfColors * 6;
    }

    if (action === "table") {
      setTableVisible(true);
    }
    let keys = [];
    switch (data.machine) {
      case "Roland":
        materialCost += totalSpecialColor;
        if (numberOfColors > 1) {
          document.getElementById("printingSpeed").innerText =
            5000 + " tabaka/h";
        } else {
          document.getElementById("printingSpeed").innerText =
            7000 + " tabaka/h";
        }
        let printHours =
          sheetNum /
          parseFloat(document.getElementById("printingSpeed").innerText);
        if (printHours < 0.25) {
          printHours = 0.25;
        }
        if (printPages === "dvostrana") {
          printHours *= 2;
        }
        document.getElementById("totalTimeOfPrinting").innerText =
          printHours.toFixed(2) + " h";
        form.setFieldsValue({
          totalPriceOfSpecialColors: totalSpecialColor.toFixed(2) + " €",
          totalPreparationOfPrintingPresses: !isNaN(totalPreparation)
            ? totalPreparation.toFixed(2) + " €"
            : "0 €",
        });
        let timeOfPrinting = document.getElementById(
          "totalTimeOfPrinting",
        ).innerText;
        let priceOfPrinting = form.getFieldValue("printPrice");
        let totalTimeOfPrinting =
          parseFloat(timeOfPrinting) *
          parseFloat(priceOfPrinting?.replace(",", "."));
        if (action === "table") {
          setHideTotal(false);
          setHideTotalPrice(false);
        }
        document.getElementById("totalElectricity").innerText =
          (parseInt(circulation) * 0.00025).toFixed(2) + " €";
        let electricity = document.getElementById("totalElectricity").innerText;
        let totalWorkPrice = parseFloat(totalTimeOfPrinting) + totalPreparation;
        totalWorkPrice += parseFloat(electricity);
        if (document.getElementById("totalTime")) {
          document.getElementById("totalTime").innerText =
            totalWorkPrice.toFixed(2) + " €";
        }
        if (isNaN(totalWorkPrice)) {
          totalWorkPrice = 0;
        }
        totalPrintCost += totalWorkPrice;
        document.getElementById("printingFormsPrice").innerText =
          numberOfColors * 6 + " €";
        squareMeters =
          ((sheetWidthRoland * sheetHeightRoland) / 1000000) *
          (parseFloat(sheetNum) + parseFloat(calculatedWastePaperSheet));
        colorQuantity = squareMeters * degreeOfPaintConsumption;
        totalColor = colorQuantity * colorPrice;
        if (printPages === "dvostrana") {
          materialCost += totalColor * 2;
        } else {
          materialCost += totalColor;
        }
        if (data.varnishing) {
          let varnishQuantity = squareMeters * degreeOfVarnishConsumption;
          let totalVarnish = varnishQuantity * varnishPrice;
          form.setFieldsValue({
            varnishQuantity:
              varnishQuantity.toFixed(2) +
              " kg * " +
              parseFloat(varnishPrice) +
              " €/kg = " +
              totalVarnish.toFixed(2) +
              " €",
          });
          materialCost += +totalVarnish;
        }
        if (data.design) {
          materialCost += +designInput;
        }
        document.getElementById("boxesTotalPrice").innerText = !isNaN(boxes)
          ? boxes.toFixed(2) + " €"
          : "0.00 €";
        if (data.boxes) {
          materialCost += +boxes;
        }
        if (data.transport) {
          materialCost += +transport;
        }
        if (data.subcontractors) {
          materialCost += +subcontractor;
        }
        if (data.tigl) {
          const tiglPrice =
            ((sheetNum + calculatedWastePaperSheet) / 2500) * 10;
          document.getElementById("tiglPrice").innerText =
            tiglPrice.toFixed(2) + " €";
          materialCost += tiglPrice;
        }
        if (data.bending) {
          const bendingPrice = ((sheetNum / 2500 + 1) * 1500) / exchangeRate;
          document.getElementById("bendingPrice").innerText =
            bendingPrice.toFixed(2) + " €";
          materialCost += bendingPrice;
        }
        form.setFieldsValue({ totalPrice: materialCost.toFixed(2) + " €" });
        keys = [
          "material",
          "work",
          "circulation",
          "pricePerPiece",
          "pricePerPieceRsd",
          "circulationRsd",
          "workRsd",
          "materialRsd",
          "basicPrice",
        ];
        break;
      default:
        if (data.varnishing) {
          let varnishQuantity = squareMeters * degreeOfVarnishConsumption;
          let totalVarnish = varnishQuantity * varnishPrice;
          form.setFieldsValue({
            varnishQuantity:
              varnishQuantity.toFixed(2) +
              " kg * " +
              parseFloat(varnishPrice) +
              " €/kg = " +
              totalVarnish.toFixed(2) +
              " €",
          });
          materialCost += totalVarnish;
        }
        if (data.design) {
          materialCost += +designInput;
        }
        document.getElementById("boxesTotalPrice").innerText = !isNaN(boxes)
          ? boxes.toFixed(2) + " €"
          : "0.00 €";
        if (data.boxes) {
          materialCost += +boxes;
        }
        if (data.transport) {
          materialCost += +transport;
        }
        if (data.subcontractors) {
          materialCost += +subcontractor;
        }
        if (data.tigl) {
          const tiglPrice =
            ((sheetNum + calculatedWastePaperSheet) / 2500) * 10;
          document.getElementById("tiglPrice").innerText =
            tiglPrice.toFixed(2) + " €";
          materialCost += tiglPrice;
        }
        if (data.bending) {
          const bendingPrice = ((sheetNum / 2500 + 1) * 1500) / exchangeRate;
          document.getElementById("bendingPrice").innerText =
            bendingPrice.toFixed(2) + " €";
          materialCost += bendingPrice;
        }
        keys = ["pricePerPieceRsd", "circulationRsd", "basicPrice"];
        break;
    }
    // if (typeOfLabel === 'TRIPLEX/KARTON') {
    //   let sheetHeightRoland = heightSheet ? heightSheet : 700;
    //   let sheetWidthRoland = widthSheet ? widthSheet : 500;
    //   let materialAmount = 0;
    //   materialAmount = sheetHeightRoland * sheetWidthRoland / 1000000;
    //   let materialInKg = materialAmount * materialWeight / 1000;
    //   price = 1.7 * materialInKg * exchangeRate * sheetNum;
    // }

    totalPrintCost += (price * sheetNum) / exchangeRate;
    let printCost = totalPrintCost;
    totalPrintCost += materialCost;
    let pricePerItem = totalPrintCost / circulation;
    for (let i = 0; i < 6; i++) {
      tableData[i] = {};
      let value;
      keys.map((key) => {
        if (i === 0) value = 0;
        if (i === 1) value = -0.05;
        if (i === 2) value = 0.1;
        if (i === 3) value = 0.15;
        if (i === 4) value = 0.3;
        if (i === 5) value = 0.4;
        if (key === "material") {
          let result = (materialCost + materialCost * value).toFixed(2);
          tableData[i][key] = !isNaN(Number(result))
            ? result.replace(".", ",")
            : "0,00";
        } else if (key === "work") {
          let result = (printCost + printCost * value).toFixed(2);
          tableData[i][key] = !isNaN(Number(result))
            ? result.replace(".", ",")
            : "0,00";
        } else if (key === "circulation") {
          let result = (totalPrintCost + totalPrintCost * value).toFixed(2);
          tableData[i][key] = !isNaN(Number(result))
            ? result.replace(".", ",")
            : "0,00";
        } else if (key === "pricePerPiece") {
          let result = (pricePerItem + pricePerItem * value).toFixed(8);
          tableData[i][key] = !isNaN(Number(result))
            ? result.replace(".", ",")
            : "0,00";
        } else if (key === "pricePerPieceRsd") {
          let discount = pricePerItem + pricePerItem * value;
          let result = (discount * exchangeRate).toFixed(3);
          tableData[i][key] = !isNaN(Number(result))
            ? result.replace(".", ",")
            : "0,00";
        } else if (key === "circulationRsd") {
          let discount = totalPrintCost + totalPrintCost * value;
          let result = (discount * exchangeRate).toFixed(2);
          tableData[i][key] = !isNaN(Number(result))
            ? result.replace(".", ",")
            : "0,00";
        } else if (key === "workRsd") {
          let discount = printCost + printCost * value;
          let result = (discount * exchangeRate).toFixed(2);
          tableData[i][key] = !isNaN(Number(result))
            ? result.replace(".", ",")
            : "0,00";
        } else if (key === "materialRsd") {
          let discount = materialCost + materialCost * value;
          let result = (discount * exchangeRate).toFixed(2);
          tableData[i][key] = !isNaN(Number(result))
            ? result.replace(".", ",")
            : "0,00";
        } else {
          if (i === 0) tableData[i][key] = "/";
          if (i === 1) tableData[i][key] = "-5%";
          if (i === 2) tableData[i][key] = "10%";
          if (i === 3) tableData[i][key] = "15%";
          if (i === 4) tableData[i][key] = "30%";
          if (i === 5) tableData[i][key] = "40%";
        }
      });
    }
    if (action === "table") {
      setDataTable(tableData);
    }

    if (
      parseFloat(numberOfColors) > 0 &&
      numberOfColors !== "" &&
      action === "table" &&
      data.machine !== "Konica Minolta Tabak"
    ) {
      if (printPages === "dvostrana") {
        form.setFieldsValue({
          colorQuantity:
            colorQuantity.toFixed(2) +
            " kg * " +
            colorPrice +
            " €/kg * 2 = " +
            (totalColor * 2).toFixed(2) +
            " €",
        });
      } else {
        form.setFieldsValue({
          colorQuantity:
            colorQuantity.toFixed(2) +
            " kg * " +
            colorPrice +
            " €/kg = " +
            totalColor.toFixed(2) +
            " €",
        });
      }
      setHideColorQuantity(false);
    } else if (action === "table") {
      setHideColorQuantity(true);
    }

    return tableData;
  };

  const createPdf = async () => {
    let dataForm = form.getFieldsValue();
    let printingSpeed;
    // console.log(dataForm);
    let totalPrintTime = document.getElementById(
      "totalTimeOfPrinting",
    ).innerText;
    dataForm.colorPrice = printType === "rolna" ? 17 : 8;
    let table;
    if (printType === "rolna") {
      table = calculateHandler("pdf", form);
    } else {
      table = calculateSheet("pdf", form);
    }

    if (document.getElementById("printingSpeedOtherNumber").value !== "") {
      printingSpeed = document.getElementById("printingSpeedOtherNumber").value;
    } else {
      printingSpeed = document.getElementById("printingSpeed").innerText;
    }

    const response = await Axios.post(
      `${SERVER_URL}/create-pdf`,
      {
        form: {
          ...dataForm,
          printingSpeed: printingSpeed,
          assignWidth: document.getElementById("assignWidth")
            ? document.getElementById("assignWidth").innerText
            : undefined,
          widthTable: document.getElementById("widthTable")
            ? document.getElementById("widthTable").innerText
            : undefined,
          numberOfItems: document.getElementById("numberOfItems")
            ? document.getElementById("numberOfItems").innerText
            : undefined,
          numberOfCylinders: document.getElementById("numberOfCylinders")
            ? document.getElementById("numberOfCylinders").innerText
            : undefined,
          volume: document.getElementById("volume")
            ? document.getElementById("volume").innerText
            : undefined,
          totalTimeOfPrinting: totalPrintTime ? totalPrintTime : undefined,
          totalElectricity: document.getElementById("totalElectricity")
            ? document.getElementById("totalElectricity").innerText
            : undefined,
          totalTime: document.getElementById("totalTime")
            ? document.getElementById("totalTime").innerText
            : undefined,
          boxesTotalPrice: document.getElementById("boxesTotalPrice")
            ? document.getElementById("boxesTotalPrice").innerText
            : undefined,
          totalPriceOfSpecialColors: document.getElementById(
            "totalPriceOfSpecialColors",
          )
            ? document.getElementById("totalPriceOfSpecialColors").innerText
            : undefined,
          printingFormsPrice: document.getElementById("printingFormsPrice")
            ? document.getElementById("printingFormsPrice").innerText
            : undefined,
          tiglPrice: document.getElementById("tiglPrice")
            ? document.getElementById("tiglPrice").innerText
            : undefined,
          bendingPrice: document.getElementById("bendingPrice")
            ? document.getElementById("bendingPrice").innerText
            : undefined,
          varnishQuantity: document.getElementById("varnishQuantity")
            ? document.getElementById("varnishQuantity").innerText
            : undefined,
          totalPrice: document.getElementById("totalPrice")
            ? document.getElementById("totalPrice").innerText
            : undefined,
          hideGrossKgPrinting,
          hideGrossMetersPrinting,
          hideGrossFolio,
          hideColorQuantity,
          hideColorPrice,
          hideDesign,
          hideBoxes,
          hideTransport,
          hideSubcontractors,
          hideTigl,
          hideBending,
          hideQuantitySpecialColors,
          hidePrintingForm,
          hideVarnishQuantity,
          hideVarnishPrice,
          hideTotalPrice,
          table: JSON.stringify(table),
          userId: currentuser.data.id,
          printType,
        },
      },
      {
        withCredentials: false,
        headers: {
          Authorization: `Bearer ${currentuser.data.token}`,
          "Content-Type": "application/json",
        },
      },
    );
    if (response.data.filename) {
      const pdfResponse = await Axios.get(
        `${SERVER_URL}/get-pdf?filename=${response.data.filename}`,
        {
          responseType: "arraybuffer",
          withCredentials: false,
          headers: { Authorization: `Bearer ${currentuser.data.token}` },
        },
      );
      const pathArr = response.data.filename.split("\\");
      const blob = new Blob([pdfResponse.data], { type: "application/pdf" });
      let a = document.createElement("a");
      let url = window.URL.createObjectURL(blob);
      a.href = url;
      a.download = pathArr[pathArr.length - 1];
      a.click();
      window.URL.revokeObjectURL(url);
    }
  };

  const totalPrintingTimeHandler = async (type) => {
    setHideTotalTimeOfPrintingOther(true);
    const data = form.getFieldsValue();
    const printingSpeed = document.getElementById(
      "printingSpeedOtherNumber",
    ).value;
    document.getElementById("printingSpeed").innerText =
      printingSpeed + " m/min";

    let calculatedWastePaper = 1;
    if (data.calculatedWastePaper) {
      calculatedWastePaper = data.calculatedWastePaper;
    }

    const circulation = data.circulation;

    const numberOfRows = data.numberOfRowsToCut;

    let numberOfItems = parseFloat(
      document.getElementById("numberOfItems").innerText,
    );

    let currentMeters =
      circulation / (numberOfItems * numberOfRows) +
      parseFloat(calculatedWastePaper);

    let minGrossOppWhite = currentMeters / printingSpeed;

    let hourGrossOppWhite = minGrossOppWhite / 60;

    // console.log(hourGrossOppWhite);

    document.getElementById("totalTimeOfPrinting").innerText =
      hourGrossOppWhite.toFixed(2) + " h";

    // document.getElementById("totalTimeOfPrinting").innerText =
    //   parseInt(printingSpeed) + "h";

    // console.log(form.getFieldValue("totalTimeOfPrinting"));
  };

  const numberOfRowsHandler = (event, form) => {
    const formValues = form.getFieldsValue();
    const numberOfRows = event.target.value;
    const labelHeight = formValues.height;
    const typeOfLabelRow = formValues.typeOfLabel;

    if (!isDigitalPrint) {
      if (numberOfRows >= 2) {
        if (typeOfLabelRow.includes("samolepljiva")) {
          ///samolepljiva
          form.setFieldsValue({
            materialWidth:
              parseFloat(labelHeight) * numberOfRows +
              (numberOfRows - 1) * 4 +
              16,
          });
          setMaterialWidth(
            parseFloat(labelHeight) * numberOfRows +
              (numberOfRows - 1) * 4 +
              16,
          );
        } else {
          ///nije samolepljiva
          form.setFieldsValue({
            materialWidth: parseFloat(labelHeight) * numberOfRows + 16,
          });
          setMaterialWidth(parseFloat(labelHeight) * numberOfRows + 16);
        }
      } else {
        form.setFieldsValue({ materialWidth: parseFloat(labelHeight) + 16 });
        setMaterialWidth(parseFloat(labelHeight) + 16);
      }
    } else {
      if (parseFloat(labelHeight) * numberOfRows > 300) {
        setTimeout(
          () =>
            alert(
              "Prekoračena je maksimalno dozvoljena visina sa brojem redova od 300 mm!",
            ),
          100,
        );
      }
    }
  };

  const labelHeightHandler = (event, form) => {
    const formValues = form.getFieldsValue();
    const numberOfRows = formValues.numberOfRowsToCut;
    const labelHeight = event.target.value;
    const typeOfLabelRow = formValues.typeOfLabel;

    if (!isDigitalPrint) {
      if (numberOfRows >= 2) {
        if (typeOfLabelRow.includes("samolepljiva")) {
          ///samolepljiva
          form.setFieldsValue({
            materialWidth:
              parseFloat(labelHeight) * numberOfRows +
              (numberOfRows - 1) * 4 +
              16,
          });
          setMaterialWidth(
            parseFloat(labelHeight) * numberOfRows +
              (numberOfRows - 1) * 4 +
              16,
          );
        } else {
          ///nije samolepljiva
          form.setFieldsValue({
            materialWidth: parseFloat(labelHeight) * numberOfRows + 16,
          });
          setMaterialWidth(parseFloat(labelHeight) * numberOfRows + 16);
        }
      } else {
        form.setFieldsValue({
          materialWidth: parseFloat(labelHeight) + 16,
        });
        setMaterialWidth(parseFloat(labelHeight) + 16);
      }
    } else {
      if (parseFloat(labelHeight) * numberOfRows > 300) {
        setTimeout(
          () =>
            alert(
              "Prekoračena je maksimalno dozvoljena visina sa brojem redova od 300 mm!",
            ),
          100,
        );
      }
    }
  };

  const calculateMinWidth = (steps, width) => {
    let minWidth = Math.abs(steps[0].steps[0].width - width);
    let n = 0,
      m = 0,
      p = 0,
      q = 0;
    for (let i = 0; i < steps.length; i++) {
      for (let j = 0; j < steps[i].steps.length; j++) {
        // if ((steps[i].steps[j].width - width < minWidth || steps[i].steps[j].width === 180.975) && steps[i].steps[j].width >= width) {
        if (
          steps[i].steps[j].width - width < minWidth &&
          steps[i].steps[j].width >= width
        ) {
          minWidth = steps[i].steps[j].width - width;
          n = i;
          m = j;
        }
      }
    }
    return { minWidth, n, m };
  };

  const checkVisibleElements = () => {
    return (
      document.getElementById("assignWidth") &&
      document.getElementById("widthTable") &&
      document.getElementById("numberOfItems") &&
      document.getElementById("numberOfCylinders") &&
      document.getElementById("volume")
    );
  };

  const labelWidthHandler = async (labelWidth, form, showAlert) => {
    const formValues = form.getFieldsValue();
    const widthPlusTwo = parseFloat(labelWidth) + 2.5;
    const widthPlusNull = parseFloat(labelWidth);
    const typeOfLabelCil = formValues.typeOfLabel;
    const index = machineSteps.findIndex((step) => step.name === machine);
    if (machine !== "Konica Minolta" && machine !== "MPS") {
      if (
        typeOfLabelCil === "samolepljiva papirna" ||
        typeOfLabelCil === "samolepljiva termo ECO" ||
        typeOfLabelCil === "samolepljiva termo TOP" ||
        typeOfLabelCil === "samolepljiva PP"
      ) {
        let findedStep;
        let findedSteps = [];
        for (const step of stepsArray) {
          if (
            ((step.br_cilindra === "8" ||
              step.br_cilindra === "8 3/4" ||
              step.br_cilindra === "9" ||
              step.br_cilindra === "11" ||
              step.br_cilindra === "12" ||
              step.br_cilindra === "12 1/4" ||
              step.br_cilindra === "13" ||
              step.br_cilindra === "14 1/4") &&
              step.sirina > widthPlusTwo) ||
            (machine === "Mark Andy" &&
              widthPlusNull === 150 &&
              typeOfLabelCil === "samolepljiva PP" &&
              step.sirina > widthPlusNull)
          ) {
            findedSteps.push(step);
          }
        }
        findedSteps.sort((a, b) =>
          a.sirina > b.sirina ? 1 : b.sirina > a.sirina ? -1 : 0,
        );
        findedStep = findedSteps[0];
        let { minWidth, n, m } = calculateMinWidth(
          printingStepsOne,
          widthPlusTwo,
          findedStep?.br_cilindra,
        );
        if (
          minWidth < findedStep?.sirina - widthPlusTwo &&
          machineSteps[index]?.values.includes(printingStepsOne[n].maxWidth) &&
          labelWidth
        ) {
          if (checkVisibleElements()) {
            document.getElementById("assignWidth").innerText = widthPlusTwo;
            document.getElementById("widthTable").innerText =
              printingStepsOne[n].steps[m].width;
            document.getElementById("numberOfItems").innerText =
              printingStepsOne[n].steps[m].numberOfItems;
            document.getElementById("numberOfCylinders").innerText =
              printingStepsOne[n].numberOfCylinders;
            document.getElementById("volume").innerText =
              printingStepsOne[n].maxWidth;
          }
          setVisible(true);
        } else if (
          machineSteps[index]?.values.includes(parseFloat(findedStep?.obim)) &&
          labelWidth
        ) {
          if (checkVisibleElements()) {
            document.getElementById("assignWidth").innerText = widthPlusTwo;
            document.getElementById("widthTable").innerText = findedStep.sirina;
            document.getElementById("numberOfItems").innerText =
              findedStep.br_komada_tm;
            document.getElementById("numberOfCylinders").innerText =
              findedStep.br_cilindra;
            document.getElementById("volume").innerText = findedStep.obim;
          }
          setVisible(true);
        } else {
          setEmptyValues();
          setVisible(false);
        }
      } else {
        let findedStep;
        let findedSteps = [];
        for (const step of stepsArray) {
          if (step.sirina >= widthPlusNull) {
            findedSteps.push(step);
          }
        }
        findedSteps.sort((a, b) =>
          a.sirina > b.sirina ? 1 : b.sirina > a.sirina ? -1 : 0,
        );
        findedStep = findedSteps[0];
        let { minWidth, n, m } =
          findedStep?.br_cilindra === "12 7/8"
            ? calculateMinWidth(printingStepsOne, widthPlusNull)
            : calculateMinWidth(printingStepsTwo, widthPlusNull);
        if (
          findedStep?.br_cilindra === "12 7/8" &&
          machineSteps[index].values.includes(printingStepsOne[n].maxWidth)
        ) {
          if (checkVisibleElements()) {
            document.getElementById("assignWidth").innerText = widthPlusNull;
            document.getElementById("widthTable").innerText =
              printingStepsOne[n].steps[m].width;
            document.getElementById("numberOfItems").innerText =
              printingStepsOne[n].steps[m].numberOfItems;
            document.getElementById("numberOfCylinders").innerText =
              printingStepsOne[n].numberOfCylinders;
            document.getElementById("volume").innerText =
              printingStepsOne[n].maxWidth;
          }
          setVisible(true);
        } else if (
          minWidth < findedStep?.sirina - widthPlusNull &&
          machineSteps[index].values.includes(printingStepsTwo[n].maxWidth)
        ) {
          if (checkVisibleElements()) {
            document.getElementById("assignWidth").innerText = widthPlusNull;
            document.getElementById("widthTable").innerText =
              printingStepsTwo[n].steps[m].width;
            document.getElementById("numberOfItems").innerText =
              printingStepsTwo[n].steps[m].numberOfItems;
            document.getElementById("numberOfCylinders").innerText =
              printingStepsTwo[n].numberOfCylinders;
            document.getElementById("volume").innerText =
              printingStepsTwo[n].maxWidth;
          }
          setVisible(true);
        } else if (
          machineSteps[index]?.values.includes(parseFloat(findedStep?.obim))
        ) {
          if (checkVisibleElements()) {
            document.getElementById("assignWidth").innerText = widthPlusNull;
            document.getElementById("widthTable").innerText = findedStep.sirina;
            document.getElementById("numberOfItems").innerText =
              findedStep.br_komada_tm;
            document.getElementById("numberOfCylinders").innerText =
              findedStep.br_cilindra;
            document.getElementById("volume").innerText = findedStep.obim;
          }
          setVisible(true);
        } else {
          setEmptyValues();
          setVisible(false);
        }
      }
    } else if (machine === "Konica Minolta") {
      let findedStep;
      let findedSteps = [];
      for (const step of stepsArray) {
        if (
          (step.br_cilindra === "11" ||
            step.br_cilindra === "12" ||
            step.br_cilindra === "13") &&
          step.sirina > widthPlusTwo
        ) {
          findedSteps.push(step);
        }
      }
      findedSteps.sort((a, b) =>
        a.sirina > b.sirina ? 1 : b.sirina > a.sirina ? -1 : 0,
      );
      findedStep = findedSteps[0];
      let allPrintSteps = printingStepsOne.concat(printingStepsTwo);
      let { minWidth, n, m } = calculateMinWidth(
        allPrintSteps,
        widthPlusTwo,
        findedStep?.br_cilindra,
      );
      if (
        minWidth < findedStep?.sirina - widthPlusTwo &&
        machineSteps[index]?.values.includes(allPrintSteps[n].maxWidth) &&
        labelWidth
      ) {
        document.getElementById("assignWidth").innerText = widthPlusTwo;
        document.getElementById("widthTable").innerText =
          allPrintSteps[n].steps[m].width;
        document.getElementById("numberOfItems").innerText =
          allPrintSteps[n].steps[m].numberOfItems;
        document.getElementById("numberOfCylinders").innerText =
          allPrintSteps[n].numberOfCylinders;
        document.getElementById("volume").innerText = allPrintSteps[n].maxWidth;
        setVisible(true);
      } else if (
        machineSteps[index]?.values.includes(parseFloat(findedStep?.obim)) &&
        labelWidth
      ) {
        document.getElementById("assignWidth").innerText = widthPlusTwo;
        document.getElementById("widthTable").innerText = findedStep.sirina;
        document.getElementById("numberOfItems").innerText =
          findedStep.br_komada_tm;
        document.getElementById("numberOfCylinders").innerText =
          findedStep.br_cilindra;
        document.getElementById("volume").innerText = findedStep.obim;
        setVisible(true);
      } else {
        setEmptyValues();
        setVisible(false);
      }
    } else if (machine === "MPS") {
      const stepsMPS = [
        "10",
        "10 1/2",
        "10 7/8",
        "11 1/4",
        "11 3/8",
        "12",
        "12 1/8",
        "12 3/8",
        "12 7/8",
        "13 1/4",
        "13 5/8",
        "14 1/2",
        "15 1/2",
        "16",
        "16 1/2",
        "17",
        "19 1/2",
      ];
      if (
        typeOfLabelCil === "samolepljiva papirna" ||
        typeOfLabelCil === "samolepljiva termo ECO" ||
        typeOfLabelCil === "samolepljiva termo TOP" ||
        typeOfLabelCil === "samolepljiva PP"
      ) {
        let findedStep;
        let findedSteps = [];
        for (const step of stepsArray) {
          if (
            step.sirina >= widthPlusTwo &&
            stepsMPS.includes(step.br_cilindra)
          ) {
            findedSteps.push(step);
          }
        }
        findedSteps.sort((a, b) =>
          a.sirina > b.sirina ? 1 : b.sirina > a.sirina ? -1 : 0,
        );
        findedStep = findedSteps[0];
        let assignWidth;
        assignWidth = widthPlusTwo;
        let { minWidth, n, m } =
          findedStep?.br_cilindra === "12 7/8"
            ? calculateMinWidth(
                printingStepsOne.filter((item) =>
                  stepsMPS.includes(item.numberOfCylinders),
                ),
                widthPlusTwo,
              )
            : calculateMinWidth(
                printingStepsTwo.filter((item) =>
                  stepsMPS.includes(item.numberOfCylinders),
                ),
                widthPlusTwo,
              );
        if (
          findedStep?.br_cilindra === "12 7/8" &&
          machineSteps[index].values.includes(printingStepsOne[n].maxWidth)
        ) {
          if (checkVisibleElements()) {
            document.getElementById("assignWidth").innerText = assignWidth;
            document.getElementById("widthTable").innerText =
              printingStepsOne[n].steps[m].width;
            document.getElementById("numberOfItems").innerText =
              printingStepsOne[n].steps[m].numberOfItems;
            document.getElementById("numberOfCylinders").innerText =
              printingStepsOne[n].numberOfCylinders;
            document.getElementById("volume").innerText =
              printingStepsOne[n].maxWidth;
          }
          setVisible(true);
        } else if (
          minWidth < findedStep?.sirina - widthPlusTwo &&
          machineSteps[index].values.includes(printingStepsTwo[n].maxWidth)
        ) {
          if (checkVisibleElements()) {
            document.getElementById("assignWidth").innerText = assignWidth;
            document.getElementById("widthTable").innerText =
              printingStepsTwo[n].steps[m].width;
            document.getElementById("numberOfItems").innerText =
              printingStepsTwo[n].steps[m].numberOfItems;
            document.getElementById("numberOfCylinders").innerText =
              printingStepsTwo[n].numberOfCylinders;
            document.getElementById("volume").innerText =
              printingStepsTwo[n].maxWidth;
          }
          setVisible(true);
        } else if (
          machineSteps[index]?.values.includes(parseFloat(findedStep?.obim))
        ) {
          if (checkVisibleElements()) {
            document.getElementById("assignWidth").innerText = assignWidth;
            document.getElementById("widthTable").innerText = findedStep.sirina;
            document.getElementById("numberOfItems").innerText =
              findedStep.br_komada_tm;
            document.getElementById("numberOfCylinders").innerText =
              findedStep.br_cilindra;
            document.getElementById("volume").innerText = findedStep.obim;
          }
          setVisible(true);
        } else {
          setEmptyValues();
          setVisible(false);
        }
      } else {
        let findedStep;
        let findedSteps = [];
        for (const step of stepsArray) {
          if (
            step.sirina >= widthPlusNull &&
            stepsMPS.includes(step.br_cilindra)
          ) {
            findedSteps.push(step);
          }
        }
        findedSteps.sort((a, b) =>
          a.sirina > b.sirina ? 1 : b.sirina > a.sirina ? -1 : 0,
        );
        findedStep = findedSteps[0];
        let assignWidth;
        assignWidth = widthPlusNull;
        let { minWidth, n, m } =
          findedStep?.br_cilindra === "12 7/8"
            ? calculateMinWidth(
                printingStepsOne.filter((item) =>
                  stepsMPS.includes(item.numberOfCylinders),
                ),
                widthPlusNull,
              )
            : calculateMinWidth(
                printingStepsTwo.filter((item) =>
                  stepsMPS.includes(item.numberOfCylinders),
                ),
                widthPlusNull,
              );
        if (
          findedStep?.br_cilindra === "12 7/8" &&
          machineSteps[index].values.includes(printingStepsOne[n].maxWidth)
        ) {
          if (checkVisibleElements()) {
            document.getElementById("assignWidth").innerText = assignWidth;
            document.getElementById("widthTable").innerText =
              printingStepsOne[n].steps[m].width;
            document.getElementById("numberOfItems").innerText =
              printingStepsOne[n].steps[m].numberOfItems;
            document.getElementById("numberOfCylinders").innerText =
              printingStepsOne[n].numberOfCylinders;
            document.getElementById("volume").innerText =
              printingStepsOne[n].maxWidth;
          }
          setVisible(true);
        } else if (
          minWidth < findedStep?.sirina - widthPlusNull &&
          machineSteps[index].values.includes(printingStepsTwo[n].maxWidth)
        ) {
          if (checkVisibleElements()) {
            document.getElementById("assignWidth").innerText = assignWidth;
            document.getElementById("widthTable").innerText =
              printingStepsTwo[n].steps[m].width;
            document.getElementById("numberOfItems").innerText =
              printingStepsTwo[n].steps[m].numberOfItems;
            document.getElementById("numberOfCylinders").innerText =
              printingStepsTwo[n].numberOfCylinders;
            document.getElementById("volume").innerText =
              printingStepsTwo[n].maxWidth;
          }
          setVisible(true);
        } else if (
          machineSteps[index]?.values.includes(parseFloat(findedStep?.obim))
        ) {
          if (checkVisibleElements()) {
            document.getElementById("assignWidth").innerText = assignWidth;
            document.getElementById("widthTable").innerText = findedStep.sirina;
            document.getElementById("numberOfItems").innerText =
              findedStep.br_komada_tm;
            document.getElementById("numberOfCylinders").innerText =
              findedStep.br_cilindra;
            document.getElementById("volume").innerText = findedStep.obim;
          }
          setVisible(true);
        } else {
          setEmptyValues();
          setVisible(false);
        }
      }
    } else if (showAlert && printType === "rolna") {
      alert("Izaberite mašinu");
      form.setFieldsValue({ width: "" });
    }
  };

  const setEmptyValues = () => {
    if (printType === "rolna") {
      document.getElementById("assignWidth").innerText = "";
      document.getElementById("widthTable").innerText = "";
      document.getElementById("numberOfItems").innerText = "";
      document.getElementById("numberOfCylinders").innerText = "";
      document.getElementById("volume").innerText = "";
    }
  };

  const machineHandler = async (value) => {
    setMachine(value);
    const formValues = form.getFieldsValue();
    const labelWidth = formValues.width;
    const widthPlusTwo = parseFloat(labelWidth) + 2.5;
    const widthPlusNull = parseFloat(labelWidth);
    const typeOfLabelCil = formValues.typeOfLabel;
    const index = machineSteps.findIndex((step) => step.name === value);

    if (value !== "Konica Minolta" && value !== "MPS") {
      setIsDigitalPrint(false);
      if (
        typeOfLabelCil === "samolepljiva papirna" ||
        typeOfLabelCil === "samolepljiva termo ECO" ||
        typeOfLabelCil === "samolepljiva termo TOP" ||
        typeOfLabelCil === "samolepljiva PP"
      ) {
        let findedStep;
        let findedSteps = [];
        for (const step of stepsArray) {
          if (
            ((step.br_cilindra === "8" ||
              step.br_cilindra === "8 3/4" ||
              step.br_cilindra === "9" ||
              step.br_cilindra === "11" ||
              step.br_cilindra === "12" ||
              step.br_cilindra === "12 1/4" ||
              step.br_cilindra === "13" ||
              step.br_cilindra === "14 1/4") &&
              step.sirina > widthPlusTwo) ||
            (machine === "Mark Andy" &&
              widthPlusNull === 150 &&
              typeOfLabelCil === "samolepljiva PP" &&
              step.sirina > widthPlusNull)
          ) {
            findedSteps.push(step);
          }
        }
        findedSteps.sort((a, b) =>
          a.sirina > b.sirina ? 1 : b.sirina > a.sirina ? -1 : 0,
        );
        findedStep = findedSteps[0];
        let { minWidth, n, m } = calculateMinWidth(
          printingStepsOne,
          widthPlusTwo,
          findedStep?.br_cilindra,
        );
        if (
          minWidth < findedStep?.sirina - widthPlusTwo &&
          machineSteps[index]?.values.includes(printingStepsOne[n].maxWidth) &&
          labelWidth
        ) {
          if (checkVisibleElements()) {
            document.getElementById("assignWidth").innerText = widthPlusTwo;
            document.getElementById("widthTable").innerText =
              printingStepsOne[n].steps[m].width;
            document.getElementById("numberOfItems").innerText =
              printingStepsOne[n].steps[m].numberOfItems;
            document.getElementById("numberOfCylinders").innerText =
              printingStepsOne[n].numberOfCylinders;
            document.getElementById("volume").innerText =
              printingStepsOne[n].maxWidth;
          }
          setVisible(true);
        } else if (
          machineSteps[index]?.values.includes(parseFloat(findedStep?.obim)) &&
          labelWidth
        ) {
          if (checkVisibleElements()) {
            document.getElementById("assignWidth").innerText = widthPlusTwo;
            document.getElementById("widthTable").innerText = findedStep.sirina;
            document.getElementById("numberOfItems").innerText =
              findedStep.br_komada_tm;
            document.getElementById("numberOfCylinders").innerText =
              findedStep.br_cilindra;
            document.getElementById("volume").innerText = findedStep.obim;
          }
          setVisible(true);
        } else {
          setEmptyValues();
          setVisible(false);
        }
      } else {
        let findedStep;
        let findedSteps = [];
        for (const step of stepsArray) {
          if (step.sirina >= widthPlusNull) {
            findedSteps.push(step);
          }
        }
        findedSteps.sort((a, b) =>
          a.sirina > b.sirina ? 1 : b.sirina > a.sirina ? -1 : 0,
        );
        findedStep = findedSteps[0];
        let { minWidth, n, m } =
          findedStep?.br_cilindra === "12 7/8"
            ? calculateMinWidth(printingStepsOne, widthPlusNull)
            : calculateMinWidth(printingStepsTwo, widthPlusNull);
        if (
          findedStep?.br_cilindra === "12 7/8" &&
          machineSteps[index].values.includes(printingStepsOne[n].maxWidth)
        ) {
          if (checkVisibleElements()) {
            document.getElementById("assignWidth").innerText = widthPlusNull;
            document.getElementById("widthTable").innerText =
              printingStepsOne[n].steps[m].width;
            document.getElementById("numberOfItems").innerText =
              printingStepsOne[n].steps[m].numberOfItems;
            document.getElementById("numberOfCylinders").innerText =
              printingStepsOne[n].numberOfCylinders;
            document.getElementById("volume").innerText =
              printingStepsOne[n].maxWidth;
          }
          setVisible(true);
        } else if (
          minWidth < findedStep?.sirina - widthPlusNull &&
          machineSteps[index].values.includes(printingStepsTwo[n].maxWidth)
        ) {
          if (checkVisibleElements()) {
            document.getElementById("assignWidth").innerText = widthPlusNull;
            document.getElementById("widthTable").innerText =
              printingStepsTwo[n].steps[m].width;
            document.getElementById("numberOfItems").innerText =
              printingStepsTwo[n].steps[m].numberOfItems;
            document.getElementById("numberOfCylinders").innerText =
              printingStepsTwo[n].numberOfCylinders;
            document.getElementById("volume").innerText =
              printingStepsTwo[n].maxWidth;
          }
          setVisible(true);
        } else if (
          machineSteps[index]?.values.includes(parseFloat(findedStep?.obim))
        ) {
          if (checkVisibleElements()) {
            document.getElementById("assignWidth").innerText = widthPlusNull;
            document.getElementById("widthTable").innerText = findedStep.sirina;
            document.getElementById("numberOfItems").innerText =
              findedStep.br_komada_tm;
            document.getElementById("numberOfCylinders").innerText =
              findedStep.br_cilindra;
            document.getElementById("volume").innerText = findedStep.obim;
          }
          setVisible(true);
        } else {
          setEmptyValues();
          setVisible(false);
        }
      }
    } else if (value === "Konica Minolta") {
      setIsDigitalPrint(true);
      let findedStep;
      let findedSteps = [];
      for (const step of stepsArray) {
        if (
          (step.br_cilindra === "11" ||
            step.br_cilindra === "12" ||
            step.br_cilindra === "13") &&
          step.sirina > widthPlusTwo
        ) {
          findedSteps.push(step);
        }
      }
      findedSteps.sort((a, b) =>
        a.sirina > b.sirina ? 1 : b.sirina > a.sirina ? -1 : 0,
      );
      findedStep = findedSteps[0];
      let allPrintSteps = printingStepsOne.concat(printingStepsTwo);
      let { minWidth, n, m } = calculateMinWidth(
        allPrintSteps,
        widthPlusTwo,
        findedStep?.br_cilindra,
      );
      if (
        minWidth < findedStep?.sirina - widthPlusTwo &&
        machineSteps[index]?.values.includes(allPrintSteps[n].maxWidth) &&
        labelWidth
      ) {
        document.getElementById("assignWidth").innerText = widthPlusTwo;
        document.getElementById("widthTable").innerText =
          allPrintSteps[n].steps[m].width;
        document.getElementById("numberOfItems").innerText =
          allPrintSteps[n].steps[m].numberOfItems;
        document.getElementById("numberOfCylinders").innerText =
          allPrintSteps[n].numberOfCylinders;
        document.getElementById("volume").innerText = allPrintSteps[n].maxWidth;
        setVisible(true);
      } else if (
        machineSteps[index]?.values.includes(parseFloat(findedStep?.obim)) &&
        labelWidth
      ) {
        document.getElementById("assignWidth").innerText = widthPlusTwo;
        document.getElementById("widthTable").innerText = findedStep.sirina;
        document.getElementById("numberOfItems").innerText =
          findedStep.br_komada_tm;
        document.getElementById("numberOfCylinders").innerText =
          findedStep.br_cilindra;
        document.getElementById("volume").innerText = findedStep.obim;
        setVisible(true);
      } else {
        setEmptyValues();
        setVisible(false);
      }
      formValues.height &&
        formValues.numberOfRowsToCut &&
        parseFloat(formValues.height) *
          parseFloat(formValues.numberOfRowsToCut) >
          300 &&
        setTimeout(
          () =>
            alert(
              "Prekoračena je maksimalno dozvoljena visina sa brojem redova od 300 mm!",
            ),
          300,
        );
    } else if (value === "MPS") {
      const stepsMPS = [
        "10",
        "10 1/2",
        "10 7/8",
        "11 1/4",
        "11 3/8",
        "12",
        "12 1/8",
        "12 3/8",
        "12 7/8",
        "13 1/4",
        "13 5/8",
        "14 1/2",
        "15 1/2",
        "16",
        "16 1/2",
        "17",
        "19 1/2",
      ];

      if (
        typeOfLabelCil === "samolepljiva papirna" ||
        typeOfLabelCil === "samolepljiva termo ECO" ||
        typeOfLabelCil === "samolepljiva termo TOP" ||
        typeOfLabelCil === "samolepljiva PP"
      ) {
        let findedStep;
        let findedSteps = [];
        for (const step of stepsArray) {
          if (
            step.sirina >= widthPlusTwo &&
            stepsMPS.includes(step.br_cilindra)
          ) {
            findedSteps.push(step);
          }
        }
        findedSteps.sort((a, b) =>
          a.sirina > b.sirina ? 1 : b.sirina > a.sirina ? -1 : 0,
        );
        findedStep = findedSteps[0];
        let assignWidth;
        assignWidth = widthPlusTwo;
        let { minWidth, n, m } =
          findedStep?.br_cilindra === "12 7/8"
            ? calculateMinWidth(
                printingStepsOne.filter((item) =>
                  stepsMPS.includes(item.numberOfCylinders),
                ),
                widthPlusTwo,
              )
            : calculateMinWidth(
                printingStepsTwo.filter((item) =>
                  stepsMPS.includes(item.numberOfCylinders),
                ),
                widthPlusTwo,
              );
        if (
          findedStep?.br_cilindra === "12 7/8" &&
          machineSteps[index].values.includes(printingStepsOne[n].maxWidth)
        ) {
          if (checkVisibleElements()) {
            document.getElementById("assignWidth").innerText = assignWidth;
            document.getElementById("widthTable").innerText =
              printingStepsOne[n].steps[m].width;
            document.getElementById("numberOfItems").innerText =
              printingStepsOne[n].steps[m].numberOfItems;
            document.getElementById("numberOfCylinders").innerText =
              printingStepsOne[n].numberOfCylinders;
            document.getElementById("volume").innerText =
              printingStepsOne[n].maxWidth;
          }
          setVisible(true);
        } else if (
          minWidth < findedStep?.sirina - widthPlusTwo &&
          machineSteps[index].values.includes(printingStepsTwo[n].maxWidth)
        ) {
          if (checkVisibleElements()) {
            document.getElementById("assignWidth").innerText = assignWidth;
            document.getElementById("widthTable").innerText =
              printingStepsTwo[n].steps[m].width;
            document.getElementById("numberOfItems").innerText =
              printingStepsTwo[n].steps[m].numberOfItems;
            document.getElementById("numberOfCylinders").innerText =
              printingStepsTwo[n].numberOfCylinders;
            document.getElementById("volume").innerText =
              printingStepsTwo[n].maxWidth;
          }
          setVisible(true);
        } else if (
          machineSteps[index]?.values.includes(parseFloat(findedStep?.obim))
        ) {
          if (checkVisibleElements()) {
            document.getElementById("assignWidth").innerText = assignWidth;
            document.getElementById("widthTable").innerText = findedStep.sirina;
            document.getElementById("numberOfItems").innerText =
              findedStep.br_komada_tm;
            document.getElementById("numberOfCylinders").innerText =
              findedStep.br_cilindra;
            document.getElementById("volume").innerText = findedStep.obim;
          }
          setVisible(true);
        } else {
          setEmptyValues();
          setVisible(false);
        }
      } else {
        let findedStep;
        let findedSteps = [];
        for (const step of stepsArray) {
          if (
            step.sirina >= widthPlusNull &&
            stepsMPS.includes(step.br_cilindra)
          ) {
            findedSteps.push(step);
          }
        }
        findedSteps.sort((a, b) =>
          a.sirina > b.sirina ? 1 : b.sirina > a.sirina ? -1 : 0,
        );
        findedStep = findedSteps[0];
        let assignWidth;
        assignWidth = widthPlusNull;
        let { minWidth, n, m } =
          findedStep?.br_cilindra === "12 7/8"
            ? calculateMinWidth(
                printingStepsOne.filter((item) =>
                  stepsMPS.includes(item.numberOfCylinders),
                ),
                widthPlusNull,
              )
            : calculateMinWidth(
                printingStepsTwo.filter((item) =>
                  stepsMPS.includes(item.numberOfCylinders),
                ),
                widthPlusNull,
              );
        if (
          findedStep?.br_cilindra === "12 7/8" &&
          machineSteps[index].values.includes(printingStepsOne[n].maxWidth)
        ) {
          if (checkVisibleElements()) {
            document.getElementById("assignWidth").innerText = assignWidth;
            document.getElementById("widthTable").innerText =
              printingStepsOne[n].steps[m].width;
            document.getElementById("numberOfItems").innerText =
              printingStepsOne[n].steps[m].numberOfItems;
            document.getElementById("numberOfCylinders").innerText =
              printingStepsOne[n].numberOfCylinders;
            document.getElementById("volume").innerText =
              printingStepsOne[n].maxWidth;
          }
          setVisible(true);
        } else if (
          minWidth < findedStep?.sirina - widthPlusNull &&
          machineSteps[index].values.includes(printingStepsTwo[n].maxWidth)
        ) {
          if (checkVisibleElements()) {
            document.getElementById("assignWidth").innerText = assignWidth;
            document.getElementById("widthTable").innerText =
              printingStepsTwo[n].steps[m].width;
            document.getElementById("numberOfItems").innerText =
              printingStepsTwo[n].steps[m].numberOfItems;
            document.getElementById("numberOfCylinders").innerText =
              printingStepsTwo[n].numberOfCylinders;
            document.getElementById("volume").innerText =
              printingStepsTwo[n].maxWidth;
          }
          setVisible(true);
        } else if (
          machineSteps[index]?.values.includes(parseFloat(findedStep?.obim))
        ) {
          if (checkVisibleElements()) {
            document.getElementById("assignWidth").innerText = assignWidth;
            document.getElementById("widthTable").innerText = findedStep.sirina;
            document.getElementById("numberOfItems").innerText =
              findedStep.br_komada_tm;
            document.getElementById("numberOfCylinders").innerText =
              findedStep.br_cilindra;
            document.getElementById("volume").innerText = findedStep.obim;
          }
          setVisible(true);
        } else {
          setEmptyValues();
          setVisible(false);
        }
      }
    }
  };

  useEffect(() => {
    if (machine === "Mark Andy") {
      setMaxWidthCheck(250);
      notification.info({
        message: "Maksimalna sirina za ovu masinu je 250mm!",
        placement: "bottomRight",
        duration: 10,
      });
    } else if (machine === "Arsoma") {
      setMaxWidthCheck(410);
      notification.info({
        message: "Maksimalna sirina za ovu masinu je 410mm!",
        placement: "bottomRight",
        duration: 10,
      });
    } else if (machine === "MPS") {
      setMaxWidthCheck(420);
      notification.info({
        message: "Maksimalna sirina za ovu masinu je 420mm!",
        placement: "bottomRight",
        duration: 10,
      });
    } else if (machine === "Konica Minolta") {
      setHidePrintingForm(true);
      // printingFormsPrice = 0;
    }
  }, [machine]);

  const numberOfColorsHandler = (event, form) => {
    const formValues = form.getFieldsValue();
    const numberOfColors = event.target.value;
    let typeOfLabel = formValues.typeOfLabel;
    if (numberOfColors > 0 && numberOfColors !== "") {
      switch (typeOfLabel) {
        case "OPP beli":
        case "OPP transparentni 35 mic":
        case "OPP transparentni 40 mic":
          form.setFieldsValue({ degreeOfPaintConsumption: 2 });
          break;
        case "samolepljiva papirna":
        case "samolepljiva termo ECO":
        case "samolepljiva termo TOP":
          form.setFieldsValue({ degreeOfPaintConsumption: 3.6 });
          break;
        case "samolepljiva PP":
          form.setFieldsValue({ degreeOfPaintConsumption: 2.6 });
          break;
        // case 'drugo': form.setFieldsValue({ degreeOfPaintConsumption: 'Unesite vrednost' });
        // break;
        default:
          form.setFieldsValue({ degreeOfPaintConsumption: undefined });
      }
      if (hideColorPrice === true) {
        setHideColorQuantity(false);
      }
    } else {
      setHideColorQuantity(true);
    }
  };

  const typeOfLabelHandler = async (value, form) => {
    const data = form.getFieldsValue();
    const typeOfLabel = data.typeOfLabel;

    const circulation = data.circulation;

    const numberOfRows = data.numberOfRowsToCut;

    let numberOfItems = parseFloat(
      document.getElementById("numberOfItems").innerText,
    );
    const calculatedWastePaper = data.calculatedWastePaper;

    let currentMeters =
      circulation / (numberOfItems * numberOfRows) +
      parseFloat(calculatedWastePaper);

    if (machine) {
      if (document.getElementById("printingSpeedOtherNumber").value !== "") {
        switch (typeOfLabel) {
          case "OPP beli":
            document.getElementById("printingSpeed").innerText =
              document.getElementById("printingSpeedOtherNumber").value +
              " m/min";
            console.log(document.getElementById("printingSpeed").innerText);
            break;
          case "OPP transparentni 35 mic":
          case "OPP transparentni 40 mic":
            document.getElementById("printingSpeed").innerText =
              document.getElementById("printingSpeedOtherNumber").value +
              " m/min";
            console.log(document.getElementById("printingSpeed").innerText);

            break;
          case "samolepljiva papirna":
          case "samolepljiva termo ECO":
          case "samolepljiva termo TOP":
            document.getElementById("printingSpeed").innerText =
              document.getElementById("printingSpeedOtherNumber").value +
              " m/min";
            console.log(document.getElementById("printingSpeed").innerText);

            break;
          case "samolepljiva PP":
            document.getElementById("printingSpeed").innerText =
              document.getElementById("printingSpeedOtherNumber").value +
              " m/min";
            console.log(document.getElementById("printingSpeed").innerText);

            break;
          case "SHRINK PVC 45 mic":
          case "SHRINK PVC 50 mic":
          case "SHRINK PET 45 mic":
          case "SHRINK PET 50 mic":
            document.getElementById("printingSpeed").innerText =
              document.getElementById("printingSpeedOtherNumber").value +
              " m/min";
            console.log(document.getElementById("printingSpeed").innerText);

            break;
          default:
            document.getElementById("printingSpeed").innerText =
              form.getFieldValue("printingSpeed") + " m/min";
            console.log(document.getElementById("printingSpeed").innerText);
        }
        // if (typeOfLabel.includes("drugo")) {
        //   document.getElementById("printingSpeed").innerText =
        //     document.getElementById("printingSpeedOtherNumber").value +
        //     " m/min";
        //   console.log(document.getElementById("printingSpeed").innerText);
        // } else if (machine === "MPS" && !typeOfLabel.includes("SHRINK")) {
        //   document.getElementById("printingSpeed").innerText = 133 + " m/min";
        // }
      } else {
        switch (typeOfLabel) {
          case "OPP beli":
            document.getElementById("printingSpeed").innerText = 70 + " m/min";
            break;
          case "OPP transparentni 35 mic":
          case "OPP transparentni 40 mic":
            document.getElementById("printingSpeed").innerText = 55 + " m/min";
            break;
          case "samolepljiva papirna":
          case "samolepljiva termo ECO":
          case "samolepljiva termo TOP":
          case "samolepljiva PP":
            document.getElementById("printingSpeed").innerText = 40 + " m/min";
            break;
          case "SHRINK PVC 45 mic":
          case "SHRINK PVC 50 mic":
          case "SHRINK PET 45 mic":
          case "SHRINK PET 50 mic":
            document.getElementById("printingSpeed").innerText = 100 + " m/min";
            break;
          default:
            document.getElementById("printingSpeed").innerText =
              form.getFieldValue("printingSpeed") + " m/min";
        }
        if (typeOfLabel.includes("drugo")) {
          document.getElementById("printingSpeed").innerText =
            document.getElementById("printingSpeedOtherNumber").value +
            " m/min";
        } else if (machine === "MPS" && !typeOfLabel.includes("SHRINK")) {
          document.getElementById("printingSpeed").innerText = 133 + " m/min";
        }
      }
    } else {
      document.getElementById("printingSpeed").innerText = 133 + " m/min";
    }

    console.log(
      typeof document.getElementById("printingSpeedOtherNumber").value,
    );

    if (document.getElementById("printingSpeedOtherNumber").value !== "") {
      switch (typeOfLabel) {
        case "OPP beli":
          let minGrossOppWhite =
            currentMeters /
            document.getElementById("printingSpeedOtherNumber").value;
          if (machine === "MPS") {
            minGrossOppWhite =
              currentMeters /
              document.getElementById("printingSpeedOtherNumber").value;
          }
          let hourGrossOppWhite = minGrossOppWhite / 60;
          document.getElementById("totalTimeOfPrinting").innerText =
            hourGrossOppWhite.toFixed(2) + " h";
          break;
        case "OPP transparentni 35 mic":
        case "OPP transparentni 40 mic":
          let minGrossOppTransparent =
            currentMeters /
            document.getElementById("printingSpeedOtherNumber").value;
          if (machine === "MPS") {
            minGrossOppTransparent =
              currentMeters /
              document.getElementById("printingSpeedOtherNumber").value;
          }
          let hourGrossOppTransparent = minGrossOppTransparent / 60;
          document.getElementById("totalTimeOfPrinting").innerText =
            hourGrossOppTransparent.toFixed(2) + " h";
          break;
        case "samolepljiva papirna":
        case "samolepljiva termo ECO":
        case "samolepljiva termo TOP":
        case "samolepljiva PP":
          let minGrossOppAll =
            currentMeters /
            document.getElementById("printingSpeedOtherNumber").value;
          if (machine === "MPS") {
            minGrossOppAll =
              currentMeters /
              document.getElementById("printingSpeedOtherNumber").value;
          }
          let hourGrossOppAll = minGrossOppAll / 60;
          document.getElementById("totalTimeOfPrinting").innerText =
            hourGrossOppAll.toFixed(2) + " h";
          break;
        default:
          // let totalTimeOfPrinting = form.getFieldValue('totalTimeOfPrinting');
          let totalTimeOfPrinting = document
            .getElementById("printingSpeed")
            .innerText.replace(" m/min", "");
          let minGrossDefault = currentMeters / totalTimeOfPrinting;
          let hourMinGrossDefault = minGrossDefault / 60;
          document.getElementById("totalTimeOfPrinting").innerText =
            hourMinGrossDefault.toFixed(2) + " h";
      }
    } else {
      switch (typeOfLabel) {
        case "OPP beli":
          let minGrossOppWhite = currentMeters / 70;
          if (machine === "MPS") {
            minGrossOppWhite = currentMeters / 133;
          }
          let hourGrossOppWhite = minGrossOppWhite / 60;
          document.getElementById("totalTimeOfPrinting").innerText =
            hourGrossOppWhite.toFixed(2) + " h";
          break;
        case "OPP transparentni 35 mic":
        case "OPP transparentni 40 mic":
          let minGrossOppTransparent = currentMeters / 55;
          if (machine === "MPS") {
            minGrossOppTransparent = currentMeters / 133;
          }
          let hourGrossOppTransparent = minGrossOppTransparent / 60;
          document.getElementById("totalTimeOfPrinting").innerText =
            hourGrossOppTransparent.toFixed(2) + " h";
          break;
        case "samolepljiva papirna":
        case "samolepljiva termo ECO":
        case "samolepljiva termo TOP":
        case "samolepljiva PP":
          let minGrossOppAll = currentMeters / 40;
          if (machine === "MPS") {
            minGrossOppAll = currentMeters / 133;
          }
          let hourGrossOppAll = minGrossOppAll / 60;
          document.getElementById("totalTimeOfPrinting").innerText =
            hourGrossOppAll.toFixed(2) + " h";
          break;
        default:
          // let totalTimeOfPrinting = form.getFieldValue('totalTimeOfPrinting');
          let totalTimeOfPrinting = document
            .getElementById("printingSpeed")
            .innerText.replace(" m/min", "");
          let minGrossDefault = currentMeters / totalTimeOfPrinting;
          let hourMinGrossDefault = minGrossDefault / 60;
          document.getElementById("totalTimeOfPrinting").innerText =
            hourMinGrossDefault.toFixed(2) + " h";
      }
    }

    const formValues = form.getFieldsValue();
    const labelWidth = formValues.width;
    const widthPlusTwo = parseFloat(labelWidth) + 2.5;
    const widthPlusNull = parseFloat(labelWidth);
    switch (value) {
      case "OPP beli":
        form.setFieldsValue({
          materialPrice: 2.9,
          degreeOfPaintConsumption: 2,
          degreeOfVarnishConsumption: 0.75,
        });
        break;
      case "OPP transparentni 35 mic":
      case "OPP transparentni 40 mic":
        form.setFieldsValue({
          materialPrice: 2.73,
          degreeOfPaintConsumption: 2,
          degreeOfVarnishConsumption: 0.75,
        });
        break;
      case "samolepljiva papirna":
        form.setFieldsValue({
          materialPrice: 0.43,
          degreeOfPaintConsumption: 3.6,
          degreeOfVarnishConsumption: 3.0,
        });
        break;
      case "samolepljiva termo ECO":
        form.setFieldsValue({
          materialPrice: 0.4,
          degreeOfPaintConsumption: 3.6,
          degreeOfVarnishConsumption: 3.0,
        });
        break;
      case "samolepljiva termo TOP":
        form.setFieldsValue({
          materialPrice: 0.57,
          degreeOfPaintConsumption: 3.6,
          degreeOfVarnishConsumption: 3.0,
        });
        break;
      case "samolepljiva PP":
        form.setFieldsValue({
          materialPrice: 0.6,
          degreeOfPaintConsumption: 2.6,
          degreeOfVarnishConsumption: 2.0,
        });
        break;
      case "SHRINK PVC 45 mic":
        form.setFieldsValue({ materialPrice: 4.59 });
        break;
      case "SHRINK PVC 50 mic":
        form.setFieldsValue({ materialPrice: 4.53 });
        break;
      case "SHRINK PET 45 mic":
        form.setFieldsValue({ materialPrice: 4.93 });
        break;
      case "SHRINK PET 50 mic":
        form.setFieldsValue({ materialPrice: 4.46 });
        break;
      case "drugo":
        form.setFieldsValue({
          materialPrice: undefined,
          degreeOfPaintConsumption: undefined,
          degreeOfVarnishConsumption: undefined,
        });
        setHidePrintingSpeedOther(false);
        setHideTotalTimeOfPrintingOther(false);
        setHideTotalElectricityOther(false);
        setHideTotalTimeOfPrinting(false);
        setHidePrintingSpeed(false);
        setMaterialPricePlaceholder("Unesite cenu");
        break;
      case "SAMOLEPLJIVI":
        form.setFieldsValue({ materialPrice: 0.206 });
        break;
      case "TRIPLEX/KARTON":
        form.setFieldsValue({ materialPrice: 1.7, materialWeight: 100 });
        break;
      case "OFSET":
        form.setFieldsValue({ materialPrice: 1.6, materialWeight: 80 });
        break;
      case "KUNST.":
        form.setFieldsValue({ materialPrice: 1.47, materialWeight: 130 });
        break;
      default:
        form.setFieldsValue({
          materialPrice: undefined,
          degreeOfPaintConsumption: undefined,
          degreeOfVarnishConsumption: undefined,
        });
        setMaterialPricePlaceholder("Izaberite materijal");
    }
    if (value !== "drugo") {
      setHidePrintingSpeed(false);
      setHidePrintingSpeedOther(true);
      setHideTotalTimeOfPrinting(false);
      setHideTotalTimeOfPrintingOther(true);
      setHideTotalElectricityOther(true);
    }
    const index = machineSteps.findIndex((step) => step.name === machine);
    if (machine && machine !== "Konica Minolta" && machine !== "MPS") {
      if (
        (value === "samolepljiva papirna" ||
          value === "samolepljiva termo ECO" ||
          value === "samolepljiva termo TOP" ||
          value === "samolepljiva PP") &&
        labelWidth
      ) {
        let findedStep;
        let findedSteps = [];
        for (const step of stepsArray) {
          if (
            ((step.br_cilindra === "8" ||
              step.br_cilindra === "8 3/4" ||
              step.br_cilindra === "9" ||
              step.br_cilindra === "11" ||
              step.br_cilindra === "12" ||
              step.br_cilindra === "12 1/4" ||
              step.br_cilindra === "13" ||
              step.br_cilindra === "14 1/4") &&
              step.sirina > widthPlusTwo) ||
            (machine === "Mark Andy" &&
              widthPlusNull === 150 &&
              value === "samolepljiva PP" &&
              step.sirina > widthPlusNull)
          ) {
            findedSteps.push(step);
          }
        }
        findedSteps.sort((a, b) =>
          a.sirina > b.sirina ? 1 : b.sirina > a.sirina ? -1 : 0,
        );
        findedStep = findedSteps[0];
        let { minWidth, n, m } = calculateMinWidth(
          printingStepsOne,
          widthPlusTwo,
          findedStep?.br_cilindra,
        );
        if (
          minWidth < findedStep?.sirina - widthPlusTwo &&
          machineSteps[index]?.values.includes(printingStepsOne[n].maxWidth) &&
          labelWidth
        ) {
          if (checkVisibleElements()) {
            document.getElementById("assignWidth").innerText = widthPlusTwo;
            document.getElementById("widthTable").innerText =
              printingStepsOne[n].steps[m].width;
            document.getElementById("numberOfItems").innerText =
              printingStepsOne[n].steps[m].numberOfItems;
            document.getElementById("numberOfCylinders").innerText =
              printingStepsOne[n].numberOfCylinders;
            document.getElementById("volume").innerText =
              printingStepsOne[n].maxWidth;
          }
          setVisible(true);
        } else if (
          machineSteps[index]?.values.includes(parseFloat(findedStep?.obim)) &&
          labelWidth
        ) {
          if (checkVisibleElements()) {
            document.getElementById("assignWidth").innerText = widthPlusTwo;
            document.getElementById("widthTable").innerText = findedStep.sirina;
            document.getElementById("numberOfItems").innerText =
              findedStep.br_komada_tm;
            document.getElementById("numberOfCylinders").innerText =
              findedStep.br_cilindra;
            document.getElementById("volume").innerText = findedStep.obim;
          }
          setVisible(true);
        } else {
          setEmptyValues();
          setVisible(false);
        }
      } else {
        let findedStep;
        let findedSteps = [];
        for (const step of stepsArray) {
          if (step.sirina >= widthPlusNull) {
            findedSteps.push(step);
          }
        }
        findedSteps.sort((a, b) =>
          a.sirina > b.sirina ? 1 : b.sirina > a.sirina ? -1 : 0,
        );
        findedStep = findedSteps[0];
        let { minWidth, n, m } =
          findedStep?.br_cilindra === "12 7/8"
            ? calculateMinWidth(printingStepsOne, widthPlusNull)
            : calculateMinWidth(printingStepsTwo, widthPlusNull);
        if (
          findedStep?.br_cilindra === "12 7/8" &&
          machineSteps[index].values.includes(printingStepsOne[n].maxWidth)
        ) {
          if (checkVisibleElements()) {
            document.getElementById("assignWidth").innerText = widthPlusNull;
            document.getElementById("widthTable").innerText =
              printingStepsOne[n].steps[m].width;
            document.getElementById("numberOfItems").innerText =
              printingStepsOne[n].steps[m].numberOfItems;
            document.getElementById("numberOfCylinders").innerText =
              printingStepsOne[n].numberOfCylinders;
            document.getElementById("volume").innerText =
              printingStepsOne[n].maxWidth;
          }
          setVisible(true);
        } else if (
          minWidth < findedStep?.sirina - widthPlusNull &&
          machineSteps[index].values.includes(printingStepsTwo[n].maxWidth)
        ) {
          if (checkVisibleElements()) {
            document.getElementById("assignWidth").innerText = widthPlusNull;
            document.getElementById("widthTable").innerText =
              printingStepsTwo[n].steps[m].width;
            document.getElementById("numberOfItems").innerText =
              printingStepsTwo[n].steps[m].numberOfItems;
            document.getElementById("numberOfCylinders").innerText =
              printingStepsTwo[n].numberOfCylinders;
            document.getElementById("volume").innerText =
              printingStepsTwo[n].maxWidth;
          }
          setVisible(true);
        } else if (
          machineSteps[index]?.values.includes(parseFloat(findedStep?.obim))
        ) {
          if (checkVisibleElements()) {
            document.getElementById("assignWidth").innerText = widthPlusNull;
            document.getElementById("widthTable").innerText = findedStep.sirina;
            document.getElementById("numberOfItems").innerText =
              findedStep.br_komada_tm;
            document.getElementById("numberOfCylinders").innerText =
              findedStep.br_cilindra;
            document.getElementById("volume").innerText = findedStep.obim;
          }
          setVisible(true);
        } else {
          setEmptyValues();
          setVisible(false);
        }
      }
    } else if (!machine && printType === "rolna") {
      alert("Izaberite mašinu");
      form.setFieldsValue({ typeOfLabel: "" });
    } else if (machine === "Konica Minolta") {
      let findedStep;
      let findedSteps = [];
      for (const step of stepsArray) {
        if (
          (step.br_cilindra === "11" ||
            step.br_cilindra === "12" ||
            step.br_cilindra === "13") &&
          step.sirina > widthPlusTwo
        ) {
          findedSteps.push(step);
        }
      }
      findedSteps.sort((a, b) =>
        a.sirina > b.sirina ? 1 : b.sirina > a.sirina ? -1 : 0,
      );
      findedStep = findedSteps[0];
      let allPrintSteps = printingStepsOne.concat(printingStepsTwo);
      let { minWidth, n, m } = calculateMinWidth(
        allPrintSteps,
        widthPlusTwo,
        findedStep?.br_cilindra,
      );
      if (
        minWidth < findedStep?.sirina - widthPlusTwo &&
        machineSteps[index]?.values.includes(allPrintSteps[n].maxWidth) &&
        labelWidth
      ) {
        document.getElementById("assignWidth").innerText = widthPlusTwo;
        document.getElementById("widthTable").innerText =
          allPrintSteps[n].steps[m].width;
        document.getElementById("numberOfItems").innerText =
          allPrintSteps[n].steps[m].numberOfItems;
        document.getElementById("numberOfCylinders").innerText =
          allPrintSteps[n].numberOfCylinders;
        document.getElementById("volume").innerText = allPrintSteps[n].maxWidth;
        setVisible(true);
      } else if (
        machineSteps[index]?.values.includes(parseFloat(findedStep?.obim)) &&
        labelWidth
      ) {
        document.getElementById("assignWidth").innerText = widthPlusTwo;
        document.getElementById("widthTable").innerText = findedStep.sirina;
        document.getElementById("numberOfItems").innerText =
          findedStep.br_komada_tm;
        document.getElementById("numberOfCylinders").innerText =
          findedStep.br_cilindra;
        document.getElementById("volume").innerText = findedStep.obim;
        setVisible(true);
      } else {
        setEmptyValues();
        setVisible(false);
      }
    } else if (machine === "MPS") {
      const stepsMPS = [
        "10",
        "10 1/2",
        "10 7/8",
        "11 1/4",
        "11 3/8",
        "12",
        "12 1/8",
        "12 3/8",
        "12 7/8",
        "13 1/4",
        "13 5/8",
        "14 1/2",
        "15 1/2",
        "16",
        "16 1/2",
        "17",
        "19 1/2",
      ];
      if (
        value === "samolepljiva papirna" ||
        value === "samolepljiva termo ECO" ||
        value === "samolepljiva termo TOP" ||
        value === "samolepljiva PP"
      ) {
        let findedStep;
        let findedSteps = [];
        for (const step of stepsArray) {
          if (
            step.sirina >= widthPlusTwo &&
            stepsMPS.includes(step.br_cilindra)
          ) {
            findedSteps.push(step);
          }
        }
        findedSteps.sort((a, b) =>
          a.sirina > b.sirina ? 1 : b.sirina > a.sirina ? -1 : 0,
        );
        findedStep = findedSteps[0];
        let assignWidth;
        assignWidth = widthPlusTwo;
        let { minWidth, n, m } =
          findedStep?.br_cilindra === "12 7/8"
            ? calculateMinWidth(
                printingStepsOne.filter((item) =>
                  stepsMPS.includes(item.numberOfCylinders),
                ),
                widthPlusTwo,
              )
            : calculateMinWidth(
                printingStepsTwo.filter((item) =>
                  stepsMPS.includes(item.numberOfCylinders),
                ),
                widthPlusTwo,
              );
        if (
          findedStep?.br_cilindra === "12 7/8" &&
          machineSteps[index].values.includes(printingStepsOne[n].maxWidth)
        ) {
          if (checkVisibleElements()) {
            document.getElementById("assignWidth").innerText = assignWidth;
            document.getElementById("widthTable").innerText =
              printingStepsOne[n].steps[m].width;
            document.getElementById("numberOfItems").innerText =
              printingStepsOne[n].steps[m].numberOfItems;
            document.getElementById("numberOfCylinders").innerText =
              printingStepsOne[n].numberOfCylinders;
            document.getElementById("volume").innerText =
              printingStepsOne[n].maxWidth;
          }
          setVisible(true);
        } else if (
          minWidth < findedStep?.sirina - widthPlusTwo &&
          machineSteps[index].values.includes(printingStepsTwo[n].maxWidth)
        ) {
          if (checkVisibleElements()) {
            document.getElementById("assignWidth").innerText = assignWidth;
            document.getElementById("widthTable").innerText =
              printingStepsTwo[n].steps[m].width;
            document.getElementById("numberOfItems").innerText =
              printingStepsTwo[n].steps[m].numberOfItems;
            document.getElementById("numberOfCylinders").innerText =
              printingStepsTwo[n].numberOfCylinders;
            document.getElementById("volume").innerText =
              printingStepsTwo[n].maxWidth;
          }
          setVisible(true);
        } else if (
          machineSteps[index]?.values.includes(parseFloat(findedStep?.obim))
        ) {
          if (checkVisibleElements()) {
            document.getElementById("assignWidth").innerText = assignWidth;
            document.getElementById("widthTable").innerText = findedStep.sirina;
            document.getElementById("numberOfItems").innerText =
              findedStep.br_komada_tm;
            document.getElementById("numberOfCylinders").innerText =
              findedStep.br_cilindra;
            document.getElementById("volume").innerText = findedStep.obim;
          }
          setVisible(true);
        } else {
          setEmptyValues();
          setVisible(false);
        }
      } else {
        let findedStep;
        let findedSteps = [];
        for (const step of stepsArray) {
          if (
            step.sirina >= widthPlusNull &&
            stepsMPS.includes(step.br_cilindra)
          ) {
            findedSteps.push(step);
          }
        }
        findedSteps.sort((a, b) =>
          a.sirina > b.sirina ? 1 : b.sirina > a.sirina ? -1 : 0,
        );
        findedStep = findedSteps[0];
        let assignWidth;
        assignWidth = widthPlusNull;
        let { minWidth, n, m } =
          findedStep?.br_cilindra === "12 7/8"
            ? calculateMinWidth(
                printingStepsOne.filter((item) =>
                  stepsMPS.includes(item.numberOfCylinders),
                ),
                widthPlusNull,
              )
            : calculateMinWidth(
                printingStepsTwo.filter((item) =>
                  stepsMPS.includes(item.numberOfCylinders),
                ),
                widthPlusNull,
              );
        if (
          findedStep?.br_cilindra === "12 7/8" &&
          machineSteps[index].values.includes(printingStepsOne[n].maxWidth)
        ) {
          if (checkVisibleElements()) {
            document.getElementById("assignWidth").innerText = assignWidth;
            document.getElementById("widthTable").innerText =
              printingStepsOne[n].steps[m].width;
            document.getElementById("numberOfItems").innerText =
              printingStepsOne[n].steps[m].numberOfItems;
            document.getElementById("numberOfCylinders").innerText =
              printingStepsOne[n].numberOfCylinders;
            document.getElementById("volume").innerText =
              printingStepsOne[n].maxWidth;
          }
          setVisible(true);
        } else if (
          minWidth < findedStep?.sirina - widthPlusNull &&
          machineSteps[index].values.includes(printingStepsTwo[n].maxWidth)
        ) {
          if (checkVisibleElements()) {
            document.getElementById("assignWidth").innerText = assignWidth;
            document.getElementById("widthTable").innerText =
              printingStepsTwo[n].steps[m].width;
            document.getElementById("numberOfItems").innerText =
              printingStepsTwo[n].steps[m].numberOfItems;
            document.getElementById("numberOfCylinders").innerText =
              printingStepsTwo[n].numberOfCylinders;
            document.getElementById("volume").innerText =
              printingStepsTwo[n].maxWidth;
          }
          setVisible(true);
        } else if (
          machineSteps[index]?.values.includes(parseFloat(findedStep?.obim))
        ) {
          if (checkVisibleElements()) {
            document.getElementById("assignWidth").innerText = assignWidth;
            document.getElementById("widthTable").innerText = findedStep.sirina;
            document.getElementById("numberOfItems").innerText =
              findedStep.br_komada_tm;
            document.getElementById("numberOfCylinders").innerText =
              findedStep.br_cilindra;
            document.getElementById("volume").innerText = findedStep.obim;
          }
          setVisible(true);
        } else {
          setEmptyValues();
          setVisible(false);
        }
      }
    }
    if (value === "OPP beli") {
      form.setFieldsValue({ grossKgPrice: 23.56 });
    }
    if (value === "OPP transparentni 35 mic") {
      form.setFieldsValue({ grossKgPrice: 31.85 });
    }
    if (value === "OPP transparentni 40 mic") {
      form.setFieldsValue({ grossKgPrice: 36.4 });
    }
    if (value === "SHRINK PVC 50 mic") {
      form.setFieldsValue({ grossKgPrice: 65.91 });
    }
    if (value === "SHRINK PVC 45 mic") {
      form.setFieldsValue({ grossKgPrice: 59.32 });
    }
    if (value === "SHRINK PET 45 mic") {
      form.setFieldsValue({ grossKgPrice: 58.48 });
    }
    if (value === "SHRINK PET 50 mic") {
      form.setFieldsValue({ grossKgPrice: 64.93 });
    }
  };

  const quantityOfSpecialColorsHandler = (event, form) => {
    let quantityColor = event.target.value;
    if (quantityColor > 0) {
      setHideQuantitySpecialColors(false);
    } else {
      setHideQuantitySpecialColors(true);
    }
  };

  const calculatedWastePaperHandler = () => {};

  const varnishingHandler = (event) => {
    const typeOfLabel = form.getFieldValue("typeOfLabel");
    if (event.target.value === true) {
      switch (typeOfLabel) {
        case "OPP beli":
        case "OPP transparentni 35 mic":
        case "OPP transparentni 40 mic":
          form.setFieldsValue({ degreeOfVarnishConsumption: 0.75 });
          break;
        case "samolepljiva papirna":
        case "samolepljiva termo ECO":
        case "samolepljiva termo TOP":
          form.setFieldsValue({ degreeOfVarnishConsumption: 3.0 });
          break;
        case "samolepljiva PP":
          form.setFieldsValue({ degreeOfVarnishConsumption: 2.0 });
          break;
        case "drugo":
          setDegreeOfVarnishConsumptionPlaceholder("Unesite vrednost");
          break;
        default:
          setDegreeOfVarnishConsumptionPlaceholder("Izaberite materijal");
      }
      setHideDegreeOfVarnishConsumption(!event.target.value);
      if (!tableVisible) {
        setHideVarnishPrice(!event.target.value);
      } else {
        setHideVarnishQuantity(false);
      }

      // setHideVarnishQuantity(!event.target.value)
    } else {
      setHideDegreeOfVarnishConsumption(!event.target.value);
      setHideVarnishPrice(!event.target.value);
      setHideVarnishQuantity(true);
    }
  };

  const folioPrintingHandler = (event, form) => {
    const folioPrinting = event.target.value;
    if (folioPrinting === true) {
      setHideFolioWidth(false);
      setHideFolioPrice(false);
      setHideGrossFolio(false);
      setHideFolioPage(false);
      setHideFolioPrinting(false);
    } else {
      setHideFolioWidth(true);
      setHideFolioPrice(true);
      setHideGrossFolio(true);
      setHideFolioPage(true);
      setHideFolioPrinting(true);
    }
  };

  const printingFormHandler = (event) => {
    setHidePrintingForm(!event.target.value);
  };

  const designHandler = (event) => {
    setHideDesign(!event.target.value);
  };

  const boxesHandler = (event) => {
    setHideBoxes(!event.target.value);
  };

  const transportHandler = (event) => {
    setHideTransport(!event.target.value);
  };

  const subcontractorHandler = (event) => {
    setHideSubcontractors(!event.target.value);
  };

  const tiglHandler = (event) => {
    setHideTigl(!event.target.value);
  };

  const bendingHandler = (event) => {
    setHideBending(!event.target.value);
  };

  const isDataFetched = !calculation.isLoading && currentuser.language;

  useEffect(() => {
    if (
      calculation &&
      calculation.data &&
      calculation.data.machine === "Konica Minolta"
    ) {
      setIsDigitalPrint(true);
    }
  }, [calculation]);

  const chooseMachine = (form) => {
    const data = form.getFieldsValue();
    const labelType = data.typeOfLabel;
    let circulation = data.circulation;
    let width = data.width;
    let height = data.height;
    let labelNum = 0;
    let labelNumSecond = 0;
    let sheetNum = 0;
    const heightSheet = parseFloat(data.heightSheet);
    const widthSheet = parseFloat(data.widthSheet);
    let labelNumForm = 0;

    if (width && height && circulation) {
      labelNum =
        Math.floor(310 / parseFloat(width)) *
        Math.floor(470 / parseFloat(height));
      labelNumSecond =
        Math.floor(310 / parseFloat(height)) *
        Math.floor(470 / parseFloat(width));
      if (labelNum > labelNumSecond) {
        labelNumForm = labelNum;
        sheetNum = Math.ceil(parseFloat(circulation) / labelNum);
      } else {
        labelNumForm = labelNumSecond;
        sheetNum = Math.ceil(parseFloat(circulation) / labelNumSecond);
      }
      if (sheetNum > 250) {
        labelNum =
          Math.floor(500 / parseFloat(width)) *
          Math.floor(700 / parseFloat(height));
        labelNumSecond =
          Math.floor(500 / parseFloat(height)) *
          Math.floor(700 / parseFloat(width));
        if (labelNum > labelNumSecond) {
          labelNumForm = labelNum;
          sheetNum = Math.ceil(parseFloat(circulation) / labelNum);
        } else {
          labelNumForm = labelNumSecond;
          sheetNum = Math.ceil(parseFloat(circulation) / labelNumSecond);
        }
        if (!form.getFieldValue("machine")) {
          form.setFieldsValue({ machine: "Roland" });
        }
        form.setFieldsValue({
          labelNum: labelNumForm,
        });
      } else {
        if (!form.getFieldValue("machine")) {
          form.setFieldsValue({
            heightSheet: undefined,
            widthSheet: undefined,
            materialWeight: undefined,
            numberOfColors: undefined,
            quantityOfSpecialColors: undefined,
            numberOfSpecialColors: undefined,
            preparationOfPrintingPresses: undefined,
            printPrice: undefined,
            degreeOfPaintConsumption: undefined,
            priceOfSpecialColors: undefined,
            materialPrice: undefined,
            colorQuantity: undefined,
            calculatedWastePaperSheet: undefined,
            sheetNum: undefined,
            sheetAmount: undefined,
            sheetWeight: undefined,
            totalMaterialPrice: undefined,
            machine: "Konica Minolta Tabak",
          });
        }
        form.setFieldsValue({
          labelNum: labelNumForm,
        });
        if (document.getElementById("printSpeed")) {
          document.getElementById("printSpeed").innerText = "";
        }
        if (document.getElementById("totalTime")) {
          document.getElementById("totalTime").innerText = "";
        }
        if (document.getElementById("totalTimeOfPrinting")) {
          document.getElementById("totalTimeOfPrinting").innerText = "";
        }
        if (document.getElementById("totalElectricity")) {
          document.getElementById("totalElectricity").innerText = "";
        }
        setHideTotalPrice(true);
      }

      if (heightSheet && widthSheet) {
        let labelNum =
          Math.floor(parseFloat(widthSheet) / parseFloat(width)) *
          Math.floor(parseFloat(heightSheet) / parseFloat(height));
        let labelNumSecond =
          Math.floor(parseFloat(widthSheet) / parseFloat(height)) *
          Math.floor(parseFloat(heightSheet) / parseFloat(width));
        if (labelNum > labelNumSecond) {
          labelNumForm = labelNum;
          sheetNum = Math.ceil(parseFloat(circulation) / labelNum);
        } else {
          labelNumForm = labelNumSecond;
          sheetNum = Math.ceil(parseFloat(circulation) / labelNumSecond);
        }
        if (
          sheetNum > 250 ||
          (heightSheet * widthSheet > 480 * 320 && labelType === "OFSET")
        ) {
          if (!form.getFieldValue("machine")) {
            form.setFieldsValue({ machine: "Roland" });
          }
          form.setFieldsValue({
            labelNum: labelNumForm,
          });
        } else {
          if (!form.getFieldValue("machine")) {
            form.setFieldsValue({
              heightSheet: undefined,
              widthSheet: undefined,
              materialWeight: undefined,
              numberOfColors: undefined,
              quantityOfSpecialColors: undefined,
              numberOfSpecialColors: undefined,
              preparationOfPrintingPresses: undefined,
              printPrice: undefined,
              degreeOfPaintConsumption: undefined,
              priceOfSpecialColors: undefined,
              materialPrice: undefined,
              colorQuantity: undefined,
              calculatedWastePaperSheet: undefined,
              sheetNum: undefined,
              sheetAmount: undefined,
              sheetWeight: undefined,
              totalMaterialPrice: undefined,
              machine: "Konica Minolta Tabak",
            });
          }
          form.setFieldsValue({
            labelNum: labelNumForm,
          });
          if (document.getElementById("printSpeed")) {
            document.getElementById("printSpeed").innerText = "";
          }
          if (document.getElementById("totalTime")) {
            document.getElementById("totalTime").innerText = "";
          }
          if (document.getElementById("totalTimeOfPrinting")) {
            document.getElementById("totalTimeOfPrinting").innerText = "";
          }
          if (document.getElementById("totalElectricity")) {
            document.getElementById("totalElectricity").innerText = "";
          }
          if (document.getElementById("totalElectricity")) {
            document.getElementById("totalElectricity").innerText = "";
          }
          if (document.getElementById("labelNum")) {
            document.getElementById("labelNum").innerText = labelNumForm;
          }
          setHideTotalPrice(true);
        }
      }
      setMachine(form.getFieldValue("machine"));
    }
  };

  const changeMaterialPrice = () => {
    const data = form.getFieldsValue();
    let typeOfLabel = data.typeOfLabel;
    let materialWeight = data.materialWeight;
    if (materialWeight) {
      switch (typeOfLabel) {
        case "TRIPLEX/KARTON":
          form.setFieldsValue({
            materialPrice: 1.7,
          });
          break;
        case "OFSET":
          if (materialWeight >= 60 && materialWeight < 80) {
            form.setFieldsValue({
              materialPrice: 1.7,
            });
          } else if (materialWeight >= 80 && materialWeight < 300) {
            form.setFieldsValue({
              materialPrice: 1.6,
            });
          } else {
            form.setFieldsValue({
              materialPrice: 1.6,
            });
          }
          break;
        case "KUNST.":
          if (materialWeight >= 80 && materialWeight < 100) {
            form.setFieldsValue({
              materialPrice: 1.5,
            });
          } else if (materialWeight >= 100 && materialWeight < 200) {
            form.setFieldsValue({
              materialPrice: 1.47,
            });
          } else if (materialWeight >= 250 && materialWeight < 350) {
            form.setFieldsValue({
              materialPrice: 1.5,
            });
          } else {
            form.setFieldsValue({
              materialPrice: 1.47,
            });
          }
          break;
        default:
          form.setFieldsValue({
            materialPrice: undefined,
          });
          break;
      }
    }
  };

  const machineSheetHandler = (form) => {
    const data = form.getFieldsValue();
    let circulation = data.circulation;
    let width = data.width;
    let height = data.height;
    let labelNum = 0;
    let labelNumSecond = 0;
    let sheetNum = 0;
    const heightSheet = parseFloat(data.heightSheet);
    const widthSheet = parseFloat(data.widthSheet);
    let labelNumForm = 0;

    setTableVisible(false);

    if (width && height && circulation) {
      labelNum =
        Math.floor(310 / parseFloat(width)) *
        Math.floor(470 / parseFloat(height));
      labelNumSecond =
        Math.floor(310 / parseFloat(height)) *
        Math.floor(470 / parseFloat(width));
      if (labelNum > labelNumSecond) {
        labelNumForm = labelNum;
        sheetNum = Math.ceil(parseFloat(circulation) / labelNum);
      } else {
        labelNumForm = labelNumSecond;
        sheetNum = Math.ceil(parseFloat(circulation) / labelNumSecond);
      }
      if (sheetNum > 250 && data.machine === "Roland") {
        labelNum =
          Math.floor(500 / parseFloat(width)) *
          Math.floor(700 / parseFloat(height));
        labelNumSecond =
          Math.floor(500 / parseFloat(height)) *
          Math.floor(700 / parseFloat(width));
        if (labelNum > labelNumSecond) {
          labelNumForm = labelNum;
          sheetNum = Math.ceil(parseFloat(circulation) / labelNum);
        } else {
          labelNumForm = labelNumSecond;
          sheetNum = Math.ceil(parseFloat(circulation) / labelNumSecond);
        }
      }
    }

    if (heightSheet && widthSheet && data.machine === "Roland") {
      let labelNum =
        Math.floor(parseFloat(widthSheet) / parseFloat(width)) *
        Math.floor(parseFloat(heightSheet) / parseFloat(height));
      let labelNumSecond =
        Math.floor(parseFloat(widthSheet) / parseFloat(height)) *
        Math.floor(parseFloat(heightSheet) / parseFloat(width));
      if (labelNum > labelNumSecond) {
        labelNumForm = labelNum;
        sheetNum = Math.ceil(parseFloat(circulation) / labelNum);
      } else {
        labelNumForm = labelNumSecond;
        sheetNum = Math.ceil(parseFloat(circulation) / labelNumSecond);
      }
    }
    if (data.machine === "Konica Minolta Tabak") {
      form.setFieldsValue({
        heightSheet: undefined,
        widthSheet: undefined,
        materialWeight: undefined,
        numberOfColors: undefined,
        quantityOfSpecialColors: undefined,
        numberOfSpecialColors: undefined,
        preparationOfPrintingPresses: undefined,
        printPrice: undefined,
        degreeOfPaintConsumption: undefined,
        priceOfSpecialColors: undefined,
        materialPrice: undefined,
        colorQuantity: undefined,
        calculatedWastePaperSheet: undefined,
      });
      if (document.getElementById("printSpeed")) {
        document.getElementById("printSpeed").innerText = "";
      }
      if (document.getElementById("totalTime")) {
        document.getElementById("totalTime").innerText = "";
      }
      if (document.getElementById("totalTimeOfPrinting")) {
        document.getElementById("totalTimeOfPrinting").innerText = "";
      }
      if (document.getElementById("totalElectricity")) {
        document.getElementById("totalElectricity").innerText = "";
      }
      if (document.getElementById("printingSpeed")) {
        document.getElementById("printingSpeed").innerText = "";
      }
      setHideTotalPreparationOfPrintingPresses(true);
      setHideTotalPrice(true);
    }
    form.setFieldsValue({
      sheetNum: undefined,
      sheetAmount: undefined,
      sheetWeight: undefined,
      totalMaterialPrice: undefined,
      labelNum: labelNumForm,
      totalPrice: undefined,
    });
    setMachine(data.machine);
  };

  return (
    <div style={{ padding: "8px" }}>
      <div style={{ textAlign: "center" }}>
        {!calculationId && isDataFetched && (
          <OldCalculationForm
            form={form}
            calculate={calculateHandler}
            calculateSheet={calculateSheet}
            createPdf={createPdf}
            onChangeNumberOfRows={numberOfRowsHandler}
            onChangeLabelHeight={labelHeightHandler}
            onChangeLabelWidth={labelWidthHandler}
            onChangeNumberOfColors={numberOfColorsHandler}
            onChangeQuantityOfSpecialColors={quantityOfSpecialColorsHandler}
            onChangeFolioPrinting={folioPrintingHandler}
            onChangePrintingForm={printingFormHandler}
            onChangeDesign={designHandler}
            onChangeBoxes={boxesHandler}
            onChangeTransport={transportHandler}
            onChangeSubcontractor={subcontractorHandler}
            onChangeVarnishing={varnishingHandler}
            onChangeTypeOfLabel={typeOfLabelHandler}
            onChangeCalculatedWastePaper={calculatedWastePaperHandler}
            onChangeMachine={machineHandler}
            hideColorQuantity={hideColorQuantity}
            hideQuantitySpecialColors={hideQuantitySpecialColors}
            hideGrossFolio={hideGrossFolio}
            hideFolioWidth={hideFolioWidth}
            hideFolioPrice={hideFolioPrice}
            hideFolioPage={hideFolioPage}
            hidePrintingForm={hidePrintingForm}
            hideDesign={hideDesign}
            hideBoxes={hideBoxes}
            hideTransport={hideTransport}
            hideSubcontractors={hideSubcontractors}
            hideTigl={hideTigl}
            hideBending={hideBending}
            hideDegreeOfVarnishConsumption={hideDegreeOfVarnishConsumption}
            hidePrintingSpeed={hidePrintingSpeed}
            hidePrintingSpeedOther={hidePrintingSpeedOther}
            hideTotalTimeOfPrinting={hideTotalTimeOfPrinting}
            hideTotalTimeOfPrintingOther={hideTotalTimeOfPrintingOther}
            hideTotalElectricityOther={hideTotalElectricityOther}
            hideGrossCurrentMeters={hideGrossCurrentMeters}
            hideGrossMetersPrinting={hideGrossMetersPrinting}
            hideGrossKgPrinting={hideGrossKgPrinting}
            hideVarnishQuantity={hideVarnishQuantity}
            hideVarnishPrice={hideVarnishPrice}
            hideColorPrice={hideColorPrice}
            hideTotalPreparationOfPrintingPresses={
              hideTotalPreparationOfPrintingPresses
            }
            hideTotal={hideTotal}
            visible={visible}
            tableVisible={tableVisible}
            dataTable={dataTable}
            materialPricePlaceholder={materialPricePlaceholder}
            degreeOfVarnishConsumptionPlaceholder={
              degreeOfVarnishConsumptionPlaceholder
            }
            hideTotalPrice={hideTotalPrice}
            isDigitalPrint={isDigitalPrint}
            printType={printType}
            setPrintType={setPrintType}
            changeMachine={chooseMachine}
            materialPriceHandler={changeMaterialPrice}
            onChangeTigl={tiglHandler}
            onChangeBending={bendingHandler}
            hideFolioPrinting={hideFolioPrinting}
            onChangeMachineSheet={machineSheetHandler}
            maxWidthCheck={maxWidthCheck}
            machine={machine}
            totalPrintingTimeHandler={totalPrintingTimeHandler}
            materialWidth={materialWidth}
          />
        )}
        {calculationId &&
          !calculation.isError &&
          calculation.data &&
          isDataFetched && (
            <OldCalculationForm
              form={form}
              data={calculation.data}
              calculate={calculateHandler}
              calculateSheet={calculateSheet}
              createPdf={createPdf}
              onChangeNumberOfRows={numberOfRowsHandler}
              onChangeLabelHeight={labelHeightHandler}
              onChangeLabelWidth={labelWidthHandler}
              onChangeNumberOfColors={numberOfColorsHandler}
              onChangeQuantityOfSpecialColors={quantityOfSpecialColorsHandler}
              onChangeFolioPrinting={folioPrintingHandler}
              onChangePrintingForm={printingFormHandler}
              onChangeDesign={designHandler}
              onChangeBoxes={boxesHandler}
              onChangeTransport={transportHandler}
              onChangeSubcontractor={subcontractorHandler}
              onChangeVarnishing={varnishingHandler}
              onChangeTypeOfLabel={typeOfLabelHandler}
              onChangeCalculatedWastePaper={calculatedWastePaperHandler}
              onChangeMachine={machineHandler}
              hideColorQuantity={hideColorQuantity}
              hideQuantitySpecialColors={hideQuantitySpecialColors}
              hideGrossFolio={hideGrossFolio}
              hideFolioWidth={hideFolioWidth}
              hideFolioPrice={hideFolioPrice}
              hideFolioPage={hideFolioPage}
              hidePrintingForm={hidePrintingForm}
              hideDesign={hideDesign}
              hideBoxes={hideBoxes}
              hideTransport={hideTransport}
              hideSubcontractors={hideSubcontractors}
              hideTigl={hideTigl}
              hideBending={hideBending}
              hideDegreeOfVarnishConsumption={hideDegreeOfVarnishConsumption}
              hidePrintingSpeed={hidePrintingSpeed}
              hidePrintingSpeedOther={hidePrintingSpeedOther}
              hideTotalTimeOfPrinting={hideTotalTimeOfPrinting}
              hideTotalTimeOfPrintingOther={hideTotalTimeOfPrintingOther}
              hideTotalElectricityOther={hideTotalElectricityOther}
              hideGrossCurrentMeters={hideGrossCurrentMeters}
              hideGrossMetersPrinting={hideGrossMetersPrinting}
              hideGrossKgPrinting={hideGrossKgPrinting}
              hideVarnishQuantity={hideVarnishQuantity}
              hideVarnishPrice={hideVarnishPrice}
              hideColorPrice={hideColorPrice}
              hideTotalPreparationOfPrintingPresses={
                hideTotalPreparationOfPrintingPresses
              }
              hideTotal={hideTotal}
              visible={visible}
              tableVisible={tableVisible}
              dataTable={dataTable}
              materialPricePlaceholder={materialPricePlaceholder}
              degreeOfVarnishConsumptionPlaceholder={
                degreeOfVarnishConsumptionPlaceholder
              }
              hideTotalPrice={hideTotalPrice}
              isDigitalPrint={isDigitalPrint}
              printType={printType}
              setPrintType={setPrintType}
              changeMachine={chooseMachine}
              materialPriceHandler={changeMaterialPrice}
              onChangeTigl={tiglHandler}
              onChangeBending={bendingHandler}
              hideFolioPrinting={hideFolioPrinting}
              onChangeMachineSheet={machineSheetHandler}
              maxWidthCheck={maxWidthCheck}
              machine={machine}
              materialWidth={materialWidth}
              totalPrintingTimeHandler={totalPrintingTimeHandler}
            />
          )}
      </div>
    </div>
  );
};

export default OldEditCalculation;
