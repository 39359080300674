import {
  UserOutlined,
  ContactsOutlined,
  UsergroupDeleteOutlined,
  AuditOutlined,
  CalculatorOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import Users from "../pages/users/Users";
import EditUser from "../pages/users/EditUser";
import ViewUser from "../pages/users/ViewUser";
import Clients from "../pages/clients/Clients";
import EditClient from "../pages/clients/EditClient";
import ViewClient from "../pages/clients/ViewClient";
import EditContact from "../pages/contact/EditContact";
import Contacts from "../pages/contact/Contacts";
import ViewContact from "../pages/contact/ViewContact";
import EditCalculation from "../pages/calculation/EditCalculation";
import EditCalculationSheet from "../pages/calculation/EditCalculationSheet";
import ViewCalculation from "../pages/calculation/ViewCalculation";
import ViewCalculationSheet from "../pages/calculation/ViewCalculationSheet";
import TypeOfLabel from "../pages/type-of-label/TypeOfLabel";
import EditTypeOfLabel from "../pages/type-of-label/EditTypeOfLabel";
import ViewTypeOfLabel from "../pages/type-of-label/ViewTypeOfLabel";
import Machine from "../pages/machine/Machine";
import EditMachine from "../pages/machine/EditMachine";
import ViewMachine from "../pages/machine/ViewMachine";
import Settings from "../pages/settings/Settings";
import Box from "../pages/boxes/Box";
import ViewBox from "../pages/boxes/ViewBox";
import EditBox from "../pages/boxes/EditBox";
import EditFinishingWork from "../pages/finishing-work/EditFinishingWork";
import TypeOfFolio from "../pages/type-of-folio/TypeOfFolio";
import EditTypeOfFolio from "../pages/type-of-folio/EditTypeOfFolio";
import ViewTypeOfFolio from "../pages/type-of-folio/ViewTypeOfFolio";
import RewindingMachine from "../pages/rewinding-machine/RewindingMachine";
import EditRewindingMachine from "../pages/rewinding-machine/EditRewindingMachine";
import ViewRewindingMachine from "../pages/rewinding-machine/ViewRewindingMachine";
import TypeOfVarnishing from "../pages/type-of-varnishing/TypeOfVarnishing";
import EditTypeOfVarnishing from "../pages/type-of-varnishing/EditTypeOfVarnishing";
import ViewTypeOfVarnishing from "../pages/type-of-varnishing/ViewTypeOfVarnishing";
import TypeOfTransport from "../pages/type-of-transport/TypeOfTransport";
import EditTypeOfTransport from "../pages/type-of-transport/EditTypeOfTransport";
import ViewTypeOfTransport from "../pages/type-of-transport/ViewTypeOfTransport";
import Calculation from "../pages/calculation/Calculation";
import OldCalculation from "../pages/calculation/OldCalculation";
import EditOldCalculation from "../pages/calculation/EditOldCalculation";
import Hilzne from "../pages/hilzne/Hilzne";
import EditHilzne from "../pages/hilzne/EditHilzne";
import ViewHilzne from "../pages/hilzne/ViewHilzne";
import Offer from "../pages/offer/Offer";
import EditOffer from "../pages/offer/EditOffer";
import ViewOffer from "../pages/offer/ViewOffer";

const routes = [
  {
    label: "Prodavci",
    icon: UserOutlined,
    // icon: './prodavci.png',
    children: [
      {
        label: "Svi prodavci",
        path: "/admin/users",
        component: Users,
        allowed: ["admin", "user"],
        showInMenu: true,
      },
      {
        label: "Novi prodavac",
        path: "/admin/new-user",
        component: EditUser,
        allowed: ["admin"],
        showInMenu: true,
      },
      {
        label: "Edit User",
        path: "/admin/edit-user/:userId",
        component: EditUser,
        allowed: ["admin", "user"],
        showInMenu: false,
      },
      {
        label: "View User",
        path: "/admin/view-user/:id",
        component: ViewUser,
        allowed: ["admin", "user"],
        showInMenu: false,
      },
    ],
  },
  {
    label: "Klijenti",
    icon: UsergroupDeleteOutlined,
    children: [
      {
        label: "Svi klijenti",
        path: "/admin/clients",
        component: Clients,
        allowed: ["admin", "user"],
        showInMenu: true,
      },
      {
        label: "Novi klijent",
        path: "/admin/new-client",
        component: EditClient,
        allowed: ["admin", "user"],
        showInMenu: true,
      },
      {
        label: "Edit User",
        path: "/admin/edit-client/:clientId",
        component: EditClient,
        allowed: ["admin", "user"],
        showInMenu: false,
      },
      {
        label: "View User",
        path: "/admin/view-client/:id",
        component: ViewClient,
        allowed: ["admin", "user"],
        showInMenu: false,
      },
    ],
  },
  {
    label: "Ponude",
    icon: AuditOutlined,
    children: [
      {
        label: "Sve ponude",
        path: "/admin/offers",
        component: Offer,
        allowed: ["admin", "user"],
        showInMenu: true,
      },
      {
        label: "Nova ponuda",
        path: "/admin/new-offer",
        component: EditOffer,
        allowed: ["admin", "user"],
        showInMenu: true,
      },
      {
        label: "Pregled ponude",
        path: "/admin/view-offer/:id",
        component: ViewOffer,
        allowed: ["admin", "user"],
        showInMenu: false,
      },
    ],
  },
  {
    label: "Potencijalni klijenti",
    icon: ContactsOutlined,
    children: [
      {
        label: "Potencijalni klijenti",
        path: "/admin/contacts",
        component: Contacts,
        allowed: ["admin", "user"],
        showInMenu: true,
      },
      {
        label: "Novi potencijalni klijent",
        path: "/admin/new-contact",
        component: EditContact,
        allowed: ["admin", "user"],
        showInMenu: true,
      },
      {
        label: "View Contact",
        path: "/admin/view-contact/:id",
        component: ViewContact,
        allowed: ["admin", "user"],
        showInMenu: false,
      },
    ],
  },
  {
    label: "Kalkulacija",
    icon: CalculatorOutlined,
    children: [
      {
        label: "Nova kalkulacija rolna",
        path: "/admin/new-calculation",
        component: EditCalculation,
        allowed: ["admin"],
        showInMenu: false,
      },
      {
        label: "Nova kalkulacija tabak",
        path: "/admin/new-calculation-sheet",
        component: EditCalculationSheet,
        allowed: ["admin"],
        showInMenu: false,
      },
      {
        label: "Edituj kalkulaciju rolna",
        path: "/admin/edit-calculations/:calculationId",
        component: EditCalculation,
        allowed: ["admin"],
        showInMenu: false,
      },
      {
        label: "Pregledaj kalkulaciju rolna",
        path: "/admin/view-calculations/:calculationId",
        component: ViewCalculation,
        allowed: ["admin"],
        showInMenu: false,
      },
      {
        label: "Edituj kalkulaciju tabak",
        path: "/admin/edit-calculations-sheet/:calculationId",
        component: EditCalculationSheet,
        allowed: ["admin"],
        showInMenu: false,
      },
      {
        label: "Pregledaj kalkulaciju tabak",
        path: "/admin/view-calculations-sheet/:calculationId",
        component: ViewCalculationSheet,
        allowed: ["admin"],
        showInMenu: false,
      },
      // {
      //   label: "Kalkulator",
      //   path: "/admin/calculator",
      //   component: Calculator,
      //   allowed: ["admin"],
      //   showInMenu: true,
      // },
      {
        label: "Kalkulacije",
        path: "/admin/calculations",
        component: Calculation,
        allowed: ["admin"],
        showInMenu: true,
      },
      {
        label: "Stare kalkulacije",
        path: "/admin/old-calculations",
        component: OldCalculation,
        allowed: ["admin"],
        showInMenu: false,
      },
      {
        label: "Edituj staru kalkulaciju",
        path: "/admin/edit-old-calculations/:calculationId",
        component: EditOldCalculation,
        allowed: ["admin"],
        showInMenu: false,
      },
    ],
  },
  {
    label: "Settings",
    icon: SettingOutlined,
    children: [
      {
        label: "Sva podešavanja",
        path: "/admin/settings",
        component: Settings,
        allowed: ["admin"],
        showInMenu: true,
      },
      {
        label: "Sve mašine",
        path: "/admin/machines",
        component: Machine,
        allowed: ["admin"],
        showInMenu: false,
      },
      {
        label: "Nova mašina",
        path: "/admin/new-machine",
        component: EditMachine,
        allowed: ["admin"],
        showInMenu: false,
      },
      {
        label: "Edituj mašinu",
        path: "/admin/edit-machine/:machineId",
        component: EditMachine,
        allowed: ["admin"],
        showInMenu: false,
      },
      {
        label: "Pregled mašine",
        path: "/admin/view-machine/:machineId",
        component: ViewMachine,
        allowed: ["admin"],
        showInMenu: false,
      },
      {
        label: "Sve etikete",
        path: "/admin/types-of-label",
        component: TypeOfLabel,
        allowed: ["admin"],
        showInMenu: false,
      },
      {
        label: "Nova etiketa",
        path: "/admin/new-typeoflabel",
        component: EditTypeOfLabel,
        allowed: ["admin"],
        showInMenu: false,
      },
      {
        label: "Edituj etiketu",
        path: "/admin/edit-typeoflabel/:typeoflabelId",
        component: EditTypeOfLabel,
        allowed: ["admin"],
        showInMenu: false,
      },
      {
        label: "Pregled etikete",
        path: "/admin/view-typeoflabel/:typeoflabelId",
        component: ViewTypeOfLabel,
        allowed: ["admin"],
        showInMenu: false,
      },
      {
        label: "Sve kutije",
        path: "/admin/boxes",
        component: Box,
        allowed: ["admin"],
        showInMenu: false,
      },
      {
        label: "Nova kutija",
        path: "/admin/new-box",
        component: EditBox,
        allowed: ["admin"],
        showInMenu: false,
      },
      {
        label: "Edituj kutiju",
        path: "/admin/edit-box/:boxId",
        component: EditBox,
        allowed: ["admin"],
        showInMenu: false,
      },
      {
        label: "Pregled kutije",
        path: "/admin/view-box/:boxId",
        component: ViewBox,
        allowed: ["admin"],
        showInMenu: false,
      },
      {
        label: "Svi tipovi folije",
        path: "/admin/types-of-folio",
        component: TypeOfFolio,
        allowed: ["admin"],
        showInMenu: false,
      },
      {
        label: "Novi tip folije",
        path: "/admin/new-typeoffolio",
        component: EditTypeOfFolio,
        allowed: ["admin"],
        showInMenu: false,
      },
      {
        label: "Edituj tip folije",
        path: "/admin/edit-typeoffolio/:typeoffolioId",
        component: EditTypeOfFolio,
        allowed: ["admin"],
        showInMenu: false,
      },
      {
        label: "Pregled tipa folije",
        path: "/admin/view-typeoffolio/:typeoffolioId",
        component: ViewTypeOfFolio,
        allowed: ["admin"],
        showInMenu: false,
      },
      {
        label: "Sve mašine za premotavanje",
        path: "/admin/rewinding-machines",
        component: RewindingMachine,
        allowed: ["admin"],
        showInMenu: false,
      },
      {
        label: "Novi tip folije",
        path: "/admin/new-rewindingmachine",
        component: EditRewindingMachine,
        allowed: ["admin"],
        showInMenu: false,
      },
      {
        label: "Edituj tip folije",
        path: "/admin/edit-rewindingmachine/:rewindingmachineId",
        component: EditRewindingMachine,
        allowed: ["admin"],
        showInMenu: false,
      },
      {
        label: "Pregled tipa folije",
        path: "/admin/view-rewindingmachine/:rewindingmachineId",
        component: ViewRewindingMachine,
        allowed: ["admin"],
        showInMenu: false,
      },
      {
        label: "Svi tipovi lakiranja",
        path: "/admin/types-of-varnishing",
        component: TypeOfVarnishing,
        allowed: ["admin"],
        showInMenu: false,
      },
      {
        label: "Novi tip lakiranja",
        path: "/admin/new-typeofvarnishing",
        component: EditTypeOfVarnishing,
        allowed: ["admin"],
        showInMenu: false,
      },
      {
        label: "Edituj tip lakiranja",
        path: "/admin/edit-typeofvarnishing/:typeofvarnishingId",
        component: EditTypeOfVarnishing,
        allowed: ["admin"],
        showInMenu: false,
      },
      {
        label: "Pregled tipa lakiranja",
        path: "/admin/view-typeofvarnishing/:typeofvarnishingId",
        component: ViewTypeOfVarnishing,
        allowed: ["admin"],
        showInMenu: false,
      },
      {
        label: "Svi tipovi transporta",
        path: "/admin/types-of-transport",
        component: TypeOfTransport,
        allowed: ["admin"],
        showInMenu: false,
      },
      {
        label: "Novi tip transporta",
        path: "/admin/new-typeoftransport",
        component: EditTypeOfTransport,
        allowed: ["admin"],
        showInMenu: false,
      },
      {
        label: "Edituj tip transporta",
        path: "/admin/edit-typeoftransport/:typeoftransportId",
        component: EditTypeOfTransport,
        allowed: ["admin"],
        showInMenu: false,
      },
      {
        label: "Pregled tipa transporta",
        path: "/admin/view-typeoftransport/:typeoftransportId",
        component: ViewTypeOfTransport,
        allowed: ["admin"],
        showInMenu: false,
      },
      {
        label: "Sve hilzne",
        path: "/admin/hilzne",
        component: Hilzne,
        allowed: ["admin"],
        showInMenu: false,
      },
      {
        label: "Nova hilzna",
        path: "/admin/new-hilzna",
        component: EditHilzne,
        allowed: ["admin"],
        showInMenu: false,
      },
      {
        label: "Edituj hilznu",
        path: "/admin/edit-hilzna/:hilznaId",
        component: EditHilzne,
        allowed: ["admin"],
        showInMenu: false,
      },
      {
        label: "Pregled hilzne",
        path: "/admin/view-hilzna/:hilznaId",
        component: ViewHilzne,
        allowed: ["admin"],
        showInMenu: false,
      },
      //////////////////////////////////////////////////
      {
        label: "Nova doradna radnja",
        path: "/admin/new-finishingwork",
        component: EditFinishingWork,
        allowed: ["admin"],
        showInMenu: false,
      },
      {
        label: "Edituj doradnu radnju",
        path: "/admin/edit-finishingwork/:finishingworkId",
        component: EditFinishingWork,
        allowed: ["admin"],
        showInMenu: false,
      },
    ],
  },
];

export default routes;
