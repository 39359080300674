import React from "react";
import { Tabs, Card, Input, Button, Form, InputNumber, Select } from "antd";

const layout = {
  labelCol: { span: 5 },
  wrapperCol: { span: 9 },
};

const Option = { Select };

const RewindingMachineForm = ({ isNew, data, onSubmit }) => {
  const [form] = Form.useForm();

  let initialValues = { ...data };

  const onFinish = async (values, isNew) => {
    if (!isNew) {
      values._id = data._id;
    }
    onSubmit(values, isNew);
  };

  return (
    <div className="panel panel-body ">
      <div className="panel-body">
        <Card
          title={`${
            isNew
              ? "NOVA MAŠINA ZA PREMOTAVANJE"
              : "IZMENA MAŠINE ZA PREMOTAVANJE"
          }`}
        >
          <Form
            {...layout}
            initialValues={initialValues}
            onFinish={(values) => onFinish(values, !data)}
            layout="vertical"
            form={form}
          >
            <div className="mainCont">
              <Form.Item
                required
                name="code"
                className="ant-form-item-control-input-content"
                label="Šifra mašine za premotavanje:"
              >
                <Input />
              </Form.Item>
            </div>

            <Tabs forceRender defaultActiveKey="1">
              <Tabs.TabPane
                forceRender
                tab="Osnove informacije o tipu mašine za premotavanje"
                key="1"
              >
                <div>
                  <div className="panel-body">
                    <Form.Item
                      required
                      className="formitemANTD"
                      name="name"
                      label="Naziv mašine za premotavanje:"
                    >
                      <Input />
                    </Form.Item>
                  </div>
                </div>
              </Tabs.TabPane>
            </Tabs>

            <div className="text-right">
              <Button type="primary" htmlType="submit">
                {isNew ? "Dodaj" : "Izmeni"} mašinu za premotavanje
              </Button>
            </div>
          </Form>
        </Card>
      </div>
    </div>
  );
};

export default RewindingMachineForm;
